import React, { FC, useState } from "react";
import { BotFRowActionComponentProps } from "../interfaces";
import Tooltip from '@material-ui/core/Tooltip';
import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import InfoBotDialogNoDate from "./error-no-date-dialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonItem: {
        padding: theme.spacing(1),
        marginTop: 15,
        marginLeft: 35
    },
    toolTipItem: {
        color: 'white',
        marginTop: 5,
        fontSize: 15,
        opacity: '0.6 !important',
    }
  })
);

export const RowActionErrorNoDate: FC<BotFRowActionComponentProps> = ({ operate, icon }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    
    const handleClickOpen = async () => {
        
        setOpen(true);
    };
    const handleClose = async () => {
        setOpen(false);
    };
    return (
        <>
            {operate.errorType === "errorNoDate" && (
            <Tooltip title="Edita la fecha del suministro" placement="bottom-start" arrow classes={{tooltip: classes.toolTipItem}}>
                <IconButton edge="end" className={classes.buttonItem} aria-label="bot" color="inherit" onClick={handleClickOpen}>
                    {icon}
                </IconButton>
            </Tooltip>
            )}

            <InfoBotDialogNoDate
                open={open}
                onClose={handleClose}
                operate={operate}
                icon={icon}
            />
        </>
    )
}