export interface IFuelType
{
    id: number;
    name: string;
}

export enum fuelTypes {
    GasNatural = 1,
    GLP,
    Gasoil,
    Diesel,
    Fuel,
    Biomasa
  }