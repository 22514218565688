import { IAccessType } from "./access-type.interfaces";
import { IFuelType } from "./fuel-types.interface";

export interface IFuelSupply {
    id: number;
    reference: string;
    accessTypeId: number | null;
    fuelTypeId: number;
    complexId: number | null;
    providerId: number | null;
    establishmentId: number | null;
    code: string | null;
    invoicesFolder: string | null;
    emailNotification: string | null;
    startCaptureDate?: Date;
    sendEmail: boolean;
    sendClientEmail: boolean;
    startValidationsDate?: Date;
    blacklisted?: boolean;
    separatedMeterAccount: boolean;
    separatedMeterInvoiceFolder: string;
    separatedMeterStartCaptureDate: Date;
    separatedMeterStartValidationsDate: Date;
}

export const initialIFuelSupply: IFuelSupply = {
    id: 0,
    reference: '',
    accessTypeId: null,
    fuelTypeId: 0,
    complexId: null,
    providerId: null,
    establishmentId: null,
    code: null,
    invoicesFolder: null,
    emailNotification: null,
    startCaptureDate: undefined,
    sendEmail: false,
    sendClientEmail: false,
    startValidationsDate: new Date(),
    blacklisted: false,
    separatedMeterAccount: false,
    separatedMeterInvoiceFolder: '',
    separatedMeterStartCaptureDate: new Date(),
    separatedMeterStartValidationsDate: new Date()
}

export interface ICreateFuelSupply {
    reference: string;
    accessTypeId: number;
    fuelTypeId: number;
    complexId: number | null;
    providerId: number | null;
    establishmentId: number | null;
    code: string | null;
    invoicesFolder: string | null;
    emailNotification: string | null;
    startCaptureDate?: Date;
    sendEmail: boolean;
    sendClientEmail: boolean;
    startValidationsDate?: Date;
    blacklisted?: boolean;
    separatedMeterAccount: boolean;
    separatedMeterInvoiceFolder: string;
    separatedMeterStartCaptureDate: Date;
    separatedMeterStartValidationsDate: Date;
}

export const initialICreateFuelSupply: ICreateFuelSupply = {
    reference: '',
    accessTypeId: 0,
    fuelTypeId: 0,
    complexId: null,
    providerId: 0,
    establishmentId: null,
    code: null,
    invoicesFolder: null,
    emailNotification: null,
    startCaptureDate: undefined,
    sendEmail: true,
    sendClientEmail: true,
    blacklisted: false,
    separatedMeterAccount: false,
    separatedMeterInvoiceFolder: '',
    separatedMeterStartCaptureDate: new Date(),
    separatedMeterStartValidationsDate: new Date(),
    startValidationsDate: undefined,
}
export interface IUpdateFuelSupply {
    id: number;
    reference: string;
    accessTypeId: number | null;
    fuelTypeId: number;
    complexId: number | null;
    providerId: number | null;
    establishmentId: number | null;
    code: string | null;
    invoicesFolder: string | null;
    emailNotification: string | null;
    startCaptureDate?: Date;
    startValidationsDate?: Date;
    sendEmail: boolean;
    sendClientEmail: boolean;
    separatedMeterAccount: boolean;
    separatedMeterInvoiceFolder: string;
    separatedMeterStartCaptureDate: Date;
    separatedMeterStartValidationsDate: Date;
}

export const initialIUpdateFuelSupply: IUpdateFuelSupply = {
    id: 0,
    reference: '',
    accessTypeId: 0,
    fuelTypeId: 0,
    complexId: null,
    establishmentId: null,
    providerId: 0,
    code: null,
    invoicesFolder: null,
    emailNotification: null,
    startCaptureDate: undefined,
    startValidationsDate: undefined,
    sendEmail: true,
    sendClientEmail: true,
    separatedMeterAccount: false,
    separatedMeterInvoiceFolder: '',
    separatedMeterStartCaptureDate: new Date(),
    separatedMeterStartValidationsDate: new Date()
}

export interface IFuelSupplyUpsert {
    id: number;
    reference: string;
    accessTypeId: number | null;
    fuelTypeId: number;
    complexId: number | null;
    providerId: number | null;
    establishmentId: number | null;
    code: string | null;
    invoicesFolder: string | null;
    emailNotification: string | null;
    startCaptureDate?: Date;
    startValidationsDate?: Date;
    sendEmail: boolean;
    sendClientEmail: boolean;
    separatedMeterAccount: boolean;
    separatedMeterInvoiceFolder: string;
    separatedMeterStartCaptureDate: Date;
    separatedMeterStartValidationsDate: Date;
}

export const initialIFuelSupplyUpsert: IFuelSupplyUpsert = {
    id: 0,
    reference: '',
    accessTypeId: 0,
    fuelTypeId: 0,
    complexId: null,
    establishmentId: null,
    providerId: 0,
    code: null,
    invoicesFolder: null,
    emailNotification: null,
    startCaptureDate: undefined,
    startValidationsDate: undefined,
    sendEmail: true,
    sendClientEmail: true,
    separatedMeterAccount: false,
    separatedMeterInvoiceFolder: '',
    separatedMeterStartCaptureDate: new Date(),
    separatedMeterStartValidationsDate: new Date()
}

export interface IFuelSupplyFindById {
    id: number;
    reference: string;
    accessTypeId: number;
    fuelTypeId: number;
    complexId: number;
    establishmentId: number;
    providerId: number | null;
    accessType: IAccessType;
    fuelType: IFuelType;
    code: string | null;
    invoicesFolder: string | null;
    emailNotification: string | null;
    startCaptureDate?: Date;
    startValidationsDate?: Date;
    sendEmail: boolean;
    sendClientEmail: boolean;
    separatedMeterAccount: boolean;
    separatedMeterInvoiceFolder: string;
    separatedMeterStartCaptureDate: Date;
    separatedMeterStartValidationsDate: Date;
}