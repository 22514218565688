import React, { FC, useEffect, useState } from "react";
import {
  makeStyles,
  SvgIconProps,
  Fab,
  Breadcrumbs,
  Grid,
  Tooltip,
} from "@material-ui/core";
import MaterialUiLink from "@material-ui/core/Link";
import { useNavigator } from "../contexts/navigator.context";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SearchIcon from '@material-ui/icons/Search';
import { useBreadCrumbName } from "../contexts/breadCrumbNames.context";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
// import { useTranslation } from "react-i18next";
import DeleteIcon from '@material-ui/icons/Delete';

interface IScreenGlobalSctructureProps {
  title: string;
  headerIcon: React.ComponentType<SvgIconProps>;
  buttonIcon?: JSX.Element;
  onButtonClick?: () => void;
  matchUrl: string;
  clientId?: string;
  drawer?: JSX.Element;
  onPeekButtonClick?: () => void;
  onCopyButtonClick?: () => void;
  onViewMapButtonClick?: () => void;
  onBlacklistButtonClick?: () => void;
  onSearchButtonClick?: () => void;
  blacklisted?: boolean;
  clientCancelled?: boolean;
  filedButtonList?: () => void;
  filedButtonListTitle?: string;
  deleteSupply?: () => void;
  loading: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.drawer + 1,
  },
  fabEye: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(18),
    zIndex: theme.zIndex.drawer + 1,
  },
  fabSearch: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(26),
    zIndex: theme.zIndex.drawer + 1,
  },
  fabPeek: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(10),
    zIndex: theme.zIndex.drawer + 1,
  },
  cpyPeek: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(20),
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  formControl: {
    marginBottom: "1rem",
    "& :last-child": {
      marginBottom: 0,
    },
  },
  progress: {
    margin: theme.spacing(2),
  },
  breadCrumb: {
    marginBottom: "1.75rem",
  },
  notBlacklisted: {
    color: "#B1C904",
  },
  blacklisted: {
    color: "#B1C300",
    opacity: 0.4,
  }
}));

const ScreenGlobalStructure: FC<IScreenGlobalSctructureProps> = (props) => {
  const classes = useStyles();
  // const { t } = useTranslation();
  const [, navigatorDispatch] = useNavigator();
  const { names: BreadCrumbNames } = useBreadCrumbName();
  const [breadCrumbs, setBreadCrumbs] = useState<string[]>(["0"]);

  const getBreadCrumbs = (): string[] => {
    var urlCpy = props.matchUrl.slice(1) + "/";
    var breadCrumbCount: number = 0;
    var slashLocation: number = urlCpy.indexOf("/");
    var crumbName = urlCpy.slice(0, slashLocation);
    var breadCrumbsCpy: string[] = [];

    while (slashLocation >= 0) {
      urlCpy = urlCpy.slice(slashLocation + 1);
      slashLocation = urlCpy.indexOf("/");
      if (!Number.isNaN(parseInt(urlCpy.slice(0, slashLocation)))) {
        breadCrumbsCpy[breadCrumbCount] = crumbName;
        breadCrumbCount++;
      } else {
        crumbName = urlCpy.slice(0, slashLocation);
      }
    }
    return breadCrumbsCpy;
  };

  useEffect(() => {
    const brdCrmps = getBreadCrumbs();
    if (brdCrmps.length === 0) {
      navigatorDispatch({
        type: "set-header",
        header: {
          title: props.title,
          icon: props.headerIcon,
        },
      });
    } else {
      setBreadCrumbs(brdCrmps);
    }

    if (props.drawer !== undefined) {
      navigatorDispatch({
        type: "set-right-nav",
        component: props.drawer,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.matchUrl]);

  const createHref = (url: string[], item: string) => {
    
    let href: string = "";

    for(let i = 0; i < url.length; i++){
      if(item === url[i]){
        href += url[i]+"/"+url[i+1]+"/";
        break;
      }
      href += url[i]+"/";
    }

    return href;
  }

  const breadCrumbsItem = (item: string, index: number) => {
    let name = BreadCrumbNames[item];
    let color = classes.notBlacklisted;
    const cancelled = "(Cancelado)";

    if(name.length > cancelled.length){
      let slicedName = name.slice(name.length - cancelled.length, name.length);
      if(slicedName === cancelled) color = classes.blacklisted;
    }
    
    const maxBreadcrumbs = Math.floor((props.matchUrl.split("/").length - 1) / 2);
    const newHref = createHref(props.matchUrl.split("/"), item);

    return (
      <>
        <MaterialUiLink
          className={color} 
          href={index+1 !== maxBreadcrumbs ? newHref : undefined}
        >
          {name}
        </MaterialUiLink>
      </>
    );
  };

  return (
    <div className={classes.root}>
      {props.onButtonClick !== undefined && props.buttonIcon !== undefined && (
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={props.onButtonClick}
          disabled={props.loading}
        >
          {props.buttonIcon}
        </Fab>
      )}
      {props.onPeekButtonClick !== undefined && (
        <Fab
          color="primary"
          aria-label="peek"
          className={classes.fabEye}
          onClick={props.onPeekButtonClick}
          disabled={props.loading}
        >
          <VisibilityIcon />
        </Fab>
      )}
      {props.filedButtonList !== undefined && props.filedButtonListTitle !== undefined &&(
        <Tooltip title={props.filedButtonListTitle} 
        placement="top">
          <Fab
            color="primary"
            aria-label="peek"
            className={classes.fabPeek}
            onClick={props.filedButtonList}
            disabled={props.loading}
          >
            <FormatListBulletedIcon />
          </Fab>
        </Tooltip>
      )}
      {props.onCopyButtonClick !== undefined && (
        <Fab
          color="primary"
          aria-label="peek"
          className={
            props.onPeekButtonClick ? classes.cpyPeek : classes.fabPeek
          }
          onClick={props.onCopyButtonClick}
          disabled={props.loading}
        >
          <FileCopyIcon />
        </Fab>
      )}
      {props.onViewMapButtonClick !== undefined && (
        <div>
          <Fab
            color="primary"
            aria-label="peek"
            className={classes.fabEye}
            onClick={props.onViewMapButtonClick}
            disabled={props.loading}
          >
            <LocationOnIcon />
          </Fab>
          <Tooltip title="Buscador" placement="top">
            <Fab
              color="primary"
              aria-label="peek"
              className={classes.fabSearch}
              onClick={props.onSearchButtonClick}
              disabled={props.loading}
            >
              <SearchIcon />
            </Fab>
          </Tooltip>
        </div>
      )}
      {props.deleteSupply !== undefined && (
        <Tooltip title="Eliminar suministro" placement="top">
          <Fab
            color="primary"
            aria-label="peek"
            className={classes.fabEye}
            onClick={props.deleteSupply}
            disabled={props.loading}
          >
            <DeleteIcon/>
          </Fab>
        </Tooltip>
      )}
      {props.onBlacklistButtonClick !== undefined && props.blacklisted === false && (
        <Tooltip title="Descartar" placement="top">
          <Fab
            color="primary"
            aria-label="peek"
            className={classes.fabPeek}
            onClick={props.onBlacklistButtonClick}
            disabled={props.loading}
          >
            <ClearIcon/>
          </Fab>
        </Tooltip>
      )}
      {props.onBlacklistButtonClick !== undefined && props.blacklisted === true && (
        <Tooltip title="Restablecer" placement="top">
          <Fab
            color="primary"
            aria-label="peek"
            className={classes.fabPeek}
            onClick={props.onBlacklistButtonClick}
            disabled={props.loading}
          >
            <CheckIcon/>
          </Fab>
        </Tooltip>
      )}
      <Grid xs={12} direction="row">
        {breadCrumbs[0] !== "0" && (
          <Breadcrumbs
            separator=">"
            aria-label="breadcrumb"
            className={classes.breadCrumb}
          >
            {breadCrumbs.map(breadCrumbsItem)}
            disabled={props.loading}
          </Breadcrumbs>
        )}

        <div className={classes.content}>{props.children}</div>
      </Grid>
    </div>
  );
};

export default ScreenGlobalStructure;
