export interface IUserSignatureData {
    fullname: string;
    role: string;
    tlf: string;
    email: string;
    imageUrl: string;
}

export const initialUserSignatureData: IUserSignatureData = {
    fullname: "",
    role: "",
    tlf: "",
    email: "",
    imageUrl: "",
}