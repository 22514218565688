import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";

export class datesHandler {
    constructor() { }

    public checkLimits(firstDate: Date, lastDate: Date): boolean {
        const firstDateCopy = new Date(firstDate);
        const lastDateCopy = new Date(lastDate);
        return firstDateCopy < lastDateCopy;
    }

    public checkOverlap(newDateInit: Date,
        newDateEnd: Date,
        oldDateInit: Date,
        oldDateEnd: Date): boolean {
        const newDateInitFormatedCopy = new Date(newDateInit);
        const newDateEndFormatedCopy = new Date(newDateEnd);
        const oldDateInitFormatedCopy = new Date(oldDateInit);
        const oldDateEndFormatedCopy = new Date(oldDateEnd);

        return ((oldDateInitFormatedCopy <= newDateEndFormatedCopy && oldDateInitFormatedCopy >= newDateInitFormatedCopy) ||
            (oldDateEndFormatedCopy >= newDateInitFormatedCopy && oldDateEndFormatedCopy <= newDateEndFormatedCopy) ||
            (newDateInitFormatedCopy >= oldDateInitFormatedCopy && newDateEndFormatedCopy <= oldDateEndFormatedCopy) ||
            (oldDateInitFormatedCopy >= newDateInitFormatedCopy && oldDateEndFormatedCopy <= newDateEndFormatedCopy));
    }
}