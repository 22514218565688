import { Drawer, List } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { FC } from "react";
import useIoC from "../../contexts/ioc.context";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import CenteredCircularProgress from "../../components/centered-circular-progress";
import AlertBox from "../../components/alert-box";
import InfoBotFields from "./info-bot_f-fields";
import {
  IMissingBotDataQuery,
  OperateGateway,
} from "../../gateways/operate.gateway";
import { IOperateVariables } from "../../gateways/operate.interface";
import ElectricityIcon from "@material-ui/icons/FlashOn";
import FuelIcon from "@material-ui/icons/LocalGasStation";
import DropIcon from "@material-ui/icons/Opacity";
import PostAddIcon from "@material-ui/icons/PostAdd";
import InfoBotSidebar from "./items/info-bot_f-sidebar";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationItem: {
      marginTop: theme.spacing(2),
      justifyContent: "center",
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: 240,
        flexShrink: 0,
      },
    },
    toolbar: theme.mixins.toolbar as CSSProperties,
    drawerPaper: {
      width: 240,
    },
  })
);

interface IListInfoBotF{
  clientId: string;
  companyId: string;
  complexId: string;
}

const ListInfoBotF: FC<IListInfoBotF> = (props) => {
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const classes = useStyles();
  const [items, setItems] = useState<IOperateVariables[]>([]);
  const { t } = useTranslation();
  const operateGateway = useIoC(OperateGateway);

  const [filters, setFilters] = useState<IMissingBotDataQuery>({
    errorNoCups: true,
    errorNoDate: true,
    errorOneDriveFile: true,
    errorOperative: true,
  });

  const handleChangeFilters = useCallback((filters: IMissingBotDataQuery) => {
    setFilters(filters);
  }, []);

  useEffect(() => {
    (async () => {
      const queryResult: IOperateVariables[] =
        await operateGateway.getMissingBot_fData(filters);
      setIsDataLoading(true);
      setItems(queryResult);
      setIsDataLoading(false);
    })();
  }, [filters]);

  const handleChange = () => {
    setItems(items);
  };

  useEffect(() => {
    setItems(items);
  }, []);

  if (isDataLoading) {
    return <CenteredCircularProgress />;
  }

  return (
    <div>
      {!Array.isArray(items) || items.length === 0 ? (
        <AlertBox variant="info">{t("common.noItems")}</AlertBox>
      ) : (
        <div>
          <List>
            {items.map((item) => {
              let icon: JSX.Element = (
                <PostAddIcon style={{ fontSize: 30, color: "#b1c904" }} />
              );
              if (item.supplyType.toLowerCase().includes("electric")) {
                icon = (
                  <ElectricityIcon style={{ fontSize: 30, color: "orange" }} />
                );
              } else if (item.supplyType.toLowerCase().includes("fuel")) {
                icon = (
                  <FuelIcon style={{ fontSize: 30, color: "SlateGrey" }} />
                );
              } else if (item.supplyType.toLowerCase().includes("water")) {
                icon = (
                  <DropIcon style={{ fontSize: 30, color: "CornflowerBlue" }} />
                );
              }

              return (
                <InfoBotFields
                  focus={false}
                  operate={item}
                  onChange={handleChange}
                  clientId={props.clientId}
                  companyId={props.companyId}
                  complexId={props.complexId}
                  icon={icon}
                />
              );
            })}
          </List>
        </div>
      )}

      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="permanent"
        anchor="right"
        open
      >
        <div className={classes.toolbar} />
        <InfoBotSidebar
          filters={filters}
          onChangeFilters={handleChangeFilters}
        />
      </Drawer>
    </div>
  );
};

export default ListInfoBotF;
