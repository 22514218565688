import React, { useEffect } from "react";
import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IValidationRule, initialValidationRule } from "../../gateways/validation-rules.interfaces";
import ValidationRuleForm from "./validation-rule-form";

export interface IValidationRuleAddDialogProps {
    open: boolean;
    validationRule: IValidationRule;
    onCreate: (item: IValidationRule) => void;
    onClose: () => void;
}

const ValidationRuleAddDialog: FC<IValidationRuleAddDialogProps> = ({ open, validationRule, onClose, onCreate }) => {
    const { t } = useTranslation();

    const [item, setItem] = useState<IValidationRule>(initialValidationRule);

    useEffect(() => {

        setItem(validationRule);

    }, [open]);

    const handleSubmit = (data: IValidationRule) => {
        onCreate(data);
    }

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
            <DialogTitle id="form-dialog-title">{t('validationRulesAddDialog.title')}</DialogTitle>
            <DialogContent>
                <ValidationRuleForm
                    item={item}
                    onSubmit={handleSubmit}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">
                    {t('common.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ValidationRuleAddDialog;