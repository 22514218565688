import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import SelectorField from "../../components/selector-field";
import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Grid,
  Button,
  ListItemIcon,
  IconButton,
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from "@material-ui/core";
import { ISupplyConnectionBot } from "../../gateways/supply-connection-bot.interface";
import { IElectricContract, IElectricContractAccountLogs } from "../../gateways/electric-contract.interfaces";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  IProvidersDisplay,
  IProviders,
} from "../../gateways/providers.interface";
import MaterialTable from "material-table";
import { IElectricSupplyRates } from "../../gateways/electric-supply-rates.interface";
import { IElectricContractMaterialTableRows } from "../../utils/material-table.interface";
import { ListAlt } from "@material-ui/icons";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      height: "100%",
      borderSpacing: 2,
      padding: theme.spacing(1),
    },
  })
);

interface IelectricContractDetailProps {
  supplyConnectionBot?: ISupplyConnectionBot;
  item: IElectricContract;
  accountLogs?: IElectricContractAccountLogs[];
  showAccountLogs?: Boolean;
  onChange: (name: string, value: any) => void;
  validateRequired: (value: any) => boolean;
  providers: IProviders[];
  onSignContractDateChange: (value: any) => void;
  onInitSupplyDateChange: (value: any) => void;
  onEndSupplyDateChange: (value: any) => void;
  rates: IElectricSupplyRates[];
  state: IElectricContractMaterialTableRows[];
  setState: React.Dispatch<
    React.SetStateAction<IElectricContractMaterialTableRows[]>
  >;
  onChangePeriodElectricSupplyRate: (
    updatingArray: IElectricContractMaterialTableRows,
    index: number
  ) => void;
  onClickAddProvider: () => void;
}

const ElectricContractFormDetailUpsert: FC<IelectricContractDetailProps> = (
  props
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Grid container justify="flex-start" alignItems="flex-start">
        <Grid item xs={6} className={classes.gridItem}>
          <FormTextField
            name="reference"
            label={t("common.reference")}
            type="string"
            // validator={props.validateRequired}
            // errorText={t('messages.requiredField')}
            value={props.item.reference}
            onChange={props.onChange}
            fullWidth
            // required
          />
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <div>
            <KeyboardDatePicker
              margin="none"
              format="dd/MM/yyyy"
              fullWidth
              id="contractSignDateSelector"
              label={t("common.contractSignDateSelector")}
              value={props.item.signContractDate}
              onChange={props.onSignContractDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              // required
            />
          </div>
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
            <SelectorField
                name='providerId'
                emptyValue={true}
                fullWidth
                inputLabel={t('common.provider')}
                helperText={t('messages.requiredField') as string}
                required
                onChange={props.onChange}
                value={props.item.providerId}
                validator={props.validateRequired}
                values={props.providers.map(value => {
                    return { id: value.id, value: value.society };
                })}
                handleClickAddButon={props.onClickAddProvider}
            />
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <FormTextField
            name="duration"
            label={t("common.duration")}
            type="number"
            // validator={props.validateRequired}
            // errorText={t('messages.requiredField')}
            value={props.item.duration}
            onChange={props.onChange}
            fullWidth
            // required
            disabled={true}
          />
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <KeyboardDatePicker
            format="dd/MM/yyyy"
            fullWidth
            id="SupplyEndDateSelector"
            label={t("common.supplyEndDateSelector")}
            value={props.item.endSupplyDate}
            onChange={props.onEndSupplyDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            required
          />
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <FormTextField
            name="term"
            label={t("common.term")}
            type="number"
            // validator={props.validateRequired}
            // errorText={t('messages.requiredField')}
            value={props.item.term}
            onChange={props.onChange}
            fullWidth
            // required
          />
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <FormTextField
            name="cancelPriorNotice"
            label={t("common.cancelPriorNotice")}
            type="number"
            // validator={props.validateRequired}
            // errorText={t('messages.requiredField')}
            value={props.item.cancelPriorNotice}
            onChange={props.onChange}
            fullWidth
            // required
          />
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <SelectorField
            name="modality"
            emptyValue={false}
            fullWidth
            inputLabel={t("common.providerTypeId")}
            helperText={t("messages.requiredField") as string}
            required
            onChange={props.onChange}
            value={props.item.modality}
            validator={props.validateRequired}
            values={[
              { id: 1, value: t("common.fixed") },
              { id: 2, value: t("common.indexed") },
            ]}
          />
        </Grid>

        <Grid item xs={6} className={classes.gridItem}>
          <SelectorField
            name="electricSupplyRateId"
            emptyValue={false}
            fullWidth
            inputLabel={t("common.periods")}
            helperText={t("messages.requiredField") as string}
            required
            onChange={props.onChange}
            value={props.item.electricSupplyRateId}
            validator={props.validateRequired}
            values={props.rates.map((value) => {
              return { id: value.id, value: value.name };
            })}
          />
        </Grid>

        <Grid item xs={props.showAccountLogs? 5 : 6} className={classes.gridItem}>
          <FormTextField
            name="accountNumber"
            label={`${t("common.accountNumber")}${props.item.accountNumber_date? ` - añadido el ${moment(props.item.accountNumber_date).format('DD/MM/YYYY')}`: ''}`}
            type="string"
            value={props.item.accountNumber?? ''}
            onChange={props.onChange}
            fullWidth
            // required
          />
        </Grid>

        {props.showAccountLogs &&
          <Grid item xs={1} className={classes.gridItem}>
            <Tooltip title="Ver registros">
              <IconButton 
                onClick={handleClickOpen('paper')} 
                disabled={props.accountLogs?.length === 0}>
                <ListAlt/>
              </IconButton>
            </Tooltip>
          </Grid>
        }

        <Grid item xs={12} className={classes.gridItem}>
          <MaterialTable
            options={{
              search: false,
              paging: false,
            }}
            localization={{
              header: {
                actions: t("common.actions") as string,
              },
            }}
            title={t("electricContractUpsertDialog.contractPeriods") as string}
            columns={[
              {
                title: t("common.periodNumber") as string,
                field: "periodNumber",
                type: "numeric",
                editable: "never",
              },
              {
                title: t("common.energyRate") as string,
                field: "energyRate",
                type: "numeric",
              },
              {
                title: t("common.powerRate") as string,
                field: "powerRate",
                type: "numeric",
              },
              {
                title: t("common.powerContract") as string,
                field: "powerContract",
                type: "numeric",
              },
              {
                title: t("common.discount") as string,
                field: "discount",
                type: "numeric",
              },
            ]}
            data={props.state}
            editable={{
              onRowUpdate: (newData: any, oldData: any) =>
                new Promise<void>(async(resolve) => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      let updateDataAid: IElectricContractMaterialTableRows[] =
                        [...props.state];
                      updateDataAid[updateDataAid.indexOf(oldData)] = newData;
                      props.setState(updateDataAid);
                      props.onChangePeriodElectricSupplyRate(
                        newData,
                        newData.periodNumber - 1
                      );
                    }
                  }, 600);
                }),
            }}
          />
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Historial de registros</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <TableContainer component={Paper}>
              <Table >
                <TableHead>
                  <TableRow>
                    <TableCell> Numero de cuenta </TableCell>
                    <TableCell> Editor </TableCell>
                    <TableCell> Fecha de edición </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.accountLogs?.map(row =>(
                    <TableRow key={row.id}>
                      {/* <TableCell>{row.accountNumber}</TableCell> */}
                      <TableCell>{row.accountNumber === ''? <i>Se eliminó la cuenta </i>: row.accountNumber }</TableCell>
                      <TableCell>{row.editorUsername}</TableCell>
                      <TableCell>{moment(row.editionDate).format('DD/MM/YYYY')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ElectricContractFormDetailUpsert;
