import React, { FC } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { LoginPage } from "../pages/account/login.page";
import { SignInPage } from "../pages/account/signin.page";

const UnauthorizedLayout: FC = () => {

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>

      <Switch>
        <Route path="/login" component={LoginPage}/>
        <Route path="/signin" component={SignInPage}/>
        <Redirect to="/login"/>
      </Switch>

    </BrowserRouter>
  );
};

export default UnauthorizedLayout;
