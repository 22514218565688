import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import IContactType from "./contact-types.interfaces";

const contactTypesResource = 'contact-types';

@inject('axios')
export class ContactTypesGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IContactType[]> {
        const response = await this.axios.get(contactTypesResource);
        return response.data;
    }

    async findById(id: number): Promise<IContactType[]> {
        const url = `${contactTypesResource}/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    async create(client: Partial<IContactType>): Promise<IContactType> {
        const response = await this.axios.post(contactTypesResource, client);
        return response.data;
    }

    async update(id: number, client: Partial<IContactType>): Promise<IContactType> {
        const url = `${contactTypesResource}/${id}`;
        const response = await this.axios.put(url, client);
        return response.data;
    }

    async delete(id: number): Promise<void> {
        const url = `${contactTypesResource}/${id}`;
        const response = await this.axios.delete(url);
        return response.data;
    }
}