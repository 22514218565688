import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import SelectorField from "../../components/selector-field";
import { IClientTypes } from "../../gateways/clients-types.interfaces";
import { IUpdateClientDto } from "../../gateways/clients.interfaces";
import { emailValidationError } from "../../utils/utils";
import { FormHelperText } from "@material-ui/core";

interface IClientDetailProps {
  item: IUpdateClientDto;
  clientTypes: IClientTypes[];
  onChange: (name: string, value: any) => void;
  validateRequired: (value: any) => boolean;
}

const ClientForm: FC<IClientDetailProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <FormTextField
        autoFocus
        fullWidth
        label={t("common.fullName")}
        name="fullname"
        required
        validator={props.validateRequired}
        errorText={t("messages.requiredField") as string}
        type="text"
        value={props.item.fullname}
        onChange={props.onChange}
      />
      <FormTextField
        fullWidth
        label={t("common.address")}
        name="address"
        // required
        // validator={props.validateRequired}
        // errorText={t('messages.requiredField')}
        type="text"
        value={props.item.address}
        onChange={props.onChange}
      />
      <FormTextField
        fullWidth
        label={t("common.internal_email")}
        name="email"
        type="email"
        value={props.item.email ?? ""}
        onChange={props.onChange}
        error={emailValidationError(props.item.email)}
        helperTextMessage={`Si hay más de 1 correo, separar con punto y coma, ejemplo: test1@test.com; test2@test.com`}
      />

      <FormTextField
        fullWidth
        label={t("common.client_email")}
        name="client_email"
        type="email"
        value={props.item.client_email ?? ""}
        onChange={props.onChange}
        error={emailValidationError(props.item.client_email)}
        helperTextMessage={`Si hay más de 1 correo, separar con punto y coma, ejemplo: test1@test.com; test2@test.com`}
      />

      <FormTextField
        fullWidth
        label={t("common.client_email_domain")}
        name="client_email_domain"
        type="email"
        value={props.item.client_email_domain ?? ""}
        onChange={props.onChange}
        helperTextMessage={`Añadir dominio general del correo del cliente, por ejemplo, si el correo es: mi_correo@enovam.com, pues sería: enovam.com`}
      />

      <SelectorField
        name="clientTypeId"
        fullWidth
        inputLabel={t("clientAddDialog.clientType")}
        onChange={props.onChange}
        required
        validator={props.validateRequired}
        value={props.item.clientTypeId}
        values={props.clientTypes.map((item) => {
          return { id: item.id, value: item.clientTypeName };
        })}
        emptyValue={false}
      />
    </div>
  );
};

export default ClientForm;
