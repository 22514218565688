import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IClient, IUpdateClientDto, IClientFindAll } from "./clients.interfaces";

@inject('axios')
export class ClientsGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(request: IClientFindAll): Promise<IClient[]> {
        const response = await this.axios.get('clients', {
            params: request
        });
        return response.data;
    }

    async findAllFilter(input: string): Promise<string[]> {
        const response = await this.axios.get(`clients/filter/${input}`);
        return response.data;
    }

    async findById(id: number): Promise<IClient> {
        const response = await this.axios.get(`clients/${id}`);
        return response.data;
    }

    async findClientEmail(id: number): Promise<string> {
        const response = await this.axios.get(`clients/clientEmail/${id}`);
        return response.data;
    }

    async create(client: Partial<IClient>): Promise<IClient | any> {
        const response = await this.axios.post('clients', client);
        return response.data;
    }

    async update(id: string, updateClientDto: IUpdateClientDto): Promise<IClient | any> {
        const response = await this.axios.put(`clients/${id}`, updateClientDto);
        return response.data;
    }
}
