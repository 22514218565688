import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IFuelSupplyRate } from "./fuel-supply-rates.interface";


@inject('axios')
export class FuelSupplyRatesGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async findAll(): Promise<IFuelSupplyRate[]> {
        const response = await this.axios.get('fuel-supply-rates');
        return response.data;
    }

    public async findById(id: number): Promise<IFuelSupplyRate>{
        const url = `fuel-supply-rates/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }
}