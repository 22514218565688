import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IProduct } from "./products.interface";

@inject('axios')
export class ProductsGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IProduct[]> {
        const response = await this.axios.get('products');
        return response.data;
    }
}