import React, { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { TreeItem } from "@material-ui/lab";
import { SuppliesGateway } from "../../gateways/supplies.gateway";
import { ISuppliesByClientId } from "../../gateways/clients.interfaces";

const useStyles = makeStyles(() => ({
    item: {
        padding: 0,
        margin: 0,
        fontWeight: 'normal'
    }
}));

interface IClientPeekSuppliesFormProps {
    clientId: string;
}

const ClientPeekSuppliesForm: FC<IClientPeekSuppliesFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [data, setData] = useState<ISuppliesByClientId[]>([]);
    const SupplyGateway = useIoC(SuppliesGateway);
    const history = useHistory();
    let nodeTreeId = `${props.clientId}/supplies/`;

    const computeUrl = (item: ISuppliesByClientId, computeType: "company" | "supply" | "establishment"): string => {

        switch (computeType) {
            default:
            case "company":
                return `companies/${item.company_id}`;
            case "supply":
                if (item.complex_id)
                {
                    if (item.supply_type == "electric")
                        return `complex/${item.complex_id}/electricSupply/${item.supply_id}`;
                    else if (item.supply_type == "fuel")
                        return `complex/${item.complex_id}/fuelSupply/${item.supply_id}`;
                    else if (item.supply_type == "water")
                        return `complex/${item.complex_id}/waterSupply/${item.supply_id}`;
                }
                else
                {
                    if (item.supply_type == "electric")
                        return `establishments/${item.establishment_id}/electricSupply/${item.supply_id}`;
                    else if (item.supply_type == "fuel")
                        return `establishments/${item.establishment_id}/fuelSupply/${item.supply_id}`;
                    else if (item.supply_type == "water")
                        return `establishments/${item.establishment_id}/waterSupply/${item.supply_id}`;
                }
            case "establishment":
                return `establishments/${item.establishment_id}`;
        }

    }

    useEffect(() => {
        (async () => {
            if (props.clientId) {
                const { clientId } = props;
                const data = await SupplyGateway.findById(Number(clientId));
                setData(data);
            }
        })()

    }, [SupplyGateway])

    const handleStopPropagation = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
    }, [])

    const getCleanEmail = (email: string | null) => {
        if (email)
        {
            if (email.length > 0)
                return email;
            else
                return 'Vacío';
        } else
            return 'Vacío';
    }

    return (
        <Box>
            <h5>Suministros: {data.length}</h5>
            {
                data.length > 0 &&
                <TreeItem nodeId={nodeTreeId} label={t('rightNavigation.all_supplies')}>
                    {
                        data.map((item) => {
                            return (
                                <TreeItem nodeId={nodeTreeId + item.supply_reference} label={<Typography style={{ fontWeight: "bold" }}>Cups: {item.supply_reference}</Typography>}>
                                    <TreeItem
                                        nodeId={item.client_name + item.supply_reference + item.supply_type}
                                        label={<a onClick={handleStopPropagation} href={computeUrl(item, "company")} target="_blank">
                                            <Typography>Nombre Empresa: {item.company_name}</Typography>
                                            <Typography>Cif: {item.company_cif}</Typography>
                                        </a>}>
                                    </TreeItem>
                                    <TreeItem
                                        nodeId={item.client_name + item.supply_reference + item.supply_type}
                                        label={'Emails Cliente'
                                        }>
                                        <TreeItem
                                            nodeId={item.client_name + item.supply_reference + item.supply_type + "clientemails"}
                                            label={<>
                                                <Typography>
                                                    <a onClick={handleStopPropagation} href={computeUrl(item, "supply")} target="_blank">Email Cliente Cups: {getCleanEmail(item.supply_client_email)}</a>
                                                </Typography>
                                                <Typography>
                                                    <a onClick={handleStopPropagation} href={computeUrl(item, "company")} target="_blank">Email Cliente Empresa: {getCleanEmail(item.company_client_email)}</a>
                                                </Typography>
                                                <Typography>
                                                    <a onClick={handleStopPropagation} href={computeUrl(item, "establishment")} target="_blank">Email Cliente Establecimiento: {getCleanEmail(item.establishment_client_email)}</a>
                                                </Typography>
                                            </>
                                            }>
                                        </TreeItem>
                                    </TreeItem>
                                </TreeItem>
                            )
                        })
                    }
                </TreeItem>
            }
        </Box>
    );
};

export default ClientPeekSuppliesForm;