import React, { FC } from "react";
import { makeStyles, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import ComplexIcon from '@material-ui/icons/Dashboard';
import { IComplex } from "../../gateways/complex.interfaces";

interface IComplexItemProps {
    item: IComplex;
    showDiscarded: boolean;
    onClickItem: (item: IComplex) => void;
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    discardedComplex: {
        color: "#E0E0E0"
    },
    nonDiscardedComplex: {
        color: "black"
    }
}));

const ComplexItem: FC<IComplexItemProps> = (props) => {
    const classes = useStyles();

    const color = props.showDiscarded ? classes.discardedComplex: classes.nonDiscardedComplex;

    return (
        <ListItem alignItems="flex-start" className={classes.listItem} onClick={() => { props.onClickItem(props.item)} }>
            <ListItemAvatar >
                <ComplexIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
            </ListItemAvatar>
            <ListItemText primary={props.item.name} secondary={props.item.direction} className={color}/>
        </ListItem>
    );
};

export default ComplexItem;