import React, { FC, useMemo, useState, useEffect } from "react";
import { TextField, InputAdornment, PropTypes, Box } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import throttle from 'lodash/throttle';

interface IFormTextFieldAutoComplete {
    options?: string[];
    value: string;
    type: string;
    validator?: (value: any) => boolean;
    errorText?: string;
    fullWidth?: boolean;
    required?: boolean;
    onChange: (name: string, value: any) => void;
    name: string;
    label: string;
    autoFocus?: boolean;
    adornmentLabel?: string;
    margin?: PropTypes.Margin;
    disabled?: boolean;
    className?: string;
    multiline?: boolean;
    onLoadOptionsAsync?: (input: string) => Promise<string[]>;
    numberOfChars?: number;
}

const FormTextFieldAutoComplete: FC<IFormTextFieldAutoComplete> = (props) => {
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');
    const [options, setOptions] = useState<string[]>([]);

    const fetch = useMemo(
        () =>
            throttle((request: { input: string }) => {
                return props.onLoadOptionsAsync
                    ? props.onLoadOptionsAsync(request.input)
                    : Promise.resolve([] as string[]);
            }, 200),
        [props.onLoadOptionsAsync],
    );

    useEffect(() => {
        if ((props.value && props.value.length < (props.numberOfChars ?? 3)) || (props.options && props.options.length > 0)) {
            setOptions([]);
            return;
        }
        const pepe = fetch({ input: props.value });
        if (pepe) {
            pepe.then((results) => {
                if (results) {
                    setOptions(results);
                }
            });
        }
    }, [props.value, fetch, props.numberOfChars]);

    const handleChange = (event: React.ChangeEvent<{}>, value: string | null) => {
        const valueValidated = (props.validator !== null && props.validator !== undefined) ? props.validator(value) : true;
        setError(!valueValidated);
        if (!valueValidated) {
            setHelperText(props.errorText ? props.errorText : '');
        } else {
            setHelperText('');
        }
        props.onChange(props.name, value);
    };

    const handleChangeFreeSolo = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        const { name, value } = event.target;
        const valueValidated = (props.validator !== null && props.validator !== undefined) ? props.validator(value) : true;
        setError(!valueValidated);
        if (!valueValidated) {
            setHelperText(props.errorText ? props.errorText : '');
        } else {
            setHelperText('');
        }
        props.onChange(name!, value);
    };

    return (
        <Box pb={1.25}>
            <Autocomplete
                freeSolo={true}
                id={props.label}
                options={props.options ? props.options : options}
                style={{ width: 300 }}
                onChange={handleChange}
                renderInput={
                    (params) =>
                        <TextField
                            {...params}
                            onChange={handleChangeFreeSolo}
                            value={props.value}
                            type={props.type}
                            error={error}
                            helperText={helperText}
                            fullWidth={props.fullWidth}
                            required={props.required}
                            name={props.name}
                            label={props.label}
                            autoFocus={props.autoFocus}
                            margin={props.margin}
                            disabled={props.disabled}
                            className={props.className}
                            multiline={props.multiline}
                        />
                }
            />
        </Box>);
};

export default FormTextFieldAutoComplete;