import React, {
  FC,
  useEffect,
  useState,
  useMemo,
  Fragment,
  useCallback,
} from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  makeStyles,
  CircularProgress,
  Box,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  TextField,
  debounce,
} from "@material-ui/core";
import SecurityIcon from "@material-ui/icons/Security";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import AddIcon from "@material-ui/icons/Add";
import AndroidIcon from "@material-ui/icons/Android";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { withRouter, RouteComponentProps } from "react-router";
import Fab from "@material-ui/core/Fab";
import useIoC from "../../contexts/ioc.context";
import AlertBox from "../../components/alert-box";
import { useNavigator } from "../../contexts/navigator.context";
import SupplyBotsIcon from "@material-ui/icons/Autorenew";
import { BotsGateway } from "../../gateways/bots.gateway";
import { IBotPreconfigRO } from "../../gateways/bots.interfaces";
import PreconfigCustomDialog from "./preconfig-custom-dialog";

const useStyles = makeStyles((theme) => ({
  listItem: {
    cursor: "pointer",
  },
  inline: {
    display: "inline",
  },
  itemAvatarIcon: {
    // backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
  fabAdd: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(10),
    zIndex: theme.zIndex.drawer + 1,
  },
  fabCustomRun: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.drawer + 1,
  },
  progress: {
    margin: theme.spacing(2),
  },
  loaderContainer: {
    textAlign: "center",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: "46%",
  },
}));

const SupplyBotList: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const [, navigatorDispatch] = useNavigator();
  const botsGateway = useIoC(BotsGateway);
  const [data, setData] = useState<IBotPreconfigRO[]>([]);
  const [dataResult, setDataResult] = useState<IBotPreconfigRO[]>([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const isEmpty = useMemo(() => !loading && data.length === 0, [loading, data]);
  const handleNavigateDetail = (item: IBotPreconfigRO) => {
    history.push("/preconfig-bots/" + item.id);
  };
  const [searchParam, setSearchParam] = useState("");

  const handleAdd = () => {
    history.push("/preconfig-bots/create");
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const preconfigBotsGateway = useIoC(BotsGateway);

  const handleClickBot = (botToProcess: IBotPreconfigRO) => {
    if (botToProcess.enabled === true) {
      (async () => {
        try {
          await preconfigBotsGateway.launchBotProvider(botToProcess);
          snackbar.enqueueSnackbar(t("messages.botAccess"), {
            variant: "success",
          });
        } catch (e) {
          const er = e as any;
          const message = er.validation || er.message;
          snackbar.enqueueSnackbar(message, {
            variant: "error",
          });
        }
      })();
    } else {
      (async () => {
        try {
          snackbar.enqueueSnackbar(t("messages.botDisabled"), {
            variant: "warning",
          });
        } catch (e) {
          const er = e as any;
          const message = er.validation || er.message;
          snackbar.enqueueSnackbar(message, {
            variant: "error",
          });
        }
      })();
    }
  };

  useEffect(() => {
    navigatorDispatch({
      type: "set-header",
      header: {
        title: "Suministros (bots)",
        icon: SupplyBotsIcon,
      },
    });
  }, [navigatorDispatch]);

  const updateResultItems = useCallback(() => {
    let items = data;

    if (searchParam !== "") {
      const mySearch = searchParam.toLowerCase();
      items = items.filter(
        (item) =>
          item.alias.toLowerCase().includes(mySearch) ||
          item.fields.filter(
            (itemField) =>
              itemField.field.toLowerCase() === "username" &&
              itemField.value.toLowerCase().includes(mySearch)
          ).length > 0
      );
      return items;
    }

    return items;
  }, [searchParam, data]);

  useEffect(() => {
    setDataResult(updateResultItems);
  }, [updateResultItems]);

  const renderItem = (item: IBotPreconfigRO, index: number) => {
    const handleNavigateWraped = () => handleNavigateDetail(item);
    return (
      <Fragment key={index}>
        <ListItem
          alignItems="flex-start"
          // onClick={handleNavigateWraped}
          className={classes.listItem}
        >
          <ListItemAvatar>
            {/* <SecurityIcon
              color="action"
              fontSize="large"
              className={classes.itemAvatarIcon}
            /> */}
             <IconButton
              // edge="end"
              aria-label="bot"
              className={classes.itemAvatarIcon}
              color={item.enabled ? "primary" : "default"}
              onClick={() => {
                handleClickBot(item);
              }}
            >
              <PlayCircleFilledIcon style={{ fontSize: 35 }} />
            </IconButton>
          </ListItemAvatar>
          <ListItemText
            onClick={handleNavigateWraped}
            primary={`${item.alias}`}
            secondary={
              <React.Fragment>
                <Typography>{item.bot.name}</Typography>
              </React.Fragment>
            }
          />
          {/* <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="bot"
              color={item.enabled ? "primary" : "default"}
              onClick={() => {
                handleClickBot(item);
              }}
            >
              <PlayCircleFilledIcon style={{ fontSize: 35 }} />
            </IconButton>
          </ListItemSecondaryAction> */}
        </ListItem>
        {index < data.length - 1 ? (
          <Divider variant="inset" component="li" />
        ) : null}
      </Fragment>
    );
  };

  const handleSearchChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = debounce((e) => {
    setSearchParam(e.target.value);
  }, 500);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let items = await botsGateway.findAllPreconfig();
        setData(items);
        setSearchParam("");
        setDataResult(items);
        setLoading(false);        
      } catch (e) {
        const er = e as any;
        const message = er.validation || er.message || "Ha ocurrido un error no controlado al obtener la lista, vuelvelo a intentar más tarde";
        snackbar.enqueueSnackbar(message, {
          variant: "error",
        });
      }
    })();
  }, [botsGateway]);

  return (
    <Box>
      {/* Barra de busqueda */}
      <Paper component="form" className={classes.root}>
        <TextField
          id="obj-search"
          className={classes.input}
          label="Buscador"
          type="search"
          onChange={(e) => {
            e.persist();
            handleSearchChange(e);
          }}
        />
      </Paper>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fabAdd}
        onClick={handleAdd}
      >
        <AddIcon />
      </Fab>
      <Fab
        color="primary"
        aria-label="Custom bot execution"
        className={classes.fabCustomRun}
        onClick={handleOpenDialog}
      >
        <AndroidIcon />
      </Fab>
      <PreconfigCustomDialog open={openDialog} onClose={handleCloseDialog} />
      {loading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.progress} />
        </div>
      ) : null}

      {isEmpty ? (
        <AlertBox variant="info">No se han encontrado registros</AlertBox>
      ) : null}

      {data && <h3>Bots: {dataResult.length}</h3>}
      {loading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.progress} />
        </div>
      ) : null}

      <List>{dataResult.map(renderItem)}</List>
    </Box>
  );
};

export default withRouter(SupplyBotList);
