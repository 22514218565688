import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IDashboardInfo, BotTrackDto, IClientSuppliesInterface } from "./dashboard.interfaces";

@inject('axios')
export class DashboardGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async findAllByClientId(id: number): Promise<IDashboardInfo[]> {
        try {
            const response = await this.axios.get(`dashboard/${id}`);
            return response.data;
        } catch (e) {
            console.log(e);
            return []
        }
    }

    public async getClientBotExecutionState(clientId: number): Promise<number> {
        const response = await this.axios.get(`dashboard/clientBotExecutionState/${clientId}`);
        return response.data;
    }

    public async getClientBotSuppliesExecutionState(clientId: number): Promise<IClientSuppliesInterface> {
        const response = await this.axios.get(`dashboard/clientBotSuppliesExecutionState/${clientId}`);
        return response.data;
    }

    public async getBotMessages(clientId: number, supplyId: number, fuelSupply?: boolean): Promise<BotTrackDto[]> {
        try {
            const response = fuelSupply ? await this.axios.get(`dashboard/getFuelMessages/${clientId}/${supplyId}`) :
                await this.axios.get(`dashboard/getMessages/${clientId}/${supplyId}`);
            return response.data;
        } catch {
            return [];
        }
    }
}