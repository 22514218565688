import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TableHead, TableRow, TableCell, Table, TableBody, Button, makeStyles, TableHeadProps, Chip, TableBodyProps } from "@material-ui/core";
import { initialValidationGroup, IValidationGroup } from "../../gateways/validation-group.interfaces";
import { IValidationContext, initialValidContext } from "../../gateways/validation-context.interfaces";
import ValidationGroupDeleteDialog from "./validation-group-delete-dialog";
import { IValidationCriteria } from "../../gateways/validation-criteria.interfaces";
import ValidationGroupAddDialog from "./validation-group-add-dialog";
import ValidationGroupUpdateDialog from "./validation-group-update-dialog";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    actionButton: {
        marginLeft: theme.spacing(1)
    }, chip: {
        margin: theme.spacing(0.5)
    }
}));

interface ValidationGroupTableProps {
    validationContext: IValidationContext;
    validationGroups: IValidationGroup[];
    onClickRules: (validationGroup: IValidationGroup) => void
};
interface ValidationGroupTablePropertiesCriteriaProps {
    validationCriterias: IValidationCriteria[];
};

const ValidationGroupTable: FC<ValidationGroupTableProps> = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [validationGroups, setValidationGroups] = useState<IValidationGroup[]>([]);
    const [validationGroup, setValidationGroup] = useState<IValidationGroup>(initialValidationGroup);
    const [validationContext, setValidationContext] = useState<IValidationContext>(initialValidContext);

    const handleShowAddValidationGroupDialog = () => {
        setOpenAddDialog(true);
    };

    const handleOpenDeleteValidationGroup = (validationGroup: IValidationGroup) => {
        setOpenDeleteDialog(true);
        setValidationGroup(validationGroup);
    };

    const handleDeleteValidationGroup = (validationGroup: IValidationGroup) => {
        const copy = validationGroups.filter(item => item.id !== validationGroup.id);
        setValidationGroups(copy);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };
    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
    };

    const handleCreate = (item: IValidationGroup) => {
        setValidationGroups([...validationGroups, item]);
        setOpenAddDialog(false);
    };
    const handleUpdate = (item: IValidationGroup) => {
        const indexItemUpdated = validationGroups.findIndex((validationGroup)=> item.id === validationGroup.id);
        validationGroups[indexItemUpdated] = item;
        setValidationGroups([...validationGroups]);
        setOpenUpdateDialog(false);
    };

    const handleUpdateValidationGroup = (item: IValidationGroup) => {
        setValidationGroup(item);
        setOpenUpdateDialog(true);
    };

    const ValidationGroupTablePropertiesCriteria: FC<ValidationGroupTablePropertiesCriteriaProps> = ({ validationCriterias }) => {
        return (<>{
            validationCriterias.length !== 0 ? 
                validationCriterias.map((value, index) => {
                return (
                    <Chip
                        key={index}
                        label={value.nameProperty}
                        className={classes.chip}
                    />
                );
            }) : <Chip
                    label={t('common.none')}
                    className={classes.chip}
                />
        }
        </>);
    };


    const ValidationGroupTableHeaderText: FC = () => {
        return (<>
            {
                Object.keys(initialValidationGroup).map((property, index) => (
                    <TableCell>{t(`validationGroupTable.${property}`)}</TableCell>
                ))
            }
        </>);
    };

    const ValidationGroupTableHeader: React.ComponentType<TableHeadProps> = () => {
        return (
            <TableHead>
                <TableRow>
                    <ValidationGroupTableHeaderText />
                </TableRow>
            </TableHead>
        );
    };

    const ValidationGroupTableBody: React.ComponentType<TableBodyProps> = () => {
        return (
            <TableBody>
                {validationGroups.map((validationGroup) => (<>
                    <TableRow>
                        <TableCell>{validationGroup.id}</TableCell>
                        <TableCell>{validationGroup.name}</TableCell>
                        <TableCell>
                            <ValidationGroupTablePropertiesCriteria
                                validationCriterias={validationGroup.validationCriterias}
                            />
                        </TableCell>
                        <TableCell align="right">
                            <Button variant="outlined" size="small" className={classes.actionButton} color="primary"
                                onClick={() => handleUpdateValidationGroup(validationGroup)}>
                                {t('common.modify')}
                            </Button>
                            <Button variant="outlined" size="small" className={classes.actionButton} color="primary"
                                onClick={() => props.onClickRules(validationGroup)}>
                                {t('common.rules')}
                            </Button>
                            <Button variant="outlined" size="small" className={classes.actionButton} color="secondary"
                                onClick={() => handleOpenDeleteValidationGroup(validationGroup)}>
                                {t('common.remove')}
                            </Button>
                        </TableCell>
                    </TableRow>
                </>))}
            </TableBody>
        );
    };

    useEffect(() => {
        (async () => {
            setValidationContext(props.validationContext);
            if (props.validationGroups) {
                setValidationGroups(props.validationGroups);
            }
        })();
    }, [props]);

    return (<>
        <div>
            <Button variant="outlined" onClick={handleShowAddValidationGroupDialog}>{t('validationGroupTable.addValidation')}</Button>
        </div>
        <Table >
            <ValidationGroupTableHeader />
            <ValidationGroupTableBody />
        </Table>

        <ValidationGroupAddDialog
            open={openAddDialog}
            validationContext={validationContext}
            onCreate={handleCreate}
            onClose={handleCloseAddDialog}
        />
        <ValidationGroupUpdateDialog
            open={openUpdateDialog}
            validationGroup={validationGroup}
            onUpdate={handleUpdate}
            onClose={handleCloseUpdateDialog}
        />

        <ValidationGroupDeleteDialog
            open={openDeleteDialog}
            item={validationGroup}
            onDeleted={handleDeleteValidationGroup}
            onClose={handleCloseDeleteDialog}
        />
    </>)
}
export default ValidationGroupTable;