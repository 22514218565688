import React, { FC, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  Theme,
  createStyles,
  Box,
} from "@material-ui/core";
import FormTextField from "../../components/form-text-field";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import { ComplexGateway } from "../../gateways/complex.gateway";
import { CompanyGateway } from "../../gateways/company.gateway";
import ICompany, { initialICompany } from "../../gateways/company.interface";
import CountryGateway from "../../gateways/country.gateway";
import ICountry from "../../gateways/country.interfaces";
import SelectorField from "../../components/selector-field";
import {
  IEstablishment,
  initialIEstablisment,
} from "../../gateways/establishment.interface";
import { EstablishmentGateway } from "../../gateways/establishment.gateway";
import { IComplex } from "../../gateways/complex.interfaces";
import { EstablishmentTypeGateway } from "../../gateways/establishment-type.gateway";
import { IEstablishmentType } from "../../gateways/establishment-type.interface";
import EstablishmentForm from "./establishment-form";
import CompanyAddDialog from "../companies/company-add-dialog";
import ComplexUpsertDialog from "../complex/complex-upsert-dialog";
import EstablishmentTypeAddDialog from "../establishment-type/establishment-type-add-dialog";
import CoordinatesGateway from "../../gateways/coordinates.gateway";
import {
  Coordinate,
  ICoordinatesReturn,
  initialCoordinatesReturn,
} from "../../gateways/coordinates.interfaces";

interface IEstablishmentUpsertDialogProps {
  open: boolean;
  item: IEstablishment | undefined;
  clientId: number;
  companyId?: number;
  complexId?: number;
  onItemUpsert: (item: IEstablishment, isNew: boolean) => void;
  onLoading: (value: boolean) => void;
  onClose: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
    },
    button: {
      margin: theme.spacing(0.5),
    },
    textField: {
      margin: theme.spacing(0.5),
    },
  })
);

const initialCompanies: ICompany[] = [];
const initialComplex: IComplex[] = [];

const EstablishmentUpsertDialog: FC<IEstablishmentUpsertDialogProps> = (
  props
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  const [item, setItem] = useState<IEstablishment>(initialIEstablisment);
  const [establishmentsTypes, setEstablishmentsTypes] = useState<
    IEstablishmentType[]
  >([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [complex, setComplex] = useState<IComplex[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);

  const snackbar = useSnackbar();
  const establishmentGateway =
    useIoC(EstablishmentGateway);
  const establishmentTypeGateway = useIoC(
    EstablishmentTypeGateway
  );
  const companyGateway = useIoC(CompanyGateway);
  const countryGateway = useIoC(CountryGateway);
  const complexGateway = useIoC(ComplexGateway);
  const coordinatesGateway = useIoC(CoordinatesGateway);

  const [openCreateEstablishmentType, setOpenCreateEstablishmentType] =
    useState(false);
  const [openCreateCompany, setOpenCreateCompany] = useState(false);
  const [openCreateComplex, setOpenCreateComplex] = useState(false);

  const [coordinatesData, setCoordinatesData] = useState<Coordinate>(
    {latitude:39.59630865176704, longitude: 2.6565865248007134 })

  const [coordinates, setCoordinates] = useState<string>("");
  const [ direction, setDirection ] = useState("");
  const [ loading, setLoading] = useState(false);

  const handleOpenEstablishmentTypeDialog = () => {
    setOpenCreateEstablishmentType(true);
  };

  const handleCloseEstablishmentTypeDialog = () => {
    setOpenCreateEstablishmentType(false);
  };

  const handleCreateEstablishmentType = (item: IEstablishmentType) => {
    setEstablishmentsTypes([...establishmentsTypes, item]);
  };

  const handleOpenCompanyDialog = () => {
    setOpenCreateCompany(true);
  };

  const handleCloseCompanyDialog = () => {
    setOpenCreateCompany(false);
  };

  const handleCreateCompany = (item: ICompany) => {
    if (!props.companyId) {
      setCompanies([...companies, item]);
    }
  };

  const handleOpenComplexDialog = () => {
    setOpenCreateComplex(true);
  };

  const handleCloseComlexDialog = () => {
    setOpenCreateComplex(false);
  };

  const handleCreateComplex = (item: IComplex) => {
    if (!props.complexId) {
      setComplex([...complex, item]);
    }
  };

  const handleSetDirection = (name: string, value: string) => {
    handleChange(name, value);
    setDirection(value);
  }

  const handleSetCoordinates = (name: string, value: string ) => {
    handleChange(name, value);
    setCoordinates(value);
  }

  const createEstablishment = async (item: IEstablishment) => {

    let changeCoordinates = '';
    
    if (item.address.trim() !== '' && typeof item.address !== "undefined"){
      const addressCoordinates = await coordinatesGateway.getCoordinates(item.address);
            changeCoordinates = `${addressCoordinates.lat}, ${addressCoordinates.lng}`
    }

    const regex = /^(?<latitude>[^,]+),[\s]+(?<longitude>[^,]+)$/;
    const coordinatesRegex = regex.exec(changeCoordinates);
    const createdCoordinates: ICoordinatesReturn =
      coordinatesRegex && coordinatesRegex.groups
        ? await coordinatesGateway.create({
            latitude: parseFloat(coordinatesRegex.groups.latitude),
            longitude: parseFloat(coordinatesRegex.groups.longitude),
          })
        : initialCoordinatesReturn;
      
    if(typeof item.complexId !== "number") item.complexId = null;     
    if(item.brandName.length === 0 ) item.brandName = " ";  
    props.onLoading(true);
    setLoading(true);
    const establishment = await establishmentGateway.create({
      ...item,
      coordinatesId: createdCoordinates.id ?? null,
    });
    snackbar.enqueueSnackbar(
      t("establishmentUpsertDialog.createSucceded", {
        id: establishment.id,
        alias: establishment.name,
      }),
      { variant: "success" }
    );
    props.onItemUpsert(establishment, true);
    props.onLoading(false);
    setLoading(false);
  };

  const updateEstablishment = async (item: IEstablishment) => {
    setLoading(true);
    const establishment = await establishmentGateway.update(item.id, item);
    snackbar.enqueueSnackbar(
      t("establishmentUpsertDialog.updateSucceded", {
        id: establishment.id,
        name: establishment.name,
      }),
      { variant: "success" }
    );
    props.onItemUpsert(establishment, false);
    setLoading(false);
  };

  const upsertCurrency = async (item: IEstablishment) => {
    try {
      setLoading(true);
      if (!props.item) {
        await createEstablishment(item);
      } else {
        await updateEstablishment(item);
      }

    } catch (e) {
      const key = "establishmentErrorHandler." + (e as any).response.data.key;
      if ((e as any).response.data.key === "creatingEntityError") {
        const message = "validation" in (e as any) ? (e as any).validation : t(key);
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else if ((e as any).response.data.key === "updatingError") {
        const message =
          "validation" in (e as any) ? (e as any).validation : t(key, { id: (e as any).response.data.id });
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else {
        const message =
          "validation" in (e as any) ? (e as any).validation : t("messages.defaultError");
        snackbar.enqueueSnackbar(message, { variant: "error" });
      }

    } finally {
      setLoading(false);
      props.onClose();
    }
  };

  const handleAccept = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (
      !item.name ||
      !item.address ||
      !item.establishmentTypeId
      // !coordinates
    ) {
      event.preventDefault();
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      return;
    }

    upsertCurrency(item);
  };

  const handleChange = (name: string, value: any) => {
    setItem({ ...item, [name]: value });
    if (name === "companyId") {
      (async () => {
        const complex = await complexGateway.findAll({ companyId: value });
        setComplex(complex);
      })();
    }
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  useEffect(() => {
    if (props.item) {
      setItem(props.item);
    } else {
      setItem(initialIEstablisment);
    }
  }, [props.item, props.open]);

  useEffect(() => {
    (async () => {
      setCoordinates("");
      if (props.complexId) {
        const complex = await complexGateway.findById(props.complexId);
        setComplex([...initialComplex, complex]);

        const company = await companyGateway.findById(complex.companyId);
        setCompanies([...initialCompanies, company]);
        setItem({ ...item, 
                  id: 0,
                  name: '',
                  establishmentTypeId: 0,
                  address: '',
                  companyId: 0,
                  sendBeforeValidating: true,
                  complexId: complex.id 
        });
      } else if (props.companyId) {
        const company = await companyGateway.findById(props.companyId);
        setCompanies([...initialCompanies, company]);
        setItem({ ...item, companyId: company.id });
      } else if (props.clientId) {
        const companies = await companyGateway.findAll({
          clientId: props.clientId,
        });
        setCompanies(companies);
      }
    })();
  }, [companyGateway, props.open]);

  useEffect(() => {
    (async () => {
      if (props.complexId) {
        const complex = await complexGateway.findById(props.complexId);
        setComplex([...initialComplex, complex]);
      } else if (props.companyId) {
        const complex = await complexGateway.findAll({
          companyId: props.companyId,
        });
        setComplex(complex);
      } else {
        const complex = await complexGateway.findAll({
          companyId: item.companyId,
        });
        setComplex(complex);
      }
    })();
  }, [item.companyId]);

  useEffect(() => {
    (async () => {
      const establishmentTypes = await establishmentTypeGateway.findAll();
      setEstablishmentsTypes(establishmentTypes);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const countries = await countryGateway.findAll();
      setCountries(countries);
    })();
  }, [props.item, props.open]);

  const handleChangeAddress = async (name: string, value: string) => {
    const addressCoordinates = await coordinatesGateway.getCoordinates(value);
    const coordinates = `${addressCoordinates.lat}, ${addressCoordinates.lng}`
    handleChange(name, value);
    setCoordinates(coordinates);
  };
  
  const handleSendBeforeTelemedidaChange = () => {
    setItem({ ...item, sendBeforeValidating: !item.sendBeforeValidating });
  };

  const handleSetCoordinatesData = (value: Coordinate)=> {
    setCoordinatesData(value)
  }
  return (
    <Box>
      <Dialog
        open={props.open && !loading}
        onClose={() => {
          props.onClose();
        }}
      >
        <DialogTitle>
          {!props.item
            ? t("establishmentUpsertDialog.createTitle")
            : t("establishmentUpsertDialog.updateTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!props.item
              ? t("establishmentUpsertDialog.createContent")
              : t("establishmentUpsertDialog.updateContent")}
          </DialogContentText>
          <EstablishmentForm
            companies={companies}
            complex={complex}
            establishmentsTypes={establishmentsTypes}
            item={item}
            onChange={handleChange}
            validateRequired={validateRequired}
            onClickAddEstablishmentTypeButton={
              handleOpenEstablishmentTypeDialog
            }
            onClickAddCompanyButton={
              props.companyId ? undefined : handleOpenCompanyDialog
            }
            onClickAddComplexButton={
              props.complexId ? undefined : handleOpenComplexDialog
            }
            coordinates={coordinates}
            coordinatesData={coordinatesData}
            handleSetCoordinates={handleSetCoordinates}
            handleSetCoordinatesData={handleSetCoordinatesData}
            handleChangeAddress={handleChangeAddress}
            handleSetDireccion={handleSetDirection}
            onSendBeforeTelemedidaChange={handleSendBeforeTelemedidaChange}
            largeXs={8}
            smallXs={4}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.onClose();
            }}
            color="primary"
          >
            {t("common.cancel")}
          </Button>
          <Button onClick={handleAccept} color="primary">
            {t("common.accept")}
          </Button>
        </DialogActions>
      </Dialog>
      <CompanyAddDialog
        clientId={props.clientId}
        onClose={handleCloseCompanyDialog}
        onItemCreated={handleCreateCompany}
        onLoading={() => loading}
        open={openCreateCompany}
      />
      <ComplexUpsertDialog
        clientId={props.clientId}
        companyId={props.companyId}
        item={undefined}
        onClose={handleCloseComlexDialog}
        onItemUpsert={handleCreateComplex}
        onLoading={() => loading}
        open={openCreateComplex}
      />
      <EstablishmentTypeAddDialog
        onClose={handleCloseEstablishmentTypeDialog}
        onCreated={handleCreateEstablishmentType}
        open={openCreateEstablishmentType}
      />
    </Box>
  );
};

export default EstablishmentUpsertDialog;
