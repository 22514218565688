import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { ContactGateway } from "../../gateways/contact.gateway";
import { ContactEntityType } from "../contacts/contact-list";
import { List, ListItem, Box, makeStyles } from "@material-ui/core";
import IContact from "../../gateways/contact.interface";
import ContactUpdateDialog from "../contacts/contact-update-dialog";
import { TreeItem } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    item: {
        padding: 0,
        margin: 0,
        fontWeight: 'normal'
    }
}));

interface IClientPeekContactsFormProps {
    clientId: string;
    entity: ContactEntityType
    root?: string
}

const ClientPeekContactsSupplyForm: FC<IClientPeekContactsFormProps> = (props) => {
    const { t } = useTranslation();
    const contactGateway = useIoC(ContactGateway);
    const [contacts, setContacts] = useState<IContact[]>([]);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [contact, setContact] = useState<any>();
    let nodeTreeId: string = `client${props.root ? props.root : ''}${props.entity}${props.clientId}`;

    const handleClickItem = (contact: IContact) => {
        setOpenUpdateDialog(true);
        setContact(contact);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
    };

    const handleUpdateContact = (contact: IContact) => {
        let copy = contacts.slice();
        const index = copy.findIndex((value) => value.id === contact.id);
        copy[index] = contact;
        setContacts(copy);
    };
    useEffect(() => {
        (async () => {
            const data = await contactGateway.find({ entity: props.entity, entityId: props.clientId });
            setContacts(data);
        })();
    }, [contactGateway]);

    return (
        <Box>
            {
                contacts.length > 0 &&
                <TreeItem nodeId={nodeTreeId + 'contact'} label={t('rightNavigation.contacts')}>
                    {
                        contacts.map((item) => {
                            return (
                                <TreeItem nodeId={nodeTreeId + 'contact' + item.id.toString()} label={item.fullName} onLabelClick={() => { handleClickItem(item) }} />
                            )
                        })
                    }
                </TreeItem>
            }

            <ContactUpdateDialog open={openUpdateDialog} contact={contact} onClose={handleCloseUpdateDialog} onContactUpdated={handleUpdateContact} />
        </Box >
    );
};

export default ClientPeekContactsSupplyForm;