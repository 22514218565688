import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import { useNavigator } from '../../contexts/navigator.context';
import DescriptionIcon from '@material-ui/icons/Description';
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { ElectricSupplyGateway } from "../../gateways/electric-supply.gateway";
import IRegisterElectricBillUpdate, { IRegisterElectricBill, initialIRegisterElectricBillUpdate } from "../../gateways/register-electric-bill.interface";
import { IPowerDetail, initialIPowerDetail } from "../../gateways/period-power-details.interface";
import { IEnergyDetail, initialIEnergyDetail } from "../../gateways/period-energy-details.interface";
import { datesHandler } from "../../utils/dates-handle";
import { ElectricContractGateway } from "../../gateways/electric-contract.gateway";
import { RegisterElectricBillGateway } from "../../gateways/register-electric-bill.gateway";
import CountryGateway from "../../gateways/country.gateway";
import CurrencyGateway from "../../gateways/currency.gateway";
import { IElectricContract, initialElectricContract } from "../../gateways/electric-contract.interfaces";
import { IElectricBillPowerMaterialTableRows, IElectricBillEnergyMaterialTableRows } from "../../utils/material-table.interface";
import RegisterElectricBillFormDetail from "./register-electric-bill-form-detail";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import { useRouteMatch } from "react-router-dom";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import moment from "moment";
import { ElectricSupplyRatesGateway } from "../../gateways/electric-supply-rates.gateway";
import { initialProviders, IProviders } from "../../gateways/providers.interface";
import { ProvidersGateway } from "../../gateways/providers.gateway";


const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20
        },
    })
);

interface IElectricBillRouteParams {
    electricSupplyId: string;
    clientId: string;
    registerElectricBillId: string;
    complexId: string;
    establishmentId: string;
}

const RegisterElectricBillDetail = () => {
    const classes = useStyles();
    const { electricSupplyId } = useParams<IElectricBillRouteParams>();
    const { clientId } = useParams<IElectricBillRouteParams>();
    const { t } = useTranslation();
    const snackbar = useSnackbar();
    const dateHandler = new datesHandler();
    const { url } = useRouteMatch();

    const [item, setItem] = useState<IRegisterElectricBillUpdate>(initialIRegisterElectricBillUpdate);
    const [, setPowerPastMonth] = useState<IPowerDetail[]>(initialIPowerDetail);
    const [, setPowerPastYear] = useState<IPowerDetail[]>(initialIPowerDetail);
    const [, setEnergyPastMonth] = useState<IEnergyDetail[]>(initialIEnergyDetail);
    const [, setEnergyPastYear] = useState<IEnergyDetail[]>(initialIEnergyDetail);

    const [powerState, setPowerState] = useState<IElectricBillPowerMaterialTableRows[]>([]);
    const [energyState, setEnergyState] = useState<IElectricBillEnergyMaterialTableRows[]>([]);
    const [firstIteration, setFirstIteration] = useState<boolean>(true);
    const [currency, setCurrency] = useState<string>('');
    const [electricContract, setElectricContract] = useState<IElectricContract>(initialElectricContract);
    const [provider, setProvider] = useState<IProviders>(initialProviders)
    const { update: updateBreadCrumbName } = useBreadCrumbName();


    const registerElectricBillGateway = useIoC(RegisterElectricBillGateway);
    const electricContractGateway = useIoC(ElectricContractGateway);
    const providerGateway = useIoC(ProvidersGateway)
    const countriesGateway = useIoC(CountryGateway);
    const currenciesGateway = useIoC(CurrencyGateway);
    const electricSupplyGateway = useIoC(ElectricSupplyGateway);
    const electricSupplyRateGateway = useIoC(ElectricSupplyRatesGateway);

    const [] = useNavigator();

    const { registerElectricBillId } = useParams<IElectricBillRouteParams>();
    const { complexId } = useParams<IElectricBillRouteParams>();
    const { establishmentId } = useParams<IElectricBillRouteParams>();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);

            if (!registerElectricBillId) { return; }
            let coinId;
            let coin;
            if (complexId) {
                try {
                    coinId = await countriesGateway.getCountrieCurrencyComplex(parseInt(complexId));
                    coin = await currenciesGateway.findById(coinId);
                    setCurrency(coin.isoCode);

                } catch (e) {
                    snackbar.enqueueSnackbar(t('currencyErrorHandler.readErrorComplex'), { variant: "warning" });
                }
            } else if (establishmentId) {
                try {
                    coinId = await countriesGateway.getCountrieCurrencyEstablishment(parseInt(establishmentId));
                    coin = await currenciesGateway.findById(coinId);
                    setCurrency(coin.isoCode);
                } catch (e) {
                    snackbar.enqueueSnackbar(t('currencyErrorHandler.readErrorEstablishment'), { variant: "warning" });
                }
            }
            const registerElectricBill = await registerElectricBillGateway.findById(Number.parseInt(registerElectricBillId));
            registerElectricBill.periodEnergyDetails.sort(function (a, b) {
                return a.id - b.id;
            });
            registerElectricBill.periodPowerDetails.sort(function (a, b) {
                return a.id - b.id;
            });

            setItem(registerElectricBill);
            updateBreadCrumbName('registerElectricBill', registerElectricBill.numberBill);

            const contractToUse = await electricContractGateway.findById(registerElectricBill.electricContractId);
            const providerToUse = await providerGateway.findById(contractToUse.providerId);

            var fillPowerStateAid: IElectricBillPowerMaterialTableRows[] = [];
            var fillEnergyStateAid: IElectricBillEnergyMaterialTableRows[] = [];
            for (let i: number = 0; i < 6; i++) {
                fillPowerStateAid[i] = {
                    periodNumber: i + 1,
                    consumedPower: registerElectricBill.periodPowerDetails[i].consumedPower,
                    consumedPowerSecondPeriod: registerElectricBill.periodPowerDetails[i].consumedPowerSecondPeriod,
                    secondaryPowerRate: registerElectricBill.periodPowerDetails[i].secondaryPowerRate,
                    discount: registerElectricBill.periodPowerDetails[i].discount,
                    hiredPower: registerElectricBill.periodPowerDetails[i].hiredPower,
                    powerRate: providerToUse.society.toLowerCase().indexOf('iberdrola') !== -1 ?
                        registerElectricBill.periodPowerDetails[i].powerRate :
                        truncateValue(registerElectricBill.periodPowerDetails[i].powerRate, 5),
                };

                fillEnergyStateAid[i] = {
                    periodNumber: i + 1,
                    consumedEnergy: registerElectricBill.periodEnergyDetails[i].consumedEnergy,
                    secondConsumedEnergy: registerElectricBill.periodEnergyDetails[i].secondConsumedEnergy,
                    secondElectricityCost: registerElectricBill.periodEnergyDetails[i].secondElectricityCost,
                    electricityCost: providerToUse.society.toLowerCase().indexOf('iberdrola') !== -1 ?
                        registerElectricBill.periodEnergyDetails[i].electricityCost :
                        truncateValue(registerElectricBill.periodEnergyDetails[i].electricityCost, 5),
                    reactiveEnergy: registerElectricBill.periodEnergyDetails[i].reactiveEnergy
                }
            };
            fillEnergyStateAid.sort(compare);
            fillPowerStateAid.sort(compare);
            setPowerState(fillPowerStateAid);
            setEnergyState(fillEnergyStateAid);
            setLoading(false);
        })();
    }, []);

    const compare = (objectA: any, objectB: any) => {
        const propertyToCompareA = objectA.periodNumber;
        const propertyToCompareB = objectA.periodNumber;
        let comparison = 0;
        if (propertyToCompareA > propertyToCompareB) {
            comparison = 1;
        } else if (propertyToCompareB < propertyToCompareA) {
            comparison = -1;
        }

        return comparison;
    }

    const truncateValue = (valueToTruncate: number, decimals: number): number => {
        let truncatedValue: number = valueToTruncate;
        truncatedValue = truncatedValue * Math.pow(10, decimals);
        truncatedValue = Math.floor(truncatedValue);
        truncatedValue = truncatedValue / Math.pow(10, decimals);
        return truncatedValue;
    }
    const getPastMonth = (date: Date) => {
        let returnDate = new Date(date.getTime());
        returnDate.setDate(10);
        return new Date(returnDate.setMonth(returnDate.getMonth() - 1));
    }

    const getPastYear = (date: Date) => {
        return new Date(date.setFullYear(date.getFullYear() - 1));
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (item.startingPeriodDate && item.endingPeriodDate
                && !dateHandler.checkLimits(item.startingPeriodDate, item.endingPeriodDate) && !firstIteration) {
                snackbar.enqueueSnackbar(t('messages.dateRangeError'), { variant: "error" });
                setLoading(false);
                return;
            }
            if (!firstIteration) {
                await checkBillOverlap();
            }
            setLoading(false);
        })();

    }, [item.startingPeriodDate, item.endingPeriodDate]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const contract = await electricContractGateway.findByBillDate(item.startingPeriodDate, electricSupplyId);
                setElectricContract(contract);
                setItem({ ...item, electricContractId: contract.id });
                const provider = await providerGateway.findById(contract.providerId);
                setProvider(provider);
            } catch (e) {
                if (!firstIteration) {
                    setItem({ ...item, electricContractId: 0 });
                    snackbar.enqueueSnackbar(t('messages.contractNotFoundError', {
                        day: item.startingPeriodDate.getDate(),
                        month: item.startingPeriodDate.getMonth() + 1,
                        year: item.startingPeriodDate.getFullYear()
                    }), { variant: "error" });
                }
            } finally {
                setFirstIteration(false);
                setLoading(false);
            }
        })();
    }, [item.startingPeriodDate]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const defaultPower: IPowerDetail[] = [];
            const dateCopy = new Date(item.issueDate)
            let pastMonthDate: Date = getPastMonth(new Date(dateCopy.getTime()));
            let pastYearDate: Date = getPastYear(new Date(dateCopy.getTime()));
            for (let i: number = 0; i < 6; i++) {
                defaultPower[i] = { consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, id: 0, powerRate: 0 }
            }

            try {
                const powerDetailPastMonth =
                    await registerElectricBillGateway.findPastPeriodPowerDetailsMonth(pastMonthDate);
                setPowerPastMonth(powerDetailPastMonth);
            } catch (e) {
                setPowerPastMonth(defaultPower)
            }

            try {
                const powerDetailPastYear = await registerElectricBillGateway.findPastPeriodPowerDetailsYear(pastYearDate);
                setPowerPastYear(powerDetailPastYear);
            } catch (e) {
                setPowerPastYear(defaultPower)
            }

            const defaultEnergy: IEnergyDetail[] = [];
            for (let i: number = 0; i < 6; i++) {
                defaultEnergy[i] = { id: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 }
            }

            try {
                const energyDetailPastMonth =
                    await registerElectricBillGateway.findPastPeriodEnergyDetailsMonth(pastMonthDate);
                setEnergyPastMonth(energyDetailPastMonth);
            } catch (e) {
                setEnergyPastMonth(defaultEnergy)
            }

            try {
                const energyDetailPastYear =
                    await registerElectricBillGateway.findPastPeriodEnergyDetailsYear(pastYearDate);
                setEnergyPastYear(energyDetailPastYear);
            } catch (e) {
                setEnergyPastYear(defaultEnergy)
            }

            setLoading(false);
        })();
    }, [item.issueDate]);

    const checkEnergyDetail = () => {
        for (let i: number = 0; i < item.periodEnergyDetails.length; i++) {
            if (
                !item.periodEnergyDetails[i].consumedEnergy ||
                (
                    item.multiplePeriodsBill &&
                    (
                        !item.periodEnergyDetails[i].secondConsumedEnergy ||
                        !item.periodEnergyDetails[i].secondElectricityCost
                    )
                )
                || !item.periodEnergyDetails[i].electricityCost ||
                !item.periodEnergyDetails[i].reactiveEnergy
            ) {
                return false;
            }
        }
        return true;
    };

    const checkPowerDetail = () => {
        for (let i: number = 0; i < item.periodPowerDetails.length; i++) {
            if (
                !item.periodPowerDetails[i].consumedPower ||
                (
                    item.multiplePeriodsBill &&
                    (
                        !item.periodPowerDetails[i].consumedPowerSecondPeriod ||
                        !item.periodPowerDetails[i].secondaryPowerRate
                    )
                )
                || !item.periodPowerDetails[i].powerRate ||
                !item.periodPowerDetails[i].discount ||
                !item.periodPowerDetails[i].hiredPower
            ) {
                return false;
            }
        }
        return true;
    };

    const checkBonification = () => {
        if (item.bonificationIncluded && (!item.bonusImport || !item.bonusBillNumber)) {
            return false
        }
        return true;
    };

    const handleSave = async () => {
        setLoading(true);
        if (!item.numberBill || !item.issueDate || !item.startingPeriodDate
            || !item.endingPeriodDate || !checkBonification() || !item.powerExcessImport
            || !item.importEquipmentRental || !item.electricTax || !item.electricContractId
            || !checkPowerDetail() || !item.powerRateImport || !checkEnergyDetail() ||
            (
                item.multiplePeriodsBill &&
                (
                    !item.firstRangeOfDates ||
                    !item.secondRangeOfDates
                )
            )
            || !item.electricRateBillImport || !item.reactiveImport
            || !item.totalBillImport || !item.totalBillImportIVA) {
            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            setLoading(false);
            return;
        }
        if (!(await checkBillOverlap())) {
            setLoading(false);
            return;
        }

        if (!dateHandler.checkLimits(item.startingPeriodDate, item.endingPeriodDate)) {
            snackbar.enqueueSnackbar(t('messages.dateRangeError'), { variant: "error" });
            setLoading(false);
            return;
        }
        try {
            if (!registerElectricBillId) {
                setLoading(false);
                return;
            }
            await registerElectricBillGateway.update(Number.parseInt(registerElectricBillId), item);

            snackbar.enqueueSnackbar(t('registerElectricBill.updateSucceded', { id: Number.parseInt(registerElectricBillId) }), {
                variant: 'success',
            });
        } catch (e) {
            const er = e as any;
            if (er.response.data.key === 'updatingError') {
                const key = 'registerElectricBillErrorHandler.' + er.response.data.key;
                const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        }
        setLoading(false);
    };

    const checkBillOverlap = async (): Promise<boolean> => {
        const existingBills: IRegisterElectricBill[] =
            await electricSupplyGateway.findByIdRegisterElectricBill(parseInt(electricSupplyId));
        if (!registerElectricBillId) {
            return false;
        }
        for (let i: number = 0; i < existingBills.length; i++) {
            if (dateHandler.checkOverlap(new Date(item.startingPeriodDate),
                new Date(item.endingPeriodDate),
                existingBills[i].startingPeriodDate,
                existingBills[i].endingPeriodDate) && existingBills[i].id !== parseInt(registerElectricBillId)) {
                snackbar.enqueueSnackbar(t('messages.dateOverlapErrorBill', { id: existingBills[i].id }), { variant: "error" });
                return false;
            }
        }
        return true;
    };

    const handleChange = (name: string, value: any) => {
        setItem({ ...item, [name]: value });
    };

    const handleIssueDateSelector = (value: any) => {
        setItem({ ...item, issueDate: value });
    };

    const handleStartingPeriodDateSelector = (value: any) => {
        setItem({ ...item, startingPeriodDate: value });
    };

    const handleEndingPeriodDateSelector = (value: any) => {
        setItem({ ...item, endingPeriodDate: value });
    };

    const handleBonificationIncludedChange = () => {
        setItem({ ...item, bonificationIncluded: !item.bonificationIncluded })
    };

    const handleCompensatedChange = () => {
        setItem({ ...item, compensateInjectEnergy: !item.compensateInjectEnergy })
    };

    const handleEstimatedChange = () => {
        setItem({ ...item, estimated: !item.estimated })
    };

    const handleMultiplePeriodsBillChange = () => {
        setItem({ ...item, multiplePeriodsBill: !item.multiplePeriodsBill })
    };

    const handleChangeDoNothing = () => {

    };

    const handleChangePeriodRegisterElectricBillPower = (updatingArray: IElectricBillPowerMaterialTableRows, index: number) => {
        let updatePeriodsAid: IPowerDetail[] = item.periodPowerDetails.slice();
        updatePeriodsAid[index].consumedPowerSecondPeriod = updatingArray.consumedPowerSecondPeriod;
        updatePeriodsAid[index].secondaryPowerRate = updatingArray.secondaryPowerRate;
        updatePeriodsAid[index].discount = updatingArray.discount;
        updatePeriodsAid[index].consumedPower = updatingArray.consumedPower;
        updatePeriodsAid[index].hiredPower = updatingArray.hiredPower;
        updatePeriodsAid[index].powerRate = updatingArray.powerRate;
        setItem({ ...item, periodPowerDetails: updatePeriodsAid });
    };

    const handleChangePeriodRegisterElectricBillEnergy = (updatingArray: IElectricBillEnergyMaterialTableRows, index: number) => {
        let updatePeriodsAid: IEnergyDetail[] = item.periodEnergyDetails.slice();
        updatePeriodsAid[index].secondConsumedEnergy = updatingArray.secondConsumedEnergy;
        updatePeriodsAid[index].secondElectricityCost = updatingArray.secondElectricityCost;
        updatePeriodsAid[index].consumedEnergy = updatingArray.consumedEnergy;
        updatePeriodsAid[index].electricityCost = updatingArray.electricityCost;
        updatePeriodsAid[index].reactiveEnergy = updatingArray.reactiveEnergy;
        setItem({ ...item, periodEnergyDetails: updatePeriodsAid });
    };

    const FormatDate = (dateString: Date) => {
        const date = new Date(dateString.toString());
        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();

    };
    const electricContractInfo = (electricContract: IElectricContract) => {
        return FormatDate(electricContract.initSupplyDate) + ' - '
            + FormatDate(electricContract.endSupplyDate);
    };

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    const copyPower = (
        periods: number,
        powerState: IElectricBillPowerMaterialTableRows[],
        parameterToCopy: 'hiredPower' | 'consumedPower' | 'powerRate'): string => {
        let stringToReturn = '';
        switch (periods) {
            case (3):
                for (let i: number = 0; i < 3; i++) {
                    stringToReturn =
                        stringToReturn.concat(
                            powerState[i][parameterToCopy] && powerState[i + 3][parameterToCopy] ?
                                Math.max(powerState[i][parameterToCopy], powerState[i + 3][parameterToCopy]).toString().replace(/\./g, ',')
                                :
                                powerState[i][parameterToCopy] ?
                                    powerState[i][parameterToCopy].toString().replace(/\./g, ',')
                                    :
                                    powerState[i + 3][parameterToCopy] ?
                                        powerState[i + 3][parameterToCopy].toString().replace(/\./g, ',')
                                        :
                                        '0',
                            '\n'
                        )
                }
                stringToReturn = stringToReturn + '\n' + '\n' + '\n'
                break;
            case (6):
                for (let i: number = 0; i < 6; i++) {
                    stringToReturn = stringToReturn.concat(
                        powerState[i][parameterToCopy] ?
                            powerState[i][parameterToCopy].toString().replace(/\./g, ',')
                            :
                            '0',
                        '\n'
                    )
                }
                break;
        }
        return stringToReturn;
    }

    const copyEnergy = (
        periods: number,
        energyState: IElectricBillEnergyMaterialTableRows[],
        parameterToCopy: 'consumedEnergy' | 'reactiveEnergy' | 'electricityCost'
    ): string => {
        let stringToReturn: string = '';
        switch (periods) {
            case (3):
                for (let i: number = 0; i < 3; i++) {
                    stringToReturn = stringToReturn.concat(
                        energyState[i][parameterToCopy] && energyState[i + 3][parameterToCopy] ?
                            (parseFloat(energyState[i][parameterToCopy].toString()) +
                                parseFloat(
                                    (provider.society.toLowerCase().indexOf('iberdrola') !== -1 &&
                                        parameterToCopy === 'consumedEnergy') ||
                                        parameterToCopy === 'electricityCost' ?
                                        '0'
                                        :
                                        energyState[i + 3][parameterToCopy].toString()
                                )).toString().replace(/\./g, ',')
                            :
                            energyState[i][parameterToCopy] ?
                                energyState[i][parameterToCopy].toString().replace(/\./g, ',')
                                :
                                energyState[i + 3][parameterToCopy] ?
                                    energyState[i + 3][parameterToCopy].toString().replace(/\./g, ',')
                                    :
                                    '0',
                        '\n'
                    )
                }
                stringToReturn = stringToReturn + '\n' + '\n' + '\n'
                break;
            case (6):
                for (let i: number = 0; i < 6; i++) {
                    stringToReturn = stringToReturn.concat(
                        energyState[i][parameterToCopy] ?
                            energyState[i][parameterToCopy].toString().replace(/\./g, ',')
                            :
                            '0',
                        '\n'
                    )
                }
                break;
        }
        return stringToReturn;
    }

    const handleCopy = async () => {
        try {
            const electricContractRate = await electricSupplyRateGateway.findById(electricContract.electricSupplyRateId);
            const momentInitDate = moment(item.startingPeriodDate);
            const momentEndDate = moment(item.endingPeriodDate);
            navigator.clipboard.writeText(
                `${copyPower(electricContractRate.periods, powerState, 'hiredPower')}`
                +
                `${copyPower(electricContractRate.periods, powerState, 'consumedPower')}`
                +
                `${copyPower(electricContractRate.periods, powerState, 'powerRate')}`
                +
                `${momentEndDate.diff(momentInitDate, 'days')}` + '\n'
                +
                `${copyEnergy(electricContractRate.periods, energyState, 'consumedEnergy')}`
                +
                `${copyEnergy(electricContractRate.periods, energyState, 'reactiveEnergy')}`
                +
                `${copyEnergy(electricContractRate.periods, energyState, 'electricityCost')}`
                +
                `${item.electricRateBillImport.toString().replace(/\./g, ',')}
                 ${item.powerRateImport.toString().replace(/\./g, ',')}
                 ${item.reactiveImport.toString().replace(/\./g, ',')}
                 ${item.powerExcessImport.toString().replace(/\./g, ',')}
                 ${item.electricTax.toString().replace(/\./g, ',')}
                 ${item.importEquipmentRental.toString().replace(/\./g, ',')}
                 ${item.totalBillImport.toString().replace(/\./g, ',')}`
            );
            snackbar.enqueueSnackbar(t('common.copySuccess'), { variant: 'success' });
        } catch (e) {
            snackbar.enqueueSnackbar(t('common.copyError'), { variant: "error" });
        }
    };


    return (
        <ScreenGlobalStructure
            buttonIcon={<SaveIcon />}
            headerIcon={DescriptionIcon}
            matchUrl={url}
            onButtonClick={handleSave}
            title={t('registerElectricBill.title')}
            clientId={clientId ? clientId : ''}
            loading={loading}
            onCopyButtonClick={handleCopy}
        >
            <RegisterElectricBillFormDetail
                onCompensatedChange={handleCompensatedChange}
                onMultiplePeriodsBillChange={handleMultiplePeriodsBillChange}
                currency={currency}
                electricContract={electricContract}
                electricContractInfo={electricContractInfo}
                energyState={energyState}
                item={item}
                onBonificationIncludedChange={handleBonificationIncludedChange}
                onChange={handleChange}
                onChangeDoNothing={handleChangeDoNothing}
                onChangePeriodRegisterElectricBillEnergy={handleChangePeriodRegisterElectricBillEnergy}
                onChangePeriodRegisterElectricBillPower={handleChangePeriodRegisterElectricBillPower}
                onEndingPeriodDateSelector={handleEndingPeriodDateSelector}
                onEstimatedChange={handleEstimatedChange}
                onIssueDateSelector={handleIssueDateSelector}
                onStartingPeriodDateSelector={handleStartingPeriodDateSelector}
                powerState={powerState}
                setEnergyState={setEnergyState}
                setPowerState={setPowerState}
                validateRequired={validateRequired}
            />

        </ScreenGlobalStructure>
    );
};


export default RegisterElectricBillDetail;