import React, { FC } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { SectorGateway } from "../../gateways/sector.gateway";
import { useSnackbar } from "notistack";

interface ISectorDeleteDialogProps {
    open: boolean;
    id: number;
    onItemDelete: (id: number) => void;
    onClose: () => void;
}

const SectorDeleteDialog: FC<ISectorDeleteDialogProps> = (props) => {
    const { t } = useTranslation();
    const sectorGateway = useIoC(SectorGateway);
    const snackbar = useSnackbar();

    const handleDelete = () => {
        (async () => {
            try {
                await sectorGateway.delete(props.id);
                snackbar.enqueueSnackbar(t('sectorDeleteDialog.deleteSucceded', { id: props.id }), { variant: "success" });
                props.onItemDelete(props.id);
            } catch (e) {
                const er = e as any;
                if (er.response.data.key === 'deletingError') {
                    const key = 'sectorErrorHandler.' + er.response.data.key;
                    const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                } else {
                    const message = "validation" in er ? er.validation : t('messages.defaultError');
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                }
            } finally {
                props.onClose();
            }
        })();
    };

    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }}>
            <DialogTitle>{t('sectorDeleteDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('sectorDeleteDialog.content')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onClose() }} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleDelete} color="primary">{t('common.accept')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SectorDeleteDialog;