import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import querystring from 'querystring';
import { IElectricContract, IElectricContractCreate } from "./electric-contract.interfaces";

const electricContractResource = 'electric-contract';
type FindFilterElectricContract = {

    referemce?: string;
    electricSupplyId?: string;
}

@inject('axios')
export class ElectricContractGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(filter: FindFilterElectricContract): Promise<IElectricContract[]> {
        let url = electricContractResource;
        if (filter) {
            const query = querystring.stringify(filter);
            url += `?${query}`;
        }
        const response = await this.axios.get(url);
        return response.data;
    }

    async findById(id: number): Promise<IElectricContract> {
        const url = `${electricContractResource}/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    async create(electricContractToCreate: IElectricContractCreate): Promise<IElectricContract> {
        const response = await this.axios.post(electricContractResource, electricContractToCreate);
        return response.data;
    }

    async update(id: number, electricContractToUpdate: IElectricContractCreate): Promise<IElectricContract> {
        const url = `${electricContractResource}/${id}`;
        const response = await this.axios.put(url, electricContractToUpdate);
        return response.data;
    }

    async delete(id: number): Promise<void> {
        const url = `${electricContractResource}/${id}`;
        const response = await this.axios.delete(url);
        return response.data;
    }

    async findByBillDate(date: Date, electricSupplyId: string): Promise<IElectricContract> {
        const url = `${electricContractResource}/initDate/` + this.formatDate(date) + '/' + electricSupplyId;
        const response = await this.axios.get(url);
        return response.data;
    }

    async addAccountNumber(id: number, accountNumber: string, username: string){
        const url = `${electricContractResource}/add-account-number`;
        const payload = { id, accountNumber, username };
        const response = await this.axios.post(url, payload );
        return response.data;
    }

    async accountHistoryLogs(id:number){
        const url = `${electricContractResource}/account-history-logs`;
        const payload = { id }
        const response = await this.axios.post( url, payload );
        return response.data;
    }

    formatDate = (date: Date) => {
        var dateToReturn: string;
        const dateCopy = new Date(date);
        const day: number = dateCopy.getDate();
        const month: number = dateCopy.getMonth() + 1;
        const year: number = dateCopy.getFullYear();
        dateToReturn = year.toString()
        if (month < 10) {
            dateToReturn = dateToReturn + `-0${month}`
        } else {
            dateToReturn = dateToReturn + `-${month}`
        }

        if (day < 10) {
            dateToReturn = dateToReturn + `-0${day}`
        } else {
            dateToReturn = dateToReturn + `-${day}`
        }
        return dateToReturn;
    };
}