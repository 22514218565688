import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IFuelType } from "./fuel-types.interface";


@inject('axios')
export class FuelTypesGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async findAll(): Promise<IFuelType[]> {
        const response = await this.axios.get('fuel-types');
        return response.data;
    }

    public async findById(id: number): Promise<IFuelType>{
        const url = `fuel-types/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }
}