import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Typography,
  makeStyles
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Map from "../../components/map";
import { ICoordinatesEstCmplx } from "../../gateways/coordinates.interfaces";
import MapIcon from '@material-ui/icons/Map';

interface IMapDialogProps {
  open: boolean;
  onClose: () => void;
  clientType: string;
  locations: ICoordinatesEstCmplx[];
  handleLocations: (cupsArr: string[], checkValue: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: "#B1C904"
  },
  map: {
    padding: '0%',
  },
  title: {
    color: 'white',
  },
  mapIcon: {
    marginRight: '1%',
    marginBottom: '0%'
  }
}));



const MapsDialog: FC<IMapDialogProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [onChangeMT, setOnChangeMT] = useState<boolean>(false) ;
  const [onChangeName, setOnChangeName] = useState<boolean>(true) ;

  const cupsMT = ["ES0031500291508001XM","ES0031500140910001AW","ES0031500303228001XX","ES0031500173521001ZS0F",
                  "ES0031500142250003MR0F","ES0031500141705001EF0F","ES0031500297186001CH","ES0031500298406001AY",
                  "ES0031500298407001TV","ES0031500296745001MC","ES0031500297389001ZC","ES0031500297417001JY",
                  "ES0031500298396001MB","ES0031500297805002TC","ES0031500297807001HH","ES0031500297765001DQ",
                  "ES0031500303032001KQ","ES0031500303179001LT","ES0031500297134001JK","ES0031500297170001SA",
                  "ES0031500297394001WY","ES0031500302142001NR","ES0031500303180001QB","ES0031500673660001EJ",
                  "ES0031500519140001SQ","ES0031500141524001HV","ES0031500171967002TB","ES0031500547421001KD",
                  "ES0031500265514001XF","ES0031500130824001EP","ES0031500132748001TN","ES0031500227185001CR",
                  "ES0031500147228001DV","ES0031500290331001BF","ES0031500199608001XR","ES0031500561057001LE",
                  "ES0031500227181001FA","ES0031500257364001TB","ES0031500534237001XT","ES0031500664941001KZ",
                  "ES0031500013112001TF","ES0031500225653001LP","ES0031500227266001EH","ES0031500561004001SZ",
                  "ES0031500256454001HY0F","ES0031500510505001DK","ES0031500228131001AB","ES0031500228257001FV",
                  "ES0031500162477001BV","ES0031500228137001BP","ES0031500238540001CQ","ES0031500660381001CV",
                  "ES0031500159373001LM","ES0031500045907001FC","ES0031500193190001WJ","ES0031500175036001NM",
                  "ES0031500521576001JV","ES0031500240792001EV","ES0031500034325001GS","ES0031500303697001TV",
                  "ES0031500242558001PP","ES0031500141042001SM","ES0031500298405001MH","ES0031500155671001QQ",
                  "ES0031500141046001MA","ES0031500258227001DM","ES0031500227332001VP","ES0031500227322001LJ",
                  "ES0031500227099001YK","ES0031500293170001MW0F","ES0031500293171001WJ0F","ES0031500199610001MT0F",
                  "ES0031500141018001YV","ES0031500159372001KV","ES0031500686223001NE","ES0031500228130001YT",
                  "ES0031500622046002ES","ES0031500072273001NQ0F","ES0031500690026001JH","ES0031500056342004MS",
                  "ES0031500531356002FG","ES0031500534244001SP0F","ES0031500177929003FK0F","ES0031500701865001NK0F",
                  "ES0031500637556001XX0F", "ES0031500163260001SM0F", "ES0031500662386001RS0F","ES0031500125873001HB",
                  "ES0031500535899001GC","ES0031500011663001CF0F","ES0031500227680001JH0F","ES0031500257368001JD0F",
                  "ES0031500291005001JS0F","ES0031500191581003NA0F","ES0031500163257001EH0F","ES0031500276652001YG0F",
                  "ES0031500163258001CY0F","ES0031500167452001RW0F","ES0031500279460002DG0F","ES0031500296921001ER0F",
                  "ES0031500260868002LP","ES0031500681871001QJ","ES0031500300742001TB","ES0031500279354001TX",
                  "ES0031500279353001WE","ES0031500050062001HR","ES0031500055814001AT","ES0031500175621001DT",
                  "ES0031500162474001LF0F","ES0031500302321001CS","ES0031500136794001DJ","ES0031500175008001JL",
                  "ES0031500657822001XC0F","ES0031500113850002TD","ES0031500612243001HM","ES0031500233385001WY",
                  "ES0031500161042001LX","ES0031500287249001DF","ES0031500285263001AB0F","ES0031500157254001AH",
                  "ES0031500139305001WB","ES0031500106574002VJ","ES0169000000312180TJ","ES0031500241988001LV",
                  "ES0031500198590003RY", "ES0031500258228001YQ", "ES0031500572801001MS"];

  const handleChangeMT = async () => {
    let data = [];
    for(var i = 0; i < cupsMT.length; i++){
      if(cupsMT[i].length === 20){
        data.push(cupsMT[i]);
        data.push(`${cupsMT[i]}0F`);
      }
      else if(cupsMT[i].length === 22){
        data.push(cupsMT[i]);
        data.push(cupsMT[i].slice(0, 20));
      }
      else{
        data.push(cupsMT[i]);
      }
    }
    props.handleLocations(data, !onChangeMT);
    setOnChangeMT(!onChangeMT);
  }

  const handleChangeName = async () => {
    setOnChangeName(!onChangeName);
  }
    
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullScreen={true}
    >
      <DialogTitle id="form-dialog-title" className={classes.background}>
        <Typography variant="h5" align="left" className={classes.title}>
          <MapIcon className={classes.mapIcon}/>
          {t("displayMapDialog.title")}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.map}>
        <Map locations={props.locations} zoomLevel={7} showNames={onChangeName} />
      </DialogContent>
      <DialogActions className={classes.background}>
        <FormControlLabel
              control={
                <Checkbox color={"secondary"} checked={onChangeMT} onChange={handleChangeMT}/>
              }
              label="Cups MT"
        />
        <FormControlLabel
              control={
                <Checkbox color={"secondary"} checked={onChangeName} onChange={handleChangeName}/>
              }
              label="Mostrar nombres"
        />
        <Button onClick={props.onClose} color="default">
          {t("common.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapsDialog;
