export interface IInvoiceType {
    id: number;
    name: string;
}

export interface IAddManualInvoice {
    supply_type_id: number | null;
    invoice_type_id: number | null;
    invoice_number: string;
    invoice_amount: string;
    provider: string | null;
    supply_reference: string;
    issue_date: string;
    is_periodical: boolean;
    start_period?: string;
    end_period?: string;
    invoice_pdf: string;
}

export const initManualInvoice: IAddManualInvoice = {
    supply_type_id: null,
    invoice_type_id: null,
    invoice_number: "",
    invoice_amount: "",
    provider: null,
    supply_reference: "",
    issue_date: "",
    is_periodical: false,
    start_period: "",
    end_period: "",
    invoice_pdf: "",
}