import React, { FC, useEffect, useState } from "react";
import useIoC from "../../contexts/ioc.context";
import IContactType from "../../gateways/contact-types.interfaces";
import { ContactTypesGateway } from "../../gateways/contact-types.gateway";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import SelectorField from "../../components/selector-field";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export interface IContactTypeMigrateContactsDialog {
    contactTypeId: number;
    open: boolean;
    handleClose: () => void;
    onMigrateContacts: (contactTypeId: number) => void;
}

const ContactTypeMigrateContactsDialog: FC<IContactTypeMigrateContactsDialog> = ({ contactTypeId, handleClose, open, onMigrateContacts }) => {
    const contactTypesGateway = useIoC(ContactTypesGateway);
    const [contactTypes, setContactTypes] = useState<IContactType[]>([]);
    const [newContactTypeId, setNewContactTypeId] = useState(0);
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const handleChange = (name: string, value: any) => {
        setNewContactTypeId(value);
    };

    const handleMigrateContacts = () => {
        if (newContactTypeId && newContactTypeId !== 0) {
            onMigrateContacts(newContactTypeId);
        } else {
            snackbar.enqueueSnackbar(t('messages.requiredField'), { variant: "error" });
        }
    };

    const setContactTypesSelectorValues = async (contactTypeId: number) => {
        const data = await contactTypesGateway.findAll();
        const copy = data.filter((item) => item.id !== contactTypeId);
        setContactTypes(copy);
    };

    const validateNotEmpty = (value: any) => {
        return value !== undefined && value !== null && value !== '';
    };

    useEffect(() => {
        if (contactTypeId !== 0) {
            setContactTypesSelectorValues(contactTypeId);
        }
    }, [contactTypeId]);

    return (
        <Dialog open={open} onClose={() => { handleClose() }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('contactTypeMigrateDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('contactTypeMigrateDialog.content')}</DialogContentText>
                <SelectorField
                    emptyValue
                    fullWidth
                    inputLabel={t('contactTypeMigrateDialog.selectorLabel')}
                    name='contact-types'
                    required
                    value={newContactTypeId}
                    values={contactTypes.map(item => {
                        return { id: item.id, value: item.name };
                    })}
                    onChange={handleChange}
                    validator={validateNotEmpty}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { handleClose() }} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleMigrateContacts} color="primary">{t('common.accept')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ContactTypeMigrateContactsDialog;