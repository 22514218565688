import React, { FC, useEffect } from "react"
import useIoC from "../contexts/ioc.context";
import { UsersGateway } from "../gateways/users.gateway";

interface IPageLoaderProps {
    onLoad: () => void;
}

export const PageLoader: FC<IPageLoaderProps> = ({ onLoad }) => {

    // const usersGateway = useIoC(UsersGateway);

    useEffect(() => {
        const asyncEffet = async () => {
            if (typeof onLoad === 'function') {
                onLoad();
            }
        }
        asyncEffet();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>Loading..</div>
    )
}
