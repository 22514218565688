import React, { useEffect, useState, useContext } from "react";
import { useParams, useRouteMatch } from "react-router";
import {
  makeStyles,
  Theme,
  createStyles,
  Box,
  CircularProgress,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { ElectricSupplyGateway } from "../../gateways/electric-supply.gateway";
import {
  initialIUpdateElectricSupply,
  IElectricSupply,
} from "../../gateways/electric-supply.interface";
import { SupplyType } from "../../gateways/supply-type.interfaces";
import { IAccessType, AccessType } from "../../gateways/access-type.interfaces";
import { AccessTypeGateway } from "../../gateways/access-type.gateway";
import { ProvidersGateway } from "../../gateways/providers.gateway";
import { IProviders } from "../../gateways/providers.interface";
import { ProviderType } from "../../gateways/provider-types.interface";
import ElectricSupplyForm from "./electric-supply-form";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import ElectricSupplyDrawer from "../../components/electric-supply-drawer";
import ProvidersUpsertDialog from "../providers/providers-upsert-dialog";
import { ElectricSupplyContext } from "./electric-supply-context";
import { MongoGateway } from "../../gateways/mongo.gateway";
import { BlacklistGateway } from "../../gateways/blacklist.gateway";
import { obtainDomainFromEmail } from "../../utils/utils";
import { ClientsGateway } from "../../gateways/clients.gateway";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2),
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    itemAvatarIcon: {
      backgroundColor: "#e0e0e0",
      borderRadius: "50%",
      padding: theme.spacing(1),
    },
  })
);

interface IElectricSupplyRouteParams {
  [x: string]: string | undefined;
  clientId: string;
  electricSupplyId: string;
}
const ElectricSupplyDetail = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { url } = useRouteMatch();
  const [errorMissingData, setErrorMissingData] = useState(false);
  const { electricSupplyId } = useParams<IElectricSupplyRouteParams>();
  const { clientId } = useParams<IElectricSupplyRouteParams>();

  const [loading, setLoading] = useState(false);
  const [electricSupplyState, electricSupplyStateDispatch] = useContext(
    ElectricSupplyContext
  );
  const electricSupply = electricSupplyState.electricSupply;
  const [accessTypes, setAccessTypes] = useState<IAccessType[]>([]);
  const [isBlacklisted, setIsBlacklisted] = useState<boolean | undefined>(
    electricSupply && electricSupply.blacklisted
      ? electricSupply.blacklisted
      : false
  );
  const [providers, setProviders] = useState<IProviders[]>([]);
  const { update: updateBreadCrumbName } = useBreadCrumbName();

  const electricSupplyGateway = useIoC(
    ElectricSupplyGateway
  );
  const accessTypeGateway = useIoC(AccessTypeGateway);
  const providersGateway = useIoC(ProvidersGateway);
  const mongoGateway = useIoC(MongoGateway);
  const blacklistGateway = useIoC(BlacklistGateway);
  const clientsGateway = useIoC(ClientsGateway);

  const [openProviderAddDialog, setOpenProviderAddDialog] = useState(false);

  const handleClickBlacklistButton = () => {
    if (isBlacklisted) {
      handleDeleteItemBlacklist();
      if (electricSupply)
        updateBreadCrumbName("electricSupply", electricSupply.referencia);
      snackbar.enqueueSnackbar(
        "Se ha reestablecido el suministro correctamente",
        {
          variant: "success",
        }
      );
    } else {
      handleInsertItemBlacklist();
      if (electricSupply)
        updateBreadCrumbName(
          "electricSupply",
          electricSupply.referencia + " (Cancelado)"
        );
      snackbar.enqueueSnackbar("Se ha descartado el suministro correctamente", {
        variant: "success",
      });
    }
    setIsBlacklisted(!isBlacklisted);
  };

  const handleClickAddProviderDialog = () => {
    setOpenProviderAddDialog(true);
  };

  const handleCloseAddProviderDialog = () => {
    setOpenProviderAddDialog(false);
  };

  const handleCreateNewProvider = (
    item: IProviders,
    isNew: boolean,
    supplyToAccessId: number[]
  ) => {
    if (
      item.providerTypeId === ProviderType.Distribuidora &&
      checkSuppliesToAccess(supplyToAccessId)
    ) {
      setProviders([...providers, item]);
    }
  };

  const checkSuppliesToAccess = (suppliesToAccessId: number[]): boolean => {
    for (const supplyToAccessId of suppliesToAccessId) {
      if (supplyToAccessId === 11) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      if(!electricSupply) return;

      const response = await electricSupplyGateway.findOneById(electricSupply.id);
      if(response.length === 0) setIsBlacklisted(undefined);

      const accessTypes = await accessTypeGateway.findAll();
      setAccessTypes(accessTypes);

      const providers = await providersGateway.findWithFilters({
        providerTypeId: ProviderType.Distribuidora.toString(),
        supplyTypeId: SupplyType.Electrico.toString(),
        accessTypeId: AccessType.Red.toString(),
      });
      setProviders(providers);
      setLoading(false);
    })();
  }, []);

  const handleSave = () => {
    if (electricSupply === null) {
      return;
    }
    if (!electricSupply.referencia || electricSupply.telemedida === null) {
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      setErrorMissingData(true);
      return;
    }

    (async () => {
      try {
        if (!electricSupplyId) {
          return;
        }

        //domain check
        if (typeof electricSupply.emailNotification === 'string' && electricSupply.emailNotification !== null && electricSupply.emailNotification !== '')
        {
            const input_domain = obtainDomainFromEmail(electricSupply.emailNotification);
            //have content
            const client = await clientsGateway.findById(parseInt(clientId, 10));
            if (typeof client.client_email_domain !== 'undefined' && client.client_email_domain !== '')
            {
                //compare both domains
                if (input_domain !== client.client_email_domain)
                {
                    //es diferente
                    if (window.confirm('El dominio del correo de cliente, no coincide con el dominio configurado en el apartado principal de Cliente, quieres continuar de todos modos ?') == false)
                    {
                        snackbar.enqueueSnackbar('No se han actualizado los cambios', { variant: "warning" });
                        setLoading(false);
                        return;
                    }
                }
            }
            
        }

        await electricSupplyGateway.update(
          Number.parseInt(electricSupplyId),
          electricSupply
        );

        snackbar.enqueueSnackbar(
          t("electricSupplyDetail.updateSucceded", {
            id: electricSupply.id,
            reference: electricSupply.referencia,
          }),
          {
            variant: "success",
          }
        );
      setErrorMissingData(false);
      } catch (e) {
        const er = e as any;
        if (er.response.data.key === "updatingError") {
          const key = "electricSupplyErrorHandler." + er.response.data.key;
          const message =
            "validation" in er
              ? er.validation
              : t(key, { id: er.response.data.id });
          snackbar.enqueueSnackbar(message, { variant: "error" });
        } else {
          const message =
            "validation" in er ? er.validation : t("messages.defaultError");
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      }
    })();
  };

  async function checkIfEmailIsBlacklisted(value: any) {
    const isEmailFull = value.includes(".com");
    if(isEmailFull) {
      const exists = await blacklistGateway.findEmail(value);
      if(!!exists) {
        value = "";
        snackbar.enqueueSnackbar("La dirección de correo introducida pertenece a un cliente descartado", {
          variant: "error",
        });
      }
    }
    return value;
  }

  const handleChange = async (name: string, value: any) => {
    if (electricSupply === null) {
      return;
    }
    if(name === "code" && value.includes("*")){
      snackbar.enqueueSnackbar("No se puede introducir este carácter especial", {
        variant: "error",
      });
      return;
    }
    if (name === "providerId" && value === "") {
      const updatedElectricSupply: IElectricSupply = {
        ...electricSupply,
        [name]: null,
      } as any;
      electricSupplyStateDispatch({
        type: "update-electricSupply",
        payload: updatedElectricSupply,
      });
    } else {
      if(name === "emailNotification") {
        value = await checkIfEmailIsBlacklisted(value);
      }
      const updatedElectricSupply: IElectricSupply = {
        ...electricSupply,
        [name]: value,
      } as any;
      electricSupplyStateDispatch({
        type: "update-electricSupply",
        payload: updatedElectricSupply,
      });
      updateBreadCrumbName("electricSupply", updatedElectricSupply.referencia);
    }
  };

  const handleTelemedidaChange = () => {
    const updatedElectricSupply: IElectricSupply = {
      ...electricSupply,
      telemedida: electricSupply ? !electricSupply.telemedida : null,
    } as any;
    electricSupplyStateDispatch({
      type: "update-electricSupply",
      payload: updatedElectricSupply,
    });
  };

  const handleSendEmailChange = () => {
    const updatedElectricSupply: IElectricSupply = {
      ...electricSupply,
      sendEmail: electricSupply ? !electricSupply.sendEmail : null,
    } as any;

    electricSupplyStateDispatch({
      type: "update-electricSupply",
      payload: updatedElectricSupply,
    });
  };

  const handleSendClientEmailChange = () => {
    const updatedElectricSupply: IElectricSupply = {
      ...electricSupply,
      sendClientEmail: electricSupply ? !electricSupply.sendClientEmail : null,
    } as any;

    electricSupplyStateDispatch({
      type: "update-electricSupply",
      payload: updatedElectricSupply,
    });
  };

  const handleCounterCheckChange = () => {
    const updatedElectricSupply: IElectricSupply = {
      ...electricSupply,
      separatedMeterAccount: electricSupply ? !electricSupply.separatedMeterAccount : null,
    } as any;
    electricSupplyStateDispatch({
      type: "update-electricSupply",
      payload: updatedElectricSupply,
    });
  };

  const handleStartCaptureDateChange = (value: any) => {
    const updatedElectricSupply: IElectricSupply = {
      ...electricSupply,
      startCaptureDate: value,
    } as any;
    electricSupplyStateDispatch({
      type: "update-electricSupply",
      payload: updatedElectricSupply,
    });
  };
  const handleValidationsDateChange = (value: any) => {
    const updatedElectricSupply: IElectricSupply = {
      ...electricSupply,
      startValidationsDate: value,
    } as any;
    electricSupplyStateDispatch({
      type: "update-electricSupply",
      payload: updatedElectricSupply,
    });
  };

  const handleSeparatedMeterStartCaptureDateChange = (value: any) => {
    const updatedElectricSupply: IElectricSupply = {
      ...electricSupply,
      separatedMeterStartCaptureDate: value,
    } as any;
    electricSupplyStateDispatch({
      type: "update-electricSupply",
      payload: updatedElectricSupply,
    });
  };
  const handleSeparatedMeterStartValidationsDateChange = (value: any) => {
    const updatedElectricSupply: IElectricSupply = {
      ...electricSupply,
      separatedMeterStartValidationsDate: value,
    } as any;
    electricSupplyStateDispatch({
      type: "update-electricSupply",
      payload: updatedElectricSupply,
    });
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  if (electricSupply === null) {
    return <CircularProgress className={classes.progress} />;
  }

  const handleInsertItemBlacklist = async () => {
    await blacklistGateway.insertCups(electricSupply.referencia);
  };

  const handleDeleteItemBlacklist = async () => {
    await blacklistGateway.deleteCups(electricSupply.referencia);
  };

  const handleDeleteSupply = async () => {
    if(window.confirm(t("electricSupplyDetail.deleteSupply") as string)){
      if(!electricSupply) return;

      if(isBlacklisted) await handleDeleteItemBlacklist();
      await electricSupplyGateway.delete(electricSupply.id);
      
      window.location.href = window.location.origin + url.split("electricSupply")[0] + "electricSupply";
    }
    else return;
  }

  return (
    <Box>
      <ScreenGlobalStructure
        buttonIcon={<SaveIcon />}
        headerIcon={FlashOnIcon}
        matchUrl={url}
        onButtonClick={handleSave}
        title={t("electricSupplyDetail.title")}
        clientId={clientId ? clientId : ""}
        loading={loading}
        drawer={
          <ElectricSupplyDrawer
            matchUrl={url}
            lateralMenu={0}
            icon={
              <FlashOnIcon
                fontSize="large"
                className={classes.itemAvatarIcon}
              />
            }
            goBack={false}
          />
        }
        onBlacklistButtonClick={handleClickBlacklistButton}
        blacklisted={isBlacklisted}
        deleteSupply={handleDeleteSupply}
      >
        <ElectricSupplyForm
          errorMissingData={errorMissingData}
          accessTypes={accessTypes}
          supply={electricSupply}
          onChangeSupply={handleChange}
          onTelemedidaChange={handleTelemedidaChange}
          providers={providers}
          showAllFields={true}
          validateRequired={validateRequired}
          onProviderAddButtonClick={handleClickAddProviderDialog}
          onStartCaptureDateChange={handleStartCaptureDateChange}
          onValidationsDateChange={handleValidationsDateChange}
          onSeparatedMeterStartCaptureDateChange={handleSeparatedMeterStartCaptureDateChange}
          onSeparatedMeterStartValidationsDateChange={handleSeparatedMeterStartValidationsDateChange}
          onSendEmailChange={handleSendEmailChange}
          onSendClientEmailChange={handleSendClientEmailChange}
          onCounterCheckChange={handleCounterCheckChange}
        />
      </ScreenGlobalStructure>
      <ProvidersUpsertDialog
        open={openProviderAddDialog}
        item={undefined}
        onClose={handleCloseAddProviderDialog}
        onItemUpsert={handleCreateNewProvider}
        type={"electricDetail"}
      />
    </Box>
  );
};

export default ElectricSupplyDetail;
