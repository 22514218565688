import React from "react";
import { Switch, Route, useRouteMatch } from "react-router";
import ElectricSupplyDetail from "./electric-supply-detail";
import ElectricSupplyList from "./electric-supply-list";
import ElectricContractRouter from "../electric-contract/electric-contract-router";
import RegisterElectricBillRouter from "../register-electric-bill/register-electric-bill-router";
import { ElectricSupplyWrapper } from './electric-supply-wrapper'


const ElectricSupplyRouter = () => {

    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/`} exact>
                <ElectricSupplyList />
            </Route>
            <Route path={`${path}/:electricSupplyId`} component={ElectricSupplyWrapper} />
        </Switch>
    );
};
export default ElectricSupplyRouter;
