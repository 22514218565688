export interface IProviders {
    id: number;
    society: string;
    alias: string;
    nif: string;
    providerTypeId: number;
}
export const initialProviders: IProviders = {
    id: 0,
    society: '',
    alias: '',
    nif: '',
    providerTypeId: 0,
}
export interface IUpdateProvidersList {
    society: string;
    alias: string;
    nif: string;
    providerTypeId: number;
    supplyToAccessId: number[];
}
export interface IProvidersDisplay {
    id: number;
    society: string;
    alias: string;
    nif: string;
    providerTypeId: number;
    supplyToAccessId: number[];
}
export interface IFindFilterProvider {
    providerTypeId: string;
    accessTypeId: string;
    supplyTypeId: string;
} 