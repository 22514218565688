import React, { FC, useEffect } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { Container } from '@material-ui/core';
import useIoC from '../../contexts/ioc.context';
import { OAuth2Gateway } from '../../gateways/oauth2.gateway';

const useStyles = makeStyles((theme) => ({
    circularLoading: {
        width: '100%',
        height: '100vh',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
    }
}));

export const LoginPage: FC = () => {

    const classes = useStyles();
    const oAuth2Gateway = useIoC(OAuth2Gateway);

    useEffect(() => {
        (async () => {
            const url = await oAuth2Gateway.generateUrl();
            window.location.href = url.data;
        })();
    }, [window.location.href]);

    return (
        <Container className={classes.circularLoading}>
            <CircularProgress/>
        </Container>
    );
};