import React, { FC } from "react";
import ICountry from "../../gateways/country.interfaces";
import { ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, makeStyles } from "@material-ui/core";
import CountryIcon from '@material-ui/icons/Public';
import UpdateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

interface ICountryItemProps {
    item: ICountry;
    onClickUpdateButton: (item: ICountry) => void;
    onClickDeleteButton: (item: ICountry) => void;
}

const useStyles = makeStyles((theme) => ({
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

const CountryItem: FC<ICountryItemProps> = (props) => {
    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar >
                <CountryIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
            </ListItemAvatar>
            <ListItemText primary={props.item.name} />
            <ListItemSecondaryAction>
                <IconButton onClick={() => { props.onClickUpdateButton(props.item) }} aria-label="update"><UpdateIcon /></IconButton>
                <IconButton onClick={() => { props.onClickDeleteButton(props.item) }} aria-label="delete"><DeleteIcon /></IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default CountryItem;
