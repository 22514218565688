import React, { FC, useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Chip, makeStyles, createStyles, Theme } from "@material-ui/core";
import FormTextField from "../../components/form-text-field";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import { ProvidersGateway } from "../../gateways/providers.gateway";
import { IProviders, IUpdateProvidersList } from "../../gateways/providers.interface";
import SelectorField from "../../components/selector-field";
import { ISupplyTypeRO } from "../../gateways/supply-type.interfaces";
import { IAccessType } from "../../gateways/access-type.interfaces";
import { ISupplyToAccess } from "../../gateways/supply-to-access.interfaces";
import { IProviderType } from "../../gateways/provider-types.interface";
import { SupplyTypeGateway } from "../../gateways/supply-type.gateway";
import { AccessTypeGateway } from "../../gateways/access-type.gateway";
import { ProviderTypesGateway } from "../../gateways/provider-types.gateway"

interface IProviderDialogProps {
    open: boolean;
    item: IProviders | undefined;
    onItemUpsert: (item: IProviders, isNew: boolean, supplyToAccessId: number[]) => void;
    onClose: () => void;
    type?: ProviderSourceType;
}

const initialSupply: ISupplyTypeRO = {
    id: 0,
    name: '',
};

const initialItem: IProviders = {
    id: 0,
    alias: '',
    nif: '',
    society: '',
    providerTypeId: 0,

};

const initialAccess: IAccessType = {
    id: 0,
    name: '',
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5)
        },
        button: {
            margin: theme.spacing(0.5),

        },
        textField: {
            margin: theme.spacing(0.5)
        }
    })
);

type ProviderSourceType = 'electricDetail' | 'electricContract' | 'fuelDetail' | 'fuelContract' | 'waterDetail';

const defaultDiaValues: Record<ProviderSourceType, {defaultItem: Partial<IProviders>, 
                                                       defaultAccessType?: Partial<IAccessType>, 
                                                       defaultSupplyType: Partial<ISupplyTypeRO>}> = {

  'electricDetail': { defaultItem: { providerTypeId: 2 }, defaultAccessType: {id: 1}, defaultSupplyType: {id: 1}},
  'electricContract': { defaultItem: { providerTypeId: 1 }, defaultAccessType: {id: 1}, defaultSupplyType: {id: 1}},
  'fuelDetail': { defaultItem: { providerTypeId: 2 }, defaultSupplyType: {id: 2}},
  'fuelContract': { defaultItem: { providerTypeId: 1 }, defaultSupplyType: {id: 2}},
  'waterDetail': { defaultItem: { providerTypeId: 2 }, defaultSupplyType: {id: 3}}
}


const ProvidersUpsertDialog: FC<IProviderDialogProps> = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const [item, setItem] = useState<IProviders>(initialItem);
    const snackbar = useSnackbar();

    const providersGateway = useIoC(ProvidersGateway);
    const supplyTypeGateway = useIoC(SupplyTypeGateway);
    const accessTypeGateway = useIoC(AccessTypeGateway);
    const providerTypeGateway = useIoC(ProviderTypesGateway);

    const [supplyType, setSupplyType] = useState<ISupplyTypeRO>(initialSupply); 
    const [supplyTypeExistent, setSupplyTypeExistent] = useState<ISupplyTypeRO[]>([]); 

    const [accessType, setAccessType] = useState<IAccessType>(initialAccess);   
    const [accessTypeExistent, setAccessTypeExistent] = useState<IAccessType[]>([]); 

    const [providerTypeExistent, setProviderTypeExistent] = useState<IProviderType[]>([]);
    
    const [supplyToAccessTypes, setSupplyToAccessTypes] = useState<ISupplyToAccess[]>([]);

    const getPossibleSupply = async ()=>{
        const possibleSupply = await supplyTypeGateway.findAll();
        const possibleSupplyOrderById = [...possibleSupply].sort((a, b) => a.id - b.id);
        setSupplyTypeExistent(possibleSupplyOrderById);
    }

    const getPossibleAccess = async ()=>{
        const possibleAccess = await accessTypeGateway.findAll();
        setAccessTypeExistent(possibleAccess);
    }

    const getProviderTypeGateway = async ()=>{
        const possbileProviderTypes = await providerTypeGateway.findAll();
        setProviderTypeExistent(possbileProviderTypes);
    }

    const createProvider = async (item: IProviders) => {
        const itemToCreate: IUpdateProvidersList = {
            alias: item.alias,
            nif: item.nif,
            providerTypeId: item.providerTypeId,
            society: item.society,
            supplyToAccessId: supplyToAccessTypes.map(value=>{return value.id})
        }
        const provider = await providersGateway.create(itemToCreate);
        snackbar.enqueueSnackbar(t('providerUpsertDialog.createSucceded', { id: provider.id, alias: provider.alias }), { variant: "success" });
        props.onItemUpsert(provider, true, supplyToAccessTypes.map(value=>{return value.id}));
    };

    const updateProvider = async (item: IProviders) => {

        const itemToUpdate: IUpdateProvidersList = {
            alias: item.alias,
            nif: item.nif,
            providerTypeId: item.providerTypeId,
            society: item.society,
            supplyToAccessId: supplyToAccessTypes.map(value=>{return value.id})
        }
        const provider = await providersGateway.update(item.id, itemToUpdate);
        snackbar.enqueueSnackbar(t('providerUpsertDialog.updateSucceded', { id: provider.id, alias: provider.alias }), { variant: "success" });
        props.onItemUpsert(provider, false, supplyToAccessTypes.map(value=>{return value.id}));
    };

    const upsertProviders = async (item: IProviders) => {
        try {
            if (!props.item) {
                await createProvider(item);
            } else {
                await updateProvider(item);
            }           
        } catch (e) {
            const er = e as any;
            const key = 'providersErrorHandler.' + er.response.data.key; 
            if (er.response.data.key === 'creatingEntityError'){    
                const message = "validation" in er ? er.validation : t(key);
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }else if (er.response.data.key === 'updatingError'){
                const message = "validation" in er ? er.validation : t(key, {id: er.response.data.id});
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }else{
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose();
        }
    };

    const handleAccept = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!item.alias || !item.providerTypeId) {
            event.preventDefault();
            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        }
        upsertProviders(item); 
    };

    const handleChange = (name: string, value: any) => {
        setItem({ ...item, [name]: value });
    };

    const handleAccess = (name: string, value: any) => {
        setAccessType({...accessType, [name]: value});
    }

    const handleSupply = (name: string, value: any) =>{
        setSupplyType({...supplyType, [name]: value});
    }

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    const handleClickAddSupplyToAccess = () => {
        if(accessType.id && supplyType.id){
            const supplyToAccessInterface: ISupplyToAccess = {id: parseInt(accessType.id.toString() + supplyType.id.toString()),
                accessTypeId: accessType.id,
                supplyTypeEntityId: supplyType.id};

            setSupplyToAccessTypes([...supplyToAccessTypes, supplyToAccessInterface]);

            setAccessType(initialAccess);

            setSupplyType(initialSupply);
        }
    };

    const handleDeleteChipSupplyToAccess = (index: number) => {
        const copy = supplyToAccessTypes.filter((v, i) => i !== index);
        setSupplyToAccessTypes(copy);
    };

    const getSupplyToAccess = async (id: number) => {
        const result = await providersGateway.findByIdSupplyToAccess(id);
        setSupplyToAccessTypes(result);
    }

    const defaultDialogValues = () => {

        if(props.type){
        
            const defaultValues = defaultDiaValues[props.type];
            
            defaultValues.defaultItem && setItem({...item, ...defaultValues.defaultItem});
            defaultValues.defaultAccessType && setAccessType({...accessType, ...defaultValues.defaultAccessType});
            defaultValues.defaultSupplyType && setSupplyType({...accessType, ...defaultValues.defaultSupplyType});
        
        }
    }

    useEffect(() => {
        getPossibleSupply();
        getPossibleAccess();
        getProviderTypeGateway();

        if (props.item) {
            getSupplyToAccess(props.item.id);            
            setItem(props.item);
        } else {
            setSupplyToAccessTypes([]);
            setItem(initialItem);
        }

        defaultDialogValues();
        
    }, [props.item,props.open]);
   

    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }}>
            <DialogTitle>{!props.item ? t('providerUpsertDialog.createTitle') : t('providerUpsertDialog.updateTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{!props.item ? t('providerUpsertDialog.createContent') : t('providerUpsertDialog.updateContent')}</DialogContentText>
                <FormTextField
                    name='alias'
                    label={t('common.alias')}
                    type='text'
                    validator={validateRequired}
                    errorText={t('messages.requiredField') as string}
                    value={item.alias}
                    onChange={handleChange}
                    fullWidth
                    autoFocus
                    required />
                <FormTextField
                    name='society'
                    label={t('common.society')}
                    type='text'
                    validator={validateRequired}
                    value={item.society}
                    onChange={handleChange}
                    fullWidth />
                <FormTextField
                    name='nif'
                    label={t('common.nif')}
                    type='text'
                    validator={validateRequired}
                    value={item.nif}
                    onChange={handleChange}
                    fullWidth />
                <SelectorField
                    name='providerTypeId'
                    emptyValue = {false}
                    fullWidth
                    inputLabel={t('common.providerTypeId')}
                    helperText={t('messages.requiredField') as string}
                    required
                    onChange={handleChange}
                    value={item.providerTypeId}
                    validator={validateRequired}
                    values={providerTypeExistent.map(value => {
                        return { id: value.id, value: value.name};
                    })} 
                     />
                <SelectorField
                    name='id'
                    emptyValue = {false}
                    fullWidth
                    inputLabel={t('common.supply')}
                    helperText={t('messages.requiredField') as string}
                    required
                    onChange={handleSupply}
                    value={supplyType.id}
                    validator={validateRequired}
                    values={supplyTypeExistent.map(value => {
                        return { id: value.id, value: value.name};
                    })} 
                />
                <SelectorField
                    name='id'
                    emptyValue = {false}
                    fullWidth
                    inputLabel={t('common.access')}
                    helperText={t('messages.requiredField') as string}
                    required
                    onChange={handleAccess}
                    value={accessType.id}
                    validator={validateRequired}
                    values={accessTypeExistent.map(value => {
                        return {id: value.id, value: value.name};
                    })} 
                />
                <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleClickAddSupplyToAccess}
                >
                    {t('common.add')}
                </Button>
                <div>
                    {supplyToAccessTypes.map((value, index) => {
                        return (
                            <Chip
                                key = {index}
                                label = {supplyTypeExistent[value.supplyTypeEntityId - 1].name
                                        + ' ' + 
                                        accessTypeExistent[value.accessTypeId - 1].name}
                                onDelete={() => { handleDeleteChipSupplyToAccess(index)}}
                                className={classes.chip}
                            />
                        );
                    })}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onClose() }} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleAccept} color="primary">{t('common.accept')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProvidersUpsertDialog;