import Axios, { AxiosError } from "axios"
import { Container } from "aurelia-dependency-injection";
import { UsersGateway } from "./users.gateway";
import config from '../config';

const buildAxiosInstance = () => {
    const instance = Axios.create({
        baseURL: config.REACT_APP_API
    });

    instance.interceptors.request.use((config) => {
        const accessToken = localStorage.getItem('user.token');
        if (accessToken !== null) {
            config.headers.Authorization = 'Bearer ' + accessToken;
        }
        return config;
    }); 

    instance.interceptors.response.use((response) => {
        return response;
    }, async (error: AxiosError) => {

        const response = error.response;
        
        if (response) {
            if (response.status === 403 || response.status === 401) {
                const rqHeaders:any = error.config?.headers ?? null;
                const jwt: string = rqHeaders && rqHeaders.Authorization ? rqHeaders.Authorization.split(' ')[1] : null;
                const isWithRefresh = rqHeaders && rqHeaders['X-With-Refresh'] === '1';
                const usersGateway: UsersGateway = Container.instance.get(UsersGateway);

                try {
                    const result = await usersGateway.refreshToken(jwt);
                    if (result) {
                        localStorage.setItem('user.token', result.accessToken);
                        const newRqConfig = error.config as any;
                        newRqConfig.headers['X-With-Refresh'] = '1';
                        newRqConfig.headers.Authorization = result.accessToken;
                        return await instance.request(newRqConfig);

                    }
                } catch (e) {
                    throw e;
                }

                return response;
            }
        }
        throw error;
    });

    return instance;
}

export default buildAxiosInstance;
