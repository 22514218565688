import { FC, useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React from "react";
import useIoC from "../../contexts/ioc.context";
import { useTranslation } from "react-i18next";
import { IFuelSupply } from "../../gateways/fuel-supply.interface";
import { IEstablishment } from "../../gateways/establishment.interface";
import SelectorField from "../../components/selector-field";
import { FuelSupplyGateway } from '../../gateways/fuel-supply.gateway';

export interface FuelSupplyConnectDialogProps {
    open: boolean,
    item: IEstablishment
    handleClose: () => void,
}
interface ISelectorFieldHelp{
    id: number,
    selectorFieldName: string
}

const FuelSupplyConnectDetailDialog: FC<FuelSupplyConnectDialogProps> = ({ open, item, handleClose }) => {

    const { t } = useTranslation();

    const [componentDisabled, setComponentDisabled] = useState(false);
    const [fuelSupplies, setFuelSupplies] = useState<IFuelSupply[]>([]);
    const [fuelSupplyId,setFuelSupplyId] = useState(0);
    let selectorFieldHelp: ISelectorFieldHelp[] = [];

    const fuelSupplyGateway = useIoC(FuelSupplyGateway);


    useEffect(() => {
        (async () => {
            if (item.complexId) {
                const fuelSupplies = await fuelSupplyGateway.findAll({ complexId: item.complexId.toString() });
                setFuelSupplies(fuelSupplies);
                for (let i: number = 0; i < fuelSupplies.length; i++) {

                }
            }
        })();
    }, [item.id]);

    const handleChange = (name: string, value: any) => {
        setFuelSupplyId(value);
    }

    const handleConnect  = () => {
        (async () => {
            const fuelSupply = fuelSupplies.find(value=> value.id === fuelSupplyId);
            if(fuelSupply){
                fuelSupply.establishmentId = item.id;

                await fuelSupplyGateway.update(fuelSupply.id,fuelSupply);
            }
            handleClose();
        })();
    };

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('fuelSupplyConnectDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('fuelSupplyConnectDialog.content')}</DialogContentText>
                <SelectorField
                    emptyValue={false}
                    fullWidth
                    required
                    inputLabel={t('rightNavigation.fuelSupply')}
                    name='fuelSupplies'
                    validator={validateRequired}
                    value={fuelSupplyId}
                    values={fuelSupplies.map(value => {
                        return { id: value.id, value: value.reference }
                    })}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={componentDisabled}>{t('common.cancel')}</Button>
                <Button onClick={handleConnect} color="primary" disabled={componentDisabled}>{t('common.connect')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default FuelSupplyConnectDetailDialog;