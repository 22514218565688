import React, { FC } from "react";
import { withRouter, RouteComponentProps, Route, Switch } from "react-router";
import ClientList from "./client-list";
import ClientDetail from "./client-detail";
import ContactList, { ContactEntityType } from "../contacts/contact-list";
import CompanyRouter from "../companies/company-router";
import ComplexRouter from "../complex/complex-router";
import EstablishmentRouter from "../establishment/establishment-router";
import { ClientWrapper } from './client-wrapper';
import { useUser } from "../../contexts/user.context";

interface IContactsRouteParams {
    clientId: string;
}

const ContactsRouter = (props: RouteComponentProps<IContactsRouteParams>) =>
    <ContactList
        entity={ContactEntityType.Client}
        entityId={props.match.params.clientId} />;



const ClientsIndex: FC<RouteComponentProps> = (props) => {
    const [{ canViewClientsList }] = useUser();
    return (
        <Switch>
            {canViewClientsList ? <Route path={`${props.match.path}`} exact component={ClientList} /> : null}
            {canViewClientsList ? <Route path={`${props.match.path}/:clientId`} component={ClientWrapper} /> : null}

            {/* */}

        </Switch>
    );
}

export default withRouter(ClientsIndex);