import React, { FC, useEffect, useState, useReducer } from "react";
import { useRouteMatch, Route, RouteComponentProps } from "react-router-dom";
import useIoC from "../../contexts/ioc.context";
import { ClientsGateway } from "../../gateways/clients.gateway";
import CompanyRouter from "./company-router";
import ComplexRouter from "../complex/complex-router";
import EstablishmentRouter from "../establishment/establishment-router";
import ContactList, { ContactEntityType } from "../contacts/contact-list";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import { useTranslation } from "react-i18next";
import { StateType, CompanyReducer, CompanyContext } from "./companies-context"
import { CompanyGateway } from "../../gateways/company.gateway";
import CompanyDetail from "./company-detail";

interface IContactsRouteParams {
    companyId: string;
}

const ContactRouter = (props: RouteComponentProps<IContactsRouteParams>) =>
    <ContactList
        entity={ContactEntityType.Company}
        entityId={props.match.params.companyId} />;


const initialState: StateType = { company: null };

export const CompanyWrapper: FC = () => {
    const { t } = useTranslation();
    const match = useRouteMatch<{ companyId: string }>();
    const [companyId, setCompanyId] = useState<number | null>(null);
    const companyGateway = useIoC(CompanyGateway);
    const [loading, setLoading] = useState(true);
    const { update: updateBreadCrumbName } = useBreadCrumbName();

    const [companyState, companyStateReducer] = useReducer(CompanyReducer, initialState);

    useEffect(() => {
        setCompanyId(parseInt(match.params.companyId, 10));
    }, [match.params.companyId]);

    useEffect(() => {
        (async () => {
            if (companyId === null) {
                return;
            }
            setLoading(true);
            const temp = await companyGateway.findById(companyId.toString());
            const breadCrumbName = temp.cancelledDate === null ? temp.name: temp.name + " (Cancelado)";
            updateBreadCrumbName('companies', breadCrumbName)
            companyStateReducer({ type: 'update-company', payload: temp });
            setLoading(false);
        })();
    }, [companyId]);

    if (companyState.company === null) {
        return (<div>{t('common.loading')}</div>);
    }

    return (<CompanyContext.Provider value={[companyState, companyStateReducer]}>
        <Route path={`${match.path}`} exact component={CompanyDetail} />
        <Route
            path={`${match.path}/contacts`}
            render={ContactRouter}
        />
        <Route path={`${match.path}/complex`}>
            <ComplexRouter />
        </Route>
        <Route path={`${match.path}/establishments`}>
            <EstablishmentRouter />
        </Route>
    </CompanyContext.Provider>);
}