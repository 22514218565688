import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React from "react";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import IContact from "../../gateways/contact.interface";
import { ContactGateway } from "../../gateways/contact.gateway";
import { useTranslation } from "react-i18next";

export interface IContactDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  onContactDeleted: (item: IContact) => void;
  contact: IContact;
}

const ContactDeleteDialog: FC<IContactDeleteDialogProps> = ({ open, onClose, onContactDeleted, contact: contact }) => {
  const [componentDisabled, setComponentDisabled] = useState(false);
  const contactGateway = useIoC(ContactGateway);
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const handleDeleteContact = () => {
    setComponentDisabled(true);
    (async () => {
      try {
        await contactGateway.delete(contact.id);
        snackbar.enqueueSnackbar(t('contactDeleteDialog.deleteSucceded', { id: contact.id }), { variant: "success" });
        onContactDeleted(contact);
      } catch (e) {
        const er = (e as any);
        if (er.response.data.key === 'deletingError'){
          const key = 'contactErrorHandler.' + er.response.data.key; 
          const message = "validation" in er ? er.validation : t(key, {id: er.response.data.id});
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }else{
          const message = "validation" in er ? er.validation : t('messages.defaultError');
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      } finally {
        setComponentDisabled(false);
        onClose();
      }
    })();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('contactDeleteDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('contactDeleteDialog.content')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={componentDisabled}>{t('common.cancel')}</Button>
        <Button onClick={handleDeleteContact} color="primary" disabled={componentDisabled}>{t('common.accept')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactDeleteDialog;
