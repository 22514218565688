import { ContactEntityType } from "../pages/contacts/contact-list";

export default interface IContact {
    id: number,
    fullName: string,
    contactTypeId: number,
    phoneNumber1: string,
    phoneNumber2: string,
    email: string,
    headquarters: string,
}

export const initialIContact: IContact = {
    id: 0,
    fullName: "",
    contactTypeId: 0,
    email: "",
    phoneNumber1: "",
    phoneNumber2: "",
    headquarters: "",
}