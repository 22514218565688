import { makeStyles, Fab, Box, List, Divider } from '@material-ui/core';
import { withRouter } from "react-router";
import { FC, useState, useMemo, useEffect } from 'react';
import { useNavigator } from '../../contexts/navigator.context';
import { useTranslation } from 'react-i18next';
import useIoC from '../../contexts/ioc.context';
import CenteredCircularProgress from '../../components/centered-circular-progress';
import React from 'react';
import AlertBox from '../../components/alert-box';
import AddIcon from '@material-ui/icons/Add';
import { IRepositoryDefinition, IRepositoryDefinitionRelations } from '../../gateways/repository-definition.interfaces';
import { RepositoryDefinitionGateway } from '../../gateways/repository-definition.gateway';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import RepositoryDefinitionItem from './repository-definition-item';
import RepositoryDefinitionDeleteDialog from './repository-definition-delete-dialog';
import RepositoryDefinitionAddDialog from './repository-definition-add-dialog';
import RepositoryDefinitionUpdateDialog from './repository-definition-update-dialog';

const useStyles = makeStyles((theme) => ({

    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    }
}));
const RepositoryDefinitionList: FC = () => {
    const [data, setData] = useState<IRepositoryDefinitionRelations[]>([]);
    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const { t } = useTranslation();
    const repositoryDefinitionGateway = useIoC(RepositoryDefinitionGateway);
    const [item, setItem] = useState<IRepositoryDefinitionRelations>();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('repositoryDefinitionList.title'),
                icon: SettingsEthernetIcon
            }
        });
    }, []);

    useEffect(() => {
        (async () => {
            setIsDataLoading(true);
            const repositoryDefinitions = await repositoryDefinitionGateway.findAll();
            setData(repositoryDefinitions);
            setIsDataLoading(false);
        })();
    }, [RepositoryDefinitionGateway]);

    const handleClickUpdateButtonItem = (item: IRepositoryDefinitionRelations) => {
        setItem(item);
        setOpenUpdateDialog(true);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCreatedRepositoryDefinition = (item: IRepositoryDefinitionRelations) => {
        setData([...data, item]);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleUpdatedRepositoryDefinition = (item: IRepositoryDefinitionRelations, id: number) => {
        let copy = data.slice();
        const index = copy.findIndex((value) => value.id === id);
        copy[index] = item;
        setData(copy);
        setOpenUpdateDialog(false);
    };

    const handleClickDeleteButtonItem = (item: IRepositoryDefinitionRelations) => {
        setItem(item);
        setOpenDeleteDialog(true);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteRepositoryDefinition = (repositoryDefinition: IRepositoryDefinition) => {
        const copy = data.filter(item => item.id !== repositoryDefinition.id);
        setData(copy);
    };

    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />
        }

        if (isDataEmpty) {
            return <AlertBox variant='info'>{t('common.noItems')}</AlertBox>
        }
        return (
            <List>
                {data.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <RepositoryDefinitionItem
                                item={item}
                                onClickUpdateButton={handleClickUpdateButtonItem} 
                                onClickDeleteButton={handleClickDeleteButtonItem} />
                            {index < data.length - 1 ? <Divider variant='inset' component='li' /> : null}
                        </div>
                    );
                })}
            </List>
        );
    };

    return (
        <Box>
            <Fab color='primary' aria-label='add' className={classes.fab} onClick={handleOpenAddDialog}>
                <AddIcon />
            </Fab>
            <Content />
            <RepositoryDefinitionAddDialog
                open={openAddDialog}
                onCreated={handleCreatedRepositoryDefinition}
                onClose={handleCloseAddDialog} />
            <RepositoryDefinitionUpdateDialog
                open={openUpdateDialog}
                item={item}
                onItemUpdate={handleUpdatedRepositoryDefinition}
                onClose={handleCloseUpdateDialog} />
            <RepositoryDefinitionDeleteDialog
                open={openDeleteDialog}
                item={item}
                onDeleted={handleDeleteRepositoryDefinition}
                onClose={handleCloseDeleteDialog} />
        </Box>
    );
}

export default withRouter(RepositoryDefinitionList);