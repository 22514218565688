import React, { FC, useEffect, useState, useMemo } from "react";
import { List, ListItem, ListItemAvatar, ListItemText, Divider, makeStyles, CircularProgress, Box, Table, TableHead, TableBody, TableCell, TableRow, ListItemSecondaryAction, Switch, ListItemIcon, TextField, Button } from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import { withRouter, RouteComponentProps } from "react-router";
import Fab from '@material-ui/core/Fab';
import useIoC from "../../contexts/ioc.context";
import AlertBox from "../../components/alert-box";
import { useNavigator } from '../../contexts/navigator.context';
import ClientIcon from '@material-ui/icons/People';
import { useTranslation } from "react-i18next";
import { MarketingGateway } from "../../gateways/marketing.gateway";
import { IClientMarketing } from "../../gateways/marketing.interfaces";
import FSTable from "../../components/fs-table";
import { IRepositoryQueryFilterDto, FilterOperator, IRepositoryDefinitionGroup } from "../../gateways/repository.interfaces";
import CreateCampaignDialog from "./create-campaign.dialog";


const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    inline: {
        display: 'inline',
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    progress: {
        margin: theme.spacing(2),
    },
    loaderContainer: {
        textAlign: 'center'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const AdminCampaignList: FC<RouteComponentProps> = ({ history }) => {
    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const { t } = useTranslation();
    const [createCampaignOpen, setCreateCampaignOpen] = useState(false);

    const handleAddCampaign = () => {
        setCreateCampaignOpen(true);
    };

    const hnadleCloseCreateCampaignDialog = () => {
        setCreateCampaignOpen(false);
    }

    const handleNavigateCampaign = (campaign: any) => {
        history.push(`/marketing/campaigns/${campaign.id}`);
    }


    return (
        <Box>
            <FSTable
                repositoryId="comercial-campaigns"
                onRowClick={handleNavigateCampaign}
                groupsEnabled={false}
                title='Campañas activas'
                columns={[
                    { name: 'name', label: 'Nombre', sortable: true, },
                    { name: 'totalClients', label: 'Clientes', sortable: true, },
                    { name: 'totalSupplies', label: 'Suministros', sortable: true, },
                    { name: 'averageInvoice', label: 'Fact. media', sortable: false, },

                    { name: 'volume', label: 'Volumen', sortable: true, },
                    { name: 'potentalSavings', label: 'Ahorro potencial', sortable: true, },
                    { name: 'opportunityFees', label: 'Oportunidad honorarios', sortable: true, },
                ]}
            />
        </Box>
    );
}

export default withRouter(AdminCampaignList);