import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TableHead, TableRow, TableCell, Table, TableBody, Button, makeStyles, TableHeadProps, Chip, TableBodyProps, Typography } from "@material-ui/core";
import { IValidationGroup } from "../../gateways/validation-group.interfaces";
import { IValidationRule, initialValidationRule } from "../../gateways/validation-rules.interfaces";
import useIoC from "../../contexts/ioc.context";
import { ValidationRuleGateway } from "../../gateways/validation-rules.gateway";
import ValidationRuleAddDialog from "./validation-rule-add-dialog";
import { IRule } from "../../gateways/rules.interfaces";
import ValidationRuleUpdateDialog from "./validation-rule-update-dialog";
import ValidationRuleDeleteDialog from "./validation-rule-delete-dialog";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    actionButton: {
        marginLeft: theme.spacing(1)
    }, chip: {
        margin: theme.spacing(0.5)
    }
}));

interface ValidationRulesTableProps {
    validationGroup: IValidationGroup;
    onClickReturn: () => void;
};
interface IValidationRulesTableRulesProps {
    rules: IRule[];
};

const ValidationRulesTable: FC<ValidationRulesTableProps> = ({ validationGroup, onClickReturn }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [validationRule, setValidationRule] = useState<IValidationRule>(initialValidationRule);
    const [validationRules, setValidationRules] = useState<IValidationRule[]>([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const validationRulesGateway = useIoC(ValidationRuleGateway);

    useEffect(() => {
        (async () => {
            const validationRules = await validationRulesGateway.findAll({ validationGroupId: validationGroup.id });
            setValidationRules(validationRules);
        })();

    }, [validationGroup])

    const handleAddValidationRuleDialog = () => {
        setValidationRule({ ...initialValidationRule, validationGroup: validationGroup })
        setOpenAddDialog(true);
    };
    const handleUpdateValidationRuleDialog = (item: IValidationRule) => {
        setValidationRule(item)
        setOpenUpdateDialog(true);
    };
    const handleDeleteValidationRuleDialog = (item: IValidationRule) => {
        setValidationRule(item)
        setOpenDeleteDialog(true);
    };

    const handleCreate = (item: IValidationRule) => {
        setValidationRules([...validationRules, item]);
        handleCloseAddDialog();
    };
    const handleUpdate = (item: IValidationRule) => {
        const copy = validationRules.slice();
        const index = copy.findIndex((value, index) => value.id === item.id);
        copy[index] = item;
        setValidationRules(copy);
        handleCloseUpdateDialog();
    };

    const handleDelete = (item: IValidationRule) => {
        const copy = validationRules.filter(item => item.id !== item.id);
        setValidationRules(copy);
        handleCloseDeleteDialog();
    }

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
    };
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const ValidationRulesTableHeaderText: FC = () => {
        return (<>

            <TableCell>{t(`validatioRulesTable.name`)}</TableCell>
            <TableCell>{t(`validatioRulesTable.rulesCriterias`)}</TableCell>

        </>);
    };

    const ValidationRulesTableHeader: React.ComponentType<TableHeadProps> = () => {
        return (
            <TableHead>
                <TableRow>
                    <ValidationRulesTableHeaderText />
                </TableRow>
            </TableHead>
        );
    };
    const ValidationRulesTableRules: FC<IValidationRulesTableRulesProps> = ({ rules }) => {
        return (<>{
            rules.length !== 0 ? rules.map((value, index) => {
                return (
                    <Chip
                        key={index}
                        label={value.name}
                        className={classes.chip}
                    />
                );
            }) : <Chip
                    label={t('common.none')}
                    className={classes.chip}
                />
        }
        </>);
    };
    const ValidationGroupTableBody: React.ComponentType<TableBodyProps> = () => {
        return (
            <TableBody>
                {validationRules.map((validationRule) => (<>
                    <TableRow>
                        <TableCell>{validationRule.name}</TableCell>
                        <TableCell>
                            <ValidationRulesTableRules
                                rules={validationRule.rules}
                            />
                        </TableCell>

                        <TableCell align="right">
                            <Button variant="outlined" size="small" className={classes.actionButton} color="primary"
                                onClick={() => { handleUpdateValidationRuleDialog(validationRule) }}>
                                {t('common.modify')}
                            </Button>
                            <Button variant="outlined" size="small" className={classes.actionButton} color="secondary"
                                onClick={() => { handleDeleteValidationRuleDialog(validationRule) }}>
                                {t('common.remove')}
                            </Button>
                        </TableCell>
                    </TableRow>
                </>))}
            </TableBody>
        );
    };


    return (<>
        <div>
            <Typography variant='h6'>
                {t('validatioRulesTable.validationGroup') + ': ' + validationGroup.name}
            </Typography>
            <Button variant="outlined" onClick={handleAddValidationRuleDialog}>
                {t('validatioRulesTable.addRule')}
            </Button>
            <Button variant="outlined" className={classes.actionButton} onClick={onClickReturn}>
                {t('validatioRulesTable.return')}
            </Button>

        </div>
        <Table >
            <ValidationRulesTableHeader />
            <ValidationGroupTableBody />
        </Table>

        <ValidationRuleAddDialog
            open={openAddDialog}
            validationRule={validationRule}
            onCreate={handleCreate}
            onClose={handleCloseAddDialog}
        />
        <ValidationRuleUpdateDialog
            open={openUpdateDialog}
            validationRule={validationRule}
            onUpdate={handleUpdate}
            onClose={handleCloseUpdateDialog}
        />
        <ValidationRuleDeleteDialog
            open={openDeleteDialog}
            validationRule={validationRule}
            onDeleted={handleDelete}
            onClose={handleCloseDeleteDialog}

        />
    </>)
}
export default ValidationRulesTable;