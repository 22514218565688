import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, makeStyles, createStyles, Theme, IconButton } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { IFuelSupply } from "../../gateways/fuel-supply.interface";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    crossButton: {
      position: "absolute",
      top: 7,
    },
    buttonGoDetail: {
        marginRight: 20,
        marginBottom: 20,
        borderSpacing: 4
    }
  })
);

export interface FuelSupplyGoDetailDialogProps {
    open: boolean,
    item: IFuelSupply | undefined;
    navigateToNextWindow: (item: IFuelSupply) => void,
    handleClose: () => void,
}

const FuelSupplyGoDetailDialog: FC<FuelSupplyGoDetailDialogProps> = ({ open, item, navigateToNextWindow, handleClose}) => {
    const [componentDisabled, setComponentDisabled] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles();

    const handleGoDetail = () => {
        if (!item){return;}
        setComponentDisabled(true);
        navigateToNextWindow(item);
        setComponentDisabled(false);
        handleClose();
      };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('common.navigateToDetail')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('fuelSupplyGoDetailDialog.content')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {handleClose ? (
                    <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    className={classes.crossButton}
                    >
                    <CloseIcon/>
                    </IconButton>
                ) : null}
                
                <Button 
                    onClick={handleGoDetail} 
                    color="primary" 
                    disabled={componentDisabled} 
                    className={classes.buttonGoDetail}
                    variant="outlined"
                    >
                        {t('common.navigateToDetail')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FuelSupplyGoDetailDialog;