import { inject } from 'aurelia-dependency-injection';
import { AxiosInstance } from 'axios';
import { IVolumeUnit, ICreateVolumeUnit, IUpdateVolumeUnit } from './volume-unit.interface';

@inject('axios')
export class VolumeUnitGateway {

    constructor(
        private axios: AxiosInstance
    ) { }

    public async findAll(): Promise<IVolumeUnit[]> {
        const response = await this.axios.get('volume-units');
        return response.data;
    }

    public async findById(id: number): Promise<IVolumeUnit> {
        const response = await this.axios.get(`volume-units/${id}`);
        return response.data;
    }

    public async create(dto: ICreateVolumeUnit): Promise<IVolumeUnit> {
        const response = await this.axios.post('volume-units', dto);
        return response.data;
    }

    public async update(id: number, dto: IUpdateVolumeUnit): Promise<IVolumeUnit> {
        const response = await this.axios.put('volume-units/' + id, dto);
        return response.data;
    }
}