import React, { FC, useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import CountryGateway from "../../gateways/country.gateway";
import ICountry from "../../gateways/country.interfaces";
import SelectorField from "../../components/selector-field";

interface ICountryMigrateCompaniesDialogProps {
    open: boolean;
    onMigration: (countryId: number) => void;
    onClose: () => void;
    id: number;
}

const CountryMigrateCompaniesDialog: FC<ICountryMigrateCompaniesDialogProps> = (props) => {
    const { t } = useTranslation();
    const countryGateway = useIoC(CountryGateway);
    const [countries, setCountries] = useState<ICountry[]>([]);
    const [newCountryId, setNewCountryId] = useState(0);

    const handleChange = (name: string, value: any) => {
        setNewCountryId(value);
    }

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    useEffect(() => {
        (async () => {
            const data = await countryGateway.findAll();
            setCountries(data.filter(value => value.id !== props.id));
        })();
    }, [props.id]);

    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('countryMigrateCompaniesDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('countryMigrateCompaniesDialog.content')}</DialogContentText>
                <SelectorField
                    emptyValue={false}
                    fullWidth
                    required
                    inputLabel={t('common.country')}
                    name='countries'
                    validator={validateRequired}
                    value={newCountryId}
                    values={countries.map(value => {
                        return { id: value.id, value: value.name }
                    })}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onClose() }} color="primary">{t('common.cancel')}</Button>
                <Button onClick={() => { props.onMigration(newCountryId) }} color="primary">{t('common.accept')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CountryMigrateCompaniesDialog;