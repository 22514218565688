import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { ISupplyConnectionBot, ICreateSupplyConnectionBot, IUpdateSupplyConnectionBot } from "./supply-connection-bot.interface";

@inject('axios')
export class SupplyConnectionBotGateway {
    private readonly url = 'supply-conection-bot';
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<ISupplyConnectionBot[]> {
        const response = await this.axios.get(this.url);
        return response.data;
    }

    async findByElectricContract(id: number): Promise<ISupplyConnectionBot> {
        const response = await this.axios.get(`${this.url}/${id}`);
        return response.data;
    }

    async findByFuelContract(id: number): Promise<ISupplyConnectionBot> {
        const response = await this.axios.get(`${this.url}/fuelContract/${id}`);
        return response.data;
    }

    async isCupsCancelled(cups: string): Promise<boolean> {
        const response = await this.axios.get(`${this.url}/cupsCancelled/${cups}`);
        return response.data;
    }

    async getSupplyInfo(cups: string): Promise<any> {
        try {
            const payLoad = {"cups": cups};

            const response = await this.axios.post(`${this.url}/supply_get_info/`,
                payLoad,
                {
                    headers:{
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    
                },
            );
            // console.log('getSupplyInfo response: ', response.data);
            return response.data ?? null;            
        } catch (e) {
            console.error('getSupplyInfo error: ', e);
            throw e;
        }
    }

    async create(createSupplyConnectionBot: ICreateSupplyConnectionBot): Promise<ISupplyConnectionBot> {
        const response = await this.axios.post(this.url, createSupplyConnectionBot);
        return response.data;
    }

    async update(id: number, partialCreateSupplyConnectionBot: IUpdateSupplyConnectionBot): Promise<ISupplyConnectionBot> {
        const response = await this.axios.put(`${this.url}/${id}`, partialCreateSupplyConnectionBot);
        return response.data;
    }

    async delete(id: number): Promise<void> {
        const response = await this.axios.delete(`${this.url}/${id}`);
        return response.data;
    }
}