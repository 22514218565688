import { makeStyles, Fab, Box, List, Divider } from "@material-ui/core";
import {  withRouter } from "react-router";
import { FC, useEffect, useState, useMemo } from "react";
import { useNavigator } from "../../contexts/navigator.context";
import { useTranslation } from "react-i18next";
import AssessmentIcon from '@material-ui/icons/Assessment';
import { VolumeUnitGateway } from "../../gateways/volume-units.gateway";
import useIoC from "../../contexts/ioc.context";
import { IVolumeUnit } from "../../gateways/volume-unit.interface";
import React from "react";
import AddIcon from '@material-ui/icons/Add';
import CenteredCircularProgress from "../../components/centered-circular-progress";
import AlertBox from '../../components/alert-box';
import VolumeUnitAddDialog from "./volume-unit-add-dialog";
import VolumeUnitUpdateDialog from "./volume-unit-update-dialog";
import VolumeUnitItem from "./volume-unit-item";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    }
}));

const VolumeUnitsList: FC = () => {
    const [data, setData] = useState<IVolumeUnit[]>([]);
    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const { t } = useTranslation();
    const volumeUnitGateway = useIoC(VolumeUnitGateway);
    const [item, setItem] = useState<IVolumeUnit>();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('volumeUnitList.title'),
                icon: AssessmentIcon
            }
        });
    }, []);

    useEffect(() => {
        (async () => {
            setIsDataLoading(true);
            const volumeUnits = await volumeUnitGateway.findAll();
            setData(volumeUnits);
            setIsDataLoading(false);
        })();
    }, [VolumeUnitGateway]);

    const handleClickUpdateButtonItem = (item: IVolumeUnit) => {
        setItem(item);
        setOpenUpdateDialog(true);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCreatedVolumeUnit = (item: IVolumeUnit) => {
        setData([...data, item]);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleUpdatedVolumeUnit = (item: IVolumeUnit) => {
        let copy = data.slice();
        const index = copy.findIndex((value) => value.id === item.id);
        copy[index] = item;
        setData(copy);
        setOpenUpdateDialog(false);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
    };

    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />
        }

        if (isDataEmpty) {
           return <AlertBox variant='info'>{t('common.noItems')}</AlertBox>
        }
        return(
            <List>
                {data.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <VolumeUnitItem
                                item={item}
                                onClickUpdateButton={handleClickUpdateButtonItem} />
                            {index < data.length - 1 ? <Divider variant='inset' component='li' /> : null}
                        </div>
                    );
                })}
            </List>
        );
    };

    return (
        <Box>
            <Fab color='primary' aria-label='add' className={classes.fab} onClick={handleOpenAddDialog}>
                <AddIcon />
            </Fab>
            <Content />
            <VolumeUnitAddDialog
                open={openAddDialog}
                handleCreated={handleCreatedVolumeUnit}
                handleClose={handleCloseAddDialog} />
            <VolumeUnitUpdateDialog
                open={openUpdateDialog}
                item={item}
                onItemUpdate={handleUpdatedVolumeUnit}
                onClose={handleCloseUpdateDialog} />
        </Box>
    );
}

export default withRouter(VolumeUnitsList);