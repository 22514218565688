import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import IRegisterElectricBillUpdate, { IRegisterElectricBill, IRegisterElectricBillCreate, IRegisterElectricBillFind } from "./register-electric-bill.interface";
import { IPowerDetail } from "./period-power-details.interface";
import { IEnergyDetail } from "./period-energy-details.interface";

const registerElectricBillGatewayResource = 'register-electric-bill';

@inject('axios')
export class RegisterElectricBillGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IRegisterElectricBill[]> {
        const response = await this.axios.get(registerElectricBillGatewayResource);
        return response.data;
    }

    async findById(id: number): Promise<IRegisterElectricBillFind> {
        const url = `${registerElectricBillGatewayResource}/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    async findPastPeriodPowerDetailsMonth(date: Date): Promise<IPowerDetail[]> {
        const url = `${registerElectricBillGatewayResource}/pastPowerMonth/` + this.formatDate(date);
        const response = await this.axios.get(url);
        return response.data;
    }

    async findPastPeriodEnergyDetailsMonth(date: Date): Promise<IEnergyDetail[]> {
        const url = `${registerElectricBillGatewayResource}/pastEnergyMonth/` + this.formatDate(date);
        const response = await this.axios.get(url);
        return response.data;
    }

    async findPastPeriodPowerDetailsYear(date: Date): Promise<IPowerDetail[]> {
        const url = `${registerElectricBillGatewayResource}/pastPowerYear/` + this.formatDate(date);
        const response = await this.axios.get(url);
        return response.data;
    }

    async findPastPeriodEnergyDetailsYear(date: Date): Promise<IEnergyDetail[]> {
        const url = `${registerElectricBillGatewayResource}/pastEnergyYear/` + this.formatDate(date);
        const response = await this.axios.get(url);
        return response.data;
    }

    async create(registerElectricBillToCreate: IRegisterElectricBillCreate): Promise<IRegisterElectricBill> {
        const response = await this.axios.post(registerElectricBillGatewayResource, registerElectricBillToCreate);
        return response.data;
    }

    async update(id: number, registerElectricBillToUpdate: IRegisterElectricBillUpdate): Promise<IRegisterElectricBill> {
        const url = `${registerElectricBillGatewayResource}/${id}`;
        const response = await this.axios.put(url, registerElectricBillToUpdate);
        return response.data;
    }

    async delete(id: number): Promise<void> {
        const url = `${registerElectricBillGatewayResource}/${id}`;
        const response = await this.axios.delete(url);
        return response.data;
    }

    formatDate = (date: Date) => {
        var dateToReturn: string;
        const day: number = date.getDate();
        const month: number = date.getMonth() + 1;
        const year: number = date.getFullYear();
        dateToReturn = year.toString()
        if (month < 10) {
            dateToReturn  = dateToReturn + `-0${month}`
        }else{
            dateToReturn  = dateToReturn + `-${month}`
        }

        if (day < 10) {
            dateToReturn  = dateToReturn + `-0${day}`
        }else{
            dateToReturn  = dateToReturn + `-${day}`
        }
        return dateToReturn;   
    };
}
