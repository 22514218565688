import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import IContactType, { defaultIContactType } from '../../gateways/contact-types.interfaces';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { ContactTypesGateway } from '../../gateways/contact-types.gateway';
import useIoC from '../../contexts/ioc.context';
import { useTranslation } from 'react-i18next';

export interface IContactTypeAddDialogProps {
    open: boolean,
    onClose: () => void,
    onContactTypeCreated: (contactType: IContactType) => void
}

const ContactTypeAddDialog: FC<IContactTypeAddDialogProps> = ({ open, onClose, onContactTypeCreated }) => {
    const [item, setItem] = useState<IContactType>();
    const [name, setName] = useState('');
    const [errorNamefield, setErrorNamefield] = useState(false);
    const [textfieldDisabled, setTextfieldDisabled] = useState(false);
    const [helperText, setHelperText] = useState('');
    const snackbar = useSnackbar();
    const contactTypesGateway = useIoC(ContactTypesGateway);
    const { t } = useTranslation();

    useEffect(()=>{
        setName('');
    },[open])

    const handleChangeName = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setName(value);
        if (value === '') {
            setErrorNamefield(true);
            setHelperText(t('messages.requiredField') as string);
        } else {
            setErrorNamefield(false)
            setHelperText('');
        }
    }
    const handleCreateContactType = () => {
        if (name === '') {
            setErrorNamefield(true);
            setHelperText(t('messages.requiredField') as string);
            return;
        }
        setTextfieldDisabled(true);
        (async () => {
            try {
                const contactType = await contactTypesGateway.create({ name });

                snackbar.enqueueSnackbar(t('contactTypeAddDialog.createContactSucceded', { id: contactType.id }), {
                    variant: 'success',
                });

                setItem(contactType);
                onContactTypeCreated(contactType);
            } catch (e) {     
                const er = (e as any);  
                if(er.response.data.key === 'creatingEntityError'){
                  const key = 'contactTypeErrorHandler.' + er.response.data.key;
                  const message = "validation" in er ? er.validation : t(key);
                  snackbar.enqueueSnackbar(message, { variant: "error" });
                }else{
                  const message = "validation" in er ? er.validation : t('messages.defaultError');
                  snackbar.enqueueSnackbar(message, { variant: "error" });
                }
            } finally {
                setTextfieldDisabled(false);
                onClose();
            }
        })();
    };
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('contactTypeAddDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('contactTypeAddDialog.content')}</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t('common.name')}
                    type="text"
                    fullWidth
                    onChange={handleChangeName}
                    value={name}
                    required
                    error={errorNamefield}
                    helperText={helperText}
                    disabled={textfieldDisabled}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={textfieldDisabled}>{t('common.cancel')}</Button>
                <Button onClick={handleCreateContactType} color="primary" disabled={textfieldDisabled}>{t('common.accept')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ContactTypeAddDialog;