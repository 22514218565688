import React, { useEffect, useImperativeHandle, useState } from "react";
import { useHistory } from "react-router";
import { AccessType, IAccessType } from "../../../../../gateways/access-type.interfaces";
import { fuelTypes, IFuelType } from "../../../../../gateways/fuel-types.interface";
import { IFormBaseProps, IFormBaseRefAttributes, IFormBaseComponent } from "./interfaces";
import { ICreateFuelSupply, IFuelSupply } from "../../../../../gateways/fuel-supply.interface";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { FuelSupplyGateway } from "../../../../../gateways/fuel-supply.gateway";
import useIoC from "../../../../../contexts/ioc.context";
import { AccessTypeGateway } from "../../../../../gateways/access-type.gateway";
import { FuelTypesGateway } from "../../../../../gateways/fuel-types.gateway";
import FuelSupplyCompleteForm from "./supply-forms/fuel.supply-form";
import FuelSupplyGoDetailDialog from "../../../../fuel-supply/fuel-supply-go-detail-dialog";
import EstablishmentUpsertDialog from "../../../../establishment/establishment-upsert-dialog";
import ComplexUpsertDialog from "../../../../complex/complex-upsert-dialog";
import CompanyAddDialog from "../../../../companies/company-add-dialog";
import ClientAddDialog from "../../../../clients/client-add-dialog";
import { EstablishmentGateway } from "../../../../../gateways/establishment.gateway";
import { CompanyGateway } from "../../../../../gateways/company.gateway";
import { ComplexGateway } from "../../../../../gateways/complex.gateway";
import { ClientsGateway } from "../../../../../gateways/clients.gateway";
import { IClient } from "../../../../../gateways/clients.interfaces";
import ICompany from "../../../../../gateways/company.interface";
import { IComplex } from "../../../../../gateways/complex.interfaces";
import { IEstablishment } from "../../../../../gateways/establishment.interface";
import { AxiosError } from "axios";

export const FuelComponent: IFormBaseComponent = React.forwardRef<
  IFormBaseRefAttributes,
  IFormBaseProps
>(({ operate }, ref) => {
  const history = useHistory();
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [ loading, setLoading] = useState(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [complexes, setComplexes] = useState<IComplex[]>([]);
  const [establishments, setEstablishments] = useState<IEstablishment[]>([]);
  const [errorMissingData, setErrorMissingData] = useState(false);
  const [openAddEstablishmentDialog, setOpenAddEstablishmentDialog] =
    useState(false);
  const [openAddComplexDialog, setOpenAddComplexDialog] = useState(false);
  const [openAddClientDialog, setOpenAddClientDialog] = useState(false);
  const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);
  const [openGoDetailDialog, setOpenGoDetailDialog] = useState(false);
  const [client, setClient] = useState<IClient | null>(null);
  const [company, setCompany] = useState<ICompany | null>(null);
  const [createdFuelSupply, setCreatedFuelSupply] = useState<IFuelSupply | null>(null);
  const [fuelSupply, setFuelSupply] = useState<ICreateFuelSupply>({
    reference: operate.cups,
    fuelTypeId: fuelTypes.GasNatural,
    accessTypeId: AccessType.Red,
    complexId: null,
    establishmentId: null,
    code: "", 
    emailNotification: null, 
    invoicesFolder: "", 
    providerId: null,
    sendEmail: false,
    sendClientEmail: false,
    blacklisted: false,
    startCaptureDate: new Date(),
    separatedMeterAccount: false,
    separatedMeterInvoiceFolder: "",
    separatedMeterStartCaptureDate: new Date(),
    separatedMeterStartValidationsDate: new Date(), 
  });

  const complex =
  complexes.find((x) => x.id === fuelSupply.complexId) ?? null;

  const establishment =
    establishments.find((x) => x.id === fuelSupply.establishmentId) ?? null;

  const companyGateway = useIoC(CompanyGateway);
  const complexGateway = useIoC(ComplexGateway);
  const clientsGateway = useIoC(ClientsGateway);
  const establishmentGateway =
    useIoC(EstablishmentGateway);
  const fuelSupplyGateway = useIoC(FuelSupplyGateway);

  const accessTypeGateway = useIoC(AccessTypeGateway);
  const [accessTypes, setAccessTypes] = useState<IAccessType[]>([
    { id: 0, name: "" },
  ]);
  const [fuelTypes_, setFuelTypes_] = useState<IFuelType[]>([]);
  const fuelTypeGateway = useIoC(FuelTypesGateway);

  const navigateToNextWindow = () => {
    client &&
      (fuelSupply.establishmentId
        ? history.push(
            `clients/${client.id}/establishments/${fuelSupply.establishmentId}/fuelSupply/${createdFuelSupply?.id}`
          )
        : history.push(
            `clients/${client.id}/complex/${fuelSupply.complexId}/fuelSupply/${createdFuelSupply?.id}`
          ));
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  const handleClickAddEstablishmentDialog = () => {
    setOpenAddEstablishmentDialog(true);
  };

  const handleCloseAddEstablishmentDialog = () => {
    setOpenAddEstablishmentDialog(false);
  };

  const handleClickAddComplexDialog = () => {
    setOpenAddComplexDialog(true);
  };

  const handleCloseAddComlexDialog = () => {
    setOpenAddComplexDialog(false);
  };

  const handleOpenAddClientDialog = () => {
    setOpenAddClientDialog(true);
  };

  const handleCloseAddClientDialog = () => {
    setOpenAddClientDialog(false);
  };

  const handleClickAddCompanyDialog = () => {
    setOpenAddCompanyDialog(true);
  };

  const handleCloseAddCompanyDialog = () => {
    setOpenAddCompanyDialog(false);
  };

  const handleCreateCompany = (item: ICompany) => {
    setCompanies([...companies, item]);
    setCompany(item);
  };

  const handleCreateComplex = (item: IComplex) => {
    setComplexes([...complexes, item]);
    setFuelSupply({
      ...fuelSupply,
      complexId: item.id,
      establishmentId: null,
    });
  };

  const handleCreateClient = (item: IClient) => {
    setClients([...clients, item]);
    setClient(item);
  };

  const handleCreateEstablishment = (item: IEstablishment) => {
    setEstablishments([...establishments, item]);
    setFuelSupply({
      ...fuelSupply,
      complexId: null,
      establishmentId: item.id,
    });
  };

  const createFuelSupply = async (item: ICreateFuelSupply) => {
    setLoading(true);
    const fuelSupply = await fuelSupplyGateway.create(item);
    snackbar.enqueueSnackbar(
      t("fuelSupplyUpsertDialog.createSucceded", { 
        id: fuelSupply.id,
        reference: fuelSupply.reference,
       }),
      { variant: "success" }
    );
    setCreatedFuelSupply(fuelSupply);
    setOpenGoDetailDialog(true);
    setLoading(false);
  };

  const upsertFuelSupply = async (item: ICreateFuelSupply) => {
    try {
      await createFuelSupply(item); 
    } catch (e) {
      const er = e as any;
      if ("isAxiosError" in er && er.isAxiosError === true) {
        const ae = e as AxiosError;
        const key = "fuelSupplyErrorHandler." + (ae.response?.data as any).key;
        if (key && key.includes("creatingEntityError")) {
          const message = "validation" in er ? er.validation : t(key);
          snackbar.enqueueSnackbar(message, { variant: "error" });
        } else {
          const message =
            "validation" in er ? er.validation : t("messages.defaultError");
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      }
    } 
  };

  useImperativeHandle(ref, () => ({
    accept: async () => {
      if (!fuelSupply.accessTypeId || !fuelSupply.fuelTypeId || !fuelSupply.reference || 
        (!fuelSupply.complexId && !fuelSupply.establishmentId)) {
        snackbar.enqueueSnackbar(t("messages.requiredFields"), {
          variant: "error",
        });
        setErrorMissingData(true);
        return;
      }
      setErrorMissingData(false);
      upsertFuelSupply(fuelSupply); 
    }
  }));

  useEffect(() => {
    (async () => {
      setLoading(true);
      const clients = await clientsGateway.findAll({ isMarketing: false });
      setClients(clients);
      setLoading(false);
    })();
  }, [clientsGateway]);

  useEffect(() => {
    (async () => {
      if (client) {
        setLoading(true);
        const companies = await companyGateway.findAll({ clientId: client.id });
        setCompanies(companies);
        setLoading(false);
      }
    })();
  }, [companyGateway, client]);

  useEffect(() => {
    (async () => {
      if (company) {
        setLoading(true);
        const [complexes, establishments] = await Promise.all([
          complexGateway.findAll({ companyId: company.id }),
          establishmentGateway.findAll({ companyId: company.id }),
        ]);
        setComplexes(complexes);
        setEstablishments(establishments);
        setLoading(false);
      }
    })();
  }, [establishmentGateway, complexGateway, company]);
 
  useEffect(() => {
    (async () => {
      setLoading(true);
      const fuelType_ = await fuelTypeGateway.findAll();
      const accessTypes_ = await accessTypeGateway.findAll();
      setFuelTypes_(fuelType_);
      setAccessTypes(accessTypes_);
      setLoading(false);
    })();
  }, []);
  
  return (
    <>
      <FuelSupplyCompleteForm
        errorMissingData={errorMissingData}
        selectedClient={client}
        selectedCompany={company}
        selectedComplex={complex}
        selectedEstablishment={establishment}
        onSelectClient={setClient}
        onSelectCompany={setCompany}
        clients={clients}
        companies={companies}
        complexes={complexes}
        establishments={establishments}
        accessTypes={accessTypes}
        fuelTypes={fuelTypes_}
        supply={fuelSupply}
        validateRequired={validateRequired}
        onChangeSupply={setFuelSupply}
        onClickAddClientButton={handleOpenAddClientDialog}
        onClickAddCompanyButton={handleClickAddCompanyDialog}
        onClickAddComplexButton={handleClickAddComplexDialog}
        onClickAddEstablishmentButton={handleClickAddEstablishmentDialog}
      />
      <ClientAddDialog
        open={openAddClientDialog}
        onClose={handleCloseAddClientDialog}
        onClientCreated={handleCreateClient}
      />

      {client && (
        <CompanyAddDialog
          open={openAddCompanyDialog}
          clientId={client.id}
          onClose={handleCloseAddCompanyDialog}
          onItemCreated={handleCreateCompany}
          onLoading={() => loading}
        />
      )}

      {client && company && (
        <ComplexUpsertDialog
          open={openAddComplexDialog}
          clientId={client.id}
          companyId={company.id}
          item={undefined}
          onClose={handleCloseAddComlexDialog}
          onItemUpsert={handleCreateComplex}
          onLoading={() => loading}
        />
      )}

      {client && company && (
        <EstablishmentUpsertDialog
          open={openAddEstablishmentDialog}
          item={undefined}
          clientId={client.id}
          companyId={company.id}
          complexId={fuelSupply?.complexId ?? undefined}
          onItemUpsert={handleCreateEstablishment}
          onLoading={()=>false}
          onClose={handleCloseAddEstablishmentDialog}
        />
      )}

      {createdFuelSupply &&
        <FuelSupplyGoDetailDialog
          open={openGoDetailDialog}
          item={createdFuelSupply}
          navigateToNextWindow={navigateToNextWindow}
          handleClose={() => setOpenGoDetailDialog(false)}
        />
      }
    </>
  );
});