import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IRepositoryDefinitionProperty } from "./repository-definition.interfaces";

const repositoryParameter = 'repository-definition-property';
@inject('axios')
export class RepositoryDefinitionPropertyGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IRepositoryDefinitionProperty[]> {
        const response = await this.axios.get(repositoryParameter);
        return response.data;
    }

    async findByValidationContextId(validationContextId: string): Promise<IRepositoryDefinitionProperty[]>{
        const response = await this.axios.get(repositoryParameter+'/validationContext/'+validationContextId); 
        return response.data;

    }
}