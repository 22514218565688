import { makeStyles, List, Divider, Box } from "@material-ui/core";
import { useParams, useHistory } from "react-router";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import AddIcon from "@material-ui/icons/Add";
import OpacityIcon from "@material-ui/icons/Opacity";
import React, { useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router";
import { useTranslation } from "react-i18next";
import EstablishmentDrawer from "../../components/establishment-drawer";
import ComplexDrawer from "../../components/complex-drawer";
import EstablishmentIcon from "@material-ui/icons/Store";
import ComplexIcon from "@material-ui/icons/Dashboard";
import CenteredCircularProgress from "../../components/centered-circular-progress";
import AlertBox from "../../components/alert-box";
import {
  initialIWaterSupplyInterFace,
  IWaterSupplyInterface,
} from "../../gateways/water-supply.interfaces";
import WaterSupplyItem from "./water-supply-item";
import { WaterSupplyGateway } from "../../gateways/water-supply.gateway";
import useIoC from "../../contexts/ioc.context";
import WaterSupplyCreate from "./water-supply-create";
import { setDate } from "date-fns/esm";
import WaterSupplyGoDetailDialog from "./water-supply-detail-dialog";
import { BlacklistGateway } from "../../gateways/blacklist.gateway";

interface IWaterSupplyRouteParams {
  [x: string]: string | undefined;
  complexId: string;
  establishmentId: string;
  clientId: string;
}

interface IBlacklistItems {
  itemsInBlacklist: string [];
}

const initialBlacklistItems: IBlacklistItems = {
  itemsInBlacklist: []
}

const useStyles = makeStyles((theme) => ({
  itemAvatarIcon: {
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
}));

const WaterSupplyList = () => {
  const classes = useStyles();
  const { complexId } = useParams<IWaterSupplyRouteParams>();
  const { establishmentId } = useParams<IWaterSupplyRouteParams>();
  const { clientId } = useParams<IWaterSupplyRouteParams>();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const [item, setItem] = useState<IWaterSupplyInterface>(
    initialIWaterSupplyInterFace
  );
  const history = useHistory();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState<IWaterSupplyInterface[]>([]);
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [goDetailDialog, setGoDetailDialog] = useState<boolean>(false);
  const isDataEmpty = useMemo(
    () => !isDataLoading && data.length === 0,
    [isDataLoading, data]
  );
  const [blackListItems, setBlacklistItems] = useState<IBlacklistItems>(initialBlacklistItems);


  const handleClickFabButton = () => {
    setItem(initialIWaterSupplyInterFace);
    setOpenCreateDialog(true); 
  };
  const handleCloseCreateDialog = () => {
    setItem(initialIWaterSupplyInterFace);
    setOpenCreateDialog(false);
  };
  const handleCreate = (item: IWaterSupplyInterface) => {
    setOpenCreateDialog(false);
    setData([item, ...data]);
    setItem(item);
    setGoDetailDialog(true);
  };
  const handleCloseGoDetailDialog = () => {
    setGoDetailDialog(false);
  };
  const goToDetail = (item: IWaterSupplyInterface) => {
    history.push(`${url}/${item.id}`);
  };
  const handleClickItem = (item: IWaterSupplyInterface) => {
    goToDetail(item);
  };

  const waterSupplyGateway = useIoC(WaterSupplyGateway);
  const blacklistGateway = useIoC(BlacklistGateway);
  
  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      let waterSupplyList;
      if (establishmentId) {
        waterSupplyList = await waterSupplyGateway.findAll({ establishmentId });
        const result = await blacklistContent(waterSupplyList);
        setData(result);
      } else if (complexId) {
        waterSupplyList = await waterSupplyGateway.getWaterComplexSupplies(Number(complexId));
        const result = await blacklistContent(waterSupplyList);
        setData(result);
      }
      setIsDataLoading(false);
    })();
  }, [waterSupplyGateway]);

  async function blacklistContent(data: IWaterSupplyInterface[]){

    var arrBlacklist= [];

    for(var i = 0; i < data.length; i++){
        if(data[i].referencia.length === 20){
            arrBlacklist.push(data[i].referencia);
            arrBlacklist.push(data[i].referencia + "0F");
        }
        else if(data[i].referencia.length === 22){
            arrBlacklist.push(data[i].referencia);
            arrBlacklist.push(data[i].referencia.slice(0, 20));
        }
        else{
            arrBlacklist.push(data[i].referencia);
        }
    }

    const result = await blacklistGateway.findCups(arrBlacklist);

    data.map((item, index) => {
      let blacklistedElement: string | undefined;
      if(item.referencia.length === 20){
          blacklistedElement = result.find((element: string) => 
          element === item.referencia || element === item.referencia+"0F");
      }
      else if(item.referencia.length === 22){
          blacklistedElement = result.find((element: string) => 
          element === item.referencia || element === item.referencia.slice(0, 20));
      }
      else{
          blacklistedElement = result.find((element: string) => element === item.referencia);
      }
        // const blacklistedElement = result.data.find((element: {_id: string}) => element._id === item.referencia);
        if(blacklistedElement !== undefined) item.blacklisted = true;
        else item.blacklisted = false;
    })
    
    return data;
  }

  const Content = () => {
    if (isDataLoading) {
      return <CenteredCircularProgress />;
    }

    if (isDataEmpty) {
      return <AlertBox variant="info">{t("common.noItems")}</AlertBox>;
    }
    return (
      <List>
        {data.map((item, index) => {
          return (
            <div key={item.id}>
              <WaterSupplyItem item={item} onClickItem={handleClickItem} establishmentId={establishmentId}/>
              {index < data.length - 1 ? (
                <Divider variant="inset" component="li" />
              ) : null}
            </div>
          );
        })}
      </List>
    );
  };
  return (
    <Box>
      <ScreenGlobalStructure
        buttonIcon={<AddIcon />}
        headerIcon={OpacityIcon}
        matchUrl={url}
        onButtonClick={handleClickFabButton}
        title={t("waterSupplyList.title")}
        clientId={clientId !== undefined ? clientId : ""}
        loading={isDataLoading}
        drawer={
          establishmentId ? (
            <EstablishmentDrawer
              matchUrl={url}
              lateralMenu={4}
              icon={
                <EstablishmentIcon
                  fontSize="large"
                  className={classes.itemAvatarIcon}
                />
              }
              goBack={true}
            />
          ) : (
            <ComplexDrawer
              matchUrl={url}
              lateralMenu={5}
              icon={
                <ComplexIcon
                  fontSize="large"
                  className={classes.itemAvatarIcon}
                />
              }
              goBack={true}
            />
          )
        }
      >
        <Content />
      </ScreenGlobalStructure>
      <WaterSupplyCreate
        complexId={parseInt(complexId)}
        establishmentId={parseInt(establishmentId)}
        onClose={handleCloseCreateDialog}
        open={openCreateDialog}
        handleCreate={handleCreate}
      />
      <WaterSupplyGoDetailDialog
        handleClose={handleCloseGoDetailDialog}
        item={item}
        navigateToNextWindow={goToDetail}
        open={goDetailDialog}
      />
    </Box>
  );
};

export default WaterSupplyList;
