
import  { IEstablishment }  from "../../gateways/establishment.interface";
import { createContext, Dispatch } from "react";

export type StateType = { establishment: IEstablishment | null };
export type ActionType = { type: 'update-establishment', payload: IEstablishment };

export const EstablishmentReducer = (state: StateType, action: ActionType) => {
    switch (action.type) {
        case 'update-establishment':
            return { ...state, establishment: action.payload };
    }
    return state;
};

export const EstablishmentContext = createContext<[StateType, Dispatch<ActionType>]>([
    { establishment: null },
    (state) => state,
]);
