import React, { FC, useCallback, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Button, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { useNavigator } from '../contexts/navigator.context';
import UserIcon from '@material-ui/icons/Person';
import { useUser } from "../contexts/user.context";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    },
    title: {
        flexGrow: 1,
    },
    sectionMobile: {},
    userButton: {
        color: '#fff',
        '& svg': { marginLeft: theme.spacing(1) }
    }
}));

const Header: FC = () => {
    const [{ user, roles }, userDispatch] = useUser();
    const classes = useStyles();
    const [, navDispatch] = useNavigator();

    const [userElement, setUserElement] = useState<any>();

    const handleToggleNavigator = useCallback(() => {
        navDispatch({ type: 'toggle-nav' });
    }, [navDispatch]);

    const handleOpenUserMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) =>
        setUserElement(event.currentTarget), []);

    const handleCloseUserMenu = useCallback(() =>
        setUserElement(null), []);

    const handleTerminateSession = useCallback(() => {
        userDispatch({ type: 'terminate-session' });
        handleCloseUserMenu();
    }, [userDispatch, handleCloseUserMenu])

    return (
        <div>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" onClick={handleToggleNavigator} className={classes.menuButton} color="inherit">
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap>
                        Enovam
                    </Typography>
                    <div className={classes.grow} />
                    <Button
                        aria-controls="user-menu"
                        aria-haspopup="true"
                        onClick={handleOpenUserMenu}
                        className={classes.userButton}
                    >
                        {process.env.NODE_ENV === 'development' ?
                            user ? user + ` [${roles && roles.length > 0 ? roles?.toString() : "Sin permisos"}]` : 'N/a' :
                            user ?? 'N/a'
                        }

                        {/* {user ?? 'N/a'} */}
                        <UserIcon />
                    </Button>
                </Toolbar>
            </AppBar>
            <Menu
                id="user-menu"
                anchorEl={userElement}
                keepMounted
                open={Boolean(userElement)}
                onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={handleTerminateSession}>
                    Cerrar sesión
                </MenuItem>
            </Menu>
        </div>
    )
}

export default Header;