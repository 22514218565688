import React, { FC, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useIoC from '../../contexts/ioc.context';
import { MarketingGateway } from '../../gateways/marketing.gateway';
import { ICampaign } from '../../gateways/marketing.interfaces';
import { useSnackbar } from 'notistack';

export interface ICreateCampaignDialogProps {
    open: boolean;
    onClose: () => void;
    onCreated?: (campaign: ICampaign) => void;
}

const CreateCampaignDialog: FC<ICreateCampaignDialogProps> = ({ open, onClose, onCreated }) => {
    const marketingGateway = useIoC(MarketingGateway);
    const snackbar = useSnackbar();

    const [creating, setCreating] = useState(false);
    const [name, setName] = useState('');

    const handleClose = () => typeof onClose === 'function' ? onClose() : undefined;

    const handleCreateCampaign = () => {
        (async () => {
            setCreating(true);

            try {
                const campaign = await marketingGateway.createCampaign({ name });

                if (typeof onCreated === 'function') {
                    onCreated(campaign);
                }

                handleClose();

                snackbar.enqueueSnackbar('Se ha creado la camapaña: ' + campaign.id, {
                    variant: 'success',
                });
            } catch (e) {
                console.error('Ha ocurrido un error al crear la campaña', e);
                snackbar.enqueueSnackbar('Ha ocurrido un error al crear la campaña', {
                    variant: 'error',
                });
                setCreating(false);
            }
        })();
    }

    const handleChange = (setter: (value: any) => void) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            setter(event.target.value);
        }
    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Alta de nueva campaña</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Create una camapaña en la que podrás asociar clientes potenciales.
                </DialogContentText>
                <TextField
                    autoFocus
                    disabled={creating}
                    margin="dense"
                    id="name"
                    label="Nombre de la campaña"
                    type="text"
                    fullWidth
                    onChange={handleChange(setName)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary" disabled={creating}>
                    Cancelar
                </Button>
                <Button onClick={handleCreateCampaign} color="primary" disabled={creating}>
                    Crear campaña
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateCampaignDialog;