import React, { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { ValidationContextGateway } from "../../gateways/validation-context.gateway";
import { initialValidContext, IValidationContext } from "../../gateways/validation-context.interfaces";
import ValidationContextForm from "./validation-context-form";

interface IValidationContextAddDialogProps {
    open: boolean;
    onItemCreate: (item: IValidationContext) => void;
    onClose: () => void;
}

const ValidationContextAddDialog: FC<IValidationContextAddDialogProps> = (props) => {
    const { t } = useTranslation();
    const [item, setItem] = useState<IValidationContext>(initialValidContext);
    const validationContextGateway = useIoC(ValidationContextGateway);
    const snackbar = useSnackbar();

    const handleCancel = () => {
        setItem(initialValidContext);
        props.onClose();
    };

    const handleChange = (data: IValidationContext) => {

        setItem(data);
    };

    const handleCreate = async () => {
        (async () => {
            try {
                const validationContext = await validationContextGateway.create(item);
                snackbar.enqueueSnackbar(t('validationContextAddDialog.createSucceded', { name: validationContext.name }), { variant: "success" });
                setItem(initialValidContext);
                props.onItemCreate(validationContext);
            } catch (e) {
                const er = e as any;
                if (er.response.data.key === 'creatingEntityError') {
                    const key = 'sectorErrorHandler.' + er.response.data.key;
                    const message = "validation" in er ? er.validation : t(key);
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                } else {
                    const message = "validation" in er ? er.validation : t('messages.defaultError');
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                }
            } finally {
                props.onClose();
            }

        })();
    };



    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }}>
            <DialogTitle>{t('validationContextAddDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('validationContextAddDialog.content')}</DialogContentText>
                <ValidationContextForm
                    item={item}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleCreate} color="primary">{t('common.create')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ValidationContextAddDialog;