import { AxiosInstance } from "axios";
import { IMarketingTaskType, IClientMarketingTask, IMarketingTaskResult, IClientMarketing, IUpdateClientMarketingDto, ICampaign, ICreateCampaignRequest } from "./marketing.interfaces";
import { inject } from "aurelia-dependency-injection";

@inject('axios')
export class MarketingGateway {
    constructor(private axios: AxiosInstance) {

    }

    public async findAllClients(): Promise<IClientMarketing[]> {
        const response = await this.axios.get('marketing/client');
        return response.data;
    }

    public async findClientById(id: number): Promise<IClientMarketing> {
        const response = await this.axios.get('marketing/client/' + id);
        return response.data;
    }

    public async findAllCampaigns(): Promise<ICampaign[]> {
        const response = await this.axios.get('marketing/campaigns/');
        return response.data;
    }

    public async getStadistics(dto: any): Promise<ICampaign[]> {
        const response = await this.axios.post('marketing/stadistics', dto);
        return response.data;
    }

    public async createCampaign(request: ICreateCampaignRequest): Promise<ICampaign> {
        const response = await this.axios.post('marketing/campaigns/', request);
        return response.data;
    }

    public async deleteCampaign(campaignId: number): Promise<void> {
        await this.axios.delete('marketing/campaigns/' + campaignId);
    }

    public async addClientToCampaign(campaignId: number, clientId: number): Promise<void> {
        await this.axios.post(`marketing/campaigns/${campaignId}/client/${clientId}`);
    }

    public async removeClientToCampaign(campaignId: number, clientId: number): Promise<void> {
        await this.axios.delete(`marketing/campaigns/${campaignId}/client/${clientId}`);
    }


    public async createClient(dto: any): Promise<IClientMarketing> {
        const response = await this.axios.post('marketing/client', dto);
        return response.data;
    }

    public async updateClient(id: number, dto: IUpdateClientMarketingDto): Promise<IClientMarketing> {
        const response = await this.axios.put(`marketing/client/${id}`, dto);
        return response.data;
    }

    public async findCalendarTasks(from: Date, to: Date): Promise<IClientMarketingTask[]> {
        const response = await this.axios.get('marketing/calendar-tasks?from=' + encodeURIComponent(from.toISOString()) + '&to=' + encodeURIComponent(to.toISOString()));
        return response.data;
    }

    public async getTaskTypes(): Promise<IMarketingTaskType[]> {
        const response = await this.axios.get('marketing/task-types');
        return response.data;
    }

    public async getTaskResults(): Promise<IMarketingTaskResult[]> {
        const response = await this.axios.get('marketing/task-results');
        return response.data;
    }

    public async createTask(dto: any): Promise<IClientMarketingTask> {
        const response = await this.axios.post('marketing/create-task', dto);
        return response.data;
    }

    public async completeTask(dto: any): Promise<IClientMarketingTask> {
        const response = await this.axios.post('marketing/complete-task', dto);
        return response.data;
    }

    public async createTasksForGroup(groupId: number): Promise<void> {
        const response = await this.axios.post('marketing/create-tasks-group', { groupId });
        return response.data;
    }

    public async initActionsMarketing(clientId: number): Promise<void> {
        await this.axios.post(`marketing/client/${clientId}/init-actions`);
    }

    public async abortTask(taskId: number): Promise<void> {
        await this.axios.delete(`marketing/task/${taskId}`);
    }

    public async setTaskDate(taskId: number, taskDate: Date): Promise<void> {
        await this.axios.put(`marketing/task/${taskId}/set-task-date`, { taskDate });
    }
}