import React, { FC, useContext, Fragment } from "react";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import FSTableHeadCell from "./table-head-cell";
import FSTableBodyCell from "./table-body-cell";
import { StateContext } from "./context";
import LinearProgress from '@material-ui/core/LinearProgress';
import FSTableBodyRow from "./table-body-row";

const FSTable: FC = () => {
    const state = useContext(StateContext);

    const handleItemClick = (item: any) => {

        return () => {

        }
    }

    console.log('fstable rows: ', state.data);

    return (
        <Fragment>
            {
                state.fetchingData
                    ? <LinearProgress />
                    : null
            }
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {
                                state.columns.map((col, index) => (
                                    <FSTableHeadCell
                                        key={'column_' + index}
                                        column={col}
                                    />
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.data.map((item, index) => (<FSTableBodyRow key={'row_' + index} item={item} />))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    )
};

export default FSTable;