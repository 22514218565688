import React from "react";
import {  Switch, Route, useRouteMatch } from "react-router";
import RegisterFuelBillList from "./register-fuel-bill-list";
import RegisterFuelBillDetail from "./register-fuel-bill-detail"


const RegisterFuelBillRouter = () => {
    
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/`} exact>
                <RegisterFuelBillList/>
            </Route>
            <Route path={`${path}/:registerFuelBillId`} exact>
                <RegisterFuelBillDetail/>
            </Route>
        </Switch>
    );
};
export default RegisterFuelBillRouter;
