import React, { FC, Fragment } from "react";
import { withRouter, RouteComponentProps, Route, Switch } from "react-router";
import SupplyBotList from "./supply-bot-list";
import SupplyBotCreate from "./supply-bot-create";
import { SupplyBotDetail } from "./supply-bot-detail";
import { SupplyBotHistory } from "./supply-bot-history";
import { useUser } from "../../contexts/user.context";

const SupplyBotsIndex: FC<RouteComponentProps> = ({ match }) => {
    const [{ canViewBotsMenu }] = useUser();
    return (
        <Switch>
            {canViewBotsMenu ? <Route path={`${match.path}`} exact component={SupplyBotList} /> : null}
            {canViewBotsMenu ? <Route path={`${match.path}/create`} exact component={SupplyBotCreate} /> : null}
            {canViewBotsMenu ? <Route path={`${match.path}/:id`} exact component={SupplyBotDetail} /> : null}
            {canViewBotsMenu ? <Route path={`${match.path}/:id/history`} exact component={SupplyBotHistory} /> : null}
        </Switch>
    );
}

export default withRouter(SupplyBotsIndex);