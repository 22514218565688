import React, { FC } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  List,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import {
  ICreateRepositoryDefinitionCriteria,
  ICreateRepositoryDefinition,
} from "../../gateways/repository-definition.interfaces";
import SelectorField from "../../components/selector-field";
import CodeMirror from "react-codemirror";
import "codemirror/lib/codemirror.css";

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
    },
    button: {
      margin: theme.spacing(0.5),
    },
    textField: {
      margin: theme.spacing(0.5),
    },
    root: {
      display: "flex",
      overflowY: "hidden",
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: theme.zIndex.drawer + 1,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    formControl: {
      marginBottom: "1rem",
      "& :last-child": {
        marginBottom: 0,
      },
    },
    progress: {
      margin: theme.spacing(2),
    },

    gridItem: {
      borderSpacing: 2,
      padding: theme.spacing(1),
    },
  })
);

interface IRepositoryDefinitionCriteriaProps {
  item: ICreateRepositoryDefinition;
  handleChange: (name: string, value: any) => void;
  handleChangeCriteriaQueryBase: (name: string, value: any) => void;
  handleChangeCriteriaPropertyEntityId: (name: string, value: any) => void;
  validateRequired: (value: any) => boolean;
  handleSortAscDefaultChange: () => void;
  handleCodeChange: (code: string) => void;
}

const RepositoryDefinitionCriteria: FC<IRepositoryDefinitionCriteriaProps> = (
  props
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  var React = require("react");
  var CodeMirror = require("react-codemirror");
  require("codemirror/mode/sql/sql");
  let options = {
    lineNumbers: true,
    mode: "sql",
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container justify="flex-start" alignItems="flex-start">
          <Grid item xs={6} className={classes.gridItem}>
            <FormControlLabel
              control={
                <Checkbox
                  color={"primary"}
                  checked={props.item.criteria.sortAscDefault}
                  onChange={props.handleSortAscDefaultChange}
                />
              }
              label={t("common.defaultAscOrder")}
            />
          </Grid>
          <Grid item xs={6} className={classes.gridItem}>
            <SelectorField
              name="repositoryDefinitionData.criteria.repositoryDefinitionPropertyEntityId"
              emptyValue={false}
              fullWidth
              inputLabel={t("common.propertyDefaultOrder")}
              helperText={t("messages.requiredField") as string}
              required
              onChange={props.handleChangeCriteriaPropertyEntityId}
              value={props.item.criteria.repositoryDefinitionPropertyEntityId}
              validator={props.validateRequired}
              values={props.item.properties.map((value) => {
                return {
                  id: props.item.properties.findIndex(
                    (object) =>
                      object.name === value.name &&
                      object.valueTypeId === value.valueTypeId
                  ),
                  value: value.name,
                };
              })}
            ></SelectorField>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <div>
              <CodeMirror
                value={props.item.criteria.queryBase}
                onChange={props.handleCodeChange}
                options={options}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default RepositoryDefinitionCriteria;
