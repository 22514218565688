import React, { FC, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  IconButton,
  Grid
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

type TOptionValue = { id: number | string; value: string; category?: string };
type TOptionValueGroup = { name: string; values: TOptionValue[] };

export interface ISelectorFieldEstProps {
  emptyValue: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  inputLabel: string;
  name: string;
  onChange: (name: string, value: any) => void;
  required: boolean;
  validator?: (value: any) => boolean;
  values: TOptionValue[];
  value: number | string | null;
  className?: string;
  handleClickAddButon?: () => void;
  disableError?: boolean;
  disabled?: boolean;
}

const SelectorFieldEst: FC<ISelectorFieldEstProps> = (props) => {
  const [error, setError] = useState(false);
  const [optionsGrouped, setOptionsGrouped] = useState<TOptionValueGroup[]>([]);

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const value = event.target.value;
    const name = event.target.name!;
    const valueValidated =
      props.validator === null ||
      props.validator === undefined ||
      props.validator(value);

    if (!props.disableError) {
      setError(!valueValidated);
    }
    props.onChange(name, value);
  };

  useEffect(() => {
    const newOptionsGrouped: TOptionValueGroup[] = [];

    for (const value of props.values) {
      const categoryToSearch = value.category ?? "Sin Complejos";
      const optionGroup = newOptionsGrouped.find(
        (x) => x.name === categoryToSearch
      );
      if (!optionGroup) {
        newOptionsGrouped.push({
          name: categoryToSearch,
          values: [value],
        });
      } else {
        optionGroup.values.push(value);
      }
    }

    setOptionsGrouped(newOptionsGrouped);
  }, [props.values]);

  return (
    <Grid container>
      <Grid item style={{ flex: 1 }}>
        <FormControl fullWidth={props.fullWidth} className={props.className}>
          <InputLabel htmlFor="helper">{props.inputLabel}</InputLabel>
          <Select
            native
            value={props.value ?? 0}
            onChange={handleChange}
            disabled={props.disabled}
            inputProps={{
              name: props.name,
              id: "helper",
            }}
            required={props.required}
            error={props.error}
            fullWidth={true}
            displayEmpty={false}
          >
            {props.emptyValue && (
              <option key={0} value="">
                {""}
              </option>
            )}

            {optionsGrouped.map((x) => (
              <optgroup key={x.name} label={x.name}>
                {x.values.map((v) => (
                  <option key={v.id} value={v.id}>
                    {v.value}
                  </option>
                ))}
              </optgroup>
            ))}
          </Select>
          {error && props.helperText ? (
            <FormHelperText>{props.helperText}</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      {props.handleClickAddButon && (
        <Grid item style={{ alignSelf: "flex-end" }}>
          <IconButton
            color="primary"
            aria-label="add"
            onClick={props.handleClickAddButon}
            style={{ paddingBottom: 0 }}
            disabled={props.disabled}
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default SelectorFieldEst;
