import React, { useState, useMemo, useEffect } from 'react';
import CenteredCircularProgress from '../../components/centered-circular-progress';
import AlertBox from '../../components/alert-box';
import { List, Divider, Box, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import useIoC from '../../contexts/ioc.context';
import { useParams, useHistory, useRouteMatch } from "react-router";
import { IElectricContract, IDisplayElectricContractList } from '../../gateways/electric-contract.interfaces';
import { ElectricContractGateway } from '../../gateways/electric-contract.gateway';
import ElectricContractItem from './electric-contract-item';
import ElectricContractGoDetailDialog from './electric-contract-go-detail-dialog';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ElectricContractUpsertDialog from './electric-contract-upsert-dialog';
import { ProvidersGateway } from '../../gateways/providers.gateway';
import { ElectricSupplyRatesGateway } from '../../gateways/electric-supply-rates.gateway';
import ElectricSupplyDrawer from "../../components/electric-supply-drawer";
import ScreenGlobalStructure from '../../components/screen-global-structure';
import FlashOnIcon from '@material-ui/icons/FlashOn';

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    itemAvatarIcon: {
      backgroundColor: "#e0e0e0",
      borderRadius: "50%",
      padding: theme.spacing(1)
    },
}));

interface IElectricContractRouteParams {
    [x: string]: string | undefined;
    electricSupplyId: string;
    complexId: string,
    establishmentId: string;
    clientId: string;
}

const ElectricContractList = () => {
    const { electricSupplyId } = useParams<IElectricContractRouteParams>();
    const { complexId } = useParams<IElectricContractRouteParams>();
    const { establishmentId } = useParams<IElectricContractRouteParams>();
    const { clientId } = useParams<IElectricContractRouteParams>();

    const history = useHistory();
    const { url } = useRouteMatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState<IElectricContract[]>([]);
    const [displayData, setDisplayData] = useState<IDisplayElectricContractList[]>([]);
    const [openUpsertDialog, setOpenUpsertDialog] = useState(false);
    const [openGoDetailDialog, setGoDetailDialog] = useState(false);
    const [item, setItem] = useState<IElectricContract>();
    const [itemToNavigate, setItemToNavigate] = useState<IElectricContract>();
    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);

    const electricContractGateway = useIoC(ElectricContractGateway);
    const providersGateway = useIoC(ProvidersGateway);
    const rateGateway = useIoC(ElectricSupplyRatesGateway);


    const handleClickFabButton = () => {

        setItem(undefined);
        setOpenUpsertDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenUpsertDialog(false);
    };

    const closeGoToDetail = () => {
        setGoDetailDialog(false);
    }
    const settingDisplayData = async (data: IElectricContract[]) => {
        var dispDatas: IDisplayElectricContractList[] = [];
        var dispData: IDisplayElectricContractList;
        for (const electricContract of data) {
            let provider: any = null;
            if(electricContract.providerId) {
                provider = await providersGateway.findById(electricContract.providerId);
            }

            let rateEntity: any = null;
            if(electricContract.electricSupplyRateId) {
                rateEntity = await rateGateway.findById(electricContract.electricSupplyRateId);
            }
            dispData = {
                id: electricContract.id,
                reference: electricContract.reference,
                cancelPriorNotice: electricContract.cancelPriorNotice,
                duration: electricContract.duration,
                electricSupplyRateId: electricContract.electricSupplyRateId,
                endSupplyDate: electricContract.endSupplyDate,
                initSupplyDate: electricContract.initSupplyDate,
                modality: electricContract.modality,
                providerId: electricContract.providerId,
                providerName: !!!provider ? null : provider.name,
                rate: !!!rateEntity ? null : rateEntity.name,
                signContractDate: electricContract.signContractDate,
                term: electricContract.term,
                supplyConnectionBot: electricContract.supplyConnectionBot
            };

            dispDatas.push(dispData);
        }
        setDisplayData(dispDatas);

    }

    const handleItemUpsert = (item: IElectricContract, isNew: boolean) => {
        let copy = data.slice();
        if (isNew) {
            copy = [item, ...data];
        } else {
            const index = data.findIndex(value => value.id === item.id);
            copy[index] = item;
        }
        settingDisplayData(copy);
        setData(copy);
        setItemToNavigate(item);
        setGoDetailDialog(true);
    };
    const handleClickItem = (item: IDisplayElectricContractList) => {

        history.push(`${url}/${item.id}`);

    }

    const navigateToNextWindow = (item: IElectricContract) => {
        history.push(`${url}/${item.id}`);
    }

    useEffect(() => {
        (async () => {
            (async () => {
                setIsDataLoading(true);
                const data = await electricContractGateway.findAll({ electricSupplyId });
                setData(data);
                settingDisplayData(data);
                setIsDataLoading(false);
            })();
        })();
    }, [electricContractGateway]);


    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />;
        }

        if (isDataEmpty) {
            return <AlertBox variant='info'>{t('common.noItems')}</AlertBox>;
        }

        return (
            <List>
                {displayData.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <ElectricContractItem item={item} onClickItem={handleClickItem} />
                            {index < data.length - 1 ? <Divider variant='inset' component='li' /> : null}
                        </div>
                    );
                })}
            </List>
        );
    };
    return (
        <Box>
            <ScreenGlobalStructure
                buttonIcon={<AddIcon />}
                headerIcon={InsertDriveFileIcon}
                matchUrl={url}
                onButtonClick={handleClickFabButton}
                title={t('electricContractList.title')}
                clientId={clientId !== undefined ? clientId : ''}
                loading={isDataLoading}
                drawer={
                    <ElectricSupplyDrawer
                        matchUrl={url}
                        lateralMenu={1}
                        icon={<FlashOnIcon fontSize='large' className={classes.itemAvatarIcon} />}
                        goBack={true}
                    />
                }
            >
                <Content />
            </ScreenGlobalStructure>
            
            {electricSupplyId && 
            <ElectricContractUpsertDialog
            complexId={complexId}
            establishmentId={establishmentId}
            open={openUpsertDialog}
            item={item}
            electricSupplyId={electricSupplyId}
            onItemUpsert={handleItemUpsert}
            onClose={handleCloseDialog} />
            }
            
            <ElectricContractGoDetailDialog
                open={openGoDetailDialog}
                item={itemToNavigate}
                navigateToNextWindow={navigateToNextWindow}
                handleClose={closeGoToDetail} />
        </Box>
    );
};

export default ElectricContractList;