import React, { FC } from "react";
import { ListItem, ListItemAvatar, ListItemText, makeStyles, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import ContactTypeIcon from '@material-ui/icons/InsertDriveFile';
import IContactType from "../../gateways/contact-types.interfaces";
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

export interface IContactTypeProps {
    contactType: IContactType,
    onClick: (item: IContactType) => void,
    handleClickDeleteItem: (item: IContactType) => void
}

const ContactTypeItem: FC<IContactTypeProps> = (props) => {
    const classes = useStyles();
    const handleClickItem = () => {
        props.onClick(props.contactType);
    }

    return (
        <>
            <ListItem alignItems="flex-start" className={classes.listItem} key={props.contactType.id} onClick={handleClickItem}>
                <ListItemAvatar >
                    <ContactTypeIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
                </ListItemAvatar>
                <ListItemText primary={props.contactType.name} />
                <ListItemSecondaryAction>
                    <IconButton onClick={() => { props.handleClickDeleteItem(props.contactType) }} aria-label="delete"><DeleteIcon /></IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </>
    );
};

export default ContactTypeItem;