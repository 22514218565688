import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IFuelSupply, IUpdateFuelSupply, ICreateFuelSupply, IFuelSupplyFindById } from "./fuel-supply.interface";
import querystring from 'querystring';
import { IRegisterFuelBill } from "./register-fuel-bill.interface";
import { IFuelContract } from "./fuel-contract.interfaces";

type FindFilterFuelSupply = {
    establishmentId?: string;
    complexId?: string;
}
class FindFilterFuelSupplySecondGeneration {
    companyId?: string;
    clientId?: string;
}
const fuelSupplyResource = 'fuel-supply';
@inject('axios')
export class FuelSupplyGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(filter?: FindFilterFuelSupply): Promise<IFuelSupply[]> {
        let url: string = fuelSupplyResource;
        if (filter) {
            const query = querystring.stringify(filter);
            url += `?${query}`;
        }
        const response = await this.axios.get(url);
        return response.data;
    }

    async findAllFilterByCompanyClient(filter?: FindFilterFuelSupplySecondGeneration) {
        if (filter) {
            return await this.axios.post(fuelSupplyResource + '/company-client', filter);
        } else {
            return await this.axios.post(fuelSupplyResource + '/company-client', {});
        }
    }

    async findAllFiltered(input: string): Promise<string[]> {
        const response = await this.axios.get(`${fuelSupplyResource}/filter/${input}`);
        return response.data;
    }

    async findById(id: number): Promise<IFuelSupplyFindById> {
        const url = `${fuelSupplyResource}/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    public async findOneById(id: number): Promise<IFuelSupply[]> {
        const response = await this.axios.get(`fuel-supply/findOneById/${id}`)
        return response.data;
    }

    public async getUrl(cups: string): Promise<string> {
        const response = await this.axios.get(`fuel-supply/getUrl/${cups}`);
        return response.data;
    }

    async create(fuelSupply: ICreateFuelSupply): Promise<IFuelSupply> {
        const response = await this.axios.post(fuelSupplyResource, fuelSupply);
        return response.data;
    }

    async update(id: number, fuelSupply: IUpdateFuelSupply): Promise<IFuelSupply> {
        const url = `${fuelSupplyResource}/${id}`;
        const response = await this.axios.put(url, fuelSupply);
        return response.data;
    }

    async delete(id: number): Promise<void> {
        const url = `${fuelSupplyResource}/${id}`;
        const response = await this.axios.delete(url);
        return response.data;
    }

    async findByIdRegisterFuelBill(id: number): Promise<IRegisterFuelBill[]> {
        const url = `${fuelSupplyResource}/registerFuelBills/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    public async findByIdFuelContract(id: number): Promise<IFuelContract[]> {
        const url = `fuel-supply/fuelContract/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    public async getFuelComplexSupplies(id: number) {
        const response = await this.axios.get(`fuel-supply/allComplexFuelSupplies/${id}`);
        return response.data;
    }
}