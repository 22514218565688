import React, { FC, useState, useEffect, useRef, createRef } from "react";
import { Dialog, DialogTitle, DialogContent, Typography,
         Button, DialogActions, DialogContentText, Grid,
         TextField, Select, MenuItem, FormControl, 
         OutlinedInput, makeStyles, ListItem, Divider,
         ListItemText, ListItemAvatar,
} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import { ClientsGateway } from "../../gateways/clients.gateway";
import useIoC from "../../contexts/ioc.context";
import UserIcon from "@material-ui/icons/Person";
import { CompanyGateway } from "../../gateways/company.gateway";
import CompanyIcon from '@material-ui/icons/BusinessCenter';
import { ComplexGateway } from "../../gateways/complex.gateway";
import { EstablishmentGateway } from "../../gateways/establishment.gateway";
import ComplexIcon from '@material-ui/icons/Dashboard';
import EstablishmentIcon from '@material-ui/icons/Store';
import { ElectricSupplyGateway } from "../../gateways/electric-supply.gateway";
import { WaterSupplyGateway } from "../../gateways/water-supply.gateway";
import { FuelSupplyGateway } from "../../gateways/fuel-supply.gateway";
import FlashOnIcon from '@material-ui/icons/FlashOn';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import OpacityIcon from "@material-ui/icons/Opacity";

interface IClientSearcher {
    open: boolean;
    onClose: () => void; 
}

const useStyles = makeStyles((theme) => ({
    success: {
        fontStyle: "italic",
        fontSize: "1em",
        paddingTop: "0.8em"
    },
    listItem: {
        cursor: "pointer",
    },
    itemAvatarIcon: {
        backgroundColor: "#e0e0e0",
        borderRadius: "50%",
        padding: theme.spacing(1),
    },
}));

const ClientSearcherDialog: FC<IClientSearcher> = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const busquedaRef = useRef();

    const [searchOption, setSearchOption] = useState<string>("client");
    const [ loading, setLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [searchFilter, setSearchFilter] = useState<string>("");

    const clientsGateway = useIoC(ClientsGateway);
    const companiesGateway = useIoC(CompanyGateway);
    const complexesGateway = useIoC(ComplexGateway);
    const establishmentsGateway = useIoC(EstablishmentGateway);
    const electricSupplyGateway = useIoC(ElectricSupplyGateway);
    const waterSupplyGateway = useIoC(WaterSupplyGateway);
    const fuelSupplyGateway = useIoC(FuelSupplyGateway);

    const optionsVariableNames = {
        client: {
            primary: 'item.fullname',
            secondary: 'item.address',
        },
        company: {
            primary: 'item.name',
            secondary: '',
        },
        complex: {
            primary: 'item.name',
            secondary: 'item.direction',
        },
        establishment: {
            primary: 'item.name',
            secondary: 'item.address',
        },
        //Electric and water case - fuel goes apart (reference)
        supply: {
            primary: 'item.referencia',
            secondary: '',
        },
    }

    const handleChange = async (event: { target: { value: any; }; }) => {
        setSearchOption(event.target.value);
    }

    const limpiarBusqueda = () => {
        const obj = busquedaRef as any;
        if (obj !== null)
            obj.current.value = "";
      };

    useEffect(() => {
        (async () => {
            setLoading(true);

            if (searchFilter.length > 0)
            {
                // setSearchFilter("");
                limpiarBusqueda();
            }

            let items: any[] = [];
            switch(searchOption) {
                case "client":
                    items = await clientsGateway.findAll({});
                    break;
                case "company":
                    items = await companiesGateway.findAll();
                    break;
                case "complex":
                    items = await complexesGateway.findAll();
                    break;
                case "establishment":
                    items = await establishmentsGateway.findAll();
                    break;
                case "supply":
                    const [electric, water, fuel] = await Promise.all([electricSupplyGateway.findAll(), await waterSupplyGateway.findAll(), fuelSupplyGateway.findAll()]);
                    // const electric = await electricSupplyGateway.findAll();
                    // const water = await waterSupplyGateway.findAll();
                    // const fuel = await fuelSupplyGateway.findAll();

                    items = items.concat(water, fuel, electric);
                    break;
            }
            setData(items);
            setFilteredData(items);
            setLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchOption]);

    useEffect(() => {
        if(searchFilter !== ""){
            let filteredData: any = [];
            filteredData = data.filter((item: any) => {
              const valToEval = item.reference !== undefined ? 'item.reference' : 
                                optionsVariableNames[searchOption as keyof typeof optionsVariableNames].primary;
              if(eval(valToEval).toLowerCase().includes(searchFilter.toLowerCase())) return item;
            })
            setFilteredData(filteredData);
        } else setFilteredData(data);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFilter]);

    const listType = (item: any) => {
        switch(searchOption){
            case "client":
                return(<UserIcon color="action" fontSize="large" className={classes.itemAvatarIcon}/>);
            case "company":
                return(<CompanyIcon color="action" fontSize="large" className={classes.itemAvatarIcon}/>);
            case "complex":
                return(<ComplexIcon color="action" fontSize="large" className={classes.itemAvatarIcon}/>);
            case "establishment":
                return(<EstablishmentIcon color="action" fontSize="large" className={classes.itemAvatarIcon}/>);
            case "supply":
                if(item.fuelTypeId !== undefined) {
                    return(<LocalGasStationIcon color="action" fontSize="large" className={classes.itemAvatarIcon}/>);
                } else if(/[a-zA-Z]/.test(item.referencia)) {
                    return(<FlashOnIcon color="action" fontSize="large" className={classes.itemAvatarIcon}/>);
                } else{
                    return(<OpacityIcon color="action" fontSize="large" className={classes.itemAvatarIcon}/>);
                }
        }
    }

    const fixReferenceEncoding = (ref: string) => {
        let result = ref;

        while (result.includes('/'))
            result = result.replace('/', '%2F');

        while (result.includes(`\\`))
            result = result.replace(`\\`, '%5C');

        while (result.includes(` `))
            result = result.replace(` `, '%20');

        return result;
    }

    const handleNavigateClient = async (item: any) => {
        let url = "";
        let clientId;
        const prefix = process.env.NODE_ENV === 'development'
                                    ? 'http://localhost:3000/clients/'
                                    : 'https://ebm.enovam.com/clients/';
        switch(searchOption){
            case "client":
                url = `${prefix}${item.id}`;
                break;
            case "company":
                url = `${prefix}${item.clientId}/companies/${item.id}`;
                break;
            case "complex":
                clientId = await companiesGateway.findById(item.companyId);
                url = `${prefix}${clientId.clientId}/complex/${item.id}`;
                break;
            case "establishment":
                clientId = await companiesGateway.findById(item.companyId);
                url = `${prefix}${clientId.clientId}/establishments/${item.id}`;
                break;
            case "supply":
                if(searchOption === "supply") {
                    if(item.fuelTypeId !== undefined) {
                        url = await fuelSupplyGateway.getUrl(fixReferenceEncoding(item.reference));
                        break;
                    } else if(/[a-zA-Z]/.test(item.referencia)) {
                        url = await electricSupplyGateway.getUrl(fixReferenceEncoding(item.referencia));
                        break;
                    } else{
                        url = await waterSupplyGateway.getUrl(fixReferenceEncoding(item.referencia));
                        break;
                    }   
                }
        }
        window.location.replace(url);
    }

    const renderListItem = (item: any, index: number) => {

        const handleNavigateClientWraped = () => handleNavigateClient(item);

        const icon = listType(item);
        const primaryText = item.reference !== undefined ? eval('item.reference') : 
                            eval(optionsVariableNames[searchOption as keyof typeof optionsVariableNames].primary);
        const secondaryText = eval(optionsVariableNames[searchOption as keyof typeof optionsVariableNames].secondary);
        return(
            <>
                <ListItem
                    alignItems="flex-start"
                    onClick={handleNavigateClientWraped}
                    className={classes.listItem}
                >
                <ListItemAvatar>
                    {icon !== undefined ? icon : <></>}
                </ListItemAvatar>
                <ListItemText primary={primaryText} secondary={secondaryText}/>
                </ListItem>
                {index < data.length - 1 ? (
                <Divider variant="inset" />
                ) : null}
            </>
        );
    }

    return(
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle id="form-dialog-title">
                <Typography variant="h5" align="center">Buscador general</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container justify="flex-end" spacing={1}>
                    <Grid item xs={4}>
                        <FormControl size="small" fullWidth>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value={searchOption}
                                onChange={handleChange}
                                input={<OutlinedInput/>}
                            >
                                <MenuItem value={"client"}>Clientes</MenuItem>
                                <MenuItem value={"company"}>Empresas</MenuItem>
                                <MenuItem value={"establishment"}>Establecimientos</MenuItem>
                                <MenuItem value={"complex"}>Complejos</MenuItem>
                                <MenuItem value={"supply"}>Suministros</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField 
                            id="outlined-basic" 
                            label="Búsqueda" 
                            variant="outlined" 
                            size="small"
                            fullWidth
                            inputRef={busquedaRef}
                            onChange={ (event: any) => {
                                setSearchFilter(event.target.value);
                            }}
                            disabled={loading}
                        />
                    </Grid>
                </Grid>
                <DialogContentText className={classes.success}>
                    Cuando se encuentre el valor que se está buscando, se le redirigirá a su página.
                </DialogContentText>
                {filteredData.map(renderListItem)}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="default">{t("common.close")}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ClientSearcherDialog;