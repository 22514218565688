import React, { FC, useState, useEffect } from "react";
import { makeStyles, Grid, Chip, Button, Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Step from "../../components/step";
import useIoC from "../../contexts/ioc.context";
import { IRepositoryDefinitionProperty } from "../../gateways/repository-definition.interfaces";
import { RepositoryDefinitionPropertyGateway } from "../../gateways/repository-definition-property.gateway";
import { IValidationRule } from "../../gateways/validation-rules.interfaces";
import { IRule } from "../../gateways/rules.interfaces";
import RuleTable from "./rule-table";
import { useTranslation } from "react-i18next";
import { RulesGateway } from "../../gateways/rules.gateway";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5)
    }
}));

interface IRuleValidationRulesFormParams {
    validationContextId: string;
}

interface IRuleValidationRulesFormProps {
    item: IValidationRule;
    nextStep: () => void;
    prevStep: () => void;
    onChange: (items: IRule[]) => void;

}

const RuleValidationRulesForm: FC<IRuleValidationRulesFormProps> = ({ item, nextStep, prevStep, onChange }) => {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const { validationContextId } = useParams<IRuleValidationRulesFormParams>();

    const [repositoryDefinitionProperties, setRepositoryDefinitionProperties] = useState<IRepositoryDefinitionProperty[]>([]);
    const [rules, setRules] = useState<IRule[]>([]);


    const repositoryDefinitionPropertyGateway = useIoC(RepositoryDefinitionPropertyGateway);
    const rulesGateway = useIoC(RulesGateway);

    useEffect(() => {
        (async () => {
            const properties = await repositoryDefinitionPropertyGateway.findByValidationContextId(validationContextId);
            setRepositoryDefinitionProperties(properties);
        })();
        setRules(item.rules);
    }, []);

    const validateRules = () => {
        (async () => {

            const rulesValidated = await rulesGateway.validateRules(rules);
            if (rulesValidated.failedRules.length !== 0) {
                for (const failed of rulesValidated.failedRules) {
                    snackbar.enqueueSnackbar(t('ruleValidationRulesForm.ruleFailed',{name: failed.name}), { variant: "error" });

                }
            } else {
                snackbar.enqueueSnackbar(t('ruleValidationRulesForm.allRulesSucced'), { variant: "success" });
                nextStep();
            }
        })();

    }

    const handleNext = () => {
        onChange([...rules]);
        validateRules();

    }

    const handlePrev = () => {
        onChange([...rules]);
        prevStep();
    }

    const handleChange = (items: IRule[]) => {
        setRules([...items]);
    }

    return (
        <Step
            title={t('ruleValidationRulesForm.title')}
            prevStep={handlePrev}
            nextStep={handleNext}
        >

            <Grid container direction="column">
                <RuleTable
                    items={rules}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item>
                <Typography> {t('common.properties')} </Typography>

                {
                    repositoryDefinitionProperties.map((value, index) => {
                        return (
                            <Chip
                                key={index}
                                label={'$' + value.name}
                                className={classes.chip}
                            />
                        );
                    })
                }
            </Grid>

        </Step>

    );
};

export default RuleValidationRulesForm;