import React, { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { List, ListItem, makeStyles, Box } from "@material-ui/core";
import { FuelContractGateway } from "../../gateways/fuel-contract.gateway";
import { IFuelContract } from "../../gateways/fuel-contract.interfaces";
import { useHistory } from "react-router-dom";
import { TreeItem } from "@material-ui/lab";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    item: {
        padding: 0,
        margin: 0,
        fontWeight: 'normal'
    }
}));

interface IClientPeekFuelContractFormProps {
    fuelSupplyId: string;
    currentUrl: string;
}

const ClientPeekFuelContractForm: FC<IClientPeekFuelContractFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [data, setData] = useState<IFuelContract[]>([]);
    const fuelContractGateway = useIoC(FuelContractGateway);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const fuelSupplyId = props.fuelSupplyId;
            const data = await fuelContractGateway.findAll({ fuelSupplyId });
            setData(data);
        })();
    }, [fuelContractGateway]);

    const handleStopPropagation = useCallback( (event: React.MouseEvent) => {
        event.stopPropagation();               
    }, [])

    return (
        <Box>
            {
                data.length > 0 &&
                <TreeItem nodeId={props.currentUrl + 'fuelContract'} label={t('rightNavigation.fuelContract')}>
                    {
                        data.map((item) => {
                            const initDate = item.initSupplyDate ? new Date(item.initSupplyDate.toString()) : undefined;
                            const endDate = item.endSupplyDate ? new Date(item.endSupplyDate.toString()) : undefined;
                            return (
                                <TreeItem nodeId={`${props.currentUrl}/fuelContract/${item.id}`}
                                    label={<a onClick={handleStopPropagation} href={`${props.currentUrl}/fuelContract/${item.id}`} target="_blank">{
                                        (initDate ? `${moment(initDate).format('DD/MM/YYYY')}` : t('common.undefinedDate'))
                                        +
                                        ' - '
                                        +
                                        (endDate ? `${moment(endDate).format('DD/MM/YYYY')}` : t('common.undefinedDate'))}</a>} />
                            )
                        })
                    }
                </TreeItem>
            }
        </Box>
    )
};

export default ClientPeekFuelContractForm;