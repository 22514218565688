import React, { useState, useMemo, useEffect } from 'react';
import CenteredCircularProgress from '../../components/centered-circular-progress';
import AlertBox from '../../components/alert-box';
import { Box, Fab, makeStyles, List, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import useIoC from '../../contexts/ioc.context';
import { useNavigator } from '../../contexts/navigator.context';
import { useParams, useHistory, useRouteMatch } from "react-router";
import DescriptionIcon from '@material-ui/icons/Description';
import { IRegisterFuelBill, IRegisterFuelBillCreate } from '../../gateways/register-fuel-bill.interface';
import { RegisterFuelBillGateway } from '../../gateways/register-fuel-bill.gateway';
import { FuelSupplyGateway } from '../../gateways/fuel-supply.gateway';
import RegisterFuelBillItem from './register-fuel-bill-item'
import RegisterFuelBillUpsertDialogNetworkGasoilDiesel from './register-fuel-bill-upsert-dialog-network-gasoil-diesel';
import RegisterFuelBillUpsertDialogNetworkGLFuelBiomass from './register-fuel-bill-upsert-dialog-network-GL-fuel-biomass';
import RegisterFuelBillUpsertDialogTankGasoilDiesel from './register-fuel-bill-upsert-dialog-tank-gasoil-diesel';
import RegisterFuelBillUpsertDialogTankGLFuelBiomass from './register-fuel-bill-upsert-dialog-tank-GL-fuel-biomass';
import RegisterFuelBillGoDetailDialog from './register-fuel-bill-go-detail-dialog'
import ScreenGlobalStructure from '../../components/screen-global-structure';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import FuelSupplyDrawer from '../../components/fuel-supply-drawer';

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

interface IFuelBillRouteParams {
    fuelSupplyId: string,
    complexId: string,
    establishmentId: string,
    clientId: string
}

const RegisterFuelBillList = () => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const { fuelSupplyId } = useParams<IFuelBillRouteParams>();
    const { complexId } = useParams<IFuelBillRouteParams>();
    const { establishmentId } = useParams<IFuelBillRouteParams>();
    const { clientId } = useParams<IFuelBillRouteParams>();
    const { t } = useTranslation();
    const classes = useStyles();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState<IRegisterFuelBill[]>([]);
    const [openUpsertDialog, setOpenUpsertDialog] = useState(false);
    const [item, setItem] = useState<IRegisterFuelBillCreate>();
    const [accessType, setAccessType] = useState<number>(0);
    const [fuelType, setFuelType] = useState<number>(0);
    const [biomass, setBiomass] = useState<boolean>(false);
    const [itemToNavigate, setItemToNavigate] = useState<IRegisterFuelBill>();
    const [openUpsertDialogNetworkGasoilDiesel, setOpenUpsertDialogNetworkGasoilDiesel] = useState(false);
    const [openUpsertDialogNetworkGLFuelBiomass, setOpenUpsertDialogNetworkGLFuelBiomass] = useState(false);
    const [openUpsertDialogTankGasoilDiesel, setOpenUpsertDialogTankGasoilDiesel] = useState(false);
    const [openUpsertDialogTankGLFuelBiomass, setOpenUpsertDialogTankGLFuelBiomass] = useState(false);
    const [openGoDetailDialog, setOpenGoDetailDialog] = useState(false);
    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);

    const registerFuelBillGateway = useIoC(RegisterFuelBillGateway);
    const fuelSupplyGateway = useIoC(FuelSupplyGateway);

    const handleClickFabButton = () => {

        setItem(undefined);
        if (accessType === 1) {
            if (fuelType === 3 || fuelType === 4) {
                setOpenUpsertDialogNetworkGasoilDiesel(true);
            } else {
                setOpenUpsertDialogNetworkGLFuelBiomass(true);
            }
        } else if (fuelType === 3 || fuelType === 4) {
            setOpenUpsertDialogTankGasoilDiesel(true);
        } else {
            setOpenUpsertDialogTankGLFuelBiomass(true);
        }
    };

    const handleItemUpsert = (item: IRegisterFuelBill, isNew: boolean) => {
        let copy = data.slice();
        if (isNew) {
            copy = [item, ...data];
        } else {
            const index = data.findIndex(value => value.id === item.id);
            copy[index] = item;
        }
        setData(copy);
        setItemToNavigate(item);
        setOpenGoDetailDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenUpsertDialogNetworkGasoilDiesel(false);
        setOpenUpsertDialogNetworkGLFuelBiomass(false);
        setOpenUpsertDialogTankGasoilDiesel(false);
        setOpenUpsertDialogTankGLFuelBiomass(false);
    };

    const handleClickItem = (item: IRegisterFuelBill) => {

        history.push(`${url}/${item.id}`);

    };

    const navigateToNextWindow = (item: IRegisterFuelBill) => {
        history.push(`${url}/${item.id}`);
    };

    const handleCloseGoToDetail = () => {
        setOpenGoDetailDialog(false);
    };

    useEffect(() => {
        (async () => {
            (async () => {
                setIsDataLoading(true);
                const data = await fuelSupplyGateway.findByIdRegisterFuelBill(parseInt(fuelSupplyId));
                setData(data);
                const supplyInfo = await fuelSupplyGateway.findById(parseInt(fuelSupplyId));
                setBiomass(supplyInfo.fuelType.id === 6);
                setAccessType(supplyInfo.accessType.id);
                setFuelType(supplyInfo.fuelType.id);
                setIsDataLoading(false);
            })();
        })();
    }, [registerFuelBillGateway]);


    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />;
        }

        if (isDataEmpty) {
            return <AlertBox variant='info'>{t('common.noItems')}</AlertBox>;
        }

        return (
            <List>
                {data.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <RegisterFuelBillItem item={item} onClickItem={handleClickItem} />
                            {index < data.length - 1 ? <Divider variant='inset' component='li' /> : null}
                        </div>
                    );
                })}
            </List>
        );
    };
    return (
        <Box>
            <ScreenGlobalStructure
                buttonIcon={<AddIcon />}
                headerIcon={LocalGasStationIcon}
                matchUrl={url}
                onButtonClick={handleClickFabButton}
                title={t('electricSupplyDetail.title')}
                clientId={clientId ? clientId : ''}
                loading={isDataLoading}
                drawer={<FuelSupplyDrawer
                    matchUrl={url}
                    lateralMenu={2}
                    icon={<LocalGasStationIcon fontSize='large' className={classes.itemAvatarIcon}/>}
                    goBack={true}
                />}
            >
                <Content />
            </ScreenGlobalStructure>
            <RegisterFuelBillGoDetailDialog
                open={openGoDetailDialog}
                item={itemToNavigate}
                navigateToNextWindow={navigateToNextWindow}
                handleClose={handleCloseGoToDetail} />

            <RegisterFuelBillUpsertDialogNetworkGasoilDiesel
                open={openUpsertDialogNetworkGasoilDiesel}
                item={item}
                complexId={complexId}
                establishmentId={establishmentId}
                fuelSupplyId={fuelSupplyId}
                onItemUpsert={handleItemUpsert}
                onClose={handleCloseDialog} />

            <RegisterFuelBillUpsertDialogNetworkGLFuelBiomass
                open={openUpsertDialogNetworkGLFuelBiomass}
                item={item}
                complexId={complexId}
                establishmentId={establishmentId}
                fuelSupplyId={fuelSupplyId}
                onItemUpsert={handleItemUpsert}
                onClose={handleCloseDialog}
                biomass={biomass} />

            <RegisterFuelBillUpsertDialogTankGasoilDiesel
                open={openUpsertDialogTankGasoilDiesel}
                item={item}
                complexId={complexId}
                establishmentId={establishmentId}
                fuelSupplyId={fuelSupplyId}
                onItemUpsert={handleItemUpsert}
                onClose={handleCloseDialog}
            />

            <RegisterFuelBillUpsertDialogTankGLFuelBiomass
                open={openUpsertDialogTankGLFuelBiomass}
                item={item}
                complexId={complexId}
                establishmentId={establishmentId}
                fuelSupplyId={fuelSupplyId}
                onItemUpsert={handleItemUpsert}
                onClose={handleCloseDialog}
                biomass={biomass} />

        </Box>
    );
};

export default RegisterFuelBillList;