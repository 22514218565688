import { AxiosInstance } from "axios";
import { inject } from "aurelia-dependency-injection";
import ICountry from "./country.interfaces";
import IRegion from "./region.interface";

@inject('axios')
export default class CountryGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<ICountry[]> {
        const response = await this.axios.get('countries');
        return response.data;
    }

    async findAllFiltered(input: string): Promise<string[]> {
        const response = await this.axios.get(`countries/filter/${input}`);
        return response.data;
    }

    async getCountrieCurrencyComplex(id: number): Promise<number> {
        const response = await this.axios.get(`countries/complex/${id}`);
        return response.data;
    }

    async getCountrieCurrencyEstablishment(id: number): Promise<number> {
        const response = await this.axios.get(`countries/establishment/${id}`);
        return response.data;
    }

    async getCountrieVolumeUnitComplex(id: number): Promise<number> {
        const response = await this.axios.get(`countries/complexVolumeUnit/${id}`);
        return response.data;
    }

    async getCountrieVolumeUnitEstablishment(id: number): Promise<number> {
        const response = await this.axios.get(`countries/establishmentVolumeUnit/${id}`);
        return response.data;
    }

    async create(partialCurrency: Partial<ICountry>): Promise<ICountry> {
        const response = await this.axios.post('countries', partialCurrency);
        return response.data;
    }

    async update(id: number, partialCurrency: Partial<ICountry>): Promise<ICountry> {
        const response = await this.axios.put(`countries/${id}`, partialCurrency);
        return response.data;
    }

    async delete(id: number): Promise<ICountry> {
        const response = await this.axios.delete(`countries/${id}`);
        return response.data;
    }

    async findAllRegions(id: number): Promise<IRegion[]> {
        const response = await this.axios.get(`countries/${id}/regions`);
        return response.data;
    }

    async createRegions(id: number, regions: IRegion[]): Promise<IRegion[]> {
        const response = await this.axios.post(`countries/${id}/regions`, regions);
        return response.data;
    }

    async updateRegions(id: number, regions: IRegion[]): Promise<IRegion[]> {
        const response = await this.axios.put(`countries/${id}/regions`, regions);
        return response.data;
    }
}
