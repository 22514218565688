import React, { useEffect } from "react";
import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles } from "@material-ui/core";
import { IValidationGroup, initialValidationGroup } from "../../gateways/validation-group.interfaces";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ValidationGroupGateway } from "../../gateways/validation-group.gateway";
import { IValidationContext } from "../../gateways/validation-context.interfaces";
import ValidationCriteriaForm from "./validation-group-form";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

export interface IValdiationCriteriaAddDialogProps {
    open: boolean;
    validationContext: IValidationContext;
    onCreate: (item: IValidationGroup) => void;
    onClose: () => void;
}

const ValidationGroupAddDialog: FC<IValdiationCriteriaAddDialogProps> = ({ open, validationContext, onClose, onCreate }) => {
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const [item, setItem] = useState<IValidationGroup>(initialValidationGroup);
    const validationGroupGateway = useIoC(ValidationGroupGateway);

    useEffect(() => {
        setItem({
            id: 0,
            name: '',
            validationCriterias: []
        }
        );

    }, [open]);

    const handleChange = (item: IValidationGroup) => {
        setItem(item);
    }

    const handleClose = () => {
        onClose();
    };

    const handleCreate = () => {
        (async () => {

            try {
                const validationGroup = await validationGroupGateway.create({
                    name: item.name,
                    validationCriterias: item.validationCriterias,
                    validationContext: validationContext

                });
                onCreate(validationGroup);
                snackbar.enqueueSnackbar(t('validationGroupAddDialog.createSucceded', { name: item.name }), { variant: "success" });
            } catch (e) {
                const er = e as any;
                if (er.response.data.key === 'creatingEntityError') {
                    const key = 'sectorErrorHandler.' + er.response.data.key;
                    const message = "validation" in er ? er.validation : t(key);
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                } else {
                    const message = "validation" in er ? er.validation : t('messages.defaultError');
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                }
            } finally {
                onClose();
            }

        })();
    }


    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
            <DialogTitle id="form-dialog-title">{t('validationGroupAddDialog.title')}</DialogTitle>
            <DialogContent>
                <ValidationCriteriaForm
                    item={item}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">
                    {t('common.cancel')}
                </Button>
                <Button onClick={handleCreate} color="primary">
                    {t('common.create')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ValidationGroupAddDialog;