import React, { FC } from 'react';
import { RouteComponentProps, Switch, Route, withRouter } from 'react-router-dom';
import ClientList from './client.list';
import ClientDetail from './client-detail';
import AgendaCalendar from './agenda-calendar';
import CampaignList from './campaign.list';
import CampaignDetail from './campaign.detail';
import AdminCampaignList from './admin-campaign.list';
import AdminCampaignDetail from './admin-campaign.detail';
import DashboardMarketing from './dashboard';
import esmGoogleQuery from './esm-google-query';
import { useUser } from '../../contexts/user.context';


const MarketingIndex: FC<RouteComponentProps> = (props) => {
    const [{ canViewESM_Menu }] = useUser();
    return (
        <Switch>
            {canViewESM_Menu ? <Route path={`${props.match.path}/dashboard`} exact component={DashboardMarketing} /> : null}
            {canViewESM_Menu ? <Route path={`${props.match.path}/agenda`} exact component={AgendaCalendar} /> : null}
            {canViewESM_Menu ? <Route path={`${props.match.path}/campaigns-admin`} exact component={AdminCampaignList} /> : null}
            {canViewESM_Menu ? <Route path={`${props.match.path}/campaigns-admin/:campaignId`} exact component={AdminCampaignDetail} /> : null}
            {canViewESM_Menu ? <Route path={`${props.match.path}/campaigns`} exact component={CampaignList} /> : null}
            {canViewESM_Menu ? <Route path={`${props.match.path}/campaigns/:campaignId`} component={CampaignDetail} /> : null}
            {canViewESM_Menu ? <Route path={`${props.match.path}/clients`} exact component={ClientList} /> : null}
            {canViewESM_Menu ? <Route path={`${props.match.path}/clients/:clientId`} exact component={ClientDetail} /> : null}
            {canViewESM_Menu ? <Route path={`${props.match.path}/esm-google-query`} exact component={esmGoogleQuery} /> : null}
        </Switch>
    );
}

export default withRouter(MarketingIndex);