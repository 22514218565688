import React from "react";
import WaterSupplyList from "./water-supply-list";
import { Switch, Route, useRouteMatch } from "react-router";
import WaterSupplyDetail from "./water-supply-detail";
import { WaterSupplyWrapper } from "./water-supply-wrapper";

const WaterSupplyRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <WaterSupplyList />
      </Route>
      <Route path={`${path}/:waterSupplyId`} component={WaterSupplyWrapper} />
    </Switch>
  );
};

export default WaterSupplyRouter;
