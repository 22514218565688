import React, { FC, useState } from "react";
import { TextField, InputAdornment, PropTypes, Box } from "@material-ui/core";

interface IFormTextField {
    value: string | number;
    type: string;
    validator?: (value: any) => boolean;
    errorText?: string;
    fullWidth?: boolean;
    required?: boolean;
    onChange: (name: string, value: any) => void;
    name: string;
    label: string;
    autoFocus?: boolean;
    adornmentLabel?: string;
    margin?: PropTypes.Margin;
    disabled?: boolean;
    className?: string;
    multiline?: boolean;
    error?:boolean;
    invoicesCounterFolder?:string;
    placeholder?:string;
    onBlur?: (event: any) => void;
    helperTextMessage?:string;
}

const FormTextField: FC<IFormTextField> = (props) => {
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        const { name, value } = event.target;
        const valueValidated = (props.validator !== null && props.validator !== undefined) ? props.validator(value) : true;
        setError(!valueValidated);
        if (!valueValidated) {
            setHelperText(props.errorText ? props.errorText : '');
        } else {
            setHelperText('');
        }
        props.onChange(name!, value);
    };

    return (
        <Box pb={1.25}>
            <TextField
                value={props.value}
                type={props.type}
                onChange={handleChange}
                error={props.error}
                helperText={props.helperTextMessage || (error && helperText)}
                fullWidth={props.fullWidth}
                required={props.required}
                name={props.name}
                label={props.label ?? "common.invoicesFolder"}
                autoFocus={props.autoFocus}
                margin={props.margin}
                InputProps={props.adornmentLabel ? { endAdornment: <InputAdornment position="end">{props.adornmentLabel}</InputAdornment> } : {}}
                disabled={props.disabled}
                className={props.className}
                multiline={props.multiline}
                placeholder={props.placeholder}
                onBlur={props.onBlur}
            />
        </Box>);
};

export default FormTextField;