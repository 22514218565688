import { IElectricContract, initialElectricContract } from "./electric-contract.interfaces";
import { IBotPreconfigRO, initialBotPreconfig } from "./bots.interfaces";
import { IFuelContract, initialIFuelContract } from "./fuel-contract.interfaces";

export interface ISupplyConnectionBot {

    id: number;

    startCaptureDate: Date;

    electricContract: IElectricContract;

    fuelContract: IFuelContract;

    botPreconfigId: number;

    botPreconfig: IBotPreconfigRO;

    enabled: boolean;
}
export interface ICreateSupplyConnectionBot {

    startCaptureDate: Date;

    electricContractId?: string;

    fuelContractId?: string;

    botPreconfigId: number;

    enabled: boolean;
}

export interface IUpdateSupplyConnectionBot {
    startCaptureDate: Date;

    electricContractId?: string;

    fuelContractId?: string;

    botPreconfigId: number;

    enabled: boolean;
}

export const initialSupplyConnectBot: ISupplyConnectionBot = {
    id: 0,
    botPreconfigId: 0,
    botPreconfig: initialBotPreconfig,
    electricContract: initialElectricContract,
    fuelContract: initialIFuelContract,
    startCaptureDate: new Date(),
    enabled: false
}