import React, { FC } from "react";
import { List, ListItem, ListItemText, ListItemIcon, Grid, SvgIconProps, makeStyles } from "@material-ui/core";
import { Link as ReactRouterLink } from "react-router-dom";
import BusinessIcon from '@material-ui/icons/BusinessCenter';
import ComplexIcon from '@material-ui/icons/Dashboard';
import EstablishmentIcon from '@material-ui/icons/Store';
import ContactsIcon from '@material-ui/icons/Contacts';
import { useTranslation } from "react-i18next";
import { useBreadCrumbName } from "../contexts/breadCrumbNames.context";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

interface IDrawerProps {
    matchUrl: string;
    lateralMenu?: number;
    icon: JSX.Element;
    goBack: boolean;
};

const ClientsDrawer: FC<IDrawerProps> = (props) => {
    const { names: BreadCrumbNames } = useBreadCrumbName();
    const { t } = useTranslation();
    var matchUrl: string =
        props.matchUrl.replace(/[^/]/g, "").length <= 2 ? props.matchUrl : props.matchUrl.slice(0, props.matchUrl.lastIndexOf('/'));
    return (
        <div>
            <List>
                <ListItem>
                    <Grid container direction="column" alignItems="center">
                        <Grid>
                            {props.icon}
                        </Grid>
                        <Grid>
                            <ListItemText primary={BreadCrumbNames['clientsDrawer']} />
                        </Grid>
                    </Grid>
                </ListItem>
                {
                    props.goBack &&
                    <ListItem button component={ReactRouterLink} to={`${matchUrl}`}>
                        <ListItemIcon><ArrowBackIosIcon /></ListItemIcon>
                        <ListItemText primary={t('rightNavigation.back')} />
                    </ListItem>
                }
                <ListItem button component={ReactRouterLink} to=
                    {`${matchUrl}/contacts`} selected={props.lateralMenu === 1}>
                    <ListItemIcon><ContactsIcon /></ListItemIcon>
                    <ListItemText primary={t('rightNavigation.contacts')} />
                </ListItem>
                <ListItem button component={ReactRouterLink} to=
                    {`${matchUrl}/companies`} selected={props.lateralMenu === 2}>
                    <ListItemIcon><BusinessIcon /></ListItemIcon>
                    <ListItemText primary={t('rightNavigation.companies')} />
                </ListItem>
                <ListItem button component={ReactRouterLink} to=
                    {`${matchUrl}/complex`} selected={props.lateralMenu === 3}>
                    <ListItemIcon><ComplexIcon /></ListItemIcon>
                    <ListItemText primary={t('rightNavigation.complexes')} />
                </ListItem>
                <ListItem button component={ReactRouterLink} to=
                    {`${matchUrl}/establishments`} selected={props.lateralMenu === 4}>
                    <ListItemIcon><EstablishmentIcon /></ListItemIcon>
                    <ListItemText primary={t('rightNavigation.establishments')} />
                </ListItem>
            </List>
        </div>
    );
};

export default ClientsDrawer;