import { SupplyTypes } from "../../../../../gateways/operate.interface";
import { ElectricityComponent } from "./electricity.supply-type";
import { FuelComponent } from "./fuel.supply-type";
import { IFormBaseComponent } from "./interfaces";
import { WaterComponent } from "./water.supply-type";

export default {
  electric: ElectricityComponent,
  fuel: FuelComponent,
  water: WaterComponent,
} as Record<SupplyTypes, IFormBaseComponent>;