import React, { FC, useState, useMemo, useEffect } from 'react';
import CenteredCircularProgress from '../../components/centered-circular-progress';
import AlertBox from '../../components/alert-box';
import { List, Divider, Box, Fab, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import useIoC from '../../contexts/ioc.context';
import { useNavigator } from '../../contexts/navigator.context';
import HiringDashboardItem from './hiring-dashboard-item';
import { HiringDashboardGateway } from '../../gateways/hiring-dashboard.gateway'
import { IAutoComplete, IHiringDashboard, initialIAutoComplete, ISocietyAutoComplete } from '../../gateways/hiring-dashboard.interfaces';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ScreenGlobalStructure from '../../components/screen-global-structure';
import { useRouteMatch } from 'react-router-dom';
import HiringDashboardUpsertDialog from './hiring-dashboard-upsert-dialog';
import IContact from '../../gateways/contact.interface';
import { ClientsGateway } from '../../gateways/clients.gateway';
import { FuelSupplyGateway } from '../../gateways/fuel-supply.gateway';
import { ElectricSupplyGateway } from '../../gateways/electric-supply.gateway';
import { ComplexGateway } from '../../gateways/complex.gateway';
import { EstablishmentGateway } from '../../gateways/establishment.gateway';
import { CompanyGateway } from '../../gateways/company.gateway';
import CountryGateway from '../../gateways/country.gateway';
import { ProvidersGateway } from '../../gateways/providers.gateway';
import { ContactGateway } from '../../gateways/contact.gateway';
import { ElectricSupplyRatesGateway } from '../../gateways/electric-supply-rates.gateway';
import { FuelSupplyRatesGateway } from '../../gateways/fuel-supply-rates.gateway';
import { FuelTypesGateway } from '../../gateways/fuel-types.gateway';
import { IClient } from '../../gateways/clients.interfaces';
import { IElectricSupplyRates } from '../../gateways/electric-supply-rates.interface';
import { IFuelSupplyRate } from '../../gateways/fuel-supply-rates.interface';
import { IComplex } from '../../gateways/complex.interfaces';
import { IEstablishment } from '../../gateways/establishment.interface';
import ICompany from '../../gateways/company.interface';
import ICountry from '../../gateways/country.interfaces';
import { IFuelType } from '../../gateways/fuel-types.interface';
import { IProviders } from '../../gateways/providers.interface';
import { IElectricSupply } from '../../gateways/electric-supply.interface';
import { IFuelSupply } from '../../gateways/fuel-supply.interface';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    }
}));

const HiringDashboardList: FC = () => {
    const classes = useStyles();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState<IHiringDashboard[]>([]);
    const { url } = useRouteMatch();
    const [openUpsertDialog, setOpenUpsertDialog] = useState(false);
    const [item, setItem] = useState<IHiringDashboard>();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);
    const { t } = useTranslation();
    const hiringDashboardGateway = useIoC(HiringDashboardGateway);
    const [, navigatorDispatch] = useNavigator();

    const [rates, setRates] = useState<IAutoComplete>(initialIAutoComplete);
    const [primaryEnergies, setPrimaryEnergies] = useState<IAutoComplete>(initialIAutoComplete);
    const [contacts, setContacts] = useState<IContact[]>([]);

    const contactsGateway = useIoC(ContactGateway);
    const electricRatesGateway = useIoC(ElectricSupplyRatesGateway);
    const fuelRatesGateway = useIoC(FuelSupplyRatesGateway);
    const fuelTypeGateway = useIoC(FuelTypesGateway);

    const handleClickFabButton = () => {
        setItem(undefined);
        setOpenUpsertDialog(true);
    };

    const handleItemUpsert = (item: IHiringDashboard, isNew: boolean) => {
        if (isNew) {
        }
    }

    const handleCloseUpsert = () => {
        setOpenUpsertDialog(false);
    }

    useEffect(() => {
        (async () => {
            try {
                setIsDataLoading(true);
                const data = await hiringDashboardGateway.findAll();

                const electricRates = await electricRatesGateway.findAll();
                const fuelRates = await fuelRatesGateway.findAll();
                const ratesInterface: IAutoComplete = {
                    electricidad: [],
                    fuel: [],
                };
                electricRates.map((electricRate: IElectricSupplyRates) => { ratesInterface.electricidad.push(electricRate.name) });
                fuelRates.map((fuelRate: IFuelSupplyRate) => { ratesInterface.fuel.push(fuelRate.name) });
                setRates(ratesInterface);

                const primaryEnergies: IAutoComplete = {
                    electricidad: [],
                    fuel: []
                };
                primaryEnergies.electricidad.push('Electricidad');
                const fuelTypes = await fuelTypeGateway.findAll();
                fuelTypes.map((fuelType: IFuelType) => { primaryEnergies.fuel.push(fuelType.name) });
                setPrimaryEnergies(primaryEnergies);

                const contacts = await contactsGateway.find();
                setContacts(contacts);

                setData(data);
                setIsDataLoading(false);
            } catch (e) {
                setIsDataLoading(false);
            }
        })();
    }, [hiringDashboardGateway]);

    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />;
        }

        if (isDataEmpty) {

            return <AlertBox variant='info'>{t('common.noItems')}</AlertBox>;
        }

        return (
            <div>
                <HiringDashboardItem
                    items={data}
                />
            </div>
        );
    };

    return (
        <Box>
            <ScreenGlobalStructure
                buttonIcon={<AddIcon />}
                headerIcon={DashboardIcon}
                matchUrl={url}
                onButtonClick={handleClickFabButton}
                title={t('hiringDashboardList.title')}
                loading={isDataLoading}
            >
                <Content />
            </ScreenGlobalStructure>
            <HiringDashboardUpsertDialog
                rates={rates}
                primaryEnergies={primaryEnergies}
                contacts={contacts}
                item={undefined}
                open={openUpsertDialog}
                onClose={handleCloseUpsert}
                onItemUpsert={handleItemUpsert}
            />
        </Box>
    );
};

export default HiringDashboardList;