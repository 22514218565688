import React from "react";
import {  Switch, Route, useRouteMatch } from "react-router";
import RegisterElectricBillList from "./register-electric-bill-list";
import ElectricSupplyDetail from "../electric-supply/electric-supply-detail";
import RegisterElectricBillDetail from "./register-electric-bill-detail";


const RegisterElectricBillRouter = () => {
    
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/`} exact>
                <RegisterElectricBillList/>
            </Route>
            <Route path={`${path}/:registerElectricBillId`} exact>
                <RegisterElectricBillDetail/>
            </Route>
        </Switch>
    );
};
export default RegisterElectricBillRouter;
