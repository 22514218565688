import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { IValidationRule, initialValidationRule } from "../../gateways/validation-rules.interfaces";
import { IRule } from "../../gateways/rules.interfaces";
import useIoC from "../../contexts/ioc.context";
import TextValidationRulesForm from "./text-validation-rules-form";
import RuleValidationRulesForm from "./rule-validation-rules-form";
import ResultConditionalValidationRulesForm from "./result-conditional-validation-rules-form";
import { ValidationRuleGateway } from "../../gateways/validation-rules.gateway";

interface IValidationRuleFormProps {
    item: IValidationRule;
    onSubmit: (item: IValidationRule) => void;
}

const ValidationRuleForm: FC<IValidationRuleFormProps> = ({ item, onSubmit }) => {
    const { t } = useTranslation();
    const snackbar = useSnackbar();

    const [activeStep, setActiveStep] = useState(0);
    const [validationRule, setValidationRule] = useState<IValidationRule>(initialValidationRule);

    const validationRuleGateway = useIoC(ValidationRuleGateway);

    useEffect(() => {
        setValidationRule(item);
    }, []);

    const handleChange = (name: string, value: any) => {
        const itemChaged = { ...validationRule, [name]: value };
        setValidationRule(itemChaged);
    };

    const handleChangeRules = (rules: IRule[]) => {
        setValidationRule({ ...validationRule, rules: rules });
    }
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleEnd = () => {
        if (item.id === 0) {
            handleCreate()
        }else{
            handleModify()
        }
    };

    const handleCreate = () => {
        (async () => {

            try {
                const createdValidationRule = await validationRuleGateway.create(validationRule);
                snackbar.enqueueSnackbar(t('validationRuleForm.createSucceded', { name: item.name }), { variant: "success" });
                onSubmit(createdValidationRule);
            } catch (e) {
                const er = e as any;
                if (er.response.data.key === 'creatingEntityError') {
                    const key = 'sectorErrorHandler.' + er.response.data.key;
                    const message = "validation" in er ? er.validation : t(key);
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                } else {
                    const message = "validation" in er ? er.validation : t('messages.defaultError');
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                }
            }
        })();
    }

    const handleModify = () => {
        (async () => {

            try {
                const updatedValidationRule = await validationRuleGateway.update(item.id, validationRule);
                snackbar.enqueueSnackbar(t('validationRuleForm.updateSucceded', { name: item.name }), { variant: "success" });
                onSubmit(updatedValidationRule);
            } catch (e) {
                const er = e as any;
                if (er.response.data.key === 'creatingEntityError') {
                    const key = 'sectorErrorHandler.' + er.response.data.key;
                    const message = "validation" in er ? er.validation : t(key);
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                } else {
                    const message = "validation" in er ? er.validation : t('messages.defaultError');
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                }
            }

        })();
    }


    switch (activeStep) {
        case 0:
            return (
                <TextValidationRulesForm
                    item={validationRule}
                    nextStep={handleNext}
                    onChange={handleChange}
                />
            );
        case 1:
            return (
                <RuleValidationRulesForm
                    item={validationRule}
                    nextStep={handleNext}
                    prevStep={handleBack}
                    onChange={handleChangeRules}
                />
            );
        case 2:
            return (
                <ResultConditionalValidationRulesForm
                    item={validationRule}
                    endStep={handleEnd}
                    prevStep={handleBack}
                    onChange={handleChange}
                />
            );
        default:
            return (<></>);

    }
};

export default ValidationRuleForm;