import { inject } from 'aurelia-dependency-injection';
import { AxiosInstance } from 'axios';
import { ILoginRequest, ILoginResult, IUser, IRefreshTokenResult } from './users.interfaces';

@inject('axios')
export class UsersGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async login(request: ILoginRequest): Promise<ILoginResult> {
        try {
            const response = await this.axios.post('users/login', request);
            return response.data;
        } catch (e) {
            console.log(e);
            return { isValid: false, message: 'Something went wrong', token: 'null' };
        }
    }

    public async getSelf(): Promise<IUser> {
        const response = await this.axios.get('users/self');
        return response.data;
    }

    public async refreshToken(accessToken: string): Promise<IRefreshTokenResult> {
        const response = await this.axios.post('/auth/refresh-token', {
            accessToken,
        });
        return response.data;
    }
}