import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { IElectricSupply } from "../../gateways/electric-supply.interface";
import { IElectricContract } from "../../gateways/electric-contract.interfaces";

export interface ElectricContractGoDetailDialogProps {
    open: boolean,
    item: IElectricContract | undefined;
    navigateToNextWindow: (item: IElectricContract) => void,
    handleClose: () => void,
}

const ElectricContractGoDetailDialog: FC<ElectricContractGoDetailDialogProps> = ({ open, item, navigateToNextWindow, handleClose}) => {
    const [componentDisabled, setComponentDisabled] = useState(false);
    const { t } = useTranslation();

    const handleGoDetail = () => {
        if (!item){return;}
        setComponentDisabled(true);
        navigateToNextWindow(item);
        setComponentDisabled(false);
        handleClose();
      };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('electricContractGoDetailDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('electricContractGoDetailDialog.content')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={componentDisabled}>{t('common.cancel')}</Button>
                <Button onClick={handleGoDetail} color="primary" disabled={componentDisabled}>{t('common.navigateToDetail')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ElectricContractGoDetailDialog;