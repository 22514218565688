import React from "react";
import {  Switch, Route, useRouteMatch } from "react-router";
import ValidationContextList from "./validation-context-list";
import ValidationContextDetail from "./validation-context-detail";


const ValidationContextRouter = () => {
    
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/`} exact>
                <ValidationContextList/>
            </Route>
            <Route path={`${path}/:validationContextId`} exact >
                <ValidationContextDetail />
            </Route>
        </Switch>
    );
};
export default ValidationContextRouter;
