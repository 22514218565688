import React, { FC, useEffect, useState, Fragment, useMemo } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { makeStyles, Fab, CircularProgress, TableHead, TableBody, Table, TableRow, TableCell, Button, TextField, Grid, AppBar, Tabs, Tab } from "@material-ui/core";
import UserIcon from '@material-ui/icons/Person';
import { useNavigator } from '../../contexts/navigator.context';
import { useTranslation } from "react-i18next";
import SaveIcon from '@material-ui/icons/Save';
import useIoC from "../../contexts/ioc.context";
import { ClientsGateway } from "../../gateways/clients.gateway";
import { useSnackbar } from "notistack";
import FormTextField from "../../components/form-text-field";
import NewActionDialog from './new-action-dialog';
import CompleteActionDialog from './complete-action-dialog';
import DetailActionDialog from './detail-action-dialog';
import moment from 'moment';
import { MarketingGateway } from "../../gateways/marketing.gateway";
import { ICreateClientMarketingDto, IClientMarketingTask, IClientMarketingContact, IClientMarketing } from "../../gateways/marketing.interfaces";

interface IClientDetailRouteParams {
    clientId: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingTop: theme.spacing(2),
        marginBottom: 70,
    },
    formControl: {
        marginBottom: '1rem',
        '& :last-child': {
            marginBottom: 0
        }
    },
    progress: {
        margin: theme.spacing(2),
    },
    tableRow: {
        '& td': {
            borderBottom: 0
        }
    },
    actionButton: {
        marginLeft: theme.spacing(1)
    },
    subtitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    appBar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
}));

const ClientTabPanel: FC<{ value: number, index: number }> = ({ value, index, children }) => {
    if (value === index) {
        return (<>{children}</>);
    }
    return null;
}

const ClientDetail: FC<RouteComponentProps<IClientDetailRouteParams>> = ({ match, history }) => {
    const [, navigatorDispatch] = useNavigator();
    const classes = useStyles();
    const { t } = useTranslation();
    const [clientDto, setClientDto] = useState<IClientMarketing>(null as any);
    const isNewClient = match.params.clientId === 'create';
    const clientId = !isNewClient ? parseInt(match.params.clientId) : null;
    const marketingGateway = useIoC(MarketingGateway);
    const [loading, setLoading] = useState(true);
    const snackbar = useSnackbar();
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [completeDialogOpen, setCompleteDialogOpen] = useState(false);
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [selectedMarketingAction, setSelectedMarketingAction] = useState<IClientMarketingTask | null>(null);
    const [tabIndex, setTabIndex] = useState<number>(isNewClient ? 1 : 0);

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('clientDetail.title'),
                icon: UserIcon
            }
        });
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (clientId !== null) {
                const client = await marketingGateway.findClientById(clientId);
                setClientDto(client);
            } else {
                setClientDto({
                    comercialName: '',
                    businessName: '',
                    nif: '',
                    website: '',
                    decisorName: '',
                    filterName: '',
                    contacts: [],
                    tasks: [],
                });
            }

            setLoading(false);
        })();
    }, [clientId]);

    const handleSave = () => {
        (async () => {
            try {
                if (clientId !== null) {
                    await marketingGateway.updateClient(clientId, clientDto);

                    snackbar.enqueueSnackbar(t('clientDetail.updateSucceded', { id: clientId, name: clientDto.businessName }), {
                        variant: 'success',
                    });
                } else {
                    const c = await marketingGateway.createClient(clientDto);

                    snackbar.enqueueSnackbar('Se ha dado de alta correctamente el cliente', {
                        variant: 'success',
                    });

                    history.push('/marketing/clients/' + c.id);
                }
            } catch (e) {
                const er = e as any;
                if (er.response.data.key === 'updatingError') {
                    const key = 'ClientsErrorHandler.' + er.response.data.key;
                    const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                } else {
                    const message = "validation" in er ? er.validation : t('messages.defaultError');
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                }
            }
        })();
    };

    const validateRequired = (value: any) => {
        return value !== null && value !== undefined && value !== '' && value !== 0;
    };

    const handleChange = (name: string, value: any) => {
        setClientDto({ ...clientDto, [name]: value });
    };

    const handleActionCreated = (action: IClientMarketingTask) => {
        (async () => {
            if (clientId === null) return;
            setLoading(true);
            const client = await marketingGateway.findClientById(clientId);
            setClientDto(client);
            setLoading(false);
        })();
    }

    const handleActionCompleted = (action: IClientMarketingTask) => {
        (async () => {
            if (clientId === null) return;
            setLoading(true);
            const client = await marketingGateway.findClientById(clientId);
            setClientDto(client);
            setLoading(false);
        })();
    }

    const handleShowAddActionDialog = () => {
        setAddDialogOpen(true);
    }

    const handleShowDetailActionDialog = (action: IClientMarketingTask) => {
        setSelectedMarketingAction(action);
        setDetailDialogOpen(true);
    }

    const handleCloseAddActionDialog = () => {
        setAddDialogOpen(false);
    }

    const handleShowCompleteActionDialog = (action: IClientMarketingTask) => {
        setSelectedMarketingAction(action);
        setCompleteDialogOpen(true);
    }

    const handleCloseCompleteActionDialog = () => {
        setCompleteDialogOpen(false);
    }

    const handleCloseDetailActionDialog = () => {
        setDetailDialogOpen(false);
    }

    const handleAddContact = (contactTypeId: number) => {
        const contacts: IClientMarketingContact[] = ([] as IClientMarketingContact[]).concat(clientDto.contacts);
        contacts.push({ contactTypeId, value: '', comment: '' })
        handleChange('contacts', contacts);
    }

    const handleChangeContact = (contact: IClientMarketingContact, data: Partial<IClientMarketingContact>) => {
        Object.assign(contact, data);
        const contacts: IClientMarketingContact[] = ([] as IClientMarketingContact[]).concat(clientDto.contacts);
        handleChange('contacts', contacts);
    }

    const handleDeleteContact = (contact: IClientMarketingContact) => {
        const contacts: IClientMarketingContact[] = ([] as IClientMarketingContact[])
            .concat(clientDto.contacts)
            .filter(x => x !== contact);
        handleChange('contacts', contacts);
    }

    const handleInitActionsMarketing = async () => {
        if (clientId) {
            if (window.confirm('¿Quieres iniciar la primera acción de marketing?')) {
                setLoading(true);

                await marketingGateway.initActionsMarketing(clientId);

                snackbar.enqueueSnackbar('Se ha iniciado correctamente', {
                    variant: 'success'
                });

                const client = await marketingGateway.findClientById(clientId);
                setClientDto(client);
                setLoading(false);
            }
        }
    }

    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        console.log('changing tab: ' + newValue);
        setTabIndex(newValue);
    }

    const handleAbortTask = async (task: IClientMarketingTask) => {
        if (clientId && !!task.id) {
            if (window.confirm(`¿Quieres anular la acción ${task.id} de marketing?`)) {
                setLoading(true);

                await marketingGateway.abortTask(task.id);

                snackbar.enqueueSnackbar('Se ha abortado correctamente', {
                    variant: 'success'
                });

                const client = await marketingGateway.findClientById(clientId);
                setClientDto(client);
                setLoading(false);
            }
        }
    }

    if (loading || clientDto === null) {
        return <CircularProgress className={classes.progress} />;
    }

    return (<div className={classes.root}>
        <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleSave}>
            <SaveIcon />
        </Fab>

        <div className={classes.content}>
            <h3 className={classes.subtitle}>{t('marketing.basicData')}</h3>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    {/* Formulario */}
                    <Grid container spacing={2}>
                        <Grid sm={3} item>
                            <FormTextField
                                autoFocus
                                fullWidth
                                label="NIF"
                                name='nif'
                                type='text'
                                value={clientDto.nif || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid sm={3} item>
                            <FormTextField
                                autoFocus
                                fullWidth
                                label={t('common.businessName')}
                                name='businessName'
                                required
                                validator={validateRequired}
                                errorText={t('messages.requiredField') as string}
                                type='text'
                                value={clientDto.businessName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid sm={3} item>
                            <FormTextField
                                autoFocus
                                fullWidth
                                label="Nombre comercial"
                                name='comercialName'
                                required
                                validator={validateRequired}
                                errorText={t('messages.requiredField') as string}
                                type='text'
                                value={clientDto.comercialName || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid sm={3} item>
                            <FormTextField
                                autoFocus
                                fullWidth
                                label="Sitio web"
                                name='website'
                                required
                                validator={validateRequired}
                                errorText={t('messages.requiredField') as string}
                                type='text'
                                value={clientDto.website || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid sm={6} item>
                            <FormTextField
                                autoFocus
                                fullWidth
                                label={t('common.filterName')}
                                name='filterName'
                                type='text'
                                value={clientDto.filterName || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid sm={6} item>
                            <FormTextField
                                autoFocus
                                fullWidth
                                label={t('common.decisorName')}
                                name='decisorName'
                                type='text'
                                value={clientDto.decisorName || ''}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {isNewClient ? null : (
                <AppBar position="static" className={classes.appBar} variant="outlined" color="default">
                    <Tabs value={tabIndex} onChange={handleChangeTab}>
                        <Tab label="Acciones" />
                        <Tab label="Datos de contacto" />
                        <Tab label="Resumen" />
                    </Tabs>
                </AppBar>
            )}

            <ClientTabPanel value={tabIndex} index={0}>
                {
                    clientDto.tasks.length === 0
                        ? (<>
                            <div>
                                <Button variant="outlined" onClick={handleInitActionsMarketing}>Iniciar acciones comerciales con el cliente</Button>
                            </div>
                        </>)
                        : (<>
                            <div>
                                <Button variant="outlined" onClick={handleShowAddActionDialog}>Alta de nueva acción comercial</Button>
                            </div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>{t('marketing.registerDate')}</TableCell>
                                        <TableCell>{t('marketing.taskDate')}</TableCell>
                                        <TableCell>{t('marketing.expiryDate')}</TableCell>
                                        <TableCell>{t('marketing.taskType')}</TableCell>
                                        <TableCell>{t('marketing.result')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {clientDto.tasks.map((task, index) => (<>
                                        <TableRow key={'task_' + index}>
                                            <TableCell>{task.id}</TableCell>
                                            <TableCell>{moment(task.registerDate).format('DD/MM/YYYY HH:mm')}</TableCell>
                                            <TableCell>
                                                {task.taskDate ? moment(task.taskDate).format('DD/MM/YYYY HH:mm') : 'N/a'}

                                            </TableCell>
                                            <TableCell>{task.expiryDate ? moment(task.expiryDate).format('DD/MM/YYYY') : 'N/a'}</TableCell>
                                            <TableCell>{task.taskType.name}</TableCell>
                                            <TableCell>{task.completedDate ? (task.completedResult ? task.completedResult.name : 'Abortada') : 'Pendiente'}</TableCell>
                                            <TableCell align="right">
                                                {
                                                    !task.completedDate
                                                        ? (<>
                                                            <Button variant="outlined" className={classes.actionButton} size="small" color="primary" onClick={() => handleShowCompleteActionDialog(task)}>{t('marketing.complete')}</Button>
                                                            <Button variant="outlined" className={classes.actionButton} size="small" color="secondary" onClick={() => handleAbortTask(task)}>Anular</Button>
                                                        </>)
                                                        : null
                                                }
                                                <Button variant="outlined" size="small" className={classes.actionButton} color="default" onClick={() => handleShowDetailActionDialog(task)}>Detalle</Button>
                                            </TableCell>
                                        </TableRow>
                                    </>))}
                                </TableBody>
                            </Table>
                        </>)
                }

                <NewActionDialog
                    open={addDialogOpen}
                    client={clientDto}
                    onClose={handleCloseAddActionDialog}
                    onActionCreated={handleActionCreated}
                />
                {
                    selectedMarketingAction
                        ? (<>
                            <CompleteActionDialog
                                open={completeDialogOpen}
                                client={clientDto}
                                action={selectedMarketingAction}
                                onClose={handleCloseCompleteActionDialog}
                                onActionCompleted={handleActionCompleted}
                            />
                            <DetailActionDialog
                                open={detailDialogOpen}
                                client={clientDto}
                                action={selectedMarketingAction}
                                onClose={handleCloseDetailActionDialog}
                            />
                        </>)
                        : null
                }
            </ClientTabPanel>

            <ClientTabPanel value={tabIndex} index={1}>
                <Grid container spacing={1}>

                    <Grid item sm={6}>
                        <h3 className={classes.subtitle}>{t('marketing.contactNumber')}<Button color="primary" onClick={() => handleAddContact(0)}>{t('marketing.addPhone')}</Button></h3>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('marketing.phone')}</TableCell>
                                    <TableCell>{t('marketing.comment')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientDto.contacts.filter(x => x.contactTypeId === 0).map((contact, index) => {
                                    return (
                                        <TableRow key={'contact_phone_' + index}>
                                            <TableCell>
                                                <TextField
                                                    value={contact.value}
                                                    type="text"
                                                    onChange={(e) => handleChangeContact(contact, { value: e.target.value })}
                                                    fullWidth={true}
                                                    margin="dense"
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={contact.comment}
                                                    type="text"
                                                    onChange={(e) => handleChangeContact(contact, { comment: e.target.value })}
                                                    fullWidth={true}
                                                    margin="dense"
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell align="right"><Button color="secondary" onClick={() => handleDeleteContact(contact)}>{t('marketing.remove')}</Button></TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item sm={6}>
                        <h3 className={classes.subtitle}>{t('marketing.addresses')} <Button color="primary" onClick={() => handleAddContact(1)}>{t('marketing.addAdress')}</Button></h3>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('marketing.address')}</TableCell>
                                    <TableCell>{t('marketing.comment')}</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientDto.contacts.filter(x => x.contactTypeId === 1).map((contact, index) => (
                                    <TableRow key={'contact_address_' + index}>
                                        <TableCell>
                                            <TextField
                                                value={contact.value}
                                                type="text"
                                                onChange={(e) => handleChangeContact(contact, { value: e.target.value })}
                                                fullWidth={true}
                                                margin="dense"
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                value={contact.comment}
                                                type="text"
                                                onChange={(e) => handleChangeContact(contact, { comment: e.target.value })}
                                                fullWidth={true}
                                                margin="dense"
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell align="right"><Button color="secondary" onClick={() => handleDeleteContact(contact)}>{t('marketing.remove')}</Button></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </ClientTabPanel>

            <ClientTabPanel value={tabIndex} index={2}>
                Resumen
            </ClientTabPanel>
        </div>
    </div>);
}

export default withRouter(ClientDetail);
