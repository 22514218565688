import { FC } from "react";
import { makeStyles, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton} from "@material-ui/core";
import React from "react";
import AssessmentIcon from '@material-ui/icons/Assessment';
import UpdateIcon from '@material-ui/icons/Create';
import { IEstablishmentType } from '../../gateways/establishment-type.interface';
import DeleteIcon from '@material-ui/icons/Delete';

interface IEstablishmentTypeItemProps {
    item: IEstablishmentType;
    onClickUpdateButton: (item: IEstablishmentType) => void;
    onClickDeleteButton: (item: IEstablishmentType) => void;
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

const EstablishTypeItem: FC<IEstablishmentTypeItemProps> = (props) => {
    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar >
                <AssessmentIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
            </ListItemAvatar>
            <ListItemText primary={props.item.name} />
            <ListItemSecondaryAction>
                <IconButton onClick={() => { props.onClickUpdateButton(props.item) }} aria-label="update"><UpdateIcon /></IconButton>
                <IconButton onClick={() => { props.onClickDeleteButton(props.item) }} aria-label="delete"><DeleteIcon /></IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
export default EstablishTypeItem;