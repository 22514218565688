
import  { IFuelSupply }  from "../../gateways/fuel-supply.interface";
import { createContext, Dispatch } from "react";

export type StateType = { fuelSupply: IFuelSupply | null };
export type ActionType = { type: 'update-fuelSupply', payload: IFuelSupply };

export const FuelSupplyReducer = (state: StateType, action: ActionType) => {
    switch (action.type) {
        case 'update-fuelSupply':
            return { ...state, fuelSupply: action.payload };
    }
    return state;
};

export const FuelSupplyContext = createContext<[StateType, Dispatch<ActionType>]>([
    { fuelSupply: null },
    (state) => state,
]);
