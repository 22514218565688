import React, { FC } from "react";
import { Tooltip, makeStyles } from "@material-ui/core";
import AdbIcon from '@material-ui/icons/Adb';
import { green, grey } from '@material-ui/core/colors';
import { IDisplayElectricContractList } from "../gateways/electric-contract.interfaces";
import { ISupplyConnectionBot } from "../gateways/supply-connection-bot.interface";

const useStyles: any = makeStyles((theme) => ({
    textTooltip: {
        fontSize: "2em"
    }
}));

interface IComponentIconProps {
    item?: ISupplyConnectionBot,
}

const ComponentIcon: FC<IComponentIconProps> = (props) => {
    const classes = useStyles();
    if (!props.item || !props.item.botPreconfig) {
        return null;
    }

    return (
        <Tooltip title={
            <span className={classes.textTooltip}>
                {props.item.botPreconfig.alias}
            </span>} arrow>
            {
                props.item.enabled ?
                    <AdbIcon fontSize="large" style={{ color: green[500] }} />
                    :
                    <AdbIcon fontSize="large" style={{ color: grey[500] }} />
            }
        </Tooltip>);

};

export default ComponentIcon;