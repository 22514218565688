import 'reflect-metadata';
// import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/browser';
import es from './locale/es.json';
import { render } from "react-dom";
import React from 'react';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

Sentry.init({ dsn: "https://5d01ff6ef1df4c2e960ace65fcda872c@sentry.io/1726744" });

const DEFAULT_LANGUAGE = "es";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            es: es
        },
        lng: DEFAULT_LANGUAGE,
        fallbackLng: DEFAULT_LANGUAGE,
        keySeparator: ".",
        interpolation: {
            escapeValue: false
        }
});

const rootElement = document.getElementById("root");
render(<App />, rootElement);