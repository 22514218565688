import React, { FC } from "react";
import { makeStyles, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import { IRegisterFuelBill } from "../../gateways/register-fuel-bill.interface";

interface IRegisterFuelBillItemProps {
    item: IRegisterFuelBill;
    onClickItem: (item: IRegisterFuelBill) => void;
}

const useStyles: (props?: any) => Record<"listItem" |
"itemAvatarIcon", string> = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

const RegisterFuelBillItem: FC<IRegisterFuelBillItemProps> = (props) => {
    const classes = useStyles();

        return (
            <ListItem alignItems="flex-start" className={classes.listItem} onClick={() => { props.onClickItem(props.item) }}>
                <ListItemAvatar >
                    <DescriptionIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
                </ListItemAvatar>
                <ListItemText primary={`Número de factura ${props.item.numberBill}`}
                 secondary={`Fecha de emisión 
                 ${`${new Date(props.item.issueDate).getDate()}/${new Date(props.item.issueDate).getMonth() + 1}/
                 ${new Date(props.item.issueDate).getFullYear()}`}`}/>
            </ListItem>
        );
};

export default RegisterFuelBillItem;