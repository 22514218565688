import React, { FC, useState } from "react";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from "@material-ui/core";
import ContactIcon from "@material-ui/icons/Contacts";
import DeleteIcon from "@material-ui/icons/Delete";
import IContact from "../../gateways/contact.interface";

export interface IContactItemProps {
  contact: IContact;
  classes: Record<"listItem" | "itemAvatarIcon", string>;
  handleClick: (contact: IContact) => void;
  handleDelete: (contact: IContact) => void;
  isParent: boolean;
}

const ContactItem: FC<IContactItemProps> = ({
  contact,
  classes,
  handleClick,
  handleDelete,
  isParent: isChild,
}) => {
  return (
    <ListItem
      alignItems="flex-start"
      className={classes.listItem}
      onClick={() => {
        handleClick(contact);
      }}
    >
      <ListItemAvatar>
        <ContactIcon
          color="action"
          fontSize="large"
          className={classes.itemAvatarIcon}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          isChild ? (
            <Typography style={{ color: "#B7B5B5" }}>
              {contact.fullName}
            </Typography>
          ) : (
            contact.fullName
          )
        }
        secondary={contact.email}
      />
      <ListItemSecondaryAction>
        <IconButton onClick={() => { handleDelete(contact) }} aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ContactItem;
