import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IProviders, IUpdateProvidersList, IProvidersDisplay } from "./providers.interface"
import { ISupplyToAccess } from "./supply-to-access.interfaces";
import querystring from 'querystring';

const providerResource = 'providers';
type  IFindFilterProvider = {
    providerTypeId?: string;
    accessTypeId?: string;
    supplyTypeId?: string;
} 
@inject('axios')
export class ProvidersGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IProviders[]> {
        const response = await this.axios.get(providerResource);
        return response.data;
    }

    public async findWithFilters(filter: IFindFilterProvider): Promise<IProvidersDisplay[]> {
        let url = `${providerResource}/filter`;
        if (filter) {
            const query = querystring.stringify(filter);
            url += `?${query}`;
        }
        const response = await this.axios.get(url, { params: {filter} });
        return response.data;
    }

    async findAllFiltered(input: string): Promise<string[]> {
        const response = await this.axios.get(`${providerResource}/filter/${input}`);
        return response.data;
    }

    async findById(id: number): Promise<IProviders> {
        const url = `${providerResource}/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    async create(provider: IUpdateProvidersList): Promise<IProviders> {
        const response = await this.axios.post(providerResource, provider);
        return response.data;
    }

    async update(id: number, provider: IUpdateProvidersList): Promise<IProviders> {
        const url = `${providerResource}/${id}`;
        const response = await this.axios.put(url, provider);
        return response.data;
    }

    async delete(id: number): Promise<void> {
        const url = `${providerResource}/${id}`;
        const response = await this.axios.delete(url);
        return response.data;
    }

    async findByIdSupplyToAccess(id: number): Promise<ISupplyToAccess[]> {
        const url = `${providerResource}/supplyToAccess/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }
}