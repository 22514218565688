import React, { FC, useState, ChangeEvent, useEffect } from 'react';
import IContactType from '../../gateways/contact-types.interfaces';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { ContactTypesGateway } from '../../gateways/contact-types.gateway';
import useIoC from '../../contexts/ioc.context';
import { isNullOrUndefined } from 'util';
import { useTranslation } from 'react-i18next';

export interface IContactTypeUpdateDialogProps {
    open: boolean,
    onClose: () => void,
    onContactTypeUpdated: (contactType: IContactType) => void,
    contactType: IContactType
}

const ContactTypeUpdateDialog: FC<IContactTypeUpdateDialogProps> = ({ open, onClose, onContactTypeUpdated, contactType }) => {
    const [item, setItem] = useState<IContactType>();
    const [name, setName] = useState('');
    const [errorNamefield, setErrorNamefield] = useState(false);
    const [textfieldDisabled, setTextfieldDisabled] = useState(false);
    const [helperText, setHelperText] = useState('');
    const snackbar = useSnackbar();
    const contactTypesGateway = useIoC(ContactTypesGateway);
    const { t } = useTranslation();

    const handleChangeName = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setName(value);
        if (value === '') {
            setErrorNamefield(true);
            setHelperText(t('messages.requiredField') as string);
        } else {
            setErrorNamefield(false)
            setHelperText('');
        }
    }
    const handleCreateContactType = () => {
        if (name === '') {
            setErrorNamefield(true);
            setHelperText(t('messages.requiredField') as string);
            return;
        }
        setTextfieldDisabled(true);
        (async () => {
            try {
                const updatedContactType = await contactTypesGateway.update(contactType.id, { name: name });

                snackbar.enqueueSnackbar(t('contactTypeUpdateDialog.updateSucceded', { id: updatedContactType.id }), { variant: 'success' });

                setItem(updatedContactType);
                onContactTypeUpdated(updatedContactType);
            } catch (e) {
                const er = (e as any);
                if (er.response.data.key === 'updatingError') {
                    const key = 'contactTypeErrorHandler.' + er.response.data.key;
                    const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                } else {
                    const message = "validation" in er ? er.validation : t('messages.defaultError');
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                }
            } finally {
                setTextfieldDisabled(false);
                onClose();
            }
        })();
    };

    useEffect(() => {
        if (!isNullOrUndefined(contactType)) {
            setName(contactType.name);
        }
    }, [contactType]);

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('contactTypeUpdateDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('contactTypeUpdateDialog.content')}</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label={t('common.name')}
                    type="text"
                    fullWidth
                    onChange={handleChangeName}
                    value={name}
                    required
                    error={errorNamefield}
                    helperText={helperText}
                    disabled={textfieldDisabled}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={textfieldDisabled}>{t('common.cancel')}</Button>
                <Button onClick={handleCreateContactType} color="primary" disabled={textfieldDisabled}>{t('common.accept')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ContactTypeUpdateDialog;