import React, { FC, useEffect, useState } from "react";
import { makeStyles, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, ListItemIcon, Tooltip, IconButton } from "@material-ui/core";
import { IFuelSupply } from "../../gateways/fuel-supply.interface";
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import { useTranslation } from "react-i18next";
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import useIoC from "../../contexts/ioc.context";
import { FuelTypesGateway } from "../../gateways/fuel-types.gateway";
import { EstablishmentGateway } from "../../gateways/establishment.gateway";
import EstablishmentIcon from '@material-ui/icons/Store';

interface IFuelSupplyItemProps {
    item: IFuelSupply;
    onClickItem: (item: IFuelSupply) => void;
    establishmentId: string;
}

const useStyles:(props?: any) => Record<"listItem" |
"itemAvatarIcon" | "deletedSupply" | "nonDeletedSupply", string> = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    deletedSupply: {
        color: "#E0E0E0"
    },
    nonDeletedSupply: {
        color: "black"
    }
}));

const FuelSupplyItem: FC<IFuelSupplyItemProps> = (props) => {
    const classes = useStyles();

    const fuelTypeGateway = useIoC(FuelTypesGateway);
    const establishmentGateway = useIoC(EstablishmentGateway);

    const [fuelTypes, setFuelTypes] = useState<{ [id: number]: string }>([])
    const [establishmentName, setEstablishmentName] = useState<string>("");
   
    const ComponentIcon = () => {
        if (props.item.complexId === null && props.establishmentId === undefined) {
            return(
                <Tooltip title={establishmentName} placement="top">
                    <ListItemIcon>
                        <EstablishmentIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
                    </ListItemIcon>
                </Tooltip>
            );
        }
        return null;
    };
    
    let color = classes.nonDeletedSupply;
    if(props.item.blacklisted){
        color = classes.deletedSupply;
    }

    useEffect (() => {
        (async () => {
            const fuelTypes = await fuelTypeGateway.findAll();
            var orderedFuelTypes: {[id: number]: string} = [];
            for (let i: number = 0; i < fuelTypes.length; i++) {
                orderedFuelTypes[fuelTypes[i].id] = fuelTypes[i].name;
            };

            setFuelTypes(orderedFuelTypes);

            if(props.item.establishmentId){
                const establishment = await establishmentGateway.findById(props.item.establishmentId);
                setEstablishmentName(establishment.name);
            }
        })();
    },[])

        return (
            <div>
                <ListItem alignItems="flex-start" className={classes.listItem} onClick={() => { props.onClickItem(props.item)} }>
                    <ListItemAvatar >
                        <LocalGasStationIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
                    </ListItemAvatar>
                    <ListItemText className={color} primary={`${props.item.reference}`} secondary={`${fuelTypes[props.item.fuelTypeId]}`}/>
                    <ListItemSecondaryAction>
                        <ComponentIcon />
                    </ListItemSecondaryAction>
                </ListItem>
            </div>
        );
};

export default FuelSupplyItem;