import { initialValidationGroup, IValidationGroup } from "./validation-group.interfaces";
import { IRule } from "./rules.interfaces";

export interface IValidationRule {
    id: number;
    name: string;
    messageError: string;
    rules: IRule[];
    validationGroup: IValidationGroup;
    resultConditional: string;

}

export interface ICreateValidationRule {
    name: string;
    messageError: string;
    validationGroup: IValidationGroup;
}

export interface IUpdateValidationRule {
    id: number;
    name: string;
    messageError: string;
    validationGroup: IValidationGroup;
}

export const initialValidationRule: IValidationRule = {
    id: 0,
    name: '',
    messageError: '',
    rules: [],
    validationGroup: initialValidationGroup,
    resultConditional: ''
}

export type FindFilterValidationRule = {
    validationGroupId?: number;
}