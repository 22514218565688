import { inject } from 'aurelia-dependency-injection';
import { AxiosInstance } from 'axios';
import { IComplex, ICreateComplex, IUpdateComplex } from './complex.interfaces';
import IRegion from './region.interface';
import querystring from 'querystring';
import { IElectricSupply } from './electric-supply.interface';

@inject('axios')
export class RegionsGateway {

    constructor(
        private axios: AxiosInstance
    ) { }

    async findAllFiltered(input: string): Promise<string[]> {
        const response = await this.axios.get(`regions/filter/${input}`);
        return response.data;
    }
}