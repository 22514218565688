import React, { FC, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import {
  IFuelSupply,
  ICreateFuelSupply,
  initialICreateFuelSupply,
} from "../../gateways/fuel-supply.interface";
import { FuelSupplyGateway } from "../../gateways/fuel-supply.gateway";
import { FuelTypesGateway } from "../../gateways/fuel-types.gateway";
import { AccessTypeGateway } from "../../gateways/access-type.gateway";
import { IAccessType } from "../../gateways/access-type.interfaces";
import { IFuelType } from "../../gateways/fuel-types.interface";
import FuelSupplyForm from "./fuel-supply-form";

interface IFuelSupplyUpsertDialogProps {
  open: boolean;
  item: IFuelSupply | undefined;
  complexId?: string;
  establishmentId?: string;
  onItemUpsert: (item: IFuelSupply, isNew: boolean) => void;
  onClose: () => void;
}

const FuelSupplyUpsertDialog: FC<IFuelSupplyUpsertDialogProps> = (props) => {
  const { t } = useTranslation();

  const [item, setItem] = useState<ICreateFuelSupply>(initialICreateFuelSupply);
  const [accessTypes, setAccessTypes] = useState<IAccessType[]>([]);
  const [fuelTypes, setFuelTypes] = useState<IFuelType[]>([]);

  const snackbar = useSnackbar();
  const [errorMissingData, setErrorMissingData] = useState(false);
  const fuelSupplyGateway = useIoC(FuelSupplyGateway);
  const fuelTypeGateway = useIoC(FuelTypesGateway);
  const accessTypeGateway = useIoC(AccessTypeGateway);

  const createFuelSupply = async (item: ICreateFuelSupply) => {
    const fuelSupply = await fuelSupplyGateway.create(item);
    snackbar.enqueueSnackbar(
      t("fuelSupplyUpsertDialog.createSucceded", { id: fuelSupply.id }),
      { variant: "success" }
    );
    props.onItemUpsert(fuelSupply, true);
  };

  const upsertElectricSupply = async (item: ICreateFuelSupply) => {
    try {
      if (!props.item) {
        await createFuelSupply(item);
      }
    } catch (e) {
      const er = e as any;
      const key = "fuelSupplyErrorHandler." + er.response.data.key;
      if (er.response.data.key === "creatingEntityError") {
        const message = "validation" in er ? er.validation : t(key);
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else if (er.response.data.key === "updatingError") {
        const message =
          "validation" in er ? er.validation : t(key, { id: er.response.data.id });
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else {
        const message =
          "validation" in er ? er.validation : t("messages.defaultError");
        snackbar.enqueueSnackbar(message, { variant: "error" });
      }
    } finally {
      props.onClose();
    }
  };

  const handleAccept = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!item.accessTypeId || !item.fuelTypeId || !item.reference) {
      event.preventDefault();
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      setErrorMissingData(true);
      return;
    }

    const alreadyExists = await fuelSupplyGateway.findAllFiltered(item.reference);

    if(!!alreadyExists[0]){
      snackbar.enqueueSnackbar(t("messages.cupsAlreadyAssigned"), {
        variant: "error",
      });
      return;
    } 

    if (!item.complexId) {
      setItem({ ...item, complexId: null });
    } else if (!item.establishmentId) {
      setItem({ ...item, establishmentId: null });
    } else {
      const message = t("messages.defaultError");
      snackbar.enqueueSnackbar(message, { variant: "error" });
    }
    setErrorMissingData(false);
    upsertElectricSupply(item);
  };

  const handleChange = (name: string, value: any) => {
    setItem({ ...item, [name]: value });
  };

  const handleSendEmailChange = () => {
    setItem({ ...item, sendEmail: !item.sendEmail });
  };

  const handleSendClientEmailChange = () => {
    setItem({ ...item, sendClientEmail: !item.sendClientEmail });
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  useEffect(() => {
    (async () => {
      if (props.item) {
        setItem(item);
      } else {
        if (props.establishmentId) {
          setItem({
            ...initialICreateFuelSupply,
            establishmentId: Number.parseInt(props.establishmentId),
          });
        } else if (props.complexId) {
          setItem({
            ...initialICreateFuelSupply,
            complexId: Number.parseInt(props.complexId),
          });
        }
      }

      const fuelType_ = await fuelTypeGateway.findAll();
      const accessType_ = await accessTypeGateway.findAll();
      setFuelTypes(fuelType_);
      setAccessTypes(accessType_);
    })();
  }, [props.item, props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
    >
      <DialogTitle>
        {!props.item
          ? t("fuelSupplyUpsertDialog.createTitle")
          : t("fuelSupplyUpsertDialog.updateTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!props.item
            ? t("fuelSupplyUpsertDialog.createContent")
            : t("fuelSupplyUpsertDialog.updateContent")}
        </DialogContentText>
        <FuelSupplyForm
          supply={item}
          onChangeSupply={handleChange}
          accessTypes={accessTypes}
          fuelTypes={fuelTypes}
          validateRequired={validateRequired}
          onSendEmailChange={handleSendEmailChange}
          onSendClientEmailChange={handleSendClientEmailChange}
          errorMissingData={errorMissingData}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}
          color="primary"
        >
          {t("common.cancel")}
        </Button>
        <Button onClick={handleAccept} color="primary">
          {t("common.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FuelSupplyUpsertDialog;
