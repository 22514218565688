import React, { FC, useEffect, useState } from "react"
import { Fab, makeStyles, FormControl, InputLabel, Input, FormHelperText, Select, MenuItem, FormControlLabel, Checkbox } from "@material-ui/core";

import { RouteComponentProps } from "react-router-dom";
import { useNavigator } from '../../contexts/navigator.context';
import SupplyBotsIcon from '@material-ui/icons/Autorenew';

import SaveIcon from '@material-ui/icons/Save';
import { IBotRO } from "../../gateways/bots.interfaces";
import { ISaveSupplyBot } from "../../gateways/supply-bots.interface";
import { ISupplyTypeRO } from "../../gateways/supply-type.interfaces";
import useIoC from "../../contexts/ioc.context";
import { BotsGateway } from "../../gateways/bots.gateway";
import { SupplyTypeGateway } from "../../gateways/supply-type.gateway";
import { SupplyBotsGateway } from "../../gateways/supply-bots.gateway";
import { useSnackbar } from "notistack";
import { IBotPreconfigRO } from "../../gateways/bots.interfaces";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { SupplyBotDrawer } from './supply-bot.drawer';
import SupplyBotForm from "./supply-bot.form";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    formControl: {
        marginBottom: '1rem',
        '& :last-child': {
            marginBottom: 0
        }
    },
    configRoot: {
        padding: theme.spacing(3, 2),
        marginBottom: theme.spacing(1)
    }
}));

interface IRouteParams {
    id: string;
}

export const SupplyBotDetail: FC<RouteComponentProps<IRouteParams>> = ({ match, history }) => {
    const [, navigatorDispatch] = useNavigator();
    const classes = useStyles();
    const snackbar = useSnackbar();

    const id = (/^\d+$/g).test(match.params.id) ? parseInt(match.params.id) : -1;

    const supplyBotsGateway = useIoC(SupplyBotsGateway);

    const [supplyBot, setSupplyBot] = useState<ISaveSupplyBot>({
        cups: '',
        code: '',
        invoicesFolder: '',
        emailNotification: '',
        enabled: true,
        supplyTypeId: -1,
        accounts: [],
    });

    const handleSave = async () => {
        try {
            const result = await supplyBotsGateway.update(id, supplyBot);
            history.push('/supply-bots/' + result.id);
            snackbar.enqueueSnackbar('El bot se ha guardado correctamente', {
                variant: 'success',
            });
        } catch (e) {
            const er = e as any;
            const message = er.validation || er.message || 'Ha ocurrido un error no controlado';
            snackbar.enqueueSnackbar(message, {
                variant: 'error',
            });
        }
    }

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: 'Ficha del bot del suministro',
                icon: SupplyBotsIcon
            }
        });
        navigatorDispatch({
            type: 'set-right-nav',
            component: (<SupplyBotDrawer id={id} tabActive="profile" />)
        });
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const data = id === -1 ? null : await supplyBotsGateway.get(id);
                if (!data) {
                    snackbar.enqueueSnackbar('No se ha encontrado el bot del suministro', {
                        variant: 'error',
                    });
                    history.push('/supply-bots/');
                } else {
                    setSupplyBot({
                        code: data.code,
                        cups: data.cups,
                        emailNotification: data.emailNotification,
                        accounts: data.accounts.map(x => ({
                            botId: x.bot.id,
                            preconfigId: x.preconfig ? x.preconfig.id : -1,
                            startCaptureDate: x.startCaptureDate,
                            endCaptureDate: x.endCaptureDate,
                            enabled: x.enabled,
                        })),
                        enabled: data.enabled,
                        invoicesFolder: data.invoicesFolder,
                        supplyTypeId: data.supplyType.id,
                    });
                }
            } catch (e) {
                snackbar.enqueueSnackbar('Ha ocurrido un error al recuperar el bot del siuministro', {
                    variant: 'error',
                });
                history.push('/supply-bots/');
            }
        })();
    }, [match.params.id]);

    return (
        <form className={classes.root}>
            <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleSave}>
                <SaveIcon />
            </Fab>

            <SupplyBotForm
                data={supplyBot}
                onChange={setSupplyBot}
            />
        </form>
    )
}