import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IValidationGroup, ICreateValidationGroup, IUpdateValidationGroup } from "./validation-group.interfaces";

const validationGroup = 'validation-groups';

@inject('axios')
export class ValidationGroupGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IValidationGroup[]> {
        const response = await this.axios.get(validationGroup);
        return response.data;
    }

    async findById(id: number): Promise<IValidationGroup> {
        const url = `${validationGroup}/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    async create(validationGroupToCreate: ICreateValidationGroup): Promise<IValidationGroup> {
        const response = await this.axios.post(validationGroup, validationGroupToCreate);
        return response.data;
    }

    async update(id: number, validationGroupTypeToUpdate: IUpdateValidationGroup): Promise<IValidationGroup> {
        const url = `${validationGroup}/${id}`;
        const response = await this.axios.put(url, validationGroupTypeToUpdate);
        return response.data;
    }

    async delete(id: number): Promise<void> {
        const url = `${validationGroup}/${id}`;
        const response = await this.axios.delete(url);
        return response.data;
    }
}