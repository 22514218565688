import React, { FC, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, Typography,
         Button, DialogActions, DialogContentText, 
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import FormTextField from "../../components/form-text-field";
import { IJobSaveConfig, IVariableData, IVariableValue } from "../../gateways/prometheus.interface";
import { BotsGateway } from "../../gateways/bots.gateway";
import useIoC from "../../contexts/ioc.context";
import { useRouteMatch } from "react-router";

interface IPreconfigCustomDialog {
    open: boolean;
    onClose: () => void;
    variablesData: IVariableData[];
    onChange: (a: any, b: any) => void;
    setFieldsToDefault: () => void;
}

const PrometheusCustomDialog: FC<IPreconfigCustomDialog> = (props) => {

    const { t } = useTranslation();
    const snackbar = useSnackbar();
    const match = useRouteMatch<{ name: string }>();

    const initialSaveConfig: IJobSaveConfig = {
        jobName: match.params.name,
        name: "",
        description: "",
        fields: []
    }

    const [saveDialog, setSaveDialog] = useState<boolean>(false);
    const [config, setConfig] = useState<IJobSaveConfig>(initialSaveConfig); 

    const botsGateway = useIoC(BotsGateway);

    function fromString(
        cookies: string | string[] | undefined,
        processEvenUndescore?: boolean
    ) {
        if (!!!cookies) {
          return {};
        }
      
        if (typeof cookies === "string") {
          cookies = [cookies];
        }
      
        const output: { [cookie: string]: string } = {};
      
        for (const cookie of cookies) {
          const constFullCookie = cookie.split(";")[0];
          const name = constFullCookie.substring(0, constFullCookie.indexOf('=')).trim();
          const value = constFullCookie.substring(constFullCookie.indexOf('=') + 1).trim();
          if ((name.indexOf("__") === 0 && !processEvenUndescore) || !!!name) {
            continue;
          }
          output[name] = value;
        }
      
        return output;
    }

    const handleChange = (name: string, value: string) => {
        const saveConfig: IJobSaveConfig = {...config, [name]: value};
        setConfig(saveConfig);
    }

    const handleOpenSaveDialog = () => {
        if(props.variablesData.find(element => element.fieldValue === "") !== undefined){
            snackbar.enqueueSnackbar(t("messages.requiredFieldsAsterisk"), {
                variant: "error",
            });
            return;
        }
        setSaveDialog(true);
    }

    const handleCloseSaveDialog = () => {
        setSaveDialog(false);
    }  

    const handleSave = async () => {
        if(config.name === "" || config.description === "") {
            snackbar.enqueueSnackbar(t("messages.requiredFieldsAsterisk"), {
                variant: "error",
            });
            return;
        }

        const fields: IVariableValue[] = [];

        for(let i = 0; i < props.variablesData.length; i++) {
            fields.push({field: props.variablesData[i].name, 
                         value: props.variablesData[i].fieldValue})
        }
        
        await botsGateway.saveJobConfig({...config, fields});
        snackbar.enqueueSnackbar(t("common.configurationSaved"), {
            variant: "success",
        });
        setConfig(initialSaveConfig);
        handleCloseSaveDialog();
    }

    const handleExecute = async () => {
        if(props.variablesData.find(element => element.fieldValue === "") !== undefined){
            snackbar.enqueueSnackbar(t("messages.requiredFieldsAsterisk"), {
                variant: "error",
            });
            return;
        }
        
        const parsedVariables = props.variablesData.map((item) => {
            switch(item.type){
                case "number":
                    item.fieldValue = parseInt(item.fieldValue);
                    break;
                case "cookie":
                    item.fieldValue = fromString(item.fieldValue);
                default:
                    break;
            }
            return item;
        })

        await botsGateway.sendJobVariables(parsedVariables);

        snackbar.enqueueSnackbar(t("common.testExecuted"), {
            variant: "success",
        });

        props.setFieldsToDefault();
        props.onClose();
    }

    const renderFields = (variable: IVariableData) => {
        return(
            <>
                <FormTextField 
                    fullWidth
                    label={variable.name}
                    name={variable.name}
                    required={variable.presence === "required" ? true : false}
                    errorText={t("messages.requiredField") as string}
                    type="text"
                    value={variable.fieldValue}
                    onChange={props.onChange}
                    placeholder={variable.type === "cookie" ? "cookie from webpage" : variable.type}
                />
            </>
        );
    }

    return (
        <>
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle id="form-dialog-title">
                <Typography variant="h5" align="center">{t("common.prometheusCustomDialog")}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Se debe introducir, como mínimo, un valor en todos los campos que tienen un * para poder
                    ejecutar una prueba unitaria de este job.
                </DialogContentText>
                {props.variablesData.map(renderFields)}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="default">{t("common.close")}</Button>
                <Button onClick={handleOpenSaveDialog} color="primary">{t("common.save")}</Button>
                <Button onClick={handleExecute} color="primary">{t("common.execute")}</Button>
            </DialogActions>
        </Dialog>

        <Dialog open={saveDialog} onClose={handleCloseSaveDialog}>
            <DialogTitle id="form-dialog-title">
                <Typography variant="h5" align="center">{t("common.saveConfiguration")}</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Dale un nombre y una descripción a la configuración para que todos los usuarios
                    puedan entender para qué sirve.
                </DialogContentText>
                <FormTextField 
                    fullWidth
                    label="Name"
                    name="name"
                    required
                    errorText={t("messages.requiredField") as string}
                    type="text"
                    value={config.name}
                    onChange={handleChange}
                />
                <FormTextField 
                    fullWidth
                    label="Description"
                    name="description"
                    required
                    errorText={t("messages.requiredField") as string}
                    type="text"
                    value={config.description}
                    onChange={handleChange}
                    multiline
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseSaveDialog} color="default">{t("common.close")}</Button>
                <Button onClick={handleSave} color="primary">{t("common.save")}</Button>
            </DialogActions>
        </Dialog>

        </>
        
    );
};

export default PrometheusCustomDialog;
