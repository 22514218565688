import React, { useEffect, useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import SelectorField from "../../../components/selector-field";
import useIoC from "../../../contexts/ioc.context";
import { ClientsGateway } from "../../../gateways/clients.gateway";
import { IClient } from "../../../gateways/clients.interfaces";
import { CompanyGateway } from "../../../gateways/company.gateway";
import ICompany from "../../../gateways/company.interface";
import { ComplexGateway } from "../../../gateways/complex.gateway";
import { IComplex } from "../../../gateways/complex.interfaces";
import { EstablishmentGateway } from "../../../gateways/establishment.gateway";
import { IEstablishment } from "../../../gateways/establishment.interface";
import { FuelSupplyGateway } from "../../../gateways/fuel-supply.gateway";
import { IFuelSupply } from "../../../gateways/fuel-supply.interface";

const BotMissingDatesSuppliesForm: FC<{
  onChangeFuelSupply: (
    fuelSupplyId: number,
    parsedFuelSupplyReference: string
  ) => void;
}> = ({ onChangeFuelSupply }) => {
  const { t } = useTranslation();
  const [clients, setClients] = useState<IClient[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [complexes, setComplexes] = useState<IComplex[]>([]);
  const [establishments, setEstablishments] = useState<IEstablishment[]>([]);
  const [fuelSupply, setFuelSupply] = useState<IFuelSupply[]>([]);

  const [item, setItem] = useState<{
    clientId: number;
    companyId: number;
    establishmentId: number;
    fuelSupplyId: number;
    complexId: number;
  }>({
    clientId: 0,
    companyId: 0,
    establishmentId: 0,
    fuelSupplyId: 0,
    complexId: 0,
  });

  const clientsGateway = useIoC(ClientsGateway);
  const companiesGateway = useIoC(CompanyGateway);
  const complexesGateway = useIoC(ComplexGateway);
  const establishmentsGateway =
    useIoC(EstablishmentGateway);
  const fuelSupplyGateway = useIoC(FuelSupplyGateway);

  useEffect(() => {
    (async () => {
      const establishments = await establishmentsGateway.findByClientId({
        clientId: item.clientId
      });
      setEstablishments(establishments);
      const complexes = await complexesGateway.findByClientId({
        clientId: item.clientId
      });
      setComplexes(complexes);
      const companies = await companiesGateway.findAll({
        clientId: item.clientId,
      });
      setCompanies(companies);
      setItem({ ...item, companyId: 0 });
      setItem({ ...item, establishmentId: 0 });
      setItem({ ...item, complexId: 0 });
    })();
  }, [item.clientId]);

  useEffect(() => {
    (async () => {
      const establishments = await establishmentsGateway.findAll({
        companyId: item.companyId,
      });
      setEstablishments(establishments);
      const complexes = await complexesGateway.findAll({
        companyId: item.companyId,
      });
      setComplexes(complexes);
      const currentEstablishment = establishments.find(
        (est) => est.id === item.establishmentId
      );
      const currentComplex = complexes.find(
        (cplx) => cplx.id === item.complexId
      );
      if (item.companyId !== currentEstablishment?.companyId) {
        setItem({ ...item, establishmentId: 0 });
      }
      if (item.companyId !== currentComplex?.companyId) {
        setItem({ ...item, complexId: 0 });
      }
    })();
  }, [item.companyId]);

  useEffect(() => {
    (async () => {
      if (item.establishmentId) {
        const fuelSupplies = await fuelSupplyGateway.findAll({
          establishmentId: item.establishmentId.toString(),
        });
        setFuelSupply(fuelSupplies);
      } else if (item.complexId) {
        const fuelSupplies = await fuelSupplyGateway.findAll({
          complexId: item.complexId.toString(),
        });
        setFuelSupply(fuelSupplies);
      }
    })();
  }, [item.establishmentId, item.complexId]);

  useEffect(() => {
    (async () => {
      const clients = await clientsGateway.findAll({
        isMarketing: false,
        showDiscarded: false,
      });
      setClients(clients);
    })();
  }, []);

  const handleChange = (name: string, value: any) => {
    setItem({ ...item, [name + "Id"]: value });
    if (name === "fuelSupply") {
      const reference = fuelSupply.find((item) => value === item.id)?.reference;
      onChangeFuelSupply(value, reference ?? "error");
    }
  };
  return (
    <div>
      <SelectorField
        emptyValue={false}
        fullWidth={true}
        name={"client"}
        inputLabel={t("botMissingDate.clients")}
        onChange={handleChange}
        required={true}
        values={clients.map((item) => {
          return { id: item.id, value: item.fullname };
        })}
        value={item.clientId}
      />
      {item.clientId !== 0 && (
        <div>
          <SelectorField
            emptyValue={false}
            fullWidth={true}
            name={"company"}
            inputLabel={t("botMissingDate.companies")}
            onChange={handleChange}
            required={true}
            values={companies.map((item) => {
              return { id: item.id, value: item.name };
            })}
            value={item.companyId}
          />
          <SelectorField
            emptyValue={false}
            fullWidth={true}
            name={"complex"}
            inputLabel={t("botMissingDate.complexes")}
            onChange={handleChange}
            required={true}
            values={complexes.map((item) => {
              return { id: item.id, value: item.name };
            })}
            value={item.complexId}
          />
          <SelectorField
            emptyValue={false}
            fullWidth={true}
            name={"establishment"}
            inputLabel={t("botMissingDate.establishments")}
            onChange={handleChange}
            required={true}
            values={establishments.map((item) => {
              return { id: item.id, value: item.name };
            })}
            value={item.establishmentId}
          />
        </div>
      )}
      {(item.establishmentId !== 0 || item.complexId !== 0) && (
        <SelectorField
          emptyValue={false}
          fullWidth={true}
          name={"fuelSupply"}
          inputLabel={t("botMissingDate.fuelSupplies")}
          onChange={handleChange}
          required={true}
          values={fuelSupply.map((item) => {
            return { id: item.id, value: item.reference };
          })}
          value={item.fuelSupplyId}
        />
      )}
    </div>
  );
};

export default BotMissingDatesSuppliesForm;
