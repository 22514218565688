import React, { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, TableCell, TableSortLabel } from "@material-ui/core";
import { IFSTableColumn } from './interfaces';
import { StateContext, ReducerContext } from "./context";

interface IFSTableHeadCellProps {
    column: IFSTableColumn;
}

const useHeadStyle = makeStyles((theme) => ({
    cell: {
        display: 'inline-flex',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
    },
    cellClickable: {
        cursor: 'pointer'
    }
}));

const FSTableHeadCell: FC<IFSTableHeadCellProps> = ({ column }) => {
    const { t } = useTranslation();
    const classes = useHeadStyle();
    const state = useContext(StateContext);
    const dispatch = useContext(ReducerContext);

    const sorting = useMemo(() => 'name' in column && state.orderBy !== null && state.orderBy === column.name, [state.orderBy]);
    const sortable = useMemo(() => 'name' in column && 'sortable' in column && column.sortable, [column]);


    const handleSort = () => {
        if (!column.sortable || !!!column.name) {
            return;
        }
        if (sorting) {
            dispatch({ type: 'setOrderDirection', payload: state.orderDirection === 'asc' ? 'desc' : 'asc' });
        } else {
            dispatch({ type: 'setOrderBy', payload: column.name });
        }
    }

    return (
        <TableCell>
            <span className={classes.cell}>
                {
                    sortable
                        ? <TableSortLabel
                            active={sorting}
                            direction={state.orderDirection}
                            onClick={handleSort}
                        >
                            {t(column.label)}
                        </TableSortLabel>
                        : t(column.label)
                }
            </span>
        </TableCell>
    )
}

export default FSTableHeadCell