import React, { useEffect, useState } from "react";
import { FC, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { Route, useRouteMatch } from "react-router-dom";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import useIoC from "../../contexts/ioc.context";
import { WaterSupplyGateway } from "../../gateways/water-supply.gateway";
import { IWaterSupplyInterface } from "../../gateways/water-supply.interfaces";
import {
  StateType,
  WaterSupplyContext,
  WaterSupplyReducer,
} from "./water-supply-context";
import WaterSupplyDetail from "./water-supply-detail";
import { BlacklistGateway } from "../../gateways/blacklist.gateway";

const initialState: StateType = { waterSupply: null };
export const WaterSupplyWrapper: FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ waterSupplyId: string }>();
  const [waterSupplyId, setWaterSupplyId] = useState<number | null>(null);
  const waterSupplyGateway = useIoC(WaterSupplyGateway);
  const blacklistGateway = useIoC(BlacklistGateway);
  const [, setLoading] = useState(true);
  const { update: updateBreadCrumbName } = useBreadCrumbName();
  const [waterSupplyState, waterSupplyStateReducer] = useReducer(
    WaterSupplyReducer,
    initialState
  );

  useEffect(() => {
    setWaterSupplyId(parseInt(match.params.waterSupplyId, 10));
  }, [match.params.waterSupplyId]);

  useEffect(() => {
    (async () => {
      if (waterSupplyId === null) {
        return;
      }
      setLoading(true);
      const temp = await waterSupplyGateway.findById(waterSupplyId);
      const result = await checkIfBlackListed(temp);
      const breadCrumbName = result === false ? temp.referencia: temp.referencia + " (Cancelado)";
      updateBreadCrumbName("waterSupply", breadCrumbName);
      waterSupplyStateReducer({ type: "update-waterSupply", payload: {...temp, blacklisted: result } });
      setLoading(false);
    })();
  }, [waterSupplyId]);

  async function checkIfBlackListed(data: IWaterSupplyInterface): Promise<boolean>{
    const r = data.referencia;
    const referencia = r.length === 20 ? [r, r+"0F"]: r.length === 22 ? [r, r.slice(0,20)]: [r];
    const result = await blacklistGateway.findCups(referencia);
    if(result.length > 0){
      return r.length === 22 ? result[0] === r || result[0] === r.slice(0,20):
             r.length === 20 ? result[0] === r || result[0] === r+"0F":
             result[0] === r;
    }
    return false;
  }

  if (waterSupplyState.waterSupply === null) {
    return <div>{t("common.loading")}</div>;
  }

  return (
    <WaterSupplyContext.Provider
      value={[waterSupplyState, waterSupplyStateReducer]}
    >
      <Route path={`${match.path}`} exact>
        <WaterSupplyDetail />
      </Route>
    </WaterSupplyContext.Provider>
  );
};
