import React from "react";
import { Switch, Route, useRouteMatch } from "react-router";
import ComplexList from "./complex-list";
import { ComplexWrapper } from "./complex-wrapper";

const ComplexRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <ComplexList />
      </Route>
      <Route path={`${path}/:complexId`} component={ComplexWrapper} />
    </Switch>
  );
};
export default ComplexRouter;
