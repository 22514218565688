export interface IRegisterFuelBill {
    id: number;
    issueDate: Date;
    numberBill: string;
    totalBillImport: number;
    startingPeriodDate: Date | null;
    endingPeriodDate: Date | null;
    gnM3: number;
    gnPCS: number;
    gnKWHPCS: number;
    gnKWHPCSOnly: number;
    gnTF: number;
    gnTV: number;
    gnOtros: number;
    importEquipmentRental: number;
    hydrocarbonsTax: number;
    gnTotal: number;
    monthlyPayment: number;
    tankDate: Date | null;
    amount: number;
    unitaryPrice: number;
    PCI: number;
    fuelContractId: number;
    fuelSupplyId: number;
}

export interface IRegisterFuelBillUpdate {
    issueDate: Date;
    numberBill: string;
    totalBillImport: number;
    startingPeriodDate: Date | null;
    endingPeriodDate: Date | null;
    gnM3: number;
    gnPCS: number;
    gnKWHPCS: number;
    gnKWHPCSOnly: number;
    gnTF: number;
    gnTV: number;
    gnOtros: number;
    importEquipmentRental: number;
    hydrocarbonsTax: number;
    gnTotal: number;
    monthlyPayment: number;
    tankDate: Date | null;
    amount: number;
    unitaryPrice: number;
    PCI: number;
    fuelContractId: number;
    fuelSupplyId: number;
}

export const initialIRegisterFuelBillUpdate = {
    fuelContractId: 0,
    fuelSupplyId: 0,
    issueDate: new Date(),
    numberBill: '',
    totalBillImport: 0,
    PCI: 0,
    amount: 0,
    endingPeriodDate: null,
    gnKWHPCS: 0,
    gnKWHPCSOnly: 0,
    gnM3: 0,
    gnOtros: 0,
    gnPCS: 0,
    gnTF: 0,
    gnTV: 0,
    gnTotal: 0,
    hydrocarbonsTax: 0,
    importEquipmentRental: 0,
    monthlyPayment: 0,
    startingPeriodDate: null,
    tankDate: null,
    unitaryPrice: 0
}

export interface IRegisterFuelBillCreate {
    issueDate: Date;
    numberBill: string;
    totalBillImport: number;
    startingPeriodDate: Date | null;
    endingPeriodDate: Date | null;
    gnM3: number;
    gnPCS: number;
    gnKWHPCS: number;
    gnKWHPCSOnly: number;
    gnTF: number;
    gnTV: number;
    gnOtros: number;
    importEquipmentRental: number;
    hydrocarbonsTax: number;
    gnTotal: number;
    monthlyPayment: number;
    tankDate: Date | null;
    amount: number;
    unitaryPrice: number;
    PCI: number;
    fuelContractId: number;
    fuelSupplyId: number;
}

export const initialIRegisterFuelBillCreate: IRegisterFuelBillCreate = {
    fuelContractId: 0,
    fuelSupplyId: 0,
    issueDate: new Date(),
    numberBill: '',
    totalBillImport: 0,
    PCI: 0,
    amount: 0,
    endingPeriodDate: null,
    gnKWHPCS: 0,
    gnKWHPCSOnly: 0,
    gnM3: 0,
    gnOtros: 0,
    gnPCS: 0,
    gnTF: 0,
    gnTV: 0,
    gnTotal: 0,
    hydrocarbonsTax: 0,
    importEquipmentRental: 0,
    monthlyPayment: 0,
    startingPeriodDate: null,
    tankDate: null,
    unitaryPrice: 0
};