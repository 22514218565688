import React, { useState, useMemo, useEffect } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { makeStyles, List, Divider, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import CenteredCircularProgress from "../../components/centered-circular-progress";
import AlertBox from "../../components/alert-box";
import AddIcon from "@material-ui/icons/Add";
import ICompany from "../../gateways/company.interface";
import { CompanyGateway } from "../../gateways/company.gateway";
import ContactsIcon from "@material-ui/icons/Contacts";
import CompanyItem from "./company-item";
import CompanyAddDialog from "./company-add-dialog";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import ClientsDrawer from "../../components/clients-drawer";
import PeopleIcon from "@material-ui/icons/People";

interface ICompanyRouteParams {
  [x: string]: string | undefined;
  clientId: string;
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  itemAvatarIcon: {
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
}));

const CompanyList = () => {
  const classes = useStyles();
  const { clientId } = useParams<ICompanyRouteParams>();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState<ICompany[]>([]);
  const [showDiscardedCompanies, setShowDiscardedCompanies] = useState<boolean>(false);
  const [filedButtonTitle, setFiledButtonTitle] = useState<string>(`${t("companyList.buttonNonDiscardedTitle")}`);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const isDataEmpty = useMemo(
    () => !isDataLoading && data.length === 0,
    [isDataLoading, data]
  );
  const companyGateway = useIoC(CompanyGateway);

  const handleClickFabButton = () => {
    setOpenAddDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenAddDialog(false);
  };

  const handleClickItem = (item: ICompany) => {
    history.push(`${url}/${item.id}`);
  };

  const handleItemCreated = (item: ICompany) => {
    setData([item, ...data]);
  };

  useEffect(() => {
    (async () => {
      if (!clientId) return;
      setIsDataLoading(true);

      const data = await companyGateway.findAll({
        clientId: parseInt(clientId, 10),
        showDiscarded: showDiscardedCompanies
      });
      
      setData(data);
      setIsDataLoading(false);
    })();
  }, [companyGateway]);

  const Content = () => {
    if (isDataLoading) {
      return <CenteredCircularProgress />;
    }

    if (isDataEmpty) {
      return <AlertBox variant="info">{t("common.noItems")}</AlertBox>;
    }
  
    return (
      <List>
        {data.map((item, index) => {
          return (
            <div key={item.id}>
              <CompanyItem item={item} showDiscarded={showDiscardedCompanies} onClickItem={handleClickItem}/>
              {index < data.length - 1 ? (
                <Divider variant="inset" component="li" />
              ) : null}
            </div>
          );
        })}
      </List>
    );
  };

  const handleCompanyList = async () => {
    setIsDataLoading(true);
    const bool = showDiscardedCompanies === false ? true: false;

    const data = await companyGateway.findAll({
      clientId: parseInt(clientId, 10),
      showDiscarded: bool
    });

    setData(data);

    if(showDiscardedCompanies === true) setFiledButtonTitle(`${t("companyList.buttonNonDiscardedTitle")}`)
    else setFiledButtonTitle(`${t("companyList.buttonDiscardedTitle")}`);

    setShowDiscardedCompanies(!showDiscardedCompanies);
    setIsDataLoading(false);
  }

  const handleOnLoading = (value: boolean) => {
    setIsDataLoading(value);
  }

  return (
    <Box>
      <ScreenGlobalStructure
        buttonIcon={<AddIcon />}
        headerIcon={ContactsIcon}
        onButtonClick={handleClickFabButton}
        title={t("companyList.title")}
        matchUrl={url}
        clientId={clientId !== undefined ? clientId : ""}
        loading={isDataLoading}
        drawer={
          <ClientsDrawer
            matchUrl={url}
            lateralMenu={2}
            icon={
              <PeopleIcon fontSize="large" className={classes.itemAvatarIcon} />
            }
            goBack={true}
          />
        }
        filedButtonList={handleCompanyList}
        filedButtonListTitle={filedButtonTitle}
      >
        <Content />
      </ScreenGlobalStructure>
      {clientId && (
        <CompanyAddDialog
          open={openAddDialog}
          onItemCreated={handleItemCreated}
          onClose={handleCloseDialog}
          clientId={parseInt(clientId, 10)}
          onLoading={handleOnLoading}
        />
      )}
    </Box>
  );
};

export default CompanyList;
