import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import SelectorField from '../../components/selector-field';
import { makeStyles, Theme, createStyles, FormControlLabel, Checkbox, Typography, Grid } from "@material-ui/core";
import { IUpdateElectricSupply } from "../../gateways/electric-supply.interface";
import { IAccessType } from "../../gateways/access-type.interfaces";
import { IProvidersDisplay } from "../../gateways/providers.interface";
import IRegisterElectricBillUpdate, { IRegisterElectricBill } from "../../gateways/register-electric-bill.interface";
import { IElectricContract, initialElectricContract } from "../../gateways/electric-contract.interfaces";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MaterialTable from "material-table";
import { IElectricBillPowerMaterialTableRows, IElectricBillEnergyMaterialTableRows } from "../../utils/material-table.interface";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subtitle: {
            paddingTop: theme.spacing(3),

        },
        gridItem: {
            borderSpacing: 2,
            padding: theme.spacing(1)
        }
    })
);

interface IRegisterElectricBillProps {
    item: IRegisterElectricBillUpdate;
    onChange: (name: string, value: any) => void;
    validateRequired: (value: any) => boolean;
    onIssueDateSelector: (value: any) => void;
    onStartingPeriodDateSelector: (value: any) => void;
    onEndingPeriodDateSelector: (value: any) => void;
    onBonificationIncludedChange: (value: any) => void;
    onEstimatedChange: () => void;
    onCompensatedChange: () => void;
    onMultiplePeriodsBillChange: () => void;
    onChangeDoNothing: () => void;
    onChangePeriodRegisterElectricBillPower: (updatingArray: IElectricBillPowerMaterialTableRows, index: number) => void
    electricContractInfo: (electricContract: IElectricContract) => string
    currency: string;
    energyState: IElectricBillEnergyMaterialTableRows[];
    electricContract: IElectricContract;
    powerState: IElectricBillPowerMaterialTableRows[];
    setPowerState: React.Dispatch<React.SetStateAction<IElectricBillPowerMaterialTableRows[]>>;
    setEnergyState: React.Dispatch<React.SetStateAction<IElectricBillEnergyMaterialTableRows[]>>;
    onChangePeriodRegisterElectricBillEnergy: (updatingArray: IElectricBillEnergyMaterialTableRows, index: number) => void;
}

const RegisterElectricBillFormDetail: FC<IRegisterElectricBillProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div>
            <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={12} className={classes.gridItem}>
                    <FormTextField
                        name='numberBill'
                        label={t('common.numberBill')}
                        type='string'
                        validator={props.validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={props.item.numberBill}
                        onChange={props.onChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={4} className={classes.gridItem}>
                    <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="issueDateSelector"
                        label={t('common.issueDate')}
                        value={props.item.issueDate}
                        onChange={props.onIssueDateSelector}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        required
                    />
                </Grid>
                <Grid item xs={4} className={classes.gridItem}>
                    <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="startingPeriodDateSelector"
                        label={t('common.startingPeriodDate')}
                        value={props.item.startingPeriodDate}
                        onChange={props.onStartingPeriodDateSelector}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        required
                    />
                </Grid>
                <Grid item xs={4} className={classes.gridItem}>
                    <KeyboardDatePicker
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="endingPeriodDateSelector"
                        label={t('common.endingPeriodDate')}
                        value={props.item.endingPeriodDate}
                        onChange={props.onEndingPeriodDateSelector}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        required
                    />
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid className={classes.gridItem}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={"primary"}
                                checked={props.item.bonificationIncluded}
                                onChange={props.onBonificationIncludedChange}
                            />
                        }
                        label={t('common.bonificationIncluded')}
                    />
                </Grid>
                {
                    props.item.bonificationIncluded &&
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item xs={6} className={classes.gridItem}>
                            <FormTextField
                                name='bonusBillNumber'
                                label={t('common.bonusBillNumber')}
                                type='number'
                                validator={props.validateRequired}
                                errorText={t('messages.requiredField') as string}
                                value={props.item.bonusBillNumber}
                                onChange={props.onChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.gridItem}>
                            <FormTextField
                                name='bonusImport'
                                label={t('common.bonusImport')}
                                type='number'
                                validator={props.validateRequired}
                                errorText={t('messages.requiredField') as string}
                                value={props.item.bonusImport}
                                onChange={props.onChange}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                }
                <Grid className={classes.gridItem}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={"primary"}
                                checked={props.item.compensateInjectEnergy}
                                onChange={props.onCompensatedChange}
                            />
                        }
                        label={t('common.compensated')}
                    />
                </Grid>
                {
                    props.item.compensateInjectEnergy &&
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item xs={6} className={classes.gridItem}>
                            <FormTextField
                                name='unitCompensatePrice'
                                label={t('common.unitCompensatePrice')}
                                type='number'
                                validator={props.validateRequired}
                                errorText={t('messages.requiredField') as string}
                                value={props.item.unitCompensatePrice ? props.item.unitCompensatePrice : 0}
                                onChange={props.onChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.gridItem}>
                            <FormTextField
                                name='amountToCompensate'
                                label={t('common.amountToCompensate') as string}
                                type='number'
                                validator={props.validateRequired}
                                errorText={t('messages.requiredField') as string}
                                value={props.item.amountToCompensate ? props.item.amountToCompensate : 0}
                                onChange={props.onChange}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                }
                <Grid className={classes.gridItem}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={"primary"}
                                checked={props.item.estimated}
                                onChange={props.onEstimatedChange}
                            />
                        }
                        label={t('common.estimated')}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid item xs={6} className={classes.gridItem}>
                    <FormTextField
                        name='powerExcessImport'
                        label={t('common.powerExcessImport')}
                        type='number'
                        validator={props.validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={props.item.powerExcessImport}
                        onChange={props.onChange}
                        fullWidth
                        required
                        adornmentLabel={props.currency}
                    />
                </Grid>
                <Grid item xs={6} className={classes.gridItem}>
                    <FormTextField
                        name='importEquipmentRental'
                        label={t('common.importEquipmentRental') as string}
                        type='number'
                        validator={props.validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={props.item.importEquipmentRental}
                        onChange={props.onChange}
                        fullWidth
                        required
                        adornmentLabel={props.currency}
                    />
                </Grid>
                <Grid item xs={6} className={classes.gridItem}>
                    <FormTextField
                        name='electricTax'
                        label={t('common.electricTax')}
                        type='number'
                        validator={props.validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={props.item.electricTax}
                        onChange={props.onChange}
                        fullWidth
                        required
                        adornmentLabel={props.currency}
                    />
                </Grid>
                <Grid item xs={6} className={classes.gridItem}>
                    <FormTextField
                        name='electricContractId'
                        label={t('common.electricContractId') as string}
                        type='number'
                        validator={props.validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={props.item.electricContractId}
                        onChange={props.onChangeDoNothing}
                        fullWidth
                        required
                        disabled={true}
                        adornmentLabel={props.electricContractInfo(props.electricContract)}
                    />
                </Grid>
            </Grid>
            <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
            >
                <Grid className={classes.gridItem}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={"primary"}
                                checked={props.item.multiplePeriodsBill}
                                onChange={props.onMultiplePeriodsBillChange}
                            />
                        }
                        label={t('common.multiplePeriodsBill')}
                    />
                </Grid>
                {
                    props.item.multiplePeriodsBill &&
                    <Grid
                        container
                        justify="flex-start"
                        alignItems="flex-start"
                    >
                        <Grid item xs={6} className={classes.gridItem}>
                            <FormTextField
                                name='firstRangeOfDates'
                                label={t('common.firstRangeOfDates') as string}
                                type='string'
                                validator={props.validateRequired}
                                errorText={t('messages.requiredField') as string}
                                value={props.item.firstRangeOfDates ? props.item.firstRangeOfDates : ''}
                                onChange={props.onChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.gridItem}>
                            <FormTextField
                                name='secondRangeOfDates'
                                label={t('common.secondRangeOfDates') as string}
                                type='string'
                                validator={props.validateRequired}
                                errorText={t('messages.requiredField') as string}
                                value={props.item.secondRangeOfDates ? props.item.secondRangeOfDates : ''}
                                onChange={props.onChange}
                                fullWidth
                                required
                            />
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} className={classes.gridItem}>
                    <MaterialTable
                        options={{
                            search: false,
                            paging: false,
                        }}
                        localization={{
                            header: {
                                actions: t('common.actions') as string
                            }
                        }}
                        title={t("registerElectricBillUpsertDialog.powerMaterialTableTitle") as string}
                        columns={props.item.multiplePeriodsBill ?
                            [
                                { title: t("common.periodNumber") as string, field: "periodNumber", type: "numeric", editable: "never" },
                                { title: t("common.consumedPower") as string + " " + props.item.firstRangeOfDates, field: "consumedPower", type: "numeric" },
                                { title: t("common.consumedPower") as string + " " + props.item.secondRangeOfDates, field: "consumedPowerSecondPeriod", type: "numeric" },
                                { title: t("common.powerRate") as string + " " + props.item.firstRangeOfDates, field: "powerRate", type: "numeric" },
                                { title: t("common.powerRate") as string + " " + props.item.secondRangeOfDates, field: "secondaryPowerRate", type: "numeric" },
                                { title: t("common.discountOverPowerRate") as string, field: "discount", type: "numeric" },
                                { title: t("common.hiredPower") as string, field: "hiredPower", type: "numeric" }
                            ] :
                            [
                                { title: t("common.periodNumber") as string, field: "periodNumber", type: "numeric", editable: "never" },
                                { title: t("common.consumedPower") as string, field: "consumedPower", type: "numeric" },
                                { title: t("common.powerRate") as string, field: "powerRate", type: "numeric" },
                                { title: t("common.discountOverPowerRate") as string, field: "discount", type: "numeric" },
                                { title: t("common.hiredPower") as string, field: "hiredPower", type: "numeric" }
                            ]
                        }
                        data={props.powerState}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise<void>(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        if (oldData) {
                                            let updateDataAid: IElectricBillPowerMaterialTableRows[] = [...props.powerState];
                                            updateDataAid[updateDataAid.indexOf(oldData)] = newData;
                                            props.setPowerState(updateDataAid);
                                            props.onChangePeriodRegisterElectricBillPower(newData, newData.periodNumber - 1);
                                        }
                                    }, 600);

                                }),
                        }}
                    />
                </Grid>

                <Grid item xs={12} className={classes.gridItem}>
                    <FormTextField
                        name='powerRateImport'
                        label={t('common.powerRateImport')}
                        type='number'
                        validator={props.validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={props.item.powerRateImport}
                        onChange={props.onChange}
                        fullWidth
                        required
                        adornmentLabel={props.currency}
                    />
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                    <MaterialTable
                        options={{
                            search: false,
                            paging: false,
                        }}
                        localization={{
                            header: {
                                actions: t('common.actions') as string
                            }
                        }}
                        title={t("registerElectricBillUpsertDialog.energyMaterialTableTitle") as string}
                        columns={
                            props.item.multiplePeriodsBill ?
                                [
                                    { title: t("common.periodNumber") as string, field: "periodNumber", type: "numeric", editable: "never" },
                                    { title: t("common.consumedEnergy") as string + " " + props.item.firstRangeOfDates, field: "consumedEnergy", type: "numeric" },
                                    { title: t("common.consumedEnergy") as string + " " + props.item.secondRangeOfDates, field: "secondConsumedEnergy", type: "numeric" },
                                    { title: t("common.electricityCost") as string + " " + props.item.firstRangeOfDates, field: "electricityCost", type: "numeric" },
                                    { title: t("common.electricityCost") as string + " " + props.item.secondRangeOfDates, field: "secondElectricityCost", type: "numeric" },
                                    { title: t("common.reactiveEnergy") as string, field: "reactiveEnergy", type: "numeric" }
                                ]
                                :
                                [
                                    { title: t("common.periodNumber") as string, field: "periodNumber", type: "numeric", editable: "never" },
                                    { title: t("common.consumedEnergy") as string, field: "consumedEnergy", type: "numeric" },
                                    { title: t("common.electricityCost") as string, field: "electricityCost", type: "numeric" },
                                    { title: t("common.reactiveEnergy") as string, field: "reactiveEnergy", type: "numeric" }
                                ]
                        }
                        data={props.energyState}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise<void>(resolve => {
                                    setTimeout(() => {
                                        resolve();
                                        if (oldData) {
                                            let updateDataAid: IElectricBillEnergyMaterialTableRows[] = [...props.energyState];
                                            updateDataAid[updateDataAid.indexOf(oldData)] = newData;
                                            props.setEnergyState(updateDataAid);
                                            props.onChangePeriodRegisterElectricBillEnergy(newData, newData.periodNumber - 1);
                                        }
                                    }, 600);

                                }),
                        }}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
            >

                <Grid item xs={6} className={classes.gridItem}>
                    <FormTextField
                        name='electricRateBillImport'
                        label={t('common.electricRateBillImport') as string}
                        type='number'
                        validator={props.validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={props.item.electricRateBillImport}
                        onChange={props.onChange}
                        fullWidth
                        required
                        adornmentLabel={props.currency}
                    />
                </Grid>

                <Grid item xs={6} className={classes.gridItem}>
                    <FormTextField
                        name='reactiveImport'
                        label={t('common.reactiveImport') as string}
                        type='number'
                        validator={props.validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={props.item.reactiveImport}
                        onChange={props.onChange}
                        fullWidth
                        required
                        adornmentLabel={props.currency}
                    />
                </Grid>

                <Grid item xs={6} className={classes.gridItem}>
                    <FormTextField
                        name='totalBillImport'
                        label={t('common.totalBillImport') as string}
                        type='number'
                        validator={props.validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={props.item.totalBillImport}
                        onChange={props.onChange}
                        fullWidth
                        required
                        adornmentLabel={props.currency}
                    />
                </Grid>

                <Grid item xs={6} className={classes.gridItem}>
                    <FormTextField
                        name='totalBillImportIVA'
                        label={t('common.totalBillImportIVA') as string}
                        type='number'
                        validator={props.validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={props.item.totalBillImportIVA}
                        onChange={props.onChange}
                        fullWidth
                        required
                        adornmentLabel={props.currency}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default RegisterElectricBillFormDetail;