import React, { FC, useEffect, useState, useReducer } from "react";
import { useRouteMatch, Route, RouteComponentProps } from "react-router-dom";
import useIoC from "../../contexts/ioc.context";
import { ClientsGateway } from "../../gateways/clients.gateway";
import CompanyRouter from "../companies/company-router";
import ComplexRouter from "../complex/complex-router";
import EstablishmentRouter from "../establishment/establishment-router";
import ContactList, { ContactEntityType } from "../contacts/contact-list";
import { ClientContext, StateType, ActionType, ClientReducer } from './client-context';
import ClientDetail from "./client-detail";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import { useTranslation } from "react-i18next";

const ContactsRouter: FC<RouteComponentProps<{ clientId: string }>> = ({ match }) => {

    return (
        <ContactList
            entity={ContactEntityType.Client}
            entityId={match.params.clientId} />
    );
}


const initialState: StateType = { client: null };

export const ClientWrapper: FC = () => {
    const { t } = useTranslation();
    const match = useRouteMatch<{ clientId: string }>();
    const [clientId, setClientId] = useState<number | null>(null);
    const clientGateway = useIoC(ClientsGateway);
    const [loading, setLoading] = useState(true);
    const { update: updateBreadCrumbName } = useBreadCrumbName();

    const [clientState, clientStateReducer] = useReducer(ClientReducer, initialState);

    useEffect(() => {
        setClientId(parseInt(match.params.clientId, 10));
    }, [match.params.clientId]);

    useEffect(() => {
        (async () => {
            if (clientId === null) {
                return;
            }
            setLoading(true);
            const temp = await clientGateway.findById(clientId);
            const breadCrumbName = temp.cancelledDate === null ? temp.fullname: temp.fullname + " (Cancelado)";
            updateBreadCrumbName('clients', breadCrumbName);
            updateBreadCrumbName('clientsDrawer', temp.fullname);
            clientStateReducer({ type: 'update-client', payload: temp});
            setLoading(false);
        })();
    }, [clientId]);

    if (clientState.client === null) {
        return (<div>{t('common.loading')}</div>);
    }

    return (<ClientContext.Provider value={[clientState, clientStateReducer]}>
        <Route path={`${match.path}`} exact component={ClientDetail} />
        <Route
            path={`${match.path}/contacts`}
            render={ContactsRouter}
        />
        <Route path={`${match.path}/companies`}>
            <CompanyRouter />
        </Route>
        <Route path={`${match.path}/complex`}>
            <ComplexRouter />
        </Route>
        <Route path={`${match.path}/establishments`}>
            <EstablishmentRouter />
        </Route>
    </ClientContext.Provider>);
}