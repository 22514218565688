import React, { FC, useState, useEffect } from "react";
import GenerateSignatureFields from "./generate-signature-fields";
import { Grid, Button, makeStyles } from "@material-ui/core";
import { initialUserSignatureData, IUserSignatureData } from "../../gateways/user-signature-data.interfaces";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigator } from "../../contexts/navigator.context";
import RateReviewIcon from '@material-ui/icons/RateReview';

const useStyles = makeStyles((theme) => ({
    marginGrid: {
        marginTop: theme.spacing(1)
    },
    clipboardButton: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2)
    },
    centerHTMLPreview: {
        display: 'table',
        margin: '0 auto',
    },
    marginItem: {
        marginTop: theme.spacing(6)
    }
}));

const GenerateSignaturePreview: FC = () => {

    const classes = useStyles();
    const snackbar = useSnackbar();
    const { t } = useTranslation();
    const [, navigatorDispatch] = useNavigator();

    const [item, setItem] = useState<IUserSignatureData>(initialUserSignatureData);
    const image = 
    `<td style="width: 115px">
        <img
            src="${item.imageUrl !== "" ? item.imageUrl : "https://enovam.com/firmas/_perfiles/DSC00243.jpg"}"
            style="width: 90px"
        />
    </td>`;

    const enovamBanner = 
        `<a href="https://www.enovam.com" style="display: block">
            <img
            src="https://enovam.com/firmas/enovam-logo-color.jpg"
            style="width: 200px"
            />
        </a>`;

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('common.signatureGenerator'),
                icon: RateReviewIcon
            }
        });
    }, []);

    const handleChange = async (name: string, value: string) => {
        setItem({...item, [name]: value});
    }

    const rawHTML = (emailType: 'completa'|'reserva'|'respuesta') => {
        return(
            `<html>
                <body>
                <table style="width: 100%; max-width: 600px">
                    <tbody>
                    <tr>
                        ${emailType === 'completa' ? image : ""}
                        <td>
                        <p
                            style="
                            font-family: 'helvetica';
                            font-size: 1em;
                            color: black;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            "
                        >
                            <b>${item.fullname !== "" ? item.fullname : "Carlos Vidal Mut"}</b><br />
                            ${item.role !== "" ? item.role : "CoFounder — Chief Strategy Officer CSO"}<br />
                            <a href="tel:${item.tlf !== "" ? item.tlf : "+34 654 06 93 78"}" style="color: black">${item.tlf !== "" ? item.tlf : "+34 654 06 93 78"}</a>
                            <br />
                            <a href="mailto:${item.email !== "" ? item.email : "carlosvidal@enovam.com"}" style="color: black">${item.email !== "" ? item.email : "carlosvidal@enovam.com"}</a>
                            <br />
                            <a href="https://www.enovam.com" style="color: black">enovam.com</a>
                        </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                ${emailType !== 'respuesta' ? enovamBanner : ""}
                <p
                    style="
                    width: 100%;
                    max-width: 600px;
                    font-family: 'helvetica';
                    font-size: 0.8em;
                    color: lightgray;
                    text-align: justify;
                    text-justify: inter-word;
                    "
                >
                AVISO LEGAL: Este mensaje y sus archivos adjuntos van dirigidos exclusivamente a su destinatario,
                pudiendo contener información confidencial sometida a secreto profesional. No está permitida su comunicación,
                reproducción o distribución sin la autorización expresa de ENOVAM PRO ENERGY EFFIENCY S.L..
                Si usted no es el destinatario final, por favor elimínelo e infórmenos por esta vía. <br/>
                PROTECCIÓN DE DATOS:  De conformidad con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril de 2016 (GDPR)
                y la Ley Orgánica 3/2018, de 5 de diciembre de Protección de Datos Personales y garantía de los derechos digitales
                (LOPDGDD), le  informamos que los  datos personales y dirección de correo electrónico  del interesado, serán tratados
                bajo  la  responsabilidad  de ENOVAM PRO ENERGY EFFIENCY S.L.  para el  mantenimiento de la  relación comercial y se
                conservarán mientras exista un interés mutuo para ello.  Los datos podrán ser cedidos a terceros para alcanzar el fin
                antes expuesto, previa comunicación al interesado y por obligación legal. Le informamos que puede ejercer los derechos
                de acceso, rectificación, portabilidad y supresión de sus datos y los de limitación y oposición a su tratamiento dirigiéndose
                a ENOVAM PRO ENERGY EFFIENCY S.L., B57726408, Calle Gremi de Sabaters, 21, planta 2, puerta B24, 07009, Polígono Son Castello,
                Indioteria, Palma, Islas Baleares,  o  enviando  un  mensaje  al  correo  electrónico  carlosvidal@enovam.com;
                marinaestrella@enovam.com.  Si considera que el tratamiento no se ajusta a la normativa vigente, podrá presentar una
                reclamación ante la autoridad de control en www.aepd.es.
                </p>
                </body>
            </html>`
        );
    }

    const returnHTMLPreview = (htmlToPreview: string) => {
        return(
            <>
                <div dangerouslySetInnerHTML={{__html:  htmlToPreview}} className={classes.centerHTMLPreview}/>
                <div className={classes.clipboardButton}>
                    <Button color="primary" variant="contained" onClick={() => {
                        navigator.clipboard.writeText(htmlToPreview);
                        snackbar.enqueueSnackbar(
                            t("common.copyToClipboardSucceded"),
                            { variant: "success" }
                        );
                    }}>Copy HTML to clipboard</Button>
                </div>
            </>
        );
    }

    return(
        <>
        <Grid container spacing={6}>
            <Grid item xs={6} className={classes.marginGrid}>
                <GenerateSignatureFields
                    item={item}
                    onChange={handleChange}
                />
            </Grid>
            <Grid item xs={6} className={classes.marginGrid}>
                {returnHTMLPreview(rawHTML('completa'))}
                <div className={classes.marginItem}>
                    {returnHTMLPreview(rawHTML('reserva'))}
                </div>
                <div className={classes.marginItem}>
                    {returnHTMLPreview(rawHTML('respuesta'))}
                </div>
            </Grid>
        </Grid>
        
        
        </>
    );
}

export default GenerateSignaturePreview;