import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TableHead, TableBody, TableCell, Table, TableRow, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PrettoSlider from '../../components/pretto-slider';
import moment from 'moment';
import { green } from '@material-ui/core/colors';
import { IClientMarketing, IClientMarketingTask } from '../../gateways/marketing.interfaces';

const useStyles = makeStyles(() => ({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {

    },
}));

interface IDetailActionDialogProps {
    open: boolean;
    client: IClientMarketing;
    action: IClientMarketingTask;
    onClose: () => void;
}

const DetailActionDialog: FC<IDetailActionDialogProps> = ({ open, onClose, client, action }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Detalle de la acción comercial</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('marketing.registerForm')}
                </DialogContentText>

                <FormControl fullWidth className={classes.formControl}>
                    <TextField
                        label="Tipo"
                        disabled
                        value={action.taskType.name}
                    />
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                    <TextField
                        label="Fecha prevista"
                        disabled
                        value={action.taskDate}
                    />
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                    <TextField
                        label="Fecha caduque"
                        disabled
                        value={action.expiryDate}
                    />
                </FormControl>

                <FormControl fullWidth>
                    <TextField
                        label="Cometario"
                        disabled
                        multiline
                        value={action.comment}
                    />
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                    <TextField
                        label="Teléfono"
                        disabled
                        value={action.phone}
                    />
                </FormControl>

                <FormControl fullWidth className={classes.formControl}>
                    <TextField
                        label="Dirección"
                        disabled
                        value={action.address}
                    />
                </FormControl>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('marketing.product')}</TableCell>
                            <TableCell>{t('marketing.probability')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            action.products.map((item, index) => (
                                <TableRow key={'product_' + index}>
                                    <TableCell>
                                        {item.product.name}
                                    </TableCell>
                                    <TableCell>
                                        <PrettoSlider
                                            valueLabelDisplay="auto"
                                            aria-label="probabilidad"
                                            disabled
                                            value={item.probability}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                {
                    action.completedResult
                        ? (<>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    label="Resultado"
                                    disabled
                                    value={action.completedResult.name}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    label="Fecha resolución"
                                    disabled
                                    value={action.completedDate ? moment(action.completedDate).format('DD/MM/YYYY HH:mm') : 'N/a'}
                                />
                            </FormControl>
                            <FormControl fullWidth className={classes.formControl}>
                                <TextField
                                    label="Observaciones de resolución"
                                    disabled
                                    value={action.completedObservations || ''}
                                />
                            </FormControl>
                        </>)
                        : (<>
                            <h4>La tarea esta pendiente</h4>
                        </>)
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="default">
                    {t('common.close')}
                </Button>
            </DialogActions>
        </Dialog >
    );
}

export default DetailActionDialog;