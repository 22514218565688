import React, { FC, useState, useEffect } from "react";
import { 
    Typography, Divider, makeStyles, Box, Grid, 
    TableBody, Table, TableHead, TableRow, TableCell,
    Tooltip, Fab,
} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { TreeItem, TreeView } from "@material-ui/lab";
import useIoC from "../../contexts/ioc.context";
import { useNavigator } from '../../contexts/navigator.context';
import { BotsGateway } from "../../gateways/bots.gateway";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router";
import PrometheusCustomDialog from "./prometheus-custom-dialog";
import { IPrometheusMetadata, IVariableData } from "../../gateways/prometheus.interface";

const useStyles = makeStyles((theme) => ({
    listItem: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    documentationText: {
        textAlign: "center",
    },
    tableSeparator: { 
        borderRight: "0.05rem solid #E0E0E0",
    },
    tableHeaderBackground: {
        backgroundColor: "#d5e27a",
    },
    tableBodyBackground: {
        backgroundColor: "#eff4cd"
    },
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const initialPrometheusMetadata: IPrometheusMetadata = {
    name: "",
    description: "",
    request_schema: null,
    error_types: null,
}

const PrometheusJobDetail: FC = () => {

    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const { t } = useTranslation();
    const match = useRouteMatch<{ id: string, name: string }>();

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [data, setData] = useState<IPrometheusMetadata>(initialPrometheusMetadata);
    const [disableCustomButton, setDisableCustomButton] = useState<boolean>(false);
    const [variablesData, setVariablesData] = useState<IVariableData[]>([]);

    let lastIdNode: number = 4;

    const botsGateway = useIoC(BotsGateway);

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: match.params.name,
                icon: InfoIcon
            }
        });
    }, []);

    useEffect(() => {
        (async () => {
            const jobsInfo = await botsGateway.getPrometheusMetadata(parseInt(match.params.id, 10));
            const specificJob = jobsInfo.find((elem: IPrometheusMetadata) => elem.name === match.params.name);
            setData(specificJob);

            if(!!!specificJob.request_schema || 
                (specificJob.request_schema.keys !== undefined && 
                    Object.keys(specificJob.request_schema.keys).length === 0)) {
                setDisableCustomButton(true);
            } else {
                const variables = convertObjectToArray(specificJob.request_schema.keys);
                const auxArr = [];
                for(let i = 0; i < variables.length; i++){
                    const name = variables[i][0];
                    let type: string;
                    if(variables[i][1].patterns !== undefined){
                        type = 'cookie';
                    } else {
                        type = variables[i][1].type;
                    }
                    const presence = !!!variables[i][1].flags ? "optional" : variables[i][1].flags.presence;
                    auxArr.push({name: name, type: type, presence: presence, fieldValue: ""});
                }
                setVariablesData(auxArr);
            }
        })();
    }, []);

    const setFieldsToDefault = () => {
        const defaultFields = variablesData.map((elem: IVariableData) => {
            return {...elem, fieldValue: ""};
        });
        setVariablesData(defaultFields);
    }

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const handleChange = (name: string, value: any) => {
        const findIdxByName = (element: IVariableData) => element.name === name;
        const idx = variablesData.findIndex(findIdxByName);
        let newVariablesData = [...variablesData];
        newVariablesData[idx].fieldValue = value;
        setVariablesData(newVariablesData);
    }

    const convertObjectToArray = (object: object) => {
        const keys = Object.keys(object);
        const values = Object.values(object);

        const convertToArray = [];

        for(let i = 0; i < keys.length; i++){
            convertToArray.push([keys[i], values[i]]);
        }

        return convertToArray;
    }

    const createTable = () => {

        if(!!!data.error_types || Object.keys(data.error_types).length < 1){
            return(
                <Grid item className={classes.documentationText}>
                    {t("common.documentationNotAvailable")}
                </Grid>
            );
        }
        return(
            <Table>
                <TableHead className={classes.tableHeaderBackground}>
                    <TableRow>
                        <TableCell align="center" className={classes.tableSeparator}>Name</TableCell>
                        <TableCell align="center">Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBodyBackground}>
                    {createDynamicRows()}
                </TableBody>
            </Table>
        );
    }

    const createDynamicRows = () => {
        
        if(!!!data.error_types) return;

        const convertToArray = convertObjectToArray(data.error_types);

        return(
            <>
                {convertToArray.map((item: any) => (
                    <TableRow>
                        <TableCell align="center" className={classes.tableSeparator}>{item[0]}</TableCell>
                        <TableCell align="center">{item[1]}</TableCell>
                    </TableRow>
                ))}
            </>
        );
    }

    const createDynamicTree = (value: any, idNode: number) => {

        const convertToArray = convertObjectToArray(value);
        const nextLastId = convertToArray.length + lastIdNode;
        lastIdNode = nextLastId;
        return(
            <>
                {convertToArray.map((item, index) =>  (
                    <TreeItem nodeId={String(idNode + index)} label={`${item[0]}${typeof item[1] === "string" ? `: ${item[1]}` : ""}`}>
                        {typeof item[1] === "object" ? createDynamicTree(item[1], lastIdNode) : undefined}
                    </TreeItem>
                ))}
            </>
        );
    }

    const createTree = () => {
        
        if(!!!data.request_schema){
            return(
                <Grid item className={classes.documentationText}>
                    {t("common.documentationNotAvailable")}
                </Grid>
            );
        }
        
        const convertToArray = convertObjectToArray(data.request_schema);

        if(Object.keys(convertToArray[1][1]).length === 0){
            return(
                <Grid item className={classes.documentationText}>
                    {t("common.noInputVariables")}
                </Grid>
            );
        }
        return(
            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
            >
                {createDynamicTree(convertToArray[1][1], 1)}
            </TreeView>
        );
    }

    return(
        <Box>
            <Grid container spacing={4}>
                
                <Grid item xs={4}>
                    <Typography variant="h5" align="center">
                        Description
                    </Typography>
                    <Divider variant="fullWidth" className={classes.listItem}/>
                    <Grid item className={classes.documentationText}>
                        {data.description}
                    </Grid>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h5" align="center">
                        Request schema
                    </Typography>
                    <Divider variant="fullWidth" className={classes.listItem}/>
                    {createTree()}
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="h5" align="center">
                        Errors
                    </Typography>
                    <Divider variant="fullWidth" className={classes.listItem}/>
                    {createTable()}
                </Grid>

            </Grid>

            <Tooltip title="Custom tests" placement="top">
                <Fab
                    color="primary"
                    aria-laber="peek"
                    className={classes.fab}
                    onClick={handleOpenDialog}
                    disabled={disableCustomButton}
                >
                    <PlayArrowIcon/>
                </Fab>
            </Tooltip>

            <PrometheusCustomDialog 
                open={openDialog}
                onClose={handleCloseDialog}
                variablesData={variablesData}
                onChange={handleChange}
                setFieldsToDefault={setFieldsToDefault}
            />
            
        </Box>
    );
}

export default PrometheusJobDetail;