import { IEstablishmentType } from "../../gateways/establishment-type.interface";
import { FC, useState, useEffect } from "react";
import useIoC from "../../contexts/ioc.context";
import { EstablishmentTypeGateway } from "../../gateways/establishment-type.gateway";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import React from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog, Grid, makeStyles, Tabs, Tab } from "@material-ui/core";
import FormTextField from "../../components/form-text-field";
import { IRepositoryDefinition, ICreateRepositoryDefinition, ICreateGroupTypes, ICreateProperties, IRepositoryDefinitionRelations } from "../../gateways/repository-definition.interfaces";
import { RepositoryDefinitionGateway } from "../../gateways/repository-definition.gateway";
import MaterialTable from 'material-table';
import { RepositoryValueTypeGateway } from "../../gateways/repository-value-types.gateway";
import { IRepositoryValueType } from "../../gateways/repository-value-type.interface";
import SelectorField from "../../components/selector-field";
import { IRepositoryDefinitionType } from "../../gateways/repository-definition-type.interfaces";
import { RepositoryDefinitionTypeGateway } from "../../gateways/repository-definition-type.gateway";
import { IGroupType } from "../../gateways/repository-group-type.interfacecs";
import { RepositoryGroupTypeGateway } from "../../gateways/repository-group-type.gateway";
import RepositoryDefinitionCriteria from "./repository-definition-criteria";
import slugify from 'react-slugify';
import RepositoryDefinitionForm from './repository-definition-form'

interface IRepositoryDefinitionAddDialogProps {
    open: boolean;
    onCreated: (repositoryDefinition: IRepositoryDefinitionRelations) => void;
    onClose: () => void;
}
const useStyles = makeStyles(theme => ({

    gridItem: {
        height: '100%',
        borderSpacing: 2,
        padding: theme.spacing(1)
    }
}));

const initialRepositoryDefinition: ICreateRepositoryDefinition = {
    name: '',
    description: '',
    repositoryDefinitionTypeEntityId: 0,
    properties: [],
    groupTypes: [],
    parameters: [],
    slug: '',
    criteria: { queryBase: '', repositoryDefinitionPropertyEntityId: -1, sortAscDefault: false }
};

const RepositoryDefinitionAddDialog: FC<IRepositoryDefinitionAddDialogProps> = (props) => {

    const classes = useStyles();
    const repositoryDefinitionGateway = useIoC(RepositoryDefinitionGateway);
    const repositoryValueTypeGateway = useIoC(RepositoryValueTypeGateway);
    const repositoryDefinitionTypeGateway = useIoC(RepositoryDefinitionTypeGateway);
    const repositoryGroupTypeGateway = useIoC(RepositoryGroupTypeGateway);
    const [repositoryDefinitionData, setRepositoryDefinitionData] = useState(initialRepositoryDefinition);
    const [repositoryDefinitionType, setRepositoryDefinitionType] = useState<IRepositoryDefinitionType[]>([]);
    const [valueTypesToLookUp, setValueTypesToLookUp] = useState<{}>({});
    const [groupTypesToLookup, setGroupTypesToLookup] = useState<{}>({});
    const [value, setValue] = useState(0);
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const fillGroupTypes = (): ICreateGroupTypes[] => {
        let toReturn: ICreateGroupTypes[] = [];
        for (let index: number = 0; index < repositoryDefinitionData.groupTypes.length; index++) {
            toReturn[index] = {
                groupTypeId: repositoryDefinitionData.groupTypes[index].groupTypeId,
                sourceProperty: repositoryDefinitionData.groupTypes[index].sourceProperty
            }
        }
        return toReturn;
    }

    const fillProperties = (): ICreateProperties[] => {
        let toReturn: ICreateProperties[] = [];
        for (let index: number = 0; index < repositoryDefinitionData.properties.length; index++) {
            toReturn[index] = {
                name: repositoryDefinitionData.properties[index].name,
                valueTypeId: repositoryDefinitionData.properties[index].valueTypeId,
            }
        }
        return toReturn;
    }

    const checkGroupTypes = (): boolean => {
        for (let i: number = 0; i < repositoryDefinitionData.groupTypes.length; i++) {
            for (let j: number = 0; j < repositoryDefinitionData.groupTypes.length; j++) {
                if (repositoryDefinitionData.groupTypes[i].groupTypeId === repositoryDefinitionData.groupTypes[j].groupTypeId && i !== j) {
                    return false;
                }
            }
        }
        return true;
    }


    const handleCreate = async () => {
        if (repositoryDefinitionData.name === undefined || repositoryDefinitionData.name === '' ||
            repositoryDefinitionData.description === undefined || repositoryDefinitionData.description === '' ||
            !repositoryDefinitionData.repositoryDefinitionTypeEntityId || repositoryDefinitionData.properties.length === 0 ||
            repositoryDefinitionData.slug === undefined ||
            repositoryDefinitionData.slug === '' || repositoryDefinitionData.criteria.queryBase === undefined ||
            repositoryDefinitionData.criteria.queryBase === '' || repositoryDefinitionData.criteria.repositoryDefinitionPropertyEntityId === -1 ||
            repositoryDefinitionData.criteria.sortAscDefault === undefined) {

            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        };
        const checkExistance = await repositoryDefinitionGateway.findBySlug(repositoryDefinitionData.slug);
        if (checkExistance.length > 0) {
            snackbar.enqueueSnackbar(t('repositoryDefinitionAddDialog.slugAlreadyExists', { id: checkExistance[0].id }), { variant: "error" });
            return;
        }
        if (!checkGroupTypes()) {
            snackbar.enqueueSnackbar(t('repositoryDefinitionErrorHandler.groupTypesError'), { variant: "error" });
            return;
        }

        try {
            let dataToSend: ICreateRepositoryDefinition =
            {
                parameters: repositoryDefinitionData.parameters,
                criteria: repositoryDefinitionData.criteria,
                description: repositoryDefinitionData.description,
                name: repositoryDefinitionData.name,
                repositoryDefinitionTypeEntityId: repositoryDefinitionData.repositoryDefinitionTypeEntityId,
                slug: repositoryDefinitionData.slug,
                groupTypes: fillGroupTypes(),
                properties: fillProperties()
            }
            const repositoryDefinition = await repositoryDefinitionGateway.create(dataToSend);
            snackbar.enqueueSnackbar(t('repositoryDefinitionAddDialog.createSucceded', { id: repositoryDefinition.id, name: repositoryDefinition.name }), { variant: "success" });
            setRepositoryDefinitionData(initialRepositoryDefinition)
            const createdRepositoryDefinition = await repositoryDefinitionGateway.findById(repositoryDefinition.id)
            props.onCreated(createdRepositoryDefinition);
        } catch (e) {
            const er = e as any;
            if (er.response.data.key === 'creatingEntityError') {
                const key = 'repositoryDefinitionErrorHandler.' + er.response.data.key;
                const message = "validation" in er ? er.validation : t(key);
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose();
        }

    };

    function a11yProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (name: string, value: any) => {
        setRepositoryDefinitionData({ ...repositoryDefinitionData, [name]: value });
    };
    const handleSortAscDefaultChange = () => {
        const changedCriteria = repositoryDefinitionData.criteria;
        changedCriteria.sortAscDefault = !changedCriteria.sortAscDefault;
        setRepositoryDefinitionData({ ...repositoryDefinitionData, criteria: changedCriteria });
    };
    const handleChangeCriteriaQueryBase = (name: string, value: any) => {
        let criteriaUpdateAid = repositoryDefinitionData.criteria;
        criteriaUpdateAid.queryBase = value;
        setRepositoryDefinitionData({ ...repositoryDefinitionData, criteria: criteriaUpdateAid });
    };
    const handleChangeCriteriaPropertyEntityId = (name: string, value: any) => {
        let criteriaUpdateAid = repositoryDefinitionData.criteria;
        criteriaUpdateAid.repositoryDefinitionPropertyEntityId = value;
        setRepositoryDefinitionData({ ...repositoryDefinitionData, criteria: criteriaUpdateAid });
    };
    const handleCodeChange = (newCode: string) => {
        const changedCriteria = repositoryDefinitionData.criteria;
        changedCriteria.queryBase = newCode;
        setRepositoryDefinitionData({ ...repositoryDefinitionData, criteria: changedCriteria })
    };
    const handleChangeValue = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleCancel = () => {
        setRepositoryDefinitionData(repositoryDefinitionData);
        props.onClose();
    };

    const validateRequired = (value: any): boolean => {
        return value !== null && value !== undefined && value !== '';
    };

    useEffect(() => {
        (async () => {
            const valueTypes = await repositoryValueTypeGateway.findAll();
            setValueTypesToLookUp(valueTypes.reduce(function (acc, cur) {
                let id = cur.id;
                let name = cur.name;
                acc = Object.assign(acc, { [id]: name });
                return acc;
            }, {}));

            setRepositoryDefinitionType(await repositoryDefinitionTypeGateway.findAll());
            const groupTypes = await repositoryGroupTypeGateway.findAll();
            setGroupTypesToLookup(groupTypes.reduce(function (acc, cur) {
                let id = cur.id;
                let description = cur.description;
                acc = Object.assign(acc, { [id]: description });
                return acc;
            }, {}));
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const slugName = slugify(repositoryDefinitionData.name);
            setRepositoryDefinitionData({ ...repositoryDefinitionData, slug: slugName });
        })();
    }, [repositoryDefinitionData.name]);

    return (
        <RepositoryDefinitionForm
            a11yProps={a11yProps}
            groupTypesToLookup={groupTypesToLookup}
            item={repositoryDefinitionData}
            onCancel={handleCancel}
            onChange={handleChange}
            onChangeCriteriaPropertyEntityId={handleChangeCriteriaPropertyEntityId}
            onChangeCriteriaQueryBase={handleChangeCriteriaQueryBase}
            onChangeValue={handleChangeValue}
            onClose={props.onClose}
            onCodeChange={handleCodeChange}
            onSortAscDefaultChange={handleSortAscDefaultChange}
            onUpsert={handleCreate}
            open={props.open}
            repositoryDefinitionData={repositoryDefinitionData}
            repositoryDefinitionType={repositoryDefinitionType}
            setRepositoryDefinitionData={setRepositoryDefinitionData}
            update={false}
            validateRequired={validateRequired}
            value={value}
            valueTypesToLookUp={valueTypesToLookUp}
        />
    );
};
export default RepositoryDefinitionAddDialog;