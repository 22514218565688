import React, { FC, useContext, useState, useEffect, useMemo, useReducer } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles } from "@material-ui/core";
import { StateContext, ReducerContext } from "./context";
import { IRepositoryQueryFilterDto, FilterOperator, IRepositoryQueryFilterOrDto } from "../../gateways/repository.interfaces";
import AddIcon from '@material-ui/icons/Add';
import FilterItemDialog from './filter-item.dialog';
import { FiltersReducer } from "./filters.reducer";
import { IFSTableColumn } from "./interfaces";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));


export const FiltersDialog: FC = () => {
    const state = useContext(StateContext);
    const dispatch = useContext(ReducerContext);
    const classes = useStyles();

    const [filtersState, filtersDispatch] = useReducer(FiltersReducer, {
        filters: [],
    });

    const defaultColumn = useMemo(() => state.columns && state.columns.length > 0 ? state.columns.filter(x => !!x.name)[0] : null, [state.columns])
    const defaultProperty = useMemo(() => defaultColumn && !!defaultColumn.name ? defaultColumn.name : '', [defaultColumn]);

    useEffect(() => {
        filtersDispatch({ type: 'reset', payload: state.filters.map(x => ({ ...x, ors: x.ors.map(o => ({ ...o })) })) });
    }, [state.filters]);

    const handleClose = () => {
        dispatch({ type: 'hideFiltersDialog' });
    }

    const handleAddFilter = () => {
        const newFilter: IRepositoryQueryFilterDto = {
            property: defaultProperty,
            ors: [
                { operator: FilterOperator.equals, value: null }
            ]
        };

        filtersDispatch({ type: 'add', payload: newFilter });
    }

    const handleApplyFilter = () => {
        dispatch({ type: 'apply-filters', payload: [...filtersState.filters] });
    }

    return (
        <Dialog open={state.openFiltersDialog} onClose={handleClose} fullWidth={true} maxWidth="md">
            <DialogTitle id="form-dialog-title">Configuración de filtros</DialogTitle>
            <DialogContent>
                {
                    filtersState.filters.map((filter, index) => (
                        <FilterItemDialog
                            key={'filter_' + index}
                            filter={filter}
                            index={index}
                            filtersDispatch={filtersDispatch}
                        />
                    ))
                }
                <Button
                    variant="contained"
                    color="default"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={handleAddFilter}
                >
                    Agregar nuevo filtro
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">
                    Cancelar
                </Button>
                <Button onClick={handleApplyFilter} color="primary">
                    Aplicar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
