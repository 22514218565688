import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IRepositoryDefinitionType } from "./repository-definition-type.interfaces";

const repositoryDefinitionType = 'repository-definition-type';
@inject('axios')
export class RepositoryDefinitionTypeGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IRepositoryDefinitionType[]> {
        const response = await this.axios.get(repositoryDefinitionType);
        return response.data;
    }
}