import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IProviders, IUpdateProvidersList, IProvidersDisplay } from "./providers.interface"
import { ISupplyToAccess } from "./supply-to-access.interfaces";
import querystring from 'querystring';
import { IRegisterFuelBill, IRegisterFuelBillUpdate, IRegisterFuelBillCreate } from "./register-fuel-bill.interface";

const registerFuelBillResource = 'register-fuel-bill';
@inject('axios')
export class RegisterFuelBillGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IRegisterFuelBill[]> {
        const response = await this.axios.get(registerFuelBillResource);
        return response.data;
    }

    async findById(id: number): Promise<IRegisterFuelBill> {
        const url = `${registerFuelBillResource}/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    async create(fuelBill: IRegisterFuelBillCreate): Promise<IRegisterFuelBill> {
        const response = await this.axios.post(registerFuelBillResource, fuelBill);
        return response.data;
    }

    async update(id: number, fuelBill: IRegisterFuelBillUpdate): Promise<IRegisterFuelBill> {
        const url = `${registerFuelBillResource}/${id}`;
        const response = await this.axios.put(url, fuelBill);
        return response.data;
    }

    async delete(id: number): Promise<void> {
        const url = `${registerFuelBillResource}/${id}`;
        const response = await this.axios.delete(url);
        return response.data;
    }
}