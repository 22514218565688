import React, { FC, useState, useEffect } from 'react';
import { Box, Grid, FormControl, InputLabel, Select, Input, MenuItem, makeStyles, useTheme, Theme, TableBody, Table, TableHead, TableRow, TableCell, Button } from '@material-ui/core';
import { MarketingGateway } from '../../gateways/marketing.gateway';
import useIoC from '../../contexts/ioc.context';
import Chip from '@material-ui/core/Chip';
import classes from '*.module.css';
import { ICampaign, IMarketingTaskResult } from '../../gateways/marketing.interfaces';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
        maxWidth: 1024,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(campaignId: number, selectedCampaignId: number[], theme: Theme) {
    return {
        fontWeight:
            selectedCampaignId.indexOf(campaignId) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}



const DashboardMarketing: FC = () => {
    const marketingGateway = useIoC(MarketingGateway);
    const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
    const [resulTypes, setResultTypes] = useState<IMarketingTaskResult[]>([]);

    const classes = useStyles();
    const theme = useTheme();
    const [campaignsSelected, setSelectedCampaigns] = React.useState<ICampaign[]>([]);
    const [fromDate, setFromDate] = useState<Date>(moment().add('days', -30).toDate());
    const [toDate, setToDate] = useState<Date>(moment().toDate());
    const [valueType, setValueType] = useState<string>('totalClients');
    const [stadistics, setStadistics] = useState<any[]>([]);
    const snackbar = useSnackbar();

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedCampaigns(event.target.value as ICampaign[]);
    };

    const handleChangeFromDate = (date: Date | null) => setFromDate(date || fromDate);
    const handleChangeToDate = (date: Date | null) => setToDate(date || toDate);
    const handleChangeValueType = (event: any) => setValueType(event.target.value);

    const handleRefresh = (overrideDto?: any) => {
        marketingGateway.getStadistics(Object.assign({
            campaignIds: campaignsSelected.map(x => x.id),
            fromDate,
            toDate
        }, overrideDto || {})).then((x: any) => {
            setStadistics(x);
            snackbar.enqueueSnackbar('Los datos se han refrescado', {
                variant: 'success',
            });
        });
    }

    const renderTotalValue = (campaign: any) => {
        let total = 0;
        for (const s of stadistics) {
            if (s.campaignId === campaign.id) {
                total += s[valueType];
            }
        }
        return total;
    }

    const renderTotal = () => {
        let total = 0;
        for (const s of stadistics) {
            total += s[valueType];
        }
        return total;
    }

    const renderTotalType = (x: IMarketingTaskResult) => {
        let total = 0;
        for (const s of stadistics) {
            if (s.actionId === x.id) {
                total += s[valueType];
            }
        }
        return total;
    }

    const renderTypeValue = (campaign: any, x: IMarketingTaskResult) => {
        let total = 0;
        for (const s of stadistics) {
            if (campaign.id === s.campaignId && s.actionId === x.id) {
                total += s[valueType];
            }
        }
        return total;
    }

    useEffect(() => {
        Promise.all([
            marketingGateway.findAllCampaigns(),
            marketingGateway.getTaskResults(),
        ]).then((result) => {
            setCampaigns(result[0]);
            setSelectedCampaigns(result[0]);
            setResultTypes(result[1].concat({ id: -1, name: 'Sin resultado' }));
            handleRefresh({ campaignIds: result[0].map(x => x.id) });
        });
    }, []);

    return (
        <Box>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">Campañas</InputLabel>
                <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    multiple
                    value={campaignsSelected.map(x => x.name)}
                    onChange={handleChange}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {(selected as string[]).map((value) => (
                                <Chip key={value} label={value} className={classes.chip} />
                            ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                    {campaigns.map((campaign) => (
                        <MenuItem key={campaign.name} value={campaign as any} style={getStyles(campaign.id, campaignsSelected.map(x => x.id), theme)}>
                            {campaign.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Grid container spacing={2}>
                <Grid item md={3}>
                    <FormControl fullWidth className={classes.formControl}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"

                            id="date-picker-inline"
                            label="Desde"
                            value={fromDate}
                            onChange={handleChangeFromDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={3}>
                    <FormControl fullWidth className={classes.formControl}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"

                            id="date-picker-inline"
                            label="Hasta"
                            value={toDate}
                            onChange={handleChangeToDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Button variant="outlined" onClick={() => handleRefresh()}>Actualizar datos</Button>
            </div>

            <FormControl style={{ width: '100%', maxWidth: '300px' }}>
                <InputLabel id="demo-simple-select-label">Valor</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={valueType}
                    onChange={handleChangeValueType}
                >
                    <MenuItem value={'totalClients'}>Totales clientes</MenuItem>
                    <MenuItem value={'oportunityFees'}>Oportunidad Honorarios</MenuItem>
                    <MenuItem value={'totalActions'}>Totales acciones</MenuItem>
                </Select>
            </FormControl>

            {
                campaignsSelected.length > 0
                    ? <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                {
                                    campaignsSelected.map(x => (<TableCell>{x.name}</TableCell>))
                                }
                                <TableCell>
                                    TOTAL
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>TOTAL</TableCell>
                                {
                                    campaignsSelected.map(x => (<TableCell>{renderTotalValue(x)}</TableCell>))
                                }
                                <TableCell>{renderTotal()}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                resulTypes.map(x => (<TableRow>
                                    <TableCell>{x.name}</TableCell>
                                    {
                                        campaignsSelected.map(c => (<TableCell>{renderTypeValue(c, x)}</TableCell>))
                                    }
                                    <TableCell>{renderTotalType(x)}</TableCell>
                                </TableRow>))
                            }
                        </TableBody>
                    </Table>
                    : <div>Selecciona las campañas y fechas por las que quieres mostrar estadisticas</div>
            }

        </Box>
    )
};

export default DashboardMarketing;