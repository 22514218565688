import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IOperateVariables } from "./operate.interface";

export interface IMissingBotDataQuery {
  errorNoCups: boolean;
  errorNoDate: boolean;
  errorOneDriveFile: boolean;
  errorOperative: boolean;
}

@inject("axios")
export class OperateGateway {
  constructor(private axios: AxiosInstance) {}

  async getMissingBot_fData(query?: IMissingBotDataQuery) {
    const response = await this.axios.post("operate/missing-bot_f-data", 
      query,
    );
    return response.data;
  }
}
