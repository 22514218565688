import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import SelectorField from "../../components/selector-field";
import { IEstablishment } from "../../gateways/establishment.interface";
import ICompany from "../../gateways/company.interface";
import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
  GridSize,
  IconButton,
} from "@material-ui/core";
import { IComplex } from "../../gateways/complex.interfaces";
import { IEstablishmentType } from "../../gateways/establishment-type.interface";
import { emailValidationError } from "../../utils/utils";
import { EditLocation } from "@material-ui/icons";
import { Coordinate, CoordinateTask } from "../../gateways/coordinates.interfaces";
import CoordinatesGateway from "../../gateways/coordinates.gateway";
import useIoC from "../../contexts/ioc.context";
import { CoordinatesSelectorMapDialog } from "../coordinates/coordinates-map";
import { useFetchController } from "../../hooks/use-fetch-controller";
import { useSnackbar } from "notistack";
import { EstablishmentCoordinatesSelectorMapDialog } from "./establishment-coordinates-map";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      height: "100%",
      borderSpacing: 2,
      padding: theme.spacing(1),
    },
    specialGridItem: {
      height: "100%",
      borderSpacing: 2,
      padding: theme.spacing(1),
      paddingLeft: "0px",
    },
  })
);

interface IComplexDetailProps {
  item: IEstablishment;
  onChange: (name: string, value: any) => void;
  validateRequired: (value: any) => boolean;
  establishmentsTypes: IEstablishmentType[];
  companies: ICompany[];
  complex: IComplex[];
  onClickAddEstablishmentTypeButton: () => void;
  onClickAddCompanyButton?: () => void;
  onClickAddComplexButton?: () => void;
  coordinates: string;
  handleSetDireccion: (name: string, value: string) => void;
  handleChangeAddress: (name: string, value: string) => void;
  onSendBeforeTelemedidaChange: () => void;
  largeXs: GridSize;
  smallXs: GridSize;
  
  coordinatesData: Coordinate;
  handleSetCoordinates: (name: string, value: string) => void;

  handleSetCoordinatesData: (value: Coordinate) => void
}

const EstablishmentForm: FC<IComplexDetailProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  


  const coordinatesGateway = useIoC(CoordinatesGateway);
  const [openMap, setOpenMap] = useState<any | null>(null);//CoordinateTask  or any
  const { enqueueSnackbar } = useSnackbar();


  const fetcher = useCallback(() => {
    return coordinatesGateway.getTasksCoordinates();
  }, [coordinatesGateway]);

  const { data, loading, replaceData } = useFetchController(fetcher, {
    autoload: true,
  });

  const handleMarkAsCompleted = useCallback(
    (
      item: CoordinateTask,
      newAddress?: {
        address: string;
        coordinates: Coordinate;
      }
    ) => {
      return async () => {
        if (
          newAddress ||
          window.confirm(`¿Quieres marcar la tarea ${item.id} como realizada?`)
        ) {
          try {
            await coordinatesGateway.markAsCompletedTask(item.id, newAddress);
            replaceData((data ?? []).filter((x) => x.id !== item.id));
            enqueueSnackbar(
              `Se ha marcado como completada la tarea ${item.id}`,
              { variant: "success" }
            );
          } catch (e: any) {
            enqueueSnackbar(
              `Ha ocurrido un error al completar la tarea: ${e.toString()}`,
              { variant: "error" }
            );
          }
        }
      };
    },
    [coordinatesGateway, enqueueSnackbar, replaceData, data]
  );





  return (
    <div>
      <Grid container direction="row" xs={12}>
        <Grid xs={props.largeXs} className={classes.specialGridItem}>
          <FormTextField
            name="name"
            label={t("common.name")}
            type="text"
            validator={props.validateRequired}
            errorText={t("messages.requiredField") as string}
            value={props.item.name}
            onChange={props.onChange}
            autoFocus
            fullWidth
            required
          />
        </Grid>
        <Grid xs={props.smallXs} className={classes.gridItem}>
          <Tooltip title={t("common.sendBeforeValidatingTooltip") as string}>
            <FormControlLabel
              control={
                <Checkbox
                  color={"primary"}
                  checked={props.item.sendBeforeValidating}
                  onChange={props.onSendBeforeTelemedidaChange}
                />
              }
              label={t("common.sendBeforeValidating")}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <FormTextField
        name="brandName"
        label={t("common.brandName")}
        type="text"
        value={props.item.brandName}
        onChange={props.onChange}
        fullWidth
      />
      <SelectorField
        name="establishmentTypeId"
        emptyValue={false}
        fullWidth
        inputLabel={t("common.establishmentType")}
        helperText={t("messages.requiredField") as string}
        required
        onChange={props.onChange}
        value={props.item.establishmentTypeId}
        validator={props.validateRequired}
        values={props.establishmentsTypes.map((value) => {
          return { id: value.id, value: value.name };
        })}
        handleClickAddButon={props.onClickAddEstablishmentTypeButton}
      />

      <Grid container direction="row" xs={12} > 
        <Grid xs={11} className={classes.specialGridItem}>
          <FormTextField
            name="address"
            disabled
            label={t("common.address")}
            type="text"
            validator={props.validateRequired}
            errorText={t("messages.requiredField") as string}
            value={props.item.address}
            onChange={props.handleSetDireccion}
            fullWidth
            required
          />
        </Grid>

        <Grid xs={1} className={classes.gridItem}>
          <IconButton
            color="secondary"
            onClick={() => setOpenMap(
              { address: props.item.address, 
                coordinates: props.coordinatesData
              })}
          >
            <EditLocation fontSize="large"/>
          </IconButton>
        </Grid>

      </Grid>

      <FormTextField
        name="coordinates"
        disabled
        label={t("common.coordinates")}
        type="text"
        validator={props.validateRequired}
        errorText={t("messages.requiredField") as string}
        value={props.coordinates}
        onChange={props.handleChangeAddress}
        fullWidth
        required
      />
      
      <SelectorField
        name="companyId"
        emptyValue={false}
        fullWidth
        inputLabel={t("common.company")}
        helperText={t("messages.requiredField") as string}
        required
        onChange={props.onChange}
        value={props.item.companyId}
        validator={props.validateRequired}
        values={props.companies.map((value) => {
          return { id: value.id, value: value.name };
        })}
        handleClickAddButon={props.onClickAddCompanyButton}
      />
      <SelectorField
        name="complexId"
        emptyValue={true}
        fullWidth
        inputLabel={t("common.complex")}
        required={false}
        onChange={props.onChange}
        value={props.item.complexId}
        values={props.complex.map((value) => {
          return { id: value.id, value: value.name };
        })}
        handleClickAddButon={props.onClickAddComplexButton}
      />
      <FormTextField
        fullWidth
        label={t("common.client_email")}
        name="client_email"
        type="email"
        value={props.item.client_email ?? ""}
        onChange={props.onChange}
        error={emailValidationError(props.item.client_email)}
        helperTextMessage={`Si hay más de 1 correo, separar con punto y coma, ejemplo: test1@test.com; test2@test.com`}
      />

      {openMap && (
        <EstablishmentCoordinatesSelectorMapDialog
          open
          onClose={() => setOpenMap(null)}
          onSelect={ () => {
            setOpenMap(null);
          }}
          initialAddress={
            openMap.address && openMap.coordinates
              ? {
                  address: openMap.address ?? "",
                  coordinates: openMap.coordinates,
                }
              : null
          }
          handleSetDireccion={props.handleSetDireccion}
          handleSetCoordinates={props.handleSetCoordinates}
          handleSetCoordinatesData={props.handleSetCoordinatesData}
        />
      )}
      
    </div>
  );
};

export default EstablishmentForm;
