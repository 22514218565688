import React, { FC } from "react";
import { makeStyles, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, ListItemIcon, Tooltip } from "@material-ui/core";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AdbIcon from '@material-ui/icons/Adb';
import { green } from '@material-ui/core/colors';
import { IDisplayElectricContractList } from "../../gateways/electric-contract.interfaces";
import { useTranslation } from "react-i18next";
import ComponentIcon from "../../components/component-icon"
import moment from "moment";

interface IElectricContractItemProps {
    item: IDisplayElectricContractList;
    onClickItem: (item: IDisplayElectricContractList) => void;
}

const useStyles: any = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textTooltip: {
        fontSize: "2em"
    }
}));
const FormatDate = (dateToFormat: Date) => {
    const { t } = useTranslation();
    return (dateToFormat ? `${moment(dateToFormat).format('DD/MM/YYYY')}` : t('common.undefinedDate'));

};
const electricContractDateInfo = (electricContract: IDisplayElectricContractList) => {
    return FormatDate(electricContract.initSupplyDate) + ' - '
        + FormatDate(electricContract.endSupplyDate);
}

const ElectricContractItem: FC<IElectricContractItemProps> = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start" className={classes.listItem} onClick={() => { props.onClickItem(props.item) }}>
            <ListItemAvatar >
                <InsertDriveFileIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
            </ListItemAvatar>
            <ListItemText primary={props.item.reference} secondary={electricContractDateInfo(props.item)} />
            <ListItemSecondaryAction>
                <ListItemIcon><ComponentIcon item={props.item.supplyConnectionBot} /></ListItemIcon>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default ElectricContractItem;