import React, { FC } from "react";
import { makeStyles, Theme, createStyles, Typography, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IDashboardToDisplay } from '../../gateways/dashboard.interfaces';
import clsx from 'clsx';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import { green, blue, red, yellow, grey } from "@material-ui/core/colors";

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        supplyContainer: {
            marginBottom: theme.spacing(5),
        },
        supplyRow: {
            cursor: 'pointer',
        },
        rowOk: {
            backgroundColor: green[200],
        },
        rowLoading: {
            backgroundColor: blue[200],
        },
        rowWithError: {
            backgroundColor: red[200],
        },
        rowWithWarning: {
            backgroundColor: yellow[200],
        },
        rowExecutionError: {
            backgroundColor: grey[200],
        },

    })
);

interface IDashboardFormProps {
    client: { id: number, name: string };
    supplyTypes: any[];
    suppliesToDisplay: IDashboardToDisplay[][];
    handleShowSupplyDialog: (supply: any) => void;
}

export const DashboardForm: FC<IDashboardFormProps> = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const getInfoButtonMessage = (state: number): string => {
        let toReturn: string = '';
        switch (state) {
            case 0:
                toReturn = t('messages.noErrorsInDashboard');
                break;
            case 1:
                toReturn = t('messages.warningsInDashboard');
                break;
            case 2:
                toReturn = t('messages.errorsInDashboard');
                break;
            case 3:
                toReturn = t('messages.dashboardLoading');
                break;
            case 4:
                toReturn = t('messages.executionErrorOnDashboard');
                break;
        }
        return toReturn;
    }

    return (
        <div>
            {
                props.supplyTypes.map((supplyType) => (
                    < div className={classes.supplyContainer} >

                        {
                            props.suppliesToDisplay[supplyType.id - 1] &&
                            props.suppliesToDisplay[supplyType.id - 1].length !== 0 &&
                            <Typography variant="h6" gutterBottom={true}>
                                Suministros de {supplyType.name}
                            </Typography>
                        }
                        {
                            props.suppliesToDisplay[supplyType.id - 1] &&
                            props.suppliesToDisplay[supplyType.id - 1].length !== 0 &&
                            < Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('dashboard.supply')}</TableCell>
                                        <TableCell>{t('dashboard.cups')}</TableCell>
                                        <TableCell>{t('common.marketer')}</TableCell>
                                        <TableCell>{t('dashboard.lastInvoicePeriod')}</TableCell>
                                        <TableCell>{t('dashboard.lastCoveredDate')}</TableCell>
                                        <TableCell>{t('dashboard.lastInvoiceDate')}</TableCell>
                                        <TableCell>{t('dashboard.lastRevisionDate')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.suppliesToDisplay[supplyType.id - 1].map((supply) => (

                                            <TableRow className={clsx(
                                                [
                                                    classes.rowOk,
                                                    classes.rowWithWarning,
                                                    classes.rowWithError,
                                                    classes.rowLoading,
                                                    classes.rowExecutionError,
                                                ][supply.state], classes.supplyRow)}
                                                onClick={() => props.handleShowSupplyDialog(supply)}>
                                                <TableCell>{supply.supplyName}</TableCell>
                                                <TableCell>{supply.cups}</TableCell>
                                                <TableCell>
                                                    {
                                                        supply.lastSupplyInvoice ? supply.lastSupplyInvoice : t('dashboard.registerNotFound')
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        supply.lastInvoicePeriod ?
                                                            supply.lastInvoicePeriod :
                                                            t('dashboard.registerNotFound')
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        supply.lastCoveredDate ?
                                                            moment(supply.lastCoveredDate)
                                                                .format('DD/MM/YYYY') :
                                                            t('dashboard.registerNotFound') as string
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        supply.lastInvoiceDate ?
                                                            moment(supply.lastInvoiceDate)
                                                                .format('DD/MM/YYYY') :
                                                            t('dashboard.registerNotFound') as string
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        supply.lastRevisionDate ?
                                                            moment(supply.lastRevisionDate)
                                                                .format('DD/MM/YYYY') :
                                                            t('dashboard.registerNotFound') as string
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title={getInfoButtonMessage(supply.state)}>
                                                        <InfoIcon color="action" />
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        }
                    </div>

                ))
            }
        </div >
    )
};
