import React, { FC, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
  Grid,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

export interface ISelectorFieldProps {
  emptyValue: boolean;
  fullWidth?: boolean;
  helperText?: string;
  inputLabel: string;
  error?: boolean;
  name: string;
  onChange: (name: string, value: any) => void;
  required: boolean;
  validator?: (value: any) => boolean;
  values: { id: number | string; value: string }[];
  value: number | string | null;
  className?: string;
  handleClickAddButon?: () => void;
  disableError?: boolean;
  disabled?: boolean;
  valueNotId?: boolean;
}

const SelectorField: FC<ISelectorFieldProps> = (props) => {
  const [error, setError] = useState(false);
  // const [displayValues, setDisplayValues] = useState<
  //   { id: number | string; value: string }[]
  // >([]);
  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const value = event.target.value;
    const name = event.target.name!;
    const valueValidated =
      props.validator === null ||
      props.validator === undefined ||
      props.validator(value);

    if (!props.disableError) {
      setError(!valueValidated);
    }
    props.onChange(name, value);
  };

  // useEffect(() => {
  //   setDisplayValues(
  //     props.emptyValue
  //       ? [{ value: "", id: 0 }, ...props.values]
  //       : [...props.values]
  //   );
  // }, [props.values]);

  return (
    <Grid container>
      <Grid item style={{ flex: 1 }}>
        <FormControl fullWidth={props.fullWidth} className={props.className}>
          <InputLabel htmlFor="helper">{props.inputLabel}</InputLabel>
          <Select
            value={props.value ?? 0}
            onChange={handleChange}
            disabled={props.disabled}
            inputProps={{
              name: props.name,
              id: "helper",
            }}
            required={props.required}
            error={props.error}
            fullWidth={true}
          >
            {props.emptyValue && (
              <MenuItem key={0} value="">
                Ninguno
              </MenuItem>
            )}
            {props.values.map((item) => {
              return (
                <MenuItem key={item.id} 
                          value={
                            props.valueNotId !== undefined && 
                            props.valueNotId === true 
                            ? item.value : item.id
                          }
                >
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
          {error && props.helperText ? (
            <FormHelperText>{props.helperText}</FormHelperText>
          ) : null}
        </FormControl>
      </Grid>
      {props.handleClickAddButon && (
        <Grid item style={{ alignSelf: "flex-end" }}>
          <IconButton
            color="primary"
            aria-label="add"
            onClick={props.handleClickAddButon}
            style={{ paddingBottom: 0 }}
            disabled={props.disabled}
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default SelectorField;
