import React, { FC, useState } from "react";
import { BotFRowActionComponentProps } from "../interfaces";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useSnackbar } from "notistack";
import Tooltip from '@material-ui/core/Tooltip';
import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import useIoC from "../../../../contexts/ioc.context";
import InfoBotDialogNoCups from "./error-no-cups-dialog";
import { BlacklistGateway } from "../../../../gateways/blacklist.gateway";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonItem: {
        padding: theme.spacing(1),
        marginTop: 15,
        marginLeft: 35
    },
    toolTipItem: {
        color: 'white',
        marginTop: 5,
        fontSize: 15,
        opacity: '0.6 !important',
    }
  })
);

export const RowActionErrorNoCups: FC<BotFRowActionComponentProps> = ({ 
  operate, icon, clientId, companyId, complexId
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const blacklistGateway = useIoC(BlacklistGateway);
    const snackbar = useSnackbar();

    const handleClickOpen = async () => {
      setOpen(true);
    };
    
    const handleClose = async () => {
      setOpen(false);
    };
    
    const handleInsertInBlackList = async () => {
      await blacklistGateway.insertCups(operate.cups);
      snackbar.enqueueSnackbar('Se ha descartado el suministro correctamente', {
        variant: 'success',
      });
    };

    return (
      <>
        <Tooltip title="Edita el suministro" placement="bottom-start" arrow classes={{tooltip: classes.toolTipItem}}>
            <IconButton edge="end" className={classes.buttonItem} aria-label="bot" color="primary" onClick={handleClickOpen}>
                {icon}
            </IconButton>
        </Tooltip>

        <InfoBotDialogNoCups
            open={open}
            onClose={handleClose}
            operate={operate}
            icon={icon}
            clientId={clientId}
            companyId={companyId}
            complexId={complexId}
        />

        {operate.errorType === "errorNoCups" && (
        <Tooltip title="Descarta el suministro" placement="bottom-start" arrow classes={{tooltip: classes.toolTipItem}}>
            <IconButton edge="end" onClick={handleInsertInBlackList} className={classes.buttonItem} aria-label="bot" color="secondary">
            <ExitToAppIcon style={{ fontSize: 28 }} />
            </IconButton>
        </Tooltip>
        )}
      </>
    );
};