import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import {
  IEstablishment,
  ICreateEstablishment,
  IUpdateEstablishment,
} from "./establishment.interface";
import querystring from "querystring";

type FindFilterEstablishment = {
  establishmentTypeId?: number;
  complexId?: number;
  companyId?: number;
  clientId?: number;
  showDiscarded?: boolean;
};

type FindFilterEstablishmentByClientId = {
  clientId: number;
  showDiscarded?: boolean;
}

@inject("axios")
export class EstablishmentGateway {
  constructor(private axios: AxiosInstance) {}

  public async findAll(
    filter?: FindFilterEstablishment
  ): Promise<IEstablishment[]> {
    let url = "establishment";
    if (filter) {
      const query = querystring.stringify(filter);
      url += `?${query}`;
    }
    const response = await this.axios.get(url);
    return response.data;
  }

  public async findById(id: number): Promise<IEstablishment> {
    const response = await this.axios.get("establishment/" + id);
    return response.data;
  }

  async findAllFiltered(input: string): Promise<string[]> {
    const response = await this.axios.get(`establishment/filter/${input}`);
    return response.data;
  }
  
  public async findByClientId(filter: FindFilterEstablishmentByClientId): Promise<IEstablishment[]> {
    const url = `establishment/client?${querystring.stringify(filter)}`
    const response = await this.axios.get(url);
    return response.data;
  }

  public async findOneById(id: number): Promise<IEstablishment[]> {
    const response = await this.axios.get(`establishment/findOneById/${id}`);
    return response.data;
  }

  public async findByCompanyId(companyId: number): Promise<IEstablishment[]> {
    const response = await this.axios.get("establishment/company/" + companyId);
    return response.data;
  }

  public async create(dto: ICreateEstablishment): Promise<IEstablishment> {
    if (dto.complexId === 0) {
      dto.complexId = null;
    }
    if (dto.coordinatesId === 0) {
      dto.coordinatesId = null;
      dto.address = " ";
    }
    const response = await this.axios.post("establishment", dto);
    return response.data;
  }

  public async update(
    id: number,
    dto: IUpdateEstablishment
  ): Promise<IEstablishment> {
    if (dto.coordinatesId === 0) {
      dto.coordinatesId = null;
      dto.address = " ";
    }
    const response = await this.axios.put("establishment/" + id, dto);
    return response.data;
  }
}
