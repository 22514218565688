import React, { FC, useEffect, useState } from "react";
import { makeStyles, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, ListItemIcon, Tooltip, IconButton } from "@material-ui/core";
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { IElectricSupply } from "../../gateways/electric-supply.interface";
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import EstablishmentIcon from '@material-ui/icons/Store';
import { EstablishmentGateway } from "../../gateways/establishment.gateway";
import useIoC from "../../contexts/ioc.context";

interface IElectricSupplyItemProps {
    item: IElectricSupply;
    onClickItem: (item: IElectricSupply) => void;
    establishmentId: string;
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    deletedSupply: {
        color: "#E0E0E0"
    },
    nonDeletedSupply: {
        color: "black"
    }
}));

const ElectricSupplyItem: FC<IElectricSupplyItemProps> = (props) => {
    const classes = useStyles();
    
    const establishmentGateway = useIoC(EstablishmentGateway);

    const [establishmentName, setEstablishmentName] = useState<string>("");

    const ComponentIcon = () => {
        if (props.item.complexId === null && props.establishmentId === undefined) {
            return( 
                <Tooltip title={establishmentName} placement="top">
                    <ListItemIcon>
                        <EstablishmentIcon color="action" fontSize="large" className={classes.itemAvatarIcon}/>
                    </ListItemIcon>
                </Tooltip>
            );
        }
        return null;
    };

    let color = classes.nonDeletedSupply;
    if(props.item.blacklisted){
        color = classes.deletedSupply;
    }

    useEffect(() => {
        (async () => {
            if(props.item.establishmentId){
                const establishment = await establishmentGateway.findById(props.item.establishmentId);
                setEstablishmentName(establishment.name);
            }
        })();
    }, []);
    
    return (
        <ListItem alignItems="flex-start" className={classes.listItem} onClick={() => { props.onClickItem(props.item) }}>
            <ListItemAvatar >
                <FlashOnIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
            </ListItemAvatar>
            <ListItemText className={color} primary={props.item.referencia}/>
            <ListItemSecondaryAction>
                <ComponentIcon />
            </ListItemSecondaryAction>
        </ListItem>);


};

export default ElectricSupplyItem;