import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import SelectorField from '../../components/selector-field';
import { IAccessType } from "../../gateways/access-type.interfaces";
import { ICreateFuelSupply, IUpdateFuelSupply } from "../../gateways/fuel-supply.interface"
import { IFuelType } from "../../gateways/fuel-types.interface";
import { IFuelContract, IFuelContractAccountLogs, IFuelContractCreate } from "../../gateways/fuel-contract.interfaces";
import { IProvidersDisplay, IProviders } from "../../gateways/providers.interface";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles, Theme, createStyles, Grid, Typography, DialogProps, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, DialogActions, Button } from "@material-ui/core";
import { IFuelSupplyRate } from "../../gateways/fuel-supply-rates.interface";
import moment from "moment";
import { ListAlt } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        text: {
            paddingTop: '2%'
        },
        gridItem: {
          height: "100%",
          borderSpacing: 2,
          padding: theme.spacing(1),
        },
        gridItemSupply: {
          height: "100%",
          borderSpacing: 2,
          paddingTop: '25px', 
          paddingBottom: '5px', 
          paddingLeft: '10px', 
          paddingRight: '10px'
        },

    })
);

interface IFuelContractProps {
    item: IFuelContract;
    accountLogs?: IFuelContractAccountLogs[];
    showAccountLogs?: Boolean;
    onChange: (name: string, value: any) => void;
    validateRequired: (value: any) => boolean;
    providers: IProviders[];
    onSignContractDateChange: (value: any) => void;
    onInitSupplyDateChange: (value: any) => void;
    onEndSupplyDateChange: (value: any) => void;
    onChangeModality: (name: string, value: any) => void;
    fuelSupplyRates: IFuelSupplyRate[]
    onClickAddProvider: () => void;
}

const FuelContractForm: FC<IFuelContractProps> = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [open, setOpen] = useState(false)
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
      setOpen(true);
      setScroll(scrollType);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
      if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [open]);

    return (
        <div>
            <Grid container justify="flex-start" alignItems="flex-start">
              <Grid item xs={6} className={classes.gridItemSupply}>
                <SelectorField
                    name='providerId'
                    emptyValue={true}
                    fullWidth
                    inputLabel={t('common.marketer')}
                    helperText={t('messages.requiredField') as string}
                    required
                    onChange={props.onChange}
                    value={props.item.providerId}
                    validator={props.validateRequired}
                    values={props.providers.map(value => {
                        return { id: value.id, value: value.society };
                    })}
                    handleClickAddButon={props.onClickAddProvider}
                />
              </Grid>

              <Grid item xs={6} className={classes.gridItem}>
                <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="contractSignDateSelector"
                    label={t('common.contractSignDateSelector')}
                    value={props.item.signContractDate}
                    onChange={props.onSignContractDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    // required
                    fullWidth
                />
              </Grid>

              <Grid item xs={6} className={classes.gridItem}>
                <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="SupplyInitDateSelector"
                    label={t('common.supplyInitDateSelector')}
                    value={props.item.initSupplyDate}
                    onChange={props.onInitSupplyDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    required
                    fullWidth
                />
              </Grid>

              <Grid item xs={6} className={classes.gridItem}>
                <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="SupplyEndDateSelector"
                    label={t('common.supplyEndDateSelector')}
                    value={props.item.endSupplyDate}
                    onChange={props.onEndSupplyDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    required
                    fullWidth
                />
              </Grid>

              <Grid item xs={6} className={classes.gridItem}>
                <FormTextField
                    name='duration'
                    label={t('common.duration')}
                    type='number'
                    // validator={props.validateRequired}
                    // errorText={t('messages.requiredField')}
                    value={props.item.duration}
                    onChange={props.onChange}
                    fullWidth
                    disabled={true}
                    // required
                />

              </Grid>

              <Grid item xs={6} className={classes.gridItem}>
                <FormTextField
                    name='term'
                    label={t('common.term')}
                    type='number'
                    // validator={props.validateRequired}
                    // errorText={t('messages.requiredField')}
                    value={props.item.term}
                    onChange={props.onChange}
                    fullWidth
                    // required
                />
              </Grid>

              <Grid item xs={6} className={classes.gridItem}>
                <FormTextField
                    name='cancelPriorNotice'
                    label={t('common.cancelPriorNotice')}
                    type='number'
                    // validator={props.validateRequired}
                    // errorText={t('messages.requiredField')}
                    value={props.item.cancelPriorNotice}
                    onChange={props.onChange}
                    fullWidth
                    // required
                />
              </Grid>

              <Grid item xs={props.showAccountLogs? 5 : 6} className={classes.gridItem}>
                <FormTextField
                  name="accountNumber"
                  label={`${t("common.accountNumber")}${props.item.accountNumber_date? ` - añadido el ${moment(props.item.accountNumber_date).format('DD/MM/YYYY')}`: ''}`}
                  type="string"
                  value={props.item.accountNumber?? ''}
                  onChange={props.onChange}
                  fullWidth
                  // required
                  />
              </Grid>

              {props.showAccountLogs &&
                <Grid item xs={1} className={classes.gridItem}>
                  <Tooltip title="Ver registros">
                    <IconButton 
                      onClick={handleClickOpen('paper')} 
                      disabled={props.accountLogs?.length === 0}>
                      <ListAlt/>
                    </IconButton>
                  </Tooltip>
                </Grid>
              }

            </Grid>
            <div className={classes.root}>
                <Grid container spacing={3} className={classes.gridItem}>
                    <Grid item xs>
                        <SelectorField
                            name='modality'
                            emptyValue={false}
                            fullWidth
                            inputLabel={t('common.modality')}
                            helperText={t('messages.requiredField') as string}
                            required
                            onChange={props.onChangeModality}
                            value={props.item.modality}
                            validator={props.validateRequired}
                            values={[{ id: 1, value: t('common.fixed') }, { id: 2, value: t('common.indexed') }]}
                        />
                    </Grid>
                    <Grid item xs>
                        <SelectorField
                            name='fuelSupplyRateId'
                            emptyValue={false}
                            fullWidth
                            inputLabel={t('common.periods')}
                            helperText={t('messages.requiredField') as string}
                            required
                            onChange={props.onChange}
                            value={props.item.fuelSupplyRateId}
                            validator={props.validateRequired}
                            values={props.fuelSupplyRates.map(value => {
                                return { id: value.id, value: value.name };
                            })}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <FormTextField
                            name='fixStipulation'
                            label={t('common.fixStipulation')}
                            type='number'
                            // validator={props.validateRequired}
                            // errorText={t('messages.requiredField')}
                            value={props.item.fixStipulation ? props.item.fixStipulation : 0 }
                            onChange={props.onChange}
                            adornmentLabel={t('common.fixStipulationRate') as string}
                            fullWidth
                            // required
                        />
                    </Grid>
                    <Grid item xs>
                        <FormTextField
                            name='variableStipulation'
                            label={t('common.variableStipulation')}
                            type='number'
                            // validator={props.validateRequired}
                            // errorText={t('messages.requiredField')}
                            value={props.item.variableStipulation ?props.item.variableStipulation : 0 }
                            onChange={props.onChange}
                            adornmentLabel="/kWhpcs"
                            fullWidth
                            // required
                        />
                    </Grid>
                    <Grid item xs>
                        <FormTextField
                            name='yearlyStipulation'
                            label={t('common.yearlyStipulation')}
                            type='number'
                            // validator={props.validateRequired}
                            // errorText={t('messages.requiredField')}
                            value={props.item.yearlyStipulation}
                            onChange={props.onChange}
                            adornmentLabel={t('common.yearlyStipulationRate') as string}
                            fullWidth
                            // required
                        />
                    </Grid>
                </Grid>
            </div>
            {props.item.modality === "2" &&
                <div className={classes.root}>
                    <Typography className={classes.text}>{t('common.additionalInfo')}</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs>
                            <FormTextField
                                name='variableA'
                                label={t('common.variableA')}
                                type='number'
                                // validator={props.validateRequired}
                                // errorText={t('messages.requiredField')}
                                value={props.item.variableA}
                                onChange={props.onChange}
                                fullWidth
                                // required
                            />
                        </Grid>
                        <Grid item xs>
                            <FormTextField
                                name='variableB'
                                label={t('common.variableB')}
                                type='number'
                                // validator={props.validateRequired}
                                // errorText={t('messages.requiredField')}
                                value={props.item.variableB}
                                onChange={props.onChange}
                                fullWidth
                                // required
                            />
                        </Grid>
                        <Grid item xs>
                            <FormTextField
                                name='variableC'
                                label={t('common.variableC')}
                                type='number'
                                // validator={props.validateRequired}
                                // errorText={t('messages.requiredField')}
                                value={props.item.variableC}
                                onChange={props.onChange}
                                fullWidth
                                // required
                            />
                        </Grid>
                    </Grid>
                </div>
            }

          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">Historial de registros</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> Numero de cuenta </TableCell>
                        <TableCell> Editor </TableCell>
                        <TableCell> Fecha de edición </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.accountLogs?.map(row =>(
                        <TableRow key={row.id}>
                          <TableCell>{row.accountNumber === ''? <i>Se eliminó la cuenta </i>: row.accountNumber }</TableCell>
                          <TableCell>{row.editorUsername}</TableCell>
                          <TableCell>{moment(row.editionDate).format('DD/MM/YYYY')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Salir
              </Button>
            </DialogActions>
          </Dialog>

        </div>
    );
};

export default FuelContractForm;