import React, { FC, useEffect, useState, useMemo } from "react";
import { List, ListItem, ListItemAvatar, ListItemText, Divider, makeStyles, CircularProgress, Box, Table, TableHead, TableBody, TableCell, TableRow, ListItemSecondaryAction, Switch, ListItemIcon, TextField, Button } from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import { withRouter, RouteComponentProps } from "react-router";
import Fab from '@material-ui/core/Fab';
import useIoC from "../../contexts/ioc.context";
import AlertBox from "../../components/alert-box";
import { useNavigator } from '../../contexts/navigator.context';
import ClientIcon from '@material-ui/icons/People';
import { useTranslation } from "react-i18next";
import { MarketingGateway } from "../../gateways/marketing.gateway";
import { IClientMarketing } from "../../gateways/marketing.interfaces";
import FSTable from "../../components/fs-table";
import { IRepositoryQueryFilterDto, FilterOperator, IRepositoryDefinitionGroup } from "../../gateways/repository.interfaces";

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    inline: {
        display: 'inline',
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    progress: {
        margin: theme.spacing(2),
    },
    loaderContainer: {
        textAlign: 'center'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const ClientList: FC<RouteComponentProps> = ({ history }) => {
    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const { t } = useTranslation();
    const marketingGateway = useIoC(MarketingGateway);

    const handleNavigateClient = (item: IClientMarketing) => {
        history.push('/marketing/clients/' + item.id);
    }

    const handleAddClient = () => {
        history.push('/marketing/clients/create');
    }

    const handleGroupCreated = (group: IRepositoryDefinitionGroup) => {
        marketingGateway.createTasksForGroup(group.id);
    }

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('clientList.title'),
                icon: ClientIcon
            }
        });
    }, []);

    return (
        <Box>
            <div className={classes.fab}>
                <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleAddClient}>
                    <AddIcon />
                </Fab>
            </div>

            <FSTable
                repositoryId="main-marketing-clients"
                onRowClick={handleNavigateClient}
                groupsEnabled={false}
                title='Listado de clientes por CUPS'
                columns={[
                    { name: 'comercialName', label: 'Nombre Comercial', sortable: true, },
                    { name: 'postalCode', label: 'C.P.', sortable: true, },
                    { name: 'opportunityFees', label: 'Oportunidad honorarios', sortable: true, },
                    { name: 'potentalSavings', label: 'Ahorro potencial', sortable: true, },
                    { name: 'averageInvoice', label: 'Fact. media', sortable: false, },
                    { name: 'volume', label: 'Volumen', sortable: true, },
                    { name: 'horasEquivalentes', label: 'heq', sortable: true, },
                    { name: 'resultName', label: 'Prox. acción', sortable: true, },
                    { name: 'taskDate', label: 'F. Prox. Acción', sortable: true, },

                    { name: 'nif', label: 'NIF', sortable: true, },
                    { name: 'cups', label: 'CUPS', sortable: false, },
                    { name: 'nombreComercializadora', label: 'Comercializadora', sortable: true, },
                    { name: 'potenciaContratadaP1', label: 'Pot. contr. P1', sortable: true, },
                    { name: 'potenciaContratadaP2', label: 'Pot. contr. P2', sortable: true, },
                    { name: 'potenciaContratadaP3', label: 'Pot. contr. P3', sortable: true, },
                    { name: 'potenciaContratadaP4', label: 'Pot. contr. P4', sortable: true, },
                    { name: 'potenciaContratadaP5', label: 'Pot. contr. P5', sortable: true, },
                    { name: 'potenciaContratadaP6', label: 'Pot. contr. P6', sortable: true, },

                    { name: 'estado', label: 'Estado', sortable: true, },
                    { name: 'types', label: 'Tipos', sortable: false, },
                ]}
            />
        </Box>
    );
}

export default withRouter(ClientList);