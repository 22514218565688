import { List } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { FC } from "react";
import useIoC from "../../contexts/ioc.context";
import { ComplexGateway } from "../../gateways/complex.gateway";
import CoordinatesGateway from "../../gateways/coordinates.gateway";
import {
  ICoordinatesEstCmplx,
  initialICoordinatesEstCmpls,
} from "../../gateways/coordinates.interfaces";
import { EstablishmentGateway } from "../../gateways/establishment.gateway";
import CoordinatesFields from "./coordinates-fields";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Pagination from "@material-ui/lab/Pagination";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import CenteredCircularProgress from "../../components/centered-circular-progress";
import AlertBox from "../../components/alert-box";
import { CoordiantesLayout } from "./coordinates.layout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationItem: {
      marginTop: theme.spacing(2),
      justifyContent: "center",
      display: "flex",
    },
  })
);

const CoordinatesPending: FC = () => {
  const snackbar = useSnackbar();
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [totalOfInformation, setTotalOfInformation] = useState<
    ICoordinatesEstCmplx[]
  >([]);
  const [isDataEmpty, setIsDataEmpty] = useState<boolean>(false);
  const [items, setItems] = useState<ICoordinatesEstCmplx[]>([]);
  const { t } = useTranslation();
  const [lastUpdatedId, setLastUpdatedId] = useState<string>("");
  const [lastUpdated, setLastUpdated] = useState<string>("");
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const complexGateway = useIoC(ComplexGateway);
  const coordinatesGateway = useIoC(CoordinatesGateway);
  const establishmentGateway = useIoC(EstablishmentGateway);

  const computeSliceValues = (page: number) => {
    return { topValue: page * 10, initValue: page * 10 - 10 };
  };

  const computeNumberOfPages = (numberOfItems: number) => {
    const numberOfPages =
      numberOfItems % 10 === 0
        ? numberOfItems / 10
        : Math.floor(numberOfItems / 10) + 1;
    return numberOfPages;
  };

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);

      const coordinatesEstAndCmplx: ICoordinatesEstCmplx[] =
        await coordinatesGateway.findEstAndCmplx();
      if (coordinatesEstAndCmplx.length > 0) {
        setLastUpdated(
          `${coordinatesEstAndCmplx[0].isEst}-${
            coordinatesEstAndCmplx[0].locationId
          }-${
            coordinatesEstAndCmplx[0].coordinates
              ? coordinatesEstAndCmplx[0].coordinates.formatted
              : 0
          }`
        );
      }

      let nonCancelledCom = await complexGateway.findAll({
        showDiscarded: false,
      });
      let nonCancelledEst = await establishmentGateway.findAll({
        showDiscarded: false,
      });

      let filteredCoordinates: ICoordinatesEstCmplx[] =
        coordinatesEstAndCmplx.filter((item) => !!!item.coordinates);

      const filteredCoordinatesEstAndCmplx: any[] = [];

      for (let i = 0; i < filteredCoordinates.length; i++) {
        for (let j = 0; j < nonCancelledCom.length; j++) {
          if (nonCancelledCom[j].id === filteredCoordinates[i].complexId) {
            filteredCoordinatesEstAndCmplx.push(filteredCoordinates[i]);
            break;
          }
        }
        for (let j = 0; j < nonCancelledEst.length; j++) {
          if (
            nonCancelledEst[j].id === filteredCoordinates[i].establishmentId
          ) {
            filteredCoordinatesEstAndCmplx.push(filteredCoordinates[i]);
            break;
          }
        }
      }
      setTotalOfInformation(filteredCoordinatesEstAndCmplx);
      setNumberOfPages(
        computeNumberOfPages(filteredCoordinatesEstAndCmplx.length)
      );
      setItems(filteredCoordinatesEstAndCmplx.slice(0, 10));
      setIsDataLoading(false);
      setIsDataEmpty(filteredCoordinatesEstAndCmplx.length === 0);
    })();
  }, [complexGateway, establishmentGateway, coordinatesGateway]);

  const handleChangePage = (event: any, value: number) => {
    setCurrentPage(value);
    const pageLimits =
      value === numberOfPages
        ? { initValue: value * 10 - 10, topValue: totalOfInformation.length }
        : computeSliceValues(value);
    setItems(
      totalOfInformation.slice(pageLimits.initValue, pageLimits.topValue)
    );
  };

  const handleChangeNew = useCallback(
    async (name, value) => {
      const regex = /^(?<latitude>[^,]+),[\s]+(?<longitude>[^,]+)$/;
      const coordinatesRegex = regex.exec(value);
      let updatedItem: ICoordinatesEstCmplx = initialICoordinatesEstCmpls;
      let newAddress: { address: string; isSpain: boolean } = {
        address: "",
        isSpain: false,
      };
      if (
        coordinatesRegex &&
        coordinatesRegex.groups &&
        parseFloat(coordinatesRegex.groups.latitude) &&
        parseFloat(coordinatesRegex.groups.longitude)
      ) {
        newAddress = await coordinatesGateway.getAddress(value);
        if (!newAddress.isSpain) {
          snackbar.enqueueSnackbar(t("coordinatesNavigation.outOfSpain"), {
            variant: "warning",
          });
        }
      }

      setItems((prevItems) =>
        prevItems.map((item) => {
          if (name !== `${item.isEst}-${item.locationId}`) {
            return item;
          } else {
            updatedItem =
              coordinatesRegex && coordinatesRegex.groups
                ? {
                    ...item,
                    address: newAddress.address,
                    coordinates: {
                      formatted: value,
                      id: 0,
                      latitude: parseFloat(coordinatesRegex.groups.latitude),
                      longitude: parseFloat(coordinatesRegex.groups.longitude),
                    },
                  }
                : {
                    ...item,
                    coordinates: {
                      formatted: value,
                      id: 0,
                      latitude: 0,
                      longitude: 0,
                    },
                  };
            return updatedItem;
          }
        })
      );
      setLastUpdated(
        `${updatedItem.isEst}-${updatedItem.locationId}-${updatedItem.coordinates.formatted}`
      );
    },
    [t, coordinatesGateway, snackbar]
  );

  const handleSave = useCallback(
    async (id: string, currPage: number, currNPages: number) => {
      const toUpdate: ICoordinatesEstCmplx = JSON.parse(id);

      if (
        !!!toUpdate.coordinates.latitude &&
        !!!toUpdate.coordinates.longitude
      ) {
        snackbar.enqueueSnackbar(t("coordinatesNavigation.inValidFormat"), {
          variant: "error",
        });
        return;
      }

      (async () => {
        try {
          const upsertedCoordinates = await coordinatesGateway.create({
            latitude: toUpdate.coordinates.latitude,
            longitude: toUpdate.coordinates.longitude,
          });

          if (toUpdate.isEst) {
            await establishmentGateway.update(toUpdate.locationId, {
              address: toUpdate.address,
              client_email: toUpdate.client_email,
              companyId: toUpdate.companyId,
              complexId: toUpdate.complexId,
              establishmentTypeId: toUpdate.establishmentId ?? 0,
              name: toUpdate.name,
              brandName: toUpdate.brandName,
              coordinatesId: upsertedCoordinates.id,
              sendBeforeValidating: toUpdate.sendBeforeValidating,
            });
          } else {
            await complexGateway.update(toUpdate.locationId, {
              companyId: toUpdate.companyId,
              countryId: toUpdate.countryId ?? 0,
              direction: toUpdate.address,
              name: toUpdate.name,
              regionsIds: [],
              coordinatesId: upsertedCoordinates.id,
              sendBeforeValidating: toUpdate.sendBeforeValidating,
            });
          }

          let nOfPages = 0;
          let newCurrPage = currPage;
          let itemToSet: ICoordinatesEstCmplx[] = [];

          setTotalOfInformation(
            (prevTotalOfInformation: ICoordinatesEstCmplx[]) => {
              const filteredValues: ICoordinatesEstCmplx[] =
                prevTotalOfInformation.filter(
                  (i: ICoordinatesEstCmplx) =>
                    i.isEst !== toUpdate.isEst ||
                    i.locationId !== toUpdate.locationId
                );

              nOfPages = computeNumberOfPages(filteredValues.length);

              if (currNPages !== nOfPages && currPage === currNPages) {
                newCurrPage = nOfPages;
              }

              const sliceLimits =
                newCurrPage === nOfPages
                  ? {
                      initValue: newCurrPage * 10 - 10,
                      topValue: filteredValues.length,
                    }
                  : computeSliceValues(newCurrPage);
              itemToSet = filteredValues.slice(
                sliceLimits.initValue,
                sliceLimits.topValue
              );

              return filteredValues;
            }
          );

          setCurrentPage(newCurrPage);
          setNumberOfPages(nOfPages);
          setItems(itemToSet);
          setLastUpdatedId(id);

          snackbar.enqueueSnackbar(
            t("coordinatesNavigation.done", {
              typeOfLocal: toUpdate.isEst ? "establecimiento" : "complejo",
              name: toUpdate.name,
              address: toUpdate.address,
            }),
            {
              variant: "success",
            }
          );
        } catch (e) {
          snackbar.enqueueSnackbar(t("messages.defaultError"), {
            variant: "error",
          });
          return;
        }
      })();
    },
    [t, snackbar, complexGateway, coordinatesGateway, establishmentGateway]
  );

  useEffect(() => {
    setItems((items) => items.filter((item) => !!!item.coordinates));
  }, [lastUpdatedId]);

  return (
    <CoordiantesLayout page="pending">
      {isDataLoading && <CenteredCircularProgress />}
      {isDataEmpty && !isDataLoading && (
        <AlertBox variant="info">{t("common.noItems")}</AlertBox>
      )}
      <div>
        <List>
          {items.map((item) => {
            return (
              <CoordinatesFields
                focus={
                  lastUpdated ===
                  `${item.isEst}-${item.locationId}-${
                    item.coordinates ? item.coordinates.formatted : 0
                  }`
                }
                coordinates={item}
                onChange={handleChangeNew}
                handleSave={handleSave}
                key={`${item.isEst}-${item.locationId}-${
                  item.coordinates ? item.coordinates.formatted : 0
                }`}
                currentNumberOfPages={numberOfPages}
                currentPage={currentPage}
              />
            );
          })}
        </List>

        <Pagination
          className={classes.paginationItem}
          count={numberOfPages}
          variant="outlined"
          color="primary"
          onChange={handleChangePage}
          page={currentPage}
        />
      </div>
    </CoordiantesLayout>
  );
};

export default CoordinatesPending;
