import React, { useContext } from 'react';
import { Reducer, ReactNode } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import createGlobalReducerContext from './generic.context';

interface NavigatorState {
    navOpen: boolean;
    header: IPageHeader | null;

    rightNavOpen: boolean;
    rightNavComponent: ReactNode | null;
}

interface IPageHeader {
    icon: React.ComponentType<SvgIconProps>;
    title: string;
}

type NavigatorAction =
    | { type: 'reset' }
    | { type: 'open-nav' | 'close-nav' | 'toggle-nav' }
    | { type: 'set-header', header: IPageHeader }
    | { type: 'set-right-nav', component: ReactNode }
    | { type: 'open-right-nav' | 'close-right-nav' | 'toggle-right-nav' }

const initialState: NavigatorState = {
    navOpen: false,
    header: null,
    rightNavComponent: null,
    rightNavOpen: false,
};


const reducer: Reducer<NavigatorState, NavigatorAction> = (state, action) => {
    switch (action.type) {
        case 'reset': return { ...initialState };
        case 'open-nav': return { ...state, navOpen: true }
        case 'close-nav': return { ...state, navOpen: false }
        case 'toggle-nav': return { ...state, navOpen: !state.navOpen }
        case 'set-header': { return { ...state, header: action.header } };
        case 'set-right-nav': { return { ...state, rightNavComponent: action.component } };
        case 'open-right-nav': return { ...state, rightNavOpen: true }
        case 'close-right-nav': return { ...state, rightNavOpen: false }
        case 'toggle-right-nav': return { ...state, navOpen: !state.rightNavOpen }
        default: throw new Error('Unexpected action');
    }
};

const [useNavigator, NavigatorProvider] = createGlobalReducerContext(reducer, initialState);

export {
    NavigatorProvider,
    useNavigator,
};