import React, { FC, useState, useMemo, useEffect, Provider } from 'react';
import CenteredCircularProgress from '../../components/centered-circular-progress';
import AlertBox from '../../components/alert-box';
import { List, Divider, Box, Fab, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { IProviders, IUpdateProvidersList } from "../../gateways/providers.interface";
import { useTranslation } from 'react-i18next';
import ProviderItem from './providers-item';
import ProvidersUpsertDialog from './providers-upsert-dialog';
import useIoC from '../../contexts/ioc.context';
import { ProvidersGateway } from "../../gateways/providers.gateway";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { useNavigator } from '../../contexts/navigator.context';
import  ProviderDeleteDialog  from './providers-delete-dialog';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    }
}));

const ProvidersList: FC = () => {
    const classes = useStyles();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState<IProviders[]>([]);
    const [openUpsertDialog, setOpenUpsertDialog] = useState(false);
    const [item, setItem] = useState<IProviders>();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);
    const { t } = useTranslation();
    const providersGateway = useIoC(ProvidersGateway);
    const [, navigatorDispatch] = useNavigator();

    const handleClickFabButton = () => {
        setItem(undefined);
        setOpenUpsertDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenUpsertDialog(false);
    };

    const handleClickUpdateButtonItem = (item: IProviders) => {
        setItem(item);
        setOpenUpsertDialog(true);
    };

    const handleClickDeleteButtonItem = (item: IProviders) => {
        setItem(item);
        setOpenDeleteDialog(true);
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const eraseDisplay = (item: IProviders) => {       

        const copy = data.filter(it => it.id !== item.id);
        setData(copy);
    }

    const handleItemUpsert = (item: IProviders, isNew: boolean, supplyToAccessId: number[]) => {
        let copy = data.slice();
        if (isNew) {
            copy = [item, ...data];
        } else {
            const index = data.findIndex(value => value.id === item.id);
            copy[index] = item;
        }
        setData(copy);
    };

    useEffect(() => {
        (async () => {
            (async () => {
                try{                    
                    setIsDataLoading(true);
                    const data = await providersGateway.findAll();
                    const sortedData = data.sort(function(a: IProviders, b: IProviders) {
                        const firstLetterA = a.society.charAt(0).toLocaleLowerCase();
                        const firstLetterB = b.society.charAt(0).toLocaleLowerCase();
                        if(firstLetterA > firstLetterB) return 1;
                        else if(firstLetterA < firstLetterB) return -1;
                        else return 0;
                    })
                    setData(sortedData);
                    setIsDataLoading(false);
                }catch(e){                    
                    setIsDataLoading(false);
                }
            })();
        })();
    }, [providersGateway]);

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('providersList.title'),
                icon: LocalShippingIcon
            }
        });
    }, []);

    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />;
        }

        if (isDataEmpty) {

            return <AlertBox variant='info'>{t('common.noItems')}</AlertBox>;
        }

        return (
            <List>
                {data.map((item, index) => {
                    return (
                        <div key={item.id}>
                             <ProviderItem
                                item={item}
                                onClickUpdateButton={handleClickUpdateButtonItem} 
                                onClickDeleteButton={handleClickDeleteButtonItem} />
                            {index < data.length - 1 ? <Divider variant='inset' component='li' /> : null}
                        </div>
                    );
                })}
            </List>
        );
    };

    return (
        <Box>
            <Fab color='primary' aria-label='add' className={classes.fab} onClick={handleClickFabButton}>
                <AddIcon />
            </Fab>
            <Content />
            <ProvidersUpsertDialog
                open={openUpsertDialog}
                item={item}
                onItemUpsert={handleItemUpsert}
                onClose={handleCloseDialog} />
            <ProviderDeleteDialog
                open={openDeleteDialog}
                item={item}
                handleClose={handleCloseDeleteDialog}
                handleDeleteProvider={handleClickDeleteButtonItem}
                eraseDisplay={eraseDisplay} />

        </Box>
    );
};

export default ProvidersList;