import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React from "react";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IValidationGroup } from "../../gateways/validation-group.interfaces";
import { ValidationGroupGateway } from "../../gateways/validation-group.gateway";

export interface IRepositoryDefinitionDeleteDialogProps {
  open: boolean;
  item: IValidationGroup | undefined;
  onClose: () => void;
  onDeleted: (item: IValidationGroup) => void;
}

const ValidationGroupDeleteDialog: FC<IRepositoryDefinitionDeleteDialogProps> = ({ open, onClose: handleClose, onDeleted: handleDeleted, item }) => {
  const [componentDisabled, setComponentDisabled] = useState(false);
  const validationGroupGateway = useIoC(ValidationGroupGateway);
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const handleDeleteValidationGroup = () => {
    if (item === undefined || item.id === 0) {
      return;
    }
    setComponentDisabled(true);
    (async () => {
      try {
        await validationGroupGateway.delete(item.id);
        snackbar.enqueueSnackbar(t('validationGroupDeleteDialog.deleteSucceded', { id: item.id, name: item.name }), { variant: "success" });
        handleDeleted(item);
      } catch (e) {
        const er = e as any;
        if (er.response.data.key === 'deletingError') {
          const key = 'validationGroupErrorHandler.' + er.response.data.key;
          const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
          snackbar.enqueueSnackbar(message, { variant: "error" });
        } else {
          const message = "validation" in er ? er.validation : t('messages.defaultError');
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      } finally {
        setComponentDisabled(false);
        handleClose();
      }
    })();
  };

  return (
    <Dialog open={open} onClose={() => { handleClose() }} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('validationGroupDeleteDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('validationGroupDeleteDialog.content')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { handleClose() }} color="primary" disabled={componentDisabled}>{t('common.cancel')}</Button>
        <Button onClick={handleDeleteValidationGroup} color="primary" disabled={componentDisabled}>{t('common.accept')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationGroupDeleteDialog;
