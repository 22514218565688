import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import {
  IElectricSupply,
  IElectricSupplyUpsert,
} from "./electric-supply.interface";
import querystring from "querystring";
import { IElectricContract } from "./electric-contract.interfaces";
import { IRegisterElectricBill } from "./register-electric-bill.interface";

type FindFilterElectricSupply = {
  establishmentId?: string;
  complexId?: string;
  clientId?: number;
};

@inject("axios")
export class ElectricSupplyGateway {
  constructor(private axios: AxiosInstance) {}

  public async findAll(
    filter?: FindFilterElectricSupply
  ): Promise<IElectricSupply[]> {
    let url = "electric-supply";
    if (filter) {
      const query = querystring.stringify(filter);
      url += `?${query}`;
    }
    const response = await this.axios.get(url);
    return response.data;
  }

  async findAllFiltered(input: string): Promise<string[]> {
    const response = await this.axios.get(`electric-supply/filter/${input}`);
    return response.data;
  }

  public async findById(id: number): Promise<IElectricSupply> {
    const url = `electric-supply/${id}`;
    const response = await this.axios.get(url);
    return response.data;
  }

  public async findByIdElectricContract(
    id: number
  ): Promise<IElectricContract[]> {
    const url = `electric-supply/electricContract/${id}`;
    const response = await this.axios.get(url);
    return response.data;
  }

  public async findByIdRegisterElectricBill(
    id: number
  ): Promise<IRegisterElectricBill[]> {
    const url = `electric-supply/registerElectricBills/${id}`;
    const response = await this.axios.get(url);
    return response.data;
  }

  public async findOneById(id: number): Promise<IElectricSupply[]> {
    const response = await this.axios.get(`electric-supply/findOneById/${id}`);
    return response.data;
  }

  public async getUrl(cups: string): Promise<string> {
    const response = await this.axios.get(`electric-supply/getUrl/${cups}`);
    return response.data;
  }

  public async create(
    electricSupplyToCreate: IElectricSupplyUpsert
  ): Promise<IElectricSupply> {
    const response = await this.axios.post(
      "electric-supply",
      electricSupplyToCreate
    );
    return response.data;
  }

  public async update(
    id: number,
    electricSupplyToUpdate: IElectricSupplyUpsert
  ): Promise<IElectricSupply> {
    const url = `electric-supply/${id}`;
    const response = await this.axios.put(url, electricSupplyToUpdate);
    return response.data;
  }

  public async delete(id: number): Promise<void> {
    const url = `electric-supply/${id}`;
    await this.axios.delete(url);
  }

  public async getElectricComplexSupplies(id: number) {
    const response = await this.axios.get(
      `electric-supply/allComplexElectricSupplies/${id}`
    );
    return response.data;
  }
}
