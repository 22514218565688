import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { ResultJiraIssue } from "./jira.interfaces";

@inject('axios')
export class JiraGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async findAll(): Promise<ResultJiraIssue[]> {
        const response = await this.axios.get('jira');
        return response.data;
    }

}