import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import { IUserSignatureData } from "../../gateways/user-signature-data.interfaces";

interface ISignatureData {
    item: IUserSignatureData,
    onChange: (name: string, value: any) => void; 
}

const GenerateSignatureFields: FC<ISignatureData> = (props) => {

    const { t } = useTranslation();

    return(
        <>
            <FormTextField
                autoFocus
                fullWidth
                label={t("common.fullName")}
                name="fullname"
                errorText={t("messages.requiredField") as string}
                type="text"
                value={props.item.fullname}
                onChange={props.onChange}
            />
            <FormTextField
                fullWidth
                label={t("common.role")}
                name="role"
                errorText={t("messages.requiredField") as string}
                type="text"
                value={props.item.role}
                onChange={props.onChange}
            />
            <FormTextField
                fullWidth
                label={t("common.tlf")}
                name="tlf"
                errorText={t("messages.requiredField") as string}
                type="text"
                value={props.item.tlf}
                onChange={props.onChange}
            />
            <FormTextField
                fullWidth
                label={t("common.email")}
                name="email"
                errorText={t("messages.requiredField") as string}
                type="text"
                value={props.item.email}
                onChange={props.onChange}
            />
            <FormTextField
                fullWidth
                label={t("common.image")}
                name="imageUrl"
                errorText={t("messages.requiredField") as string}
                type="text"
                value={props.item.imageUrl}
                onChange={props.onChange}
            />
        </>
    );
}

export default GenerateSignatureFields;