import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IBotRO } from "./bots.interfaces";
import { IElectricSupplyRates } from "./electric-supply-rates.interface";

@inject('axios')
export class ElectricSupplyRatesGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async findAll(): Promise<IElectricSupplyRates[]> {
        const response = await this.axios.get('electric-supply-rates');
        return response.data;
    }

    public async findById(id: number): Promise<IElectricSupplyRates>{
        const url = `electric-supply-rates/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }
}