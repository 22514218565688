import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IValidationContext, ICreateValidationContext, IUpdateValidationContext } from "./validation-context.interfaces";

const validationContext = 'validation-context';
@inject('axios')
export class ValidationContextGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IValidationContext[]> {
        const response = await this.axios.get(validationContext);
        return response.data;
    }

    async findById(id: string): Promise<IValidationContext> {
        const url = `${validationContext}/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    async create(repositoryGroupTypeToCreate: ICreateValidationContext): Promise<IValidationContext> {
        const response = await this.axios.post(validationContext, repositoryGroupTypeToCreate);
        return response.data;
    }

    async update(id: string, repositoryGroupTypeToUpdate: IUpdateValidationContext): Promise<IValidationContext> {
        const url = `${validationContext}/${id}`;
        const response = await this.axios.put(url, repositoryGroupTypeToUpdate);
        return response.data;
    }

    async delete(id: string): Promise<void> {
        const url = `${validationContext}/${id}`;
        const response = await this.axios.delete(url);
        return response.data;
    }
}