import React, { FC, useContext, useState } from "react";
import { IProviders, IUpdateProvidersList } from "../../gateways/providers.interface";
import { makeStyles, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Box, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, TablePagination } from "@material-ui/core";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import UpdateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { columns, IHiringDashboard } from "../../gateways/hiring-dashboard.interfaces";
import FSTable from "../../components/fs-table/table";
import { StateContext } from "../../components/fs-table/context";
import moment from "moment";

interface IHiringDashboardItemProps {
    items: IHiringDashboard[];
    // onClickUpdateButton: (item: IHiringDashboard) => void;
    // onClickDeleteButton: (item: IHiringDashboard) => void;
}

const useStyles = makeStyles((theme) => ({
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 650,
    },
}));

const HiringDashboardItem: FC<IHiringDashboardItemProps> = (props) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(props.items.length);

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={'right'}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: IHiringDashboard) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.cups} onClick={() => alert('test')}>
                                    {columns.map((column) => {
                                        const value =
                                            column.id === 'currentContractEndDate' ||
                                                column.id === 'currentContractInitDate' ||
                                                column.id === 'currentContractSigningDate' ||
                                                column.id === 'futureContractEndDate' ||
                                                column.id === 'futureContractInitDate' ||
                                                column.id === 'futureContractSigningDate' ?
                                                moment(row[column.id]).format('DD/MM/YYYY')
                                                :
                                                row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align ? 'right' : undefined} >
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[props.items.length, 500, 1000]}
                component="div"
                count={props.items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default HiringDashboardItem;