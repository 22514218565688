import React, { FC } from "react";
import { withRouter, RouteComponentProps, Route, Switch } from "react-router";
import { useUser } from "../../contexts/user.context";
import BotsDashboard from "./bots";

const DashboardsIndex: FC<RouteComponentProps> = ({match}) => {
    const [{ canViewBotsMenu }] = useUser();
    
    return (
        <Switch>
            {canViewBotsMenu ? <Route path={`${match.path}/bots`} component={BotsDashboard} /> : null}
        </Switch>
    );
}

export default withRouter(DashboardsIndex);