import { ResultJiraIssue } from "../../../gateways/jira.interfaces";
import { MongoGateway } from "../../../gateways/mongo.gateway";

export const findMongoItem = async (item: ResultJiraIssue, mongoGateway: MongoGateway) => {
    //db
    let toFind = ' - Base de datos: ';
    let dbL = item.description.filter(desc => desc.toLowerCase().includes(toFind.toLowerCase()));
    let dbname = '';
    if (dbL.length > 0)
    {
        dbname = dbL[0].replace(toFind,'');
    }

    const dbtoFind = dbname.toLowerCase();
    let cwhere: any | null = null;

    if (dbtoFind === 'disa')
    {
        toFind= '- Número de la factura, invoiceNumber: ';
        const iNumberList = item.description.filter(desc => desc.toLowerCase().includes(toFind.toLowerCase()));

        let iNumber = '';
        if (iNumberList.length > 0)
        {
            iNumber = iNumberList[0].replace(toFind,'');
        }
        
        cwhere = [{"invoiceNumber":iNumber}];

        console.log(JSON.stringify(cwhere));

    }else if (dbtoFind === 'ufg')
    {
        //{"invoiceData.UFGCCode": UFGCCode, "invoiceData.invoiceCode": invoiceCode}
        toFind= '- Código UFG, invoiceData.UFGCCode: ';
        const iL1 = item.description.filter(desc => desc.toLowerCase().includes(toFind.toLowerCase()));

        let iResult1 = '';
        if (iL1.length > 0)
        {
            iResult1 = iL1[0].replace(toFind,'');
        }
        
        toFind= '- Código de la factura, invoiceData.invoiceCode: ';
        const iL2 = item.description.filter(desc => desc.toLowerCase().includes(toFind.toLowerCase()));

        let iResult2 = '';
        if (iL2.length > 0)
        {
            iResult2 = iL2[0].replace(toFind,'');
        }

        cwhere = [{"invoiceData.UFGCCode": iResult1, "invoiceData.invoiceCode": iResult2}];

        console.log(JSON.stringify(cwhere));
    }

    if(cwhere !== null)
    {
        try {
            return await mongoGateway.findAll({
                dbCollection: dbtoFind,
                dbName: "invoices",
                where: cwhere
                });
        } catch (e) {
            console.log("[findMongoItem]" + e as any);
        }
        return null;
    }else{
        return null;
    }
    

}