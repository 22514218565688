import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import DescriptionIcon from '@material-ui/icons/Description';
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { FuelSupplyGateway } from "../../gateways/fuel-supply.gateway";
import { datesHandler } from "../../utils/dates-handle";
import { FuelContractGateway } from "../../gateways/fuel-contract.gateway";
import { RegisterFuelBillGateway } from "../../gateways/register-fuel-bill.gateway";
import CountryGateway from "../../gateways/country.gateway";
import CurrencyGateway from "../../gateways/currency.gateway";
import { IFuelContract, initialIFuelContract } from "../../gateways/fuel-contract.interfaces";
import { IRegisterFuelBillUpdate, IRegisterFuelBill, initialIRegisterFuelBillUpdate } from "../../gateways/register-fuel-bill.interface";
import { VolumeUnitGateway } from "../../gateways/volume-units.gateway"
import RegisterFuelBillGeneral from "./register-fuel-bill-general";
import RegisterFuelBillGasoilDiesel from "./register-fuel-bill-gasoil-diesel";
import RegisterFuelBillNetwork from "./register-fuel-bill-network";
import RegisterFuelBillGlFuelBiomass from "./register-fuel-bill-GL-fuel-biomass";
import RegisterFuelBillNotNetwork from "./register-fuel-bill-not-network";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import { useRouteMatch } from "react-router-dom";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import moment from "moment";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20
        },
    })
);

interface IFuelBillRouteParams {
    fuelSupplyId: string;
    clientId: string;
};
const RegisterFuelBillDetail = () => {
    const { fuelSupplyId } = useParams<IFuelBillRouteParams>();
    const { t } = useTranslation();
    const snackbar = useSnackbar();
    const dateHandler = new datesHandler();
    const { url } = useRouteMatch();
    const { clientId } = useParams<IFuelBillRouteParams>();
    const { update: updateBreadCrumbName } = useBreadCrumbName();

    const [item, setItem] = useState<IRegisterFuelBillUpdate>(initialIRegisterFuelBillUpdate);
    const [currency, setCurrency] = useState<string>('');
    const [volumeUnit, setVolumeUnit] = useState<string>('');
    const [fuelContract, setFuelContract] = useState<IFuelContract>(initialIFuelContract);
    const [network, setNetwork] = useState<boolean>(true);
    const [glpFuelBiomass, setGlpFuelBiomass] = useState<boolean>(true);
    const [biomass, setBiomass] = useState<boolean>(false);


    const registerFuelBillGateway = useIoC(RegisterFuelBillGateway);
    const fuelContractGateway = useIoC(FuelContractGateway);
    const countriesGateway = useIoC(CountryGateway);
    const currenciesGateway = useIoC(CurrencyGateway);
    const fuelSupplyGateway = useIoC(FuelSupplyGateway);
    const volumeUnitGateway = useIoC(VolumeUnitGateway);
    const classes = useStyles();

    const { registerFuelBillId } = {registerFuelBillId: null}; //useParams();
    const { complexId } = {complexId: null}; //useParams();
    const { establishmentId } = { establishmentId: null}; //useParams();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);

            let coinId;
            let coin;

            let volumeUnitId;
            let volumeUnit;

            if (complexId) {
                try {
                    coinId = await countriesGateway.getCountrieCurrencyComplex(parseInt(complexId as any));
                    coin = await currenciesGateway.findById(coinId);
                    setCurrency(coin.isoCode);

                } catch (e) {
                    snackbar.enqueueSnackbar(t('currencyErrorHandler.readErrorComplex'), { variant: "warning" });
                }

                try {
                    volumeUnitId = await countriesGateway.getCountrieVolumeUnitComplex(parseInt(complexId as any));
                    volumeUnit = await volumeUnitGateway.findById(volumeUnitId);
                    setVolumeUnit(volumeUnit.name);

                } catch (e) {
                    snackbar.enqueueSnackbar(t('volumeUnitsErrorHandler.readErrorComplex'), { variant: "warning" });
                }
            } else if (establishmentId) {
                try {
                    coinId = await countriesGateway.getCountrieCurrencyEstablishment(parseInt(establishmentId as any));
                    coin = await currenciesGateway.findById(coinId);
                    setCurrency(coin.isoCode);
                } catch (e) {
                    snackbar.enqueueSnackbar(t('currencyErrorHandler.readErrorEstablishment'), { variant: "warning" });
                }

                try {
                    volumeUnitId = await countriesGateway.getCountrieVolumeUnitEstablishment(parseInt(establishmentId as any));
                    volumeUnit = await volumeUnitGateway.findById(volumeUnitId);
                    setVolumeUnit(volumeUnit.name);

                } catch (e) {
                    snackbar.enqueueSnackbar(t('volumeUnitsErrorHandler.readErrorEstablishment'), { variant: "warning" });
                }
            }
            if (!registerFuelBillId) {
                return;
            }
            const registerFuelBill = await registerFuelBillGateway.findById(Number.parseInt(registerFuelBillId as any));
            setItem(registerFuelBill);
            updateBreadCrumbName('registerFuelBill', registerFuelBill.fuelContractId.toString());
            const fuelSupply = await fuelSupplyGateway.findById(parseInt(fuelSupplyId));
            if (fuelSupply.accessTypeId !== 1) {
                setNetwork(false);
            }
            if (fuelSupply.fuelTypeId === 3 || fuelSupply.fuelTypeId === 4) {
                setGlpFuelBiomass(false)
            }
            setBiomass(fuelSupply.fuelTypeId === 6);
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (item.startingPeriodDate !== null) {
                    const contract = await fuelContractGateway.findByBillDate(item.startingPeriodDate, fuelSupplyId);
                    setFuelContract(contract);
                    setItem({ ...item, fuelContractId: contract.id });
                } else {
                    setItem({ ...item, fuelContractId: 0 });
                    setFuelContract(initialIFuelContract);
                }
            } catch (e) {
                const data = await fuelSupplyGateway.findByIdFuelContract(parseInt(fuelSupplyId));
                setItem({ ...item, fuelContractId: 0 });
                setFuelContract(initialIFuelContract);
                if (data.length > 0) {
                    if (item.startingPeriodDate) {
                        snackbar.enqueueSnackbar(t('messages.contractNotFoundError', {
                            day: item.startingPeriodDate.getDate(),
                            month: item.startingPeriodDate.getMonth() + 1,
                            year: item.startingPeriodDate.getFullYear()
                        }), { variant: "error" });
                    }
                } else {
                    snackbar.enqueueSnackbar(t('messages.nonExistentContracts'), { variant: "error" });
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [item.startingPeriodDate]);

    useEffect(() => {
        (async () => {
            if (item.startingPeriodDate && item.endingPeriodDate
                && !dateHandler.checkLimits(item.startingPeriodDate, item.endingPeriodDate)) {
                snackbar.enqueueSnackbar(t('messages.dateRangeError'), { variant: "error" });
                return;
            }
            await checkBillOverlap();
        })();
    }, [item.startingPeriodDate, item.endingPeriodDate]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (item.tankDate !== null) {
                    const contract = await fuelContractGateway.findByBillDate(item.tankDate, fuelSupplyId);
                    setFuelContract(contract);
                    setItem({ ...item, fuelContractId: contract.id });
                } else {
                    setItem({ ...item, fuelContractId: 0 });
                    setFuelContract(initialIFuelContract);
                }
            } catch (e) {
                const data = await fuelSupplyGateway.findByIdFuelContract(parseInt(fuelSupplyId));
                setItem({ ...item, fuelContractId: 0 });
                setFuelContract(initialIFuelContract);
                if (data.length > 0) {
                    if (item.startingPeriodDate) {
                        snackbar.enqueueSnackbar(t('messages.contractNotFoundError', {
                            day: item.startingPeriodDate.getDate(),
                            month: item.startingPeriodDate.getMonth() + 1,
                            year: item.startingPeriodDate.getFullYear()
                        }), { variant: "error" });
                    }
                } else {
                    snackbar.enqueueSnackbar(t('messages.nonExistentContracts'), { variant: "error" });
                }
            } finally {
                setLoading(false);
            }
        })();
    }, [item.tankDate]);

    const checkPCI = (): boolean => {
        return !(biomass && (item.PCI.toString() === '0' || !item.PCI));
    };

    const handleSave = async () => {
        setLoading(true);
        if (network) {
            if (!item.issueDate || !item.numberBill || !item.totalBillImport
                || !item.startingPeriodDate || !item.endingPeriodDate
                || !item.gnM3 || !item.gnPCS || !item.gnKWHPCS || !item.gnKWHPCSOnly || !item.gnTF
                || !item.gnTV || !item.gnOtros || !item.importEquipmentRental
                || !item.hydrocarbonsTax || !item.gnTotal || !item.monthlyPayment
                || !item.amount || !item.unitaryPrice || !checkPCI()
                || item.issueDate.toString() === '0' || item.numberBill.toString() === '0' || item.totalBillImport.toString() === '0'
                || item.startingPeriodDate.toString() === '0' || item.endingPeriodDate.toString() === '0'
                || item.gnM3.toString() === '0' || item.gnPCS.toString() === '0' || item.gnKWHPCS.toString() === '0' || item.gnKWHPCSOnly.toString() === '0' || item.gnTF.toString() === '0'
                || item.gnTV.toString() === '0' || item.gnOtros.toString() === '0' || item.importEquipmentRental.toString() === '0'
                || item.hydrocarbonsTax.toString() === '0' || item.gnTotal.toString() === '0' || item.monthlyPayment.toString() === '0'
                || item.amount.toString() === '0' || item.unitaryPrice.toString() === '0') {
                snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
                setLoading(false);
                return;
            }

            if (!dateHandler.checkLimits(item.startingPeriodDate, item.endingPeriodDate)) {
                snackbar.enqueueSnackbar(t('messages.dateRangeError'), { variant: "error" });
                setLoading(false);
                return;
            }
            if (!(await checkBillOverlap())) {
                setLoading(false);
                return;
            }
        } else {
            if (!item.issueDate || !item.numberBill || !item.totalBillImport || !item.tankDate
                || !item.amount || !item.unitaryPrice || !checkPCI()
                || item.issueDate.toString() === '0' || item.numberBill.toString() === '0' || item.totalBillImport.toString() === '0'
                || item.tankDate.toString() === '0' || item.amount.toString() === '0' || item.unitaryPrice.toString() === '0') {
                snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
                setLoading(false);
                return;
            }
        }
        try {
            if (!registerFuelBillId) {
                setLoading(false);
                return;
            }
            await registerFuelBillGateway.update(Number.parseInt(registerFuelBillId as any), item);

            snackbar.enqueueSnackbar(t('registerFuelBill.updateSucceded', { id: Number.parseInt(registerFuelBillId as any) }), {
                variant: 'success',
            });
        } catch (e) {
            const er = e as any;
            if (er.response.data.key === 'updatingError') {
                const key = 'registerFuelBillErrorHandler.' + er.response.data.key;
                const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        }
        setLoading(false);
    };

    const checkBillOverlap = async (): Promise<boolean> => {
        const existingBills: IRegisterFuelBill[] =
            await fuelSupplyGateway.findByIdRegisterFuelBill(parseInt(fuelSupplyId));
        if (!registerFuelBillId) {
            return false;
        }
        for (let bill of existingBills) {
            if (item.startingPeriodDate && item.endingPeriodDate && bill.startingPeriodDate && bill.endingPeriodDate) {
                if (dateHandler.checkOverlap(new Date(item.startingPeriodDate),
                    new Date(item.endingPeriodDate),
                    bill.startingPeriodDate,
                    bill.endingPeriodDate) && bill.id !== parseInt(registerFuelBillId as any)) {

                    snackbar.enqueueSnackbar(t('messages.dateOverlapErrorBill', { id: bill.id }), { variant: "error" });
                    return false;
                }
            }

        }
        return true;
    };

    const formatDate = (dateString: Date) => {
        const date = new Date(dateString.toString());
        if (date.toString() === 'Invalid Date') {
            return t('common.unknown');
        }
        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();

    };

    const fuelContractInfo = (fuelContract: IFuelContract) => {
        return formatDate(fuelContract.initSupplyDate) + ' - '
            + formatDate(fuelContract.endSupplyDate);
    };

    const handleChange = (name: string, value: any) => {
        setItem({ ...item, [name]: value });
    };

    const handleIssueDateSelector = (value: any) => {
        setItem({ ...item, issueDate: value });
    };

    const handleStartingPeriodDateSelector = (value: any) => {
        setItem({ ...item, startingPeriodDate: value });
    };

    const handleEndingPeriodDateSelector = (value: any) => {
        setItem({ ...item, endingPeriodDate: value });
    };

    const tankDateSelector = (value: any) => {
        setItem({ ...item, tankDate: value });
    };

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };
    const handleCopy = () => {
        try {
            navigator.clipboard.writeText(
                `${item.gnM3.toString().replace(/\./g, ',')}
                ${item.gnPCS.toString().replace(/\./g, ',')}
                ${item.gnKWHPCS.toString().replace(/\./g, ',')}
                ${item.gnKWHPCSOnly.toString().replace(/\./g, ',')}
                ${0}
                ${item.gnTF.toString().replace(/\./g, ',')}
                ${item.gnTV.toString().replace(/\./g, ',')}
                ${item.gnOtros.toString().replace(/\./g, ',')}
                ${item.gnTotal.toString().replace(/\./g, ',')}
                ${item.startingPeriodDate ? moment(item.startingPeriodDate).format('DD/MM/YYYY') : t('common.undefinedDate')}
                ${item.endingPeriodDate ? moment(item.endingPeriodDate).format('DD/MM/YYYY') : t('common.undefinedDate')}`
            );
            snackbar.enqueueSnackbar(t('common.copySuccess'), { variant: 'success' });
        } catch (e) {
            snackbar.enqueueSnackbar(t('common.copyError'), { variant: "error" });
        }
    };

    return (
        <ScreenGlobalStructure
            buttonIcon={<SaveIcon />}
            headerIcon={DescriptionIcon}
            matchUrl={url}
            onButtonClick={handleSave}
            title={t('registerFuelBill.title')}
            clientId={clientId ? clientId : ''}
            onCopyButtonClick={handleCopy}
            loading={loading}
        >
            <RegisterFuelBillGeneral
                handleChange={handleChange}
                handleIssueDateSelector={handleIssueDateSelector}
                item={item}
                validateRequired={validateRequired}
            />
            {
                network &&
                <RegisterFuelBillNetwork
                    currency={currency}
                    handleChange={handleChange}
                    handleEndingPeriodDateSelector={handleEndingPeriodDateSelector}
                    handleStartingPeriodDateSelector={handleStartingPeriodDateSelector}
                    item={item}
                    validateRequired={validateRequired}
                    fuelContract={fuelContract}
                    fuelContractInfo={fuelContractInfo}
                />
            }
            {
                !network &&
                <RegisterFuelBillNotNetwork
                    fuelContract={fuelContract}
                    fuelContractInfo={fuelContractInfo}
                    item={item}
                    onChange={handleChange}
                    tankDateSelector={tankDateSelector}
                    validateRequired={validateRequired}
                />
            }
            {
                glpFuelBiomass &&
                <RegisterFuelBillGlFuelBiomass
                    biomass={biomass}
                    item={item}
                    currency={currency}
                    handleChange={handleChange}
                    validateRequired={validateRequired}
                />
            }
            {
                !glpFuelBiomass &&
                <RegisterFuelBillGasoilDiesel
                    currency={currency}
                    handleChange={handleChange}
                    item={item}
                    validateRequired={validateRequired}
                    volumeUnit={volumeUnit} />
            }
        </ScreenGlobalStructure>
    );
};


export default RegisterFuelBillDetail;