export interface IElectricSupply {
    id: number;
    referencia: string;
    telemedida: boolean;
    accessTypeId: number | null;
    complexId: number | null;
    establishmentId: number | null;
    providerId: number | null;
    code: string;
    invoicesFolder: string;
    emailNotification: string | null;
    startCaptureDate?: Date;
    sendEmail: boolean;
    sendClientEmail: boolean;
    startValidationsDate?: Date;
    blacklisted?: boolean;
    separatedMeterAccount: boolean;
    separatedMeterInvoiceFolder: string;
    separatedMeterStartCaptureDate: Date | undefined;
    separatedMeterStartValidationsDate: Date | undefined;
}

export interface IUpdateElectricSupply {
    id: number;
    referencia: string;
    telemedida: boolean;
    accessTypeId: number | null;
    complexId: number | null;
    establishmentId: number | null;
    providerId: number | null;
    code: string;
    invoicesFolder: string;
    emailNotification: string | null;
    startCaptureDate?: Date;
    sendEmail: boolean;
    sendClientEmail: boolean;
    startValidationsDate?: Date;
    separatedMeterAccount: boolean;
    separatedMeterInvoiceFolder: string;
    separatedMeterStartCaptureDate: Date | undefined;
    separatedMeterStartValidationsDate: Date | undefined;
}
export interface IElectricSupplyUpsert {
    id: number,
    referencia: string;
    telemedida: boolean;
    accessTypeId: number | null;
    complexId: number | null;
    establishmentId: number | null;
    providerId: number | null;
    code: string;
    invoicesFolder: string;
    emailNotification: string | null;
    startCaptureDate?: Date;
    sendEmail: boolean;
    sendClientEmail: boolean;
    startValidationsDate?: Date;
    separatedMeterAccount: boolean;
    separatedMeterInvoiceFolder: string;
    separatedMeterStartCaptureDate: Date | undefined;
    separatedMeterStartValidationsDate: Date | undefined;
}

export const initialIElectricSupplyUpsert: IElectricSupplyUpsert = {
    id: 0,
    complexId: null,
    establishmentId: null,
    referencia: '',
    telemedida: false,
    accessTypeId: null,
    providerId: null,
    code: '',
    emailNotification: null,
    invoicesFolder: '',
    startCaptureDate: undefined,
    sendEmail: true,
    sendClientEmail: true,
    separatedMeterAccount: false,
    separatedMeterInvoiceFolder: '',
    separatedMeterStartCaptureDate: undefined,
    separatedMeterStartValidationsDate: undefined,
    startValidationsDate: undefined
}

export const initialIUpdateElectricSupply: IUpdateElectricSupply = {
    referencia: '',
    telemedida: false,
    complexId: 0,
    establishmentId: 0,
    id: 0,
    providerId: 0,
    accessTypeId: 0,
    code: '',
    emailNotification: null,
    invoicesFolder: '',
    startCaptureDate: undefined,
    sendEmail: true,
    sendClientEmail: true,
    separatedMeterAccount: false,
    separatedMeterInvoiceFolder: '',
    separatedMeterStartCaptureDate: undefined,
    separatedMeterStartValidationsDate: undefined,
    startValidationsDate: undefined

}