import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { ClientSelector } from "../../components/atoms/client-selector";
import { ClientTypeSelector } from "../../components/atoms/client-type-selector";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import useIoC from "../../contexts/ioc.context";
import { StatisticsGateway } from "../../gateways/statistics.gateway";
import { useFetchController } from "../../hooks/use-fetch-controller";

export const DashboardPage: FC = () => {
  const { url } = useRouteMatch();

  const [clientTypeId, setClientTypeId] = useState<number | null>(null);

  return (
    <ScreenGlobalStructure
      headerIcon={DashboardIcon}
      title="Cuadro de mandos"
      matchUrl={url}
      loading={false}
    >
      <Box style={{ display: "flex", gap: 8, marginBottom: 16 }}>
        <FormControl margin="dense" style={{ minWidth: 220 }}>
          <InputLabel>Tipo cliente</InputLabel>
          <ClientTypeSelector
            allowAll
            value={clientTypeId}
            onChange={(e) =>
              setClientTypeId(
                (e.target.value as string) === "all"
                  ? null
                  : (e.target.value as number)
              )
            }
          />
        </FormControl>
      </Box>
      <Box display="flex" flexDirection="column" style={{ gap: 16 }}>
        <ClientMetricsTable clientTypeId={clientTypeId} metrics="today" />
        <ClientMetricsTable clientTypeId={clientTypeId} metrics="month" />
        <ClientMetricsTable clientTypeId={clientTypeId} metrics="custom" />
        <ClientMetricsTable clientTypeId={clientTypeId} metrics="custom-2" />
        <ClientMetricsTable clientTypeId={clientTypeId} metrics="custom-3" />
      </Box>
      {/* <Grid container spacing={3}>
        <Grid xs={12} lg={12} xl={6} item>
          <ClientMetricsTable metrics="today" />
        </Grid>
        <Grid xs={12} lg={12} xl={6} item>
          <ClientMetricsTable metrics="month" />
        </Grid>
        <Grid xs={12} lg={6} xl={4} item>
          <ClientMetricsTable metrics="custom" />
        </Grid>
      </Grid> */}
    </ScreenGlobalStructure>
  );
};

export const EmptyDashboardPage: FC = () => {
  const { url } = useRouteMatch();

  return (
    <ScreenGlobalStructure
      headerIcon={DashboardIcon}
      title=''
      matchUrl={url}
      loading={false}
    >
      <Box style={{ display: "flex", gap: 8, marginBottom: 16 }}>
        <FormControl margin="dense" style={{ minWidth: 220 }}>
          <h1>EBM - Enovam Business Manager</h1>
          
        </FormControl>
      </Box>
      
    </ScreenGlobalStructure>
  );
};

export type ClientMetricsTableProps = {
  metrics: "today" | "month" | "custom" | "custom-2" | "custom-3";
  clientTypeId?: number | null;
};

const ClientMetricsTable: FC<ClientMetricsTableProps> = ({
  metrics,
  clientTypeId,
}) => {
  const metricsLabel =
    metrics === "today"
      ? "HOY"
      : metrics === "month"
      ? "Mes Actual"
      : metrics === "custom"
      ? "Diciembre"
      : metrics === "custom-2"
      ? "Noviembre"
      : "Octubre";

  const showPercentDownloads = metrics === "month" || metrics === "custom";

  const from = useMemo(() => {
    switch (metrics) {
      case "today":
        return moment().startOf("day").toDate();
      case "month":
        return moment().startOf("month").startOf("day").toDate();
      case "custom":
        return new Date(2022, 11, 1);
      case "custom-2":
        return new Date(2022, 10, 1);
      case "custom-2":
        return new Date(2022, 9, 1);
      default:
        return new Date();
    }
  }, [metrics]);

  const to = useMemo(() => {
    switch (metrics) {
      case "today":
        return moment().endOf("day").toDate();
      case "month":
        return moment().endOf("month").endOf("day").toDate();
      case "custom":
        return new Date(2023, 0, 1);
      case "custom-2":
        return new Date(2022, 11, 1);
      case "custom-3":
        return new Date(2023, 10, 1);
      default:
        return new Date();
    }
  }, [metrics]);

  const statisticsGateway = useIoC(StatisticsGateway);

  const fetcher = useCallback(
    () =>
      clientTypeId
        ? statisticsGateway.getClientStats(from, to, clientTypeId)
        : statisticsGateway.getServiceTypesStats(from, to),
    [statisticsGateway, clientTypeId]
  );

  const { data: stats, loading: loadingStats } = useFetchController(fetcher, {
    autoload: true,
  });

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              style={{ backgroundColor: "#b1c904", borderTopLeftRadius: 10 }}
            >
              {metricsLabel}
            </TableCell>
            <TableCell style={{ backgroundColor: "#c9c9c9" }}>CUPS</TableCell>
            <TableCell>Desc.</TableCell>
            {showPercentDownloads && <TableCell>%</TableCell>}
            <TableCell style={{ whiteSpace: "nowrap" }}>Env. Cliente</TableCell>
            <TableCell>%</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>Env. OP</TableCell>
            <TableCell>%</TableCell>
            <TableCell style={{ whiteSpace: "nowrap" }}>Pnd. OP</TableCell>
            <TableCell>%</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingStats && (
            <TableRow>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              {showPercentDownloads && (
                <TableCell>
                  <Skeleton variant="text" />
                </TableCell>
              )}
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" />
              </TableCell>
            </TableRow>
          )}
          {!loadingStats &&
            stats?.map((x) => (
              <TableRow key={`stat_${x.id}`}>
                <TableCell>{x.name}</TableCell>
                <TableCell>{x.total_cups}</TableCell>
                <TableCell>{x.invoices_download}</TableCell>
                {showPercentDownloads && (
                  <TableCell>
                    {x.total_cups !== 0
                      ? Math.floor((x.invoices_download / x.total_cups) * 100)
                      : 100}
                    %
                  </TableCell>
                )}
                <TableCell>{x.invoices_sended_to_cli}</TableCell>
                <TableCell>
                  {x.invoices_download !== 0
                    ? Math.floor(
                        (x.invoices_sended_to_cli / x.invoices_download) * 100
                      )
                    : 100}
                  %
                </TableCell>
                <TableCell>{x.invoices_sended_to_op}</TableCell>
                <TableCell>
                  {x.invoices_download !== 0
                    ? Math.floor(
                        (x.invoices_sended_to_op / x.invoices_download) * 100
                      )
                    : 100}
                  %
                </TableCell>
                <TableCell>
                  {x.invoices_download - x.invoices_sended_to_op}
                </TableCell>
                <TableCell>
                  {x.invoices_sended_to_op !== 0
                    ? Math.floor(
                        ((x.invoices_download - x.invoices_sended_to_op) /
                          x.invoices_sended_to_op) *
                          100
                      )
                    : 0}
                  %
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

