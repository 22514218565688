import { IEstablishmentType, IUpdateEstablishmentType } from "../../gateways/establishment-type.interface";
import { FC, useState, useEffect } from "react";
import useIoC from "../../contexts/ioc.context";
import { EstablishmentTypeGateway } from "../../gateways/establishment-type.gateway";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import React from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog } from "@material-ui/core";
import FormTextField from "../../components/form-text-field";

interface IEstablishmentTypeAddDialogProps {
    open: boolean;
    item: IEstablishmentType | undefined;
    onItemUpdate: (establishmentType: IEstablishmentType) => void;
    onClose: () => void;
}

const initialEstablishmentType: IUpdateEstablishmentType = {
    name: ''
};

const EstablishmentTypeUpdateDialog: FC<IEstablishmentTypeAddDialogProps> = (props) => {

    const gateway = useIoC(EstablishmentTypeGateway);
    const [establishmentTypeData, setEstablishmentTypeData] = useState(initialEstablishmentType);
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const handleUpdate = async () => {
        if (establishmentTypeData.name === undefined || establishmentTypeData.name === '') {
            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        }
        if (!props.item) {
            return;
        }
        try {
            const establishmentType = await gateway.update(props.item.id, establishmentTypeData);
            snackbar.enqueueSnackbar(t('establishmentTypeUpdateDialog.updateSucceded', { id: establishmentType.id, name: establishmentType.name }), { variant: "success" });
            setEstablishmentTypeData(initialEstablishmentType)
            props.onItemUpdate(establishmentType);
        } catch (e) {
            const er = e as any;
            if (er.response.data.key === 'updatingError'){
                const key = 'establishmentTypeErrorHandler.' + er.response.data.key;
                const message = "validation" in er ? er.validation : t(key, {id: er.response.data.id});
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }else{
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose();
        }

    };
    const handleChange = (name: string, value: any) => {
        setEstablishmentTypeData({...establishmentTypeData, [name]: value});
    };

    const handleCancel = () => {
        setEstablishmentTypeData(initialEstablishmentType);
        props.onClose();
    };

    const validateRequired = (value: string): boolean => {
        return value !== null && value !== undefined && value !== '';
    };

    useEffect(() => {
        if (props.item) {
            setEstablishmentTypeData(props.item);
        }
    }, [props.item]);

    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }}>
            <DialogTitle>{t('establishmentTypeUpdateDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('establishmentTypeUpdateDialog.content')}</DialogContentText>
                <FormTextField
                    name='name'
                    label={t('common.name')}
                    type='text'
                    validator={validateRequired}
                    errorText={t('messages.requiredField') as string}
                    value={establishmentTypeData.name}
                    onChange={handleChange}
                    autoFocus
                    fullWidth
                    required 
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleUpdate} color="primary">{t('common.update')}</Button>
            </DialogActions>

        </Dialog>
    );
};
export default EstablishmentTypeUpdateDialog;