import React, { useContext } from "react"
import { TablePagination } from "@material-ui/core"
import { StateContext, ReducerContext } from "./context"

const FSTablePagination = () => {
    const state = useContext(StateContext);
    const dispatch = useContext(ReducerContext);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        dispatch({ type: 'setCurrentPage', payload: page });
    }

    const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
        const rowsPerPage = parseInt(event.target.value);
        dispatch({ type: 'setRowsPerPage', payload: rowsPerPage });
    }

    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}
            component="div"
            count={state.count}
            rowsPerPage={state.rowsPerPage}
            page={state.currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    )
}

export default FSTablePagination;