import React, { FC, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { makeStyles, Fab, List, ListItem, ListItemIcon, ListItemText, Grid, useTheme } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { ValidationContextGateway } from "../../gateways/validation-context.gateway";
import { initialValidContext, IValidationContext } from "../../gateways/validation-context.interfaces";
import ValidationContextForm from "./validation-context-form";
import { useNavigator } from "../../contexts/navigator.context";
import ValidationGroupTable from "../validation-group/validation-group-table";
import SwipeableViews from "react-swipeable-views";
import { IValidationGroup, initialValidationGroup } from "../../gateways/validation-group.interfaces";
import ValidationRulesTable from "../validation-rules/validation-rule-table";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingTop: theme.spacing(2),
        marginBottom: 70,
    }
}));

const ValidationContextDetail: FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const { validationContextId } = {validationContextId: null}; //useParams();
    const theme = useTheme();
    const snackbar = useSnackbar();

    const [indexView, setIndexView] = useState(0);
    const [item, setItem] = useState<IValidationContext>(initialValidContext);
    const [validationGroup, setValidationGroup] = useState<IValidationGroup>(initialValidationGroup);
    const validationContextGateway = useIoC(ValidationContextGateway);

    const drawer = useMemo(() => (
        <div>
            <List>
                <ListItem button component={Link} to={`/validations`}>
                    <ListItemIcon><VerifiedUserIcon /></ListItemIcon>
                    <ListItemText primary={t('rightNavigation.validations')} />
                </ListItem>
            </List>
        </div>
    ), []);

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('validationContextDetail.title'),
                icon: VerifiedUserIcon
            }
        });

        navigatorDispatch({
            type: 'set-right-nav',
            component: drawer
        })
    }, []);

    useEffect(() => {
        (async () => {
            if (validationContextId) {
                const validationContext = await validationContextGateway.findById(validationContextId as any);
                setItem(validationContext);
            }
        })();
    }, []);

    const handleChange = (data: IValidationContext) => {
        setItem(data);
    };

    const handleCreate = async () => {
        (async () => {
            try {
                if (!validationContextId) { return; }
                const validationContext = await validationContextGateway.update(validationContextId as any, item);
                snackbar.enqueueSnackbar(t('validationContextDetail.createSucceded', { name: validationContext.name }), { variant: "success" });
                setItem(validationContext);
            } catch (e) {
                const er = e as any;
                if (er.response.data.key === 'creatingEntityError') {
                    const key = 'sectorErrorHandler.' + er.response.data.key;
                    const message = "validation" in er ? er.validation : t(key);
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                } else {
                    const message = "validation" in er ? er.validation : t('messages.defaultError');
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                }
            }
        })();
    };

    const handleClickRules = (validationGroup: IValidationGroup) => {
        setValidationGroup(validationGroup);
        setIndexView(1);

    }

    const handleClickReturn = () => {
        setValidationGroup(initialValidationGroup);
        setIndexView(0);
    }

    return (
        <div className={classes.root}>
            <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleCreate}>
                <SaveIcon />
            </Fab>
            <div className={classes.content}>

                <ValidationContextForm
                    item={item}
                    onChange={handleChange}
                />
                <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={indexView}
                >
                    <ValidationGroupTable
                        validationContext={item}
                        validationGroups={item.validationGroups}
                        onClickRules={handleClickRules}
                    />
                    <ValidationRulesTable
                        validationGroup={validationGroup}
                        onClickReturn={handleClickReturn}
                    />
                </SwipeableViews>

            </div>

        </div>
    );
};

export default ValidationContextDetail;