import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IBotRO } from "./bots.interfaces";
import { ISupplyTypeRO } from "./supply-type.interfaces";

@inject('axios')
export class SupplyTypeGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async findAll(): Promise<ISupplyTypeRO[]> {
        const response = await this.axios.get('supply-type');
        return response.data;
    }
}
