import React, { useEffect, useImperativeHandle, useState } from "react";
import { useHistory } from "react-router";
import {
  IWaterSupplyInterface,
  IWaterSupplyInterfaceUpsert,
} from "../../../../../gateways/water-supply.interfaces";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import useIoC from "../../../../../contexts/ioc.context";
import {
  IFormBaseComponent,
  IFormBaseRefAttributes,
  IFormBaseProps,
} from "./interfaces";
import { WaterSupplyGateway } from "../../../../../gateways/water-supply.gateway";
import EstablishmentUpsertDialog from "../../../../establishment/establishment-upsert-dialog";
import ComplexUpsertDialog from "../../../../complex/complex-upsert-dialog";
import CompanyAddDialog from "../../../../companies/company-add-dialog";
import ClientAddDialog from "../../../../clients/client-add-dialog";
import WaterSupplyCompleteForm from "./supply-forms/water.supply-form";
import { IClient } from "../../../../../gateways/clients.interfaces";
import ICompany from "../../../../../gateways/company.interface";
import { IEstablishment } from "../../../../../gateways/establishment.interface";
import { IComplex } from "../../../../../gateways/complex.interfaces";
import { CompanyGateway } from "../../../../../gateways/company.gateway";
import { ComplexGateway } from "../../../../../gateways/complex.gateway";
import { ClientsGateway } from "../../../../../gateways/clients.gateway";
import { EstablishmentGateway } from "../../../../../gateways/establishment.gateway";
import WaterSupplyGoDetailDialog from "../../../../water-supply/water-supply-detail-dialog";

export const WaterComponent: IFormBaseComponent = React.forwardRef<
  IFormBaseRefAttributes,
  IFormBaseProps
>(({ operate }, ref) => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const [ loading, setLoading] = useState(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [complexes, setComplexes] = useState<IComplex[]>([]);
  const [establishments, setEstablishments] = useState<IEstablishment[]>([]);
  const [errorMissingData, setErrorMissingData] = useState(false);
  const [openAddEstablishmentDialog, setOpenAddEstablishmentDialog] =
    useState(false);
  const [openAddComplexDialog, setOpenAddComplexDialog] = useState(false);
  const [openAddClientDialog, setOpenAddClientDialog] = useState(false);
  const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);
  const [openGoDetailDialog, setOpenGoDetailDialog] = useState(false);
  const [client, setClient] = useState<IClient | null>(null);
  const [company, setCompany] = useState<ICompany | null>(null);
  const [waterSupply, setWaterSupply] = useState<IWaterSupplyInterface>({
    id: parseInt(operate.supplyId),
    referencia: operate.cups,
    accessTypeId: null,
    telemedida: false,
    complexId: undefined,
    establishmentId: undefined,
    code: "",
    emailNotification: null,
    invoicesFolder: "",
    providerId: null,
    sendEmail: false,
    sendClientEmail: false,
    blacklisted: false,
    startCaptureDate: new Date(),
    separatedMeterAccount: false,
    separatedMeterInvoiceFolder: "",
    separatedMeterStartCaptureDate: new Date(),
    separatedMeterStartValidationsDate: new Date(), 
  });

  const complex =
  complexes.find((x) => x.id === waterSupply.complexId) ?? null;

  const establishment =
    establishments.find((x) => x.id === waterSupply.establishmentId) ?? null;

  const companyGateway = useIoC(CompanyGateway);
  const complexGateway = useIoC(ComplexGateway);
  const clientsGateway = useIoC(ClientsGateway);
  const establishmentGateway =
    useIoC(EstablishmentGateway);

  const waterSupplyGateway = useIoC(WaterSupplyGateway);

  const navigateToNextWindow = () => {
    client &&
      (waterSupply.establishmentId
        ? history.push(
            `clients/${client.id}/establishments/${waterSupply.establishmentId}/waterSupply/${waterSupply.id}`
          )
        : history.push(
            `clients/${client.id}/complex/${waterSupply.complexId}/waterSupply/${waterSupply.id}`
          ));
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  const handleClickAddEstablishmentDialog = () => {
    setOpenAddEstablishmentDialog(true);
  };

  const handleCloseAddEstablishmentDialog = () => {
    setOpenAddEstablishmentDialog(false);
  };

  const handleClickAddComplexDialog = () => {
    setOpenAddComplexDialog(true);
  };

  const handleCloseAddComlexDialog = () => {
    setOpenAddComplexDialog(false);
  };

  const handleOpenAddClientDialog = () => {
    setOpenAddClientDialog(true);
  };

  const handleCloseAddClientDialog = () => {
    setOpenAddClientDialog(false);
  };

  const handleClickAddCompanyDialog = () => {
    setOpenAddCompanyDialog(true);
  };

  const handleCloseAddCompanyDialog = () => {
    setOpenAddCompanyDialog(false);
  };

  const handleCreateCompany = (item: ICompany) => {
    setCompanies([...companies, item]);
    setCompany(item);
  };

  const handleCreateComplex = (item: IComplex) => {
    setComplexes([...complexes, item]);
    setWaterSupply({
      ...waterSupply,
      complexId: item.id,
      establishmentId: undefined,
    });
  };

  const handleCreateClient = (item: IClient) => {
    setClients([...clients, item]);
    setClient(item);
  };

  const handleCreateEstablishment = (item: IEstablishment) => {
    setEstablishments([...establishments, item]);
    setWaterSupply({
      ...waterSupply,
      complexId: undefined,
      establishmentId: item.id,
    });
  };

  const createWaterSupply = async (item: IWaterSupplyInterfaceUpsert) => {
    setLoading(true);
    const waterSupply = await waterSupplyGateway.create(item);
    snackbar.enqueueSnackbar(
      t("waterSupplyCreateDialog.createSucceded", { 
        id: waterSupply.id,
        reference: waterSupply.referencia 
      }),
      { variant: "success" }
    );
    setWaterSupply(waterSupply);
    setOpenGoDetailDialog(true);
    setLoading(false);
  };

  const upsertWaterSupply = async (item: IWaterSupplyInterface) => {
    try {
      await createWaterSupply(item);
    } catch (e) {
      const er = e as any;
      const key = "waterSupplyErrorHandler." + er.response.data.key;
      if (er.response.data.key === "creatingEntityError") {
        const message = "validation" in er ? er.validation : t(key);
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else {
        const message =
          "validation" in er ? er.validation : t("messages.defaultError");
        snackbar.enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    accept: async() => {
      if (!waterSupply.referencia || (!waterSupply.complexId && !waterSupply.establishmentId)) {
        snackbar.enqueueSnackbar(t("messages.requiredFields"), {
          variant: "error",
        });
        setErrorMissingData(true);
        return;
      }
      setErrorMissingData(false);
      upsertWaterSupply(waterSupply);
    }
  }));

  useEffect(() => {
    (async () => {
      setLoading(true);
      const clients = await clientsGateway.findAll({ isMarketing: false });
      setClients(clients);
      setLoading(false);
    })();
  }, [clientsGateway]);

  useEffect(() => {
    (async () => {
      if (client) {
        setLoading(true);
        const companies = await companyGateway.findAll({ clientId: client.id });
        setCompanies(companies);
        setLoading(false);
      }
    })();
  }, [companyGateway, client]);

  useEffect(() => {
    (async () => {
      if (company) {
        setLoading(true);
        const [complexes, establishments] = await Promise.all([
          complexGateway.findAll({ companyId: company.id }),
          establishmentGateway.findAll({ companyId: company.id }),
        ]);
        setComplexes(complexes);
        setEstablishments(establishments);
        setLoading(false);
      }
    })();
  }, [establishmentGateway, complexGateway, company]);

  return (
    <>
      <WaterSupplyCompleteForm
        errorMissingData={errorMissingData}
        selectedClient={client}
        selectedCompany={company}
        selectedComplex={complex}
        selectedEstablishment={establishment}
        onSelectClient={setClient}
        onSelectCompany={setCompany}
        clients={clients}
        companies={companies}
        complexes={complexes}
        establishments={establishments}
        supply={waterSupply}
        validateRequired={validateRequired}
        onChangeSupply={setWaterSupply}
        onClickAddClientButton={handleOpenAddClientDialog}
        onClickAddCompanyButton={handleClickAddCompanyDialog}
        onClickAddComplexButton={handleClickAddComplexDialog}
        onClickAddEstablishmentButton={handleClickAddEstablishmentDialog}
      />

      <ClientAddDialog
        open={openAddClientDialog}
        onClose={handleCloseAddClientDialog}
        onClientCreated={handleCreateClient}
      />

      {client && (
        <CompanyAddDialog
          open={openAddCompanyDialog}
          clientId={client.id}
          onClose={handleCloseAddCompanyDialog}
          onItemCreated={handleCreateCompany}
          onLoading={() => loading}
        />
      )}

      {client && company && (
        <ComplexUpsertDialog
          open={openAddComplexDialog}
          clientId={client.id}
          companyId={company.id}
          item={undefined}
          onClose={handleCloseAddComlexDialog}
          onItemUpsert={handleCreateComplex}
          onLoading={() => loading}
        />
      )}

      {client && company && (
        <EstablishmentUpsertDialog
          open={openAddEstablishmentDialog}
          item={undefined}
          clientId={client.id}
          companyId={company.id}
          complexId={waterSupply?.complexId ?? undefined}
          onItemUpsert={handleCreateEstablishment}
          onLoading={()=>false}
          onClose={handleCloseAddEstablishmentDialog}
        />
      )}

      <WaterSupplyGoDetailDialog
        open={openGoDetailDialog}
        item={waterSupply}
        navigateToNextWindow={navigateToNextWindow}
        handleClose={() => setOpenGoDetailDialog(false)}
      />
    </>
  );
});
