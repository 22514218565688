import React, { FC, useState, useEffect } from "react";
import classes from "*.module.css";
import { FormControl, InputLabel, Input, FormHelperText, Select, MenuItem, makeStyles, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ISaveSupplyBot, ISaveSupplyBotAccount } from "../../gateways/supply-bots.interface";
import useIoC from "../../contexts/ioc.context";
import { BotsGateway } from "../../gateways/bots.gateway";
import { SupplyTypeGateway } from "../../gateways/supply-type.gateway";
import { ISupplyTypeRO } from "../../gateways/supply-type.interfaces";
import { IBotRO, IBotPreconfigRO } from "../../gateways/bots.interfaces";
import SupplyBotAccountForm from "./supply-bot-account.form";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    formControl: {
        marginBottom: '1rem',
        '& :last-child': {
            marginBottom: 0
        }
    },
    configRoot: {
        padding: theme.spacing(3, 2),
        marginBottom: theme.spacing(1)
    }
}));

export interface ISupplyBotFormProps {
    data: ISaveSupplyBot;
    onChange: (data: ISaveSupplyBot) => void;
}

const SupplyBotForm: FC<ISupplyBotFormProps> = ({ data, onChange }) => {
    const classes = useStyles();

    const botsGateway = useIoC(BotsGateway);
    const supplyTypeGateway = useIoC(SupplyTypeGateway);

    const [supplyTypes, setSupplyTypes] = useState<ISupplyTypeRO[]>([]);
    const [bots, setBots] = useState<IBotRO[]>([]);

    useEffect(() => {
        (async () => {
            const supplyTypes = await supplyTypeGateway.findAll();
            setSupplyTypes(supplyTypes);

            const bots = await botsGateway.findAll();
            setBots(bots);
        })();
    }, [supplyTypeGateway, botsGateway]);

    const handleChange = (prop: string) => (event: React.ChangeEvent<{ value: any }>) => onChange({ ...data, [prop]: event.target.value });
    const handleChangeAccount = (originalAccount: ISaveSupplyBotAccount) => (newAccount: ISaveSupplyBotAccount) => {
        let accounts = [...data.accounts];
        accounts.splice(accounts.indexOf(originalAccount), 1, newAccount);
        onChange({ ...data, accounts });
    }
    const handleAddAccount = () => {
        onChange({
            ...data, accounts: [...data.accounts, {
                botId: -1,
                preconfigId: -1,
                startCaptureDate: null,
                endCaptureDate: null,
            }]
        })
    }

    return (
        <div className={classes.content}>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="code-input">Código del suministro</InputLabel>
                <Input id="code-input" aria-describedby="code-helper-text" value={data.code} onChange={handleChange('code')} />
                <FormHelperText id="code-helper-text">Por ejemplo: BPTAL</FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="cups-input">CUPS</InputLabel>
                <Input id="cups-input" aria-describedby="cups-helper-text" value={data.cups} onChange={handleChange('cups')} />
                <FormHelperText id="cups-helper-text">CUPS vinculado a las facturas que se van a recuperar</FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="email-notification-input">Email de notificación</InputLabel>
                <Input id="email-notification-input" type="email" aria-describedby="email-notification-helper-text" value={data.emailNotification} onChange={handleChange('emailNotification')} />
                <FormHelperText id="cups-helper-text">Las facturas que se capturen se notificaran al mail indicado</FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="invoicesFolder-input">Carpeta facturas</InputLabel>
                <Input id="invoicesFolder-input" aria-describedby="invoicesFolder-helper-text" value={data.invoicesFolder} onChange={handleChange('invoicesFolder')} />
                <FormHelperText id="invoicesFolder-helper-text">Carpeta de descarga de facturas (onedrive)</FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel htmlFor="supplyType-input">Tipo de suministro</InputLabel>
                <Select
                    value={data.supplyTypeId}
                    onChange={handleChange('supplyTypeId')}
                    inputProps={{ name: 'supplyType-input' }}
                >
                    {
                        supplyTypes.map((x) => (
                            <MenuItem key={'supply_type_' + x.id} value={x.id}>{x.name}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data.enabled}
                            onChange={handleChange('enabled')}
                        />
                    }
                    label="Activo"
                />
            </FormControl>
            <Button onClick={handleAddAccount}>Añadir nueva cuenta</Button>
            {
                data.accounts.map(x => (
                    <SupplyBotAccountForm
                        account={x}
                        bots={bots}
                        onChange={handleChangeAccount(x)}
                    />
                ))
            }
        </div>
    )
}

export default SupplyBotForm;