import React, { FC, useState, useMemo, useEffect } from "react";
import { makeStyles, List, Box, Divider } from "@material-ui/core";
import { useParams, useRouteMatch } from "react-router-dom";
import Contact from "../../gateways/contact.interface";
import CenteredCircularProgress from "../../components/centered-circular-progress";
import AlertBox from "../../components/alert-box";
import useIoC from "../../contexts/ioc.context";
import { ContactGateway } from "../../gateways/contact.gateway";
import ContactsIcon from "@material-ui/icons/Contacts";
import { withRouter, RouteComponentProps } from "react-router";
import ContactItem from "./contact-item";
import IContact from "../../gateways/contact.interface";
import AddIcon from "@material-ui/icons/Add";
import ContactAddDialog from "./contact-add-dialog";
import ContactDeleteDialog from "./contact-delete-dialog";
import ContactUpdateDialog from "./contact-update-dialog";
import { useTranslation } from "react-i18next";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import ClientsDrawer from "../../components/clients-drawer";
import PeopleIcon from "@material-ui/icons/People";
import CompaniesDrawer from "../../components/companies-drawer";
import CompanyIcon from "@material-ui/icons/BusinessCenter";

export enum ContactEntityType {
  Client = "client",
  Profile = "profile",
  Company = "company",
  Establishments = "establishment",
  Complex = "complex",
}

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: 50,
  },
  listItem: {
    cursor: "pointer",
  },
  itemAvatarIcon: {
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

interface IContactListProps extends RouteComponentProps {
  entity: ContactEntityType;
  entityId: string;
}

const ContactList: FC<IContactListProps> = ({ entity, entityId }) => {
  const classes = useStyles();
  const { clientId } = {clientId: null}; //useParams();
  const { url } = useRouteMatch();
  const [contacts, setContacts] = useState<
    {
      contact: Contact;
      hierarchyType: "childContacts" | "actualContacts" | "parentContacts";
    }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [contact, setContact] = useState<any>();
  const contactGateway = useIoC(ContactGateway);
  const emptyList = useMemo(
    () => !loading && contacts.length === 0,
    [loading, contacts]
  );
  const { t } = useTranslation();

  const handleAddContactType = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleCreatedContact = (contact: IContact) => {
    setContacts([{contact, hierarchyType: "actualContacts"}, ...contacts]);
  };

  const handleUpdateContactFromCreateDialog = (contact: IContact) => {
    setContacts([{contact, hierarchyType: "actualContacts"}, ...contacts.filter(item => (item.contact.id !== contact.id))]);
  }

  const handleDeleteContact = (contact: IContact) => {
    const copy = contacts.filter((item) => item.contact.id !== contact.id);
    setContacts(copy);
  };

  const handleUpdateContact = (contact: IContact) => {
    let copy = contacts.slice();
    const index = copy.findIndex((value) => value.contact.id === contact.id);
    copy[index].contact = contact;
    setContacts(copy);
  };

  const handleClickItem = (contact: IContact) => {
    setOpenUpdateDialog(true);
    setContact(contact);
  };

  const handleClickDelete = (contact: IContact) => {
    setOpenDeleteDialog(true);
    setContact(contact);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const data = await contactGateway.findHierarchy({ entity, entityId });
      const contactsToPrint: {
        contact: Contact;
        hierarchyType: "childContacts" | "actualContacts" | "parentContacts";
      }[] = [];
      Object.keys(data).map((i) => {
        for (const value of data[
          i as "childContacts" | "actualContacts" | "parentContacts"
        ]) {
          contactsToPrint.push({
            contact: value,
            hierarchyType: i as
              | "childContacts"
              | "actualContacts"
              | "parentContacts",
          });
        }
      });
      setContacts(contactsToPrint);
      setLoading(false);
    })();
  }, [contactGateway]);

  return (
    <Box>
      <ScreenGlobalStructure
        buttonIcon={<AddIcon />}
        headerIcon={ContactsIcon}
        onButtonClick={handleAddContactType}
        title={t("contactList.title")}
        matchUrl={url}
        clientId={clientId ?? ""}
        loading={loading}
        drawer={
          entity === "client" ? (
            <ClientsDrawer
              matchUrl={url}
              lateralMenu={1}
              icon={
                <PeopleIcon
                  fontSize="large"
                  className={classes.itemAvatarIcon}
                />
              }
              goBack={true}
            />
          ) : (
            <CompaniesDrawer
              matchUrl={url}
              lateralMenu={1}
              icon={
                <CompanyIcon
                  fontSize="large"
                  className={classes.itemAvatarIcon}
                />
              }
              goBack={true}
            />
          )
        }
      >
        {loading ? <CenteredCircularProgress /> : null}
        {emptyList ? (
          <AlertBox variant="info">{t("common.noItems")}</AlertBox>
        ) : null}
        <List className={classes.list}>
          {contacts.map((contact, index) => {
            return (
              <div key={contact.contact.id}>
                <ContactItem
                  contact={contact.contact}
                  classes={classes}
                  handleClick={handleClickItem}
                  handleDelete={handleClickDelete}
                  isParent={contact.hierarchyType === 'parentContacts'}
                />
                {index < contacts.length - 1 ? (
                  <Divider variant="inset" component="li" />
                ) : null}
              </div>
            );
          })}
        </List>
      </ScreenGlobalStructure>
      <ContactAddDialog
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        onContactCreated={handleCreatedContact}
        entity={entity}
        entityId={entityId}
        onContactUpdated={handleUpdateContactFromCreateDialog}
      />
      <ContactDeleteDialog
        open={openDeleteDialog}
        contact={contact}
        onClose={handleCloseDeleteDialog}
        onContactDeleted={handleDeleteContact}
      />
      <ContactUpdateDialog
        open={openUpdateDialog}
        contact={contact}
        onClose={handleCloseUpdateDialog}
        onContactUpdated={handleUpdateContact}
        entity={entity}
        entityId={entityId}
      />
    </Box>
  );
};

export default withRouter(ContactList);
