export interface IUpdatePowerDetail {
    consumedPower: number;
    consumedPowerSecondPeriod?: number;
    powerRate: number;
    secondaryPowerRate?: number;
    discount: number;
    hiredPower: number;
    registerElectricBillId: number;
}

export interface IPowerDetail {
    id: number;
    consumedPower: number;
    consumedPowerSecondPeriod?: number;
    powerRate: number;
    secondaryPowerRate?: number;
    discount: number;
    hiredPower: number;
    registerElectricBillId: number;
}

export const initialIPowerDetail: IPowerDetail[] = [
    { consumedPowerSecondPeriod: 0, secondaryPowerRate: 0, consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, id: 0, powerRate: 0 },
    { consumedPowerSecondPeriod: 0, secondaryPowerRate: 0, consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, id: 0, powerRate: 0 },
    { consumedPowerSecondPeriod: 0, secondaryPowerRate: 0, consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, id: 0, powerRate: 0 },
    { consumedPowerSecondPeriod: 0, secondaryPowerRate: 0, consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, id: 0, powerRate: 0 },
    { consumedPowerSecondPeriod: 0, secondaryPowerRate: 0, consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, id: 0, powerRate: 0 },
    { consumedPowerSecondPeriod: 0, secondaryPowerRate: 0, consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, id: 0, powerRate: 0 },]

export const initialICreatePowerDetail: ICreatePowerDetail[] = [
    { consumedPowerSecondPeriod: 0, secondaryPowerRate: 0, consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, powerRate: 0 },
    { consumedPowerSecondPeriod: 0, secondaryPowerRate: 0, consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, powerRate: 0 },
    { consumedPowerSecondPeriod: 0, secondaryPowerRate: 0, consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, powerRate: 0 },
    { consumedPowerSecondPeriod: 0, secondaryPowerRate: 0, consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, powerRate: 0 },
    { consumedPowerSecondPeriod: 0, secondaryPowerRate: 0, consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, powerRate: 0 },
    { consumedPowerSecondPeriod: 0, secondaryPowerRate: 0, consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, powerRate: 0 },]

export interface ICreatePowerDetail {
    consumedPower: number;
    consumedPowerSecondPeriod?: number;
    powerRate: number;
    secondaryPowerRate?: number;
    discount: number;
    hiredPower: number;
    registerElectricBillId: number;
}