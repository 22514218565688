import React, { useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  Fab,
  Box,
  Typography,
} from "@material-ui/core";
import {
  IFuelContract,
  IFuelContractAccountLogs,
  IFuelContractUpdate,
  initialIFuelContract,
} from "../../gateways/fuel-contract.interfaces";
import { useParams, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IProviders } from "../../gateways/providers.interface";
import { IFuelSupplyRate } from "../../gateways/fuel-supply-rates.interface";
import { useSnackbar } from "notistack";
import { FuelContractGateway } from "../../gateways/fuel-contract.gateway";
import useIoC from "../../contexts/ioc.context";
import { datesHandler } from "../../utils/dates-handle";
import SaveIcon from "@material-ui/icons/Save";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { ProvidersGateway } from "../../gateways/providers.gateway";
import { FuelSupplyRatesGateway } from "../../gateways/fuel-supply-rates.gateway";
import { ProviderType } from "../../gateways/provider-types.interface";
import { SupplyType } from "../../gateways/supply-type.interfaces";
import { FuelSupplyGateway } from "../../gateways/fuel-supply.gateway";
import moment from "moment";
import FuelContractForm from "./fuel-contract-form";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import ProvidersUpsertDialog from "../providers/providers-upsert-dialog";
import ContractDrawer from "../../components/contract-drawer";
import SupplyConnectionBotDialog from "../supply-connection-bot/supply-connection-bot-dialog";
import {
  ISupplyConnectionBot,
  initialSupplyConnectBot,
} from "../../gateways/supply-connection-bot.interface";
import { SupplyConnectionBotGateway } from "../../gateways/supply-connection-bot.gateway";
import { useUser } from "../../contexts/user.context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    itemAvatarIcon: {
      backgroundColor: "#e0e0e0",
      borderRadius: "50%",
      padding: theme.spacing(1),
    },
    botText: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  })
);

interface IFuelContractRouteParams {
  [x: string]: string | undefined;
  fuelSupplyId: string;
  fuelContractId: string;
  clientId: string;
}

const FuelContractDetail = () => {
  const { fuelSupplyId } = useParams<IFuelContractRouteParams>();
  const { fuelContractId } = useParams<IFuelContractRouteParams>();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const dateHandler = new datesHandler();
  const classes = useStyles();
  const { clientId } = useParams<IFuelContractRouteParams>();
  const { url } = useRouteMatch();
  const { update: updateBreadCrumbName } = useBreadCrumbName();

  const [ loading, setLoading] = useState(false);
  const [item, setItem] = useState<IFuelContract>(initialIFuelContract);
  const [accountLogs, setAccountLogs] = useState<IFuelContractAccountLogs[]>([])
  const [providers, setProviders] = useState<IProviders[]>([]);
  const [fuelSupplyRates, setFuelSupplyRates] = useState<IFuelSupplyRate[]>([]);
  const [firstIteration, setFirstIteration] = useState<boolean>(true);
  const [openSupplyConnectionBotDialog, setOpenSupplyConnectionBotDialog] =
    useState(false);
  const [supplyConnectionBot, setSupplyConnectionBot] =
    useState<ISupplyConnectionBot>(initialSupplyConnectBot);

  const fuelSupplyGateway = useIoC(FuelSupplyGateway);
  const fuelContractGateway = useIoC(FuelContractGateway);
  const providerGateway = useIoC(ProvidersGateway);
  const fuelSupplyRatesGateway = useIoC(
    FuelSupplyRatesGateway
  );

  const [openProviderAddDialog, setOpenProviderAddDialog] = useState(false);
  const supplyConnectionBotGateway = useIoC(
    SupplyConnectionBotGateway
  );

  const [{ user }] = useUser();

  const handleOpenBotDialog = () => {
    setOpenSupplyConnectionBotDialog(true);
  };

  const handleCloseSupplyConnectionBotDialog = () => {
    setOpenSupplyConnectionBotDialog(false);
  };

  const handleItem = (item: ISupplyConnectionBot) => {
    setSupplyConnectionBot(item);
  };

  const handleClickAddProviderDialog = () => {
    setOpenProviderAddDialog(true);
  };

  const handleCloseAddProviderDialog = () => {
    setOpenProviderAddDialog(false);
  };

  const handleCreateNewProvider = (item: IProviders, isNew: boolean) => {
    setProviders([...providers, item]);
  };

  const updateFuelContract = async (item:IFuelContract) => {
    const { accountNumber, accountNumber_ebmUser, accountNumber_date, ...rest } = item;
    if (!fuelSupplyId || !fuelContractId) return;
    setLoading(true);
    const fuelContract = await fuelContractGateway.update(fuelContractId, {
      ...rest,
      fuelSupplyId: Number.parseInt(fuelSupplyId),
    });

    if(accountNumber !== null && user){
      // await electricContractGateway.addAccountNumber(item.id, item.accountNumber, user)
      await fuelContractGateway.addAccountNumber(fuelContract.id, accountNumber, user)
      
      const reloadAccountLogs = await fuelContractGateway.accountHistoryLogs(fuelContract.id)
      setAccountLogs(reloadAccountLogs)
    }

    snackbar.enqueueSnackbar(
      t("fuelContractDetail.updateSucceded", {
        id: fuelContract.id,
        reference: fuelContract.signContractDate,
      }),
      { variant: "success" }
    );
    setLoading(false);
  };

  const handleSave = async () => {
    // if (!item.term || !item.signContractDate || !item.providerId
    //     || !item.modality || !item.initSupplyDate || !item.duration
    //     || !item.endSupplyDate || !item.fuelSupplyRateId || !item.cancelPriorNotice) {
    //     snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
    //     return;
    // }

    if (!dateHandler.checkLimits(item.initSupplyDate, item.endSupplyDate)) {
      snackbar.enqueueSnackbar(t("messages.dateRangeError"), {
        variant: "error",
      });
      return;
    }
    setLoading(true);
    const existingContracts: IFuelContract[] =
      await fuelContractGateway.findAll({ fuelSupplyId });
    for (let i: number = 0; i < existingContracts.length; i++) {
      if (
        dateHandler.checkOverlap(
          new Date(item.initSupplyDate),
          new Date(item.endSupplyDate),
          existingContracts[i].initSupplyDate,
          existingContracts[i].endSupplyDate
        ) &&
        fuelContractId &&
        existingContracts[i].id !== parseInt(fuelContractId)
      ) {
        snackbar.enqueueSnackbar(
          t("messages.dateOverlapErrorContract", {
            id: existingContracts[i].id,
          }),
          { variant: "error" }
        );
        setLoading(false);
        return;
      }
    }
    setLoading(false);
    updateFuelContract(item);
  };

  const handleChange = (name: string, value: any) => {
    setItem({ ...item, [name]: value });
  };

  const handleChangeModality = (name: string, value: any) => {
    if (value !== "2") {
      setItem({
        ...item,
        [name]: value,
        variableA: 0,
        variableB: 0,
        variableC: 0,
      });
    } else {
      setItem({ ...item, [name]: value });
    }
  };

  const handleSignContractDateChange = (value: any) => {
    setItem({ ...item, signContractDate: value });
  };

  const handleInitSupplyDateChange = (value: any) => {
    setItem({ ...item, initSupplyDate: value });
    updateBreadCrumbName(
      "fuelContract",
      (value
        ? `${moment(value).format("DD/MM/YYYY")}`
        : t("common.undefinedDate")) +
        " - " +
        (item.endSupplyDate
          ? `${moment(item.endSupplyDate).format("DD/MM/YYYY")}`
          : t("common.undefinedDate"))
    );
  };

  const handleEndSupplyDateChange = (value: any) => {
    setItem({ ...item, endSupplyDate: value });
    updateBreadCrumbName(
      "fuelContract",
      (item.initSupplyDate
        ? `${moment(item.initSupplyDate).format("DD/MM/YYYY")}`
        : t("common.undefinedDate")) +
        " - " +
        (value
          ? `${moment(value).format("DD/MM/YYYY")}`
          : t("common.undefinedDate"))
    );
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      setItem(initialIFuelContract);
      const fuelSupply = fuelSupplyId
        ? await fuelSupplyGateway.findById(Number.parseInt(fuelSupplyId))
        : null;

      const providers = await providerGateway.findWithFilters({
        providerTypeId: ProviderType.Comercializadora.toString(),
        supplyTypeId: SupplyType.Combustible.toString(),
        accessTypeId: fuelSupply?.accessTypeId.toString(),
      });

      setProviders(providers);

      const fuelSupplyRates = await fuelSupplyRatesGateway.findAll();
      setFuelSupplyRates(fuelSupplyRates);
      
      if (!fuelContractId) {
        setLoading(false);
        return;
      }
      const fuelContract = await fuelContractGateway.findById(fuelContractId);
      setItem(fuelContract);

      const contractAccountLogs = await fuelContractGateway.accountHistoryLogs(fuelContract.id)
      setAccountLogs(contractAccountLogs)

      const initDate = fuelContract.initSupplyDate
        ? new Date(fuelContract.initSupplyDate.toString())
        : undefined;
      const endDate = fuelContract.endSupplyDate
        ? new Date(fuelContract.endSupplyDate.toString())
        : undefined;

      updateBreadCrumbName(
        "fuelContract",
        (initDate
          ? `${moment(initDate).format("DD/MM/YYYY")}`
          : t("common.undefinedDate")) +
          " - " +
          (endDate
            ? `${moment(endDate).format("DD/MM/YYYY")}`
            : t("common.undefinedDate"))
      );

      const supplyConnectionBot =
        await supplyConnectionBotGateway.findByFuelContract(fuelContract.id);
      setSupplyConnectionBot(supplyConnectionBot);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (firstIteration) {
        setFirstIteration(false);
      } else if (
        !dateHandler.checkLimits(item.initSupplyDate, item.endSupplyDate)
      ) {
        snackbar.enqueueSnackbar(t("messages.dateRangeError"), {
          variant: "error",
        });
        setLoading(false);
        return;
      } else if (fuelSupplyId) {
        const existingContracts: IFuelContract[] =
          await fuelSupplyGateway.findByIdFuelContract(parseInt(fuelSupplyId));
        for (let i: number = 0; i < existingContracts.length; i++) {
          if (
            dateHandler.checkOverlap(
              new Date(item.initSupplyDate),
              new Date(item.endSupplyDate),
              existingContracts[i].initSupplyDate,
              existingContracts[i].endSupplyDate
            ) &&
            fuelContractId &&
            existingContracts[i].id !== parseInt(fuelContractId)
          ) {
            snackbar.enqueueSnackbar(
              t("messages.dateOverlapErrorContract", {
                id: existingContracts[i].id,
              }),
              { variant: "error" }
            );
            setLoading(false);
            return;
          }
        }
      }

      setItem({
        ...item,
        duration: Math.ceil(
          moment(item.endSupplyDate).diff(item.initSupplyDate, "month", true)
        ),
      });
      setLoading(false);
    })();
  }, [item.initSupplyDate, item.endSupplyDate]);

  return (
    <Box>
      {supplyConnectionBot ? (
        <Typography className={classes.botText}>
          {t("electricContractDetail.botConnected", {
            name: supplyConnectionBot.botPreconfig.alias,
          })}
        </Typography>
      ) : null}
      <ScreenGlobalStructure
        buttonIcon={<SaveIcon />}
        headerIcon={InsertDriveFileIcon}
        matchUrl={url}
        onButtonClick={handleSave}
        title={t("electricContractDetail.title")}
        clientId={clientId ? clientId : ""}
        loading={loading}
        drawer={
          <ContractDrawer
            icon={
              <InsertDriveFileIcon
                fontSize="large"
                className={classes.itemAvatarIcon}
              />
            }
            onOpenBotDialog={handleOpenBotDialog}
          />
        }
      >
        <FuelContractForm
          fuelSupplyRates={fuelSupplyRates}
          item={item}
          accountLogs={accountLogs}
          showAccountLogs={true}
          onChange={handleChange}
          onChangeModality={handleChangeModality}
          onEndSupplyDateChange={handleEndSupplyDateChange}
          onInitSupplyDateChange={handleInitSupplyDateChange}
          onSignContractDateChange={handleSignContractDateChange}
          providers={providers}
          validateRequired={validateRequired}
          onClickAddProvider={handleClickAddProviderDialog}
        />
      </ScreenGlobalStructure>
      <ProvidersUpsertDialog
        open={openProviderAddDialog}
        item={undefined}
        onClose={handleCloseAddProviderDialog}
        onItemUpsert={handleCreateNewProvider}
      />
      <SupplyConnectionBotDialog
        open={openSupplyConnectionBotDialog}
        item={supplyConnectionBot}
        electricContractId={undefined}
        fuelContractId={item.id.toString()}
        onItem={handleItem}
        onClose={handleCloseSupplyConnectionBotDialog}
      />
    </Box>
  );
};

export default FuelContractDetail;
