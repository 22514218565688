import { IEstablishmentType } from "../../gateways/establishment-type.interface";
import { FC, useState } from "react";
import useIoC from "../../contexts/ioc.context";
import { EstablishmentTypeGateway } from "../../gateways/establishment-type.gateway";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import React from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog } from "@material-ui/core";
import FormTextField from "../../components/form-text-field";

interface IEstablishmentTypeAddDialogProps {
    open: boolean;
    onCreated: (volumeUnit: IEstablishmentType) => void;
    onClose: () => void;
}

const initialEstablishmentType: IEstablishmentType = {
    id: 0,
    name: ''
};

const EstablishmentTypeAddDialog: FC<IEstablishmentTypeAddDialogProps> = (props) => {

    const gateway = useIoC(EstablishmentTypeGateway);
    const [establishmentTypeData, setEstablishmentTypeData] = useState(initialEstablishmentType);
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const handleCreate = async () => {
        if (establishmentTypeData.name === undefined || establishmentTypeData.name === '') {
            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        }

        try {
            const establishmentType = await gateway.create(establishmentTypeData);
            snackbar.enqueueSnackbar(t('establishmentTypeAddDialog.createSucceded', { id: establishmentType.id, name: establishmentType.name }), { variant: "success" });
            setEstablishmentTypeData(initialEstablishmentType)
            props.onCreated(establishmentType);
        } catch (e) {
            if((e as any).response.data.key === 'creatingEntityError'){
                const key = 'establishmentTypeErrorHandler.' + (e as any).response.data.key;
                const message = "validation" in (e as any) ? (e as any).validation : t(key);
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }else{
                const message = "validation" in (e as any) ? (e as any).validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose();
        }

    };
    const handleChange = (name: string, value: any) => {
        setEstablishmentTypeData({...establishmentTypeData, [name]: value});
    };

    const handleCancel = () => {
        setEstablishmentTypeData(initialEstablishmentType);
        props.onClose();
    };

    const validateRequired = (value: string): boolean => {
        return value !== null && value !== undefined && value !== '';
    };

    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }}>
            <DialogTitle>{t('establishmentTypeAddDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('establishmentTypeAddDialog.content')}</DialogContentText>
                <FormTextField
                    name='name'
                    label={t('common.name')}
                    type='text'
                    validator={validateRequired}
                    errorText={t('messages.requiredField') as string}
                    value={establishmentTypeData.name}
                    onChange={handleChange}
                    autoFocus
                    fullWidth
                    required 
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleCreate} color="primary">{t('common.create')}</Button>
            </DialogActions>

        </Dialog>
    );
};
export default EstablishmentTypeAddDialog;