import { IVolumeUnit } from "../../gateways/volume-unit.interface";
import { FC, useState } from "react";
import { VolumeUnitGateway } from "../../gateways/volume-units.gateway";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React from "react";
import FormTextField from "../../components/form-text-field";

interface IVolumeUnitAddDialogProps {
    open: boolean;
    handleCreated: (volumeUnit: IVolumeUnit) => void;
    handleClose: () => void;
}

const initialVolumeUnit: IVolumeUnit = {
    id: 0,
    name: '',
    conversionValue: 0.0
};

const VolumeUnitAddDialog: FC<IVolumeUnitAddDialogProps> = (props) => {

    const gateway = useIoC(VolumeUnitGateway);
    const [volumeUnitData, setVolumeUnitData] = useState(initialVolumeUnit);
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const handleCreate = async () => {
        if (volumeUnitData.name === undefined || volumeUnitData.name === '') {
            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        }

        try {
            const volumeUnit = await gateway.create(volumeUnitData);
            snackbar.enqueueSnackbar(t('volumeUnitAddDialog.createSucceded', { id: volumeUnit.id }), { variant: "success" });
            setVolumeUnitData(initialVolumeUnit)
            props.handleCreated(volumeUnit);
        } catch (e) {
            const er = e as any;
            if (er.response.data.key === 'creatingEntityError') {
                const key = 'volumeUnitsErrorHandler.' + er.response.data.key;
                const message = "validation" in er ? er.validation : t(key);
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.handleClose();
        }

    };
    const handleChange = (name: string, value: any) => {
        setVolumeUnitData({ ...volumeUnitData, [name]: value });
    };

    const handleCancel = () => {
        setVolumeUnitData(initialVolumeUnit);
        props.handleClose();
    };

    const validateRequired = (value: string | number): boolean => {
        return value !== null && value !== undefined && value !== '' && value !== 0;
    };

    return (
        <Dialog open={props.open} onClose={() => { props.handleClose() }}>
            <DialogTitle>{t('volumeUnitAddDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('volumeUnitAddDialog.content')}</DialogContentText>
                <FormTextField
                    name='name'
                    label={t('common.name') as string}
                    type='text'
                    validator={validateRequired}
                    errorText={t('messages.requiredField') as string}
                    value={volumeUnitData.name}
                    onChange={handleChange}
                    autoFocus
                    fullWidth
                    required
                />

                <FormTextField
                    name='conversionValue'
                    label={t('common.conversionValue') as string}
                    type='number'
                    value={volumeUnitData.conversionValue}
                    onChange={handleChange}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleCreate} color="primary">{t('common.create')}</Button>
            </DialogActions>

        </Dialog>
    );
};

export default VolumeUnitAddDialog;