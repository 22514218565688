
import  { IComplex }  from "../../gateways/complex.interfaces";
import { createContext, Dispatch } from "react";

export type StateType = { complex: IComplex | null };
export type ActionType = { type: 'update-complex', payload: IComplex };

export const ComplexReducer = (state: StateType, action: ActionType) => {
    switch (action.type) {
        case 'update-complex':
            return { ...state, complex: action.payload };
    }
    return state;
};

export const ComplexContext = createContext<[StateType, Dispatch<ActionType>]>([
    { complex: null },
    (state) => state,
]);
