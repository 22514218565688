import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IProviders } from "../../gateways/providers.interface";
import useIoC from "../../contexts/ioc.context";
import { ProvidersGateway } from "../../gateways/providers.gateway";

export interface IProvidersDeleteDialogProps {
    open: boolean,
    item: IProviders | undefined,
    handleClose: () => void,
    handleDeleteProvider: (item: IProviders) => void,
    eraseDisplay: (item: IProviders) => void,
}

const ProviderDeleteDialog: FC<IProvidersDeleteDialogProps> = ({ open, item, handleClose, handleDeleteProvider, eraseDisplay }) => {
    const [componentDisabled, setComponentDisabled] = useState(false);
    const providerGateway = useIoC(ProvidersGateway);
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const handleDelete = () => {
        if (item === undefined || item.id === 0) {
          return;
        }
        setComponentDisabled(true);
        (async () => {
          try {
            await providerGateway.delete(item.id);
            snackbar.enqueueSnackbar(t('providerDeleteDialog.deleteSucceded', { id: item.id, name: item.alias }), { variant: "success" });
            handleDeleteProvider(item);
            eraseDisplay(item);
          } catch (e) {
            const er = e as any;
            if (er.response.data.key === 'deletingError'){
              const key = 'providersErrorHandler.' + er.response.data.key; 
              const message = "validation" in er ? er.validation : t(key, {id: er.response.data.id});
              snackbar.enqueueSnackbar(message, { variant: "error" });
            }else{
              const message = "validation" in er ? er.validation : t('messages.defaultError');
              snackbar.enqueueSnackbar(message, { variant: "error" });
            }
          } finally {
            setComponentDisabled(false);
            handleClose();
          }
        })();
      };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('providerTypeDeleteDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('providerTypeDeleteDialog.content')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={componentDisabled}>{t('common.cancel')}</Button>
                <Button onClick={handleDelete} color="primary" disabled={componentDisabled}>{t('common.accept')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProviderDeleteDialog;