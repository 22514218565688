
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
  } from "@material-ui/core";
  import React, { FC, useEffect, useState } from "react";
  import config from "../../config";
  import { Coordinate } from "../../gateways/coordinates.interfaces";
  import useIoC from "../../contexts/ioc.context";
  import CoordinatesGateway from "../../gateways/coordinates.gateway";
  import PlaceAutocomplete from "../../components/atoms/place-autocomplete";
  import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
  
  export type CoordinatesSelectorMapDialogProps = {
    initialAddress?: PlaceInfo | null;
    open: boolean;
    onClose: () => void;
    onSelect: () => void;
    handleSetDireccion: (name: string, value: any) => void;
    handleSetCoordinates: (name: string, value: any) => void;
    handleSetCoordinatesData: (value: Coordinate) => void
  };
  
  export type PlaceInfo = {
    address: string;
    coordinates: {
      latitude: any;
      longitude: any;
    };
  };
  
  const containerStyle = {
    width: "100%",
    height: "100%",
    minHeight: "240px",
  };
  
  export const EstablishmentCoordinatesSelectorMapDialog: FC<
    CoordinatesSelectorMapDialogProps
  > = ({ initialAddress, open, onClose, onSelect, handleSetDireccion, handleSetCoordinates, handleSetCoordinatesData }) => {
    const { isLoaded } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: config.REACT_APP_GOOGLE_API_KEY,
    });
  
    const coordinatesGateway = useIoC(CoordinatesGateway);
    const [tempPoint, setTempPoint] = useState<PlaceInfo | null>( initialAddress ?? null );

    const defaultAdress = {
      lat: 39.59630865176704,
      lng: 2.6565865248007134
    }

    useEffect(() => {
      if(initialAddress)
      setMapMarkerPosition({
          lat: parseFloat(tempPoint?.coordinates.latitude),
          lng: parseFloat(tempPoint?.coordinates.longitude),
        }) 
    
    }, [])
    
    
    const [mapMarkerPosition, setMapMarkerPosition] = useState(defaultAdress);

    const handleMapClick = async (e: google.maps.MapMouseEvent) => {
      if (!e.latLng) return;
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      const result = await coordinatesGateway.getAddressFromCoordinates(lat, lng);

      if (result) {
        setTempPoint({
          address: result.address,
          coordinates: {
            latitude: lat,
            longitude: lng,
          },
        });
      }

      setMapMarkerPosition({
        lat,
        lng
      })
    };
  
    const handleAutocomplete = async (value: string | null) => {
      if (value === null || value.length === 0) {
        setTempPoint(null);
        return;
      }
  
      const coords = await coordinatesGateway.geocode(value);
  
      if (!coords?.geometry?.location) {
        setTempPoint(null);
        return;
      }
  
      setTempPoint({
        address: value,
        coordinates: {
          latitude: coords.geometry.location.lat,
          longitude: coords.geometry.location.lng,
        },
      });

      setMapMarkerPosition({
        lat: coords.geometry.location.lat,
        lng: coords.geometry.location.lng,
      })
    };

      
    const handleSelect = async () => {
      if (!tempPoint) return;
  
      if (
        window.confirm(
          `¿Estas seguro de que quieres completar la tarea con los siguientes datos de dirección?
   - Dirección: ${tempPoint.address}
   - Coordenadas: Latitud (${tempPoint.coordinates.latitude}), Longitud (${tempPoint.coordinates.longitude})`
        )
      ) {
        onSelect();


        handleSetCoordinates( "coordinates", `${tempPoint.coordinates.latitude}, ${tempPoint.coordinates.longitude}`)
        handleSetDireccion( "address", tempPoint.address)

         handleSetCoordinatesData({
          latitude: tempPoint.coordinates.latitude,
          longitude: tempPoint.coordinates.longitude,
        })
      }
    };
  
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Selector de direcciones</DialogTitle>
        <DialogContent>
          <Box mb={1}>
            <PlaceAutocomplete
              label="Dirección"
              onChange={handleAutocomplete}
              value={tempPoint?.address ?? null}
            />
          </Box>
          <Box style={{ height: "60vh" }}>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={16}
                center={mapMarkerPosition}
                onClick={handleMapClick}
              >
                {tempPoint && (
                  <Marker position={mapMarkerPosition} />
                )}
              </GoogleMap>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button color="primary" onClick={handleSelect} disabled={!tempPoint}>
            Seleccionar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  declare global {
    interface Window {
      google: any;
    }
  }