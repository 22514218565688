export interface IRepositoryDefinition {
    id: number;
    name: string;
    description: string;
    slug: string;
    repositoryDefinitionTypeEntityId: number;
}

export interface IRepositoryDefinitionRelations {
    id: number;
    name: string;
    description: string;
    slug: string;
    repositoryDefinitionTypeEntityId: number;
    properties: IProperties[];
    groupTypes: IGroupTypes[];
    parameters: IRepositoryParameter[];
    criteria: IRepositoryDefinitionCriteria;
}

export interface ICreateRepositoryDefinition {
    name: string;
    description: string;
    slug: string;
    repositoryDefinitionTypeEntityId: number;
    properties: ICreateProperties[];
    groupTypes: ICreateGroupTypes[];
    parameters: ICreateRepositoryParameter[];
    criteria: ICreateRepositoryDefinitionCriteria;
}

export interface IUpdateRepositoryDefinition {
    name: string;
    description: string;
    slug: string;
    repositoryDefinitionTypeEntityId: number;
    properties: IUpdateProperties[];
    groupTypes: IUpdateGroupTypes[];
    parameters: IUpdateRepositoryParameter[];
    criteria: IUpdateRepositoryDefinitionCriteria;
}

export interface IUpdateRepositoryDefinitionCriteria {
    queryBase: string;
    repositoryDefinitionPropertyEntityId: number;
    properties: ICreateProperties;
    sortAscDefault: boolean;
}

export interface IProperties {
    id: number;
    name: string;
    valueTypeId: number;
}

export interface IRepositoryDefinitionProperty {
    id: number;
    name: string;
    valueTypeId: number;
}

export interface ICreateProperties {
    name: string;
    valueTypeId: number;
}

export interface IUpdateProperties {
    name: string;
    valueTypeId: number;
}

export interface IGroupTypes {
    id: number;
    sourceProperty: string;
    groupTypeId: string;
}

export interface ICreateGroupTypes {
    sourceProperty: string;
    groupTypeId: string;
}

export interface IUpdateGroupTypes {
    sourceProperty: string;
    groupTypeId: string;
}

export interface ICreateRepositoryDefinitionCriteria {
    queryBase: string;
    repositoryDefinitionPropertyEntityId: number;
    sortAscDefault: boolean;
}

export interface IRepositoryDefinitionCriteria {
    id: number
    queryBase: string;
    repositoryDefinitionPropertyEntityId: number;
    sortAscDefault: boolean;
}


export interface IRepositoryParameter {
    id: number;
    name: string;
}

export interface ICreateRepositoryParameter {
    name: string;
}

export interface IUpdateRepositoryParameter {
    name: string;
}

export const initialRepositoryDefinitionCriteria: IRepositoryDefinitionCriteria = {
    id: 0,
    queryBase: '',
    repositoryDefinitionPropertyEntityId: 0,
    sortAscDefault: false
}

export const initialRepositoryDefinitionRelations: IRepositoryDefinitionRelations = {
    id: 0,
    name: '',
    description: '',
    slug: '',
    repositoryDefinitionTypeEntityId: 0,
    properties: [],
    groupTypes: [],
    parameters: [],
    criteria: initialRepositoryDefinitionCriteria
}