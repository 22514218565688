import React, { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ClientPeekElectricSupplyForm from "./client-peek-form-electric-supply";
import ClientPeekFuelSupplyForm from "./client-peek-form-fuel-supply";
import useIoC from "../../contexts/ioc.context";
import { EstablishmentGateway } from "../../gateways/establishment.gateway";
import { IEstablishment } from "../../gateways/establishment.interface";
import { makeStyles, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { TreeItem } from "@material-ui/lab";
import ClientPeekWaterSupplyForm from "./client-peek-form-water-supply";

const useStyles = makeStyles(() => ({
    item: {
        padding: 0,
        margin: 0,
        fontWeight: 'normal'
    }
}));

interface IClientPeekEstablishmentFormProps {
    complexId?: string;
    companyId?: string;
    clientId: string;
}


const ClientPeekEstablishmentForm: FC<IClientPeekEstablishmentFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [data, setData] = useState<IEstablishment[]>([]);
    const establishmentGateway = useIoC(EstablishmentGateway);
    const history = useHistory();
    let nodeTreeId = `${props.clientId}${props.companyId ? `/company/${props.companyId}` : ''}${props.complexId ? `/complex/${props.complexId}` : ''}/establishment/`;

    const computeUrl = (item: IEstablishment): string => {
        if (props.companyId && props.complexId) {
            return (`${props.clientId}/companies/${props.companyId}/complex/${props.complexId}/establishments/${item.id}`);
        } else if (props.complexId) {
            return (`${props.clientId}/complex/${item.id}/establishments/${item.id}`);
        } else if (props.companyId) {
            return (`${props.clientId}/companies/${props.companyId}/establishments/${item.id}`);
        }
        else {
            return (`${props.clientId}/establishments/${item.id}`);
        }
    }

    useEffect(() => {
        (async () => {
            if (props.complexId) {
                const complexId = parseInt(props.complexId, 10);
                const data = await establishmentGateway.findAll({ complexId });
                setData(data)
            } else if (props.companyId) {
                const companyId = parseInt(props.companyId, 10);
                const data = await establishmentGateway.findAll({ companyId });
                setData(data)

            } else {
                const data = await establishmentGateway.findByClientId({
                    clientId: Number.parseInt(props.clientId)
                });
                setData(data)
            }
        })()

    }, [establishmentGateway])

    const handleStopPropagation = useCallback( (event: React.MouseEvent) => {
        event.stopPropagation();               
    }, [])

    return (
        <Box>
            {
                data.length > 0 &&
                <TreeItem nodeId={nodeTreeId} label={t('rightNavigation.establishments')}>
                    {
                        data.map((item) => {
                            return (
                            <TreeItem nodeId={nodeTreeId + item.id.toString()} label={<a onClick={handleStopPropagation} href={computeUrl(item)} target="_blank">{item.name}</a>} >
                                    <ClientPeekElectricSupplyForm
                                        establishmentId={item.id.toString()}
                                        currentUrl={computeUrl(item)}
                                    />
                                    <ClientPeekFuelSupplyForm
                                        establishmentId={item.id.toString()}
                                        currentUrl={computeUrl(item)}
                                    />
                                    <ClientPeekWaterSupplyForm
                                        establishmentId={item.id.toString()}
                                        currentUrl={computeUrl(item)}
                                    />
                                </TreeItem>
                            )
                        })
                    }
                </TreeItem>
            }
        </Box>
    );
};

export default ClientPeekEstablishmentForm;