import { Grid, TextField } from "@material-ui/core";
import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import SelectorField from "../../components/selector-field";
import IContactType from "../../gateways/contact-types.interfaces";
import IContact from "../../gateways/contact.interface";
interface IContactForm {
    item: IContact,
    handleChange: (name: string, value: any) => void,
    contactTypes: IContactType[],
    clickContactTypeAddButton: () => void,
    propagateError: boolean
}
const ContactForm: FC<IContactForm> = ({item, handleChange, contactTypes, clickContactTypeAddButton, propagateError})=>{
    const { t } = useTranslation();
    return <div>
        <FormTextField
            margin="dense"
            name="fullName"
            label={t("common.name")}
            type="text"
            fullWidth
            onChange={handleChange}
            value={item.fullName}
            validator={(value)=>{return !!value}}
            required
            autoFocus
            error={propagateError && !!!item.fullName}
          />
          <FormTextField
            margin="dense"
            name="email"
            label={t("common.email")}
            type="email"
            fullWidth
            required
            onChange={handleChange}
            validator={(value)=>{return !!value}}
            value={item.email}
            error={propagateError && !!!item.email}
          />
          <FormTextField
            margin="dense"
            name="phoneNumber1"
            label={t("common.phoneNumber1")}
            type="text"
            fullWidth
            onChange={handleChange}
            value={item.phoneNumber1}
          />
          <FormTextField
            margin="dense"
            name="phoneNumber2"
            label={t("common.phoneNumber2")}
            type="text"
            fullWidth
            onChange={handleChange}
            value={item.phoneNumber2}
          />
          <FormTextField
            margin="dense"
            name="headquarters"
            label={t("common.headquarters")}
            type="text"
            fullWidth
            onChange={handleChange}
            value={item.headquarters}
          />
          <SelectorField
            name="contactTypeId"
            fullWidth
            inputLabel={t("common.contactType")}
            onChange={handleChange}
            required={true}
            validator={(value)=>{return !!value}}
            value={item.contactTypeId}
            values={contactTypes.map((item) => {
              return { id: item.id, value: item.name };
            })}
            emptyValue={false}
            handleClickAddButon={clickContactTypeAddButton}
            error={propagateError && !!!item.contactTypeId}
          />
        </div>
}

export default ContactForm;