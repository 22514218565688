import React, { FC, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ClientsGateway } from "../../gateways/clients.gateway";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import {
  IClient,
  IUpdateClientDto,
  initialClientDto,
} from "../../gateways/clients.interfaces";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { RouteComponentProps, withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import ClientForm from "./client-form";
import { ClientTypesGateway } from "../../gateways/clients-types.gateway";
import { IClientTypes } from "../../gateways/clients-types.interfaces";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface IClientAddDialogProps {
  open: boolean;
  onClose: () => void;
  onClientCreated: (client: IClient) => void;
}

const ClientAddDialog: FC<IClientAddDialogProps & RouteComponentProps> = ({
  open,
  onClose,
  onClientCreated,
  history,
}) => {
  const clientsGateway = useIoC(ClientsGateway);
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();
  const [item, setItem] = useState<IClient | null>(null);
  const [clientToCreate, setClientToCreate] =
    useState<IUpdateClientDto>(initialClientDto);
  const classes = useStyles();
  const { t } = useTranslation();
  const [clientTypes, setClientTypes] = useState<IClientTypes[]>([]);
  const clientTypesGateway = useIoC(ClientTypesGateway);

  useEffect(() => {
    (async () => {
      const clientTypes = await clientTypesGateway.findAll();
      setClientTypes(clientTypes);
    })();
  }, []);

  const validateRequired = (value: any) => {
    return value !== null && value !== undefined && value !== "" && value !== 0;
  };

  const handleChange = (name: string, value: any) => {
    setClientToCreate({ ...clientToCreate, [name]: value });
  };

  const handleNavigateDetail = () => {
    if (item) {
      history.push("/clients/" + item.id);
    }
  };

  const handleCreateClient = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    (async () => {
      setLoading(true);

      try {
        let client = await clientsGateway.create(clientToCreate);

        if (typeof client.message !== 'undefined')
        {
          snackbar.enqueueSnackbar(client.message, { variant: "error" });
          return;
        }
        else
          client = client as IClient;

        snackbar.enqueueSnackbar(
          t("clientAddDialog.createSucceded", { id: client.id }),
          {
            variant: "success",
          }
        );

        setItem(client);
        onClientCreated(client);
      } catch (e) {
        const er = (e as any);
        if (er.response.data.key === "creatingEntityError") {
          const key = "clientsErrorHandler." + er.response.data.key;
          const message = "validation" in er ? er.validation : t(key);
          snackbar.enqueueSnackbar(message, { variant: "error" });
        } else {
          const message =
            "validation" in er ? er.validation : t("messages.defaultError");
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      } finally {
        setLoading(false);
      }
    })();
  };

  return (
    <Dialog open={open && !loading} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {t("clientAddDialog.title")}
      </DialogTitle>
      {item === null ? (
        <form onSubmit={handleCreateClient}>
          <DialogContent>
            <DialogContentText>
              {t("clientAddDialog.content")}
            </DialogContentText>
            <ClientForm
              onChange={handleChange}
              item={clientToCreate}
              validateRequired={validateRequired}
              clientTypes={clientTypes}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="default" disabled={loading}>
              {t("common.close")}
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {t("clientAddDialog.registerClient")}
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      ) : (
        <>
          <DialogContent>
            <DialogContentText>
              {t("clientAddDialog.createdMessage", {
                id: item.id,
                fullname: clientToCreate.fullname,
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="default" disabled={loading}>
              {t("common.close")}
            </Button>
            <Button
              onClick={handleNavigateDetail}
              color="primary"
              disabled={loading}
            >
              {t("common.navigateToDetail")}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default withRouter(ClientAddDialog);
