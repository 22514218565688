import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";

type PromiseFn<T, P> = (...params: P[]) => Promise<T>;

export type Fetcher<T extends (...args: any[]) => Promise<any>> = (
  ...args: Parameters<T>
) => ReturnType<T>;

export type FetchControllerOptions = {
  autoload: boolean;
  displayErrors: boolean;
};

export function useFetchController<T, P>(
  fetcher: PromiseFn<T, P>,
  options?: Partial<FetchControllerOptions>
) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(options?.autoload ?? false);
  let { autoload, displayErrors } = options || {};

  displayErrors = displayErrors === undefined || displayErrors === true;

  const fetchData = useCallback(
    async (...args: P[]) => {
      setLoading(true);
      try {
        const data = await fetcher(...args);
        setData(data);
        return data;
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
          if (displayErrors) enqueueSnackbar(err.message, { variant: "error" });
          throw err;
        } else {
          let message = `Unknown error: ${err}`;
          let error = new Error(message);
          setError(error);
          if (displayErrors) enqueueSnackbar(message, { variant: "error" });
          throw error;
        }
      } finally {
        setLoading(false);
      }
    },
    [fetcher, enqueueSnackbar, displayErrors]
  );

  const clearData = useCallback(() => {
    setLoading(false);
    setData(null);
    setError(null);
  }, []);

  const replaceData = useCallback((data: T | null) => {
    setData(data);
  }, []);

  useEffect(() => {
    if (autoload) fetchData();
  }, [autoload, fetchData]);

  return { data, error, loading, fetchData, clearData, replaceData };
}
