import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IRuleValidationResponse, IRule } from "./rules.interfaces";

@inject('axios')
export class RulesGateway {
    private readonly url = 'rules';

    constructor(
        private axios: AxiosInstance
    ) { }

    public async validateRules(dto: IRule[]): Promise<IRuleValidationResponse> {
        const response = await this.axios.post(this.url + '/validateRules', dto);
        return response.data;
    }


}