export interface IWaterSupplyInterfaceUpsert {
  referencia: string;
  accessTypeId: number | null;
  providerId: number | null;
  sendEmail?: boolean;
  sendClientEmail?: boolean;
  telemedida: boolean;
  startCaptureDate?: Date;
  code?: string;
  invoicesFolder?: string;
  emailNotification?: string | null;
  complexId?: number;
  establishmentId?: number;
  startValidationsDate?: Date;
  separatedMeterAccount: boolean;
  separatedMeterInvoiceFolder: string;
  separatedMeterStartCaptureDate: Date;
  separatedMeterStartValidationsDate: Date;
}

export interface IWaterSupplyInterface {
  id: number;
  referencia: string;
  accessTypeId: number | null;
  providerId: number | null;
  sendEmail?: boolean;
  sendClientEmail?: boolean;
  telemedida: boolean;
  startCaptureDate?: Date;
  code?: string;
  invoicesFolder?: string;
  emailNotification?: string | null;
  complexId?: number;
  establishmentId?: number;
  startValidationsDate?: Date;
  blacklisted?: boolean;
  separatedMeterAccount: boolean;
  separatedMeterInvoiceFolder: string;
  separatedMeterStartCaptureDate: Date;
  separatedMeterStartValidationsDate: Date;
}

export const initialIWaterSupplyInterFace = {
  id: 0,
  referencia: "",
  accessTypeId: 0,
  providerId: 0,
  sendEmail: false,
  sendClientEmail: false,
  telemedida: false,
  startCaptureDate: undefined,
  startValidationsDate: undefined,
  code: "",
  invoicesFolder: "",
  emailNotification: null,
  complexId: undefined,
  establishmentId: undefined,
  separatedMeterAccount: false,
  separatedMeterInvoiceFolder: '',
  separatedMeterStartCaptureDate: new Date(),
  separatedMeterStartValidationsDate: new Date()
};
