import { BotFErrorTypes } from "./interfaces"
import errorNoCups from './error-no-cups';
import errorNoDate from './error-no-date';
import errorOneDriveFile from './error-one-drive-file';
import errorOperativeMail from './error-operative-mail';

const errorsTypeDictionary: BotFErrorTypes = {
    errorNoCups,
    errorNoDate,
    errorOneDriveFile,
    errorOperativeMail
}

export default errorsTypeDictionary;

