import React, { FC, useState, useMemo, useEffect } from 'react';
import { useHistory, useRouteMatch } from "react-router";
import CenteredCircularProgress from '../../components/centered-circular-progress';
import AlertBox from '../../components/alert-box';
import { List, Divider, Box, Fab, makeStyles } from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import useIoC from '../../contexts/ioc.context';
import { useNavigator } from '../../contexts/navigator.context';
import { IValidationContext } from '../../gateways/validation-context.interfaces';
import { ValidationContextGateway } from '../../gateways/validation-context.gateway';
import ValidationContextAddDialog from './validation-context-add-dialog';
import ValidationContextItem from './validation-context-item';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    }
}));

const ValidationContextList: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { url } = useRouteMatch();
    const { t } = useTranslation();
    const [, navigatorDispatch] = useNavigator();


    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState<IValidationContext[]>([]);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [item, setItem] = useState<IValidationContext>();
    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);
    const validationContextGateway = useIoC(ValidationContextGateway);

    const handleClickFabButton = () => {
        setItem(undefined);
        setOpenAddDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenAddDialog(false);
    };

    const handleItemCreated = (item: IValidationContext) => {
        setData([item, ...data]);
    };

    const handleClickItem = (item: IValidationContext) => {
        history.push(`${url}/${item.id}`);
    };

    useEffect(() => {
        (async () => {
            (async () => {
                try {
                    setIsDataLoading(true);
                    const data = await validationContextGateway.findAll();
                    setData(data);
                    setIsDataLoading(false);
                } catch (e) {
                    setIsDataLoading(false);
                }
            })();
        })();
    }, [validationContextGateway]);

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('validationContextList.title'),
                icon: VerifiedUserIcon
            }
        });
    }, []);

    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />;
        }

        if (isDataEmpty) {
            return <AlertBox variant='info'>{t('common.noItems')}</AlertBox>;
        }

        return (
            <List>
                {data.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <ValidationContextItem
                                item={item}
                                onClickItem={handleClickItem}
                            />
                            {index < data.length - 1 ? <Divider variant='inset' component='li' /> : null}
                        </div>
                    );
                })}
            </List>
        );
    };

    return (
        <Box>
            <Fab color='primary' aria-label='add' className={classes.fab} onClick={handleClickFabButton}>
                <AddIcon />
            </Fab>
            <Content />
            <ValidationContextAddDialog
                open={openAddDialog}
                onItemCreate={handleItemCreated}
                onClose={handleCloseDialog}
            />
        </Box>
    );
};

export default ValidationContextList;