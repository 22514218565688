import React, { FC, useEffect, useState, useReducer } from "react";
import { useRouteMatch, Route, RouteComponentProps } from "react-router-dom";
import useIoC from "../../contexts/ioc.context";
import EstablishmentRouter from "../establishment/establishment-router";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import { useTranslation } from "react-i18next";
import { StateType, ComplexReducer, ComplexContext } from "./complex-context";
import ComplexDetail from "./complex-detail";
import { ComplexGateway } from "../../gateways/complex.gateway";
import ElectricSupplyRouter from "../electric-supply/electric-supply-router";
import FuelSupplyRouter from "../fuel-supply/fuel-supply-router";
import WaterSupplyRouter from "../water-supply/water-supply-router";
import ContactList, { ContactEntityType } from "../contacts/contact-list";

const initialState: StateType = { complex: null };
interface IContactsRouteParams {
  complexId: string;
}

const ContactRouter = (props: RouteComponentProps<IContactsRouteParams>) => (
  <ContactList
    entity={ContactEntityType.Complex}
    entityId={props.match.params.complexId}
  />
);

export const ComplexWrapper: FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ complexId: string }>();
  const [complexId, setComplexId] = useState<number | null>(null);
  const complexGateway = useIoC(ComplexGateway);
  const [, setLoading] = useState(true);
  const { update: updateBreadCrumbName } = useBreadCrumbName();

  const [complexState, complexStateReducer] = useReducer(
    ComplexReducer,
    initialState
  );

  useEffect(() => {
    setComplexId(parseInt(match.params.complexId, 10));
  }, [match.params.complexId]);

  useEffect(() => {
    (async () => {
      if (complexId === null) {
        return;
      }
      setLoading(true);
      const temp = await complexGateway.findById(complexId);
      updateBreadCrumbName("complex", temp.name);
      complexStateReducer({ type: "update-complex", payload: temp });
      setLoading(false);
    })();
  }, [complexId]);

  if (complexState.complex === null) {
    return <div>{t("common.loading")}</div>;
  }
  return (
    <ComplexContext.Provider value={[complexState, complexStateReducer]}>
      <Route path={`${match.path}`} exact>
        <ComplexDetail />
      </Route>
      <Route path={`${match.path}/contacts`} render={ContactRouter} />
      <Route path={`${match.path}/electricSupply`}>
        <ElectricSupplyRouter />
      </Route>
      <Route path={`${match.path}/establishments`}>
        <EstablishmentRouter />
      </Route>
      <Route path={`${match.path}/fuelSupply`}>
        <FuelSupplyRouter />
      </Route>
      <Route path={`${match.path}/waterSupply`}>
        <WaterSupplyRouter />
      </Route>
    </ComplexContext.Provider>
  );
};
