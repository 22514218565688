import React, { useState, useEffect, useContext } from 'react';
import ICompany, { initialICompany } from '../../gateways/company.interface';
import { makeStyles, Box, CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useTranslation } from 'react-i18next';
import { CompanyGateway } from '../../gateways/company.gateway';
import useIoC from '../../contexts/ioc.context';
import { SectorGateway } from '../../gateways/sector.gateway';
import CountryGateway from '../../gateways/country.gateway';
import ISector from '../../gateways/sector.interfaces';
import ICountry from '../../gateways/country.interfaces';
import { useParams, useRouteMatch } from 'react-router';
import { useSnackbar } from 'notistack';
import CompanyIcon from '@material-ui/icons/BusinessCenter';
import CompanyForm from './company-form'
import ScreenGlobalStructure from '../../components/screen-global-structure';
import { useBreadCrumbName } from '../../contexts/breadCrumbNames.context';
import CompaniesDrawer from '../../components/companies-drawer'
import CountryUpsertDialog from '../countries/country-upsert-dialog';
import SectorCreateDialog from '../sectors/sector-create-dialog';
import { CompanyContext } from './companies-context';
import { ClientsGateway } from '../../gateways/clients.gateway';
import { obtainDomainFromEmail } from '../../utils/utils';

interface ICompanyDetailRouteParams {
    [x: string]: string | undefined;
    companyId: string;
}

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    progress: {
        margin: theme.spacing(2),
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

const CompanyDetail = () => {
    const snackbar = useSnackbar();
    const classes = useStyles();
    const { t } = useTranslation();
    const match = useRouteMatch<{ clientId: string }>();
    const { clientId } = {clientId: null};//useParams();

    const { companyId } = useParams<ICompanyDetailRouteParams>();
    const [companyState, companyStateDispatch] = useContext(CompanyContext);
    const companyGateway = useIoC(CompanyGateway);
    const sectorGateway = useIoC(SectorGateway);
    const countryGateway = useIoC(CountryGateway);
    const clientsGateway = useIoC(ClientsGateway);
    const [sectors, setSectors] = useState<ISector[]>([]);
    const [countries, setCountries] = useState<ICountry[]>([]);

    const company = companyState.company;
    const blacklisted = company && company.cancelledDate !== null ? true: false;
    const [isBlacklisted, setIsBlacklisted] = useState<boolean | undefined>(blacklisted);

    const { update: updateBreadCrumbName } = useBreadCrumbName();
    const [createSector, setCreateSector] = useState(false);
    const [createCountry, setCreateCountry] = useState(false);
    const [ loading, setLoading] = useState(false);

    const handleCompanyState = () => {

        if(isBlacklisted){
          if(company){
            updateBreadCrumbName('companies', company.name);
            company.cancelledDate = null;
          } 
          snackbar.enqueueSnackbar('Se ha reestablecido la compañía correctamente', {
            variant: 'success',
          });
        }
        else{
          if(company){
            company.cancelledDate = new Date();
            updateBreadCrumbName('companies', company.name+" (Cancelado)");
          } 
          snackbar.enqueueSnackbar('Se ha descartado la compañía correctamente', {
            variant: 'success',
          });
        }
    
        handleSave();
        setIsBlacklisted(!isBlacklisted);
      }

    const handleClickAddSectorButton = () => {
        setCreateSector(true);
    }

    const handleClickAddCountryButton = () => {
        setCreateCountry(true);
    }
    const handleCloseAddSectorButton = () => {
        setCreateSector(false);
    }

    const handleCloseAddCountryButton = () => {
        setCreateCountry(false);
    }
    const handleCreateSector = (item: ISector) => {
        setSectors([...sectors, item]);
    }
    const handleCreateCountry = (item: ICountry, isNew: boolean) => {
        setCountries([...countries, item]);
    }

    const handleSave = () => {
        if (company === null) {
            return;
        }

        if (!company.name || !company.countryId || !company.sectorId) {
            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        }

        (async () => {
            try {
                if (!companyId) return;
                setLoading(true);

                //domain check
                if (typeof company.client_email !== 'undefined' && company.client_email !== '')
                {
                    const input_domain = obtainDomainFromEmail(company.client_email);
                    //have content
                    const client = await clientsGateway.findById(parseInt(match.params.clientId, 10));
                    if (typeof client.client_email_domain !== 'undefined' && client.client_email_domain !== '')
                    {
                        //compare both domains
                        if (input_domain !== client.client_email_domain)
                        {
                            //es diferente
                            if (window.confirm('El dominio del correo de cliente, no coincide con el dominio configurado en el apartado principal de Cliente, quieres continuar de todos modos ?') == false)
                            {
                                snackbar.enqueueSnackbar('No se han actualizado los cambios', { variant: "warning" });
                                setLoading(false);
                                return;
                            }
                        }
                    }
                    
                }

                const companyUpdated = await companyGateway.update(companyId, company);
                snackbar.enqueueSnackbar(t('companyDetail.updateSucceded', { id: companyUpdated.id, name: companyUpdated.name }), { variant: "success" });
            } catch (e) {
                const er = (e as any);
                if (er.response.data.key === 'updatingError') {
                    const key = 'companiesErrorHandler.' + er.response.data.key;
                    const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                } else {
                    const message = "validation" in er ? er.validation : t('messages.defaultError');
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                }
            } finally {
                setLoading(false);
            }
        })();
    };

    const handleChange = (name: string, value: any) => {
        if (company === null) {
            return;
        }
        const updatedCompany: ICompany = {...company, [name]: value} as any;
        companyStateDispatch({type: 'update-company', payload: updatedCompany});
        updateBreadCrumbName('companies', updatedCompany.name);
    };

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            const data = await clientsGateway.findById(parseInt(match.params.clientId, 10));
            if (data.cancelledDate !== null) setIsBlacklisted(undefined);
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const data = await sectorGateway.findAll();
            setSectors(data);
            setLoading(true);
        })();
    }, [sectorGateway]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const data = await countryGateway.findAll();
            setCountries(data);
            setLoading(false);
        })();
    }, [countryGateway]);

    if (company === null) {
        return (<CircularProgress className={classes.progress} />);
    }

    return (
        <Box>
            <ScreenGlobalStructure
                buttonIcon={<SaveIcon />}
                headerIcon={CompanyIcon}
                matchUrl={match.url}
                onButtonClick={handleSave}
                title={t('companyDetail.title')}
                clientId={clientId ?? ''}
                loading={loading}
                drawer={
                    <CompaniesDrawer
                        matchUrl={match.url}
                        lateralMenu={0}
                        icon={<CompanyIcon fontSize='large' className={classes.itemAvatarIcon}/>}
                        goBack={false}
                    />
                }
                onBlacklistButtonClick = {handleCompanyState}
                blacklisted = {isBlacklisted}
            >
                <CompanyForm
                    item={company}
                    onChange={handleChange}
                    validateRequired={validateRequired}
                    countries={countries}
                    sectors={sectors}
                    handleClickAddButtonCountry={handleClickAddCountryButton}
                    handleClickAddButtonSector={handleClickAddSectorButton}
                />
            </ScreenGlobalStructure>
            <CountryUpsertDialog
                open={createCountry}
                onItemUpsert={handleCreateCountry}
                onClose={handleCloseAddCountryButton}
                item={undefined}
            />
            <SectorCreateDialog
                open={createSector}
                onItemCreate={handleCreateSector}
                onClose={handleCloseAddSectorButton}
            />
        </Box>
    );
};

export default CompanyDetail;