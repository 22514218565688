import React, { FC, useState, useEffect } from 'react';
import { IBotPreconfigRO, IBotRO } from '../../gateways/bots.interfaces';
import { ISaveSupplyBotAccount } from '../../gateways/supply-bots.interface';
import { makeStyles, FormControl, FormHelperText, InputLabel, Select, MenuItem, Grid, FormControlLabel } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    formControl: {
        marginBottom: '1rem',
        '& :last-child': {
            marginBottom: 0
        }
    },
    configRoot: {
        padding: theme.spacing(3, 2),
        marginBottom: theme.spacing(1)
    }
}));

interface ISupplyBotAccountFormProps {
    account: ISaveSupplyBotAccount;
    bots: IBotRO[];
    onChange: (account: ISaveSupplyBotAccount) => void;
}

const SupplyBotAccountForm: FC<ISupplyBotAccountFormProps> = ({ account, bots, onChange }) => {
    const [preconfigOptions, setPreconfigOptions] = useState<IBotPreconfigRO[]>([]);
    const classes = useStyles();

    useEffect(() => {
        if (account.botId) {
            const bot = bots.find(x => x.id === account.botId);
            if (bot) {
                setPreconfigOptions(bot.preconfigs);
                return;
            }
        }
        setPreconfigOptions([]);
    }, [account.botId, bots]);

    const handleChange = (prop: string) => (event: React.ChangeEvent<{ value: any }>) => onChange({ ...account, [prop]: event.target.value });
    const handleChangeDate = (prop: string) => (date: Date | null) => onChange({ ...account, [prop]: date });

    return (
        <Grid container spacing={2}>
            <Grid item md={3}>
                <FormControl fullWidth className={classes.formControl}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"

                        id="date-picker-inline"
                        label="Capturar facturas a partir de"
                        value={account.startCaptureDate}
                        onChange={handleChangeDate('startCaptureDate')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item md={3}>
                <FormControl fullWidth className={classes.formControl}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"

                        id="date-picker-inline"
                        label="Capturar facturas hasta"
                        value={account.endCaptureDate}
                        onChange={handleChangeDate('endCaptureDate')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item md={3}>
                <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="bot-input">Bot</InputLabel>
                    <Select
                        value={account.botId}
                        onChange={handleChange('botId')}
                        inputProps={{ name: 'bot-input' }}
                    >
                        {
                            bots.map((x) => (
                                <MenuItem key={'bot_' + x.id} value={x.id}>{x.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={3}>
                <FormControl fullWidth className={classes.formControl}>
                    <InputLabel htmlFor="preconfig-input">Bot config</InputLabel>
                    <Select
                        value={account.preconfigId}
                        onChange={handleChange('preconfigId')}
                        inputProps={{ name: 'preconfig-input' }}
                    >
                        {
                            preconfigOptions.map((x) => (
                                <MenuItem key={'preconfig_' + x.id} value={x.id}>{x.alias}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
};

export default SupplyBotAccountForm;