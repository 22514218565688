import React, { FC } from "react";
import { List, ListItem, ListItemText, ListItemIcon, Grid } from "@material-ui/core";
import { Link as ReactRouterLink } from "react-router-dom";
import EstablishmentIcon from '@material-ui/icons/Store';
import { useTranslation } from "react-i18next";
import { useBreadCrumbName } from "../contexts/breadCrumbNames.context";
import DescriptionIcon from '@material-ui/icons/Description';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

interface IDrawerProps {
    matchUrl: string;
    lateralMenu?: number;
    icon: JSX.Element;
    goBack: boolean
};

const FuelSupplyDrawer: FC<IDrawerProps> = (props) => {
    const { names: BreadCrumbNames } = useBreadCrumbName();
    const { t } = useTranslation();
    var matchUrl: string =
        (props.matchUrl.replace(/[^/]/g, "").length <= 2 ||
            !isNaN(parseInt(props.matchUrl[props.matchUrl.length - 1]))) ? props.matchUrl : props.matchUrl.slice(0, props.matchUrl.lastIndexOf('/'));

    return (

        <div>
            <List>
                <ListItem>
                    <Grid container direction="column" alignItems="center">
                        <Grid>
                            {props.icon}
                        </Grid>
                        <Grid>
                            <ListItemText primary={BreadCrumbNames['clientsDrawer']} />
                        </Grid>
                    </Grid>
                </ListItem>
                {
                    props.goBack &&
                    <ListItem button component={ReactRouterLink} to={`${matchUrl}`}>
                        <ListItemIcon><ArrowBackIosIcon /></ListItemIcon>
                        <ListItemText primary={t('rightNavigation.back')} />
                    </ListItem>
                }
                <ListItem button component={ReactRouterLink} to={`${matchUrl}/fuelContract`}
                    selected={props.lateralMenu === 1}>
                    <ListItemIcon><InsertDriveFileIcon /></ListItemIcon>
                    <ListItemText primary={t('rightNavigation.fuelContract')} />
                </ListItem>
                <ListItem button component={ReactRouterLink} to={`${matchUrl}/registerFuelBill`}
                    selected={props.lateralMenu === 2}>
                    <ListItemIcon><DescriptionIcon /></ListItemIcon>
                    <ListItemText primary={t('rightNavigation.fuelBill')} />
                </ListItem>
            </List>
        </div>
    );
};

export default FuelSupplyDrawer;