import React, { FC, useState, useEffect, useMemo } from "react";
import { List, ListItem, ListItemAvatar, ListItemText, Divider, makeStyles, CircularProgress, Box } from "@material-ui/core";
import useIoC from "../../contexts/ioc.context";
import AlertBox from "../../components/alert-box";
import { useNavigator } from '../../contexts/navigator.context';
import { BotsGateway } from "../../gateways/bots.gateway";
import { IBotRO } from "../../gateways/bots.interfaces";
import { useTranslation } from "react-i18next";
import InfoIcon from '@material-ui/icons/Info';
import { RouteComponentProps } from "react-router";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BlockIcon from '@material-ui/icons/Block';
import { useSnackbar } from "notistack";


const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer',
    },
    inline: {
        display: 'inline',
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    progress: {
        margin: theme.spacing(2),
    },
    loaderContainer: {
        textAlign: 'center'
    }
}));

const PrometheusBotList: FC<RouteComponentProps> = ({ history }) => {

    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const { t } = useTranslation();
    const snackbar = useSnackbar();

    const [data, setData] = useState<IBotRO[]>([]);
    const [loading, setLoading] = useState(false);
    const isEmpty = useMemo(() => !loading && data.length === 0, [loading, data]);

    const botsGateway = useIoC(BotsGateway);

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('common.prometheus'),
                icon: InfoIcon
            }
        });
    }, [data]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const items = await botsGateway.findAll();
            setData(quickSort(items));
            setLoading(false);
        })();
    }, [botsGateway]);

    function quickSort(bots: IBotRO[]): IBotRO[] {
        if (bots.length <= 1)
            return bots;
        else {
            let left = [];
            let right = [];
            let newArray: IBotRO[] = [];
            let pivot = bots.pop();
            let length = bots.length;

            if (!!!pivot)
                return [];

            for (let i = 0; i < length; i++) {
                if (bots[i].name.toLocaleLowerCase().charAt(0) <= pivot.name.toLocaleLowerCase().charAt(0)) {
                    left.push(bots[i]);
                }
                else {
                    right.push(bots[i]);
                }
            }

            return newArray.concat(quickSort(left), pivot, quickSort(right));
        }
    }

    const handleClick = (item: IBotRO) => {
        if(!!!item.prometheus_host) {
            snackbar.enqueueSnackbar(t("common.documentationNotAvailable"), {
                variant: 'error',
            });
        }
        else history.push(`/prometheus/${item.id}`);
    }

    const renderItem = (item: IBotRO, index: number) => {

        const handleClickWrapper = async () => handleClick(item);

        const icon = item.prometheus_host ? 
            <ArrowForwardIcon color="primary" fontSize="large" className={classes.itemAvatarIcon}/> :
            <BlockIcon color="secondary" fontSize="large" className={classes.itemAvatarIcon}/>;

        return (
            <>
                <ListItem alignItems="flex-start" className={classes.listItem} onClick={handleClickWrapper}>
                    <ListItemAvatar >
                        {icon}
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${item.name}`}
                    />
                </ListItem>
                {
                    index < data.length - 1
                        ? <Divider variant="inset" component="li" />
                        : null
                }
            </>
        );
    }

    return(
        <Box>

            {
                loading
                    ? (
                        <div className={classes.loaderContainer}>
                            <CircularProgress className={classes.progress} />
                        </div>
                    ) : null
            }

            {
                isEmpty
                    ? (
                        <AlertBox variant="info">
                            No se han encontrado registros
                        </AlertBox>
                    ) : null
            }

            <List>
                {data.map(renderItem)}
            </List>
        </Box>
    );
}

export default PrometheusBotList;