import React, { useState, useMemo, useEffect } from "react";
import CenteredCircularProgress from "../../components/centered-circular-progress";
import AlertBox from "../../components/alert-box";
import { makeStyles, List, Divider, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import ComplexItem from "./complex-item";
import ComplexUpsertDialog from "./complex-upsert-dialog";
import useIoC from "../../contexts/ioc.context";
import ComplexIcon from "@material-ui/icons/Dashboard";
import { useParams, useHistory, useRouteMatch } from "react-router";
import { ComplexGateway } from "../../gateways/complex.gateway";
import { IComplex } from "../../gateways/complex.interfaces";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import PeopleIcon from "@material-ui/icons/People";
import ClientsDrawer from "../../components/clients-drawer";
import CompaniesDrawer from "../../components/companies-drawer";
import CompanyIcon from "@material-ui/icons/BusinessCenter";

const useStyles = makeStyles((theme) => ({
  itemAvatarIcon: {
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
}));

interface IComplexRouteParams {
  [x: string]: string | undefined;
  clientId: string;
  companyId: string;
}

const ComplexList = () => {
  const classes = useStyles();
  const { clientId } = useParams<IComplexRouteParams>();
  const { companyId } = useParams<IComplexRouteParams>();

  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState<IComplex[]>([]);
  const [openUpsertDialog, setOpenUpsertDialog] = useState(false);
  const [item, setItem] = useState<IComplex>();
  const [showDiscardedComplex, setShowDiscardedComplex] = useState<boolean>(false);
  const [filedButtonTitle, setFiledButtonTitle] = useState<string>(`${t("complexList.buttonNonDiscardedTitle")}`);

  const isDataEmpty = useMemo(
    () => !isDataLoading && data.length === 0,
    [isDataLoading, data]
  );
  const complexGateway = useIoC(ComplexGateway);

  const handleClickFabButton = () => {
    setItem(undefined);
    setOpenUpsertDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenUpsertDialog(false);
  };

  const handleItemUpsert = (item: IComplex, isNew: boolean) => {
    let copy = data.slice();
    if (isNew) {
      copy = [item, ...data];
    } else {
      const index = data.findIndex((value) => value.id === item.id);
      copy[index] = item;
    }
    setData(copy);
  };
  const handleClickItem = (item: IComplex) => {
    history.push(`${url}/${item.id}`);
  };

  useEffect(() => {
    (async () => {
      (async () => {
        setIsDataLoading(true);
        if (!companyId) {
          if (clientId) {
            const data = await complexGateway.findAll({
              clientId: parseInt(clientId, 10),
              showDiscarded: showDiscardedComplex
            });
            setData(data);
          }
        } else {
          const data = await complexGateway.findAll({
            companyId: parseInt(companyId, 10),
            showDiscarded: showDiscardedComplex
          });
          setData(data);
        }
        setIsDataLoading(false);
      })();
    })();
  }, [complexGateway]);

  const Content = () => {
    if (isDataLoading) {
      return <CenteredCircularProgress />;
    }

    if (isDataEmpty) {
      return <AlertBox variant="info">{t("common.noItems")}</AlertBox>;
    }

    return (
      <List>
        {data.map((item, index) => {
          return (
            <div key={item.id}>
              <ComplexItem item={item} showDiscarded={showDiscardedComplex} onClickItem={handleClickItem} />
              {index < data.length - 1 ? (
                <Divider variant="inset" component="li" />
              ) : null}
            </div>
          );
        })}
      </List>
    );
  };

  const handleComplexList = async () => {
    setIsDataLoading(true);
    const bool = showDiscardedComplex === false ? true: false;
    let data;

    if (!companyId) {
      if (clientId) {
        data = await complexGateway.findAll({
          clientId: parseInt(clientId, 10),
          showDiscarded: bool
        });
      }
    } else {
      data = await complexGateway.findAll({
        companyId: parseInt(companyId, 10),
        showDiscarded: bool
      });
    }

    if(data === undefined){
      return;
    }

    setData(data);

    if(showDiscardedComplex === true) setFiledButtonTitle(`${t("complexList.buttonNonDiscardedTitle")}`)
    else setFiledButtonTitle(`${t("complexList.buttonDiscardedTitle")}`);

    setShowDiscardedComplex(!showDiscardedComplex);
    setIsDataLoading(false);
  }

  const handleOnLoading = (value: boolean) => {
    setIsDataLoading(value);
  }

  return (
    <Box>
      <ScreenGlobalStructure
        buttonIcon={<AddIcon />}
        headerIcon={ComplexIcon}
        matchUrl={url}
        onButtonClick={handleClickFabButton}
        title={t("complexList.title")}
        clientId={clientId !== undefined ? clientId : ""}
        loading={isDataLoading}
        drawer={
          !companyId ? (
            <ClientsDrawer
              matchUrl={url}
              lateralMenu={3}
              icon={
                <PeopleIcon
                  fontSize="large"
                  className={classes.itemAvatarIcon}
                />
              }
              goBack={true}
            />
          ) : (
            <CompaniesDrawer
              matchUrl={url}
              lateralMenu={2}
              icon={
                <CompanyIcon
                  fontSize="large"
                  className={classes.itemAvatarIcon}
                />
              }
              goBack={true}
            />
          )
        }
        filedButtonList={handleComplexList}
        filedButtonListTitle={filedButtonTitle}
      >
        <Content />
      </ScreenGlobalStructure>
      {clientId && (
        <ComplexUpsertDialog
          open={openUpsertDialog}
          item={item}
          clientId={parseInt(clientId, 10)}
          companyId={companyId ? parseInt(companyId, 10) : undefined}
          onItemUpsert={handleItemUpsert}
          onLoading={handleOnLoading}
          onClose={handleCloseDialog}
        />
      )}
    </Box>
  );
};

export default ComplexList;
