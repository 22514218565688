import React, { useState, useMemo, useEffect } from 'react';
import CenteredCircularProgress from '../../components/centered-circular-progress';
import AlertBox from '../../components/alert-box';
import { List, Divider, Box, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import useIoC from '../../contexts/ioc.context';
import { useParams, useHistory, useRouteMatch } from "react-router";
import { IFuelContract, IFuelContractItem } from '../../gateways/fuel-contract.interfaces';
import { FuelContractGateway } from '../../gateways/fuel-contract.gateway';
import { ProvidersGateway } from '../../gateways/providers.gateway';
import { FuelSupplyRatesGateway } from '../../gateways/fuel-supply-rates.gateway';
import FuelContractItem from './fuel-contract-item';
import FuelContractUpsertDialog from './fuel-contract-upsert-dialog';
import ScreenGlobalStructure from '../../components/screen-global-structure';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import FuelSupplyDrawer from "../../components/fuel-supply-drawer"

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

interface IFuelContractRouteParams {
    [x: string]: string | undefined;
    fuelSupplyId: string;
    complexId: string,
    establishmentId: string;
    clientId: string;
}

const FuelContractList = () => {
    const { fuelSupplyId } = useParams<IFuelContractRouteParams>();
    const { clientId } = useParams<IFuelContractRouteParams>();

    const history = useHistory();
    const { url } = useRouteMatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState<IFuelContract[]>([]);
    const [displayData, setDisplayData] = useState<IFuelContractItem[]>([]);
    const [openUpsertDialog, setOpenUpsertDialog] = useState(false);
    const [item, setItem] = useState<IFuelContract>();

    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);

    const fuelContractGateway = useIoC(FuelContractGateway);
    const providersGateway = useIoC(ProvidersGateway);
    const fuelRateGateway = useIoC(FuelSupplyRatesGateway);

    const handleClickFabButton = () => {

        setItem(undefined);
        setOpenUpsertDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenUpsertDialog(false);
    };

    const settingDisplayData = async (data: IFuelContract[]) => {
        var displayList: IFuelContractItem[] = [];
        setIsDataLoading(true);
        for (let fuelContract of data) {
            let provider = await providersGateway.findById(fuelContract.providerId);
            let rateEntity = await fuelRateGateway.findById(fuelContract.fuelSupplyRateId);
            displayList.push({
                id: fuelContract.id,
                cancelPriorNotice: fuelContract.cancelPriorNotice,
                duration: fuelContract.duration,
                endSupplyDate: fuelContract.endSupplyDate,
                initSupplyDate: fuelContract.initSupplyDate,
                modality: fuelContract.modality,
                provider: provider.alias,
                fuelSupplyRate: rateEntity.name,
                signContractDate: fuelContract.signContractDate,
                term: fuelContract.term,
                fixStipulation: fuelContract.fixStipulation,
                variableStipulation: fuelContract.variableStipulation,
                yearlyStipulation: fuelContract.variableStipulation,
                supplyConnectionBot: fuelContract.supplyConnectionBot,
            });
        }
        setDisplayData(displayList);
        setIsDataLoading(false);
    };

    const handleItemUpsert = (item: IFuelContract) => {
        let copy = data.slice();
        copy = [item, ...data];

        settingDisplayData(copy);
        setData(copy);
        setOpenUpsertDialog(false);
    };

    const handleClickItem = (item: IFuelContractItem) => {
        history.push(`${url}/${item.id}`);
    };

    useEffect(() => {
        (async () => {
            (async () => {
                setIsDataLoading(true);
                const data = await fuelContractGateway.findAll({ fuelSupplyId });
                setData(data);
                settingDisplayData(data);
                setIsDataLoading(false);
            })();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fuelContractGateway]);

    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />;
        }

        if (isDataEmpty) {
            return <AlertBox variant='info'>{t('common.noItems')}</AlertBox>;
        }

        return (
            <List>
                {displayData.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <FuelContractItem item={item} onClickItem={handleClickItem} />
                            {index < data.length - 1 ? <Divider variant='inset' component='li' /> : null}
                        </div>
                    );
                })}
            </List>
        );
    };
    return (
        <Box>
            <ScreenGlobalStructure
                buttonIcon={<AddIcon />}
                headerIcon={LocalGasStationIcon}
                matchUrl={url}
                onButtonClick={handleClickFabButton}
                title={t('electricSupplyDetail.title')}
                clientId={clientId ? clientId : ''}
                loading={isDataLoading}
                drawer={<FuelSupplyDrawer
                    matchUrl={url}
                    lateralMenu={1}
                    icon={<LocalGasStationIcon fontSize='large' className={classes.itemAvatarIcon}/>}
                    goBack={true}
                />}
            >
                <Content />
            </ScreenGlobalStructure>
            <FuelContractUpsertDialog
                open={openUpsertDialog}
                item={item}
                onItemUpsert={handleItemUpsert}
                onClose={handleCloseDialog}
            />
        </Box>
    );
};

export default FuelContractList;