import React, { FC, useState, useEffect } from "react";
import { IComplex, initialIComplex } from "../../gateways/complex.interfaces";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import { ComplexGateway } from "../../gateways/complex.gateway";
import { CompanyGateway } from "../../gateways/company.gateway";
import ICompany from "../../gateways/company.interface";
import IRegion from "../../gateways/region.interface";
import CountryGateway from "../../gateways/country.gateway";
import ICountry from "../../gateways/country.interfaces";
import ComplexForm from "./complex-form";
import CountryUpsertDialog from "../countries/country-upsert-dialog";
import CompanyAddDialog from "../companies/company-add-dialog";
import CoordinatesGateway from "../../gateways/coordinates.gateway";
import {
  ICoordinatesReturn,
  initialCoordinatesReturn,
} from "../../gateways/coordinates.interfaces";

interface IComplexUpsertDialogProps {
  open: boolean;
  item: IComplex | undefined;
  clientId: number;
  companyId?: number;
  onItemUpsert: (item: IComplex, isNew: boolean) => void;
  onLoading: (value: boolean) => void;
  onClose: () => void;
}
const initialCompanies: ICompany[] = [];

const ComplexUpsertDialog: FC<IComplexUpsertDialogProps> = (props) => {
  const { t } = useTranslation();
  const [item, setItem] = useState<IComplex>(initialIComplex);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [regions, setRegions] = useState<IRegion[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [coordinates, setCoordinates] = useState<string>("");

  const snackbar = useSnackbar();
  const complexGateway = useIoC(ComplexGateway);
  const companyGateway = useIoC(CompanyGateway);
  const countryGateway = useIoC(CountryGateway);
  const coordinatesGateway = useIoC(CoordinatesGateway);
  const [createCountry, setCreateCountry] = useState(false);
  const [createCompany, setCreateCompany] = useState(false);
  const [ direction, setDirection ] = useState("");
  const [ loading, setLoading] = useState(false);

  const handleClickAddCountryButton = () => {
    setCreateCountry(true);
  };

  const handleCloseAddCountryButton = () => {
    setCreateCountry(false);
  };

  const handleClickAddCompanyButton = () => {
    setCreateCompany(true);
  };

  const handleCloseAddCompanyButton = () => {
    setCreateCompany(false);
  };

  const handleCreateCountry = (item: ICountry, isNew: boolean) => {
    setCountries([...countries, item]);
  };

  const handleCreateCompany = (item: ICompany) => {
    if (!props.companyId) {
      setCompanies([...companies, item]);
    }
  };

  const createComplex = async (item: IComplex) => {
    const regex = /^(?<latitude>[^,]+),[\s]+(?<longitude>[^,]+)$/;
    const coordinatesRegex = regex.exec(coordinates);
    const createdCoordinates: ICoordinatesReturn =
      coordinatesRegex && coordinatesRegex.groups
        ? await coordinatesGateway.create({
            latitude: parseFloat(coordinatesRegex.groups.latitude),
            longitude: parseFloat(coordinatesRegex.groups.longitude),
          })
        : initialCoordinatesReturn;
    props.onLoading(true);
    setLoading(true);
    const complex = await complexGateway.create({
      ...item,
      regionsIds: regions.map((value) => {
        return value.id;
      }),
      coordinatesId: createdCoordinates.id,
    });
    snackbar.enqueueSnackbar(
      t("complexUpsertDialog.createSucceded", {
        id: complex.id,
        name: complex.name,
      }),
      { variant: "success" }
    );
    props.onItemUpsert(complex, true);
    props.onLoading(false);
    setLoading(false);
  };

  const updateComplex = async (item: IComplex) => {
    const complex = await complexGateway.update(item.id, {
      ...item,
      regionsIds: regions.map((value) => {
        return value.id;
      }),
    });
    snackbar.enqueueSnackbar(
      t("complexUpsertDialog.updateSucceded", {
        id: complex.id,
        name: complex.name,
      }),
      { variant: "success" }
    );
    props.onItemUpsert(complex, false);
  };

  const upsertCurrency = async (item: IComplex) => {
    try {
      if (!props.item) {
        await createComplex(item);
      } else {
        await updateComplex(item);
      }
    } catch (e) {
      const er = (e as any);
      const key = "complexErrorHandler." + er.response.data.key;
      if (er.response.data.key === "creatingEntityError") {
        const message = "validation" in er ? er.validation : t(key);
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else if (er.response.data.key === "updatingError") {
        const message =
          "validation" in er ? er.validation : t(key, { id: er.response.data.id });
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else {
        const message =
          "validation" in er ? er.validation : t("messages.defaultError");
        snackbar.enqueueSnackbar(message, { variant: "error" });
      }
    } finally {
      props.onClose();
    }
  };

  const handleAccept = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (
      !item.name ||
      // !item.direction ||
      !item.companyId ||
      !item.countryId
      // !coordinates
    ) {
      event.preventDefault();
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      return;
    }

    upsertCurrency(item);
  };

  const handleChange = (name: string, value: any) => {
    setItem({ ...item, [name]: value });
  };

  const handleChangeCountry = (name: string, value: any) => {
    setItem({ ...item, [name]: value });
    (async () => {
      const regions = await countryGateway.findAllRegions(value);
      setRegions(regions);
    })();
  };

  const handleDeleteChipRegion = (index: number) => {
    const copy = regions.filter((v, i) => i !== index);
    setRegions(copy);
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  useEffect(() => {
    if (props.item) {
      setItem(props.item);
    } else {
      setItem(initialIComplex);
    }
  }, [props.item, props.open]);

  useEffect(() => {
    (async () => {
      setCoordinates("");
      if (!props.companyId) {
        if (props.clientId){
          const companies = await companyGateway.findAll({
            clientId: props.clientId,
          });
          setCompanies(companies);
        }
      } else {
        const company = await companyGateway.findById(props.companyId);
        setCompanies([...initialCompanies, company]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyGateway, props.open]);

  useEffect(() => {
    (async () => {
      if (props.item) {
        const regions = await complexGateway.findByIdAllRegions(props.item.id);
        setRegions(regions);
      } else {
        setRegions([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    (async () => {
      const countries = await countryGateway.findAll();
      setCountries(countries);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item, props.open]);

  const handleChangeAddress = async (name: string, value: string) => {
    const addressCoordinates = await coordinatesGateway.getCoordinates(value);
    const changeCoordinates = `${addressCoordinates.lat}, ${addressCoordinates.lng}`
    handleChange(name, value);
    setCoordinates(changeCoordinates);
  }

  const handleSendBeforeTelemedidaChange = ()=>{
    setItem({...item, sendBeforeValidating: !item.sendBeforeValidating})
  };
  
  const handleSetDirection = (name: string, value: string) => {
    handleChange(name, value);
    setDirection(value);
  }

  return (
    <Box>
      <Dialog
        open={props.open && !loading}
        onClose={() => {
          props.onClose();
        }}
      >
        <DialogTitle>
          {!props.item
            ? t("complexUpsertDialog.createTitle")
            : t("complexUpsertDialog.updateTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!props.item
              ? t("complexUpsertDialog.createContent")
              : t("complexUpsertDialog.updateContent")}
          </DialogContentText>
          <ComplexForm
            companies={companies}
            countries={countries}
            item={item}
            onChange={handleChange}
            onChipRegionDelete={handleDeleteChipRegion}
            onCountryChange={handleChangeCountry}
            regions={regions}
            validateRequired={validateRequired}
            onClickAddCountryButton={handleClickAddCountryButton}
            onClickAddCompanyButton={
              props.companyId ? undefined : handleClickAddCompanyButton
            }
            coordinates={coordinates}
            handleChangeAddress={handleChangeAddress}
            handleSetDireccion={handleSetDirection}
            onSendBeforeTelemedidaChange={handleSendBeforeTelemedidaChange}
            largeXs={8}
            smallXs={4}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.onClose();
            }}
            color="primary"
          >
            {t("common.cancel")}
          </Button>
          <Button onClick={handleAccept} color="primary">
            {t("common.accept")}
          </Button>
        </DialogActions>
      </Dialog>
      <CountryUpsertDialog
        open={createCountry}
        onItemUpsert={handleCreateCountry}
        onClose={handleCloseAddCountryButton}
        item={undefined}
      />
      <CompanyAddDialog
        clientId={props.clientId}
        onClose={handleCloseAddCompanyButton}
        onItemCreated={handleCreateCompany}
        open={createCompany}
        onLoading={() => loading}
      />
    </Box>
  );
};

export default ComplexUpsertDialog;
