import React, { FC, Fragment, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid'
import { EventApi, View } from "@fullcalendar/core";
import { MarketingGateway } from "../../gateways/marketing.gateway";
import useIoC from "../../contexts/ioc.context";
import { RouteComponentProps, withRouter } from "react-router-dom";
import interactionPlugin from '@fullcalendar/interaction';
import moment from "moment";

const AgendaCalendar: FC<RouteComponentProps> = ({ history }) => {

    const height = window.innerHeight - 140;
    const [events, setEvents] = useState<({
        id: number,
        clientId: number,
        title: string,
        start: Date,
        end: Date,
        editable: boolean,
        durationEditable: boolean,
        color: string,
    })[]>([]);
    const [dateRange, setDateRange] = useState<{ from: Date, to: Date } | null>(null);
    const marketingGateway = useIoC(MarketingGateway);

    useEffect(() => {
        if (dateRange === null) {
            return;
        }

        marketingGateway.findCalendarTasks(dateRange.from, dateRange.to).then((tasks) => {
            setEvents(tasks.filter(x => !!x.taskDate).map(x => ({
                id: x.id || - 1,
                clientId: x.clientMarketing.id || -1,
                start: x.taskDate || new Date(),
                end: moment(x.taskDate || new Date()).add(20, 'minutes').toDate(),
                editable: x.completedDate === null,
                durationEditable: false,
                color: x.completedDate === null ? '#9f85c1' : (x.completedResult ? '#b1c904' : '#f50057'),
                title: x.clientMarketing.comercialName || x.clientMarketing.businessName,
            })));
        });
    }, [dateRange]);

    const handleLoadDates = (event: { view: View }) => {
        setDateRange({ from: event.view.activeStart, to: event.view.activeEnd });
    }

    const handleEventEdited = async (event: { event: EventApi, view: View }) => {
        if (event.event.start === null) {
            return;
        }
        console.log(event);
        await marketingGateway.setTaskDate(parseInt(event.event.id, 10), event.event.start);
    }

    const handleEventClick = (e: { event: EventApi }) => {
        const eventId = parseInt(e.event.id, 10);
        const event = events.find(x => x.id === eventId);
        if (!!event) {
            history.push('/marketing/clients/' + event.clientId);
        }
    }

    return (
        <div style={{ height }}>
            <FullCalendar
                defaultView="timeGridWeek"
                height="parent"
                editable={true}
                eventStartEditable={true}
                eventDurationEditable={false}
                eventDrop={handleEventEdited}
                datesRender={handleLoadDates}
                header={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'timeGridDay,timeGridWeek',
                }}
                plugins={[timeGridPlugin, interactionPlugin]}
                eventClick={handleEventClick}
                // ref={this.calendarComponentRef}
                events={events}
            // dateClick={this.handleDateClick}
            />
        </div>
    );
}

export default withRouter(AgendaCalendar);