import { AxiosInstance } from "axios";
import { inject } from "aurelia-dependency-injection";
import ICurrency from "./currency.interface";

@inject('axios')
export default class CurrencyGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<ICurrency[]> {
        const response = await this.axios.get('currencies');
        return response.data;
    }

    async findById(id: number): Promise<ICurrency> {
        const response = await this.axios.get(`currencies/${id}`)
        return response.data;
    }

    async create(partialCurrency: Partial<ICurrency>): Promise<ICurrency> {
        const response = await this.axios.post('currencies', partialCurrency);
        return response.data;
    }

    async update(id: number, partialCurrency: Partial<ICurrency>): Promise<ICurrency> {
        const response = await this.axios.put(`currencies/${id}`, partialCurrency);
        return response.data;
    }
}