import { FC, useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React from "react";
import useIoC from "../../contexts/ioc.context";
import { useTranslation } from "react-i18next";
import { IElectricSupply } from "../../gateways/electric-supply.interface";
import { IEstablishment } from "../../gateways/establishment.interface";
import SelectorField from "../../components/selector-field";
import { ElectricSupplyGateway } from '../../gateways/electric-supply.gateway';

export interface ElectricSupplyConnectDialogProps {
    open: boolean,
    item: IEstablishment
    handleClose: () => void,
}

const ElectricSupplyConnectDetailDialog: FC<ElectricSupplyConnectDialogProps> = ({ open, item, handleClose }) => {

    const { t } = useTranslation();

    const [componentDisabled, setComponentDisabled] = useState(false);
    const [electricSupplies, setElectricSupplies] = useState<IElectricSupply[]>([]);
    const [electricSupplyId,setElectricSupplyId] = useState(0);

    const electricSupplyGateway = useIoC(ElectricSupplyGateway);


    useEffect(() => {
        (async () => {
            if (item.complexId) {
                const electricSupplies = await electricSupplyGateway.findAll({ complexId: item.complexId.toString() });
                setElectricSupplies(electricSupplies);
            }
        })();
    }, [item.id]);

    const handleChange = (name: string, value: any) => {
        setElectricSupplyId(value);
    }

    const handleConnect  = () => {
        (async () => {
            const electricSupply = electricSupplies.find(value=> value.id === electricSupplyId);
            if(electricSupply){
                electricSupply.establishmentId = item.id;

                await electricSupplyGateway.update(electricSupply.id,electricSupply);
            }
            handleClose();
        })();
    };

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('electricSupplyConnectDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('electricSupplyConnectDialog.content')}</DialogContentText>
                <SelectorField
                    emptyValue={false}
                    fullWidth
                    required
                    inputLabel={t('common.electricSupply')}
                    name='electricSupplies'
                    validator={validateRequired}
                    value={electricSupplyId}
                    values={electricSupplies.map(value => {
                        return { id: value.id, value: value.referencia }
                    })}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={componentDisabled}>{t('common.cancel')}</Button>
                <Button onClick={handleConnect} color="primary" disabled={componentDisabled}>{t('common.connect')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ElectricSupplyConnectDetailDialog;