import React, { FC } from "react";
import { Box, makeStyles, SnackbarContent } from "@material-ui/core";
import { green, amber } from "@material-ui/core/colors";
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

interface IAlertBoxProps {
    variant: keyof typeof variantIcon;
}



const AlertBox: FC<IAlertBoxProps> = ({ children, variant }) => {
    const classes = useStyles();
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant])}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {children}
                </span>
            }
        />
    )
}

export default AlertBox;