import React, { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { FuelSupplyGateway } from "../../gateways/fuel-supply.gateway";
import { IFuelSupply } from "../../gateways/fuel-supply.interface";
import { List, ListItem, makeStyles, Box } from "@material-ui/core";
import ClientPeekFuelContractForm from "./client-peek-form-fuel-contract";
import ClientPeekFuelBillForm from "./client-peek-form-fuel-bill"
import { useHistory } from "react-router-dom";
import { TreeItem } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    item: {
        padding: 0,
        margin: 0,
        fontWeight: 'normal'
    }
}));

interface IClientPeekFuelSupplyFormProps {
    establishmentId?: string;
    complexId?: string;
    currentUrl: string
}

const ClientPeekFuelSupplyForm: FC<IClientPeekFuelSupplyFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [data, setData] = useState<IFuelSupply[]>([]);
    const fuelSupplyGateway = useIoC(FuelSupplyGateway);
    const history = useHistory();

    const handleClickItem = (item: IFuelSupply) => {
        history.push(computeCurrentUrl(item));
    };

    const computeCurrentUrl = (item: IFuelSupply): string => {
        return (`${props.currentUrl}/fuelSupply/${item.id}`);
    }

    useEffect(() => {
        (async () => {

            if (props.establishmentId) {
                const establishmentId = props.establishmentId;
                const data = await fuelSupplyGateway.findAll({ establishmentId });
                setData(data);

            }
            else if (props.complexId) {
                const complexId = props.complexId;
                const data = await fuelSupplyGateway.findAll({ complexId });
                setData(data);
            }
        })();
    }, [fuelSupplyGateway]);

    const handleStopPropagation = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
    }, [])

    return (
        <Box>
            {
                data.length > 0 &&
                <TreeItem nodeId={props.currentUrl + 'fuelSupply'} label={t('rightNavigation.fuelSupply')}>
                    {
                        data.map((item) => {
                            return (
                                <TreeItem nodeId={computeCurrentUrl(item)} label={<a onClick={handleStopPropagation} href={computeCurrentUrl(item)} target="_blank">{item.reference}</a>} >
                                    <ClientPeekFuelContractForm
                                        fuelSupplyId={item.id.toString()}
                                        currentUrl={computeCurrentUrl(item)}
                                    />
                                    <ClientPeekFuelBillForm
                                        fuelSupplyId={item.id.toString()}
                                        currentUrl={computeCurrentUrl(item)} />
                                </TreeItem>
                            )
                        })
                    }
                </TreeItem>
            }
        </Box>
    );
};

export default ClientPeekFuelSupplyForm;