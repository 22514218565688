import React, { FC } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
} from "@material-ui/core";
import { Link as ReactRouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBreadCrumbName } from "../contexts/breadCrumbNames.context";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import OpacityIcon from "@material-ui/icons/Opacity";
import ContactsIcon from "@material-ui/icons/Contacts";

interface IDrawerProps {
  matchUrl: string;
  lateralMenu?: number;
  icon: JSX.Element;
  goBack: boolean;
  contacts?: boolean;
}

const EstablishmentDrawer: FC<IDrawerProps> = (props) => {
  const { names: BreadCrumbNames } = useBreadCrumbName();
  const { t } = useTranslation();
  var matchUrl: string =
    props.matchUrl.replace(/[^/]/g, "").length <= 2 ||
    !isNaN(parseInt(props.matchUrl[props.matchUrl.length - 1]))
      ? props.matchUrl
      : props.matchUrl.slice(0, props.matchUrl.lastIndexOf("/"));

  return (
    <div>
      <List>
        <ListItem>
          <Grid container direction="column" alignItems="center">
            <Grid>{props.icon}</Grid>
            <Grid>
              <ListItemText primary={BreadCrumbNames["clients"]} />
            </Grid>
          </Grid>
        </ListItem>
        {props.goBack && (
          <ListItem button component={ReactRouterLink} to={`${matchUrl}`}>
            <ListItemIcon>
              <ArrowBackIosIcon />
            </ListItemIcon>
            <ListItemText primary={t("rightNavigation.back")} />
          </ListItem>
        )}
        {props.contacts && (
          <ListItem
            button
            component={ReactRouterLink}
            to={`${matchUrl}/contacts`}
            selected={props.lateralMenu === 1}
          >
            <ListItemIcon>
              <ContactsIcon />
            </ListItemIcon>
            <ListItemText primary={t("common.contacts")} />
          </ListItem>
        )}
        <ListItem
          button
          component={ReactRouterLink}
          to={`${matchUrl}/electricSupply`}
          selected={props.lateralMenu === 2}
        >
          <ListItemIcon>
            <FlashOnIcon />
          </ListItemIcon>
          <ListItemText primary={t("rightNavigation.electricSupply")} />
        </ListItem>

        <ListItem
          button
          component={ReactRouterLink}
          to={`${matchUrl}/fuelSupply`}
          selected={props.lateralMenu === 3}
        >
          <ListItemIcon>
            <LocalGasStationIcon />
          </ListItemIcon>
          <ListItemText primary={t("rightNavigation.fuelSupply")} />
        </ListItem>

        <ListItem
          button
          component={ReactRouterLink}
          to={`${matchUrl}/waterSupply`}
          selected={props.lateralMenu === 4}
        >
          <ListItemIcon>
            <OpacityIcon />
          </ListItemIcon>
          <ListItemText primary={t("rightNavigation.waterSupply")} />
        </ListItem>
      </List>
    </div>
  );
};

export default EstablishmentDrawer;
