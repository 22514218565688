import { IValidationCriteria } from "./validation-criteria.interfaces";
import { IValidationContext } from "./validation-context.interfaces";

export interface IValidationGroup {
    id: number;
    name: string;
    validationCriterias: IValidationCriteria[];
}

export interface ICreateValidationGroup {
    name: string;
    validationCriterias: IValidationCriteria[];
    validationContext: IValidationContext
}

export interface IUpdateValidationGroup {
    id: number;
    name: string;
    validationCriterias: IValidationCriteria[];
}

export const initialValidationGroup: IValidationGroup = {
    id: 0,
    name: '',
    validationCriterias: []
}