import { MongoGateway } from "../../../gateways/mongo.gateway";

export const findMongoItem = async (dbtoFind: string, where: any, mongoGateway: MongoGateway, skip: number = 0, limit: number = 0) => {
    
    if(where !== null)
    {
        try {
            return await mongoGateway.findAll({
                dbCollection: "curvas",
                dbName: dbtoFind,
                where: where ? [
                    {$match: { $or: [ JSON.parse(where)]}},
                    // {$group: {_id: "$invoiceNumber", "doc": {"$first":"$$ROOT"}}},
                    // {"$replaceRoot":{"newRoot":"$doc"}}
                  ] : undefined,
                skip,
                limit
                });
        } catch (e) {
            console.log("[findMongoItem]" + e as any);
        }
        return null;
    }else{
        return null;
    }
    

}

export const dateNormalizer = (fecha: string) =>{

    const objFecha = new Date(fecha.includes('T') ? fecha.split('T')[0] : fecha);

    const dia = objFecha.getDate().toString().padStart(2, "0");
    const mes = (objFecha.getMonth()+1).toString().padStart(2, "0");
    const ano = objFecha.getFullYear();

    return `${dia}/${mes}/${ano}`;

}

export const convertirCurvasEndesa = (item: any) => {

    const Head1 = `<table border='0'>
    <tr>
        <td>${item.username}</td>
        <TD>${dateNormalizer(new Date(Date.now()).toISOString())}</TD></tr>
    <tr>
        <td>Periodo Inicial :</td><td>&nbsp; ${dateNormalizer(item.initPeriod)}</td><td>Periodo Final:</td><td>&nbsp; ${dateNormalizer(item.endPeriod)}</td>
    </tr>
    </table>
    <br/> `;

    let Data1 = `<table border='1'> 
    <tr>
        <td>Dia</td>
        <td>Horas</td>
        <td>Cuarto</td>
        <td>Potencia:</td>
        <td>Calidad</td>
    </tr>`;

    item.curva.forEach((itm: any) => {
        const potencia = itm.potencia as number;
        const data_template = `<tr>
            <td>${dateNormalizer(itm.dia)}</td>
            <td>${itm.horas}</td>
            <td>${itm.cuarto}</td>
            <td>${potencia ? potencia * 1000 : itm.potencia}</td>
            <td>${itm.calidad}</td>
        </tr>`;
        Data1 = Data1 + data_template;
    });

    Data1 = Data1 + `</table>`;

    return Head1 + Data1;

}

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}