import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";

@inject('axios')
export class MongoGateway {

    constructor(
        private axios: AxiosInstance
    ) { }
    public async findAll(
        payload: {
            dbName: string,
            dbCollection: string,
            where: object | undefined,
            skip?: number,
            limit?: number
        }) {
        const response = await this.axios.post('mongo/aggregateManyItems', payload);
        return response.data as [];
    }

    public async findManyItems(
        payload: {
            dbName: string,
            dbCollection: string,
            where: object,
            skip?: number,
            limit?: number
        }) {
        const response = await this.axios.post('mongo/findManyItems', payload);
        return response.data;
    }

    public async editCollection(
        payload: {
            dbName: string,
            dbCollection: string,
            id: string,
            item: object,
            where: object
        }) {
        const response = await this.axios.put('mongo/', payload);
        return response.data;
    }

    public async editCollections(
        payload: {
            dbName: string,
            dbCollection: string,
            id: string,
            item: object,
            where: object
        }
    ) {
        const response = await this.axios.put('mongo/many', payload);
        return response.data;
    }

    manageReferences(reference: string, option: string) {

        if (option !== "insert" && option !== "delete") {
            console.log("Use a correct option");
            return;
        }

        if (option === "insert") {
            let newReferences = reference.length === 20 ? [{ _id: reference }, { _id: reference + "0F" }] :
                reference.length === 22 ? [{ _id: reference }, { _id: reference.slice(0, 20) }] :
                    [{ _id: reference }];
            this.insertToBlacklist(newReferences);
        }
        else {
            let newReferences = reference.length === 20 ? [reference, reference + "0F"] :
                reference.length === 22 ? [reference, reference.slice(0, 20)] :
                    [reference];
            this.deleteFromBlacklist(newReferences);
        }
    }

    async insertToBlacklist(item: { _id: string }[]) {
        await this.axios.post("mongo/insert_item_blacklist", item);
    }

    async deleteFromBlacklist(item: string[]) {
        await this.axios.post('mongo/delete_item_blacklist', item);
    }

    async findOneItem(filter: any) {
        const payload = {
            dbName: "operations",
            dbCollection: "create_invoice_requests",
            filter
        }
        const response = await this.axios.post("mongo/findOne", payload);
        return response.data;
    }

    async consultBlacklist(item: any) {
        return await this.axios.post(
            "mongo/findManyItems",
            {
                dbName: "operations",
                dbCollection: "blackList",
                where: item
            });
    }
}