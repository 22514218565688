import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React from "react";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { EstablishmentTypeGateway } from "../../gateways/establishment-type.gateway";
import { IEstablishmentType } from "../../gateways/establishment-type.interface";

export interface IEstablishmentTypeDeleteDialogProps {
  open: boolean;
  item: IEstablishmentType | undefined;
  onClose: () => void;
  onDeleted: (item: IEstablishmentType) => void;
}

const EstablishmentTypeDeleteDialog: FC<IEstablishmentTypeDeleteDialogProps> = ({ open, onClose: handleClose, onDeleted: handleDeleted, item }) => {
  const [componentDisabled, setComponentDisabled] = useState(false);
  const establishmentTypeGateway = useIoC(EstablishmentTypeGateway);
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const handleDeleteContact = () => {
    if (item === undefined || item.id === 0) {
      return;
    }
    setComponentDisabled(true);
    (async () => {
      try {
        await establishmentTypeGateway.delete(item.id);
        snackbar.enqueueSnackbar(t('establishmentTypeDeleteDialog.deleteSucceded', { id: item.id, name: item.name }), { variant: "success" });
        handleDeleted(item);
      } catch (e) {
        const er = e as any;
        if (er.response.data.key === 'deletingError'){
          const key = 'establishmentTypeErrorHandler.' + er.response.data.key; 
          const message = "validation" in er ? er.validation : t(key, {id: er.response.data.id});
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }else{
          const message = "validation" in er ? er.validation : t('messages.defaultError');
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      } finally {
        setComponentDisabled(false);
        handleClose();
      }
    })();
  };

  return (
    <Dialog open={open} onClose={() => { handleClose() }} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('establishmentTypeDeleteDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('establishmentTypeDeleteDialog.content')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { handleClose() }} color="primary" disabled={componentDisabled}>{t('common.cancel')}</Button>
        <Button onClick={handleDeleteContact} color="primary" disabled={componentDisabled}>{t('common.accept')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EstablishmentTypeDeleteDialog;
