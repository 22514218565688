import { FC } from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useNavigator } from '../contexts/navigator.context';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    titleIcon: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 45,
        width: 45,
        marginRight: 10,
    },
    titleText: {
        display: 'flex',
        alignItems: 'center',
    }
}));

const PageHeader: FC = () => {
    const [navigator] = useNavigator();
    const classes = useStyles();

    return navigator.header ? (
        <Box className={classes.root}>
            <Typography component="h1" className={classes.titleText}>
                <Box component="span" className={classes.titleIcon}>
                    <navigator.header.icon />
                </Box>
                {navigator.header.title}
            </Typography>
        </Box>
    ) : null;
}

export default PageHeader;