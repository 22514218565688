import React, { FC, useMemo } from "react";
import FSTable from "../../components/fs-table";
import { RouteComponentProps } from "react-router-dom";
import GroupWorkIcon from '@material-ui/icons/GroupWork';

const CampaignDetail: FC<RouteComponentProps<{ campaignId: string }>> = ({ match, history }) => {

    const campaignId = useMemo(() => parseInt(match.params.campaignId), [match.params]);



    const handleNavigateClient = (item: any) => {
        history.push('/marketing/clients/' + item.id);
    }

    return (<>

        <FSTable
            repositoryId="comercial-campaigns-clients"
            onRowClick={handleNavigateClient}
            params={{ campaignId }}
            title='Listado de potenciales clientes'
            columns={[
                { name: 'comercialName', label: 'Nombre Comercial', sortable: true, },
                { name: 'resultName', label: 'Prox. acción', sortable: true, },
                { name: 'taskDate', label: 'F. Prox. Acción', sortable: true, },

                { name: 'estado', label: 'Estado', sortable: true, },

                { name: 'averageInvoice', label: 'Fact Media', sortable: true, },
                { name: 'potentalSavings', label: 'Ahorro potencial', sortable: true, },
                { name: 'opportunityFees', label: 'Oport. Honorarios', sortable: true, },

                { name: 'filterName', label: 'Nombre del Filtro', sortable: true, },
                { name: 'decisorName', label: 'Nombre del Decisor', sortable: true, },

                { name: 'accionesComercialesRealizadas', label: 'Comercializadora', sortable: true, },
            ]}
        />
    </>);
}

export default CampaignDetail;