import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../../../../../components/form-text-field";
import { IClient } from "../../../../../../gateways/clients.interfaces";
import ICompany from "../../../../../../gateways/company.interface";
import { IComplex } from "../../../../../../gateways/complex.interfaces";
import { IEstablishment } from "../../../../../../gateways/establishment.interface";
import { IUpdateFuelSupply } from "../../../../../../gateways/fuel-supply.interface";
import ClientCpanyEstCplexForm from "../../../util-errors/clientCpanyEstCplex";

interface IFuelSupplyProps {
  supply: IUpdateFuelSupply;
  onChangeSupply: (supply: IUpdateFuelSupply) => void;

  selectedClient?: IClient | null;
  selectedCompany?: ICompany | null;
  selectedComplex?: IComplex | null;
  selectedEstablishment?: IEstablishment | null;

  clients: IClient[];
  companies: ICompany[];
  complexes: IComplex[];
  establishments: IEstablishment[];

  errorMissingData: boolean;
  validateRequired: (value: any) => boolean;

  onSelectClient: (client: IClient) => void;
  onSelectCompany: (company: ICompany) => void;
  onClickAddCompanyButton?: () => void;
  onClickAddEstablishmentButton: () => void;
  onClickAddComplexButton?: () => void;
  onClickAddClientButton?: () => void;
}

const FuelSupplyCompleteForm: FC<IFuelSupplyProps> = ({
  supply,
  onChangeSupply,

  selectedClient,
  selectedCompany,
  selectedComplex,
  selectedEstablishment,

  clients,
  companies,
  complexes,
  establishments,
  
  errorMissingData,
  validateRequired,

  onSelectClient,
  onSelectCompany,
  onClickAddClientButton,
  onClickAddCompanyButton,
  onClickAddComplexButton,
  onClickAddEstablishmentButton,
}) => {
  const { t } = useTranslation();
  const handleSelectComplex = (complex: IComplex) => {
    onChangeSupply({
      ...supply,
      establishmentId: null,
      complexId: complex.id,
    })
  };

  const handleSelectEstablishment = (establishment: IEstablishment) =>
    onChangeSupply({
      ...supply,
      establishmentId: establishment.id,
      complexId: null,
    });
  
  const handleChangeSingleSupplyElement = (name: string, value: any) => {
    onChangeSupply({ ...supply, [name]: value });
  };

  return (
    <div>
      <FormTextField
        name="reference"
        label={t("fuelSupplyUpsertDialog.reference")}
        type="text"
        validator={validateRequired}
        error={errorMissingData}
        errorText={t("messages.requiredField") as string}
        value={supply.reference}
        onChange={handleChangeSingleSupplyElement}
        fullWidth
        required
        autoFocus
      />

      <FormTextField
        disabled
        name="invoicesFolder"
        label={t("common.invoicesFolder")}
        type="text"
        validator={validateRequired}
        error={errorMissingData}
        errorText={t("messages.requiredField") as string}
        value={supply.invoicesFolder ? supply.invoicesFolder : ""}
        onChange={handleChangeSingleSupplyElement}
        fullWidth
        required
      />

      <ClientCpanyEstCplexForm
        clients={clients}
        companies={companies}
        complexes={complexes}
        errorMissingData={errorMissingData}
        establishments={establishments}
        clientSelected={selectedClient}
        companySelected={selectedCompany}
        complexSelected={selectedComplex}
        establishmentSelected={selectedEstablishment}
        validateRequired={validateRequired}
        onSelectClient={onSelectClient}
        onSelectCompany={onSelectCompany}
        onSelectComplex={handleSelectComplex}
        onSelectEstablishment={handleSelectEstablishment}
        onClickAddCompanyButton={onClickAddCompanyButton}
        onClickAddComplexButton={onClickAddComplexButton}
        onClickAddEstablishmentButton={onClickAddEstablishmentButton}
        onClickAddClientButton={onClickAddClientButton}
      />
    </div>
  );
};

export default FuelSupplyCompleteForm;
