import React, { useContext } from "react";
import { Switch, Route, useRouteMatch } from "react-router";
import EstablishmentDetail from "./establishment-detail";
import EstablishmentList from "./establishment-list";
import ElectricSupplyRouter from "../electric-supply/electric-supply-router";
import FuelSupplyRouter from "../fuel-supply/fuel-supply-router";
import { EstablishmentWrapper } from './establishment-wrapper'

const EstablishmentRouter = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/`} exact>
                <EstablishmentList />
            </Route>

            <Route path={`${path}/:establishmentId`} component={EstablishmentWrapper} />
        </Switch>
    );
};
export default EstablishmentRouter;
