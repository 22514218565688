import React, { useEffect } from "react";
import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles } from "@material-ui/core";
import { IValidationGroup, initialValidationGroup } from "../../gateways/validation-group.interfaces";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ValidationGroupGateway } from "../../gateways/validation-group.gateway";
import ValidationCriteriaForm from "./validation-group-form";

// const useStyles = makeStyles((theme) => ({
//     button: {
//         margin: theme.spacing(1),
//     },
// }));

export interface IValdiationCriteriaUpdateDialogProps {
    open: boolean;
    validationGroup: IValidationGroup;
    onUpdate: (item: IValidationGroup) => void;
    onClose: () => void;
}

const ValidationGroupUpdateDialog: FC<IValdiationCriteriaUpdateDialogProps> = ({ open, validationGroup, onClose, onUpdate }) => {
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const [item, setItem] = useState<IValidationGroup>(initialValidationGroup);
    const validationGroupGateway = useIoC(ValidationGroupGateway);

    useEffect(() => {
        setItem(validationGroup);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleChange = (item: IValidationGroup) => {
        setItem(item);
    }

    const handleClose = () => {
        onClose();
    };

    const handleUpdate = () => {
        (async () => {

            try {
                const validationGroupUpdated = await validationGroupGateway.update(validationGroup.id, {
                    id: validationGroup.id,
                    name: item.name,
                    validationCriterias: item.validationCriterias

                });
                onUpdate(validationGroupUpdated);
                snackbar.enqueueSnackbar(t('validationContextUpdateDialog.updateSucceded', { name: item.name }), { variant: "success" });
            } catch (e) {
                const er = e as any;
                if (er.response.data.key === 'creatingEntityError') {
                    const key = 'sectorErrorHandler.' + er.response.data.key;
                    const message = "validation" in er ? er.validation : t(key);
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                } else {
                    const message = "validation" in er ? er.validation : t('messages.defaultError');
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                }
            } finally {
                onClose();
            }

        })();
    }


    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
            <DialogTitle id="form-dialog-title">{t('validationContextUpdateDialog.title')}</DialogTitle>
            <DialogContent>
                <ValidationCriteriaForm
                    item={item}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="default">
                    {t('common.cancel')}
                </Button>
                <Button onClick={handleUpdate} color="primary">
                    {t('common.update')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ValidationGroupUpdateDialog;