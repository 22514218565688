import React, { FC } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, Theme, createStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ClientPeekForm from "./client-peek-form"
import { IClient } from "../../gateways/clients.interfaces";

interface IClientPeekDialogProps {
    open: boolean;
    onClose: () => void;
    client: IClient;
}


const ClientPeekDialog: FC<IClientPeekDialogProps> = (props) => {
    const { t } = useTranslation();
    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }}>
            <DialogTitle>{t('clientPeekUpsertDialog.title', {client: props.client.fullname})}</DialogTitle>
            <DialogContent>
                <ClientPeekForm
                    clientId={props.client.id.toString()}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onClose() }} color="primary">{t('common.close')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ClientPeekDialog;