export interface IEstablishment {
    id: number;
    name: string;
    brandName: string;
    establishmentTypeId: number;
    address: string;
    client_email: string;
    companyId: number;
    complexId: number | null;
    coordinatesId?: number | null;
    sendBeforeValidating: boolean;
    cancelledDate?: Date | null;
}
export interface ICreateEstablishment {
    brandName: string;
    name: string;
    establishmentTypeId: number;
    address: string;
    client_email: string;
    companyId: number;
    complexId: number | null;
    coordinatesId?: number | null;
    sendBeforeValidating: boolean;
    cancelledDate?: Date | null;
}
export interface IUpdateEstablishment {
    brandName: string;
    name: string;
    establishmentTypeId: number;
    address: string;
    client_email: string;
    companyId: number;
    complexId: number | null;
    coordinatesId?: number | null;
    sendBeforeValidating: boolean;
}

export const initialIEstablisment: IEstablishment = {
    id: 0,
    name: '',
    brandName: '',
    establishmentTypeId: 0,
    address: '',
    client_email: '',
    companyId: 0,
    complexId: 0,
    sendBeforeValidating: true
}