import React, { FC, Fragment, useCallback, useMemo } from "react";
import { Drawer, makeStyles, List, ListItem, ListItemIcon, ListItemText, Hidden, Fab, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useNavigator } from '../contexts/navigator.context';
import MaintenaceIcon from '@material-ui/icons/Build';
import ClientIcon from '@material-ui/icons/People';
import BotIcon from '@material-ui/icons/Android';
import MarketingIcon from '@material-ui/icons/BusinessCenter';
import HomeIcon from '@material-ui/icons/Home';
import { useTranslation } from "react-i18next";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { ExpandableList } from "../components/expandable-list";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AddIcon from "@material-ui/icons/Add";
import { useUser } from "../contexts/user.context";

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: 240,
            flexShrink: 0,
        },
    },
    toolbar: theme.mixins.toolbar as CSSProperties,
    drawerPaper: {
        width: 240,
    },
    // manualInvoiceButton: {
    //     position: "fixed",
    //     bottom: "0%",
    //     margin: "0.9%"
    // },
    lastListItem: {
        '& > .MuiListItem-root': {
            paddingLeft: theme.spacing(4),
        },
        marginBottom: "30%",
    }
}));

export const Navigator: FC = () => {
    const [{ canViewBotsMenu,
        canViewCurvesMenu,
        canViewHiringBoard, 
        canViewClientsList, 
        canViewProvidersList, 
        canViewMaintenanceMenu, 
        canViewESM_Menu,
        canViewManualInvoice,
        subCanViewBotMissingDate,
        subCanViewSignatureGenerator,
        subCanViewMaintenanceRestOfItems,
        subCanViewCoordenadas,
        subCanViewOneDriveConverter
    }] = useUser();
    const [{ navOpen }, navDispatch] = useNavigator();
    const { t, i18n } = useTranslation();
    const language = React.useRef(i18n.language);

    //test i18n if works
    // console.log('Current Lng: ' + language.current);
    // console.log('t(navigation.home): ' + i18n.t('navigation.home'));

    const classes = useStyles();

    const handleClose = useCallback(() => {
        navDispatch({ type: 'close-nav' });
    }, [navDispatch]);

    //ToDo more languages
    const handleChangeLanguage = () => {
        const newLanguage = language.current === "es" ? "en" : "es";//ToDo en/cat/...etc
        language.current = newLanguage;
        i18n.changeLanguage(language.current);
      };

    const drawer = useMemo(() => (
        <div>
            <List component="nav">
                <ListItem button component={Link} to="/">
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary={t('navigation.home')} />
                </ListItem>

                <ListItem button component={Link} to="/new-manual-invoice">
                    <ListItemIcon><AddIcon /></ListItemIcon>
                    <ListItemText primary={t('navigation.addManualInvoice')} />
                </ListItem>

                {canViewBotsMenu || canViewCurvesMenu? <ExpandableList icon={BotIcon} text={t('navigation.bots')}>
                    {canViewBotsMenu ?<ListItem button component={Link} to="/dashboards/bots">
                        <ListItemText primary={t('navigation.dashboard')} />
                    </ListItem> : <Fragment/>}
                    {canViewBotsMenu ?<ListItem button component={Link} to="/preconfig-bots">
                        <ListItemText primary={t('navigation.preConfig')} />
                    </ListItem>: <Fragment/>}
                    {canViewBotsMenu ?<ListItem button component={Link} to="/supply-bots">
                        <ListItemText primary={t('navigation.supplyBots')} />
                    </ListItem>: <Fragment/>}
                    {canViewBotsMenu ?<ListItem button component={Link} to="/providers-bots">
                        <ListItemText primary="Proveedores (bots)"/>
                    </ListItem>: <Fragment/>}
                    {canViewBotsMenu ?<ListItem button component={Link} to="/jira">
                        <ListItemText primary={t('navigation.jiraMenu')} />
                    </ListItem>: <Fragment/>}
                    {canViewBotsMenu || canViewCurvesMenu ?<ListItem button component={Link} to="/curvas">
                        <ListItemText primary={t('navigation.buscadorCurvas')} />
                    </ListItem>: <Fragment/>}
                </ExpandableList> : <Fragment/>}
                
                {canViewHiringBoard ? <ListItem button component={Link} to="/hiring-dashboard">
                    <ListItemIcon><DashboardIcon /></ListItemIcon>
                    <ListItemText primary={t('navigation.hiring-dashboard')} />
                </ListItem> : <Fragment/>}

                {canViewClientsList ? <ListItem button component={Link} to="/clients">
                    <ListItemIcon><ClientIcon /></ListItemIcon>
                    <ListItemText primary={t('navigation.clients')} />
                </ListItem> : <Fragment/>}

                {canViewProvidersList ? <ListItem button component={Link} to="/providers">
                    <ListItemIcon><LocalShippingIcon /></ListItemIcon>
                    <ListItemText primary={t('navigation.providers')} />
                </ListItem> : <Fragment/>}

                {canViewMaintenanceMenu ? <ExpandableList icon={MaintenaceIcon} text={t('navigation.maintenances')}>
                    {subCanViewMaintenanceRestOfItems ? <ListItem button component={Link} to="/contact-types">
                        <ListItemText primary={t('navigation.contactTypes')} />
                    </ListItem> : <Fragment/>}
                    {subCanViewMaintenanceRestOfItems ? <ListItem button component={Link} to="/sectors">
                        <ListItemText primary={t('navigation.sectors')} />
                    </ListItem> : <Fragment/>}
                    {subCanViewMaintenanceRestOfItems ? <ListItem button component={Link} to="/volume-units">
                        <ListItemText primary={t('navigation.volumeUnits')} />
                    </ListItem> : <Fragment/>}
                    {subCanViewMaintenanceRestOfItems ? <ListItem button component={Link} to="/currencies">
                        <ListItemText primary={t('navigation.currencies')} />
                    </ListItem> : <Fragment/>}
                    {subCanViewMaintenanceRestOfItems ? <ListItem button component={Link} to="/countries">
                        <ListItemText primary={t('navigation.countries')} />
                    </ListItem> : <Fragment/>}
                    {subCanViewMaintenanceRestOfItems ? <ListItem button component={Link} to="/establishment-type">
                        <ListItemText primary={t('navigation.establishmentType')} />
                    </ListItem> : <Fragment/>}
                    {subCanViewMaintenanceRestOfItems ? <ListItem button component={Link} to="/repository-definition">
                        <ListItemText primary={t('navigation.repositoryDefinition')} />
                    </ListItem> : <Fragment/>}
                    {subCanViewMaintenanceRestOfItems ? <ListItem button component={Link} to="/validations">
                        <ListItemText primary={t('navigation.validations')} />
                    </ListItem> : <Fragment/>}
                    {subCanViewMaintenanceRestOfItems || subCanViewCoordenadas ? <ListItem button component={Link} to="/coordinates">
                        <ListItemText primary={t('common.coordinates')} />
                    </ListItem> : <Fragment/>}
                    {subCanViewMaintenanceRestOfItems ? <ListItem button component={Link} to="/operate">
                        <ListItemText primary={t('common.info-bot')} />
                    </ListItem>: <Fragment/>}
                    {subCanViewBotMissingDate ? <ListItem button component={Link} to="/bot-missing-dates">
                        <ListItemText primary={t('common.completeMissingDates')} />
                    </ListItem>: <Fragment/>}
                    {subCanViewSignatureGenerator ? <ListItem button component={Link} to="/generate-signature">
                        <ListItemText primary={t('common.signatureGenerator')} />
                    </ListItem>: <Fragment/>}
                    {subCanViewOneDriveConverter ? <ListItem button component={Link} to="/onedrive-path">
                        <ListItemText primary={t('navigation.onedrivePath')} />
                    </ListItem>: <Fragment/>}
                    {subCanViewMaintenanceRestOfItems ? <ListItem button component={Link} to="/prometheus">
                        <ListItemText primary={t('common.prometheus')} />
                    </ListItem>: <Fragment/>}
                </ExpandableList> : <Fragment/>}

                {canViewESM_Menu ? <ExpandableList icon={MarketingIcon} text={t('navigation.marketing')}>
                    <ListItem button component={Link} to="/marketing/clients">
                        <ListItemText primary={t('navigation.clients')} />
                    </ListItem>
                    <ListItem button component={Link} to="/marketing/campaigns-admin">
                        <ListItemText primary={t('navigation.admin_campaigns')} />
                    </ListItem>
                    <ListItem button component={Link} to="/marketing/agenda">
                        <ListItemText primary={t('navigation.agenda')} />
                    </ListItem>
                    <ListItem button component={Link} to="/marketing/campaigns">
                        <ListItemText primary={t('navigation.campaigns')} />
                    </ListItem>
                    <ListItem button component={Link} to="/marketing/dashboard">
                        <ListItemText primary={'Cuadro de mandos'} />
                    </ListItem>
                    <ListItem button component={Link} to="/marketing/esm-google-query">
                        <ListItemText primary={t('navigation.esm-google-query')} />
                    </ListItem>
                </ExpandableList> : <Fragment/>}

            </List>
            {/* {canViewManualInvoice ? <Button 
                color="primary" 
                variant="contained"
                startIcon={<AddIcon />} 
                aria-label="add"
                component={Link}
                to="/new-manual-invoice"
                className={classes.manualInvoiceButton} 
            >
                {t('navigation.addManualInvoice')}
            </Button> : <Fragment/>} */}
        </div >
    ), []);

    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden smUp implementation="css">
                <Drawer
                    open={navOpen}
                    onClose={handleClose}
                    variant="temporary"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    <div className={classes.toolbar} />
                    {drawer}
                </Drawer>
            </Hidden>
        </nav>
    );
}

