import React, { FC } from "react";
import { withRouter, RouteComponentProps, Route, Switch } from "react-router";
import ESMGQCallAndExport from "./esmgq-call-and-export";
import ESMGQCreate from "./esmgq-create";

const ESMGQIndex: FC<RouteComponentProps> = ({ match }) => {
    return(
        <Switch>
            <Route path={`${match.path}`} exact component={ESMGQCallAndExport} />
            <Route path={`${match.path}/create` } exact component={ESMGQCreate} />
        </Switch>
    );
}

export default withRouter(ESMGQIndex);