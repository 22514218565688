import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  createStyles,
  makeStyles,
  Theme,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Button from "@material-ui/core/Button";
import {
  IOperateVariables,
  SupplyTypes,
} from "../../../../gateways/operate.interface";
import ElectricityIcon from "@material-ui/icons/FlashOn";
import FuelIcon from "@material-ui/icons/LocalGasStation";
import DropIcon from "@material-ui/icons/Opacity";
import CloseIcon from '@material-ui/icons/Close';
import supplyComponentTypes from "./supply-types";
import { IFormBaseRefAttributes } from "./supply-types/interfaces";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonItem: {
      margin: 10,
      borderSpacing: 1,
      padding: theme.spacing(1),
    },
    crossButton: {
      position: 'absolute',
      right: 16,
      top: 12
    },
    buttonAccept: {
      margin: 20,
      borderSpacing: 4,
      padding: theme.spacing(1),    
    }
  })
);

interface IInfoBotDialogProps {
  open: boolean;
  operate: IOperateVariables;
  onClose: () => void;
  icon: JSX.Element;
}

function getNormalizedSupplyType(supplyType: string): SupplyTypes | null {
  if (!supplyType) return null;

  supplyType = supplyType.toLowerCase();

  if (supplyType.includes("electric")) {
    return "electric";
  } else if (
    supplyType.includes("fuel")) {
    return "fuel";
  } else if (supplyType.includes("water")) {
    return "water";
  } else {
    return null;
  }
}

const SelectNewSupplyType: FC<{
  onSelectSupplyType: (supplyType: SupplyTypes) => void;
}> = ({ onSelectSupplyType }) => {
  const classes = useStyles();
  const handleSelectSupplyType = (supplyType: SupplyTypes) => () =>
    onSelectSupplyType(supplyType);

  return (
    <>
      <Button
        aria-label="Edita Electricidad"
        variant="contained"
        className={classes.buttonItem}
        onClick={handleSelectSupplyType("electric")}
        style={{ marginLeft: 115}}
      >
        <ElectricityIcon style={{ fontSize: 20, color: "orange"  }} />
        Electricidad
      </Button>

      <Button
        aria-label="Edita Gas"
        
        variant="contained"
        className={classes.buttonItem}
        onClick={handleSelectSupplyType("fuel")}
      >
        <FuelIcon style={{ fontSize: 20, color: "SlateGrey" }} />
        Gas
      </Button>

      <Button
        aria-label="Edita Agua"
       
        variant="contained"
        className={classes.buttonItem}
        onClick={handleSelectSupplyType("water")}
      >
        <DropIcon style={{ fontSize: 20, color: "CornflowerBlue" }} />
        Agua
      </Button>
    </>
  );
};

const InfoBotDialogNoDate: FC<IInfoBotDialogProps> = ({
  open,
  onClose,
  operate,
  icon
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  // const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [supplyType, setSupplyType] = useState<SupplyTypes | null>(null);
  const isSpecificSupplyType = getNormalizedSupplyType(operate?.supplyType) !== null;

  const formComponentRef = useRef<IFormBaseRefAttributes>(null);
  const FormComponent = supplyType ? supplyComponentTypes[supplyType] : null;


  const handleSelectSupplyType = (supplyType: SupplyTypes) =>
    setSupplyType(supplyType);

  const handleUnselectSupplyType = () => setSupplyType(null);

  const handleAccept = async () => {
    setLoading(true);
    await formComponentRef?.current?.accept();
    setLoading(false);
  };

  const handleCloseAll = () => {
    handleUnselectSupplyType();
    onClose();
  };

  useEffect(() => {
    setLoading(true);
    const operateSupplyType = getNormalizedSupplyType(operate?.supplyType);
    setSupplyType(operateSupplyType);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {icon}
          {t(`bot_f.dialog.errorNoDate.${supplyType}.title`)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t(`bot_f.dialog.errorNoDate.${supplyType}.content`)}</DialogContentText>
          {isSpecificSupplyType && FormComponent ? (
            <FormComponent ref={formComponentRef} operate={operate} onClose={onClose} onCreate={handleCloseAll}/>
          ) : (
            <>
              {onClose ? (
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  className={classes.crossButton}
                >
                  <CloseIcon/>
                </IconButton>
                ) : null
              }
              <SelectNewSupplyType onSelectSupplyType={handleSelectSupplyType}/>
            </>
          )}
        </DialogContent>
        {isSpecificSupplyType && FormComponent ? (
          <DialogActions>
            {onClose ? (
              <IconButton
                aria-label="close"
                onClick={onClose}
                disabled={loading}
                className={classes.crossButton}
              >
                <CloseIcon/>
              </IconButton>
              ) : null
            }
            <Button onClick={handleAccept} disabled={loading} variant="outlined" color="primary" className={classes.buttonAccept}>
              {t("bot_f.button.update")}
            </Button>
          </DialogActions>
        ) : null
        }
      </Dialog>

      {!isSpecificSupplyType && (
        <Dialog
          open={Boolean(FormComponent)}
          onClose={handleUnselectSupplyType}
        >
          <DialogTitle>
            {icon}
            {t(`bot_f.dialog.errorNoDate.${supplyType}.title`)}
          </DialogTitle>
          <DialogContent>
          <DialogContentText>{t(`bot_f.dialog.errorNoDate.${supplyType}.content`)}</DialogContentText>
            {FormComponent && (
              <FormComponent ref={formComponentRef} operate={operate} onClose={handleUnselectSupplyType} onCreate={handleCloseAll}/>
            )}
          </DialogContent>
          <DialogActions>
           {handleUnselectSupplyType ? (
              <IconButton
                aria-label="close"
                onClick={handleUnselectSupplyType}
                disabled={loading}
                className={classes.crossButton}
              >
                <CloseIcon/>
              </IconButton>
              ) : null
            }
            <Button onClick={handleAccept} disabled={loading} variant="outlined" color="primary" className={classes.buttonAccept}>
              {t("bot_f.button.update")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default InfoBotDialogNoDate;
