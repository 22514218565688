import React, { FC } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import FormTextField from "../../components/form-text-field";
import { useTranslation } from "react-i18next";
import { IRule } from "../../gateways/rules.interfaces";

const useStyles = makeStyles((theme) => ({

    fields: {
        border: theme.spacing(1)
    }
}));

interface IRuleItemProps {
    item: IRule;
    onChange: (name: string, value: any) => void
}

const RuleItem: FC<IRuleItemProps> = ({item, onChange}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    return (
        <Grid container direction="row">
            <Grid item  >
                <FormTextField
                    name='name'
                    label={t('common.name') as string}
                    type='text'
                    validator={validateRequired}
                    errorText={t('messages.requiredField') as string}
                    value={item.name}
                    onChange={onChange}
                    fullWidth
                    required
                    className={classes.fields}
                />
            </Grid>

            <Grid item xs >
                <FormTextField
                    name='regex'
                    label={t('common.regex') as string}
                    type='text'
                    validator={validateRequired}
                    errorText={t('messages.requiredField') as string}
                    value={item.regex}
                    onChange={onChange}
                    fullWidth
                    required
                    multiline
                    className={classes.fields}
                />
            </Grid>

        </Grid>
    );
};
export default RuleItem;