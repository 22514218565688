import React, { FC } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import InvoiceIcon from '@material-ui/icons/PictureAsPdf';
import ProfileIcon from '@material-ui/icons/Assignment';
import HistoryIcon from '@material-ui/icons/History';
import { Link } from "react-router-dom";

interface ISupplyBotDrawerProps {
    id: number;
    tabActive: 'profile' | 'history' | 'invoices';
}

export const SupplyBotDrawer: FC<ISupplyBotDrawerProps> = ({ id, tabActive }) => (
    <div>
        <List>
            <ListItem selected={tabActive === 'profile'} button component={Link} to={`/supply-bots/${id}`}>
                <ListItemIcon><ProfileIcon /></ListItemIcon>
                <ListItemText primary="Ficha" />
            </ListItem>
            <ListItem selected={tabActive === 'history'} button component={Link} to={`/supply-bots/${id}/history`}>
                <ListItemIcon><HistoryIcon /></ListItemIcon>
                <ListItemText primary="Historico" />
            </ListItem>
            <ListItem selected={tabActive === 'invoices'} button component={Link} to="/complexs">
                <ListItemIcon><InvoiceIcon /></ListItemIcon>
                <ListItemText primary="Facturas" />
            </ListItem>
        </List>
    </div>
);