import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IOperator } from "./operators.interface";

@inject('axios')
export class OperatorGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IOperator[]> {
        const response = await this.axios.get('operators');
        return response.data;
    }

}