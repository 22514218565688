import React, { Reducer, Dispatch, ReducerState, ReducerAction, createContext, useReducer, FC } from "react";


function createGlobalReducerContext<TState, TActions>(reducer: Reducer<TState, TActions>, state: ReducerState<Reducer<TState, TActions>>) {
    type ContextDispatchAction = Dispatch<ReducerAction<typeof reducer>>;
    type ContextType = [typeof state, ContextDispatchAction];
    const Context = createContext<ContextType>([state, (s) => s]);

    const Provider: FC = ({ children }) => {
        const r = useReducer(reducer, state);
        return (
            <Context.Provider value={r}>
                {children}
            </Context.Provider>
        );
    }

    const useContext = () => React.useContext(Context);
    
    const result: [typeof useContext, typeof Provider] = [
        useContext,
        Provider
    ];

    return result;
}

export default createGlobalReducerContext;