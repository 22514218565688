import React, { FC, useCallback } from "react";
import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export const CoordiantesLayout: FC<{ page: "pending" | "tasks" }> = ({
  page,
  children,
}) => {
  const history = useHistory();

  const handleChange = useCallback(
    (_, value: string) => {
      if (value === page) return;
      switch (value) {
        case "pending":
          history.push("/coordinates/");
          break;
        case "tasks":
          history.push("/coordinates/tasks");
          break;
      }
    },
    [page, history]
  );

  return (
    <Box>
      <AppBar position="static">
        <Tabs
          value={page}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Pendientes de asignar" value="pending" />
          <Tab label="Tareas pendientes" value="tasks" />
        </Tabs>
      </AppBar>
      <Box mt={2}>{children}</Box>
    </Box>
  );
};
