import React, { useState, useMemo, useEffect } from 'react';
import CenteredCircularProgress from '../../components/centered-circular-progress';
import AlertBox from '../../components/alert-box';
import { makeStyles, List, Divider, Box } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import { useTranslation } from 'react-i18next';
import useIoC from '../../contexts/ioc.context';
import { useParams, useHistory, useRouteMatch } from "react-router";
import FuelSupplyUpsertDialog from './fuel-supply-upsert-dialog';
import FuelSupplyGoDetailDialog from './fuel-supply-go-detail-dialog';
import { IEstablishment, initialIEstablisment } from '../../gateways/establishment.interface';
import { IFuelSupply } from '../../gateways/fuel-supply.interface';
import { FuelSupplyGateway } from '../../gateways/fuel-supply.gateway';
import FuelSupplyItem from './fuel-supply-item';
import FuelSupplyGoEstablishmentDetailDialog from './fuel-supply-go-establishment-detail-dialog';
import FuelSupplyConnectDetailDialog from './fuel-supply-connect-dialog';
import ScreenGlobalStructure from '../../components/screen-global-structure';
import EstablishmentIcon from '@material-ui/icons/Store';
import ComplexIcon from '@material-ui/icons/Dashboard';
import EstablishmentDrawer from '../../components/establishment-drawer';
import ComplexDrawer from '../../components/complex-drawer';
import { BlacklistGateway } from '../../gateways/blacklist.gateway';

interface IFuelSupplyRouteParams {
    [x: string]: string | undefined;
    complexId: string;
    establishmentId: string;
    clientId: string;
}

interface IBlacklistItems {
    itemsInBlacklist: string [];
}

const initialBlacklistItems: IBlacklistItems = {
    itemsInBlacklist: []
}

const useStyles = makeStyles((theme) => ({
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

const FuelSupplyList = () => {
    const classes = useStyles();
    const { complexId } = useParams<IFuelSupplyRouteParams>();
    const { establishmentId } = useParams<IFuelSupplyRouteParams>();
    const { clientId } = useParams<IFuelSupplyRouteParams>();

    const history = useHistory();
    const { url } = useRouteMatch();
    const { t } = useTranslation();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState<IFuelSupply[]>([]);
    const [openUpsertDialog, setOpenUpsertDialog] = useState(false);
    const [openGoDetailDialog, setOpenGoDetailDialog] = useState(false);
    const [openConnectDialog, setOpenConnectDialog] = useState(false);
    const [openGoEstablishmentDetailDialog, setOpenGoEstablishmentDetailDialog] = useState(false);
    const [blackListItems, setBlacklistItems] = useState<IBlacklistItems>(initialBlacklistItems);

    const [item, setItem] = useState<IFuelSupply>();
    const [itemToNavigate, setItemToNavigate] = useState<IFuelSupply>();
    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);
    const [establishment] = useState<IEstablishment>(initialIEstablisment);

    const fuelSupplyGateway = useIoC(FuelSupplyGateway);
    const blacklistGateway = useIoC(BlacklistGateway);

    const handleClickFabButton = () => {

        setItem(undefined);
        setOpenUpsertDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenUpsertDialog(false);
    };

    const handleCloseGoToDetail = () => {
        setOpenGoDetailDialog(false);
    };

    const handleCloseConnectDialog = () => {
        setOpenConnectDialog(false);
    };

    const handleCloseGoEstablishmentDetailDialog = () => {
        setOpenGoEstablishmentDetailDialog(false);
    };

    const handleItemUpsert = (item: IFuelSupply, isNew: boolean) => {
        let copy = data.slice();
        if (isNew) {
            copy = [item, ...data];
        } else {
            const index = data.findIndex(value => value.id === item.id);
            copy[index] = item;
        }
        setData(copy);
        setItemToNavigate(item);
        setOpenGoDetailDialog(true);
    };
    const handleClickItem = (item: IFuelSupply) => {

        history.push(`${url}/${item.id}`);

    }

    const navigateToNextWindow = (item: IFuelSupply) => {
        history.push(`${url}/${item.id}`);
    }

    useEffect(() => {
        (async () => {
            (async () => {
                setIsDataLoading(true);

                if (establishmentId) {
                    const data = await fuelSupplyGateway.findAll({ establishmentId });
                    const result = await blacklistContent(data);
                    setData(result);

                }
                else if (complexId) {
                    const data = await fuelSupplyGateway.getFuelComplexSupplies(Number(complexId));
                    const result = await blacklistContent(data);
                    setData(result);
                }
                setIsDataLoading(false);
            })();

        })();
    }, [openConnectDialog, fuelSupplyGateway]);

    async function blacklistContent(data: IFuelSupply[]){

        var arrBlacklist= [];
        setIsDataLoading(true);
        for(var i = 0; i < data.length; i++){
            if(data[i].reference.length === 20){
                arrBlacklist.push(data[i].reference);
                arrBlacklist.push(data[i].reference + "0F");
            }
            else if(data[i].reference.length === 22){
                arrBlacklist.push(data[i].reference);
                arrBlacklist.push(data[i].reference.slice(0, 20));
            }
            else{
                arrBlacklist.push(data[i].reference);
            }
        }
    
        const result = await blacklistGateway.findCups(arrBlacklist);
    
        data.map((item, index) => {
            let blacklistedElement: string | undefined;
            if(item.reference.length === 20){
                blacklistedElement = result.find((element: string) => 
                element === item.reference || element === item.reference+"0F");
            }
            else if(item.reference.length === 22){
                blacklistedElement = result.find((element: string) => 
                element === item.reference || element === item.reference.slice(0, 20));
            }
            else{
                blacklistedElement = result.find((element: string) => element === item.reference);
            }
            // const blacklistedElement = result.data.find((element: {_id: string}) => element._id === item.reference);
            if(blacklistedElement !== undefined) item.blacklisted = true;
            else item.blacklisted = false;
        })
        setIsDataLoading(false);
        return data;
      }

    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />;
        }

        if (isDataEmpty) {
            return <AlertBox variant='info'>{t('common.noItems')}</AlertBox>;
        }
        return (
            <List>
                {data.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <FuelSupplyItem item={item} onClickItem={handleClickItem} establishmentId={establishmentId}/>
                            {index < data.length - 1 ? <Divider variant='inset' component='li' /> : null}
                        </div>
                    );
                })}
            </List>
        );
    };
    return (
        <Box>
            <ScreenGlobalStructure
                buttonIcon={<AddIcon />}
                headerIcon={LocalGasStationIcon}
                matchUrl={url}
                onButtonClick={handleClickFabButton}
                title={t('fuelSupplyList.title')}
                clientId={clientId !== undefined ? clientId : ''}
                loading={isDataLoading}
                drawer={establishmentId ?
                    <EstablishmentDrawer
                        matchUrl={url}
                        lateralMenu={3}
                        icon={<EstablishmentIcon fontSize='large' className={classes.itemAvatarIcon} />}
                        goBack={true}
                    /> :
                    <ComplexDrawer
                        matchUrl={url}
                        lateralMenu={4}
                        icon={<ComplexIcon fontSize='large' className={classes.itemAvatarIcon}/>}
                        goBack={true}
                    />
                }
            >
                <Content />
            </ScreenGlobalStructure>
            <FuelSupplyUpsertDialog
                open={openUpsertDialog}
                item={item}
                complexId={complexId}
                establishmentId={establishmentId}
                onItemUpsert={handleItemUpsert}
                onClose={handleCloseDialog} />

            <FuelSupplyGoDetailDialog
                open={openGoDetailDialog}
                item={itemToNavigate}
                navigateToNextWindow={navigateToNextWindow}
                handleClose={handleCloseGoToDetail} />

            <FuelSupplyGoEstablishmentDetailDialog
                open={openGoEstablishmentDetailDialog}
                handleClose={handleCloseGoEstablishmentDetailDialog} />

            <FuelSupplyConnectDetailDialog
                open={openConnectDialog}
                item={establishment}
                handleClose={handleCloseConnectDialog} />
        </Box>
    );
};

export default FuelSupplyList;