import React, { useEffect, useImperativeHandle, useState } from "react";
import { AccessType } from "../../../../../gateways/access-type.interfaces";
import { fuelTypes } from "../../../../../gateways/fuel-types.interface";
import { IFormBaseComponent, IFormBaseRefAttributes, IFormBaseProps } from "./interfaces";
import { IUpdateFuelSupply } from "../../../../../gateways/fuel-supply.interface";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { FuelSupplyGateway } from "../../../../../gateways/fuel-supply.gateway";
import useIoC from "../../../../../contexts/ioc.context";
import FuelSupplyCompleteForm from "./supply-forms/fuel.supply-form";
import { IClient } from "../../../../../gateways/clients.interfaces";
import ICompany from "../../../../../gateways/company.interface";
import { IComplex } from "../../../../../gateways/complex.interfaces";
import { IEstablishment } from "../../../../../gateways/establishment.interface";
import { CompanyGateway } from "../../../../../gateways/company.gateway";
import { ComplexGateway } from "../../../../../gateways/complex.gateway";
import { ClientsGateway } from "../../../../../gateways/clients.gateway";
import { EstablishmentGateway } from "../../../../../gateways/establishment.gateway";
import ClientAddDialog from "../../../../clients/client-add-dialog";
import CompanyAddDialog from "../../../../companies/company-add-dialog";
import ComplexUpsertDialog from "../../../../complex/complex-upsert-dialog";
import EstablishmentUpsertDialog from "../../../../establishment/establishment-upsert-dialog";

export const FuelComponent: IFormBaseComponent = React.forwardRef<
  IFormBaseRefAttributes,
  IFormBaseProps
>(({ operate, onClose }, ref) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [ loading, setLoading] = useState(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [complexes, setComplexes] = useState<IComplex[]>([]);
  const [establishments, setEstablishments] = useState<IEstablishment[]>([]);
  const [errorMissingData, setErrorMissingData] = useState(false);
  const [openAddEstablishmentDialog, setOpenAddEstablishmentDialog] =
    useState(false);
  const [openAddComplexDialog, setOpenAddComplexDialog] = useState(false);
  const [openAddClientDialog, setOpenAddClientDialog] = useState(false);
  const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);
  const [client, setClient] = useState<IClient | null>(null);
  const [company, setCompany] = useState<ICompany | null>(null);
  const [fuelSupply, setFuelSupply] = useState<IUpdateFuelSupply>({
    id: parseInt(operate.supplyId),
    reference: operate.cups,
    accessTypeId: AccessType.Red,
    fuelTypeId: fuelTypes.GasNatural, 
    providerId: null,
    complexId: null,
    establishmentId: null,
    code: null,
    emailNotification: null,
    invoicesFolder: null,
    startCaptureDate: new Date(),
    sendEmail: true,
    sendClientEmail: true,    
    separatedMeterAccount: false,
    separatedMeterInvoiceFolder: "",
    separatedMeterStartCaptureDate: new Date(),
    separatedMeterStartValidationsDate: new Date(), 
  });

  const complex =
  complexes.find((x) => x.id === fuelSupply.complexId) ?? null;

  const establishment =
    establishments.find((x) => x.id === fuelSupply.establishmentId) ?? null;

  const companyGateway = useIoC(CompanyGateway);
  const complexGateway = useIoC(ComplexGateway);
  const clientsGateway = useIoC(ClientsGateway);
  const establishmentGateway =
    useIoC(EstablishmentGateway);
  const fuelSupplyGateway = useIoC(FuelSupplyGateway);

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  const handleClickAddEstablishmentDialog = () => {
    setOpenAddEstablishmentDialog(true);
  };

  const handleCloseAddEstablishmentDialog = () => {
    setOpenAddEstablishmentDialog(false);
  };

  const handleClickAddComplexDialog = () => {
    setOpenAddComplexDialog(true);
  };

  const handleCloseAddComlexDialog = () => {
    setOpenAddComplexDialog(false);
  };

  const handleOpenAddClientDialog = () => {
    setOpenAddClientDialog(true);
  };

  const handleCloseAddClientDialog = () => {
    setOpenAddClientDialog(false);
  };

  const handleClickAddCompanyDialog = () => {
    setOpenAddCompanyDialog(true);
  };

  const handleCloseAddCompanyDialog = () => {
    setOpenAddCompanyDialog(false);
  };

  const handleCreateCompany = (item: ICompany) => {
    setCompanies([...companies, item]);
    setCompany(item);
  };

  const handleCreateComplex = (item: IComplex) => {
    setComplexes([...complexes, item]);
    setFuelSupply({
      ...fuelSupply,
      complexId: item.id,
      establishmentId: null,
    });
  };

  const handleCreateClient = (item: IClient) => {
    setClients([...clients, item]);
    setClient(item);
  };

  const handleCreateEstablishment = (item: IEstablishment) => {
    setEstablishments([...establishments, item]);
    setFuelSupply({
      ...fuelSupply,
      complexId: null,
      establishmentId: item.id,
    });
  };

  const handleStartCaptureDateChange = (value: any) => {
    const updatedFuelSupply: IUpdateFuelSupply = {
      ...fuelSupply,
      startCaptureDate: value,
    } as any;
    setFuelSupply(updatedFuelSupply);
  };

  const handleStartValidationsDate = (value: any) => {
    const updatedFuelSupply: IUpdateFuelSupply = {
      ...fuelSupply,
      startValidationsDate: value,
    } as any;
    setFuelSupply(updatedFuelSupply);
  };

  const updateFuelSupply = async (item: IUpdateFuelSupply) => {
    setLoading(true);
    const fuelSupply = await fuelSupplyGateway.update(item.id, item);
    snackbar.enqueueSnackbar(
      t("fuelSupplyUpsertDialog.updateSucceded", {
        id: fuelSupply.fuelTypeId,
        reference: fuelSupply.reference,
      }),
      { variant: "success" }
    );
    setFuelSupply(fuelSupply);
    setLoading(false);
  };

  const upsertFuelSupply = async (item: IUpdateFuelSupply) => {
     try {
      await updateFuelSupply(item);
    } catch (e) {
      const er = e as any;
      const key = "fuelSupplyErrorHandler." + er.response.data.key;
      if (er.response.data.key === "updatingError") {
        const message =
          "validation" in er ? er.validation : t(key, { id: er.response.data.id });
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else {
        const message =
          "validation" in er ? er.validation : t("messages.defaultError");
        snackbar.enqueueSnackbar(message, { variant: "error" });
      }
    } 
    finally {
      onClose();
    }
  };

  useImperativeHandle(ref, () => ({
    accept: async () => {
      if (!fuelSupply.startCaptureDate || !fuelSupply.startValidationsDate || !fuelSupply.reference || 
        (!fuelSupply.complexId && !fuelSupply.establishmentId)) {
        snackbar.enqueueSnackbar(t("messages.requiredFields"), {
          variant: "error",
        });
        setErrorMissingData(true);
        return;
      }
      setErrorMissingData(false);
      upsertFuelSupply(fuelSupply); 
    }
  }));

  useEffect(() => {
    (async () => {
      setLoading(true);
      const clients = await clientsGateway.findAll({ isMarketing: false });
      setClients(clients);
      setLoading(false);
    })();
  }, [clientsGateway]);

  useEffect(() => {
    (async () => {
      if (client) {
        setLoading(true);
        const companies = await companyGateway.findAll({ clientId: client.id });
        setCompanies(companies);
        setLoading(false);
      }
    })();
  }, [companyGateway, client]);

  useEffect(() => {
    (async () => {
      if (company) {
        setLoading(true);
        const [complexes, establishments] = await Promise.all([
          complexGateway.findAll({ companyId: company.id }),
          establishmentGateway.findAll({ companyId: company.id }),
        ]);
        setComplexes(complexes);
        setEstablishments(establishments);
        setLoading(false);
      }
    })();
  }, [establishmentGateway, complexGateway, company]);
  
  return (
    <>
      <FuelSupplyCompleteForm
        errorMissingData={errorMissingData}
        selectedClient={client}
        selectedCompany={company}
        selectedComplex={complex}
        selectedEstablishment={establishment}
        onSelectClient={setClient}
        onSelectCompany={setCompany}
        clients={clients}
        companies={companies}
        complexes={complexes}
        establishments={establishments}
        supply={fuelSupply}
        validateRequired={validateRequired}
        onChangeSupply={setFuelSupply}
        onClickAddClientButton={handleOpenAddClientDialog}
        onClickAddCompanyButton={handleClickAddCompanyDialog}
        onClickAddComplexButton={handleClickAddComplexDialog}
        onClickAddEstablishmentButton={handleClickAddEstablishmentDialog}
        onStartCaptureDateChange={handleStartCaptureDateChange}
        onStartValidationDateChange={handleStartValidationsDate}
      />
      <ClientAddDialog
        open={openAddClientDialog}
        onClose={handleCloseAddClientDialog}
        onClientCreated={handleCreateClient}
      />

      {client && (
        <CompanyAddDialog
          open={openAddCompanyDialog}
          clientId={client.id}
          onClose={handleCloseAddCompanyDialog}
          onItemCreated={handleCreateCompany}
          onLoading={() => loading}
        />
      )}

      {client && company && (
        <ComplexUpsertDialog
          open={openAddComplexDialog}
          clientId={client.id}
          companyId={company.id}
          item={undefined}
          onClose={handleCloseAddComlexDialog}
          onItemUpsert={handleCreateComplex}
          onLoading={() => loading}
        />
      )}

      {client && company && (
        <EstablishmentUpsertDialog
          open={openAddEstablishmentDialog}
          item={undefined}
          clientId={client.id}
          companyId={company.id}
          complexId={fuelSupply?.complexId ?? undefined}
          onItemUpsert={handleCreateEstablishment}
          onLoading={()=>false}
          onClose={handleCloseAddEstablishmentDialog}
        />
      )}
    </>
  );
});