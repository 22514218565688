import React, { useState, useMemo, useEffect } from 'react';
import CenteredCircularProgress from '../../components/centered-circular-progress';
import AlertBox from '../../components/alert-box';
import { List, Divider, Box, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import useIoC from '../../contexts/ioc.context';
import { useParams, useHistory, useRouteMatch } from "react-router";
import DescriptionIcon from '@material-ui/icons/Description';
import { ElectricSupplyGateway } from '../../gateways/electric-supply.gateway';
import { IRegisterElectricBill, IRegisterElectricBillCreate } from '../../gateways/register-electric-bill.interface';
import { RegisterElectricBillGateway } from '../../gateways/register-electric-bill.gateway';
import RegisterElectricBillItem from './register-electric-bill-item';
import RegisterElectricBillUpsertDialog from './register-electric-bill-upsert-dialog';
import RegisterEletricBillGoDetailDialog from './register-electric-bill-go-detail-dialog';
import ScreenGlobalStructure from '../../components/screen-global-structure';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import ElectricSupplyDrawer from "../../components/electric-supply-drawer";

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20
    },
    itemAvatarIcon: {
        backgroundColor: "#e0e0e0",
        borderRadius: "50%",
        padding: theme.spacing(1)
    }
}));

interface IElectricContractRouteParams {
    electricSupplyId: string,
    complexId: string,
    establishmentId: string;
    clientId: string;
}

const RegisterElectricBillList = () => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const { electricSupplyId } = useParams<IElectricContractRouteParams>();
    const { complexId } = useParams<IElectricContractRouteParams>();
    const { establishmentId } = useParams<IElectricContractRouteParams>();
    const { clientId } = useParams<IElectricContractRouteParams>();
    const { t } = useTranslation();
    const classes = useStyles();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState<IRegisterElectricBill[]>([]);
    const [openUpsertDialog, setOpenUpsertDialog] = useState(false);
    const [item, setItem] = useState<IRegisterElectricBillCreate>();
    const [itemToNavigate, setItemToNavigate] = useState<IRegisterElectricBill>();
    const [openGoDetailDialog, setOpenGoDetailDialog] = useState(false);
    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);

    const registerElectricBillGateway = useIoC(RegisterElectricBillGateway);
    const electricSupplyGateway = useIoC(ElectricSupplyGateway);

    const handleClickFabButton = () => {

        setItem(undefined);
        setOpenUpsertDialog(true);
    };

    const handleItemUpsert = (item: IRegisterElectricBill, isNew: boolean) => {
        let copy = data.slice();
        if (isNew) {
            copy = [item, ...data];
        } else {
            const index = data.findIndex(value => value.id === item.id);
            copy[index] = item;
        }
        setData(copy);
        setItemToNavigate(item);
        setOpenGoDetailDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenUpsertDialog(false);
    };

    const handleClickItem = (item: IRegisterElectricBill) => {

        history.push(`${url}/${item.id}`);

    };

    const navigateToNextWindow = (item: IRegisterElectricBill) => {
        history.push(`${url}/${item.id}`);
    };

    const handleCloseGoToDetail = () => {
        setOpenGoDetailDialog(false);
    };

    useEffect(() => {
        (async () => {
            (async () => {
                setIsDataLoading(true);
                const data = await electricSupplyGateway.findByIdRegisterElectricBill(parseInt(electricSupplyId));
                setData(data);
                setIsDataLoading(false);
            })();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerElectricBillGateway]);


    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />;
        }

        if (isDataEmpty) {
            return <AlertBox variant='info'>{t('common.noItems')}</AlertBox>;
        }

        return (
            <List>
                {data.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <RegisterElectricBillItem item={item} onClickItem={handleClickItem} />
                            {index < data.length - 1 ? <Divider variant='inset' component='li' /> : null}
                        </div>
                    );
                })}
            </List>
        );
    };
    return (
        <Box>
            <ScreenGlobalStructure
                buttonIcon={<AddIcon />}
                headerIcon={DescriptionIcon}
                matchUrl={url}
                onButtonClick={handleClickFabButton}
                title={t('registerElectricBillList.title') as string}
                clientId={clientId ?? ''}
                loading={isDataLoading}
                drawer={
                    <ElectricSupplyDrawer
                        matchUrl={url}
                        lateralMenu={2}
                        icon={<FlashOnIcon fontSize='large' className={classes.itemAvatarIcon}/>}
                        goBack={true}
                    />
                }

            >
                <Content />
            </ScreenGlobalStructure>
            <RegisterEletricBillGoDetailDialog
                open={openGoDetailDialog}
                item={itemToNavigate}
                navigateToNextWindow={navigateToNextWindow}
                handleClose={handleCloseGoToDetail} />

            <RegisterElectricBillUpsertDialog
                open={openUpsertDialog}
                item={item}
                complexId={complexId}
                establishmentId={establishmentId}
                electricSupplyId={electricSupplyId}
                onItemUpsert={handleItemUpsert}
                onClose={handleCloseDialog} />
        </Box>
    );
};

export default RegisterElectricBillList;