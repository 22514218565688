import React, { FC, useMemo } from "react";
import { IFSTableColumn } from './interfaces';
import { TableCell } from "@material-ui/core";

interface IFSTableBodyCellProps {
    column: IFSTableColumn;
    item: any;
}

const FSTableBodyCell: FC<IFSTableBodyCellProps> = ({ column, item }) => {

    const value = useMemo(() => {
        if (typeof column.render === 'function') {
            return column.render(item);
        } else if (!!column.name) {
            return item[column.name]
        } else {
            return 'N/a';
        }
    }, [column, item]);

    return (
        <TableCell style={{ whiteSpace: 'nowrap' }}>{value}</TableCell>
    )
}

export default FSTableBodyCell;