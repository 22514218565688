import React, { FC, useEffect, useState, useMemo, Fragment } from "react";
import { List, ListItem, ListItemAvatar, ListItemText, Divider, makeStyles, CircularProgress, Box, ListItemSecondaryAction, IconButton, Collapse, ListItemIcon, Paper, TextField } from "@material-ui/core";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import MailIcon from '@material-ui/icons/Mail';
import BuildIcon from "@material-ui/icons/Build";
import BugReportIcon from '@material-ui/icons/BugReport';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { withRouter, RouteComponentProps } from "react-router";
import useIoC from "../../contexts/ioc.context";
import AlertBox from "../../components/alert-box";
import { useNavigator } from '../../contexts/navigator.context';
import SupplyBotsIcon from '@material-ui/icons/Autorenew';
import { JiraGateway } from "../../gateways/jira.gateway";
import { ResultJiraIssue } from "../../gateways/jira.interfaces";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { BotsGateway } from "../../gateways/bots.gateway";
import { IBotPreconfigRO } from "../../gateways/bots.interfaces";
import { findMongoItem } from "./functions/functions";
import { MongoGateway } from "../../gateways/mongo.gateway";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    listItem: {
        cursor: 'pointer'
    },
    inline: {
        display: 'inline',
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    progress: {
        margin: theme.spacing(2),
    },
    loaderContainer: {
        textAlign: 'center'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    iconButton: {
        padding: 10,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        width: "46%",
    },
}));

const JiraIssuesList: FC<RouteComponentProps> = ({ history }) => {
    const mongoGateway = useIoC(MongoGateway);

    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const jiraGateway = useIoC(JiraGateway);
    const [data, setData] = useState<ResultJiraIssue[]>([]);
    const [loading, setLoading] = useState(false);
    const isEmpty = useMemo(() => !loading && data.length === 0, [loading, data]);
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const botsGateway = useIoC(BotsGateway);
    const [botData, setBotData] = useState<IBotPreconfigRO[]>([]);

    const [expanded, setExpanded] = useState<number[]>([]);

    const handleExpand = (value: number) => () => {
        const currentIndex = expanded.indexOf(value);
        const newExpanded = [...expanded];

        if (currentIndex === -1) {
            newExpanded.push(value);
        } else {
            newExpanded.splice(currentIndex, 1);
        }

        setExpanded(newExpanded);
    };

    const handleOnOpen = (item: ResultJiraIssue) => {
        if (!!!item.issueKey) {
            snackbar.enqueueSnackbar(t("common.documentationNotAvailable"), {
                variant: "error",
            });
        } else {
            const toFind = ' - Link al correo: ';
            let mailFactura = item.description.filter(desc => desc.toLowerCase().includes(toFind.toLowerCase()));
            
            if (mailFactura.length > 0)
            {
                const fixed = mailFactura[0].replace(toFind,'');
                window.open(fixed);
            }
        }
    };

    const handleOnOpenInfo = async (item: ResultJiraIssue) => {
        if (!!!item.issueKey) {
            snackbar.enqueueSnackbar(t("common.documentationNotAvailable"), {
                variant: "error",
            });
        } else {
            
            const callMongo = await findMongoItem(item, mongoGateway);

            console.log("[callMongo] " + callMongo ? JSON.stringify(callMongo):'empty');
        }
    };

    const [titleSearch, setTitleSearch] = useState('');
    const [descSearch, setDescSearch] = useState('');
    const [jiraSearch, setJiraSearch] = useState('');
    const [resultItems, setResultItems] = useState<ResultJiraIssue[]>([]);

    const updateResultItems = () => {
        let items = data;

        if (titleSearch !== '') {
            items = items.filter(item => item.title.toLowerCase().includes(titleSearch.toLowerCase()));
        }

        if (descSearch !== '') {
            items = items.filter(item => item.description.join().toLowerCase().includes(descSearch.toLowerCase()));
        }

        if (jiraSearch !== '')
        {
            items = items.filter(item => item.issueKey.toLowerCase().includes(jiraSearch.toLowerCase()));
        }

        //ToDo link items :P
        // for (let index = 0; index < items.length; index++) {
        //     let element = items[index];
        //     const toFind = ' - Dirección del destinatario: ';
        //     let botCredentials = element.description.filter(desc => desc.toLowerCase().includes(toFind.toLowerCase()));
        //     if (botCredentials && botCredentials.length > 0) {
        //         const myBotCredentials = botCredentials[0].replace(toFind, '').replace('@enovam.com', '');
        //         console.log('[myBotCredentials] Issue Title: ' + element.title + ' - UserName: ' + myBotCredentials);
        //         const bot = botData.filter(
        //             bot => bot.fields.filter(
        //                 botInfo => botInfo.field.toLowerCase().includes(myBotCredentials.toLowerCase())
        //             )
        //                 || bot.alias.toLowerCase().includes(myBotCredentials.toLowerCase()));

        //         if (bot && bot.length > 0) {
        //             items[index].IBotPreconfigRo_Data = bot[0];
        //         }

        //     }

        // }

        return items;

    }
    useEffect(() => {
        setResultItems(updateResultItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titleSearch, descSearch, jiraSearch]);

    const renderItem = (item: ResultJiraIssue, index: number) => {


        const handleClickOpenWrapper = () => handleOnOpen(item);
        const handleClickOpenInfoWrapper = async ()=> handleOnOpenInfo(item);

        return (
            <Fragment key={index ?? item.issueID}>
                <ListItem alignItems="flex-start" className={classes.listItem} onClick={handleExpand(index)}>
                    <ListItemAvatar >
                        <BugReportIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${item.title}`}
                        secondary={`${item.issueKey}`}
                    />
                    <ListItemSecondaryAction>
                        {/* <IconButton edge="end" aria-label="bot" color={item.enabled ? "primary":"default"} onClick={()=>{handleClickBot(item)}}>   
                                <PlayCircleFilledIcon style={{fontSize: 35}}/>
                            </IconButton> */}
                    </ListItemSecondaryAction>
                </ListItem>

                <Collapse
                    in={expanded.indexOf(index) !== -1}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                            <ListItemIcon>
                                <BuildIcon />
                            </ListItemIcon>
                            <ListItemText className={classes.inline} primary="Description" secondary={`${item.description.join("\n\n")}`} />
                            <IconButton edge="end"
                                onClick={handleClickOpenWrapper}
                                className={classes.iconButton}
                                aria-label="open mail"
                            >
                                <MailIcon style={{ fontSize: 35 }} />
                            </IconButton>
                            <IconButton edge="end"
                                onClick={handleClickOpenInfoWrapper}
                                className={classes.iconButton}
                                aria-label="open info"
                            >
                                <ListAltIcon style={{ fontSize: 35 }} />
                            </IconButton>
                        </ListItem>
                    </List>
                </Collapse>

                {
                    index < data.length - 1
                        ? <Divider variant="inset" component="li" />
                        : null
                }
            </Fragment>
        );
    }

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t("navigation.jiraMain"),
                icon: SupplyBotsIcon
            }
        });
    }, [navigatorDispatch, t]);

    useEffect(() => {
        (async () => {
            const items = await botsGateway.findAllPreconfig();
            setBotData(items);
        })();
    }, [botsGateway]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            let items = await jiraGateway.findAll();

            setData(items);
            setTitleSearch('');
            setDescSearch('');
            setResultItems(items);
            setLoading(false);

        })();
    }, [jiraGateway]);

    return (
        <Box>
            {/* Barra de busqueda */}
            <h4>Buscador:</h4>

            {loading
                    ? (
                        <div className={classes.loaderContainer}>
                            <CircularProgress className={classes.progress} />
                        </div>
                    ) : (<Paper component="form" className={classes.root}>
                <TextField
                    id="title-search"
                    className={classes.input}
                    label="Título"
                    type="search"
                    onChange={(e) => {
                        setTitleSearch(e.target.value);
                    }}
                />
                <TextField
                    id="desc-search"
                    className={classes.input}
                    label="Descripción"
                    type="search"
                    onChange={(e) => {
                        setDescSearch(e.target.value);
                    }}
                />
                <TextField
                    id="jira-search"
                    className={classes.input}
                    label="Jira Issue - MCXXXX"
                    type="search"
                    onChange={(e) => {
                        setJiraSearch(e.target.value);
                    }}
                />
            </Paper>)}

            {data && <h3>Incidencias: {resultItems.length}</h3>}
            {
                loading
                    ? (
                        <div className={classes.loaderContainer}>
                            <CircularProgress className={classes.progress} />
                        </div>
                    ) : null
            }

            {
                isEmpty
                    ? (
                        <AlertBox variant="info">
                            No se han encontrado registros
                        </AlertBox>
                    ) : null
            }
            <List>
                {resultItems && resultItems.length > 0 ? resultItems.map(renderItem): null}
            </List>
        </Box>
    );
}

export default withRouter(JiraIssuesList);