export interface IRepositoryQueryOrderDto {
    property: string;
    asc: boolean;
}

export enum FilterOperator {
    equals = 0,
    distinct = 1,
    greatherThan = 2,
    lessThan = 3,
    startsWith = 4,
    endsWith = 5,
    contains = 6,
    notStartsWith = 7,
    notEndsWith = 8,
    notContains = 9,
}

export interface IRepositoryQueryFilterOrDto {
    operator: FilterOperator;
    value: any;
}

export interface IRepositoryQueryFilterDto {
    property: string;
    ors: IRepositoryQueryFilterOrDto[];
}

export interface IRepositoryQueryPaginationDto {
    skip: number;
    take: number;
}

export interface IRepositoryQueryCriteriaDto {
    orders?: IRepositoryQueryOrderDto[];
    filters?: IRepositoryQueryFilterDto[];
    pagination?: IRepositoryQueryPaginationDto;
    filterGroupId?: number;
}

export interface IRepositoryQueryDto {
    criteria?: IRepositoryQueryCriteriaDto;
    params?: { name: string, value: any }[];
}

export interface IRepositoryDefinitionGroup {
    id: number;
    name: string;
}

export interface IRepositoryGroupType {
    id: string;
    description: string;
    sourceTable: string;
    sourceColumn: string;
}

export interface ICreateGroupDto {
    groupTypeId: string;
    request: IRepositoryQueryDto;
    name: string;
}