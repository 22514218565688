import React, { FC, useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, makeStyles, Grid, Theme, createStyles, Box } from "@material-ui/core";
import FormTextField from "../../components/form-text-field";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import { IElectricContractCreate, IElectricContract, initialElectricContract, IElectricContractAccountLogs } from "../../gateways/electric-contract.interfaces";
import { ElectricContractGateway } from "../../gateways/electric-contract.gateway";
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { IProvidersDisplay, IProviders } from "../../gateways/providers.interface";
import { ProvidersGateway } from "../../gateways/providers.gateway";
import { ProviderType } from "../../gateways/provider-types.interface";
import { SupplyType } from "../../gateways/supply-type.interfaces";
import { AccessType } from "../../gateways/access-type.interfaces";
import SelectorField from "../../components/selector-field";
import { ICreatePeriodElectricSupplyRate } from "../../gateways/period-electric-supply-rate.interfaces";
import { IElectricSupplyRates } from "../../gateways/electric-supply-rates.interface";
import { ElectricSupplyRatesGateway } from "../../gateways/electric-supply-rates.gateway";
import { datesHandler } from "../../utils/dates-handle";
import CountryGateway from "../../gateways/country.gateway";
import CurrencyGateway from "../../gateways/currency.gateway";
import MaterialTable from 'material-table';
import { IElectricContractMaterialTableRows } from "../../utils/material-table.interface";
import { ElectricSupplyGateway } from "../../gateways/electric-supply.gateway";
import moment from "moment";
import ElectricContractFormDetailUpsert from "./electric-contract-form-upsert";
import ProvidersUpsertDialog from "../providers/providers-upsert-dialog";
import { useUser } from "../../contexts/user.context";

interface IElectricContractUpsertDialogProps {
    open: boolean;
    item: IElectricContract | undefined;
    electricSupplyId: string;
    complexId: string | undefined;
    establishmentId: string | undefined;
    onItemUpsert: (item: IElectricContract, isNew: boolean) => void;
    onClose: (currency: string) => void;
};


const ElectricContractUpsertDialog: FC<IElectricContractUpsertDialogProps> = (props) => {
    const { t } = useTranslation();
    const [item, setItem] = useState<IElectricContract>(initialElectricContract);
    const [providers, setProviders] = useState<IProviders[]>([]);
    const [rates, setRates] = useState<IElectricSupplyRates[]>([]);
    const [state, setState] = useState<IElectricContractMaterialTableRows[]>([]);
    const [period, setPeriod] = useState(3);
    const [currency, setCurrency] = useState<string>('');
    const [firstIteration, setFirstIteration] = useState<boolean>(true);
    const snackbar = useSnackbar();
    const dateHandler = new datesHandler();
    const electricContractGateway = useIoC(ElectricContractGateway);
    const providerGateway = useIoC(ProvidersGateway);
    const ratesGateway = useIoC(ElectricSupplyRatesGateway);
    const countriesGateway = useIoC(CountryGateway);
    const currenciesGateway = useIoC(CurrencyGateway);
    const electricSupplyGateway = useIoC(ElectricSupplyGateway);
    const [openProviderAddDialog, setOpenProviderAddDialog] = useState(false);

    const [{ user }] = useUser();

    const handleClickAddProviderDialog = () => {
        setOpenProviderAddDialog(true);
    }

    const handleCloseAddProviderDialog = () => {
        setOpenProviderAddDialog(false);
    }

    const handleCreateNewProvider = (item: IProviders, isNew: boolean) => {
        setProviders([...providers, item]);
    }

    const createElectricContract = async (item: IElectricContract) => {
        let contractToCreate: IElectricContractCreate
        let periodsElectricSupplyRateToCreate: ICreatePeriodElectricSupplyRate[] = [];
        for (let i: number = 0; i < period; i++) {
            periodsElectricSupplyRateToCreate[i] = item.periodsElectricSupplyRate[i];
        }
        contractToCreate = {
            reference: item.reference,
            cancelPriorNotice: item.cancelPriorNotice,
            duration: item.duration,
            electricSupplyRateId: item.electricSupplyRateId,
            endSupplyDate: item.endSupplyDate,
            initSupplyDate: item.initSupplyDate,
            modality: item.modality,
            periodsElectricSupplyRate: periodsElectricSupplyRateToCreate,
            providerId: item.providerId,
            signContractDate: item.signContractDate,
            term: item.term,
            electricSupplyId: parseInt(props.electricSupplyId)
        }

        const electricContract = await electricContractGateway.create(contractToCreate);

        if(item.accountNumber && user){
            await electricContractGateway.addAccountNumber(electricContract.id, item.accountNumber, user)
          }

        snackbar.enqueueSnackbar(t('electricContractUpsertDialog.createSucceded',
            { id: electricContract.id, reference: electricContract.signContractDate }), { variant: "success" });
        props.onItemUpsert(electricContract, true);
    };

    const updateElectricContract = async (item: IElectricContract) => {
        const contractToUpdate: IElectricContractCreate = {
            reference: item.reference,
            cancelPriorNotice: item.cancelPriorNotice,
            duration: item.duration,
            electricSupplyRateId: item.electricSupplyRateId,
            endSupplyDate: item.endSupplyDate,
            initSupplyDate: item.initSupplyDate,
            modality: item.modality,
            periodsElectricSupplyRate: item.periodsElectricSupplyRate,
            providerId: item.providerId,
            signContractDate: item.signContractDate,
            term: item.term,
            electricSupplyId: parseInt(props.electricSupplyId)
        }
        const electricContract = await electricContractGateway.update(item.id, contractToUpdate);
        snackbar.enqueueSnackbar(t('electricContractUpsertDialog.updateSucceded',
            { id: electricContract.id, reference: electricContract.signContractDate }), { variant: "success" });
        props.onItemUpsert(electricContract, false);
    };

    const upsertElectricSupply = async (item: IElectricContract) => {
        try {
            if (!props.item) {
                await createElectricContract(item);
            } else {
                await updateElectricContract(item);
            }
        } catch (e) {
            const er = e as any;
            const key = 'electricContractErrorHandler.' + er.response.data.key;
            if (er.response.data.key === 'creatingEntityError') {
                const message = "validation" in er ? er.validation : t(key);
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else if (er.response.data.key === 'updatingError') {
                const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose(currency);
        }
    };

    const handleAccept = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // if (!item.term || !item.signContractDate || !item.providerId || !item.duration
        //     || !checkPeriodsElectricSupplyRate() || !item.modality || !item.initSupplyDate
        //     || !item.endSupplyDate || !item.electricSupplyRateId || !item.cancelPriorNotice) {
        //     event.preventDefault();
        //     snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
        //     return;
        // }

        if (!dateHandler.checkLimits(item.initSupplyDate, item.endSupplyDate)) {
            event.preventDefault();
            snackbar.enqueueSnackbar(t('messages.dateRangeError'), { variant: "error" });
            return;
        }
        const existingContracts: IElectricContract[] = await electricSupplyGateway.findByIdElectricContract(parseInt(props.electricSupplyId));
        for (let i: number = 0; i < existingContracts.length; i++) {
            if (dateHandler.checkOverlap(new Date(item.initSupplyDate), new Date(item.endSupplyDate), existingContracts[i].initSupplyDate, existingContracts[i].endSupplyDate)) {
                event.preventDefault();
                snackbar.enqueueSnackbar(t('messages.dateOverlapErrorContract', { id: existingContracts[i].id }), { variant: "error" });
                return;
            }
        }

        upsertElectricSupply(item);
    };

    const checkPeriodsElectricSupplyRate = () => {
        for (var i: number = 0; i < period; i++) {
            if (item.periodsElectricSupplyRate[i].discount === 0 ||
                item.periodsElectricSupplyRate[i].electricContractId === 0 ||
                item.periodsElectricSupplyRate[i].energyRate === 0 ||
                item.periodsElectricSupplyRate[i].powerContract === 0 ||
                item.periodsElectricSupplyRate[i].powerRate === 0) {

                return false;
            }
        }
        return true;
    }

    const handleChange = (name: string, value: any) => {
        setItem({ ...item, [name]: value });
    };

    const handleSignContractDateChange = (value: any) => {
        setItem({ ...item, signContractDate: value });
    };

    const handleInitSupplyDateChange = (value: any) => {
        setItem({ ...item, initSupplyDate: value });
    };

    const handleEndSupplyDateChange = (value: any) => {
        setItem({ ...item, endSupplyDate: value });
    };

    const handleChangePeriodElectricSupplyRate = (updatingArray: IElectricContractMaterialTableRows, index: number) => {
        let updatePeriodsAid: ICreatePeriodElectricSupplyRate[] = item.periodsElectricSupplyRate.slice();
        updatePeriodsAid[index].discount = updatingArray.discount;
        updatePeriodsAid[index].energyRate = updatingArray.energyRate;
        updatePeriodsAid[index].powerContract = updatingArray.powerContract;
        updatePeriodsAid[index].powerRate = updatingArray.powerRate;
        setItem({ ...item, periodsElectricSupplyRate: updatePeriodsAid });
    };

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };


    useEffect(() => {
        (async () => {
            let coinId;
            let coin;
            if (props.complexId) {
                try {
                    coinId = await countriesGateway.getCountrieCurrencyComplex(parseInt(props.complexId));
                    coin = await currenciesGateway.findById(coinId);
                    setCurrency(coin.isoCode);

                } catch (e) {
                    snackbar.enqueueSnackbar(t('currencyErrorHandler.readErrorComplex'), { variant: "warning" });
                }
            } else if (props.establishmentId) {
                try {
                    coinId = await countriesGateway.getCountrieCurrencyEstablishment(parseInt(props.establishmentId));
                    coin = await currenciesGateway.findById(coinId);
                    setCurrency(coin.isoCode);
                } catch (e) {
                    snackbar.enqueueSnackbar(t('currencyErrorHandler.readErrorEstablishment'), { variant: "warning" });
                }
            }
            if (props.item) {
                setItem(props.item);
            } else {
                setItem({
                    ...item, electricSupplyId: initialElectricContract.electricSupplyId,
                    cancelPriorNotice: initialElectricContract.cancelPriorNotice,
                    duration: initialElectricContract.duration,
                    endSupplyDate: initialElectricContract.endSupplyDate,
                    id: initialElectricContract.id,
                    electricSupplyRateId: initialElectricContract.electricSupplyRateId,
                    initSupplyDate: initialElectricContract.initSupplyDate,
                    modality: initialElectricContract.modality,
                    providerId: initialElectricContract.providerId,
                    signContractDate: initialElectricContract.signContractDate,
                    term: initialElectricContract.term,
                    periodsElectricSupplyRate:
                        [{ discount: 0, electricContractId: 1, energyRate: 0, powerContract: 0, powerRate: 0 },
                        { discount: 0, electricContractId: 1, energyRate: 0, powerContract: 0, powerRate: 0 },
                        { discount: 0, electricContractId: 1, energyRate: 0, powerContract: 0, powerRate: 0 },
                        { discount: 0, electricContractId: 1, energyRate: 0, powerContract: 0, powerRate: 0 },
                        { discount: 0, electricContractId: 1, energyRate: 0, powerContract: 0, powerRate: 0 },
                        { discount: 0, electricContractId: 1, energyRate: 0, powerContract: 0, powerRate: 0 },],
                    accountNumber: initialElectricContract.accountNumber,
                    accountNumber_ebmUser: initialElectricContract.accountNumber_ebmUser,
                    accountNumber_date: initialElectricContract.accountNumber_date
                });
            }
            const providers_ = await providerGateway.findWithFilters(
                {
                    providerTypeId: ProviderType.Comercializadora.toString(),
                    supplyTypeId: SupplyType.Electrico.toString(),
                    accessTypeId: AccessType.Red.toString()
                }
            );

            const electricSupplyRates_ = await ratesGateway.findAll();

            setProviders(providers_);
            setRates(electricSupplyRates_);

        }
        )();
    }, [props.item, props.open]);



    useEffect(() => {
        (async () => {
            const electricSupplyRate_ = await ratesGateway.findById(item.electricSupplyRateId);
            setPeriod(electricSupplyRate_.periods);
            var fillStateAid: IElectricContractMaterialTableRows[] = [];
            for (let i: number = 0; i < electricSupplyRate_.periods; i++) {
                fillStateAid[i] = {
                    periodNumber: i + 1,
                    discount: item.periodsElectricSupplyRate[i].discount,
                    energyRate: item.periodsElectricSupplyRate[i].energyRate,
                    powerContract: item.periodsElectricSupplyRate[i].powerContract,
                    powerRate: item.periodsElectricSupplyRate[i].powerRate
                };
            };
            setState(fillStateAid);
        })();
    }, [item.electricSupplyRateId]);

    useEffect(() => {
        (async () => {
            if (firstIteration) {
                setFirstIteration(false)
            } else if (!dateHandler.checkLimits(item.initSupplyDate, item.endSupplyDate) && props.open) {
                snackbar.enqueueSnackbar(t('messages.dateRangeError'), { variant: "error" })
                return;
            } else if (props.open) {
                const existingContracts: IElectricContract[] = await electricSupplyGateway.findByIdElectricContract(parseInt(props.electricSupplyId));
                for (let i: number = 0; i < existingContracts.length; i++) {
                    if (dateHandler.checkOverlap(new Date(item.initSupplyDate),
                        new Date(item.endSupplyDate),
                        existingContracts[i].initSupplyDate,
                        existingContracts[i].endSupplyDate)) {
                        snackbar.enqueueSnackbar(t('messages.dateOverlapErrorContract', { id: existingContracts[i].id }), { variant: "error" });
                        return;
                    }
                }
            }

            setItem({ ...item, duration: Math.ceil(moment(item.endSupplyDate).diff(item.initSupplyDate, 'month', true)) });
        })();
    }, [item.initSupplyDate, item.endSupplyDate]);

    return (
        <Box>
            <Dialog open={props.open} onClose={() => { props.onClose(currency) }} maxWidth={'md'} fullWidth={true}>
                <DialogTitle>{!props.item ? t('electricContractUpsertDialog.createTitle') : t('electricContractUpsertDialog.updateTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{!props.item ? t('electricContractUpsertDialog.createContent') : t('electricContractUpsertDialog.updateContent')}</DialogContentText>
                    <ElectricContractFormDetailUpsert
                        onChangePeriodElectricSupplyRate={handleChangePeriodElectricSupplyRate}
                        item={item}
                        onChange={handleChange}
                        onEndSupplyDateChange={handleEndSupplyDateChange}
                        onInitSupplyDateChange={handleInitSupplyDateChange}
                        onSignContractDateChange={handleSignContractDateChange}
                        providers={providers}
                        rates={rates}
                        setState={setState}
                        state={state}
                        validateRequired={validateRequired}
                        onClickAddProvider={handleClickAddProviderDialog}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { 
                            props.onClose(currency); 
                            setItem(initialElectricContract);
                        }} 
                        color="primary">
                            {t('common.cancel')}
                    </Button>
                    <Button onClick={handleAccept} color="primary">{t('common.accept')}</Button>
                </DialogActions>
            </Dialog>
            <ProvidersUpsertDialog
                open={openProviderAddDialog}
                item={undefined}
                onClose={handleCloseAddProviderDialog}
                onItemUpsert={handleCreateNewProvider}
                type={"electricContract"}
            />
        </Box>
    );
};

export default ElectricContractUpsertDialog;