import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import SelectorField from "../../components/selector-field";
import ICompany from "../../gateways/company.interface";
import ISector from "../../gateways/sector.interfaces";
import ICountry from "../../gateways/country.interfaces";
import { emailValidationError } from "../../utils/utils";

interface ICompanyDetailProps {
  item: ICompany;
  onChange: (name: string, value: any) => void;
  validateRequired: (value: any) => boolean;
  sectors: ISector[];
  countries: ICountry[];
  handleClickAddButtonSector: () => void;
  handleClickAddButtonCountry: () => void;
}

const CompanyForm: FC<ICompanyDetailProps> = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <FormTextField
        name="cif"
        label={t("common.cif")}
        type="text"
        validator={props.validateRequired}
        errorText={t("messages.requiredField") as string}
        value={props.item.cif}
        onChange={props.onChange}
        autoFocus
        fullWidth
        required
      />
      <FormTextField
        name="name"
        label={t("common.name")}
        type="text"
        validator={props.validateRequired}
        errorText={t("messages.requiredField") as string}
        value={props.item.name}
        onChange={props.onChange}
        fullWidth
        required
      />
      <SelectorField
        name="sectorId"
        fullWidth
        inputLabel={t("common.sector")}
        onChange={props.onChange}
        required
        validator={props.validateRequired}
        value={props.item.sectorId}
        values={props.sectors.map((item) => {
          return { id: item.id, value: item.name };
        })}
        emptyValue={false}
        handleClickAddButon={props.handleClickAddButtonSector}
      />
      <SelectorField
        name="countryId"
        fullWidth
        inputLabel={t("common.country")}
        onChange={props.onChange}
        required
        validator={props.validateRequired}
        value={props.item.countryId}
        values={props.countries.map((item) => {
          return { id: item.id, value: item.name };
        })}
        emptyValue={false}
        handleClickAddButon={props.handleClickAddButtonCountry}
      />
      <FormTextField
        fullWidth
        label={t("common.client_email")}
        name="client_email"
        type="text"
        value={props.item.client_email ?? ""}
        onChange={props.onChange}
        error={emailValidationError(props.item.client_email)}
        helperTextMessage={`Si hay más de 1 correo, separar con punto y coma, ejemplo: test1@test.com; test2@test.com`}
      />
    </div>
  );
};

export default CompanyForm;
