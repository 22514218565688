import React, {
  useContext,
  FC,
  createContext,
  useState,
  useCallback,
} from "react";
interface IStateValue {
  clients: string;
  clientsDrawer: string;
  complex: string;
  contacts: string;
  companies: string;
  establishments: string;
  electricSupply: string;
  electricContract: string;
  registerElectricBill: string;
  fuelSupply: string;
  fuelContract: string;
  waterSupply: string;
  registerFuelBill: string;
}
interface IContextValue {
  names: { [key: string]: string };
  update: (key: keyof IStateValue, value: string) => void;
}

const BreadCrumbNameContext = createContext(
  undefined as unknown as IContextValue
);

export const useBreadCrumbName = () => useContext(BreadCrumbNameContext);

export const BreadCrumbNameContextProvider: FC = ({ children }) => {
  const [names, setNames] = useState({
    clients: "",
    clientsDrawer: "",
    complex: "",
    contacts: "",
    companies: "",
    establishments: "",
    electricSupply: "",
    electricContract: "",
    registerElectricBill: "",
    fuelSupply: "",
    fuelContract: "",
    registerFuelBill: "",
    waterSupply: "",
  });
  const update = useCallback((key: keyof IStateValue, value: string) => {
    setNames((oldNames) => {
      console.log("old: " + oldNames[key] + " new: " + value);
      oldNames[key] = value;
      return oldNames;
    });
  }, []);
  return (
    <BreadCrumbNameContext.Provider
      value={{
        names,
        update,
      }}
    >
      {children}
    </BreadCrumbNameContext.Provider>
  );
};
