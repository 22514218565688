import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IRepositoryDefinition, ICreateRepositoryDefinition, IUpdateRepositoryDefinition } from "./repository-definition.interfaces";
import { IGroupType, IUpdateGroupType } from "./repository-group-type.interfacecs";

const repositoryGroupType = 'repository-group-type';
@inject('axios')
export class RepositoryGroupTypeGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IGroupType[]> {
        const response = await this.axios.get(repositoryGroupType);
        return response.data;
    }

    async findById(id: string): Promise<IGroupType> {
        const url = `${repositoryGroupType}/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    async create(repositoryGroupTypeToCreate: IGroupType): Promise<IGroupType> {
        const response = await this.axios.post(repositoryGroupType, repositoryGroupTypeToCreate);
        return response.data;
    }

    async update(id: string, repositoryGroupTypeToUpdate: IUpdateGroupType): Promise<IGroupType> {
        const url = `${repositoryGroupType}/${id}`;
        const response = await this.axios.put(url, repositoryGroupTypeToUpdate);
        return response.data;
    }

    async delete(id: string): Promise<void> {
        const url = `${repositoryGroupType}/${id}`;
        const response = await this.axios.delete(url);
        return response.data;
    }
}