import React, { FC } from "react";
import { IProviders, IUpdateProvidersList } from "../../gateways/providers.interface";
import { makeStyles, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import UpdateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

interface IProviderItemProps {
    item: IProviders;
    onClickUpdateButton: (item: IProviders) => void;
    onClickDeleteButton: (item: IProviders) => void;
}

const useStyles = makeStyles((theme) => ({
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

const ProviderItem: FC<IProviderItemProps> = (props) => {
    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar >
                <LocalShippingIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
            </ListItemAvatar>
            <ListItemText primary={props.item.alias} secondary={props.item.society} />
            <ListItemSecondaryAction>
                <IconButton onClick={() => { props.onClickUpdateButton(props.item) }} aria-label="update"><UpdateIcon /></IconButton>
                <IconButton onClick={() => { props.onClickDeleteButton(props.item) }} aria-label="delete"><DeleteIcon /></IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default ProviderItem;