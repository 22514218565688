import React, { useContext, useEffect, useState } from "react";
import { FC } from "react";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import SaveIcon from "@material-ui/icons/Save";
import OpacityIcon from "@material-ui/icons/Opacity";
import { useParams, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WaterSupplyForm from "./water-supply-form";
import {
  //initialIWaterSupplyInterFace,
  IWaterSupplyInterface,
  IWaterSupplyInterfaceUpsert,
} from "../../gateways/water-supply.interfaces";
import { WaterSupplyGateway } from "../../gateways/water-supply.gateway";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import { WaterSupplyContext } from "./water-supply-context";
import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
// import { MongoGateway } from "../../gateways/mongo.gateway";
import { ProvidersGateway } from "../../gateways/providers.gateway";
import { IProviders } from "../../gateways/providers.interface";
import { ProviderType } from "../../gateways/provider-types.interface";
import { SupplyType } from "../../gateways/supply-type.interfaces";
import { IAccessType } from "../../gateways/access-type.interfaces";
import { AccessTypeGateway } from "../../gateways/access-type.gateway";
import ProvidersUpsertDialog from "../providers/providers-upsert-dialog";
import { BlacklistGateway } from "../../gateways/blacklist.gateway";
import { obtainDomainFromEmail } from "../../utils/utils";
import { ClientsGateway } from "../../gateways/clients.gateway";

interface IWaterSupplyRouteParams {
  [x: string]: string | undefined;
  complexId: string;
  establishmentId: string;
  waterSupplyId: string;
  clientId: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2),
    },
    itemAvatarIcon: {
      backgroundColor: "#e0e0e0",
      borderRadius: "50%",
      padding: theme.spacing(1),
    },
  })
);

const WaterSupplyDetail: FC = () => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const [errorMissingData, setErrorMissingData] = useState(false);
  const { clientId } = useParams<IWaterSupplyRouteParams>(); //, complexId, establishmentId, waterSupplyId
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { update: updateBreadCrumbName } = useBreadCrumbName();
  const [waterSupplyState, waterSupplyStateDispatch] =
    useContext(WaterSupplyContext);
  const waterSupply = waterSupplyState.waterSupply;
  const [isBlacklisted, setIsBlacklisted] = useState<boolean | undefined>(waterSupply && waterSupply.blacklisted ? waterSupply.blacklisted: false);
  const [openProviderAddDialog, setOpenProviderAddDialog] = useState(false);
  const [providers, setProviders] = useState<IProviders[]>([]);
  const [accessTypes, setAccessTypes] = useState<IAccessType[]>([]);
  const [ loading, setLoading] = useState(false);
  // const styles = {
  //   marginTop: "10px",
  //   marginLeft: "25px",
  // };

  const waterSupplyGateway = useIoC(WaterSupplyGateway);
  //const mongoGateway = useIoC(MongoGateway);
  const providersGateway = useIoC(ProvidersGateway);
  const accessTypeGateway = useIoC(AccessTypeGateway);
  const blacklistGateway = useIoC(BlacklistGateway);
  const clientsGateway = useIoC(ClientsGateway);
  
  const handleClickBlacklistButton = () => {
    if(isBlacklisted){
      handleDeleteItemBlacklist();
      if(waterSupply) updateBreadCrumbName('waterSupply', waterSupply.referencia);
      snackbar.enqueueSnackbar('Se ha reestablecido el suministro correctamente', {
        variant: 'success',
      });
    }
    else{
      handleInsertItemBlacklist();
      if(waterSupply) updateBreadCrumbName('waterSupply', waterSupply.referencia+" (Cancelado)");
      snackbar.enqueueSnackbar('Se ha descartado el suministro correctamente', {
        variant: 'success',
      });
    }
    setIsBlacklisted(!isBlacklisted);
  }
  
  useEffect(() => {
    (async () => {

      if(!waterSupply) return;
      setLoading(true);
      const response = await waterSupplyGateway.findOneById(waterSupply.id);
      if(response.length === 0) setIsBlacklisted(undefined);

      const accessTypes = await accessTypeGateway.findAll();
      setAccessTypes(accessTypes);

      if(waterSupply?.accessTypeId){
        const providers = await providersGateway.findWithFilters({
          providerTypeId: ProviderType.Distribuidora.toString(),
          supplyTypeId: SupplyType.Agua.toString(),
          accessTypeId: waterSupply.accessTypeId.toString(),
        });
        setProviders(providers);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkIfEmailIsBlacklisted(value: any) {
    const isEmailFull = value.includes(".com");
    if(isEmailFull) {
      setLoading(true);
      const exists = await blacklistGateway.findEmail(value);
      if(!!exists) {
        value = "";
        snackbar.enqueueSnackbar("La dirección de correo introducida pertenece a un cliente descartado", {
          variant: "error",
        });
      }
      setLoading(false);
    }
    return value;
  }

  const handleChange = async (name: string, value: any) => {
    if (waterSupply === null) {
      return;
    }
    if(name === "code" && value.includes("*")){
      snackbar.enqueueSnackbar("No se puede introducir este carácter especial", {
        variant: "error",
      });
      return;
    }
    if(name === "emailNotification") {
      value = await checkIfEmailIsBlacklisted(value);
    }
    const updatedWaterSupply: IWaterSupplyInterface = {
      ...waterSupply,
      [name]: value,
    } as any;
    waterSupplyStateDispatch({
      type: "update-waterSupply",
      payload: updatedWaterSupply,
    });
    updateBreadCrumbName("waterSupply", updatedWaterSupply.referencia);
  };
  const handleChangeBooleanSendEmail = () => {
    if (waterSupply === null) {
      return;
    }
    const updatedWaterSupply: IWaterSupplyInterface = {
      ...waterSupply,
      sendEmail: !waterSupply.sendEmail,
    } as any;
    waterSupplyStateDispatch({
      type: "update-waterSupply",
      payload: updatedWaterSupply,
    });
  };

  const handleChangeClientSendEmail = () => {
    if (waterSupply === null) {
      return;
    }
    const updatedWaterSupply: IWaterSupplyInterface = {
      ...waterSupply,
      sendClientEmail: !waterSupply.sendClientEmail,
    } as any;
    waterSupplyStateDispatch({
      type: "update-waterSupply",
      payload: updatedWaterSupply,
    });
  };

  const handleCounterCheckChange = () => {
    const updatedWaterSupply: IWaterSupplyInterface = {
      ...waterSupply,
      separatedMeterAccount: waterSupply ? !waterSupply.separatedMeterAccount : null,
    } as any;
    waterSupplyStateDispatch({
      type: "update-waterSupply",
      payload: updatedWaterSupply,
    });
  };
  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };
  const handleStartCaptureDateChange = (value: any) => {
    if (waterSupply === null) {
      return;
    }
    const updatedWaterSupply: IWaterSupplyInterface = {
      ...waterSupply,
      startCaptureDate: value,
    } as any;
    waterSupplyStateDispatch({
      type: "update-waterSupply",
      payload: updatedWaterSupply,
    });
  };
  const handleStartValidationsDate = (value: any) => {
    if (waterSupply === null) {
      return;
    }
    const updatedWaterSupply: IWaterSupplyInterface = {
      ...waterSupply,
      startValidationsDate: value,
    } as any;
    waterSupplyStateDispatch({
      type: "update-waterSupply",
      payload: updatedWaterSupply,
    });
  };
  const handleSeparatedMeterInvoiceFolder = (value: any) => {
    const updatedWaterSupply: IWaterSupplyInterface = {
      ...waterSupply,
      separatedMeterInvoiceFolder: value,
    } as any;
    waterSupplyStateDispatch({
      type: "update-waterSupply",
      payload: updatedWaterSupply,
    });
  };
  const handleSeparatedMeterStartCaptureDateChange = (value: any) => {
    const updatedWaterSupply: IWaterSupplyInterface = {
      ...waterSupply,
      separatedMeterStartCaptureDate: value,
    } as any;
    waterSupplyStateDispatch({
      type: "update-waterSupply",
      payload: updatedWaterSupply,
    });
  };
  const handleSeparatedMeterStartValidationsDateChange = (value: any) => {
    const updatedWaterSupply: IWaterSupplyInterface = {
      ...waterSupply,
      separatedMeterStartValidationsDate: value,
    } as any;
    waterSupplyStateDispatch({
      type: "update-waterSupply",
      payload: updatedWaterSupply,
    });
  };
  const handleSave = () => {
    if (waterSupply === null) {
      return;
    }
    if (!waterSupply.referencia) {
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      setErrorMissingData(true);
      return;
    }
    (async () => {
      try {
        if (!waterSupply) {
          return;
        }
        setLoading(true);

        //domain check
        if (typeof waterSupply.emailNotification === 'string' && waterSupply.emailNotification !== null && waterSupply.emailNotification !== '')
        {
            const input_domain = obtainDomainFromEmail(waterSupply.emailNotification);
            //have content
            const client = await clientsGateway.findById(parseInt(clientId, 10));
            if (typeof client.client_email_domain !== 'undefined' && client.client_email_domain !== '')
            {
                //compare both domains
                if (input_domain !== client.client_email_domain)
                {
                    //es diferente
                    if (window.confirm('El dominio del correo de cliente, no coincide con el dominio configurado en el apartado principal de Cliente, quieres continuar de todos modos ?') == false)
                    {
                        snackbar.enqueueSnackbar('No se han actualizado los cambios', { variant: "warning" });
                        setLoading(false);
                        return;
                    }
                }
            }
            
        }

        const itemToSave = waterSupply as IWaterSupplyInterfaceUpsert;
        await waterSupplyGateway.update(waterSupply.id, itemToSave);
        snackbar.enqueueSnackbar(
          t("waterSupplyDetail.updateSucceded", {
            reference: waterSupply.referencia,
            id: waterSupply.id,
          }),
          {
            variant: "success",
          }
        );
        setErrorMissingData(false);
      } catch (e) {
        const er = e as any;
        if (er.response.data.key === "updatingError") {
          const key = "waterSupplyErrorHandler." + er.response.data.key;
          const message =
            "validation" in er
              ? er.validation
              : t(key, { id: er.response.data.id });
          snackbar.enqueueSnackbar(message, { variant: "error" });
        } else {
          const message =
            "validation" in er ? er.validation : t("messages.defaultError");
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      } finally {
        setLoading(false);
      }
    })();
  };

  if (waterSupply === null) {
    return <CircularProgress className={classes.progress} />;
  }

  const handleInsertItemBlacklist = async () => {
    await blacklistGateway.insertCups(waterSupply.referencia);
  }

  const handleDeleteItemBlacklist = async () => {
    await blacklistGateway.deleteCups(waterSupply.referencia);
  }

  const handleClickAddProviderDialog = () => {
    setOpenProviderAddDialog(true);
  };

  const handleCloseAddProviderDialog = () => {
    setOpenProviderAddDialog(false);
  };

  const handleCreateNewProvider = (
    item: IProviders,
    isNew: boolean,
    supplyToAccessId: number[]
  ) => {
    if (
      item.providerTypeId === ProviderType.Distribuidora &&
      checkSuppliesToAccess(supplyToAccessId)
    ) {
      setProviders([...providers, item]);
    }
  };

  const checkSuppliesToAccess = (suppliesToAccessId: number[]): boolean => {
    for (const supplyToAccessId of suppliesToAccessId) {
      if (supplyToAccessId === 13 || supplyToAccessId === 23) {
        return true;
      }
    }
    return false;
  };

  const handleDeleteSupply = async () => {
    if(window.confirm(t("electricSupplyDetail.deleteSupply") as string)){
      if(!waterSupply) return;

      if(isBlacklisted) await handleDeleteItemBlacklist();
      await waterSupplyGateway.delete(waterSupply.id);
      
      window.location.href = window.location.origin + url.split("waterSupply")[0] + "waterSupply";
    }
    else return;
  }

  return (
    <Box>
      <ScreenGlobalStructure
        buttonIcon={<SaveIcon />}
        headerIcon={OpacityIcon}
        matchUrl={url}
        onButtonClick={handleSave}
        title={t("waterSupplyDetail.title")}
        clientId={clientId ? clientId : ""}
        onBlacklistButtonClick={handleClickBlacklistButton}
        blacklisted={isBlacklisted}
        deleteSupply={handleDeleteSupply}
        loading={loading}
      >
        <WaterSupplyForm
          errorMissingData={errorMissingData}
          supply={waterSupply}
          showAllFields={true}
          onChangeSupply={handleChange}
          validateRequired={validateRequired}
          onSendEmailChange={handleChangeBooleanSendEmail}
          onSendClientEmailChange={handleChangeClientSendEmail}
          handleDateChange={handleStartCaptureDateChange}
          handleValidationDateChange={handleStartValidationsDate}
          onChangeSeparatedMeterInvoiceFolder={handleSeparatedMeterInvoiceFolder}
          onSeparatedMeterStartCaptureDateChange={handleSeparatedMeterStartCaptureDateChange}
          onSeparatedMeterStartValidationsDateChange={handleSeparatedMeterStartValidationsDateChange}
          providers={providers}
          onProviderAddButtonClick={handleClickAddProviderDialog}
          accessTypes={accessTypes}
          onCounterCheckChange={handleCounterCheckChange}
        />
      </ScreenGlobalStructure>
      <ProvidersUpsertDialog
        open={openProviderAddDialog}
        item={undefined}
        onClose={handleCloseAddProviderDialog}
        onItemUpsert={handleCreateNewProvider}
        type={"waterDetail"}
      />
    </Box>
  );
};

export default WaterSupplyDetail;
