import React, { useEffect, useState, useContext } from "react";
import { useParams, useRouteMatch } from "react-router";
import {
  makeStyles,
  Theme,
  createStyles,
  Box,
  CircularProgress,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import EstablishmentIcon from "@material-ui/icons/Store";
import { useTranslation } from "react-i18next";
import { IComplex } from "../../gateways/complex.interfaces";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import ICompany, { initialICompany } from "../../gateways/company.interface";
import { ComplexGateway } from "../../gateways/complex.gateway";
import { CompanyGateway } from "../../gateways/company.gateway";
import {
  initialIEstablisment,
  IEstablishment,
} from "../../gateways/establishment.interface";
import { EstablishmentGateway } from "../../gateways/establishment.gateway";
import { EstablishmentTypeGateway } from "../../gateways/establishment-type.gateway";
import { IEstablishmentType } from "../../gateways/establishment-type.interface";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import EstablishmentForm from "./establishment-form";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import EstablishmentDrawer from "../../components/establishment-drawer";
import CompanyAddDialog from "../companies/company-add-dialog";
import ComplexUpsertDialog from "../complex/complex-upsert-dialog";
import EstablishmentTypeAddDialog from "../establishment-type/establishment-type-add-dialog";
import { EstablishmentContext } from "./establishment-context";
import {
  Coordinate,
  ICoordinatesReturn,
  initialCoordinatesReturn,
} from "../../gateways/coordinates.interfaces";
import CoordinatesGateway from "../../gateways/coordinates.gateway";
import { ClientsGateway } from "../../gateways/clients.gateway";
import { obtainDomainFromEmail } from "../../utils/utils";

const initialCompanies: ICompany[] = [];
const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  itemAvatarIcon: {
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
}));

const EstablishmentDetail = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const { complexId } = useParams() as any;
  const { companyId } = useParams() as any;
  const { clientId } = useParams() as any;
  const { establishmentId } = useParams() as any;

  const { url } = useRouteMatch();

  const [loading, setLoading] = useState(false);
  const [establishmentState, establishmentStateDispatch] =
    useContext(EstablishmentContext);
    
  const establishment = establishmentState.establishment;
  let blacklisted = establishment && establishment.cancelledDate !== null ? true: false;
  const [isBlacklisted, setIsBlacklisted] = useState<boolean | undefined>(blacklisted);
  
  const [complex, setComplex] = useState<IComplex[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [establishmentsTypes, setEstablishmentsTypes] = useState<
    IEstablishmentType[]
  >([]);
  const [coordinates, setCoordinates] = useState<string>("");
  const [coordinatesData, setCoordinatesData] = useState<Coordinate>(
    {latitude:39.59630865176704, longitude: 2.6565865248007134 })

  const { update: updateBreadCrumbName } = useBreadCrumbName();

  const complexGateway = useIoC(ComplexGateway);
  const companyGateway = useIoC(CompanyGateway);
  const clientsGateway = useIoC(ClientsGateway);
  const establishmentGateway =
    useIoC(EstablishmentGateway);
  const establishmentTypeGateway = useIoC(
    EstablishmentTypeGateway
  );
  const coordinatesGateway = useIoC(CoordinatesGateway);

  const [openCreateEstablishmentType, setOpenCreateEstablishmentType] =
    useState(false);
  const [openCreateCompany, setOpenCreateCompany] = useState(false);
  const [openCreateComplex, setOpenCreateComplex] = useState(false);
  const [ direction, setDirection ] = useState("");

  const handleEstablishmentState = () => {
    if(establishment){
      if(isBlacklisted){
        updateBreadCrumbName('establishments', establishment.name);
        establishment.cancelledDate = null;
        snackbar.enqueueSnackbar('Se ha reestablecido el establecimiento correctamente', {
          variant: 'success',
        });
      }
      else{
        establishment.cancelledDate = new Date();
        updateBreadCrumbName('establishments', establishment.name+" (Cancelado)");
        snackbar.enqueueSnackbar('Se ha descartado el establecimiento correctamente', {
          variant: 'success',
        });
      }

      handleSave();
      setIsBlacklisted(!isBlacklisted);
    }
  }

  const handleOpenEstablishmentTypeDialog = () => {
    setOpenCreateEstablishmentType(true);
  };

  const handleCloseEstablishmentTypeDialog = () => {
    setOpenCreateEstablishmentType(false);
  };

  const handleCreateEstablishmentType = (item: IEstablishmentType) => {
    setEstablishmentsTypes([...establishmentsTypes, item]);
  };

  const handleOpenCompanyDialog = () => {
    setOpenCreateCompany(true);
  };

  const handleCloseCompanyDialog = () => {
    setOpenCreateCompany(false);
  };

  const handleCreateCompany = (item: ICompany) => {
    if (!companyId) {
      setCompanies([...companies, item]);
    }
  };

  const handleOpenComplexDialog = () => {
    setOpenCreateComplex(true);
  };

  const handleCloseComlexDialog = () => {
    setOpenCreateComplex(false);
  };

  const handleCreateComplex = (item: IComplex) => {
    if (!complexId) {
      setComplex([...complex, item]);
    }
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      if (!establishmentId || establishment === null) {
        return;
      }

      const response = await establishmentGateway.findOneById(establishment.id);
      if(response.length === 0) setIsBlacklisted(undefined);

      if (establishment.coordinatesId) {
        const initialCoordiantes = await coordinatesGateway.findById(
          establishment.coordinatesId
        );
        setCoordinates(
          `${initialCoordiantes.latitude}, ${initialCoordiantes.longitude}`
        );
        setCoordinatesData(
           {latitude:initialCoordiantes.latitude , longitude:initialCoordiantes.longitude}
           )
      }

      const establishmentTypes = await establishmentTypeGateway.findAll();
      setEstablishmentsTypes(establishmentTypes);

      if (complexId) {
        const complex = await complexGateway.findById(
          Number.parseInt(complexId as any)
        );
        setComplex([...[], complex]);

        const company = await companyGateway.findById(
          complex.companyId.toString()
        );
        setCompanies([...initialCompanies, company]);
      } else if (companyId) {
        const companyItem = await companyGateway.findById(companyId.toString());
        setCompanies([...initialCompanies, companyItem]);
        const complexs = await complexGateway.findAll({ companyId: parseInt(companyId, 10) });
        setComplex(complexs);
      } else if (clientId) {
        const companies = await companyGateway.findAll({ clientId:  parseInt(clientId as any, 10) });
        setCompanies(companies);
        const complexs = await complexGateway.findByClientId({
          clientId: Number.parseInt(clientId as any)
        });
        setComplex(complexs);
      }
    })();
    setLoading(false);
  }, []);

  const handleSave = () => {
    if (establishment === null) return;

    if (!establishment.name || !establishment.establishmentTypeId) {
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      return;
    }

    if (establishment.complexId === 0) {
      establishment.complexId = null;
    }

    (async () => {
      try {
        if (!establishmentId) {
          return;
        }

        setLoading(true);

        //domain check
        if (typeof establishment.client_email !== 'undefined' && establishment.client_email !== '')
        {
            const input_domain = obtainDomainFromEmail(establishment.client_email);
            //have content
            const client = await clientsGateway.findById(parseInt(clientId, 10));
            if (typeof client.client_email_domain !== 'undefined' && client.client_email_domain !== '')
            {
                //compare both domains
                if (input_domain !== client.client_email_domain)
                {
                    //es diferente
                    if (window.confirm('El dominio del correo de cliente, no coincide con el dominio configurado en el apartado principal de Cliente, quieres continuar de todos modos ?') == false)
                    {
                        snackbar.enqueueSnackbar('No se han actualizado los cambios', { variant: "warning" });
                        setLoading(false);
                        return;
                    }
                }
            }
            
        }

        let updatedCoordinates = coordinates;
        let changeCoordinates = '';

        if (direction.trim() !== '')
        {
          const addressCoordinates = await coordinatesGateway.getCoordinates(direction);
          changeCoordinates = `${addressCoordinates.lat}, ${addressCoordinates.lng}`
        } else if ( direction === '' && typeof establishment.address !== 'undefined' && establishment.address.trim() !== '' ) {
          const addressCoordinates = await coordinatesGateway.getCoordinates(establishment.address);
          changeCoordinates = `${addressCoordinates.lat}, ${addressCoordinates.lng}`
        }
        
        setCoordinates(changeCoordinates);
        updatedCoordinates = changeCoordinates;

        const regex = /^(?<latitude>[^,]+),[\s]+(?<longitude>[^,]+)$/;
        const coordinatesRegex = regex.exec(updatedCoordinates);
        const createdCoordinates: ICoordinatesReturn =
          coordinatesRegex && coordinatesRegex.groups
            ? await coordinatesGateway.create({
                latitude: parseFloat(coordinatesRegex.groups.latitude),
                longitude: parseFloat(coordinatesRegex.groups.longitude),
              })
            : initialCoordinatesReturn;
        await establishmentGateway.update(Number.parseInt(establishmentId as any), {
          ...establishment,
          coordinatesId: createdCoordinates.id,
        });

        snackbar.enqueueSnackbar(
          t("establishmentDetail.updateSucceed", {
            id: Number.parseInt(establishmentId as any),
            name: establishment.name,
          }),
          {
            variant: "success",
          }
        );
      } catch (e) {
        if ((e as any).response.data.key === "updatingError") {
          const key = "establishmentErrorHandler." + (e as any).response.data.key;
          const message =
            "validation" in (e as any)
              ? (e as any).validation
              : t(key, { id: (e as any).response.data.id });
          snackbar.enqueueSnackbar(message, { variant: "error" });
        } else {
          const message =
            "validation" in (e as any) ? (e as any).validation : t("messages.defaultError");
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      } finally {
        setLoading(false);
      }
    })();
  };

  const handleChangeAddress = async (name: string, value: string) => {
    setLoading(true);
    const addressCoordinates = await coordinatesGateway.getCoordinates(value);
    const changeCoordinates = `${addressCoordinates.lat}, ${addressCoordinates.lng}`
    handleChange(name, value);
    setCoordinates(changeCoordinates);
    setLoading(false);
  };

  const handleChange = (name: string, value: any) => {
    if (establishment === null) {
      return;
    }
    setLoading(true);
    const updatedEstablishment: IEstablishment = {
      ...establishment,
      [name]: value,
    } as any;
    establishmentStateDispatch({
      type: "update-establishment",
      payload: updatedEstablishment,
    });
    updateBreadCrumbName("establishments", updatedEstablishment.name);
    if (name === "companyId") {
      (async () => {
        const complex = await complexGateway.findAll({ companyId: value });
        setComplex(complex);
      })();
    }
    setLoading(false);
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  if (establishment === null) {
    return <CircularProgress className={classes.progress} />;
  }
  const handleSendBeforeTelemedidaChange = ()=>{
    handleChange('sendBeforeValidating', !establishment.sendBeforeValidating)
  }

  const handleSetDirection = (name: string, value: string) => {
    handleChange(name, value);
    setDirection(value);
  }
  const handleSetCoordinates = (name: string, value: string ) => {
    handleChange(name, value);
    setCoordinates(value);
  }

  const handleSetCoordinatesData = (value: Coordinate) =>{
    setCoordinatesData(value)
  }

  return (
    <Box>
      <ScreenGlobalStructure
        buttonIcon={<SaveIcon />}
        headerIcon={EstablishmentIcon}
        matchUrl={url}
        onButtonClick={handleSave}
        title={t("establishmentDetail.title")}
        clientId={clientId ?? ""}
        loading={loading}
        drawer={
          <EstablishmentDrawer
            matchUrl={url}
            lateralMenu={0}
            icon={
              <EstablishmentIcon
                fontSize="large"
                className={classes.itemAvatarIcon}
              />
            }
            goBack={false}
            contacts={true}
          />
        }
        onBlacklistButtonClick={handleEstablishmentState}
        blacklisted={isBlacklisted}
      >
        <EstablishmentForm
          companies={companies}
          complex={complex}
          establishmentsTypes={establishmentsTypes}
          item={establishment}
          onChange={handleChange}
          validateRequired={validateRequired}
          onClickAddEstablishmentTypeButton={handleOpenEstablishmentTypeDialog}
          onClickAddCompanyButton={
            companyId ? undefined : handleOpenCompanyDialog
          }
          onClickAddComplexButton={
            complexId ? undefined : handleOpenComplexDialog
          }
          coordinates={coordinates}
          coordinatesData={coordinatesData}
          handleSetCoordinatesData={handleSetCoordinatesData}
          handleChangeAddress={handleChangeAddress}
          handleSetDireccion={handleSetDirection}
          handleSetCoordinates={handleSetCoordinates}
          onSendBeforeTelemedidaChange={handleSendBeforeTelemedidaChange}
          largeXs={10}
          smallXs={2}
        />
      </ScreenGlobalStructure>
      <CompanyAddDialog
        clientId={clientId ? parseInt(clientId as any, 10) : 0}
        onClose={handleCloseCompanyDialog}
        onItemCreated={handleCreateCompany}
        onLoading={() => loading}
        open={openCreateCompany}
      />
      <ComplexUpsertDialog
        clientId={clientId ? parseInt(clientId as any, 10) : 0}
        companyId={companyId ? parseInt(companyId, 10) : 0}
        item={undefined}
        onClose={handleCloseComlexDialog}
        onItemUpsert={handleCreateComplex}
        onLoading={() => loading}
        open={openCreateComplex}
      />
      <EstablishmentTypeAddDialog
        onClose={handleCloseEstablishmentTypeDialog}
        onCreated={handleCreateEstablishmentType}
        open={openCreateEstablishmentType}
      />
    </Box>
  );
};

export default EstablishmentDetail;
