import React, { FC, useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import CountryGateway from "../../gateways/country.gateway";
import CurrencyGateway from "../../gateways/currency.gateway";
import { datesHandler } from "../../utils/dates-handle";
import { IRegisterFuelBillCreate, IRegisterFuelBill, initialIRegisterFuelBillCreate } from "../../gateways/register-fuel-bill.interface";
import { RegisterFuelBillGateway } from "../../gateways/register-fuel-bill.gateway";
import { FuelContractGateway } from "../../gateways/fuel-contract.gateway";
import { FuelSupplyGateway } from "../../gateways/fuel-supply.gateway";
import RegisterFuelBillGeneral from "./register-fuel-bill-general";
import RegisterFuelBillNetwork from "./register-fuel-bill-network";
import { VolumeUnitGateway } from "../../gateways/volume-units.gateway"
import RegisterFuelBillGasoilDiesel from "./register-fuel-bill-gasoil-diesel";
import { IFuelContract, initialIFuelContract } from "../../gateways/fuel-contract.interfaces";


interface IRegisterFuelBillUpsertDialogPropsNetworkGasoilDieselProps {
    open: boolean;
    item: IRegisterFuelBillCreate | undefined;
    fuelSupplyId: string;
    complexId: string | undefined;
    establishmentId: string | undefined;
    onItemUpsert: (item: IRegisterFuelBill, isNew: boolean) => void;
    onClose: () => void;
};

const RegisterFuelBillUpsertDialogNetworkGasoilDiesel:
    FC<IRegisterFuelBillUpsertDialogPropsNetworkGasoilDieselProps> = (props) => {
        const { t } = useTranslation();
        const [item, setItem] = useState<IRegisterFuelBillCreate>(initialIRegisterFuelBillCreate);
        const [currency, setCurrency] = useState<string>('');
        const [volumeUnit, setVolumeUnit] = useState<string>('');
        const [fuelContract, setFuelContract] = useState<IFuelContract>(initialIFuelContract);
        const snackbar = useSnackbar();
        const dateHandler = new datesHandler;

        const registerFuelBillGateway = useIoC(RegisterFuelBillGateway);
        const fuelContractGateway = useIoC(FuelContractGateway);
        const countriesGateway = useIoC(CountryGateway);
        const currenciesGateway = useIoC(CurrencyGateway);
        const fuelSupplyGateway = useIoC(FuelSupplyGateway);
        const volumeUnitGateway = useIoC(VolumeUnitGateway);

        const createFuelBill = async (item: IRegisterFuelBillCreate) => {
            const registerFuelBill = await registerFuelBillGateway.create({ ...item, fuelSupplyId: parseInt(props.fuelSupplyId) });
            snackbar.enqueueSnackbar(t('registerFuelBillUpsertDialog.createSucceded',
                { id: registerFuelBill.id }), { variant: "success" });
            props.onItemUpsert(registerFuelBill, true);
        };

        const upsertElectricSupply = async (item: IRegisterFuelBillCreate) => {
            try {
                if (!props.item) {
                    await createFuelBill(item);
                } else {
                    throw Error();
                }
            } catch (e) {
                const er = e as any;
                const key = 'registerFuelBillErrorHandler.' + er.response.data.key;
                if (er.response.data.key === 'creatingEntityError') {
                    const message = "validation" in er ? er.validation : t(key);
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                } else if (er.response.data.key === 'updatingError') {
                    const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                } else {
                    const message = "validation" in er ? er.validation : t('messages.defaultError');
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                }
            } finally {
                props.onClose();
            }
        };

        const handleAccept = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (!item.issueDate || !item.numberBill || !item.totalBillImport
                || !item.startingPeriodDate || !item.endingPeriodDate
                || !item.gnM3 || !item.gnPCS || !item.gnKWHPCS|| !item.gnKWHPCSOnly || !item.gnTF
                || !item.gnTV || !item.gnOtros || !item.importEquipmentRental
                || !item.hydrocarbonsTax || !item.gnTotal || !item.monthlyPayment
                || !item.amount || !item.unitaryPrice) {
                event.preventDefault();
                snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
                return;
            }

            if (!dateHandler.checkLimits(item.startingPeriodDate, item.endingPeriodDate)) {
                event.preventDefault();
                snackbar.enqueueSnackbar(t('messages.dateRangeError'), { variant: "error" });
                return;
            }
            if (!(await checkBillOverlap())) {
                return;
            }
            upsertElectricSupply(item);
        };

        const checkBillOverlap = async (): Promise<boolean> => {
            const existingBills: IRegisterFuelBill[] =
                await fuelSupplyGateway.findByIdRegisterFuelBill(parseInt(props.fuelSupplyId));
            for (let bill of existingBills) {
                if (item.startingPeriodDate && item.endingPeriodDate && bill.startingPeriodDate && bill.endingPeriodDate) {
                    if (dateHandler.checkOverlap(new Date(item.startingPeriodDate),
                        new Date(item.endingPeriodDate),
                        bill.startingPeriodDate,
                        bill.endingPeriodDate)) {

                        snackbar.enqueueSnackbar(t('messages.dateOverlapErrorBill', { id: bill.id }), { variant: "error" });
                        return false;
                    }
                }

            }
            return true;
        };
        const formatDate = (dateString: Date) => {
            const date = new Date(dateString.toString());
            if (date.toString() === 'Invalid Date') {
                return t('common.unknown');
            }
            return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();

        };

        const fuelContractInfo = (fuelContract: IFuelContract) => {
            return formatDate(fuelContract.initSupplyDate) + ' - '
                + formatDate(fuelContract.endSupplyDate);
        };

        const handleChange = (name: string, value: any) => {
            setItem({ ...item, [name]: value });
        };

        const handleIssueDateSelector = (value: any) => {
            setItem({ ...item, issueDate: value });
        };

        const handleStartingPeriodDateSelector = (value: any) => {
            setItem({ ...item, startingPeriodDate: value });
        };

        const handleEndingPeriodDateSelector = (value: any) => {
            setItem({ ...item, endingPeriodDate: value });
        };

        const validateRequired = (value: any) => {
            return value !== undefined && value !== null && value !== '' && value !== 0;
        };

        useEffect(() => {
            (async () => {
                let coinId;
                let coin;

                let volumeUnitId;
                let volumeUnit;

                if (props.complexId) {
                    try {
                        coinId = await countriesGateway.getCountrieCurrencyComplex(parseInt(props.complexId));
                        coin = await currenciesGateway.findById(coinId);
                        setCurrency(coin.isoCode);

                    } catch (e) {
                        snackbar.enqueueSnackbar(t('currencyErrorHandler.readErrorComplex'), { variant: "warning" });
                    }

                    try {
                        volumeUnitId = await countriesGateway.getCountrieVolumeUnitComplex(parseInt(props.complexId));
                        volumeUnit = await volumeUnitGateway.findById(volumeUnitId);
                        setVolumeUnit(volumeUnit.name);

                    } catch (e) {
                        snackbar.enqueueSnackbar(t('volumeUnitsErrorHandler.readErrorComplex'), { variant: "warning" });
                    }
                } else if (props.establishmentId) {
                    try {
                        coinId = await countriesGateway.getCountrieCurrencyEstablishment(parseInt(props.establishmentId));
                        coin = await currenciesGateway.findById(coinId);
                        setCurrency(coin.isoCode);
                    } catch (e) {
                        snackbar.enqueueSnackbar(t('currencyErrorHandler.readErrorEstablishment'), { variant: "warning" });
                    }

                    try {
                        volumeUnitId = await countriesGateway.getCountrieVolumeUnitEstablishment(parseInt(props.establishmentId));
                        volumeUnit = await volumeUnitGateway.findById(volumeUnitId);
                        setVolumeUnit(volumeUnit.name);

                    } catch (e) {
                        snackbar.enqueueSnackbar(t('volumeUnitsErrorHandler.readErrorEstablishment'), { variant: "warning" });
                    }
                }
                if (initialIRegisterFuelBillCreate.startingPeriodDate !== null) {
                    const contract = await fuelContractGateway.findByBillDate(initialIRegisterFuelBillCreate.startingPeriodDate, props.fuelSupplyId);
                    setFuelContract(contract);
                    setItem({ ...initialIRegisterFuelBillCreate, fuelContractId: contract.id });
                } else {
                    setItem({ ...initialIRegisterFuelBillCreate, fuelContractId: 0 });
                    setFuelContract(initialIFuelContract);
                }

            }
            )();
        }, [props.item, props.open]);

        useEffect(() => {
            (async () => {
                try {
                    if (item.startingPeriodDate !== null) {
                        const contract = await fuelContractGateway.findByBillDate(item.startingPeriodDate, props.fuelSupplyId);
                        setFuelContract(contract);
                        setItem({ ...item, fuelContractId: contract.id });
                    } else {
                        setItem({ ...item, fuelContractId: 0 });
                        setFuelContract(initialIFuelContract);
                    }
                } catch (e) {
                    const data = await fuelSupplyGateway.findByIdFuelContract(parseInt(props.fuelSupplyId));
                    setItem({ ...item, fuelContractId: 0 });
                    setFuelContract(initialIFuelContract);
                    if (data.length > 0) {
                        if (item.startingPeriodDate) {
                            snackbar.enqueueSnackbar(t('messages.contractNotFoundError', {
                                day: item.startingPeriodDate.getDate(),
                                month: item.startingPeriodDate.getMonth() + 1,
                                year: item.startingPeriodDate.getFullYear()
                            }), { variant: "error" });
                        }
                    } else {
                        snackbar.enqueueSnackbar(t('messages.nonExistentContracts'), { variant: "error" });
                    }
                }
            })();
        }, [item.startingPeriodDate]);

        useEffect(() => {
            (async () => {
                if (item.startingPeriodDate && item.endingPeriodDate && props.open
                    && !dateHandler.checkLimits(item.startingPeriodDate, item.endingPeriodDate)) {
                    snackbar.enqueueSnackbar(t('messages.dateRangeError'), { variant: "error" });
                    return;
                }
                if (props.open) {
                    await checkBillOverlap();
                }
            })();
        }, [item.startingPeriodDate, item.endingPeriodDate]);

        return (
            <Dialog open={props.open} onClose={() => { props.onClose() }}>
                <DialogTitle>{!props.item ? t('registerFuelBillUpsertDialog.createTitle') : t('registerFuelBillUpsertDialog.updateTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{!props.item ? t('registerFuelBillUpsertDialog.createContent') : t('registerFuelBillUpsertDialog.updateContent')}</DialogContentText>

                    <RegisterFuelBillGeneral
                        handleChange={handleChange}
                        handleIssueDateSelector={handleIssueDateSelector}
                        item={item}
                        validateRequired={validateRequired}
                    />
                    <RegisterFuelBillNetwork
                        currency={currency}
                        handleChange={handleChange}
                        handleEndingPeriodDateSelector={handleEndingPeriodDateSelector}
                        handleStartingPeriodDateSelector={handleStartingPeriodDateSelector}
                        item={item}
                        validateRequired={validateRequired}
                        fuelContract={fuelContract}
                        fuelContractInfo={fuelContractInfo}
                    />
                    <RegisterFuelBillGasoilDiesel
                        currency={currency}
                        handleChange={handleChange}
                        item={item}
                        validateRequired={validateRequired}
                        volumeUnit={volumeUnit} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { props.onClose() }} color="primary">{t('common.cancel')}</Button>
                    <Button onClick={handleAccept} color="primary">{t('common.accept')}</Button>
                </DialogActions>
            </Dialog>
        );
    };

export default RegisterFuelBillUpsertDialogNetworkGasoilDiesel;