import {
  createStyles,
  IconButton,
  ListItem,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { memo } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import SaveIcon from "@material-ui/icons/Save";
import { ICoordinatesEstCmplx } from "../../gateways/coordinates.interfaces";

interface ICoordinatesFieldsProps {
  focus: boolean;
  coordinates: ICoordinatesEstCmplx;
  onChange: (name: string, value: any) => void;
  handleSave: (id: string, currPage: number,
    currNPages: number) => void;
    currentPage: number;
    currentNumberOfPages: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      height: "100%",
      borderSpacing: 2,
      padding: theme.spacing(1),
    },
  })
);

const CoordinatesFields: FC<ICoordinatesFieldsProps> = memo((props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const xs = 3;
  const handleClick = async (id: string) => {
    props.handleSave(id, props.currentPage, props.currentNumberOfPages);
  };
  return (
    <ListItem alignItems="flex-start">
      <Grid container direction="row" xs={12}>
        <Grid xs={xs} className={classes.gridItem}>
          <FormTextField
            fullWidth
            disabled
            label={t("marketing.client")}
            name="clientName"
            validator={() => {
              return true;
            }}
            errorText={t("messages.requiredField") as string}
            type="text"
            value={props.coordinates.clientName}
            onChange={props.onChange}
          />
        </Grid>
        <Grid xs={xs} className={classes.gridItem}>
          <FormTextField
            fullWidth
            disabled
            label={`${
              props.coordinates.isEst
                ? "Nombre de establecimiento:"
                : "Nombre de complejo:"
            }`}
            name="name"
            validator={() => {
              return true;
            }}
            errorText={t("messages.requiredField") as string}
            type="text"
            value={props.coordinates.name}
            onChange={props.onChange}
          />
        </Grid>
        <Grid xs={xs} className={classes.gridItem}>
          <FormTextField
            fullWidth
            disabled
            label={t("marketing.address")}
            name="address"
            validator={() => {
              return true;
            }}
            errorText={t("messages.requiredField") as string}
            type="text"
            value={props.coordinates.address}
            onChange={props.onChange}
          />
        </Grid>
        <Grid container xs={xs} className={classes.gridItem} direction="row">
          <Grid xs={11}>
            {props.focus ? (
              <FormTextField
                autoFocus
                fullWidth
                label={t("common.coordinates") + " (lat, long)"}
                name={`${props.coordinates.isEst}-${props.coordinates.locationId}`}
                validator={() => {
                  return true;
                }}
                errorText={t("messages.requiredField") as string}
                type="text"
                value={
                  props.coordinates.coordinates
                    ? props.coordinates.coordinates.formatted
                    : ""
                }
                onChange={props.onChange}
              />
            ) : (
              <FormTextField
                fullWidth
                label={t("common.coordinates") + " (lat, long)"}
                name={`${props.coordinates.isEst}-${props.coordinates.locationId}`}
                validator={() => {
                  return true;
                }}
                errorText={t("messages.requiredField") as string}
                type="text"
                value={
                  props.coordinates.coordinates
                    ? props.coordinates.coordinates.formatted
                    : ""
                }
                onChange={props.onChange}
              />
            )}
          </Grid>
          <Grid xs={1}>
            <IconButton
              color="primary"
              aria-label="add"
              onClick={() => handleClick(JSON.stringify(props.coordinates))}
            >
              <SaveIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
});

export default CoordinatesFields;
