import React, { FC } from "react";
import { makeStyles, ListItem, ListItemAvatar, ListItemText, IconButton } from "@material-ui/core";
import EstablishmentIcon from '@material-ui/icons/Store';
import { IEstablishment } from "../../gateways/establishment.interface";
import ProvidersUpsertDialog from "../providers/providers-upsert-dialog";


interface IEstablishmentItemProps {
    item: IEstablishment;
    showDiscarded: boolean;
    onClickItem: (item: IEstablishment) => void;
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    discardedEstablishment: {
        color: "#E0E0E0"
    },
    nonDiscardedEstablishment: {
        color: "black"
    }
}));

const EstablishmentItem: FC<IEstablishmentItemProps> = (props) => {
    const classes = useStyles();
    
    const color = props.showDiscarded ? classes.discardedEstablishment: classes.nonDiscardedEstablishment;

    return (
        <ListItem alignItems="flex-start" className={classes.listItem} onClick={() => { props.onClickItem(props.item)} }>
            <ListItemAvatar >
                <EstablishmentIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
            </ListItemAvatar>
            <ListItemText primary={props.item.name} secondary={props.item.address} className={color} />
        </ListItem>
    );
};

export default EstablishmentItem;