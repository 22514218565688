import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { ISupplyBot, ISaveSupplyBot } from "./supply-bots.interface";

@inject('axios')
export class SupplyBotsGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async findAll(): Promise<ISupplyBot[]> {
        const response = await this.axios.get('supply-bots');
        return response.data;
    }

    public async get(id: number): Promise<ISupplyBot> {
        const response = await this.axios.get('supply-bots/' + id);
        return response.data;
    }

    public async create(dto: ISaveSupplyBot): Promise<ISupplyBot> {
        const response = await this.axios.post('supply-bots', dto);
        return response.data;
    }

    public async update(id: number, dto: ISaveSupplyBot): Promise<ISupplyBot> {
        const response = await this.axios.put('supply-bots/' + id, dto);
        return response.data;
    }
}