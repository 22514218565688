import React, { FC, useState, useMemo, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { makeStyles, Box, Fab, List, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import CenteredCircularProgress from '../../components/centered-circular-progress';
import AlertBox from '../../components/alert-box';
import SectorItem from './sector-item';
import { useNavigator } from '../../contexts/navigator.context';
import SectorIcon from '@material-ui/icons/AccountTree';
import useIoC from '../../contexts/ioc.context';
import { SectorGateway } from '../../gateways/sector.gateway';
import ISector from '../../gateways/sector.interfaces';
import SectorCreateDialog from './sector-create-dialog';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    }
}));

const SectorList: FC<RouteComponentProps> = () => {
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState<ISector[]>([]);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);
    const [, navigatorDispatch] = useNavigator();
    const sectorGateway = useIoC(SectorGateway);

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('sectorList.title'),
                icon: SectorIcon
            }
        });
    }, []);

    useEffect(() => {
        (async () => {
            setIsDataLoading(true);
            const data = await sectorGateway.findAll();
            setData(data);
            setIsDataLoading(false);
        })();
    }, [sectorGateway]);

    const handleClickFabButton = () => {
        setOpenCreateDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenCreateDialog(false);
    };

    const handleItemCreate = (item: ISector) => {
        const copy = data.slice();
        setData([item, ...copy]);
    };

    const handleItemUpdate = (item: ISector) => {
        const copy = data.slice();
        const index = copy.findIndex((value, index) => value.id === item.id);
        copy[index] = item;
        setData(copy);
    };

    const handleItemDelete = (id: number) => {
        let copy = data.filter((value) => {
            return value.id !== id;
        });
        setData(copy);
    };

    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />;
        }

        if (isDataEmpty) {
            return <AlertBox variant="info">{t('common.noItems')}</AlertBox>;
        }

        return (
            <List>
                {data.map((item, index) => {
                    return (
                        <div key={index}>
                            <SectorItem item={item} onItemUpdate={handleItemUpdate} onItemDelete={handleItemDelete} />
                            {index < data.length - 1 ? <Divider variant="inset" component="li" /> : null}
                        </div>
                    );
                })}
            </List>
        );
    };

    return (
        <Box>
            <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleClickFabButton}>
                <AddIcon />
            </Fab>
            <Content />
            <SectorCreateDialog
                open={openCreateDialog}
                onItemCreate={handleItemCreate}
                onClose={handleCloseDialog} />
        </Box>
    );
}

export default SectorList;