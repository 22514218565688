import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IComplex, ICreateComplex, IUpdateComplex } from "./complex.interfaces";
import IRegion from "./region.interface";
import querystring from "querystring";
import { IElectricSupply } from "./electric-supply.interface";

type ComplexFindAllFilter = {
  companyId?: number;
  clientId?: number;
  countryId?: number;
  showDiscarded?: boolean;
};

type ComplexFindByClientId = {
  clientId: number;
  showDiscarded?: boolean;
}
@inject("axios")
export class ComplexGateway {
  constructor(private axios: AxiosInstance) {}

  public async findAll(filter?: ComplexFindAllFilter): Promise<IComplex[]> {
    let url = "complex";
    if (filter) {
      const query = querystring.stringify(filter);
      url += `?${query}`;
    }
    const response = await this.axios.get(url);
    return response.data;
  }

  async findAllFiltered(input: string): Promise<string[]> {
    const response = await this.axios.get(`complex/filter/${input}`);
    return response.data;
  }

  public async findById(id: number): Promise<IComplex> {
    const response = await this.axios.get("complex/" + id);
    return response.data;
  }

  public async findByClientId(filter: ComplexFindByClientId): Promise<IComplex[]> {

    const query = querystring.stringify(filter);
    const url = `complex/client?${query}`;
    
    const response = await this.axios.get(url);
    return response.data;
  }

  public async findOneById(id: number): Promise<IComplex[]> {
    const response = await this.axios.get(`complex/findOneById/${id}`);
    return response.data;
  }

  public async findByIdAllRegions(id: number): Promise<IRegion[]> {
    const response = await this.axios.get("complex/regions/" + id);
    return response.data;
  }

  public async create(dto: ICreateComplex): Promise<IComplex> {
    if (dto.coordinatesId === 0) {
      dto.coordinatesId = null;
      dto.direction = " ";
    }
    const response = await this.axios.post("complex", dto);
    return response.data;
  }

  public async update(id: number, dto: IUpdateComplex): Promise<IComplex> {
    if (dto.coordinatesId === 0) {
      dto.coordinatesId = null;
      dto.direction = " ";
    }
    const response = await this.axios.put("complex/" + id, dto);
    return response.data;
  }

  public async findByIdElectricSupply(id: number): Promise<IElectricSupply[]> {
    const response = await this.axios.get("complex/electricSupply/" + id);
    return response.data;
  }
}
