import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { ICreateValidationRule, FindFilterValidationRule, IValidationRule, IUpdateValidationRule } from "./validation-rules.interfaces";
import querystring from 'querystring';

const validationRules = 'validation-rules';


@inject('axios')
export class ValidationRuleGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(filter?: FindFilterValidationRule): Promise<IValidationRule[]> {

        let url = validationRules;
        if (filter) {
            const query = querystring.stringify(filter);
            url += `?${query}`;
        }
        const response = await this.axios.get(url);
        return response.data;
    }

    async findById(id: number): Promise<IValidationRule> {
        const url = `${validationRules}/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    async create(validationRuleToCreate: ICreateValidationRule): Promise<IValidationRule> {
        const response = await this.axios.post(validationRules, validationRuleToCreate);
        return response.data;
    }

    async update(id: number, validationRuleToUpdate: IUpdateValidationRule): Promise<IValidationRule> {
        const url = `${validationRules}/${id}`;
        const response = await this.axios.put(url, validationRuleToUpdate);
        return response.data;
    }

    async delete(id: number): Promise<void> {
        const url = `${validationRules}/${id}`;
        const response = await this.axios.delete(url);
        return response.data;
    }
}