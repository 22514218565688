import React, { FC, useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Chip, makeStyles, createStyles, Theme, Grid } from "@material-ui/core";
import FormTextField from "../../components/form-text-field";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import { IAutoComplete, IHiringDashboard, initialIHiringDashboard, ISocietyAutoComplete } from "../../gateways/hiring-dashboard.interfaces";
import { HiringDashboardGateway } from "../../gateways/hiring-dashboard.gateway";
import IContact from "../../gateways/contact.interface";
import CountryGateway from "../../gateways/country.gateway";
import FormTextFieldAutoComplete from "../../components/form-text-field-auto-complete";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { ClientsGateway } from "../../gateways/clients.gateway";
import { CompanyGateway } from "../../gateways/company.gateway";
import { ComplexGateway } from "../../gateways/complex.gateway";
import { ContactGateway } from "../../gateways/contact.gateway";
import { ElectricSupplyRatesGateway } from "../../gateways/electric-supply-rates.gateway";
import { ElectricSupplyGateway } from "../../gateways/electric-supply.gateway";
import { EstablishmentGateway } from "../../gateways/establishment.gateway";
import { FuelSupplyRatesGateway } from "../../gateways/fuel-supply-rates.gateway";
import { FuelSupplyGateway } from "../../gateways/fuel-supply.gateway";
import { FuelTypesGateway } from "../../gateways/fuel-types.gateway";
import { ProvidersGateway } from "../../gateways/providers.gateway";
import ICompany from "../../gateways/company.interface";
import { RegionsGateway } from "../../gateways/regions-gateway";

interface IHiringDashboardDialogProps {
    open: boolean;
    item: IHiringDashboard | undefined;
    onItemUpsert: (item: IHiringDashboard, isNew: boolean) => void;
    onClose: () => void;
    rates: IAutoComplete;
    primaryEnergies: IAutoComplete;
    contacts: IContact[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5)
        },
        button: {
            margin: theme.spacing(0.5),

        },
        textField: {
            margin: theme.spacing(0.5)
        },
        gridItem: {
            height: '100%',
            borderSpacing: 2,
            padding: theme.spacing(1)
        }
    })
);
const HiringDashboardUpsertDialog: FC<IHiringDashboardDialogProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [item, setItem] = useState<IHiringDashboard>(initialIHiringDashboard);

    const [energyType, setEnergyType] = useState<'electricidad' | 'fuel'>('electricidad');
    const [both, setBoth] = useState<boolean>(true);
    const snackbar = useSnackbar();
    const countryGateway = useIoC(CountryGateway);
    const clientsGateway = useIoC(ClientsGateway);
    const fuelSuppliesGateway = useIoC(FuelSupplyGateway);
    const electricSuppliesGateway = useIoC(ElectricSupplyGateway);
    const complexGateway = useIoC(ComplexGateway);
    const establishmentGateway = useIoC(EstablishmentGateway);
    const societyGateway = useIoC(CompanyGateway);
    const regionsGateway = useIoC(RegionsGateway);
    const providersGateway = useIoC(ProvidersGateway);
    const contactsGateway = useIoC(ContactGateway);

    const electricRatesGateway = useIoC(ElectricSupplyRatesGateway);
    const fuelRatesGateway = useIoC(FuelSupplyRatesGateway);
    const fuelTypeGateway = useIoC(FuelTypesGateway);

    const hiringDashboardGateway = useIoC(HiringDashboardGateway);


    const createProvider = async (item: IHiringDashboard) => {

        const hiringDashboard = await hiringDashboardGateway.create(item);
        snackbar.enqueueSnackbar(t('hiringDashboardUpsertDialog.createSucceded',), { variant: "success" });
        props.onItemUpsert(hiringDashboard, true);
    };

    const updateProvider = async (item: IHiringDashboard) => {

    };

    const upsertProviders = async (item: IHiringDashboard) => {
        try {
            if (!props.item) {
                await createProvider(item);
            } else {
                await updateProvider(item);
            }
        } catch (e) {
            const er = e as any;
            const key = 'hiringDashboardErrorHandler.' + er.response.data.key;
            if (er.response.data.key === 'creatingEntityError') {
                const message = "validation" in er ? er.validation : t(key);
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else if (er.response.data.key === 'updatingError') {
                const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose();
        }
    };

    const handleAccept = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (
            !item.clientName ||
            !item.country ||
            !item.society ||
            !item.cups ||
            !item.cif ||
            !item.rate ||
            (!item.establishment && !item.complex)
        ) {
            event.preventDefault();
            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        }


        upsertProviders(item);
    };

    const handleChange = (name: string, value: any) => {
        setItem({ ...item, [name]: value });
    };

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    useEffect(() => {
        if (props.item) {
            setBoth(false);
            if (props.item.primaryEnergy === 'Electricidad') {
                setEnergyType('electricidad');
            } else {
                setEnergyType('fuel');
            }
            setItem(props.item);
        } else {
            setBoth(true);
            setItem(initialIHiringDashboard);
        }
    }, [props.item, props.open]);

    useEffect(() => {
        let electric: boolean = false;
        for (const rate of props.rates.electricidad) {
            if (item.rate === rate) {
                electric = true;
                setEnergyType('electricidad');
                setItem({ ...item, primaryEnergy: 'Electricidad' })
                break
            }
        }
        if (!electric) {
            setEnergyType('fuel');
        }
        if (item.rate) {
            setBoth(false)
        } else {
            setBoth(true)
        }
    }, [item.rate]);

    useEffect(() => {
        if (item.primaryEnergy === 'Electricidad') {
            setBoth(false);
            setEnergyType('electricidad');
        } else if (!item.primaryEnergy) {
            setBoth(true);
        } else {
            setBoth(false);
            setEnergyType('fuel');
        }
    }, [item.primaryEnergy]);

    const getCups = async (input: string): Promise<string[]> => {
        const electricCups = both || energyType === 'electricidad' ? await electricSuppliesGateway.findAllFiltered(input) : [];
        if (electricCups.length > 0) {
            return electricCups
        }
        const fuelCups = both || energyType === 'fuel' ? await fuelSuppliesGateway.findAllFiltered(input) : [];
        return fuelCups;
    }

    const getSociety = async (input: string, toLookFor: string): Promise<string[]> => {
        const companies = await societyGateway.findAllFiltered(toLookFor, input);
        return companies.map((company: ICompany) => { return (toLookFor === 'name' ? company.name : company.cif) });
    }

    const onCurrentContractSigningDateChange = (value: any) => {
        setItem({ ...item, currentContractSigningDate: value });
    };
    const onCurrentContractInitDateChange = (value: any) => {
        setItem({ ...item, currentContractInitDate: value });
    };
    const onCurrentContractEndDateChange = (value: any) => {
        setItem({ ...item, currentContractEndDate: value });
    };
    const onFutureContractSigningDateChange = (value: any) => {
        setItem({ ...item, futureContractSigningDate: value });
    };
    const onFutureContractInitDateChange = (value: any) => {
        setItem({ ...item, futureContractInitDate: value });
    };
    const onFutureContractEndDateChange = (value: any) => {
        setItem({ ...item, futureContractEndDate: value });
    };

    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }} maxWidth={'lg'} fullWidth={true}>
            <DialogTitle>{!props.item ? t('providerUpsertDialog.createTitle') : t('providerUpsertDialog.updateTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{!props.item ? t('providerUpsertDialog.createContent') : t('providerUpsertDialog.updateContent')}</DialogContentText>
                <Grid
                    container
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='clientName'
                            label={t('marketing.client')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.clientName}
                            onChange={handleChange}
                            onLoadOptionsAsync={(input: string) => clientsGateway.findAllFilter(input)}
                            numberOfChars={1}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='cups'
                            label={t('common.electricReference')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.cups}
                            onLoadOptionsAsync={(input: string) => getCups(input)}
                            numberOfChars={2}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='rate'
                            label={t('common.periods')}
                            type='text'
                            options={both ? props.rates.electricidad.concat(props.rates.fuel) : energyType === 'electricidad' ? props.rates.electricidad : props.rates.fuel}
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.rate}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='complex'
                            label={t('rightNavigation.complexes')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.complex}
                            onLoadOptionsAsync={(input: string) => complexGateway.findAllFiltered(input)}
                            numberOfChars={1}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='establishment'
                            label={t('rightNavigation.establishments')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.establishment}
                            onLoadOptionsAsync={(input: string) => establishmentGateway.findAllFiltered(input)}
                            numberOfChars={1}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='society'
                            label={t('common.society')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.society}
                            onLoadOptionsAsync={(input: string) => getSociety(input, 'name')}
                            numberOfChars={1}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='cif'
                            label={t('common.cif')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.cif}
                            onLoadOptionsAsync={(input: string) => getSociety(input, 'cif')}
                            numberOfChars={1}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='country'
                            label={t('common.country')}
                            onLoadOptionsAsync={(input: string) => countryGateway.findAllFiltered(input)}
                            numberOfChars={1}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.country}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='region'
                            label={t('common.regions')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.region}
                            onChange={handleChange}
                            onLoadOptionsAsync={(input: string) => regionsGateway.findAllFiltered(input)}
                            numberOfChars={1}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='primaryEnergy'
                            options={both ?
                                props.primaryEnergies.electricidad.concat(props.primaryEnergies.fuel) : energyType === 'electricidad' ?
                                    props.primaryEnergies.electricidad : props.primaryEnergies.fuel}
                            label={t('common.primaryEnergy')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.primaryEnergy}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            fullWidth
                            id="currentContractSigningDate"
                            label={t('common.currentContractSignDateSelector')}
                            value={item.currentContractSigningDate}
                            onChange={onCurrentContractSigningDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            fullWidth
                            id="currentContractInitDate"
                            label={t('common.currentSupplyInitDateSelector')}
                            value={item.currentContractInitDate}
                            onChange={onCurrentContractInitDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            fullWidth
                            id="currentContractEndDate"
                            label={t('common.currentSupplyEndDateSelector')}
                            value={item.currentContractEndDate}
                            onChange={onCurrentContractEndDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='currentProvider'
                            label={t('common.currentProvider')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.currentProvider}
                            onLoadOptionsAsync={(input: string) => providersGateway.findAllFiltered(input)}
                            numberOfChars={1}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            fullWidth
                            id="futureContractSigningDate"
                            label={t('common.futureContractSignDateSelector')}
                            value={item.futureContractSigningDate}
                            onChange={onFutureContractSigningDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            fullWidth
                            id="futureContractInitDate"
                            label={t('common.futureSupplyInitDateSelector')}
                            value={item.futureContractInitDate}
                            onChange={onFutureContractInitDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            fullWidth
                            id="futureContractEndDate"
                            label={t('common.futureSupplyEndDateSelector')}
                            value={item.futureContractEndDate}
                            onChange={onFutureContractEndDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='futureProvider'
                            label={t('common.futureProvider')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.futureProvider}
                            onChange={handleChange}
                            onLoadOptionsAsync={(input: string) => providersGateway.findAllFiltered(input)}
                            numberOfChars={1}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='contactAgent'
                            label={t('common.contactAgent')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.contactAgent}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='email'
                            label={t('common.email')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={item.email}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3} className={classes.gridItem}>
                        <FormTextFieldAutoComplete
                            name='phoneNumber'
                            label={t('common.phoneNumber1')}
                            type='text'
                            validator={validateRequired}
                            errorText={t('messages.requiredField')as string}
                            value={item.phoneNumber}
                            onChange={handleChange}
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onClose() }} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleAccept} color="primary">{t('common.accept')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default HiringDashboardUpsertDialog;