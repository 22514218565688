import React from 'react';
import { Switch, Route, useRouteMatch, RouteComponentProps } from 'react-router';
import CompanyList from './company-list';
import CompanyDetail from './company-detail';
import ContactList, { ContactEntityType } from '../contacts/contact-list';
import ComplexRouter from '../complex/complex-router';
import EstablishmentRouter from '../establishment/establishment-router';
import { CompanyWrapper } from './company-wrapper'

interface IContactsRouteParams {
    companyId: string;
}

const ContactRouter = (props: RouteComponentProps<IContactsRouteParams>) =>
    <ContactList
        entity={ContactEntityType.Company}
        entityId={props.match.params.companyId} />;

const CompanyRouter = () => {
    const { path } = useRouteMatch(); 

    return (
        <Switch>
            <Route path={`${path}/`} exact>
                <CompanyList />
            </Route>
            <Route path={`${path}/:companyId`} component={CompanyWrapper} />
        </Switch>
    );
};

export default CompanyRouter;