export interface IHiringDashboard {
    clientName: string;
    cups: string;
    rate: string;
    complex: string;
    establishment: string;
    society: string;
    cif: string;
    country: string;
    region: string;
    primaryEnergy: string;
    currentContractSigningDate: Date;
    currentContractInitDate: Date;
    currentContractEndDate: Date;
    currentProvider: string;
    futureContractInitDate: Date;
    futureContractEndDate: Date;
    futureContractSigningDate: Date;
    futureProvider: string;
    contactAgent: string;
    phoneNumber: string;
    email: string;
    cancelPriorNotice: number;
    code: string;
    emailNotification: string;
    invoicesFolder: string;
}

export const initialIHiringDashboard: IHiringDashboard = {
    clientName: '',
    cups: '',
    rate: '',
    complex: '',
    establishment: '',
    society: '',
    cif: '',
    country: '',
    region: '',
    primaryEnergy: '',
    currentContractSigningDate: new Date(),
    currentContractInitDate: new Date(),
    currentContractEndDate: new Date(),
    currentProvider: '',
    futureContractInitDate: new Date(),
    futureContractEndDate: new Date(),
    futureContractSigningDate: new Date(),
    futureProvider: '',
    contactAgent: '',
    phoneNumber: '',
    email: '',
    cancelPriorNotice: 0,
    code: '',
    emailNotification: '',
    invoicesFolder: '',
}

interface IColumns {
    id: keyof IHiringDashboard;
    label: string;
    minWidth: number;
    align?: string;
}
export const columns: IColumns[] = [
    {
        id: 'clientName',
        label: 'Empresa',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'cups',
        label: 'CUPS',
        minWidth: 100,
        align: 'right',
    },
    {
        id: 'rate',
        label: 'Tarifa',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'complex',
        label: 'Complejo',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'establishment',
        label: 'Establecimiento',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'society',
        label: 'Sociedad',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'cif',
        label: 'CIF',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'country',
        label: 'País',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'region',
        label: 'Región',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'primaryEnergy',
        label: 'Energía primaria',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'currentContractSigningDate',
        label: 'F. firma actual',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'currentContractInitDate',
        label: 'F. inicio actual',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'currentContractEndDate',
        label: 'F. fin actual',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'cancelPriorNotice',
        label: 'Días preaviso',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'currentProvider',
        label: 'Proveedor actual',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'futureContractSigningDate',
        label: 'F. firma futuro',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'futureContractInitDate',
        label: 'F. inicio futuro',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'futureContractEndDate',
        label: 'F. fin futuro',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'futureProvider',
        label: 'Proveedor futuro',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'contactAgent',
        label: 'Agente contacto',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'phoneNumber',
        label: 'Móvil',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'email',
        label: 'Email',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'code',
        label: 'Código',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'emailNotification',
        label: 'Email a notificar',
        minWidth: 170,
        align: 'right',
    },
    {
        id: 'invoicesFolder',
        label: 'Ruta de carpeta',
        minWidth: 170,
        align: 'right',
    },
];

export interface IAutoComplete {
    electricidad: string[];
    fuel: string[]
}

export const initialIAutoComplete: IAutoComplete = {
    electricidad: [],
    fuel: [],
}

export interface ISocietyAutoComplete {
    societyName: string;
    cif: string;
}