import React, { FC, useEffect, useState, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  makeStyles,
  CircularProgress,
  Box,
  Tabs,
  Tab,
} from "@material-ui/core";
import UserIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import { withRouter, RouteComponentProps } from "react-router";
import { ClientsGateway } from "../../gateways/clients.gateway";
import useIoC from "../../contexts/ioc.context";
import { IClient } from "../../gateways/clients.interfaces";
import AlertBox from "../../components/alert-box";
import ClientIcon from "@material-ui/icons/People";
import ClientAddDialog from "./client-add-dialog";
import { useTranslation } from "react-i18next";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import { useRouteMatch } from "react-router-dom";
import { IClientTypes } from "../../gateways/clients-types.interfaces";
import { ClientTypesGateway } from "../../gateways/clients-types.gateway";
import MapsDialog from "../maps/maps-dialog";
import CoordinatesGateway from "../../gateways/coordinates.gateway";
import { ICoordinatesEstCmplx } from "../../gateways/coordinates.interfaces";
import ClientSearcherDialog from "./client-searcher-dialog";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles((theme) => ({
  listItem: {
    cursor: "pointer",
  },
  itemAvatarIcon: {
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
  progress: {
    margin: theme.spacing(2),
  },
  loaderContainer: {
    textAlign: "center",
  },
  deletedSupply: {
    color: "#E0E0E0"
  },
  nonDeletedSupply: {
      color: "black"
  },
  filters: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  filtersBox: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    border: "solid 1px black",
    borderRadius: "8px",
  }
}));

const ClientList: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const clientsGateway = useIoC(ClientsGateway);
  const clientTypesGateway = useIoC(ClientTypesGateway);
  const coordinatesGateway = useIoC(CoordinatesGateway);
  
  const [data, setData] = useState<IClient[]>([]);
  const [loading, setLoading] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [openSearcher, setOpenSearcher] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);
  // useState<IClientTypes>({
  //   id: 0,
  //   clientTypeName: "Todos los clientes",
  // });
  const [coordinates, setCoordinates] = useState<ICoordinatesEstCmplx[]>([]);
  const [coordinatesToDisplay, setCoordinatesToDisplay] = useState<
    ICoordinatesEstCmplx[]
  >([]);
  const [clientTypes, setClientTypes] = useState<IClientTypes[]>([]);
  const [showDiscardedClients, setShowDiscardedClients] = useState<boolean>(false);
  const [filedButtonTitle, setFiledButtonTitle] = useState<string>(`${t("clientList.buttonNonDiscardedTitle")}`);

  const [filterValue, setFilterValue] = React.useState('all');

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(e.target.value);
  };

  
  const isEmpty = useMemo(() => !loading && data.length === 0, [loading, data]);

  const handleClientCreated = (item: IClient) => {
    setData([item, ...data]);
  };

  const handleNavigateClient = (item: IClient) => {
    history.push("/clients/" + item.id);
  };

  const handleAddClient = () => {
    setAddDialogOpen(true);
  };

  const handleCloseAddDialog = () => {
    setAddDialogOpen(false);
  };

  const renderItem = (item: IClient, index: number) => {
    const handleNavigateClientWraped = () => handleNavigateClient(item);

    let itemColor = classes.nonDeletedSupply;
    if(item.cancelledDate !== null) itemColor = classes.deletedSupply;

    return (
      <>
        <ListItem
          alignItems="flex-start"
          onClick={handleNavigateClientWraped}
          className={classes.listItem}
        >
          <ListItemAvatar>
            <UserIcon
              color="action"
              fontSize="large"
              className={classes.itemAvatarIcon}
            />
          </ListItemAvatar>
          <ListItemText
            className={itemColor}
            primary={item.fullname}
            secondary={<React.Fragment>{item.address}</React.Fragment>}
          />
        </ListItem>
        {index < data.length - 1 ? (
          <Divider variant="inset" component="li" />
        ) : null}
      </>
    );
  };

  const getCliensWithCurrentFilterAndTypes = async() =>{
    
      let clients: IClient[] = [];

      try {

        clients = await clientsGateway.findAll({ isMarketing: false, showDiscarded: showDiscardedClients });
  
        //filters
        if (clients.length > 0)
        {
          //by client type filter
          if (tabValue !== null && typeof tabValue === 'number' && tabValue > 0)
            clients = clients.filter((client) => client.clientTypeId === tabValue);
    
          //by fields
          if (filterValue !== null && filterValue.length > 0 && filterValue !== 'all')
          {
            switch (filterValue) {
              case 'without-client-email':
                clients = clients.filter((client) => typeof client.client_email === 'undefined' || client.client_email === null || client.client_email.length === 0);
                break;
              case 'without-op-email':
                clients = clients.filter((client) => typeof client.email === 'undefined' || client.email === null || client.email.length === 0);
                break;
            }
          }
        }
      } catch (e) {
        console.error('getCliensWithCurrentFilterAndTypes -> error:', e);
      } finally {
        return clients;
      }
  }

  //client types
  useEffect(() => {
    (async ()=>{
      const clientsTypes = await clientTypesGateway.findAll();
      clientsTypes.unshift({ id: 0, clientTypeName: "Todos los clientes" });
      setClientTypes(clientsTypes);
    })();

  }, [clientTypesGateway]);

  //client loading effect
  useEffect(()=>{
    (async ()=>{
      try {
        setLoading(true);
        const clients = await getCliensWithCurrentFilterAndTypes();
        setData(clients);
      } catch (e) {
        console.error('client-list -> useEffect -> [filterValue, tabValue] error:', e);
        setData([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [filterValue, tabValue]);

  //coordinates loading effect
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const coordinates: ICoordinatesEstCmplx[] =
          await coordinatesGateway.findEstAndCmplx();
        setCoordinates(
          coordinates.filter((coordinate) => !!coordinate.coordinates)
        );
        // setCoordinatesToDisplay(
        //   coordinates.filter((coordinate) => !!coordinate.coordinates)
        // );
        setCoordinatesToDisplay(
          coordinates.filter((coordinate) => !!coordinate.coordinates && coordinate.clientTypeId === tabValue)
        );
      } catch (e) {
        console.error('client-list -> useEffect -> [coordinatesGateway] error:', e);
      } finally {
        setLoading(false);        
      }
      
    })();
  }, [coordinatesGateway, filterValue, tabValue]);

  const handleTabChanges = (event: any, newValue: any) => {
    (async () => {
      try {
        setLoading(true);
        setTabValue(newValue);
        // const clients = await clientsGateway.findAll({ isMarketing: false });
        // setCoordinatesToDisplay(
        //   coordinates.filter((coordinate) => coordinate.clientTypeId === newValue)
        // );
        // if (newValue === 0) {
        //   setData(clients);
        // } else {
        //   setData(clients.filter((client) => client.clientTypeId === newValue));
        // }
      } catch (e) {
        console.error('client-list -> handleTabChanges error:', e);
      } finally {
        setLoading(false);
      }
    })();
  };

  const handleLocations = async (cupsArr: string[], checkValue: boolean) => {
    try {
      if(checkValue) {
        setLoading(true);
        const finalCoordinates = await coordinatesGateway.findEstAndCmplx(cupsArr);
        setCoordinatesToDisplay(finalCoordinates);
        setLoading(false);
      } else {
        setCoordinatesToDisplay(coordinates);
      }
    } catch (e) {
      console.error('client-list -> handleLocations error:', e);
    } finally {
      setLoading(false);
    }
  }

  const handleClickMapButton = async () => {
    setOpenMap(true);
  };

  const handleCloseMap = () => {
    setOpenMap(false);
  };

  const handleClientList = async () => {
    try {
      setLoading(true);
      const clients = await getCliensWithCurrentFilterAndTypes();
      setData(clients);

      if(showDiscardedClients === true) setFiledButtonTitle(`${t("clientList.buttonNonDiscardedTitle")}`)
      else setFiledButtonTitle(`${t("clientList.buttonDiscardedTitle")}`);

      setShowDiscardedClients(!showDiscardedClients);
    } catch (e) {
      console.error('client-list -> handleClientList error:', e);
    } finally {
      setLoading(false);
    }
  }

  const handleOpenSearcher = () => {
    setOpenSearcher(true);
  }

  const handleCloseSearcher = () => {
    setOpenSearcher(false);
  }

  return (
    <Box>
      <Box>
        <Tabs
          value={tabValue}
          onChange={handleTabChanges}
          variant="fullWidth"
          indicatorColor="primary"
          aria-label="basic tabs example"
        >
          {clientTypes.map((clientType) => {
            return <Tab label={clientType.clientTypeName} />;
          })}
        </Tabs>
      </Box>
      <ScreenGlobalStructure
        buttonIcon={<AddIcon />}
        headerIcon={ClientIcon}
        onButtonClick={handleAddClient}
        onViewMapButtonClick={handleClickMapButton}
        onSearchButtonClick={handleOpenSearcher}
        title={t("clientList.title")}
        matchUrl={url}
        filedButtonList={handleClientList}
        filedButtonListTitle={filedButtonTitle}
        loading={loading}
      >
      
      <Box className={classes.filtersBox}>
        <FormLabel className={classes.filters} component="legend">Filtrar por campos:</FormLabel>
        {!loading ? <RadioGroup className={classes.filters} aria-label="filters" name="filters1" value={filterValue} onChange={handleFilterChange}>
                    <FormControlLabel value="all" control={<Radio />} label="Todos" />
                    <FormControlLabel value="without-client-email" control={<Radio />} label="Sin Email de Cliente configurado" />
                    <FormControlLabel value="without-op-email" control={<Radio />} label="Sin Email de OP configurado" />
                  </RadioGroup>: <CircularProgress className={classes.progress} />}
      </Box>

      {
        isEmpty || data.length === 0 ? 
        (loading ? <CircularProgress className={classes.progress} /> : <AlertBox variant="info">{t("common.noItems")}</AlertBox>) : 
        <Box>
          <h3>Cantidad: {data.length}</h3>
          <List>{data.map(renderItem)}</List>
        </Box>
      }

      </ScreenGlobalStructure>

      <ClientAddDialog
        open={addDialogOpen}
        onClose={handleCloseAddDialog}
        onClientCreated={handleClientCreated}
      />
      <MapsDialog
        clientType={"test"}
        open={openMap}
        onClose={handleCloseMap}
        locations={coordinatesToDisplay}
        handleLocations={handleLocations}
      />
      <ClientSearcherDialog
        open={openSearcher}
        onClose={handleCloseSearcher}
      />
    </Box>
  );
};

export default withRouter(ClientList);
