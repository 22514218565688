import React, { FC, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import {
  IElectricSupply,
  initialIElectricSupplyUpsert,
} from "../../gateways/electric-supply.interface";
import { ElectricSupplyGateway } from "../../gateways/electric-supply.gateway";
import ElectricSupplyForm from "./electric-supply-form";

interface IComplexUpsertDialogProps {
  open: boolean;
  item: IElectricSupply | undefined;
  errorMissingData: boolean;
  complexId?: string;
  establishmentId?: string;
  onItemUpsert: (item: IElectricSupply, isNew: boolean) => void;
  onClose: () => void;
}

const ElectricSupplyUpsertDialog: FC<IComplexUpsertDialogProps> = (props) => {
  const { t } = useTranslation();

  const [item, setItem] = useState<IElectricSupply>(
    initialIElectricSupplyUpsert
  );

  const snackbar = useSnackbar();
  const [errorMissingData, setErrorMissingData] = useState(false);
  const electricSupplyGateway = useIoC(
    ElectricSupplyGateway
  );

  const createElectricSupply = async (item: IElectricSupply) => {
    const electricSupply = await electricSupplyGateway.create(item);
    snackbar.enqueueSnackbar(
      t("electricSupplyUpsertDialog.createSucceded", {
        id: electricSupply.id,
        reference: electricSupply.referencia,
      }),
      { variant: "success" }
    );
    props.onItemUpsert(electricSupply, true);
  };

  const updateElectricSupply = async (item: IElectricSupply) => {
    const electricSupply = await electricSupplyGateway.update(item.id, item);
    snackbar.enqueueSnackbar(
      t("electricSupplyUpsertDialog.updateSucceded", {
        id: electricSupply.id,
        reference: electricSupply.referencia,
      }),
      { variant: "success" }
    );
    props.onItemUpsert(electricSupply, false);
  };

  const upsertElectricSupply = async (item: IElectricSupply) => {
    try {
      if (!props.item) {
        await createElectricSupply(item);
      } else {
        await updateElectricSupply(item);
      }
    } catch (e) {
      const er = e as any;
      const key = "electricSupplyErrorHandler." + er.response.data.key;
      if (er.response.data.key === "creatingEntityError") {
        const message = "validation" in er ? er.validation : t(key);
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else if (er.response.data.key === "updatingError") {
        const message =
          "validation" in er ? er.validation : t(key, { id: er.response.data.id });
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else {
        const message =
          "validation" in er ? er.validation : t("messages.defaultError");
        snackbar.enqueueSnackbar(message, { variant: "error" });
      }
    } finally {
      props.onClose();
    }
  };

  const handleAccept = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!item.referencia) {
      event.preventDefault();
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      setErrorMissingData(true);
      return;
    }

    const alreadyExists = await electricSupplyGateway.findAllFiltered(item.referencia);

    if(!!alreadyExists[0]){
      snackbar.enqueueSnackbar(t("messages.cupsAlreadyAssigned"), {
        variant: "error",
      });
      return;
    } 

    if (!item.complexId) {
      setItem({ ...item, complexId: null });
    } else if (!item.establishmentId) {
      setItem({ ...item, establishmentId: null });
    } else {
      const message = t("messages.defaultError");
      snackbar.enqueueSnackbar(message, { variant: "error" });
    }
    setErrorMissingData(false);
    upsertElectricSupply(item);
  };

  const handleChange = (name: string, value: any) => {
    setItem({ ...item, [name]: value });
  };

  const handleTelemedidaChange = () => {
    setItem({ ...item, telemedida: !item.telemedida });
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  useEffect(() => {
    if (props.item) {
      setItem(props.item);
    } else {
      if (props.establishmentId) {
        setItem({
          ...initialIElectricSupplyUpsert,
          establishmentId: Number.parseInt(props.establishmentId),
        });
      } else if (props.complexId) {
        setItem({
          ...initialIElectricSupplyUpsert,
          complexId: Number.parseInt(props.complexId),
        });
      }
    }
  }, [props.item, props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
    >
      <DialogTitle>
        {!props.item
          ? t("electricSupplyUpsertDialog.createTitle")
          : t("electricSupplyUpsertDialog.updateTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!props.item
            ? t("electricSupplyUpsertDialog.createContent")
            : t("electricSupplyUpsertDialog.updateContent")}
        </DialogContentText>
        <ElectricSupplyForm
          supply={item}
          onChangeSupply={handleChange}
          onTelemedidaChange={handleTelemedidaChange}
          validateRequired={validateRequired}
          errorMissingData={errorMissingData}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}
          color="primary"
        >
          {t("common.cancel")}
        </Button>
        <Button onClick={handleAccept} color="primary">
          {t("common.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ElectricSupplyUpsertDialog;
