import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { List, Button } from "@material-ui/core";
import { IRule } from "../../gateways/rules.interfaces";
import RuleItem from "./rule-item";

interface RulesTableProps {
    items: IRule[];
    onChange: (items: IRule[]) => void;
}

const RuleTable: FC<RulesTableProps> = ({ items, onChange }) => {
    const { t } = useTranslation();

    const [rules, setRules] = useState<IRule[]>([]);

    useEffect(() => {
        setRules(items);
    }, [items]);

    const handleChange = (index: number) => {

        return (name: string, value: any) => {
            const itemChaged = { ...rules[index], [name]: value };

            rules[index] = itemChaged;
            onChange([...rules]);
        };

    }

    const handleAddRule = () => {
        const rule: IRule = {
            id: 0,
            name: '',
            regex: ''        
        }
        onChange([...rules, rule]);
    }


    return (
        <List>
            {
                rules.map((value, index) => {
                    return (
                        <RuleItem
                            item={value}
                            onChange={handleChange(index)}
                        />
                    )
                })
            }
            <Button onClick={handleAddRule}>
                {t('common.add')}
            </Button>
        </List>
    );
};
export default RuleTable