import { ISupplyBot } from "./supply-bots.interface";

export interface IBotFieldRO {
  id: number;
  field: string;
  type: string;
}

export interface IBotCategoryRO {
  id: number;
  category: string;
  bot: IBotRO[];
}

export interface IBotConfigsValuesRO {
  id: number;
  field: string;
  value: string;
  config: IBotJobConfigRO;
}

export interface IBotJobConfigRO {
  id: number;
  name: string;
  description: string;
  job: IBotJobRO;
  values: IBotConfigsValuesRO[];
}

export interface IBotJobRO {
  id: number;
  name: string;
  bot: IBotRO;
  configs: IBotJobConfigRO[];
}

export interface IBotRO {
  id: number;
  name: string;
  fields: IBotFieldRO[];
  preconfigs: IBotPreconfigRO[];
  enabled: boolean;
  category: IBotCategoryRO;
  prometheus_host: string;
  job: IBotJobRO[];
}

export interface IBotPreconfigRO {
  id: number;
  alias: string;
  bot: IBotRO;
  fields: IBotPreconfigFieldRO[];
  enabled: boolean;
  meterRentalPreconfig: boolean;
}

export interface IBotPreconfigFieldRO {
  field: string;
  value: string;
  type: string;
}

export interface IPreconfigCustomFields {
  field: string;
  value: string | Date;
}

export interface SaveBotPreconfigFieldDto {
  field: string;
  value: string;
}

export interface SaveBotPreconfigDto {
  alias: string;
  botId: number;
  fields: SaveBotPreconfigFieldDto[];
  enabled: boolean;
  meterRentalPreconfig: boolean;
}

export interface ITrackingQuery {
  supplyBotId?: number;
  preconfigId?: number;
  fromDate?: Date;
  toDate?: Date;
  levels?: string[];
}

export interface IBotTrackRO {
  id: number;
  message: string;
  date: Date;
  level: string;
  supplyBot: ISupplyBot;
  preconfig: IBotPreconfigRO;
}
export const initialBotCategory: IBotCategoryRO = {
  id: 0,
  category: "",
  bot: [],
};
export const initialBot: IBotRO = {
  id: 0,
  name: "",
  fields: [],
  preconfigs: [],
  enabled: false,
  category: initialBotCategory,
  prometheus_host: "",
  job: [],
};
export const initialBotPreconfig: IBotPreconfigRO = {
  id: 0,
  alias: "",
  bot: initialBot,
  fields: [],
  enabled: false,
  meterRentalPreconfig: false,
};
