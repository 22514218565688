import React, { FC, Fragment } from "react";
import { withRouter, RouteComponentProps, Route, Switch } from "react-router";
import PreconfigList from "./preconfig-list";
import PreconfigCreate from "./preconfig-create";
import PreconfigDetail from "./preconfig-detail";
import { useUser } from "../../contexts/user.context";

const SupplyBotsIndex: FC<RouteComponentProps> = ({ match }) => {
    const [{ canViewBotsMenu }] = useUser();
    return (
        <Switch>
            {canViewBotsMenu ? <Route path={`${match.path}` } exact component={PreconfigList} /> : null}
            {canViewBotsMenu ? <Route path={`${match.path}/create` } exact component={PreconfigCreate} /> : null}
            {canViewBotsMenu ? <Route path={`${match.path}/:id` } exact component={PreconfigDetail} /> : null}
        </Switch>
    );
}

export default withRouter(SupplyBotsIndex);