import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  initialIWaterSupplyInterFace,
  IWaterSupplyInterface,
  IWaterSupplyInterfaceUpsert,
} from "../../gateways/water-supply.interfaces";
import WaterSupplyForm from "./water-supply-form";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import { WaterSupplyGateway } from "../../gateways/water-supply.gateway";

interface IWaterSupplyCreateProps {
  open: boolean;
  complexId: number;
  establishmentId: number;
  handleCreate: (item: IWaterSupplyInterface) => void;
  onClose: () => void;
}

const WaterSupplyCreate: FC<IWaterSupplyCreateProps> = (props) => {
  const [errorMissingData, setErrorMissingData] = useState(false);
  const [item, setItem] = useState<IWaterSupplyInterface>(
    props.complexId && props.establishmentId
      ? {
          ...initialIWaterSupplyInterFace,
          complexId: props.complexId,
          establishmentId: props.establishmentId,
        }
      : props.complexId
      ? { ...initialIWaterSupplyInterFace, 
        complexId: props.complexId }
      : props.establishmentId
      ? {
          ...initialIWaterSupplyInterFace,
          establishmentId: props.establishmentId,
        }
      : initialIWaterSupplyInterFace
  );
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const waterSupplyGateway = useIoC(WaterSupplyGateway);

  const createWaterSupply = async (item: IWaterSupplyInterfaceUpsert) => {
    const waterSupply = await waterSupplyGateway.create(item);
    snackbar.enqueueSnackbar(
      t("waterSupplyCreateDialog.createSucceded", { id: waterSupply.id }),
      { variant: "success" }
    );
    props.handleCreate(waterSupply);
  };

  const handleAccept = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!item.referencia) {
      event.preventDefault();
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      setErrorMissingData(true);
      return;
    }

    const alreadyExists = await waterSupplyGateway.findAll({referencia: item.referencia});

    if(!!alreadyExists[0]){
      snackbar.enqueueSnackbar(t("messages.cupsAlreadyAssigned"), {
        variant: "error",
      });
      return;
    } 

    setErrorMissingData(false);
    createWaterSupply(item);
  };

  const handleChange = (name: string, value: any) => {
    setItem({ ...item, [name]: value });
  };

  const handleChangeBooleanSendEmail = () => {
    setItem({ ...item, sendEmail: !item.sendEmail });
  };

  const handleChangeSendClientEmail = () => {
    setItem({ ...item, sendClientEmail: !item.sendClientEmail });
  };


  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  useEffect(() => {
    (async () => {
      setItem(
        props.complexId && props.establishmentId
          ? {
              ...initialIWaterSupplyInterFace,
              complexId: props.complexId,
              establishmentId: props.establishmentId,
            }
          : props.complexId
          ? { ...initialIWaterSupplyInterFace, complexId: props.complexId }
          : props.establishmentId
          ? {
              ...initialIWaterSupplyInterFace,
              establishmentId: props.establishmentId,
            }
          : initialIWaterSupplyInterFace
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
    >
      <DialogTitle>{t("waterSupplyCreateDialog.createTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("waterSupplyCreateDialog.createContent")}
        </DialogContentText>
        <WaterSupplyForm
          supply={item}
          onChangeSupply={handleChange}
          onSendEmailChange={handleChangeBooleanSendEmail}
          onSendClientEmailChange={handleChangeSendClientEmail}
          validateRequired={validateRequired}
          errorMissingData={errorMissingData}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}
          color="primary"
        >
          {t("common.cancel")}
        </Button>
        <Button onClick={handleAccept} color="primary">
          {t("common.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WaterSupplyCreate;
