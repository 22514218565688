import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import SelectorField from '../../components/selector-field';
import { makeStyles, Dialog, DialogTitle, DialogContent, DialogContentText, Grid, DialogActions, Button, Tabs, Tab } from "@material-ui/core";
import { ICreateRepositoryDefinition } from "../../gateways/repository-definition.interfaces";
import MaterialTable from 'material-table';
import { IRepositoryDefinitionType } from "../../gateways/repository-definition-type.interfaces";
import RepositoryDefinitionCriteria from "./repository-definition-criteria";

const useStyles = makeStyles(theme => ({    

    gridItem: {
        height: '100%',
        borderSpacing: 2,
        padding: theme.spacing(1)
    }
}));

interface IRepositoryDefinitionProps {
    open: boolean;
    onClose: () => void;
    item: ICreateRepositoryDefinition;
    onChange: (name: string, value: any) => void;
    validateRequired: (value: any) => boolean;
    value: number;
    onChangeValue: (event: React.ChangeEvent<{}>, newValue: number) => void;
    a11yProps(index: any): {
        id: string;
        'aria-controls': string;
    };
    valueTypesToLookUp: {};
    repositoryDefinitionData: ICreateRepositoryDefinition;
    setRepositoryDefinitionData: (value: React.SetStateAction<ICreateRepositoryDefinition>) => void;
    groupTypesToLookup: {};
    repositoryDefinitionType: IRepositoryDefinitionType[];
    onSortAscDefaultChange: () => void;
    onChangeCriteriaPropertyEntityId: (name: string, value: any) => void;
    onChangeCriteriaQueryBase: (name: string, value: any) => void;
    onCodeChange: (newCode: string) => void;
    onCancel: () => void;
    onUpsert: () => Promise<void>;
    update: boolean;
}

const RepositoryDefinitionForm: FC<IRepositoryDefinitionProps> = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }} maxWidth={'md'} fullWidth={true}>
            <DialogTitle>{t('repositoryDefinitionUpdateDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('repositoryDefinitionUpdateDialog.content')}</DialogContentText>
                <Grid
                    container
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='name'
                            label={t('common.name') as string}
                            type='text'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.name}
                            onChange={props.onChange}
                            autoFocus
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='slug'
                            label={t('common.slug') as string}
                            type='text'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.slug}
                            onChange={props.onChange}
                            fullWidth
                            disabled={true}
                            required />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                        <FormTextField
                            name='description'
                            label={t('common.description') as string}
                            type='text'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.description}
                            onChange={props.onChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                        <Tabs value={props.value}
                            onChange={props.onChangeValue}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            aria-label="Material table tabs">
                            <Tab label={t("common.properties")} {...props.a11yProps(0)} />
                            <Tab label={t("common.groupTypes")} {...props.a11yProps(1)} />
                            <Tab label={t("common.parameters")} {...props.a11yProps(2)} />
                        </Tabs>
                    </Grid>

                    {
                        props.value === 0 && <Grid item xs={12} className={classes.gridItem}>
                            <MaterialTable
                                options={{
                                    search: false,
                                    paging: false,
                                    addRowPosition: 'first'
                                }}
                                localization={{
                                    header: {
                                        actions: t('common.actions') as string
                                    }
                                }}
                                title={t("common.properties") as string}
                                columns={
                                    [
                                        {
                                            title: t("common.name") as string,
                                            field: "name"
                                        },
                                        {
                                            title: t("repositoryDefinitionUpdateDialog.valueType") as string,
                                            field: "valueTypeId",
                                            lookup: props.valueTypesToLookUp
                                        }]}
                                data={props.item.properties}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise<void>(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                const data = [...props.repositoryDefinitionData.properties];
                                                data.push(newData);
                                                props.setRepositoryDefinitionData({ ...props.repositoryDefinitionData, properties: data });
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise<void>(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    let updateDataAid = [...props.repositoryDefinitionData.properties];
                                                    updateDataAid[updateDataAid.indexOf(oldData)] = newData;
                                                    props.setRepositoryDefinitionData({ ...props.repositoryDefinitionData, properties: updateDataAid });
                                                }
                                            }, 600);

                                        }),
                                    onRowDelete: oldData =>
                                        new Promise<void>(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                const data = [...props.repositoryDefinitionData.properties];
                                                data.splice(data.indexOf(oldData), 1);
                                                props.setRepositoryDefinitionData({ ...props.repositoryDefinitionData, properties: data });
                                            }, 600);
                                        }),
                                }}
                            />
                        </Grid>
                    }
                    {
                        props.value === 1 && <Grid item xs={12} className={classes.gridItem}>
                            <MaterialTable
                                options={{
                                    search: false,
                                    paging: false,
                                    addRowPosition: 'first'
                                }}
                                localization={{
                                    header: {
                                        actions: t('common.actions') as string
                                    }
                                }}
                                title={t("common.groupTypes") as string}
                                columns={[
                                    {
                                        title: t("common.sourceProperty") as string,
                                        field: "sourceProperty",
                                    },
                                    {
                                        title: t("repositoryDefinitionUpdateDialog.groupTypeId") as string,
                                        field: "groupTypeId",
                                        lookup: props.groupTypesToLookup
                                    }]}
                                data={props.item.groupTypes}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise<void>(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                const data = [...props.repositoryDefinitionData.groupTypes];
                                                data.push(newData);
                                                props.setRepositoryDefinitionData({ ...props.repositoryDefinitionData, groupTypes: data });
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise<void>(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    let updateDataAid = [...props.repositoryDefinitionData.groupTypes];
                                                    updateDataAid[updateDataAid.indexOf(oldData)] = newData;
                                                    props.setRepositoryDefinitionData({ ...props.repositoryDefinitionData, groupTypes: updateDataAid });
                                                }
                                            }, 600);

                                        }),
                                    onRowDelete: oldData =>
                                        new Promise<void>(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                const data = [...props.repositoryDefinitionData.groupTypes];
                                                data.splice(data.indexOf(oldData), 1);
                                                props.setRepositoryDefinitionData({ ...props.repositoryDefinitionData, groupTypes: data });
                                            }, 600);
                                        }),
                                }}
                            />
                        </Grid>
                    }
                    {
                        props.value === 2 && <Grid item xs={12} className={classes.gridItem}>
                            <MaterialTable
                                options={{
                                    search: false,
                                    paging: false,
                                    addRowPosition: 'first'
                                }}
                                localization={{
                                    header: {
                                        actions: t('common.actions') as string
                                    }
                                }}
                                title={t("repositoryDefinitionUpdateDialog.parameters") as string}
                                columns={[
                                    {
                                        title: t("repositoryDefinitionUpdateDialog.name") as string,
                                        field: "name",
                                    }
                                ]}
                                data={props.item.parameters}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise<void>(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                const data = [...props.repositoryDefinitionData.parameters];
                                                data.push(newData);
                                                props.setRepositoryDefinitionData({ ...props.repositoryDefinitionData, parameters: data });
                                            }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise<void>(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                if (oldData) {
                                                    let updateDataAid = [...props.repositoryDefinitionData.parameters];
                                                    updateDataAid[updateDataAid.indexOf(oldData)] = newData;
                                                    props.setRepositoryDefinitionData({ ...props.repositoryDefinitionData, parameters: updateDataAid });
                                                }
                                            }, 600);

                                        }),
                                    onRowDelete: oldData =>
                                        new Promise<void>(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                const data = [...props.repositoryDefinitionData.parameters];
                                                data.splice(data.indexOf(oldData), 1);
                                                props.setRepositoryDefinitionData({ ...props.repositoryDefinitionData, parameters: data });
                                            }, 600);
                                        }),
                                }
                            }
                            />
                        </Grid>
                    }
                    <Grid item xs={12} className={classes.gridItem}>
                        <SelectorField
                            name='repositoryDefinitionTypeEntityId'
                            emptyValue={false}
                            fullWidth
                            inputLabel={t('common.repositoryDefinitionTypeEntityId')}
                            helperText={t('messages.requiredField') as string}
                            required
                            onChange={props.onChange}
                            value={props.item.repositoryDefinitionTypeEntityId}
                            validator={props.validateRequired}
                            values={props.repositoryDefinitionType.map(value => {
                                return { id: value.id, value: value.name };
                            })} >
                        </SelectorField>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                        <RepositoryDefinitionCriteria
                            handleChange={props.onChange}
                            handleSortAscDefaultChange={props.onSortAscDefaultChange}
                            item={props.repositoryDefinitionData}
                            validateRequired={props.validateRequired}
                            handleChangeCriteriaPropertyEntityId={props.onChangeCriteriaPropertyEntityId}
                            handleChangeCriteriaQueryBase={props.onChangeCriteriaQueryBase}
                            handleCodeChange={props.onCodeChange} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel} color="primary">{t('common.cancel')}</Button>
                <Button onClick={props.onUpsert} color="primary">{props.update ? t('common.update') : t('common.create')}</Button>
            </DialogActions>

        </Dialog>
    );
};

export default RepositoryDefinitionForm;