import { Reducer } from "react";
import { IFSTableColumn } from "./interfaces";
import { IRepositoryQueryFilterDto, IRepositoryDefinitionGroup } from "../../gateways/repository.interfaces";

export interface IState {
    repositoryId: string;
    title: string;
    groupsEnabled: boolean;
    openFiltersDialog: boolean;
    openCreateGroupDialog: boolean;
    openSelectGroupDialog: boolean;
    forceGroup: IRepositoryDefinitionGroup | null;
    selectedGroup: IRepositoryDefinitionGroup | null;
    groups: IRepositoryDefinitionGroup[];
    count: number;
    rowsPerPage: number;
    currentPage: number;
    columns: IFSTableColumn[];
    orderBy: string | null;
    orderDirection: 'asc' | 'desc';
    filters: IRepositoryQueryFilterDto[];
    data: any[];
    countingData: boolean;
    fetchingData: boolean;
    onRowClick?: (item: any) => void;
    onGroupCreated?: (group: IRepositoryDefinitionGroup) => void;
}

export const initialState: IState = {
    repositoryId: '',
    title: '',
    groupsEnabled: false,
    openFiltersDialog: false,
    openCreateGroupDialog: false,
    openSelectGroupDialog: false,
    forceGroup: null,
    selectedGroup: null,
    count: 0,
    rowsPerPage: 10,
    currentPage: 0,
    columns: [],
    orderBy: null,
    orderDirection: 'asc',
    data: [],
    countingData: false,
    fetchingData: false,
    filters: [],
    groups: [],
}

export type Action =
    { type: 'showFiltersDialog' | 'hideFiltersDialog' }
    | { type: 'showCreateGroupDialog' | 'hideCreateGroupDialog' }
    | { type: 'showSelectGroupDialog' | 'hideSelectGroupDialog' }
    | { type: 'setCurrentPage', payload: number }
    | { type: 'setRowsPerPage', payload: number }
    | { type: 'setOrderBy', payload: string }
    | { type: 'setOrderDirection', payload: 'asc' | 'desc' }
    | { type: 'setCount', payload: number }
    | { type: 'counting' }
    | { type: 'fetchedData', payload: any[] }
    | { type: 'fetchingData' }
    | { type: 'apply-filters', payload: IRepositoryQueryFilterDto[] }
    | { type: 'applyGroup', payload: IRepositoryDefinitionGroup | null }
    | { type: 'forceGroup', payload: IRepositoryDefinitionGroup | null }
    | { type: 'updateAny', payload: Partial<IState> };

export const tableReducer: Reducer<IState, Action> = (state, action) => {
    switch (action.type) {
        case 'showFiltersDialog':
            return { ...state, openFiltersDialog: true };
        case 'hideFiltersDialog':
            return { ...state, openFiltersDialog: false };
        case 'showCreateGroupDialog':
            return { ...state, openCreateGroupDialog: true };
        case 'hideCreateGroupDialog':
            return { ...state, openCreateGroupDialog: false };
        case 'showSelectGroupDialog':
            return { ...state, openSelectGroupDialog: true };
        case 'hideSelectGroupDialog':
            return { ...state, openSelectGroupDialog: false };
        case 'setCurrentPage':
            return { ...state, currentPage: action.payload };
        case 'setRowsPerPage':
            return { ...state, rowsPerPage: action.payload };
        case 'setOrderBy':
            return { ...state, orderBy: action.payload, orderDirection: 'asc' };
        case 'setOrderDirection':
            return { ...state, orderDirection: action.payload };
        case 'setCount':
            return { ...state, count: action.payload, countingData: false };
        case 'fetchedData':
            return { ...state, data: action.payload, fetchingData: false };
        case 'fetchingData':
            return { ...state, fetchingData: true };
        case 'counting':
            return { ...state, countingData: true };
        case 'apply-filters':
            return { ...state, filters: action.payload, openFiltersDialog: false };
        case 'applyGroup':
            return { ...state, selectedGroup: action.payload };
        case 'forceGroup':
            return { ...state, forceGroupId: action.payload };
        case 'updateAny':
            return { ...state, ...action.payload };
    }
    return state;
}
