import React, { FC, useEffect, useState, useMemo } from "react";
import { Typography, List, ListItem, ListItemAvatar, ListItemText, Divider, makeStyles, CircularProgress, Box, ListItemSecondaryAction, IconButton} from "@material-ui/core";
import SecurityIcon from '@material-ui/icons/Security';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { withRouter, RouteComponentProps } from "react-router";
import useIoC from "../../contexts/ioc.context";
import AlertBox from "../../components/alert-box";
import { useNavigator } from '../../contexts/navigator.context';
import SupplyBotsIcon from '@material-ui/icons/Autorenew';
import { BotsGateway } from "../../gateways/bots.gateway";
import { IBotRO } from "../../gateways/bots.interfaces";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    inline: {
        display: 'inline',
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    progress: {
        margin: theme.spacing(2),
    },
    loaderContainer: {
        textAlign: 'center'
    }
}));

const ProvidersBotList: FC<RouteComponentProps> = ({ history }) => {
    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const botsGateway = useIoC(BotsGateway);
    const [data, setData] = useState<IBotRO[]>([]);
    const [loading, setLoading] = useState(false);
    const isEmpty = useMemo(() => !loading && data.length === 0, [loading, data]);
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const handleClickBot = async (botToProcess: IBotRO) => { 
        if(botToProcess.preconfigs.length > 0){
            try {
                await botsGateway.launchBotPlatform(botToProcess.id);               
                snackbar.enqueueSnackbar(t("messages.botPlatformsLaunched"), {
                    variant: 'success',
                });
            } catch (e) {
                const er = e as any;
                const message = er.validation || er.message;
                snackbar.enqueueSnackbar(message, {
                    variant: 'error',
                });
            }
        }else{
            try {             
                snackbar.enqueueSnackbar(t("messages.noBotPlatforms"), {
                    variant: 'warning',
                });
            } catch (e) {
                const er = e as any;
                const message = er.validation || er.message;
                snackbar.enqueueSnackbar(message, {
                    variant: 'error',
                });
            }
        }
    }

    const renderItem = (item: IBotRO, index: number) => {
        return (
            <>
                <ListItem alignItems="flex-start" className={classes.listItem}>
                    <ListItemAvatar >
                        <SecurityIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${item.name}`}
                    />
                    <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="bot" color={item.enabled ? "primary":"default"} onClick={()=>{handleClickBot(item)}}>   
                                <PlayCircleFilledIcon style={{fontSize: 35}}/>
                            </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                {
                    index < data.length - 1
                        ? <Divider variant="inset" component="li" />
                        : null
                }
            </>
        );
    }

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: 'Proveedores (bots)',
                icon: SupplyBotsIcon
            }
        });
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const items = await botsGateway.findAll();
            setData(items);
            setLoading(false);
        })();
    }, [botsGateway]);

    return (
        <Box>

            {
                loading
                    ? (
                        <div className={classes.loaderContainer}>
                            <CircularProgress className={classes.progress} />
                        </div>
                    ) : null
            }

            {
                isEmpty
                    ? (
                        <AlertBox variant="info">
                            No se han encontrado registros
                        </AlertBox>
                    ) : null
            }

            <List>
                {data.map(renderItem)}
            </List>
        </Box>
    );
}

export default withRouter(ProvidersBotList);