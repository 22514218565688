import React, { useContext, useMemo } from 'react';
import { Toolbar, Typography, Tooltip, IconButton, makeStyles, Button, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { StateContext, ReducerContext } from './context';
import FilterListIcon from '@material-ui/icons/FilterList';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

const useStyles = makeStyles((theme) => ({
    title: {
        flex: '1 1 100%',
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    item: {
        margin: theme.spacing(1),

    },
    button: {
        height: 60,
    }
}));

const FSTableToolbar = () => {
    const state = useContext(StateContext);
    const dispatch = useContext(ReducerContext);
    const classes = useStyles();
    const { t } = useTranslation();
    const [anchorMenuGroup, setAnchorMenuGroup] = React.useState<HTMLElement | null>(null);

    const handleShowFiltersDialog = () => {
        handleCloseMenuGroup();
        dispatch({ type: 'showFiltersDialog' });
    }

    const handleOpenCreateGroupDialog = () => {
        handleCloseMenuGroup();
        dispatch({ type: 'showCreateGroupDialog' });
    }

    const handleOpenSelectGroupDialog = () => dispatch({ type: 'showSelectGroupDialog' });

    const handleCloseMenuGroup = () => setAnchorMenuGroup(null);

    const handleShowMenuGroup = (event: React.MouseEvent<HTMLElement>) => setAnchorMenuGroup(event.currentTarget);

    const filtersRendered = useMemo(() => {
        return state.filters.map(filter => {
            const column = state.columns.find(x => 'name' in x && x.name === filter.property);
            let tooltipLines: string[] = [];
            for (const or of filter.ors) {
                tooltipLines.push(`${t('fs_table.operator_' + or.operator.toString())} ${or.value}`);
            }
            return {
                filter,
                label: column ? t(column.label) : filter.property,
                tooltip: tooltipLines.join('\n'),
            };
        });
    }, [state.columns, state.filters]);

    return (
        <Toolbar>
            <Typography className={classes.title} variant="h6" id="tableTitle">
                {t(state.title)}
            </Typography>

            {
                filtersRendered.map((filter) => (
                    <Tooltip
                        title={filter.tooltip}
                        onClick={handleShowFiltersDialog}
                        className={classes.item}
                    >
                        <Button variant="outlined" className={classes.button}>
                            {filter.label}
                        </Button>
                    </Tooltip>
                ))
            }

            {
                state.groupsEnabled && state.selectedGroup
                    ? <Button className={classes.item} variant="outlined" onClick={handleOpenSelectGroupDialog}>
                        Grupo {state.selectedGroup.name}
                    </Button>
                    : null
            }


            <Tooltip title="Filtros" onClick={handleShowFiltersDialog}>
                <IconButton>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>

            {
                state.forceGroup === null && state.groupsEnabled
                    ? (<>
                        <Tooltip title="Gestión de grupos" onClick={handleShowMenuGroup}>
                            <IconButton aria-label="filter list">
                                <GroupWorkIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorMenuGroup}
                            keepMounted
                            open={Boolean(anchorMenuGroup)}
                            onClose={handleCloseMenuGroup}
                        >
                            <MenuItem onClick={handleOpenCreateGroupDialog}>Crear grupo</MenuItem>
                            <MenuItem onClick={handleOpenSelectGroupDialog}>Seleccionar grupo</MenuItem>
                        </Menu>
                    </>)
                    : null
            }
        </Toolbar>
    );
}

export default FSTableToolbar;