import {
  Box,
  LinearProgress,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { FC, useCallback } from "react";
import useIoC from "../../contexts/ioc.context";
import { ClientTypesGateway } from "../../gateways/clients-types.gateway";
import { useFetchController } from "../../hooks/use-fetch-controller";

export type ClientTypeSelectorProps = SelectProps & { allowAll?: boolean };

export const ClientTypeSelector: FC<ClientTypeSelectorProps> = ({
  allowAll,
  value,
  ...selectProps
}) => {
  const clientTypesGateway = useIoC(ClientTypesGateway);

  const fetcher = useCallback(
    () => clientTypesGateway.findAll(),
    [clientTypesGateway]
  );

  const { data, loading } = useFetchController(fetcher, {
    autoload: true,
  });

  if (loading) {
    return <Skeleton variant="rect" />;
  }

  return (
    <Select defaultValue="all" {...selectProps}>
      {allowAll && <MenuItem value="all">Todos</MenuItem>}
      {data?.map((x) => (
        <MenuItem key={`menu-item_${x.id}`} value={x.id}>
          {x.clientTypeName}
        </MenuItem>
      ))}
    </Select>
  );
};
