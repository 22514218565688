import React, { FC, useState, useMemo, FormEventHandler, ChangeEventHandler, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ClientsGateway } from '../../gateways/clients.gateway';
import useIoC from '../../contexts/ioc.context';
import { useSnackbar } from 'notistack';
import { CircularProgress, makeStyles, FormHelperText, FormControl, Checkbox, FormControlLabel, InputLabel, TableHead, TableBody, TableCell, Table, TableRow } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { green } from '@material-ui/core/colors';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ProductsGateway } from '../../gateways/products.gateway';
import { MarketingGateway } from '../../gateways/marketing.gateway';
import { IProduct } from '../../gateways/products.interface';
import PrettoSlider from '../../components/pretto-slider';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { IClientMarketingTask, IMarketingTaskResult, IClientMarketing } from '../../gateways/marketing.interfaces';

const useStyles = makeStyles(() => ({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {

    },
}))

interface ICompleteActionDialogProps {
    open: boolean;
    client: IClientMarketing;
    action: IClientMarketingTask;
    onClose: () => void;
    onActionCompleted: (action: IClientMarketingTask) => void;
}

interface ISelectableProduct {
    product: IProduct;
    selected: boolean;
    probability: number;
}

const CompleteActionDialog: FC<ICompleteActionDialogProps> = ({ open, onClose, client, action, onActionCompleted }) => {
    const snackbar = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const [products, setProducts] = useState<ISelectableProduct[]>([]);
    const [taskResults, setTaskResults] = useState<IMarketingTaskResult[]>([]);

    const productsGateway = useIoC(ProductsGateway);
    const marketingGateway = useIoC(MarketingGateway);

    const hasAnyPhone = useMemo(() => client.contacts.filter(x => x.contactTypeId === 0).length > 0, [client]);
    const [useAnotherPhone, setUseAnotherPhone] = useState<boolean>(!hasAnyPhone);

    const [dto, setDto] = useState<{
        completedObservations: string,
        completedResult: IMarketingTaskResult | null,
        nextTaskDate: Date | null,
        phone: string
    }>({
        completedObservations: '',
        completedResult: action.completedResult,
        nextTaskDate: null,
        phone: ''
    });

    useEffect(() => {
        (async () => {
            const products = await productsGateway.findAll();
            setProducts(products.map((product) => {
                const current = action.products ? action.products.find(x => x.product.id === product.id) : null;
                return ({
                    product,
                    selected: !!current,
                    probability: current ? current.probability : 0,
                });
            }));
        })();
    }, [productsGateway]);

    useEffect(() => {
        (async () => {
            const tmp = await marketingGateway.getTaskResults();
            setTaskResults(tmp);
        })();
    }, [marketingGateway])

    const handleCompleteAction: FormEventHandler = (event) => {
        event.preventDefault();

        (async () => {
            if (!!!dto.completedResult) {
                snackbar.enqueueSnackbar('Tienes que seleccionar un resultado', {
                    variant: 'error',
                });
                return;
            }

            if (dto.completedResult.id !== 99) {
                if (dto.phone.length === 0) {
                    snackbar.enqueueSnackbar('Tienes que especificar el tel. de la prox. llamada', {
                        variant: 'error',
                    });
                    return;
                }

                if (dto.nextTaskDate === null) {
                    snackbar.enqueueSnackbar('Tienes que especificar la fecha de la prox. llamada', {
                        variant: 'error',
                    });
                    return;
                }
            }

            try {
                const tmp = await marketingGateway.completeTask({
                    taskId: action.id,
                    completedObservations: dto.completedObservations,
                    completedResultId: dto.completedResult.id,
                    nextTaskDate: dto.nextTaskDate,
                    phone: dto.phone.length > 0 ? dto.phone : null,
                    products: products.filter(x => x.selected).map(x => ({
                        productId: x.product.id,
                        probability: x.probability,
                    }))
                });
                snackbar.enqueueSnackbar('La acción se ha completado correctamente', {
                    variant: 'success',
                });
                onActionCompleted(tmp);
                onClose();
            } catch (e) {
                snackbar.enqueueSnackbar('Ha ocurrido un error al completar la acción: ' + e, {
                    variant: 'error',
                });
            }
        })();
    }

    const handleChange = (property: string) => (event: React.ChangeEvent<{ value: unknown }>) => setDto({ ...dto, [property]: event.target.value as any });
    const handleChangeDate = (property: string) => (value: Date | null) => setDto({ ...dto, [property]: value });
    const handleChangeSelect = (property: string, source: any[], match: (item: any, value: any) => boolean) => (event: React.ChangeEvent<{ value: unknown }>) => {
        const s = source.find(x => match(x, event.target.value as any));
        setDto({ ...dto, [property]: s });
    }

    const handleChangeProductProbability = (product: ISelectableProduct, value: number) => {
        product.probability = value;
        setDto({ ...dto });
    }
    const handleChangeProductSelected = (product: ISelectableProduct, checked: boolean) => {
        product.selected = checked;
        setDto({ ...dto });
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Acción Comercial</DialogTitle>
            <form onSubmit={handleCompleteAction}>
                <DialogContent>
                    <DialogContentText>
                        Formulario de confirmación de una acción comercial
                    </DialogContentText>
                    <FormControl fullWidth>
                        <TextField
                            type="text"
                            label="Observaciones"
                            onChange={handleChange('completedObservations')}
                            fullWidth
                            margin="dense"
                            multiline
                        />
                    </FormControl>
                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel id="result-selector-label">{t('marketing.result')}</InputLabel>
                        <Select
                            native
                            labelId="result-selector-label"
                            onChange={handleChangeSelect('completedResult', taskResults, (s, v) => s.id === v)}
                            value={dto.completedResult !== null ? dto.completedResult.id : ''}
                            inputProps={{
                                name: 'type-selector',
                                id: 'type-selector',
                            }}
                        >
                            <option value=""></option>
                            {
                                taskResults.map((item, index) => {
                                    return (
                                        <option key={'task_result_' + index} value={item.id}>{item.name}</option>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>
                    {
                        dto.completedResult && dto.completedResult.id !== 99
                            ? (<>
                                <FormControl fullWidth className={classes.formControl}>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                    >
                                        <Grid item xs={6}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                fullWidth
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="dense"
                                                id="task-date"
                                                label="Fecha prevista de llamada"
                                                value={dto.nextTaskDate}
                                                onChange={handleChangeDate('nextTaskDate')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <KeyboardTimePicker
                                                fullWidth
                                                ampm={false}
                                                margin="dense"
                                                variant="inline"
                                                id="task-time"
                                                label="Hora prevista de llamada"
                                                value={dto.nextTaskDate}
                                                onChange={handleChangeDate('nextTaskDate')}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                                <FormControl fullWidth className={classes.formControl}>
                                    {
                                        !useAnotherPhone
                                            ? (<>
                                                <InputLabel id="phone-selector-label">{t('marketing.phone')}</InputLabel>
                                                <Select
                                                    native
                                                    labelId="phone-selector-label"
                                                    onChange={handleChange('phone')}
                                                    value={dto.phone}
                                                    id="phone-selector"
                                                >
                                                    <option value=""></option>
                                                    {
                                                        client.contacts.filter(x => x.contactTypeId === 0).map((contact, index) => (
                                                            <option key={'phone_' + index} value={contact.value}>{contact.value}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </>)
                                            : (<TextField
                                                fullWidth
                                                margin="dense"
                                                id="phone"
                                                label="Teléfono alternativo"
                                                type="phone"
                                                value={dto.phone}
                                                onChange={handleChange('phone')}
                                                disabled={loading}
                                            />)
                                    }

                                    {
                                        hasAnyPhone ? (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={useAnotherPhone}
                                                        onChange={(e) => setUseAnotherPhone(e.target.checked)}
                                                        color="primary"
                                                    />
                                                }
                                                label="Usar otro teléfono"
                                            />
                                        ) : null
                                    }
                                </FormControl>
                            </>)
                            : null
                    }
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{t('marketing.product')}</TableCell>
                                <TableCell>{t('marketing.probability')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                products.map((item, index) => (
                                    <TableRow key={'product_' + index}>
                                        <TableCell>
                                            <Checkbox checked={item.selected} onChange={(e) => handleChangeProductSelected(item, e.target.checked)} />
                                        </TableCell>
                                        <TableCell>
                                            {item.product.name}
                                        </TableCell>
                                        <TableCell>
                                            <PrettoSlider
                                                valueLabelDisplay="auto"
                                                aria-label="probabilidad"
                                                value={item.probability}
                                                onChange={(e, value) => handleChangeProductProbability(item, value as number)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="default" disabled={loading}>
                        {t('common.close')}
                    </Button>
                    <Button type="submit" color="primary" disabled={loading}>
                        Completar acción
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default CompleteActionDialog;
