import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import moment from "moment";
import {
  GetServiceTypeStats,
  GetStatsDto,
  GlobalStatistics,
} from "./statistics.interface";

@inject("axios")
export class StatisticsGateway {
  constructor(private axios: AxiosInstance) {}

  public async getGlobalStatistics(
    startDate: Date,
    endDate: Date
  ): Promise<GlobalStatistics[]> {
    const params = new URLSearchParams({
      start_date: moment(startDate).format("YYYY/MM/DD"),
      end_date: moment(endDate).format("YYYY/MM/DD"),
    });
    const response = await this.axios.get(
      `statistics-manager/global-statistics?${params.toString()}`
    );
    return response.data;
  }

  public async getStats(dto: GetStatsDto): Promise<GlobalStatistics[]> {
    const response = await this.axios.post(`statistics-manager/get-stats`, dto);
    return response.data;
  }

  public async getServiceTypesStats(
    from: Date,
    to: Date
  ): Promise<GetServiceTypeStats[]> {
    const response = await this.axios.post(
      `statistics-manager/service-types-stats`,
      { from_date: from, to_date: to }
    );
    return response.data;
  }

  public async getClientStats(
    from: Date,
    to: Date,
    clientTypeId: number
  ): Promise<GetServiceTypeStats[]> {
    const response = await this.axios.post(`statistics-manager/client-stats`, {
      from_date: from,
      to_date: to,
      client_type_id: clientTypeId,
    });
    return response.data;
  }
}
