
import  ICompany  from "../../gateways/company.interface";
import { createContext, Dispatch } from "react";

export type StateType = { company: ICompany | null };
export type ActionType = { type: 'update-company', payload: ICompany };

export const CompanyReducer = (state: StateType, action: ActionType) => {
    switch (action.type) {
        case 'update-company':
            return { ...state, company: action.payload };
    }
    return state;
};

export const CompanyContext = createContext<[StateType, Dispatch<ActionType>]>([
    { company: null },
    (state) => state,
]);
