import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IAddEmail, IEmailBlacklist } from "./blacklist.interfaces";

@inject('axios')
export class BlacklistGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findEmail(email: string): Promise<IEmailBlacklist> {
        const response = await this.axios.get(`blacklist/findEmail/${email}`);
        return response.data;
    }

    async findCups(cups: string[]): Promise<string[]> {
        const response = await this.axios.post(`blacklist/findCups`, cups);
        return response.data;
    }

    async insertEmail(email: IAddEmail): Promise<IEmailBlacklist> {
        const response = await this.axios.post('blacklist/insertEmail', email);
        return response.data;
    }

    async insertCups(cups: string) {
        await this.axios.post(`blacklist/insertCups/${cups}`);
    }

    async deleteEmail(email: string) {
        await this.axios.delete(`blacklist/deleteEmail/${email}`);
    }

    async deleteCups(cups: string) {
        await this.axios.delete(`blacklist/deleteCups/${cups}`);
    }
}