import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRouteMatch } from "react-router-dom";
import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Box,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { SupplyType } from "../../gateways/supply-type.interfaces";
import { AccessType } from "../../gateways/access-type.interfaces";
import { ProvidersGateway } from "../../gateways/providers.gateway";
import { IProviders } from "../../gateways/providers.interface";
import { ProviderType } from "../../gateways/provider-types.interface";
import {
  IElectricContractCreate,
  IElectricContract,
  initialElectricContract,
  IElectricContractAccountLogs,
} from "../../gateways/electric-contract.interfaces";
import { ElectricContractGateway } from "../../gateways/electric-contract.gateway";
import { IElectricSupplyRates } from "../../gateways/electric-supply-rates.interface";
import { ICreatePeriodElectricSupplyRate } from "../../gateways/period-electric-supply-rate.interfaces";
import { ElectricSupplyRatesGateway } from "../../gateways/electric-supply-rates.gateway";
import { datesHandler } from "../../utils/dates-handle";
import CountryGateway from "../../gateways/country.gateway";
import CurrencyGateway from "../../gateways/currency.gateway";
import { IElectricContractMaterialTableRows } from "../../utils/material-table.interface";
import { ElectricSupplyGateway } from "../../gateways/electric-supply.gateway";
import moment from "moment";
import { SupplyConnectionBotGateway } from "../../gateways/supply-connection-bot.gateway";
import {
  ISupplyConnectionBot,
  initialSupplyConnectBot,
} from "../../gateways/supply-connection-bot.interface";
import SupplyConnectionBotDialog from "../supply-connection-bot/supply-connection-bot-dialog";
import ElectricContractFormDetailUpsert from "./electric-contract-form-upsert";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import ContractDrawer from "../../components/contract-drawer";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import ProvidersUpsertDialog from "../providers/providers-upsert-dialog";
import { useUser } from "../../contexts/user.context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    root: {
      display: "flex",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    botText: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    itemAvatarIcon: {
      backgroundColor: "#e0e0e0",
      borderRadius: "50%",
      padding: theme.spacing(1),
    },
  })
);

interface IElectricContractRouteParams {
  [x: string]: string | undefined;
  complexId: string;
  establishmentId: string;
  electricSupplyId: string;
  clientId: string;
  electricContractId: string;
}

const ElectricContractDetail = () => {
  const { complexId } = useParams<IElectricContractRouteParams>();
  const { establishmentId } = useParams<IElectricContractRouteParams>();
  const { electricSupplyId } = useParams<IElectricContractRouteParams>();
  const { clientId } = useParams<IElectricContractRouteParams>();
  const { url } = useRouteMatch();

  const classes = useStyles();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const dateHandler = new datesHandler();

  const { electricContractId } = useParams<IElectricContractRouteParams>();
  const [state, setState] = useState<IElectricContractMaterialTableRows[]>([]);
  const { update: updateBreadCrumbName } = useBreadCrumbName();

  const [loading , setLoading] = useState(false);
  const [item, setItem] = useState<IElectricContract>(initialElectricContract);
  const [accountLogs, setAccountLogs] = useState<IElectricContractAccountLogs[]>([])
  const [rates, setRates] = useState<IElectricSupplyRates[]>([]);
  const [providers, setProviders] = useState<IProviders[]>([]);
  const [period, setPeriod] = useState(3);
  const [, setCurrency] = useState<string>("");
  const [firstIteration, setFirstIteration] = useState<boolean>(true);
  const [supplyConnectionBot, setSupplyConnectionBot] =
    useState<ISupplyConnectionBot>(initialSupplyConnectBot);
  const [openSupplyConnectionBotDialog, setOpenSupplyConnectionBotDialog] =
    useState(false);

  const electricContractGateway = useIoC(
    ElectricContractGateway
  );
  const ratesGateway = useIoC(
    ElectricSupplyRatesGateway
  );
  const providerGateway = useIoC(ProvidersGateway);
  const countriesGateway = useIoC(CountryGateway);
  const currenciesGateway = useIoC(CurrencyGateway);
  const electricSupplyGateway = useIoC(
    ElectricSupplyGateway
  );
  const supplyConnectionBotGateway = useIoC(
    SupplyConnectionBotGateway
  );

  const [{ user }] = useUser();
  const [openProviderAddDialog, setOpenProviderAddDialog] = useState(false);

  const handleClickAddProviderDialog = () => {
    setOpenProviderAddDialog(true);
  };

  const handleCloseAddProviderDialog = () => {
    setOpenProviderAddDialog(false);
  };

  const handleCreateNewProvider = (item: IProviders, isNew: boolean) => {
    setProviders([...providers, item]);
  };

  const handleOpenBotDialog = () => {
    (async () => {
      setOpenSupplyConnectionBotDialog(true);
    })();
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (!electricContractId) {
        return;
      }
      if (complexId) {
        try {
          const coinId = await countriesGateway.getCountrieCurrencyComplex(
            parseInt(complexId)
          );
          const coin = await currenciesGateway.findById(coinId);
          setCurrency(coin.isoCode);
        } catch (e) {
          snackbar.enqueueSnackbar(t("currencyErrorHandler.readErrorComplex"), {
            variant: "warning",
          });
        }
      } else if (establishmentId) {
        try {
          const coinId =
            await countriesGateway.getCountrieCurrencyEstablishment(
              parseInt(establishmentId)
            );
          const coin = await currenciesGateway.findById(coinId);
          setCurrency(coin.isoCode);
        } catch (e) {
          snackbar.enqueueSnackbar(
            t("currencyErrorHandler.readErrorEstablishment"),
            { variant: "warning" }
          );
        }
      }
      const electricContract = await electricContractGateway.findById(
        Number.parseInt(electricContractId)
      );
      if (electricContract.electricSupplyRateId < 3) {
        for (let i: number = 3; i < 6; i++) {
          electricContract.periodsElectricSupplyRate.push({
            discount: 0,
            electricContractId: 1,
            energyRate: 0,
            powerContract: 0,
            powerRate: 0,
          });
        }
      }
      setItem(electricContract);

      const contractAccountLogs = await electricContractGateway.accountHistoryLogs(electricContract.id)
      setAccountLogs(contractAccountLogs)

      updateBreadCrumbName(
        "electricContract",
        (electricContract.initSupplyDate
          ? `${moment(electricContract.initSupplyDate).format("DD/MM/YYYY")}`
          : t("common.undefinedDate")) +
          " - " +
          (electricContract.endSupplyDate
            ? `${moment(electricContract.endSupplyDate).format("DD/MM/YYYY")}`
            : t("common.undefinedDate"))
      );
      const electricSupplyRates_ = await ratesGateway.findAll();
      const providers_ = await providerGateway.findWithFilters({
        providerTypeId: ProviderType.Comercializadora.toString(),
        supplyTypeId: SupplyType.Electrico.toString(),
        accessTypeId: AccessType.Red.toString(),
      });
      const supplyConnectionBot =
        await supplyConnectionBotGateway.findByElectricContract(
          electricContract.id
        );
      setSupplyConnectionBot(supplyConnectionBot);

      setProviders(providers_);
      setRates(electricSupplyRates_);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const electricSupplyRate_ = await ratesGateway.findById(
        item.electricSupplyRateId
      );
      setPeriod(electricSupplyRate_.periods);
      var fillStateAid: IElectricContractMaterialTableRows[] = [];
      for (let i: number = 0; i < electricSupplyRate_.periods; i++) {
        fillStateAid[i] = {
          periodNumber: i + 1,
          discount: item.periodsElectricSupplyRate[i].discount,
          energyRate: item.periodsElectricSupplyRate[i].energyRate,
          powerContract: item.periodsElectricSupplyRate[i].powerContract,
          powerRate: item.periodsElectricSupplyRate[i].powerRate,
        };
      }
      setState(fillStateAid);
    })();
  }, [item.electricSupplyRateId]);

  useEffect(() => {
    (async () => {
      if (firstIteration) {
        setFirstIteration(false);
      } else if (
        !dateHandler.checkLimits(item.initSupplyDate, item.endSupplyDate)
      ) {
        snackbar.enqueueSnackbar(t("messages.dateRangeError"), {
          variant: "error",
        });
        return;
      } else {
        const existingContracts: IElectricContract[] = electricSupplyId
          ? await electricSupplyGateway.findByIdElectricContract(
              parseInt(electricSupplyId, 10)
            )
          : [];
        for (let i: number = 0; i < existingContracts.length; i++) {
          if (
            dateHandler.checkOverlap(
              new Date(item.initSupplyDate),
              new Date(item.endSupplyDate),
              existingContracts[i].initSupplyDate,
              existingContracts[i].endSupplyDate
            ) &&
            electricContractId &&
            existingContracts[i].id !== parseInt(electricContractId)
          ) {
            snackbar.enqueueSnackbar(
              t("messages.dateOverlapErrorContract", {
                id: existingContracts[i].id,
              }),
              { variant: "error" }
            );
            return;
          }
        }
      }
      setItem({
        ...item,
        duration: Math.ceil(
          moment(item.endSupplyDate).diff(item.initSupplyDate, "month", true)
        ),
      });
    })();
  }, [item.initSupplyDate, item.endSupplyDate]);

  const checkPeriodsElectricSupplyRate = () => {
    for (var i: number = 0; i < period; i++) {
      if (
        item.periodsElectricSupplyRate[i].discount === 0 ||
        item.periodsElectricSupplyRate[i].electricContractId === 0 ||
        item.periodsElectricSupplyRate[i].energyRate === 0 ||
        item.periodsElectricSupplyRate[i].powerContract === 0 ||
        item.periodsElectricSupplyRate[i].powerRate === 0
      ) {
        return false;
      }
    }
    return true;
  };

  const handleSave = async () => {
    // if (!item.cancelPriorNotice || !item.duration ||
    //     !checkPeriodsElectricSupplyRate() || !item.endSupplyDate ||
    //     !item.initSupplyDate || !item.modality ||
    //     !item.periodsElectricSupplyRate || !item.providerId ||
    //     !item.signContractDate || !item.term) {
    //     snackbar.enqueueSnackbar(t('messages.requiredFields'), {
    //         variant: 'error',
    //     });
    //     return;
    // }

    if (!dateHandler.checkLimits(item.initSupplyDate, item.endSupplyDate)) {
      snackbar.enqueueSnackbar(t("messages.dateRangeError"), {
        variant: "error",
      });
      return;
    }
    const existingContracts: IElectricContract[] = electricSupplyId 
      ? await electricSupplyGateway.findByIdElectricContract(
        parseInt(electricSupplyId)
      ): [];
    for (let i: number = 0; i < existingContracts.length; i++) {
      if (
        dateHandler.checkOverlap(
          item.initSupplyDate,
          item.endSupplyDate,
          existingContracts[i].initSupplyDate,
          existingContracts[i].endSupplyDate
        ) &&
        electricContractId &&
        existingContracts[i].id !== parseInt(electricContractId)
      ) {
        snackbar.enqueueSnackbar(
          t("messages.dateOverlapErrorContract", {
            id: existingContracts[i].id,
          }),
          { variant: "error" }
        );
        return;
      }
    }
    try {
      if (!electricContractId) {
        return;
      }
      let contractToUpdate: IElectricContractCreate;
      let periodsElectricSupplyRateToCreate: ICreatePeriodElectricSupplyRate[] =
        [];
      for (let i: number = 0; i < period; i++) {
        periodsElectricSupplyRateToCreate[i] =
          item.periodsElectricSupplyRate[i];
      }
      contractToUpdate = {
        reference: item.reference,
        cancelPriorNotice: item.cancelPriorNotice,
        duration: item.duration,
        electricSupplyRateId: item.electricSupplyRateId,
        endSupplyDate: item.endSupplyDate,
        initSupplyDate: item.initSupplyDate,
        modality: item.modality,
        periodsElectricSupplyRate: periodsElectricSupplyRateToCreate,
        providerId: item.providerId,
        signContractDate: item.signContractDate,
        term: item.term,
        electricSupplyId: item.electricSupplyId,
      };

      await electricContractGateway.update(
        Number.parseInt(electricContractId),
        contractToUpdate
      );
      
      if(item.accountNumber !== null && user){
        await electricContractGateway.addAccountNumber(item.id, item.accountNumber, user)
        
        const reloadAccountLogs = await electricContractGateway.accountHistoryLogs(item.id)
        setAccountLogs(reloadAccountLogs)
      }

      snackbar.enqueueSnackbar(
        t("electricContractDetail.updateSucceded", {
          id: Number.parseInt(electricContractId),
          reference: item.term,
        }),
        {
          variant: "success",
        }
      );
    } catch (e) {
      const er = e as any;
      if (er.response.data.key === "updatingError") {
        const key = "electricContractErrorHandler." + er.response.data.key;
        const message =
          "validation" in er ? er.validation : t(key, { id: er.response.data.id });
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else {
        const message =
          "validation" in er ? er.validation : t("messages.defaultError");
        snackbar.enqueueSnackbar(message, { variant: "error" });
      }
    }
  };

  const handleChange = (name: string, value: any) => {
    setItem({ ...item, [name]: value });
  };

  const handleChangePeriodElectricSupplyRate = (
    updatingArray: IElectricContractMaterialTableRows,
    index: number
  ) => {
    let updatePeriodsAid: ICreatePeriodElectricSupplyRate[] =
      item.periodsElectricSupplyRate.slice();
    updatePeriodsAid[index].discount = updatingArray.discount;
    updatePeriodsAid[index].energyRate = updatingArray.energyRate;
    updatePeriodsAid[index].powerContract = updatingArray.powerContract;
    updatePeriodsAid[index].powerRate = updatingArray.powerRate;
    setItem({ ...item, periodsElectricSupplyRate: updatePeriodsAid });
  };

  const handleSignContractDateChange = (value: any) => {
    setItem({ ...item, signContractDate: value });
  };

  const handleInitSupplyDateChange = (value: any) => {
    setItem({ ...item, initSupplyDate: value });
    updateBreadCrumbName(
      "electricContract",
      (value
        ? `${moment(value).format("DD/MM/YYYY")}`
        : t("common.undefinedDate")) +
        " - " +
        (item.endSupplyDate
          ? `${moment(item.endSupplyDate).format("DD/MM/YYYY")}`
          : t("common.undefinedDate"))
    );
  };

  const handleEndSupplyDateChange = (value: any) => {
    setItem({ ...item, endSupplyDate: value });
    updateBreadCrumbName(
      "electricContract",
      (item.initSupplyDate
        ? `${moment(item.initSupplyDate).format("DD/MM/YYYY")}`
        : t("common.undefinedDate")) +
        " - " +
        (value
          ? `${moment(value).format("DD/MM/YYYY")}`
          : t("common.undefinedDate"))
    );
  };

  const handleCloseSupplyConnectionBotDialog = () => {
    setOpenSupplyConnectionBotDialog(false);
  };

  const handleItem = (item: ISupplyConnectionBot) => {
    setSupplyConnectionBot(item);
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  return (
    <Box>
      <div className={classes.root}>
        <div className={classes.content}>
          {supplyConnectionBot ? (
            <Typography className={classes.botText}>
              {t('electricContractDetail.botConnected', {
                name: supplyConnectionBot.botPreconfig.alias,
              })}
            </Typography>
          ) : null}
          <ScreenGlobalStructure
            buttonIcon={<SaveIcon />}
            headerIcon={InsertDriveFileIcon}
            matchUrl={url}
            onButtonClick={handleSave}
            title={t('electricContractDetail.title')}
            clientId={clientId ? clientId : ''}
            loading={loading}
            drawer={
              <ContractDrawer
                onOpenBotDialog={handleOpenBotDialog}
                icon={
                  <InsertDriveFileIcon
                    fontSize='large'
                    className={classes.itemAvatarIcon}
                  />
                }
              />
            }
          >
            <ElectricContractFormDetailUpsert
              onChangePeriodElectricSupplyRate={
                handleChangePeriodElectricSupplyRate
              }
              item={item}
              accountLogs={accountLogs}
              showAccountLogs={true}
              onChange={handleChange}
              onEndSupplyDateChange={handleEndSupplyDateChange}
              onInitSupplyDateChange={handleInitSupplyDateChange}
              onSignContractDateChange={handleSignContractDateChange}
              providers={providers}
              rates={rates}
              setState={setState}
              state={state}
              validateRequired={validateRequired}
            //  style={styles}
              onClickAddProvider={handleClickAddProviderDialog}
            />
          </ScreenGlobalStructure>
        </div>
      </div>
      <ProvidersUpsertDialog
        open={openProviderAddDialog}
        item={undefined}
        onClose={handleCloseAddProviderDialog}
        onItemUpsert={handleCreateNewProvider}
      />

      <SupplyConnectionBotDialog
        open={openSupplyConnectionBotDialog}
        item={supplyConnectionBot}
        electricContractId={item.id.toString()}
        fuelContractId={undefined}
        onItem={handleItem}
        onClose={handleCloseSupplyConnectionBotDialog}
      />
    </Box>
  );
};

export default ElectricContractDetail;
