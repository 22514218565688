
import  { IElectricSupply }  from "../../gateways/electric-supply.interface";
import { createContext, Dispatch } from "react";

export type StateType = { electricSupply: IElectricSupply | null };
export type ActionType = { type: 'update-electricSupply', payload: IElectricSupply };

export const ElectricSupplyReducer = (state: StateType, action: ActionType) => {
    switch (action.type) {
        case 'update-electricSupply':
            return { ...state, electricSupply: action.payload };
    }
    return state;
};

export const ElectricSupplyContext = createContext<[StateType, Dispatch<ActionType>]>([
    { electricSupply: null },
    (state) => state,
]);
