import React, { FormEventHandler, useEffect } from "react";
import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, Input, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
    IBotPreconfigFieldRO,
  } from "../../gateways/bots.interfaces";

export interface IFieldAddDialogProps {
    open: boolean;
    
    onCreate: (item: IBotPreconfigFieldRO) => void;
    onClose: () => void;
}

const PreconfigAddFieldDialog: FC<IFieldAddDialogProps> = ({ open, onClose, onCreate }) => {
    const { t } = useTranslation();
    const [ field, setField] = useState<IBotPreconfigFieldRO>({
        field: '',
        value: '',
        type: ''
    });

    useEffect(() => {
        setField({
            field: '',
            value: '',
            type: ''
        })
    }, [open]);

    const handleClose = () => {
        onClose();
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        // console.log('field:', event.target.value);
        let tfield = field;
        tfield.field = event.target.value;
        setField(tfield);
    }

    const handleChangeValue = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        // console.log('value:', event.target.value);
        let tfield = field;
        tfield.value = event.target.value;
        setField(tfield);
    }

    const handleSubmit: FormEventHandler = (e) => {
        e.preventDefault();
        // console.log('field', field);
        onCreate(field);
        onClose();
    }

    return (
        <Dialog open={open} onClose={handleClose}fullWidth={true} maxWidth="md">
            <DialogTitle id="form-dialog-title">Añadir nuevo campo personalizado</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="field"
                    label="Campo"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleChangeField}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="value"
                    label="Valor"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleChangeValue}
                />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        {t('common.add')}
                    </Button>
                    <Button onClick={handleClose} color="default">
                        {t('common.cancel')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
export default PreconfigAddFieldDialog;