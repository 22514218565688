import {
  Box,
  createStyles,
  Grid,
  GridSize,
  IconButton,
  ListItem,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { memo, useEffect, useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import { IMongoBotMissingDatesDisplay } from "../../gateways/mongo.interfaces";
import SaveIcon from "@material-ui/icons/Save";
import DescriptionIcon from "@material-ui/icons/Description";
import CenteredCircularProgress from "../../components/centered-circular-progress";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import InfoBotDialogNoCups from "../info-bot_f/errors/error-no-cups/error-no-cups-dialog";
import BotMissingDatesSupplies from "./bot-missing-dates-add-supply/bot-missing-dates-add-supply";
import { FuelSupplyGateway } from "../../gateways/fuel-supply.gateway";
import useIoC from "../../contexts/ioc.context";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      height: "100%",
      borderSpacing: 2,
      padding: theme.spacing(1),
    },
  })
);

interface IBotMissingDatesFields {
  focus: boolean;
  fields: IMongoBotMissingDatesDisplay;
  onChangeDate: (event: any, id: string | null | undefined) => void;
  onSave: (
    item: IMongoBotMissingDatesDisplay,
    currPage: number,
    currNPages: number
  ) => void;
  onOpenFile: (item: IMongoBotMissingDatesDisplay) => void;
  loadingRow: string;
  currentPage: number;
  currentNumberOfPages: number;
  isDisaFg: boolean;
  onChange: (name: string, value: any, id: string | null | undefined) => void;
}

const BotMissingDatesFields: FC<IBotMissingDatesFields> = memo((props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [supplyReference, setSupplyReference] = useState<string>("");
  const [openSupplyFill, setOpenSupplyFill] = useState(false);
  const fuelSupplyGateway = useIoC(FuelSupplyGateway);
  useEffect(() => {
    (async () => {
      if (!props.fields.supplyId) {
        setSupplyReference("");
      } else {
        const fuelSupply = await fuelSupplyGateway.findById(
          props.fields.supplyId
        );
        setSupplyReference(fuelSupply.reference);
      }
    })();
  }, [props.fields.supplyId]);
  const handleClick = async (item: IMongoBotMissingDatesDisplay) => {
    props.onSave(item, props.currentPage, props.currentNumberOfPages);
  };
  const handleOpenFile = async (item: IMongoBotMissingDatesDisplay) => {
    props.onOpenFile(item);
  };
  const handleChangeDate = (event: any, id: string) => {
    const formattedDate = moment(event).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    const dateToUTC = moment.utc(formattedDate).toDate();

    props.onChangeDate(dateToUTC, id);
  };
  const handleChange = (name: string, value: any) => {
    props.onChange(name, value, props.fields.originId);
  };
  const handleOpenSupplyCompletion = () => {
    setOpenSupplyFill(true);
  };
  const handleCloseSupplyCompletion = () => {
    setOpenSupplyFill(false);
  };

  const handleAcceptSupplyCompletion = (
    fuelSupplyId: number,
    reference: string
  ) => {
    setSupplyReference(reference);
    handleChange("supplyId", fuelSupplyId);
    handleCloseSupplyCompletion();
  };

  return (
    <Box>
      <ListItem alignItems="flex-start">
        <Grid container direction="row" xs={12}>
          <Grid xs={3} className={classes.gridItem}>
            <FormTextField
              fullWidth
              disabled
              label={t("bot_f.mantainment.invoiceNumber")}
              name="invoiceNumber"
              validator={() => {
                return true;
              }}
              errorText={t("messages.requiredField") as string}
              type="text"
              value={props.fields.invoiceNumber}
              onChange={() => {}}
            />
          </Grid>
          {props.isDisaFg && (
            <Grid xs={3} className={classes.gridItem}>
              <Grid container direction="row" xs={12}>
                <Grid xs={10}>
                  <FormTextField
                    fullWidth
                    disabled
                    label={t("common.supply")}
                    name="cups"
                    validator={() => {
                      return true;
                    }}
                    errorText={t("messages.requiredField") as string}
                    type="text"
                    value={supplyReference}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid xs={2}>
                  <IconButton
                    color="primary"
                    aria-label="add"
                    style={{ paddingBottom: 0 }}
                    disabled={false}
                    onClick={handleOpenSupplyCompletion}
                  >
                    <LocalGasStationIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid xs={!props.isDisaFg ? 3 : 2} className={classes.gridItem}>
            <KeyboardDatePicker
              InputLabelProps={{
                shrink: true,
              }}
              format="dd/MM/yyyy"
              id={`initDate`}
              label={t("bot_f.mantainment.initDate")}
              value={props.fields.initDate}
              onChange={(event) => {
                handleChangeDate(event, `initDate->${props.fields.originId}`);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              fullWidth
            />
          </Grid>
          <Grid xs={!props.isDisaFg ? 3 : 2} className={classes.gridItem}>
            <KeyboardDatePicker
              InputLabelProps={{
                shrink: !!props.fields.endDate,
              }}
              format="dd/MM/yyyy"
              id={`endDate`}
              label={t("bot_f.mantainment.endDate")}
              value={props.fields.endDate}
              onChange={(event: any) => {
                handleChangeDate(event, `endDate->${props.fields.originId}`);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              fullWidth
            />
          </Grid>
          <Grid xs={!props.isDisaFg ? 3 : 2} className={classes.gridItem}>
            <Grid container direction="row" xs={12}>
              <Grid xs={11}>
                <KeyboardDatePicker
                  InputLabelProps={{
                    shrink: !!props.fields.issueDate,
                  }}
                  format="dd/MM/yyyy"
                  id={`issueDate`}
                  label={t("bot_f.mantainment.issueDate")}
                  value={props.fields.issueDate}
                  onChange={(event) => {
                    handleChangeDate(
                      event,
                      `issueDate->${props.fields.originId}`
                    );
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                />
              </Grid>
              <Grid xs={1}>
                {props.loadingRow === props.fields._id ? (
                  <CenteredCircularProgress />
                ) : (
                  <Grid container direction="column">
                    <IconButton
                      color="primary"
                      aria-label="add"
                      onClick={() => handleClick(props.fields)}
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      aria-label="add"
                      onClick={() => handleOpenFile(props.fields)}
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <BotMissingDatesSupplies
        open={openSupplyFill}
        onClose={handleCloseSupplyCompletion}
        onAccept={handleAcceptSupplyCompletion}
      />
    </Box>
  );
});

export default BotMissingDatesFields;
