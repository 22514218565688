
import { IClient } from "../../gateways/clients.interfaces";
import { createContext, Dispatch } from "react";

export type StateType = { client: IClient | null };
export type ActionType = { type: 'update-client', payload: IClient };

export const ClientReducer = (state: StateType, action: ActionType) => {
    switch (action.type) {
        case 'update-client':
            return { ...state, client: action.payload };
    }
    return state;
};

export const ClientContext = createContext<[StateType, Dispatch<ActionType>]>([
    { client: null },
    (state: any) => state,
]);
