import React, { FC, useEffect, useState, useMemo, Fragment } from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  makeStyles,
  CircularProgress,
  Box,
  IconButton,
  Collapse,
  ListItemIcon,
  Paper,
  TextField,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import BuildIcon from "@material-ui/icons/Build";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import { withRouter, RouteComponentProps } from "react-router";
import useIoC from "../../contexts/ioc.context";
import AlertBox from "../../components/alert-box";
import { useNavigator } from "../../contexts/navigator.context";
import SupplyBotsIcon from "@material-ui/icons/Autorenew";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  capitalizeFirstLetter,
  convertirCurvasEndesa,
  dateNormalizer,
  findMongoItem,
} from "./functions/functions";
import { MongoGateway } from "../../gateways/mongo.gateway";
import { S3Gateway } from "../../gateways/s3.gateway";
import { type } from "os";

const fetch = require("node-fetch");

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  listItem: {
    cursor: "pointer",
  },
  inline: {
    display: "inline",
  },
  itemAvatarIcon: {
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
  progress: {
    margin: theme.spacing(2),
  },
  loaderContainer: {
    textAlign: "center",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  iconButton: {
    padding: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: "46%",
  },
}));

const CurvesList: FC<RouteComponentProps> = ({ history }) => {
  const mongoGateway = useIoC(MongoGateway);
  const s3Gateway = useIoC(S3Gateway);
  const classes = useStyles();
  const [, navigatorDispatch] = useNavigator();
  const [resultItems, setResultItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const isEmpty = useMemo(
    () => !loading && resultItems && resultItems.length === 0,
    [loading, resultItems]
  );
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<number[]>([]);

  const handleExpand = (value: number) => () => {
    const currentIndex = expanded.indexOf(value);
    const newExpanded = [...expanded];

    if (currentIndex === -1) {
      newExpanded.push(value);
    } else {
      newExpanded.splice(currentIndex, 1);
    }

    setExpanded(newExpanded);
  };

  const convertBase64IntoBlob = async (base64String: string) => {
    const url = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + base64String;
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }

  const downloadMinioFileIntoBlob = async (url: string) =>
  {
    const link = await s3Gateway.getUrl(url);
    const response = await fetch(link);
    const blob = await response.blob();
    return new Blob([blob], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });
  }

  const handleOnOpen = async (item: any) => {
    if (!!!item.invoiceNumber) {
      snackbar.enqueueSnackbar(t("common.documentationNotAvailable"), {
        variant: "error",
      });
    } else {
      // const curvas = !!!item.base64File ? convertirCurvasEndesa(item) : item.base64File;
      if (item.type) {
        let blob = null;

        if (typeof item.type === 'string' && item.type.length > 0)
        {
          const tipo = (item.type as string).toLowerCase();
          if(tipo === 'endesa') {
              //Endesa case
              blob = new Blob([convertirCurvasEndesa(item)], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
              });
          } else if (tipo === 'iberdrola') {
              //Iberdrola case
              if (typeof item.base64File !== 'undefined')
                //from base64
                blob = await convertBase64IntoBlob(item.base64File);
              else if(typeof item.urlFile === 'string' && item.urlFile.length > 0) {
                //from minio url
                blob = await downloadMinioFileIntoBlob(item.urlFile);
              }

          }
  
          if (blob != null)
          {
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "curvas de potencia " + item.invoiceNumber + ".xls";
            document.body.appendChild(link);
    
            link.click();
    
            document.body.removeChild(link);
            //const win = window.open(url);
    
            //visor curvas por web - OP no lo necesita
            // const win = window.open("", `Curvas de la factura: ${item.invoiceNumber}`, "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=580,top=0,left=0");
    
            // if (win)
            // {
            //     win.document.body.innerHTML = curvas as any;
            // }
            // else
            // {
            //     snackbar.enqueueSnackbar(t("common.documentationNotAvailable"), {
            //         variant: "error",
            //     });
            // }

          } else {
            snackbar.enqueueSnackbar(t("common.documentationNotAvailable"), {
              variant: "error",
            });
          }

        }

      } else {
        snackbar.enqueueSnackbar(t("common.documentationNotAvailable"), {
          variant: "error",
        });
      }
    }
  };

  const [invoiceNumberSearch, setInvoiceNumberSearch] = useState("");
  const [invoiceCupsSearch, setInvoiceCupsSearchSearch] = useState("");
  const [usernameSearch, setUsernameSearch] = useState("");

  const [page, setPage] = useState(1);

  const queryMongoDBcollections = async (dbName: string) => {
    if (
      invoiceNumberSearch !== "" ||
      invoiceCupsSearch !== "" ||
      usernameSearch !== ""
    ) {
      //const items: any[] = [];

      if (invoiceNumberSearch !== "") {
        setPage(1);
        // items.push(await findMongoItem(dbName, `{ "invoiceNumber" : ${JSON.stringify(invoiceNumberSearch)} }`, mongoGateway, 0, 50));
        return await findMongoItem(
          dbName,
          `{ "invoiceNumber" : ${JSON.stringify(invoiceNumberSearch)} }`,
          mongoGateway,
          0,
          10
        );
      }
      if (invoiceCupsSearch !== "") {
        setPage(1);
        // items.push(await findMongoItem(dbName, `{ "invoiceCups" : "${JSON.stringify(invoiceCupsSearch)}" }`, mongoGateway, 0, 50));
        return await findMongoItem(
          dbName,
          `{ "invoiceCups" : ${JSON.stringify(invoiceCupsSearch)} }`,
          mongoGateway,
          0,
          10
        );
      }
      if (usernameSearch !== "") {
        setPage(1);
        // items.push(await findMongoItem(dbName, `{ "username" : "${JSON.stringify(usernameSearch)}" }`, mongoGateway, 0, 50));
        return await findMongoItem(
          dbName,
          `{ "username" : ${JSON.stringify(usernameSearch)} }`,
          mongoGateway,
          0,
          10
        );
      }

      // if (items && items.length > 0)
      //     return items;
    }

    //empty search
    setPage(1);
    return await findMongoItem(dbName, undefined, mongoGateway, 0, 50);
  };

  const updateResultItems = async () => {
    setLoading(true);
    const dbNames = ["endesa", "iberdrola"];
    const items: any[] = [];

    for(let i = 0; i < dbNames.length; i++) {
        const currentDBName = dbNames[i];
       
        let platformCurves: any[] | null = await queryMongoDBcollections(currentDBName);
        if(!!platformCurves) {
          const max = 10 / dbNames.length
          platformCurves = platformCurves.slice(0, max);
        }
        if (Array.isArray(platformCurves) && platformCurves.length > 0) {
            //console.log('Curvas ' + currentDBName, EndesaItems.length)
            platformCurves.forEach((itm: any) => {
                items.push({ type: capitalizeFirstLetter(currentDBName), ...itm });
            });
        }
    }

    //update all items
    setResultItems(items);
    setLoading(false);
  };

  const renderItem = (item: any, index: number) => {
    const handleClickOpenWrapper = () => handleOnOpen(item);

    if (
      item !== null &&
      item.type !== null &&
      item.invoiceCups &&
      item.initPeriod !== null &&
      item.endPeriod !== null
    )
      return (
        <Fragment key={index ?? item._id}>
          <ListItem
            alignItems="flex-start"
            className={classes.listItem}
            onClick={handleExpand(index)}
          >
            <ListItemAvatar>
              <ArrowDropDownCircleIcon
                color="action"
                fontSize="large"
                className={classes.itemAvatarIcon}
              />
            </ListItemAvatar>
            <ListItemText
              primary={`Tipo: [${item.type}] - Numero de factura: ${
                item.invoiceNumber
              } - Periodos: ${dateNormalizer(
                item.initPeriod
              )} al ${dateNormalizer(item.endPeriod)}`}
              secondary={`Referencia: ${item.invoiceCups}`}
            />
          </ListItem>

          <Collapse
            in={expanded.indexOf(index) !== -1}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.inline}
                  primary="Credenciales usadas"
                  secondary={`Nombre de Usuario: ${item.username}`}
                />
                <ListItemText
                  className={classes.inline}
                  primary="Eventos Bot"
                  secondary={`Descargada el: ${dateNormalizer(
                    item.documentInsertedDate.toString()
                  )}`}
                />
                <IconButton
                  edge="end"
                  onClick={handleClickOpenWrapper}
                  className={classes.iconButton}
                  aria-label="descargar curva"
                >
                  <CloudDownloadIcon style={{ fontSize: 35 }} />
                </IconButton>
              </ListItem>
            </List>
          </Collapse>

          {index < resultItems.length - 1 ? (
            <Divider variant="inset" component="li" />
          ) : null}
        </Fragment>
      );
    else return <Fragment></Fragment>;
  };

  useEffect(() => {
    navigatorDispatch({
      type: "set-header",
      header: {
        title: t("navigation.buscadorCurvasMain"),
        icon: SupplyBotsIcon,
      },
    });
  }, [navigatorDispatch, t]);

  useEffect(() => {
    updateResultItems();
  }, [mongoGateway]);

  return (
    <Box>
      {/* Barra de busqueda */}
      <h4>Buscador:</h4>

      {loading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.progress} />
        </div>
      ) : (
        <Paper component="form" className={classes.root}>
          <TextField
            id="invoicenumber-search"
            className={classes.input}
            label="Numero de factura"
            type="search"
            onChange={(e) => {
              setInvoiceNumberSearch(e.target.value);
            }}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setInvoiceNumberSearch(e.target.value);
                updateResultItems();
              }
            }}
          />
          <TextField
            id="cups-search"
            className={classes.input}
            label="Numero de referencia / CUPS"
            type="search"
            onChange={(e) => {
              setInvoiceCupsSearchSearch(e.target.value);
            }}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setInvoiceCupsSearchSearch(e.target.value);
                updateResultItems();
              }
            }}
          />
          <TextField
            id="username-search"
            className={classes.input}
            label="Nombre Usuario en Credenciales"
            type="search"
            onChange={(e) => {
              setUsernameSearch(e.target.value);
            }}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault();
                setUsernameSearch(e.target.value);
                updateResultItems();
              }
            }}
          />
        </Paper>
      )}

      {resultItems && <h3>Resultado (Máx 10): {resultItems.length}</h3>}
      {loading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.progress} />
        </div>
      ) : null}

      {isEmpty ? (
        <AlertBox variant="info">No se han encontrado registros</AlertBox>
      ) : null}
      <List>
        {resultItems && resultItems.length > 0
          ? resultItems.map(renderItem)
          : null}
      </List>
    </Box>
  );
};

export default withRouter(CurvesList);
