import React, { FC, useEffect, useMemo, useState, useContext } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { makeStyles, CircularProgress, Box } from "@material-ui/core";
import UserIcon from "@material-ui/icons/Person";
import { useTranslation } from "react-i18next";
import SaveIcon from "@material-ui/icons/Save";
import { initialClientDto, IClient } from "../../gateways/clients.interfaces";
import useIoC from "../../contexts/ioc.context";
import { ClientsGateway } from "../../gateways/clients.gateway";
import { useSnackbar } from "notistack";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import ClientForm from "./client-form";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import PeopleIcon from "@material-ui/icons/People";
import ClientsDrawer from "../../components/clients-drawer";
import { ClientContext } from "./client-context";
import { useRouteMatch } from "react-router-dom";
import ClientPeekDialog from "./client-peek-dialog";
import { ClientTypesGateway } from "../../gateways/clients-types.gateway";
import { IClientTypes } from "../../gateways/clients-types.interfaces";
import { BlacklistGateway } from "../../gateways/blacklist.gateway";
import { obtainDomainFromEmail } from "../../utils/utils";

interface IClientDetailRouteParams {
  clientId: string;
}

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  itemAvatarIcon: {
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
}));

const ClientDetail: FC <RouteComponentProps>= ({history}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const match = useRouteMatch();
  const [clientState, clientStateDispatch] = useContext(ClientContext);
  const clientGateway = useIoC(ClientsGateway);
  const clientTypesGateway = useIoC(ClientTypesGateway);
  const blacklistGateway = useIoC(BlacklistGateway);
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();
  const [peekDialogOpen, setPeekDialogOpen] = useState(false);
  const [clientTypes, setClientTypes] = useState<IClientTypes[]>([]);
  const { update: updateBreadCrumbName } = useBreadCrumbName();

  const client = clientState.client; 
  let blacklisted = false;
  if(client && client.cancelledDate !== null) blacklisted = true;
  const [isBlacklisted, setIsBlacklisted] = useState<boolean>(blacklisted);
  const handlePeekDialogOpen = () => setPeekDialogOpen(true);
  const handlePeekDialogClose = () => setPeekDialogOpen(false);

  useEffect(() => {
    (async () => {
      const clientTypes = await clientTypesGateway.findAll();
      setClientTypes(clientTypes);
    })();
  }, []);

  const handleClientState = async () => {

    if(isBlacklisted){
      if(client){
        updateBreadCrumbName('clients', client.fullname);
        client.cancelledDate = null;
        const email = await clientGateway.findClientEmail(client.id);
        if(!!email) await blacklistGateway.deleteEmail(email);
      }
      snackbar.enqueueSnackbar('Se ha reestablecido el cliente correctamente', {
        variant: 'success',
      });
    }
    else{
      if(client){
        client.cancelledDate = new Date();
        updateBreadCrumbName('clients', client.fullname+" (Cancelado)");
        
        const clientEmail = await clientGateway.findClientEmail(client.id);
        if(!!clientEmail) await blacklistGateway.insertEmail({email: clientEmail, cancelledDate: new Date()});
      } 
      snackbar.enqueueSnackbar('Se ha descartado el cliente correctamente', {
        variant: 'success',
      });
    }

    handleSave();
    setIsBlacklisted(!isBlacklisted);
  }

  const handleSave = () => {
    if (client === null) {
      return;
    }

    if (
      !client.fullname
      //  || !client.address
    ) {
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      return;
    }

    let input_domain = obtainDomainFromEmail(client.client_email);
    let forceRefresh = false;
    if (typeof input_domain !== 'undefined' && (typeof client.client_email_domain === undefined || client.client_email_domain === ''))
    {
      //sugerimos auto fill
      if (window.confirm('No hay definido un dominio por defecto, quieres definir el detectado en el primer correo de cliente: [' + input_domain + '] ?') == true)
      {
        client.client_email_domain = input_domain;
        forceRefresh = true;
      }
    } else {
      //verificamos que el domain coincida
      if(typeof input_domain !== 'undefined' && client.client_email_domain !== input_domain)
      {
        //es diferente
        if (window.confirm('El dominio definido no coincide con el primer correo de cliente, quieres definir el detectado: [' + input_domain + '] ?') == true)
        {
          client.client_email_domain = input_domain;
          forceRefresh = true;
        }
      }
    }
    
    (async () => {
      try {
        let result = await clientGateway.update(client.id.toString(), client);

        if (typeof result.message !== 'undefined')
        {
          snackbar.enqueueSnackbar(result.message, { variant: "error" });
          return;
        }
        else
          result = result as IClient;

        snackbar.enqueueSnackbar(
          t("clientDetail.updateSucceded", {
            id: client.id,
            name: client.fullname,
          }),
          {
            variant: "success",
          }
        );

        if (forceRefresh)
        {
          history.push("/clients/" + client.id);
        }

      } catch (e) {
        const er = (e as any);
        if (er.response.data.key === "updatingError") {
          const key = "clientsErrorHandler." + er.response.data.key;
          const message =
            "validation" in er
              ? er.validation
              : t(key, { id: er.response.data.id });
          snackbar.enqueueSnackbar(message, { variant: "error" });
        } else {
          const message =
            "validation" in er ? er.validation : t("messages.defaultError");
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      }
    })();
  };

  const validateRequired = (value: any) => {
    return value !== null && value !== undefined && value !== "" && value !== 0;
  };

  const handleChange = (name: string, value: any) => {
    if (client === null) {
      return;
    }
    const updatedClient: IClient = { ...client, [name]: value } as any;
    clientStateDispatch({ type: "update-client", payload: updatedClient });
    updateBreadCrumbName("clients", updatedClient.fullname);
  };

  if (client === null) {
    return <CircularProgress className={classes.progress} />;
  }

  return (
    <Box>
      <ScreenGlobalStructure
        buttonIcon={<SaveIcon />}
        headerIcon={UserIcon}
        onButtonClick={handleSave}
        title={client.fullname}
        clientId={client.id.toString()}
        matchUrl={match.url}
        loading={loading}
        drawer={
          <ClientsDrawer
            matchUrl={match.url}
            lateralMenu={0}
            icon={
              <PeopleIcon fontSize="large" className={classes.itemAvatarIcon} />
            }
            goBack={false}
          />
        }
        onPeekButtonClick={handlePeekDialogOpen}
        onBlacklistButtonClick={handleClientState}
        blacklisted={isBlacklisted}
        >
        <ClientForm
          onChange={handleChange}
          item={client}
          validateRequired={validateRequired}
          clientTypes={clientTypes}
        />
      </ScreenGlobalStructure>
      <ClientPeekDialog
        open={peekDialogOpen}
        onClose={handlePeekDialogClose}
        client={client}
      />
    </Box>
  );
};

export default ClientDetail;
