import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IEstablishmentType, ICreateEstablishmentType, IUpdateEstablishmentType } from "./establishment-type.interface";

@inject('axios')
export class EstablishmentTypeGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IEstablishmentType[]> {
        const response = await this.axios.get('establishment-type');
        return response.data;
    }

    async findById(id: number): Promise<IEstablishmentType> {
        const response = await this.axios.get(`establishment-type/${id}`);
        return response.data;
    }

    async create(establishmentType: ICreateEstablishmentType): Promise<IEstablishmentType> {
        const response = await this.axios.post('establishment-type', establishmentType);
        return response.data;
    }

    async update(id: number, partialEstablishmentType: Partial<IUpdateEstablishmentType>): Promise<IEstablishmentType> {
        const response = await this.axios.put(`establishment-type/${id}`, partialEstablishmentType);
        return response.data;
    }

    async delete(id: number): Promise<void> {
        const response = await this.axios.delete(`establishment-type/${id}`);
        return response.data;
    }
}