import { FC, useState } from "react";
import ISector from "../../gateways/sector.interfaces";
import { makeStyles, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import React from "react";
import SectorIcon from '@material-ui/icons/AccountTree';
import UpdateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import SectorUpdateDialog from "./sector-update-dialog";
import SectorDeleteDialog from "./sector-delete-dialog";

interface ISectorItemProps {
    item: ISector;
    onItemUpdate: (item: ISector) => void;
    onItemDelete: (id: number) => void;
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

const SectorItem: FC<ISectorItemProps> = (props) => {
    const classes = useStyles();
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleClickDeleteButton = () => {
        setOpenDeleteDialog(true);
    };

    const handleClickUpdateButton = () => {
        setOpenUpdateDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
    };

    return (
        <>
            <ListItem alignItems="flex-start" className={classes.listItem}>
                <ListItemAvatar >
                    <SectorIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
                </ListItemAvatar>
                <ListItemText primary={props.item.name} />
                <ListItemSecondaryAction>
                    <IconButton onClick={handleClickUpdateButton} aria-label="update"><UpdateIcon /></IconButton>
                    <IconButton onClick={handleClickDeleteButton} aria-label="delete"><DeleteIcon /></IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <SectorUpdateDialog
                open={openUpdateDialog}
                item={props.item}
                onItemUpdate={props.onItemUpdate}
                onClose={handleCloseUpdateDialog} />
            <SectorDeleteDialog
                open={openDeleteDialog}
                id={props.item.id}
                onItemDelete={props.onItemDelete}
                onClose={handleCloseDeleteDialog} />
        </>
    );
};

export default SectorItem;