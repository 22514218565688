import { Reducer } from "react";
import createGlobalReducerContext from "./generic.context";
import * as crypto from "crypto";

interface UserState {
  token: string | null;
  user: string | null;
  roles: string[] | null;

  //main menu
  canViewBotsMenu: boolean;
  canViewHiringBoard: boolean;
  canViewClientsList: boolean;
  canViewProvidersList: boolean;
  canViewMaintenanceMenu: boolean;
  canViewESM_Menu: boolean;
  canViewCurvesMenu: boolean;
  canViewManualInvoice: boolean;

  //sub menus
  subCanViewBotMissingDate: boolean;
  subCanViewSignatureGenerator: boolean;
  subCanViewMaintenanceRestOfItems: boolean;
  subCanViewCoordenadas: boolean;
  subCanViewOneDriveConverter: boolean;
}

type UserAction =
  | { type: "terminate-session" }
  | { type: "set-token"; token: string }
  | { type: "set-user"; user: string }
  | { type: "set-role"; role: string[] };

const reducer: Reducer<UserState, UserAction> = (state, action) => {
  switch (action.type) {
    case "set-token":
      return setUserToken(state, action.token);
    case "set-user":
      return setUsername(state, action.user);
    case "set-role":
      return setRole(state, action.role);
    case "terminate-session":
      return setUserToken(state, null);
    default:
      throw new Error("Unexpected action");
  }
};

const setUsername = (state: UserState, user: string | null) => {
  const newState = { ...state, user };

  if (user) {
    localStorage.setItem("user.username", user);
  }

  return newState;
};

const setUserToken = (state: UserState, token: string | null) => {
  const newState = { ...state, token };

  if (token) {
    localStorage.setItem("user.token", token);
  } else {
    localStorage.removeItem("user.token");
    newState.user = null;
  }
  return newState;
};

const setRole = (state: UserState, role: string[] | null) => {
  const newState = { ...state, role };

  if (role) {
    localStorage.setItem("user.role", role.toString());
  }

  return newState;
};

const FindRole = (role: string) => {
  //disabled roles until finish/fix
  //return true;

  //disabled due error in dependencies when adding a new lib

  // const original:any = localStorage.getItem('user.role') ?? {};
  // if (original === '' || typeof original.rl === 'undefined' || typeof original.rh === 'undefined') return false;
  // const rolesList = base64url.fromBase64(original);
  // if (rolesList === '') return false;

  // //check hash
  // const base64Digest = crypto
  //   .createHash('sha256')
  //   .update(rolesList)
  //   .digest('base64');

  // const code_challenge = base64url.fromBase64(base64Digest);

  // if (code_challenge === '') return false;

  // if (original.rh !== code_challenge) return false;
  //return (rolesList.toLowerCase().split(',') ?? []).includes(role.toLowerCase());

  return (
    localStorage.getItem("user.role")?.toLowerCase().split(",") ?? []
  ).includes(role.toLowerCase());
};

// ### Role definitions ###

const CanViewAll = () => {
  return FindRole("ebm.developer") || FindRole("ebm.manager");
};

const CanViewAnyDep = () => {
  return (
    CanViewAll() ||
    FindRole("ebm.administration-head") ||
    FindRole("ebm.administration") ||
    FindRole("ebm.operational-head") ||
    FindRole("ebm.operational-basic") ||
    FindRole("ebm.operational") ||
    FindRole("ebm.marketing-head") ||
    FindRole("ebm.marketing") ||
    FindRole("ebm.mce-head") ||
    FindRole("ebm.mce") ||
    FindRole("ebm.projects-head") ||
    FindRole("ebm.projects") ||
    FindRole("ebm.sda-head") ||
    FindRole("ebm.sda")
  );
  // FindRole('ebm.ma-head') ||
  // FindRole('ebm.ma');
};

// General Main Menu Items
const CanViewBotsMenu = () => {
  return FindRole("ebm.operational-head") || CanViewAll();
};

//bots => Visor Curvas
const CanViewCurvesMenu = () => {
  return (
    FindRole("ebm.operational-head") ||
    FindRole("ebm.operational") ||
    FindRole("ebm.operational-basic") ||
    CanViewAll()
  );
};

const CanViewHiringBoard = () => {
  return CanViewAll();
};

const CanViewClientsList = () => {
  return (
    FindRole("ebm.operational") ||
    FindRole("ebm.operational-head") ||
    CanViewAll()
  );
};

const CanViewProvidersMenuList = () => {
  return FindRole("ebm.operational-head") || CanViewAll();
};

const CanViewMaintenanceMenu = () => {
  return CanViewAnyDep();
};

const CanViewESM_Menu = () => {
  return FindRole("ebm.marketing-head") || CanViewAll();
};

const CanViewManualInvoice = () => {
  return (
    FindRole("ebm.operational") ||
    FindRole("ebm.operational-basic") ||
    FindRole("ebm.operational-head") ||
    CanViewAll()
  );
};

// subItems =>

// MaintenanceMenu - Facturas sin Fecha
const SubCanViewBotMissingDate = () => {
  return (
    FindRole("ebm.operational-head") ||
    FindRole("ebm.administration") ||
    CanViewAll()
  );
};

// MaintenanceMenu - Generador de firmas
const SubCanViewSignatureGenerator = () => {
  return CanViewAnyDep();
};

// MaintenanceMenu - RestOfItems
const SubCanViewMaintenanceRestOfItems = () => {
  return CanViewAll();
};

const SubCanViewCoordenadas = () => {
  return (
    FindRole("ebm.operational") ||
    FindRole("ebm.operational-head") ||
    CanViewAll()
  );
};

const SubCanViewOneDriveConverter = () => {
  return (
    FindRole("ebm.operational-basic") ||
    FindRole("ebm.operational") ||
    FindRole("ebm.operational-head") ||
    CanViewAll()
  );
};

// ########################

const [useUser, UserProvider] = createGlobalReducerContext(reducer, {
  token: localStorage.getItem("user.token"),
  user: localStorage.getItem("user.username"),
  roles: localStorage.getItem("user.role")?.split(",") ?? [],
  canViewBotsMenu: CanViewBotsMenu(),
  canViewCurvesMenu: CanViewCurvesMenu(),
  canViewHiringBoard: CanViewHiringBoard(),
  canViewClientsList: CanViewClientsList(),
  canViewProvidersList: CanViewProvidersMenuList(),
  canViewMaintenanceMenu: CanViewMaintenanceMenu(),
  canViewESM_Menu: CanViewESM_Menu(),
  canViewManualInvoice: CanViewManualInvoice(),
  subCanViewBotMissingDate: SubCanViewBotMissingDate(),
  subCanViewSignatureGenerator: SubCanViewSignatureGenerator(),
  subCanViewMaintenanceRestOfItems: SubCanViewMaintenanceRestOfItems(),
  subCanViewCoordenadas: SubCanViewCoordenadas(),
  subCanViewOneDriveConverter: SubCanViewOneDriveConverter(),
});

export { useUser, UserProvider };
