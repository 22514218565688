import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import ISector from "./sector.interfaces";

@inject('axios')
export class SectorGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<ISector[]> {
        const response = await this.axios.get('sectors');
        return response.data;
    }

    async create(sector: Partial<ISector>): Promise<ISector> {
        const response = await this.axios.post('sectors', sector);
        return response.data;
    }

    async update(id: number, sector: Partial<ISector>): Promise<ISector> {
        const url = `sectors/${id}`;
        const response = await this.axios.put(url, sector);
        return response.data;
    }

    async delete(id: number): Promise<void> {
        const url = `sectors/${id}`;
        const response = await this.axios.delete(url);
        return response.data;
    }
}