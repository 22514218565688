import React, { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ClientPeekElectricSupplyForm from "./client-peek-form-electric-supply";
import ClientPeekFuelSupplyForm from "./client-peek-form-fuel-supply";
import ClientPeekEstablishmentForm from "./client-peek-form-establishments";
import useIoC from "../../contexts/ioc.context";
import { ComplexGateway } from "../../gateways/complex.gateway";
import { IComplex } from "../../gateways/complex.interfaces";
import { makeStyles, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { TreeItem } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
    item: {
        padding: 0,
        margin: 0,
        fontWeight: 'normal'
    }
}));

interface IClientPeekComplexFormProps {
    clientId: string;
    companyId?: string;
}

const ClientPeekComplexFormProps: FC<IClientPeekComplexFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [data, setData] = useState<IComplex[]>([]);
    const complexGateway = useIoC(ComplexGateway);
    const history = useHistory();
    let nodeTreeId = `${props.clientId}/${props.companyId ? `/company/${props.companyId}` : ''}/complex/`;
    
    const computeUrl = (item: IComplex): string => {
        if (props.companyId) {
            return (`${props.clientId}/companies/${props.companyId}/complex/${item.id}`);
        } else {
            return (`${props.clientId}/complex/${item.id}`);
        }
    }

    useEffect(() => {
        (async () => {
            if (!props.companyId) {
                const data = await complexGateway.findByClientId({clientId: Number.parseInt(props.clientId)});
                setData(data);
            } else {
                const companyId = parseInt(props.companyId, 10);
                const data = await complexGateway.findAll({ companyId });
                setData(data)
            }
        })();
    }, [complexGateway]);

    const handleStopPropagation = useCallback( (event: React.MouseEvent) => {
        event.stopPropagation();               
    }, [])

    return (
        <Box>
            {
                data.length > 0 &&
                <TreeItem nodeId={nodeTreeId} label={t('rightNavigation.complexes')}>
                    {
                        data.map((item) => {
                            return (
                                <TreeItem nodeId={nodeTreeId + item.id.toString()}  label={<a onClick={handleStopPropagation} href={computeUrl(item)} target="_blank">{item.name}</a>}>
                                    <ClientPeekEstablishmentForm
                                        clientId={props.clientId}
                                        companyId={props.companyId ? props.companyId : undefined}
                                        complexId={item.id.toString()}
                                    /><ClientPeekElectricSupplyForm
                                        complexId={item.id.toString()}
                                        currentUrl={computeUrl(item)}
                                    />
                                    <ClientPeekFuelSupplyForm
                                        complexId={item.id.toString()}
                                        currentUrl={computeUrl(item)}
                                    />
                                </TreeItem>
                            )
                        })
                    }
                </TreeItem>
            }
        </Box>
    );
};

export default ClientPeekComplexFormProps;