import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import {
  IWaterSupplyInterface,
  IWaterSupplyInterfaceUpsert,
} from "./water-supply.interfaces";
import querystring from "querystring";

type FindFilterWaterSupply = {
  establishmentId?: string;
  complexId?: string;
  referencia?: string;
};

@inject("axios")
export class WaterSupplyGateway {
  constructor(private axios: AxiosInstance) {}

  public async findAll(
    filter?: FindFilterWaterSupply
  ): Promise<IWaterSupplyInterface[]> {
    let url = "water-supply";
    if (filter) {
      const query = querystring.stringify(filter);
      url += `?${query}`;
    }
    const response = await this.axios.get(url);
    return response.data;
  }

  public async findById(id: number): Promise<IWaterSupplyInterface> {
    const response = await this.axios.get(`water-supply/${id}`);
    return response.data;
  }

  public async findOneById(id: number): Promise<IWaterSupplyInterface[]> {
    const response = await this.axios.get(`water-supply/findOneById/${id}`)
    return response.data;
  }

  public async getUrl(cups: string): Promise<string> {
    const response = await this.axios.get(`water-supply/getUrl/${cups}`);
    return response.data;
}

  public async create(
    dto: IWaterSupplyInterfaceUpsert
  ): Promise<IWaterSupplyInterface> {
    const response = await this.axios.post("water-supply", dto);
    return response.data;
  }

  public async update(
    id: number,
    dto: IWaterSupplyInterfaceUpsert
  ): Promise<IWaterSupplyInterface> {
    const response = await this.axios.put("water-supply/" + id, dto);
    return response.data;
  }

  public async getWaterComplexSupplies(id: number){
    const response = await this.axios.get(`water-supply/allComplexWaterSupplies/${id}`);
    return response.data;
  }

  async delete(id: number): Promise<void> {
    const response = await this.axios.delete(`water-supply/${id}`);
    return response.data;
  }
}
