import React, { useEffect, useState, useContext } from "react";
import { useParams, useRouteMatch } from "react-router";
import { makeStyles, Box, CircularProgress } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import ComplexIcon from "@material-ui/icons/Dashboard";
import { useTranslation } from "react-i18next";
import { IComplex, IUpdateComplex } from "../../gateways/complex.interfaces";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import ICompany from "../../gateways/company.interface";
import IRegion from "../../gateways/region.interface";
import ICountry from "../../gateways/country.interfaces";
import CountryGateway from "../../gateways/country.gateway";
import { ComplexGateway } from "../../gateways/complex.gateway";
import { CompanyGateway } from "../../gateways/company.gateway";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import ComplexForm from "./complex-form";
import ComplexDrawer from "../../components/complex-drawer";
import CountryUpsertDialog from "../countries/country-upsert-dialog";
import CompanyAddDialog from "../companies/company-add-dialog";
import { ComplexContext } from "./complex-context";
import CoordinatesGateway from "../../gateways/coordinates.gateway";
import {
  ICoordinatesReturn,
  initialCoordinatesReturn,
} from "../../gateways/coordinates.interfaces";
import { ClientsGateway } from "../../gateways/clients.gateway";

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  itemAvatarIcon: {
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
}));

const initialCompanies: ICompany[] = [];

const ComplexDetail = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const snackbar = useSnackbar();

  const { complexId } = useParams() as any;
  const { clientId } = useParams() as any;
  const { companyId } = useParams() as any;

  const { url } = useRouteMatch();

  const [ loading, setLoading] = useState(false);
  const [complexState, complexStateDispatch] = useContext(ComplexContext);
 
  const complex = complexState.complex;
  const blacklisted = complex && complex.cancelledDate !== null ? true: false;
  const [isBlacklisted, setIsBlacklisted] = useState<boolean | undefined>(blacklisted);

  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [regions, setRegions] = useState<IRegion[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [coordinates, setCoordinates] = useState<string>("");
  const { update: updateBreadCrumbName } = useBreadCrumbName();

  
  const complexGateway = useIoC(ComplexGateway);
  const companyGateway = useIoC(CompanyGateway);
  const countryGateway = useIoC(CountryGateway);
  const coordinatesGateway = useIoC(CoordinatesGateway);
  const clientsGateway = useIoC(ClientsGateway);
  const [createCountry, setCreateCountry] = useState(false);
  const [createCompany, setCreateCompany] = useState(false);
  const [ direction, setDirection ] = useState("");

  const handleComplexState = () => {
    if(complex){
      if(isBlacklisted){
        updateBreadCrumbName('complex', complex.name);
        complex.cancelledDate = null;
        snackbar.enqueueSnackbar('Se ha reestablecido el complejo correctamente', {
          variant: 'success',
        });
      }
      else{
        complex.cancelledDate = new Date();
        updateBreadCrumbName('complex', complex.name+" (Cancelado)");
        snackbar.enqueueSnackbar('Se ha descartado el complejo correctamente', {
          variant: 'success',
        });
      }

      handleSave();
      setIsBlacklisted(!isBlacklisted);
    }
  }

  const handleClickAddCountryButton = () => {
    setCreateCountry(true);
  };

  const handleCloseAddCountryButton = () => {
    setCreateCountry(false);
  };

  const handleClickAddCompanyButton = () => {
    setCreateCompany(true);
  };

  const handleCloseAddCompanyButton = () => {
    setCreateCompany(false);
  };

  const handleCreateCountry = (item: ICountry, isNew: boolean) => {
    setCountries([...countries, item]);
  };

  const handleCreateCompany = (item: ICompany) => {
    if (!companyId) {
      setCompanies([...companies, item]);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (!complexId || !complex) {
        return;
      }

      if (complex.coordinatesId) {
        const initialCoordiantes = await coordinatesGateway.findById(
          complex.coordinatesId
        );
        setCoordinates(
          `${initialCoordiantes.latitude}, ${initialCoordiantes.longitude}`
        );
      }

      const regions = complexId !== null ? await complexGateway.findByIdAllRegions(
        Number.parseInt(complexId as any)
      ) : [];
      setRegions(regions);

      const countries = await countryGateway.findAll();
      setCountries(countries);

      setDirection(complex.direction);

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {

      if(!complex) return;
      setLoading(true);
      const response = await complexGateway.findOneById(complex.id);
      if(response.length === 0) setIsBlacklisted(undefined);

      if (!companyId) {
        const companies = clientId ? 
        await companyGateway.findAll({ clientId: parseInt(clientId as any, 10) })
        : [];
        setCompanies(companies);
      } else {
        const company = await companyGateway.findById(companyId as any);

        setCompanies([...initialCompanies, company]);
      }
      setLoading(false);
    })();
  }, [companyGateway]);

  const handleSave = () => {
    if (complex === null) {
      return;
    }
    if (
      !complex.name ||
      // !complex.direction ||
      !complex.companyId ||
      !complex.countryId
      // !coordinates
    ) {
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      return;
    }

    (async () => {
      try {
        if (!complexId) {
          return;
        }

        let updatedCoordinates = coordinates;
        let changeCoordinates = '';

        if (direction !== '')
        {
          const addressCoordinates = await coordinatesGateway.getCoordinates(direction);
          changeCoordinates = `${addressCoordinates.lat}, ${addressCoordinates.lng}`
        }
        
        setCoordinates(changeCoordinates);
        updatedCoordinates = changeCoordinates;
        
        const regex = /^(?<latitude>[^,]+),[\s]+(?<longitude>[^,]+)$/;
        const coordinatesRegex = regex.exec(updatedCoordinates);
        const createdCoordinates: ICoordinatesReturn =
          coordinatesRegex && coordinatesRegex.groups
            ? await coordinatesGateway.create({
                latitude: parseFloat(coordinatesRegex.groups.latitude),
                longitude: parseFloat(coordinatesRegex.groups.longitude),
              })
            : initialCoordinatesReturn;
        const complexToUpdate: IUpdateComplex = {
          companyId: complex.companyId,
          countryId: complex.countryId,
          direction: complex.direction,
          name: complex.name,
          regionsIds: complex.regionsIds,
          sendBeforeValidating: complex.sendBeforeValidating,
          cancelledDate: complex.cancelledDate
        };
        await complexGateway.update(Number.parseInt(complexId as any), {
          ...complexToUpdate,
          regionsIds: regions.map((value) => {
            return value.id;
          }),
          coordinatesId: createdCoordinates.id,
        });

        snackbar.enqueueSnackbar(
          t("clientDetail.updateSucceded", {
            id: Number.parseInt(complexId as any),
            name: complex.name,
          }),
          {
            variant: "success",
          }
        );
      } catch (e) {
        const er = (e as any);
        if (er.response.data.key === "updatingError") {
          const key = "complexErrorHandler." + er.response.data.key;
          const message =
            "validation" in er
              ? er.validation
              : t(key, { id: er.response.data.id });
          snackbar.enqueueSnackbar(message, { variant: "error" });
        } else {
          const message =
            "validation" in er ? er.validation : t("messages.defaultError");
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      }
    })();
  };
  const handleChange = (name: string, value: any) => {
    if (complex === null) {
      return;
    }
    const updatedComplex: IComplex = { ...complex, [name]: value } as any;
    complexStateDispatch({ type: "update-complex", payload: updatedComplex });
    updateBreadCrumbName("complex", updatedComplex.name);
  };

  const handleChangeCountry = (name: string, value: any) => {
    handleChange(name, value);
    (async () => {
      const regions = await countryGateway.findAllRegions(value);
      setRegions(regions);
    })();
  };

  const handleDeleteChipRegion = (index: number) => {
    const copy = regions.filter((v, i) => i !== index);
    setRegions(copy);
  };
  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  if (complex === null) {
    return <CircularProgress className={classes.progress} />;
  }

  const handleChangeAddress = async (name: string, value: string) => {
    const addressCoordinates = await coordinatesGateway.getCoordinates(value);
    const changeCoordinates = `${addressCoordinates.lat}, ${addressCoordinates.lng}`
    handleChange(name, value);
    setCoordinates(changeCoordinates);
  }

  const handleSendBeforeTelemedidaChange = ()=>{
    handleChange('sendBeforeValidating', !complex.sendBeforeValidating)
  }

  
  const handleSetDirection = (name: string, value: string) => {
    handleChange(name, value);
    setDirection(value);
  }

  return (
    <Box>
      <ScreenGlobalStructure
        buttonIcon={<SaveIcon />}
        headerIcon={ComplexIcon}
        matchUrl={url}
        onButtonClick={handleSave}
        title={t("complexDetail.title")}
        clientId={clientId ?? ""}
        loading={loading}
        drawer={
          <ComplexDrawer
            matchUrl={url}
            lateralMenu={0}
            icon={
              <ComplexIcon
                fontSize="large"
                className={classes.itemAvatarIcon}
              />
            }
            goBack={false}
            contacts={true}
          />
        }
        onBlacklistButtonClick={handleComplexState}
        blacklisted={isBlacklisted}
      >
        <ComplexForm
          companies={companies}
          countries={countries}
          item={complex}
          onChange={handleChange}
          onChipRegionDelete={handleDeleteChipRegion}
          onCountryChange={handleChangeCountry}
          regions={regions}
          validateRequired={validateRequired}
          onClickAddCompanyButton={
            companyId ? undefined : handleClickAddCompanyButton
          }
          onClickAddCountryButton={handleClickAddCountryButton}
          coordinates={coordinates}
          handleChangeAddress={handleChangeAddress}
          handleSetDireccion={handleSetDirection}
          onSendBeforeTelemedidaChange={handleSendBeforeTelemedidaChange}
          largeXs={10}
          smallXs={2}
        />
      </ScreenGlobalStructure>
      <CountryUpsertDialog
        open={createCountry}
        onItemUpsert={handleCreateCountry}
        onClose={handleCloseAddCountryButton}
        item={undefined}
      />
      <CompanyAddDialog
        clientId={clientId ? parseInt(clientId as any) : 0}
        onClose={handleCloseAddCompanyButton}
        onItemCreated={handleCreateCompany}
        onLoading={() => loading}
        open={createCompany}
      />
    </Box>
  );
};

export default ComplexDetail;
