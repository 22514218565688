import {
  createStyles,
  ListItem,
  makeStyles,
  Theme
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tooltip from '@material-ui/core/Tooltip';
import React, { memo } from "react";
import { FC } from "react";
import CancelScheduleSendIcon from "@material-ui/icons/CancelScheduleSend";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import ErrorIcon from "@material-ui/icons/Error";
import SdCardAlertIcon from "@material-ui/icons/NoSim";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import { IOperateVariables } from "../../gateways/operate.interface";
import errorTypes from './errors/index'
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      height: "100%",
      borderSpacing: 1,
      padding: theme.spacing(1)
    },
    gridButtonItem: {
      marginTop: 28,
      marginRight: -76
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
          width: 240,
          flexShrink: 0,
      },
    },
    toolbar: theme.mixins.toolbar as CSSProperties,
    drawerPaper: {
        width: 240,
    },
    buttonItem: {
      margin: 10,
      borderSpacing: 1,
      padding: theme.spacing(1),
    },
    toolTipItem: {
      color: 'white',
      marginTop: 12,
      fontSize: 15,
      opacity: '0.6 !important',
    }
  })
);

interface IInfoBotFieldsProps {
  focus: boolean;
  operate: IOperateVariables;
  onChange: () => void;
  clientId: string;
  companyId: string;
  complexId: string;
  icon: JSX.Element;
}

const InfoBotFields: FC<IInfoBotFieldsProps> = memo((props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const errorTypeDefinition = props.operate.errorType in errorTypes
    ? errorTypes[props.operate.errorType]
    : null;

  return (
    <ListItem alignItems="flex-start">
      <Grid container direction="row" xs={12}>
        <Grid item xs={1} className={classes.gridButtonItem}>
          {
            props.operate.errorType.includes("errorNoCups") ?
            <Tooltip title="Error en la referencia" placement="bottom-start" arrow classes={{tooltip: classes.toolTipItem}}>
              <ErrorIcon style={{ fontSize: 28, color: "#e50000" }}/> 
            </Tooltip> :
            props.operate.errorType.includes("errorNoDate") ?
            <Tooltip title="Error en las fechas" placement="bottom-start" arrow classes={{tooltip: classes.toolTipItem}}>
              <EventBusyIcon style={{ fontSize: 28, color: "#e50000" }} /> 
            </Tooltip> :
            props.operate.errorType.includes("errorOneDriveFile") ?
            <Tooltip title="Error en la ruta de la carpeta de facturas" placement="bottom-start" arrow classes={{tooltip: classes.toolTipItem}}>
              <SdCardAlertIcon style={{ fontSize: 28, color: "#e50000" }} /> 
            </Tooltip> :
            props.operate.errorType.includes("errorOperativeMail") ?
            <Tooltip title="Error en el correo para las notificaciones de nuevas facturas" placement="bottom-start" arrow classes={{tooltip: classes.toolTipItem}}>
              <CancelScheduleSendIcon style={{ fontSize: 28, color: "#e50000" }}/>
            </Tooltip>
            :"null"
          }
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          <FormTextField
            fullWidth
            disabled
            label={t("dashboard.cups")}
            name="cups"
            validator={() => {
              return true;
            }}
            errorText={t("messages.requiredField") as string}
            type="text"
            value={props.operate.cups}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={1} className={classes.gridItem}>
          <FormTextField
            fullWidth
            disabled
            label={t("operateVariable.platform")}
            name="platform"
            validator={() => {
              return true;
            }}
            errorText={t("messages.requiredField") as string}
            type="text"
            value={props.operate.platform}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          <FormTextField
            fullWidth
            disabled
            label={t("common.username")}
            name="supplyType"
            validator={() => {
              return true;
            }}
            errorText={t("messages.requiredField") as string}
            type="text"
            value={props.operate.username}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          <FormTextField
            fullWidth
            disabled
            label={t("common.password")}
            name="password"
            validator={() => {
              return true;
            }}
            errorText={t("messages.requiredField") as string}
            type="text"
            value={props.operate.password}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={2} className={classes.gridItem}>
          <FormTextField
            fullWidth
            disabled
            label={t("common.supplyType")}
            name="password"
            validator={() => {
              return true;
            }}
            errorText={t("messages.requiredField") as string}
            type="text"
            value={props.operate.supplyType}
            onChange={props.onChange}
          />
        </Grid>
        <Grid item xs={1}>
        {
          errorTypeDefinition
            ? <errorTypeDefinition.rowActionComponent 
                operate={props.operate} 
                icon={props.icon} 
                clientId={props.clientId} 
                companyId={props.companyId}
                complexId={props.complexId}
            />
            : <p>Sin acciones programadas</p>
        }
        </Grid>
      </Grid>
    </ListItem>
  );
});

export default InfoBotFields;