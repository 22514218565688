import React, { FC, useContext, Dispatch, useMemo } from 'react';
import { IRepositoryQueryFilterDto, FilterOperator, IRepositoryQueryFilterOrDto } from '../../gateways/repository.interfaces';
import { Grid, IconButton, FormControl, InputLabel, Select, MenuItem, TextField, Link, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { StateContext, ReducerContext } from './context';
import { IFilterReducerAction } from './filters.reducer';
import { useTranslation } from 'react-i18next';
import { IFSTableColumn } from './interfaces';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));


interface IFilterItemDialogProps {
    filter: IRepositoryQueryFilterDto;
    index: number;
    filtersDispatch: Dispatch<IFilterReducerAction>;
}

const FilterItemDialog: FC<IFilterItemDialogProps> = ({ filter, index, filtersDispatch }) => {
    const state = useContext(StateContext);
    const dispatch = useContext(ReducerContext);
    const classes = useStyles();
    const { t } = useTranslation();

    const operators = useMemo(() => {
        let enumValues: Array<string> = [];

        for (let value in FilterOperator) {
            if (typeof FilterOperator[value] === 'number') {
                enumValues.push(FilterOperator[value]);
            }
        }

        return enumValues;
    }, []);

    const handleRemoveFilter = () => {
        filtersDispatch({ type: 'remove', payload: index });
    }

    const handleChangeProperty = (filter: IRepositoryQueryFilterDto) => {
        return (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
            filter.property = event.target.value as string;
            filtersDispatch({ type: 'forceUpdate' });
        };
    }

    const handleChangeOperator = (or: IRepositoryQueryFilterOrDto) => {
        return (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
            or.operator = event.target.value as FilterOperator;
            filtersDispatch({ type: 'forceUpdate' });
        };
    }

    const handleRemoveFilterOr = (filter: IRepositoryQueryFilterDto, orIndex: number) => {
        return () => {
            filter.ors.splice(orIndex, 1);
            filtersDispatch({ type: 'forceUpdate' });
        };
    }

    const handleAddFilterOr = (filter: IRepositoryQueryFilterDto) => {
        return () => {
            filter.ors.push({ operator: FilterOperator.equals, value: null });
            filtersDispatch({ type: 'forceUpdate' });
        }
    }

    const handleChangeValue = (or: IRepositoryQueryFilterOrDto) => {
        return (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
            or.value = event.target.value;
            filtersDispatch({ type: 'forceUpdate' });
        };
    }

    return (
        <Grid container spacing={1} key={'filter_' + index}>
            <Grid item xs={5}>
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item>
                        <IconButton aria-label="delete" size="small" color="secondary" onClick={handleRemoveFilter}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        <FormControl fullWidth>
                            <InputLabel id={'filter_property_label_' + index}>Propiedad</InputLabel>
                            <Select
                                labelId={'filter_property_label_' + index}
                                value={filter.property}
                                id={'filter_property_select_' + index}
                                onChange={handleChangeProperty(filter)}
                            >
                                {
                                    state.columns.filter(x => !!x.name).map((column) => (
                                        <MenuItem key={'property_' + column.name} value={column.name}>{t(column.label)}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                {
                    filter.ors.map((or, orIndex) => (
                        <Grid container spacing={1} >
                            <Grid item xs={3}>
                                <FormControl fullWidth>
                                    <InputLabel id={'filter_operator_label_' + orIndex}>Op.</InputLabel>
                                    <Select
                                        labelId={'filter_operator_label_' + orIndex}
                                        id={'filter_operator_select_' + orIndex}
                                        value={or.operator}
                                        onChange={handleChangeOperator(or)}
                                    >
                                        {
                                            operators.map(x => (
                                                <MenuItem value={x}>{t('fs_table.operator_' + x)}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item xs>
                                        <FormControl fullWidth>
                                            <TextField
                                                id={'filter_value_input_' + orIndex}
                                                margin="none"
                                                label="Valor"
                                                type="text"
                                                value={or.value}
                                                onChange={handleChangeValue(or)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            color="secondary"
                                            disabled={filter.ors.length <= 1}
                                            onClick={handleRemoveFilterOr(filter, orIndex)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                }
                <div style={{ textAlign: 'center', marginTop: 3 }}>
                    <Link onClick={handleAddFilterOr(filter)}>Añadir "o"</Link>
                </div>
            </Grid>
        </Grid>
    );
}

export default FilterItemDialog;