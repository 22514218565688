import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IRepositoryQueryDto, IRepositoryGroupType, ICreateGroupDto, IRepositoryDefinitionGroup } from "./repository.interfaces";

@inject('axios')
export class RepositoryGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async query(repositoryId: string, query: IRepositoryQueryDto): Promise<any[]> {
        const response = await this.axios.post(`repository/${repositoryId}/query`, query);
        return response.data;
    }

    public async count(repositoryId: string, query: IRepositoryQueryDto): Promise<number> {
        const response = await this.axios.post(`repository/${repositoryId}/count`, query);
        return response.data;
    }

    public async getDefinitionGroupTypes(repositoryId: string): Promise<IRepositoryGroupType[]> {
        const response = await this.axios.get(`repository/${repositoryId}/group-types`);
        return response.data;
    }

    public async getDefinitionGroups(repositoryId: string): Promise<IRepositoryDefinitionGroup[]> {
        const response = await this.axios.get(`repository/${repositoryId}/groups`);
        return response.data;
    }

    public async createGroup(repositoryId: string, dto: ICreateGroupDto): Promise<IRepositoryDefinitionGroup> {
        const response = await this.axios.post(`repository/${repositoryId}/create-group`, dto);
        return response.data;
    }
}