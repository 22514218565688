import React, { FC } from "react";
import { makeStyles, Theme, createStyles, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IRegisterFuelBillCreate } from "../../gateways/register-fuel-bill.interface";
import { IFuelContract } from "../../gateways/fuel-contract.interfaces";

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5)
        },
        button: {
            margin: theme.spacing(0.5),

        },
        textField: {
            margin: theme.spacing(0.5)
        },
        root: {
            display: 'flex',
            overflowY: 'hidden'
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 1,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        formControl: {
            marginBottom: '1rem',
            '& :last-child': {
                marginBottom: 0
            }
        },
        progress: {
            margin: theme.spacing(2),
        },

        gridItem: {
            borderSpacing: 2,
            padding: theme.spacing(1)
        }
    })
);

interface IRegisterFuelBillNetworkProps {
    item: IRegisterFuelBillCreate;
    handleStartingPeriodDateSelector: (value: any) => void;
    handleEndingPeriodDateSelector: (value: any) => void;
    handleChange: (name: string, value: any) => void;
    validateRequired: (value: any) => boolean;
    currency: string;
    fuelContract: IFuelContract;
    fuelContractInfo: (fuelContract: IFuelContract) => string;
}

const RegisterFuelBillNetwork: FC<IRegisterFuelBillNetworkProps> = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Grid
                    container
                    justify="flex-start"
                    alignItems="flex-start"
                >

                    <Grid item xs={6} className={classes.gridItem}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            margin="none"
                            fullWidth
                            id="startingPeriodDateSelector"
                            label={t('common.startingPeriodDate') as string}
                            value={props.item.startingPeriodDate}
                            onChange={props.handleStartingPeriodDateSelector}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={6} className={classes.gridItem}>
                        <KeyboardDatePicker
                            format="dd/MM/yyyy"
                            fullWidth
                            margin="none"
                            id="endingPeriodDateSelector"
                            label={t('common.endingPeriodDate') as string}
                            value={props.item.endingPeriodDate}
                            onChange={props.handleEndingPeriodDateSelector}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='fuelContractId'
                            label={t('common.electricContractId') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.fuelContractId}
                            onChange={props.handleChange}
                            fullWidth
                            disabled={true}
                            adornmentLabel={props.fuelContractInfo(props.fuelContract)}
                        />
                    </Grid>

                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='gnM3'
                            label={t('common.GN') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.gnM3}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={t('common.m3') as string}
                        />
                    </Grid>

                    <Grid item xs={4} className={classes.gridItem}>
                        <FormTextField
                            name='gnPCS'
                            label={t('common.GN') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.gnPCS}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={t('common.PCS') as string}
                        />
                    </Grid>

                    <Grid item xs={4} className={classes.gridItem}>
                        <FormTextField
                            name='gnKWHPCSOnly'
                            label={t('common.GN') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.gnKWHPCSOnly}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={`${t('common.KWhpcs') as string}`}
                        />
                    </Grid>

                    <Grid item xs={4} className={classes.gridItem}>
                        <FormTextField
                            name='gnKWHPCS'
                            label={t('common.GN') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.gnKWHPCS}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={`${props.currency}/${t('common.KWhpcs') as string}`}
                        />
                    </Grid>

                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='gnTF'
                            label={t('common.GN') as string + ' ' + t('common.TF') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.gnTF}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={props.currency}
                        />
                    </Grid>

                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='gnTV'
                            label={t('common.GN') as string + ' ' + t('common.TV') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.gnTV}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={props.currency}
                        />
                    </Grid>

                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='gnOtros'
                            label={t('common.GN') as string + ' ' + t('common.others') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.gnOtros}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={props.currency}
                        />
                    </Grid>

                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='importEquipmentRental'
                            label={t('common.importEquipmentRental') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.importEquipmentRental}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={props.currency}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='hydrocarbonsTax'
                            label={t('common.hydrocarbonsTax') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.hydrocarbonsTax}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={props.currency}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='gnTotal'
                            label={t('common.GN') as string + ' ' + t('common.total') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.gnTotal}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={props.currency}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='monthlyPayment'
                            label={t('common.monthlyPayment') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.monthlyPayment}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={props.currency}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default RegisterFuelBillNetwork;