export interface ISupplyTypeRO {
    id: number;
    name: string;
}

export enum SupplyType{
    Electrico = 1,
    Combustible,
    Agua
}
