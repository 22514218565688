import { ISupplyConnectionBot } from "./supply-connection-bot.interface";

export interface IFuelContract {
    id: number;
    providerId: number;
    signContractDate: Date;
    initSupplyDate: Date;
    duration: number;
    endSupplyDate: Date;
    term: number;
    cancelPriorNotice: number;
    modality: string;
    fuelSupplyRateId: number;
    fixStipulation: number;
    variableStipulation: number;
    yearlyStipulation: number;
    variableA: number;
    variableB: number;
    variableC: number;
    fuelSupplyId: number;
    supplyConnectionBot?: ISupplyConnectionBot;
    accountNumber: string | null;
    accountNumber_ebmUser: string | null ;
    accountNumber_date: Date | null;
}


export const initialIFuelContract: IFuelContract = {
    id: 0,
    providerId: 0,
    signContractDate: new Date(),
    initSupplyDate: new Date(),
    duration: 0,
    endSupplyDate: new Date(),
    term: 0,
    cancelPriorNotice: 0,
    modality: '',
    fuelSupplyRateId: 0,
    fixStipulation: 0,
    variableStipulation: 0,
    yearlyStipulation: 0,
    variableA: 0,
    variableB: 0,
    variableC: 0,
    fuelSupplyId: 0,
    accountNumber: null,
    accountNumber_ebmUser: null,
    accountNumber_date: null
}

export interface IFuelContractAccountLogs {
    id: number;
    contractReferenceId: number;
    accountNumber: string;
    editionDate: Date;
    editorUsername: string;
}
export interface IFuelContractCreate {
    providerId: number;
    signContractDate: Date;
    initSupplyDate: Date;
    duration: number;
    endSupplyDate: Date;
    term: number;
    cancelPriorNotice: number;
    modality: string;
    fuelSupplyRateId: number;
    fixStipulation: number;
    variableStipulation: number;
    yearlyStipulation: number;
    variableA: number;
    variableB: number;
    variableC: number;
    fuelSupplyId: number;
    accountNumber: string | null;
}

export interface IFuelContractUpdate {
    providerId: number;
    signContractDate: Date;
    initSupplyDate: Date;
    duration: number;
    endSupplyDate: Date;
    term: number;
    cancelPriorNotice: number;
    modality: string;
    fuelSupplyRateId: number;
    fixStipulation: number;
    variableStipulation: number;
    yearlyStipulation: number;
    variableA: number;
    variableB: number;
    variableC: number;
    fuelSupplyId: number;
}


export interface IFuelContractItem {
    id: number;
    provider: string;
    signContractDate: Date;
    initSupplyDate: Date;
    duration: number;
    endSupplyDate: Date;
    term: number;
    cancelPriorNotice: number;
    modality: string;
    fuelSupplyRate: string;
    fixStipulation: number;
    variableStipulation: number;
    yearlyStipulation: number;
    supplyConnectionBot?: ISupplyConnectionBot;
}
