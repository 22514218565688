import React, { FC, useState, useMemo, useEffect } from 'react';
import CenteredCircularProgress from '../../components/centered-circular-progress';
import AlertBox from '../../components/alert-box';
import { List, Divider, Box, Fab, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ICurrency from '../../gateways/currency.interface';
import { useTranslation } from 'react-i18next';
import CurrencyItem from './currency-item';
import CurrencyUpsertDialog from './currency-upsert-dialog';
import useIoC from '../../contexts/ioc.context';
import CurrencyGateway from '../../gateways/currency.gateway';
import CurrencyIcon from '@material-ui/icons/MonetizationOn';
import { useNavigator } from '../../contexts/navigator.context';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    }
}));

const CurrencyList: FC = () => {
    const classes = useStyles();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState<ICurrency[]>([]);
    const [openUpsertDialog, setOpenUpsertDialog] = useState(false);
    const [item, setItem] = useState<ICurrency>();
    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);
    const { t } = useTranslation();
    const currencyGateway = useIoC(CurrencyGateway);
    const [, navigatorDispatch] = useNavigator();

    const handleClickFabButton = () => {
        setItem(undefined);
        setOpenUpsertDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenUpsertDialog(false);
    };

    const handleClickUpdateButtonItem = (item: ICurrency) => {
        setItem(item);
        setOpenUpsertDialog(true);
    };

    const handleItemUpsert = (item: ICurrency, isNew: boolean) => {
        let copy = data.slice();
        if (isNew) {
            copy = [item, ...data];
        } else {
            const index = data.findIndex(value => value.id === item.id);
            copy[index] = item;
        }
        setData(copy);
    };

    useEffect(() => {
        (async () => {
            (async () => {
                setIsDataLoading(true);
                const data = await currencyGateway.findAll();
                setData(data);
                setIsDataLoading(false);
            })();
        })();
    }, [currencyGateway]);

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('currencyList.title'),
                icon: CurrencyIcon
            }
        });
    }, []);

    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />;
        }

        if (isDataEmpty) {
            return <AlertBox variant='info'>{t('common.noItems')}</AlertBox>;
        }

        return (
            <List>
                {data.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <CurrencyItem item={item} onClickUpdateButton={handleClickUpdateButtonItem} />
                            {index < data.length - 1 ? <Divider variant='inset' component='li' /> : null}
                        </div>
                    );
                })}
            </List>
        );
    };

    return (
        <Box>
            <Fab color='primary' aria-label='add' className={classes.fab} onClick={handleClickFabButton}>
                <AddIcon />
            </Fab>
            <Content />
            <CurrencyUpsertDialog
                open={openUpsertDialog}
                item={item}
                onItemUpsert={handleItemUpsert}
                onClose={handleCloseDialog} />
        </Box>
    );
};

export default CurrencyList;