import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IRepositoryValueType } from "./repository-value-type.interface";

const repositoryDefinition = 'repository-value-type';
@inject('axios')
export class RepositoryValueTypeGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IRepositoryValueType[]> {
        const response = await this.axios.get(repositoryDefinition);
        return response.data;
    }
}