import React, { FC } from "react";
import { makeStyles, ListItem, ListItemText, ListItemAvatar, Tooltip, ListItemSecondaryAction, ListItemIcon } from "@material-ui/core";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useTranslation } from "react-i18next";
import { IFuelContractItem } from "../../gateways/fuel-contract.interfaces";
import ComponentIcon from "../../components/component-icon";
import { ISupplyConnectionBot } from "../../gateways/supply-connection-bot.interface";
import moment from "moment";

interface IFuelContractItemProps {
    item: IFuelContractItem;
    onClickItem: (item: IFuelContractItem) => void;
}

const useStyles: any = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));
const FormatDate = (dateToFormat: Date) => {
    const { t } = useTranslation();
    return (dateToFormat ? `${moment(dateToFormat).format('DD/MM/YYYY')}` : t('common.undefinedDate'));

};

const AdditionalInfo = (item: IFuelContractItem) => {
    const { t } = useTranslation();

    return t('common.supplyInitDateSelector') + ': ' + FormatDate(item.initSupplyDate) + ' - ' +
        t('common.supplyEndDateSelector') + ': ' + FormatDate(item.endSupplyDate);

}
const FuelContractItem: FC<IFuelContractItemProps> = (props) => {
    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start" className={classes.listItem} onClick={() => { props.onClickItem(props.item) }} >
            <ListItemAvatar >
                <InsertDriveFileIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
            </ListItemAvatar>
            <ListItemText primary={props.item.provider} secondary={AdditionalInfo(props.item)} >
            </ListItemText>
            <ListItemSecondaryAction>
                <ListItemIcon><ComponentIcon item={props.item.supplyConnectionBot} /></ListItemIcon>
            </ListItemSecondaryAction>

        </ListItem>
    );
};

export default FuelContractItem;