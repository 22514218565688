import React, { useEffect, useState, useContext } from "react";
import { useParams, useRouteMatch } from "react-router";
import {
  makeStyles,
  Theme,
  createStyles,
  CircularProgress,
  Box,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { FuelSupplyGateway } from "../../gateways/fuel-supply.gateway";
import { IFuelSupply } from "../../gateways/fuel-supply.interface";
import { AccessType, IAccessType } from "../../gateways/access-type.interfaces";
import { AccessTypeGateway } from "../../gateways/access-type.gateway";
import { IFuelType } from "../../gateways/fuel-types.interface";
import { FuelTypesGateway } from "../../gateways/fuel-types.gateway";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import FuelSupplyForm from "./fuel-supply-form";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import FuelSupplyDrawer from "../../components/fuel-supply-drawer";
import { FuelSupplyContext } from "./fuel-supply-context";
import { MongoGateway } from "../../gateways/mongo.gateway";
import { IProviders } from "../../gateways/providers.interface";
import { ProviderType } from "../../gateways/provider-types.interface";
import { SupplyType } from "../../gateways/supply-type.interfaces";
import { ProvidersGateway } from "../../gateways/providers.gateway";
import ProvidersUpsertDialog from "../providers/providers-upsert-dialog";
import { BlacklistGateway } from "../../gateways/blacklist.gateway";
import { obtainDomainFromEmail } from "../../utils/utils";
import { ClientsGateway } from "../../gateways/clients.gateway";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2),
    },
    itemAvatarIcon: {
      backgroundColor: "#e0e0e0",
      borderRadius: "50%",
      padding: theme.spacing(1),
    },
  })
);

interface IFuelSupplyRouteParams {
  [x: string]: string | undefined;
  fuelSupplyId: string;
  clientId: string;
}

const FuelSupplyDetail = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { url } = useRouteMatch();
  const { update: updateBreadCrumbName } = useBreadCrumbName();

  const { fuelSupplyId } = useParams<IFuelSupplyRouteParams>();
  const { clientId } = useParams<IFuelSupplyRouteParams>();
  const [errorMissingData, setErrorMissingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fuelSupplyState, fuelSupplyStateDispatch] =
    useContext(FuelSupplyContext);
  const fuelSupply = fuelSupplyState.fuelSupply;
  const [accessTypes, setAccessTypes] = useState<IAccessType[]>([
    { id: 0, name: "" },
  ]);
  const [fuelTypes, setFuelTypes] = useState<IFuelType[]>([]);
  const [isBlacklisted, setIsBlacklisted] = useState<boolean | undefined>(
    fuelSupply && fuelSupply.blacklisted ? fuelSupply.blacklisted : false
  );
  const [openProviderAddDialog, setOpenProviderAddDialog] = useState(false);
  const [providers, setProviders] = useState<IProviders[]>([]);

  const styles = {
    marginTop: "10px",
    marginLeft: "25px",
  };

  const fuelSupplyGateway = useIoC(FuelSupplyGateway);
  const accessTypeGateway = useIoC(AccessTypeGateway);
  const fuelTypeGateway = useIoC(FuelTypesGateway);
  const mongoGateway = useIoC(MongoGateway);
  const providersGateway = useIoC(ProvidersGateway);
  const blacklistGateway = useIoC(BlacklistGateway);
  const clientsGateway = useIoC(ClientsGateway);
  
  const handleClickBlacklistButton = () => {
    if (isBlacklisted) {
      handleDeleteItemBlacklist();
      if (fuelSupply) updateBreadCrumbName("fuelSupply", fuelSupply.reference);
      snackbar.enqueueSnackbar(
        "Se ha reestablecido el suministro correctamente",
        {
          variant: "success",
        }
      );
    } else {
      handleInsertItemBlacklist();
      if (fuelSupply)
        updateBreadCrumbName(
          "fuelSupply",
          fuelSupply.reference + " (Cancelado)"
        );
      snackbar.enqueueSnackbar("Se ha descartado el suministro correctamente", {
        variant: "success",
      });
    }
    setIsBlacklisted(!isBlacklisted);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);

      if(!fuelSupply) return;

      const response = await fuelSupplyGateway.findOneById(fuelSupply.id);
      if(response.length === 0) setIsBlacklisted(undefined);

      const fuelType_ = await fuelTypeGateway.findAll();
      const accessType_ = await accessTypeGateway.findAll();
      setFuelTypes(fuelType_);
      setAccessTypes(accessType_);

      const providers = await providersGateway.findWithFilters({
        providerTypeId: ProviderType.Distribuidora.toString(),
        supplyTypeId: SupplyType.Combustible.toString(),
        accessTypeId: AccessType.Red.toString(),
      });
      setProviders(providers);
      
      setLoading(false);
    })();
  }, []);

  const handleSave = () => {
    if (fuelSupply === null) {
      return;
    }
    if (
      !fuelSupply.accessTypeId ||
      !fuelSupply.fuelTypeId ||
      !fuelSupply.reference
    ) {
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      setErrorMissingData(true);
      return;
    }

    (async () => {
      try {
        if (!fuelSupplyId) {
          return;
        }
        setLoading(true);
        const fuelSupplyToUpdate: IFuelSupply = {
          accessTypeId: fuelSupply.accessTypeId,
          complexId: fuelSupply.complexId,
          establishmentId: fuelSupply.establishmentId,
          providerId: fuelSupply.providerId,
          fuelTypeId: fuelSupply.fuelTypeId,
          id: fuelSupply.id,
          reference: fuelSupply.reference,
          code: fuelSupply.code,
          emailNotification: fuelSupply.emailNotification,
          invoicesFolder: fuelSupply.invoicesFolder,
          startCaptureDate: fuelSupply.startCaptureDate,
          sendEmail: fuelSupply.sendEmail,
          sendClientEmail: fuelSupply.sendClientEmail,
          startValidationsDate: fuelSupply.startValidationsDate,
          separatedMeterAccount: fuelSupply.separatedMeterAccount ,
          separatedMeterInvoiceFolder: fuelSupply.separatedMeterInvoiceFolder ,
          separatedMeterStartCaptureDate: fuelSupply.separatedMeterStartCaptureDate ,
          separatedMeterStartValidationsDate: fuelSupply.separatedMeterStartValidationsDate

        };

        //domain check
        if (typeof fuelSupply.emailNotification === 'string' && fuelSupply.emailNotification !== null && fuelSupply.emailNotification !== '')
        {
            const input_domain = obtainDomainFromEmail(fuelSupply.emailNotification);
            //have content
            const client = await clientsGateway.findById(parseInt(clientId, 10));
            if (typeof client.client_email_domain !== 'undefined' && client.client_email_domain !== '')
            {
                //compare both domains
                if (input_domain !== client.client_email_domain)
                {
                    //es diferente
                    if (window.confirm('El dominio del correo de cliente, no coincide con el dominio configurado en el apartado principal de Cliente, quieres continuar de todos modos ?') == false)
                    {
                        snackbar.enqueueSnackbar('No se han actualizado los cambios', { variant: "warning" });
                        setLoading(false);
                        return;
                    }
                }
            }
            
        }

        await fuelSupplyGateway.update(
          Number.parseInt(fuelSupplyId),
          fuelSupplyToUpdate
        );

        snackbar.enqueueSnackbar(
          t("fuelSupplyDetail.updateSucceded", {
            reference: fuelSupply.reference,
            id: fuelSupply.id,
          }),
          {
            variant: "success",
          }
        );
        setErrorMissingData(false);
      } catch (e) {
        const er = e as any;
        if (er.response.data.key === "updatingError") {
          const key = "fuelSupplyErrorHandler." + er.response.data.key;
          const message =
            "validation" in er
              ? er.validation
              : t(key, { id: er.response.data.id });
          snackbar.enqueueSnackbar(message, { variant: "error" });
        } else {
          const message =
            "validation" in er ? er.validation : t("messages.defaultError");
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      } finally {
        setLoading(false);
      }
    })();
  };

  async function checkIfEmailIsBlacklisted(value: any) {
    setLoading(true);
    const isEmailFull = value.includes(".com");
    if(isEmailFull) {
      const exists = await blacklistGateway.findEmail(value);
      if(!!exists) {
        value = "";
        snackbar.enqueueSnackbar("La dirección de correo introducida pertenece a un cliente descartado", {
          variant: "error",
        });
      }
    }
    setLoading(false);
    return value;
  }

  const handleChange = async (name: string, value: any) => {
    if (fuelSupply === null) {
      return;
    }
    if(name === "code" && value.includes("*")){
      snackbar.enqueueSnackbar("No se puede introducir este carácter especial", {
        variant: "error",
      });
      return;
    }
    if(name === "emailNotification") {
      value = await checkIfEmailIsBlacklisted(value);
    }
    const updatedFuelSupply: IFuelSupply = {
      ...fuelSupply,
      [name]: value,
    } as any;
    fuelSupplyStateDispatch({
      type: "update-fuelSupply",
      payload: updatedFuelSupply,
    });
    updateBreadCrumbName("fuelSupply", fuelSupply.reference);
  };

  const handleSendEmailChange = () => {
    const updatedFuelSupply: IFuelSupply = {
      ...fuelSupply,
      sendEmail: fuelSupply ? !fuelSupply.sendEmail : null,
    } as any;
    fuelSupplyStateDispatch({
      type: "update-fuelSupply",
      payload: updatedFuelSupply,
    });
  };

  const handleSendClientEmailChange = () => {
    const updatedFuelSupply: IFuelSupply = {
      ...fuelSupply,
      sendClientEmail: fuelSupply ? !fuelSupply.sendClientEmail : null,
    } as any;
    fuelSupplyStateDispatch({
      type: "update-fuelSupply",
      payload: updatedFuelSupply,
    });
  };

  const handleCounterCheckChange = () => {
    const updatedFuelSupply: IFuelSupply = {
      ...fuelSupply,
      separatedMeterAccount: fuelSupply ? !fuelSupply.separatedMeterAccount : null,
    } as any;
    fuelSupplyStateDispatch({
      type: "update-fuelSupply",
      payload: updatedFuelSupply,
    });
  };

  const handleStartCaptureDateChange = (value: any) => {
    const updatedFuelSupply: IFuelSupply = {
      ...fuelSupply,
      startCaptureDate: value,
    } as any;
    fuelSupplyStateDispatch({
      type: "update-fuelSupply",
      payload: updatedFuelSupply,
    });
  };

  const handleStartValidationsDate = (value: any) => {
    const updatedFuelSupply: IFuelSupply = {
      ...fuelSupply,
      startValidationsDate: value,
    } as any;
    fuelSupplyStateDispatch({
      type: "update-fuelSupply",
      payload: updatedFuelSupply,
    });
  };
  const handleSeparatedMeterStartCaptureDateChange = (value: any) => {
    const updatedFuelSupply: IFuelSupply = {
      ...fuelSupply,
      separatedMeterStartCaptureDate: value,
    } as any;
    fuelSupplyStateDispatch({
      type: "update-fuelSupply",
      payload: updatedFuelSupply,
    });
  };
  const handleSeparatedMeterStartValidationsDateChange = (value: any) => {
    const updatedFuelSupply: IFuelSupply = {
      ...fuelSupply,
      separatedMeterStartValidationsDate: value,
    } as any;
    fuelSupplyStateDispatch({
      type: "update-fuelSupply",
      payload: updatedFuelSupply,
    });
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  if (fuelSupply === null) {
    return <CircularProgress className={classes.progress} />;
  }

  const handleInsertItemBlacklist = async () => {
    await blacklistGateway.insertCups(fuelSupply.reference);
  };

  const handleDeleteItemBlacklist = async () => {
    await blacklistGateway.deleteCups(fuelSupply.reference);
  };

  const handleClickAddProviderDialog = () => {
    setOpenProviderAddDialog(true);
  };

  const handleCloseAddProviderDialog = () => {
    setOpenProviderAddDialog(false);
  };

  const handleCreateNewProvider = (
    item: IProviders,
    isNew: boolean,
    supplyToAccessId: number[]
  ) => {
    if (
      item.providerTypeId === ProviderType.Distribuidora &&
      checkSuppliesToAccess(supplyToAccessId)
    ) {
      setProviders([...providers, item]);
    }
  };

  const checkSuppliesToAccess = (suppliesToAccessId: number[]): boolean => {
    for (const supplyToAccessId of suppliesToAccessId) {
      if (supplyToAccessId === 12 || supplyToAccessId === 22) {
        return true;
      }
    }
    return false;
  };

  const handleDeleteSupply = async () => {
    if(window.confirm(t("fuelSupplyDetail.deleteSupply") as string)){
      if(!fuelSupply) return;
      setLoading(true);
      if(isBlacklisted) await handleDeleteItemBlacklist();
      await fuelSupplyGateway.delete(fuelSupply.id);
      
      window.location.href = window.location.origin + url.split("fuelSupply")[0] + "fuelSupply";
      setLoading(false);
    }
    else return;
  }

  return (
    <Box>
      <ScreenGlobalStructure
        buttonIcon={<SaveIcon />}
        headerIcon={LocalGasStationIcon}
        matchUrl={url}
        onButtonClick={handleSave}
        title={t("fuelSupplyDetail.title")}
        clientId={clientId ? clientId : ""}
        loading={loading}
        drawer={
          <FuelSupplyDrawer
            matchUrl={url}
            lateralMenu={0}
            icon={
              <LocalGasStationIcon
                fontSize="large"
                className={classes.itemAvatarIcon}
              />
            }
            goBack={false}
          />
        }
        onBlacklistButtonClick={handleClickBlacklistButton}
        blacklisted={isBlacklisted}
        deleteSupply={handleDeleteSupply}
      >
        <FuelSupplyForm
          errorMissingData={errorMissingData}
          accessTypes={accessTypes}
          fuelTypes={fuelTypes}
          supply={fuelSupply}
          showAllFields={true}
          onChangeSupply={handleChange}
          validateRequired={validateRequired}
          providers={providers}
          onProviderAddButtonClick={handleClickAddProviderDialog}
          onStartCaptureDateChange={handleStartCaptureDateChange}
          onStartValidationDateChange={handleStartValidationsDate}
          onSeparatedMeterStartCaptureDateChange={handleSeparatedMeterStartCaptureDateChange}
          onSeparatedMeterStartValidationsDateChange={handleSeparatedMeterStartValidationsDateChange}
          onSendEmailChange={handleSendEmailChange}
          onSendClientEmailChange={handleSendClientEmailChange}
          onCounterCheckChange={handleCounterCheckChange}
        />
      </ScreenGlobalStructure>
      <ProvidersUpsertDialog
        open={openProviderAddDialog}
        item={undefined}
        onClose={handleCloseAddProviderDialog}
        onItemUpsert={handleCreateNewProvider}
        type={"fuelDetail"}
      />
    </Box>
  );
};

export default FuelSupplyDetail;
