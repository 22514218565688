import { IEstablishmentType, IUpdateEstablishmentType } from "../../gateways/establishment-type.interface";
import { FC, useState, useEffect } from "react";
import useIoC from "../../contexts/ioc.context";
import { EstablishmentTypeGateway } from "../../gateways/establishment-type.gateway";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import React from "react";
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog, makeStyles, Grid, Tabs, Tab } from "@material-ui/core";
import FormTextField from "../../components/form-text-field";
import { IRepositoryDefinition, IUpdateRepositoryDefinition, IUpdateGroupTypes, IUpdateProperties, IRepositoryDefinitionRelations, IGroupTypes, IProperties, IRepositoryParameter, IUpdateRepositoryParameter } from "../../gateways/repository-definition.interfaces";
import { RepositoryDefinitionGateway } from "../../gateways/repository-definition.gateway";
import { RepositoryGroupTypeGateway } from "../../gateways/repository-group-type.gateway";
import { RepositoryDefinitionTypeGateway } from "../../gateways/repository-definition-type.gateway";
import { RepositoryValueTypeGateway } from "../../gateways/repository-value-types.gateway";
import { IRepositoryDefinitionType } from "../../gateways/repository-definition-type.interfaces";
import slugify from 'react-slugify';
import MaterialTable from "material-table";
import SelectorField from "../../components/selector-field";
import RepositoryDefinitionCriteria from "./repository-definition-criteria";
import RepositoryDefinitionUpdateForm from "./repository-definition-form-update";

interface IRepositoryDefinitionUpdateDialogProps {
    open: boolean;
    onItemUpdate: (repositoryDefinition: IRepositoryDefinitionRelations, id: number) => void;
    onClose: () => void;
    item: IRepositoryDefinitionRelations | undefined;
}
const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            flexGrow: 1,
            margin: theme.spacing(1),
            width: 200,

        },
    },

    gridItem: {
        height: '100%',
        borderSpacing: 2,
        padding: theme.spacing(1)
    }
}));

const initialRepositoryDefinition: IUpdateRepositoryDefinition = {
    name: '',
    description: '',
    repositoryDefinitionTypeEntityId: 0,
    properties: [],
    groupTypes: [],
    parameters: [],
    slug: '',
    criteria: {
        queryBase: '',
        repositoryDefinitionPropertyEntityId: -1,
        sortAscDefault: false,
        properties:
        {
            name: '',
            valueTypeId: 0
        }
    }
};

const RepositoryDefinitionUpdateDialog: FC<IRepositoryDefinitionUpdateDialogProps> = (props) => {

    const classes = useStyles();
    const repositoryDefinitionGateway = useIoC(RepositoryDefinitionGateway);
    const repositoryValueTypeGateway = useIoC(RepositoryValueTypeGateway);
    const repositoryDefinitionTypeGateway = useIoC(RepositoryDefinitionTypeGateway);
    const repositoryGroupTypeGateway = useIoC(RepositoryGroupTypeGateway);
    const [repositoryDefinitionData, setRepositoryDefinitionData] = useState(initialRepositoryDefinition);
    const [repositoryDefinitionType, setRepositoryDefinitionType] = useState<IRepositoryDefinitionType[]>([]);
    const [valueTypesToLookUp, setValueTypesToLookUp] = useState<{}>({});
    const [groupTypesToLookup, setGroupTypesToLookup] = useState<{}>({});
    const [value, setValue] = useState(0);
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const fillGroupTypes = (groupTypes?: IGroupTypes[]): IUpdateGroupTypes[] => {
        let toReturn: IUpdateGroupTypes[] = [];
        if (!groupTypes) {
            for (let index: number = 0; index < repositoryDefinitionData.groupTypes.length; index++) {
                toReturn[index] = {
                    groupTypeId: repositoryDefinitionData.groupTypes[index].groupTypeId,
                    sourceProperty: repositoryDefinitionData.groupTypes[index].sourceProperty,
                }
            }
        } else {
            for (let index: number = 0; index < groupTypes.length; index++) {
                toReturn[index] = {
                    groupTypeId: groupTypes[index].groupTypeId,
                    sourceProperty: groupTypes[index].sourceProperty
                }
            }
        }
        return toReturn;
    }

    const fillProperties = (properties?: IProperties[]): IUpdateProperties[] => {
        let toReturn: IUpdateProperties[] = [];
        if (!properties) {
            for (let index: number = 0; index < repositoryDefinitionData.properties.length; index++) {
                toReturn[index] = {
                    name: repositoryDefinitionData.properties[index].name,
                    valueTypeId: repositoryDefinitionData.properties[index].valueTypeId
                }
            }
        } else {
            for (let index: number = 0; index < properties.length; index++) {
                toReturn[index] = {
                    name: properties[index].name,
                    valueTypeId: properties[index].valueTypeId
                }
            }
        }
        return toReturn;
    }

    const fillParameters = (parameters?: IRepositoryParameter[]): IUpdateRepositoryParameter[] => {
        let toReturn: IUpdateRepositoryParameter[] = [];
        if (!parameters) {
            for (const parameter of repositoryDefinitionData.parameters) {
                toReturn.push({ name: parameter.name });
            }
        } else {
            for (const parameter of parameters) {
                toReturn.push({ name: parameter.name })
            }
        }
        return toReturn;
    }

    const checkGroupTypes = (): boolean => {
        for (let i: number = 0; i < repositoryDefinitionData.groupTypes.length; i++) {
            for (let j: number = 0; j < repositoryDefinitionData.groupTypes.length; j++) {
                if (repositoryDefinitionData.groupTypes[i].groupTypeId === repositoryDefinitionData.groupTypes[j].groupTypeId && i !== j) {
                    return false;
                }
            }
        }
        return true;
    }

    const handleUpdate = async () => {
        if (repositoryDefinitionData.name === undefined || repositoryDefinitionData.name === '' ||
            repositoryDefinitionData.description === undefined || repositoryDefinitionData.description === '' ||
            !repositoryDefinitionData.repositoryDefinitionTypeEntityId || repositoryDefinitionData.properties.length === 0 ||
            repositoryDefinitionData.slug === undefined ||
            repositoryDefinitionData.slug === '' || repositoryDefinitionData.criteria.queryBase === undefined ||
            repositoryDefinitionData.criteria.queryBase === '' || repositoryDefinitionData.criteria.repositoryDefinitionPropertyEntityId === -1 ||
            repositoryDefinitionData.criteria.sortAscDefault === undefined) {

            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        };
        if (!props.item) {
            return;
        }
        const checkExistance = await repositoryDefinitionGateway.findBySlug(repositoryDefinitionData.slug);
        if (checkExistance.length > 0 && repositoryDefinitionData.slug !== props.item.slug) {
            snackbar.enqueueSnackbar(t('repositoryDefinitionAddDialog.slugAlreadyExists', { id: checkExistance[0].id }), { variant: "error" });
            return;
        }
        if (!checkGroupTypes()) {
            snackbar.enqueueSnackbar(t('repositoryDefinitionErrorHandler.groupTypesError'), { variant: "error" });
            return;
        }
        try {
            let dataToSend: IUpdateRepositoryDefinition =
            {
                criteria: {
                    ...repositoryDefinitionData.criteria, properties:
                    {
                        name: repositoryDefinitionData.properties[repositoryDefinitionData.criteria.repositoryDefinitionPropertyEntityId].name,
                        valueTypeId: repositoryDefinitionData.properties[repositoryDefinitionData.criteria.repositoryDefinitionPropertyEntityId].valueTypeId
                    }
                },
                description: repositoryDefinitionData.description,
                name: repositoryDefinitionData.name,
                repositoryDefinitionTypeEntityId: repositoryDefinitionData.repositoryDefinitionTypeEntityId,
                slug: repositoryDefinitionData.slug,
                groupTypes: fillGroupTypes(),
                properties: fillProperties(),
                parameters: fillParameters()
            }
            const repositoryDefinition = await repositoryDefinitionGateway.update(props.item.id, dataToSend);
            snackbar.enqueueSnackbar(t('repositoryDefinitionAddDialog.updateSucceded', { id: repositoryDefinition.id, name: repositoryDefinition.name }), { variant: "success" });
            setRepositoryDefinitionData(initialRepositoryDefinition)
            const updatedRepositoryDefinition = await repositoryDefinitionGateway.findById(repositoryDefinition.id);
            props.onItemUpdate(updatedRepositoryDefinition, props.item.id);
        } catch (e) {
            const er = e as any;
            if (er.response.data.key === 'updatingError') {
                const key = 'repositoryDefinitionErrorHandler.' + er.response.data.key;
                const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose();
        }

    };


    function a11yProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const handleChange = (name: string, value: any) => {
        setRepositoryDefinitionData({ ...repositoryDefinitionData, [name]: value });
    };
    const handleChangeValue = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleCancel = () => {
        props.onClose();
    };
    const handleSortAscDefaultChange = () => {
        const changedCriteria = repositoryDefinitionData.criteria;
        changedCriteria.sortAscDefault = !changedCriteria.sortAscDefault;
        setRepositoryDefinitionData({ ...repositoryDefinitionData, criteria: changedCriteria });
    };
    const handleChangeCriteriaQueryBase = (name: string, value: any) => {
        let criteriaUpdateAid = repositoryDefinitionData.criteria;
        criteriaUpdateAid.queryBase = value;
        setRepositoryDefinitionData({ ...repositoryDefinitionData, criteria: criteriaUpdateAid });
    };
    const handleChangeCriteriaPropertyEntityId = (name: string, value: any) => {
        let criteriaUpdateAid = repositoryDefinitionData.criteria;
        criteriaUpdateAid.repositoryDefinitionPropertyEntityId = value;
        setRepositoryDefinitionData({ ...repositoryDefinitionData, criteria: criteriaUpdateAid });
    };
    const handleCodeChange = (newCode: string) => {
        const changedCriteria = repositoryDefinitionData.criteria;
        changedCriteria.queryBase = newCode;
        setRepositoryDefinitionData({ ...repositoryDefinitionData, criteria: changedCriteria })
    }

    const validateRequired = (value: string): boolean => {
        return value !== null && value !== undefined && value !== '';
    };

    useEffect(() => {
        if (props.item) {
            let trueId = props.item.criteria.repositoryDefinitionPropertyEntityId
            let repDefId = props.item.properties.findIndex
                (
                    object => object.id === trueId
                );
            setRepositoryDefinitionData({
                criteria: {
                    queryBase: props.item.criteria.queryBase,
                    sortAscDefault: props.item.criteria.sortAscDefault,
                    repositoryDefinitionPropertyEntityId: repDefId,
                    properties:
                    {
                        name: props.item.properties[repDefId].name,
                        valueTypeId: props.item.properties[repDefId].valueTypeId
                    }
                },
                description: props.item.description,
                name: props.item.name,
                repositoryDefinitionTypeEntityId: props.item.repositoryDefinitionTypeEntityId,
                slug: props.item.slug,
                groupTypes: fillGroupTypes(props.item.groupTypes),
                properties: fillProperties(props.item.properties),
                parameters: fillParameters(props.item.parameters)

            });
        }
    }, [props.item]);

    useEffect(() => {
        (async () => {
            const valueTypes = await repositoryValueTypeGateway.findAll();
            setValueTypesToLookUp(valueTypes.reduce(function (acc, cur) {
                let id = cur.id;
                let name = cur.name;
                acc = Object.assign(acc, { [id]: name });
                return acc;
            }, {}));

            setRepositoryDefinitionType(await repositoryDefinitionTypeGateway.findAll());
            const groupTypes = await repositoryGroupTypeGateway.findAll();
            setGroupTypesToLookup(groupTypes.reduce(function (acc, cur) {
                let id = cur.id;
                let description = cur.description;
                acc = Object.assign(acc, { [id]: description });
                return acc;
            }, {}));
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const slugName = slugify(repositoryDefinitionData.name);
            setRepositoryDefinitionData({ ...repositoryDefinitionData, slug: slugName });
        })();
    }, [repositoryDefinitionData.name]);

    return (
        
        <RepositoryDefinitionUpdateForm
            a11yProps={a11yProps}
            groupTypesToLookup={groupTypesToLookup}
            item={repositoryDefinitionData}
            onCancel={handleCancel}
            onChange={handleChange}
            onChangeCriteriaPropertyEntityId={handleChangeCriteriaPropertyEntityId}
            onChangeCriteriaQueryBase={handleChangeCriteriaQueryBase}
            onChangeValue={handleChangeValue}
            onClose={props.onClose}
            onCodeChange={handleCodeChange}
            onSortAscDefaultChange={handleSortAscDefaultChange}
            onUpsert={handleUpdate}
            open={props.open}
            repositoryDefinitionData={repositoryDefinitionData}
            repositoryDefinitionType={repositoryDefinitionType}
            setRepositoryDefinitionData={setRepositoryDefinitionData}
            update={true}
            validateRequired={validateRequired}
            value={value}
            valueTypesToLookUp={valueTypesToLookUp}
        />
    );
};
export default RepositoryDefinitionUpdateDialog;