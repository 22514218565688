export default interface ICompany {
    id: number;
    name: string;
    client_email: string;
    sectorId: number;
    countryId: number;
    clientId: number;
    cif: string;
    cancelledDate?: Date | null;
}

export const initialICompany: ICompany = {
    id: 0,
    name: '',
    client_email: '',
    countryId: 0,
    sectorId: 0,
    clientId: 0,
    cif: ''
}