import React, { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IUpdateClientDto } from "../../gateways/clients.interfaces";
import useIoC from "../../contexts/ioc.context";
import { ElectricSupplyGateway } from "../../gateways/electric-supply.gateway";
import { IElectricSupply } from "../../gateways/electric-supply.interface";
import { List, ListItem, makeStyles, Box } from "@material-ui/core";
import ClientPeekElectricContractForm from "./client-peek-form-electric-contract";
import ClientPeekElectricBillForm from "./client-peek-form-electric-bill"
import { useHistory } from "react-router-dom";
import { TreeItem } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    item: {
        padding: 0,
        margin: 0,
        fontWeight: 'normal'
    }
}));

interface IClientPeekElectricSupplyFormProps {
    establishmentId?: string;
    complexId?: string;
    currentUrl: string;
}

const ClientPeekElectricSupplyForm: FC<IClientPeekElectricSupplyFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const electricSupplyGateway = useIoC(ElectricSupplyGateway);
    const [data, setData] = useState<IElectricSupply[]>([]);
    const history = useHistory();

    const handleClickItem = (item: IElectricSupply) => {
        history.push(computeCurrentUrl(item));
    };

    const computeCurrentUrl = (item: IElectricSupply): string => {
        return (`${props.currentUrl}/electricSupply/${item.id}`);
    }

    useEffect(() => {
        (async () => {
            if (props.establishmentId) {
                const establishmentId = props.establishmentId;
                const data = await electricSupplyGateway.findAll({ establishmentId });
                setData(data);

            }
            else if (props.complexId) {
                const complexId = props.complexId;
                const data = await electricSupplyGateway.findAll({ complexId });
                setData(data);
            }
        })();
    }, [electricSupplyGateway]);

    const handleStopPropagation = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
    }, [])

    return (
        <Box>
            {
                data.length > 0 &&
                <TreeItem nodeId={props.currentUrl + 'electricSupply'} label={t('rightNavigation.electricSupply')}>
                    {
                        data.map((item) => {
                            return (
                                <TreeItem
                                    nodeId={computeCurrentUrl(item)}
                                    label={<a onClick={handleStopPropagation}
                                        href={computeCurrentUrl(item)}
                                        target="_blank">{item.referencia}</a>}
                                >
                                    <ClientPeekElectricContractForm
                                        electricSupplyId={item.id.toString()}
                                        currentUrl={computeCurrentUrl(item)}
                                    />
                                    <ClientPeekElectricBillForm
                                        electricSupplyId={item.id.toString()}
                                        currentUrl={computeCurrentUrl(item)}
                                    />
                                </TreeItem>
                            )
                        })
                    }
                </TreeItem>
            }
        </Box>
    );
};

export default ClientPeekElectricSupplyForm;