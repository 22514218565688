import React from "react";
import { FC } from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core";
import "./cluster-marker.css";

interface ILocationPinProps {
  lat: number;
  lng: number;
  text?: string;
  isCluster?: boolean;
  pointCount?: number;
  points?: {
    type: string;
    properties: {
      cluster: boolean;
      coordinatesId: number;
      client: string;
    };
    geometry: {
      type: string;
      coordinates: number[];
    };
  }[];
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    cursor: "pointer",
  },
  itemAvatarIcon: {
    backgroundColor: "default",
    borderRadius: "50%",
    textAlign: "center",
    transform: "translateZ(0) translate(-50%, -50%)",
    position: "absolute",
  },
}));
const LocationPin: FC<ILocationPinProps> = (props) => {
  console.log(props.isCluster, props.points, props.pointCount);
  const classes = useStyles();
  return (
    <div>
      {props.isCluster && props.points && props.pointCount ? (
        <div
          className="cluster-marker"
          style={{
            width: `${10 + (props.pointCount / props.points.length) * 20}px`,
            height: `${10 + (props.pointCount / props.points.length) * 20}px`,
          }}
        >
          {props.pointCount}
        </div>
      ) : (
        <div>
          <LocationOnIcon
            fontSize="large"
            color="primary"
            className={classes.itemAvatarIcon}
          />
          {<p className="pin-text">{props.text}</p>}
        </div>
      )}
    </div>
  );
};

export default LocationPin;
