import React, { FC } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    progress: {
        margin: theme.spacing(2),
    },
    loaderContainer: {
        textAlign: 'center'
    }
}));

const CenteredCircularProgress: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.loaderContainer}>
            <CircularProgress className={classes.progress} />
        </div>
    );
}

export default CenteredCircularProgress;