import { IVolumeUnit, IUpdateVolumeUnit } from "../../gateways/volume-unit.interface";
import { FC, useState, useEffect } from "react";
import { VolumeUnitGateway } from "../../gateways/volume-units.gateway";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React from "react";
import FormTextField from "../../components/form-text-field";

interface IVolumeUnitUpdateDialogProps {
    open: boolean;
    item: IVolumeUnit | undefined;
    onItemUpdate: (volumeUnit: IVolumeUnit) => void;
    onClose: () => void;
}

const initialVolumeUnit: IUpdateVolumeUnit = {
    name: '',
    conversionValue: 0.0
};

const VolumeUnitUpdateDialog: FC<IVolumeUnitUpdateDialogProps> = (props) => {

    const volumeUnitGateway = useIoC(VolumeUnitGateway);
    const [volumeUnitData, setVolumeUnitData] = useState(initialVolumeUnit);

    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const handleUpdate = async () => {
        if (volumeUnitData.name === undefined || volumeUnitData.name === '') {
            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        }
        if (!props.item) {
            return;
        }
        try {
            const volumeUnit = await volumeUnitGateway.update(props.item.id, volumeUnitData);
            snackbar.enqueueSnackbar(t('volumeUnitUpdateDialog.updateSucceded', { id: volumeUnit.id }), { variant: "success" });
            setVolumeUnitData(initialVolumeUnit)
            props.onItemUpdate(volumeUnit);
        } catch (e) {
            const er = e as any;
            if (er.response.data.key === 'updatingError') {
                const key = 'volumeUnitsErrorHandler.' + er.response.data.key;
                const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose();
        }

    };
    const handleChange = (name: string, value: any) => {
        setVolumeUnitData({ ...volumeUnitData, [name]: value });
    };

    const handleCancel = () => {
        props.onClose();
    };

    const validateRequired = (value: string | number): boolean => {
        return value !== null && value !== undefined && value !== '' && value !== 0;
    };

    useEffect(() => {
        if (props.item) {
            setVolumeUnitData(props.item);
        }
    }, [props.item]);

    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }}>
            <DialogTitle>{t('volumeUnitUpdateDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('volumeUnitUpdateDialog.content')}</DialogContentText>
                <FormTextField
                    name='name'
                    label={t('common.name') as string}
                    type='text'
                    validator={validateRequired}
                    errorText={t('messages.requiredField') as string}
                    value={volumeUnitData.name}
                    onChange={handleChange}
                    autoFocus
                    fullWidth
                    required
                />

                <FormTextField
                    name='conversionValue'
                    label={t('common.conversionValue') as string}
                    type='number'
                    value={volumeUnitData.conversionValue}
                    onChange={handleChange}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleUpdate} color="primary">{t('common.update')}</Button>
            </DialogActions>

        </Dialog>
    );
};

export default VolumeUnitUpdateDialog;