import { makeStyles } from '@material-ui/core';
import { ICreatePeriodElectricSupplyRate } from "./period-electric-supply-rate.interfaces";
import { ISupplyConnectionBot } from "./supply-connection-bot.interface";

export interface IElectricContract {
    id: number;
    reference: string;
    providerId: number;
    signContractDate: Date;
    initSupplyDate: Date;
    duration: number;
    endSupplyDate: Date;
    term: number;
    cancelPriorNotice: number;
    modality: string;
    electricSupplyRateId: number;
    electricSupplyId: number;
    periodsElectricSupplyRate: ICreatePeriodElectricSupplyRate[];
    supplyConnectionBot?: ISupplyConnectionBot;
    accountNumber: string | null;
    accountNumber_ebmUser: string | null ;
    accountNumber_date: Date | null;
}

export interface IElectricContractCreate {
    reference: string;
    providerId: number;
    signContractDate: Date;
    initSupplyDate: Date;
    duration: number;
    endSupplyDate: Date;
    term: number;
    cancelPriorNotice: number;
    modality: string;
    electricSupplyRateId: number;
    electricSupplyId: number;
    periodsElectricSupplyRate: ICreatePeriodElectricSupplyRate[];
}

export interface IElectricContractAccountLogs {
    id: number;
    contractReferenceId: number;
    accountNumber: string;
    editionDate: Date;
    editorUsername: string;
}

export interface IDisplayElectricContractList {
    id: number;
    reference: string;
    providerId: number;
    signContractDate: Date;
    initSupplyDate: Date;
    duration: number;
    endSupplyDate: Date;
    term: number;
    cancelPriorNotice: number;
    modality: string;
    electricSupplyRateId: number;
    providerName: string;
    rate: string;
    supplyConnectionBot?: ISupplyConnectionBot;
}

export const initialElectricContract: IElectricContract = {
    id: 0,
    reference:'',
    cancelPriorNotice: 0,
    duration: 0,
    electricSupplyRateId: 0,
    endSupplyDate: new Date(),
    initSupplyDate: new Date(),
    modality: '',
    providerId: 0,
    signContractDate: new Date(),
    term: 0,
    periodsElectricSupplyRate: [{ discount: 0, electricContractId: 1, energyRate: 0, powerContract: 0, powerRate: 0 },
        { discount: 0, electricContractId: 1, energyRate: 0, powerContract: 0, powerRate: 0 },
        { discount: 0, electricContractId: 1, energyRate: 0, powerContract: 0, powerRate: 0 },
        { discount: 0, electricContractId: 1, energyRate: 0, powerContract: 0, powerRate: 0 },
        { discount: 0, electricContractId: 1, energyRate: 0, powerContract: 0, powerRate: 0 },
        { discount: 0, electricContractId: 1, energyRate: 0, powerContract: 0, powerRate: 0 }],
    electricSupplyId: 0,
    supplyConnectionBot: undefined,
    accountNumber: null,
    accountNumber_ebmUser: null,
    accountNumber_date: null
};