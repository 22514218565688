import React, { FC, useCallback, useState, useEffect } from "react";
import {
  Box,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { CoordiantesLayout } from "./coordinates.layout";
import useIoC from "../../contexts/ioc.context";
import CoordinatesGateway from "../../gateways/coordinates.gateway";
import { useFetchController } from "../../hooks/use-fetch-controller";
import { Alert } from "@material-ui/lab";
import { CoordinatesSelectorMapDialog } from "./coordinates-map";
import { Check, EditLocation } from "@material-ui/icons";
import {
  Coordinate,
  CoordinateTask,
} from "../../gateways/coordinates.interfaces";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    focusedCell: {
      color: "green",
      cursor: "pointer",
      backgroundColor: "#f3f7f2",
      borderRadius: 4,
    },
    nonFocusedCell: {
      color: "black",
      cursor: "pointer",
    },
  })
);

export const CoordinatesTasks: FC = () => {
  const classes = useStyles();
  const coordinatesGateway = useIoC(CoordinatesGateway);
  const [openMap, setOpenMap] = useState<CoordinateTask | null>(null);
  const [filterBy, setFilterBy] = useState<string>("Tarea");
  const { enqueueSnackbar } = useSnackbar();

  const fetcher = useCallback(() => {
    return coordinatesGateway.getTasksCoordinates();
  }, [coordinatesGateway]);

  const { data, loading, replaceData } = useFetchController(fetcher, {
    autoload: true,
  });

  const handleMarkAsCompleted = useCallback(
    (
      item: CoordinateTask,
      newAddress?: {
        address: string;
        coordinates: Coordinate;
      }
    ) => {
      return async () => {
        if (
          newAddress ||
          window.confirm(`¿Quieres marcar la tarea ${item.id} como realizada?`)
        ) {
          try {
            await coordinatesGateway.markAsCompletedTask(item.id, newAddress);
            replaceData((data ?? []).filter((x) => x.id !== item.id));
            enqueueSnackbar(
              `Se ha marcado como completada la tarea ${item.id}`,
              { variant: "success" }
            );
          } catch (e: any) {
            enqueueSnackbar(
              `Ha ocurrido un error al completar la tarea: ${e.toString()}`,
              { variant: "error" }
            );
          }
        }
      };
    },
    [coordinatesGateway, enqueueSnackbar, replaceData, data]
  );

  function sortObjects(arr: any[], property: string) {
    arr.sort((a, b) => {
      if(a[property] === null && b[property] === null) return 0;
      else if(a[property] === null) return 1;
      else if(b[property] === null) return -1;
      else if(typeof a[property] === "number") return a[property] - b[property]; 
      else return a[property].toLowerCase().charCodeAt(0) - b[property].toLowerCase().charCodeAt(0);
    });
    return arr;
  }

  const handleClick = async (event: any) => {
    const cases = {
      Tarea: "id",
      Cliente: "client_name",
      Complejo: "complex_name",
      Establecimiento: "establishment_name",
    };
    const text: "Tarea" | "Cliente" | "Complejo" | "Establecimiento" =
      event.target.outerText;
    setFilterBy(text);
    if(!!!data) return;
    const orderedData = sortObjects(data, cases[text]);
    replaceData(orderedData);
  };

  return (
    <CoordiantesLayout page="tasks">
      {
        <Box>
          <Table>
            <TableHead
              style={{
                position: "sticky",
                top: 63,
                zIndex: 1,
                backgroundColor: "#fff",
              }}
            >
              <TableRow>
                <TableCell
                  className={
                    filterBy === "Tarea"
                      ? classes.focusedCell
                      : classes.nonFocusedCell
                  }
                  onClick={handleClick}
                >
                  Tarea
                </TableCell>
                <TableCell
                  className={
                    filterBy === "Cliente"
                      ? classes.focusedCell
                      : classes.nonFocusedCell
                  }
                  onClick={handleClick}
                >
                  Cliente
                </TableCell>
                <TableCell
                  className={
                    filterBy === "Complejo"
                      ? classes.focusedCell
                      : classes.nonFocusedCell
                  }
                  onClick={handleClick}
                >
                  Complejo
                </TableCell>
                <TableCell
                  className={
                    filterBy === "Establecimiento"
                      ? classes.focusedCell
                      : classes.nonFocusedCell
                  }
                  onClick={handleClick}
                >
                  Establecimiento
                </TableCell>
                <TableCell>Dirección</TableCell>
                <TableCell>Coordenas (lat / long)</TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={7}
                  style={{
                    backgroundColor: "#000",
                    height: 2,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {loading && (
                    <LinearProgress variant="indeterminate" color="primary" />
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item) => (
                <TableRow key={`row_${item.id}`}>
                  <TableCell>{item.id}</TableCell>
                  <TableCell>{item.client_name}</TableCell>
                  <TableCell>{item.complex_name ?? "Sin asignar"}</TableCell>
                  <TableCell>
                    {item.establishment_name ?? "Sin asignar"}
                  </TableCell>
                  <TableCell>{item.address ?? "Sin asignar"}</TableCell>
                  <TableCell>
                    {item.coordinates
                      ? `${item.coordinates.latitude} / ${item.coordinates.longitude}`
                      : "Sin asignar"}
                  </TableCell>

                  <TableCell>
                    <Box display="flex">
                      <IconButton
                        color="secondary"
                        onClick={() => setOpenMap(item)}
                      >
                        <EditLocation />
                      </IconButton>
                      <IconButton
                        color="primary"
                        onClick={handleMarkAsCompleted(item)}
                      >
                        <Check />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {data && data.length === 0 && (
            <Alert>¡Buen trabajo! No hay tareas pendientes de revisar 💪</Alert>
          )}
        </Box>
      }
      {openMap && (
        <CoordinatesSelectorMapDialog
          open
          onClose={() => setOpenMap(null)}
          onSelect={async (address, coordinates) => {
            await handleMarkAsCompleted(openMap, { address, coordinates })();
            setOpenMap(null);
          }}
          initialAddress={
            openMap.address && openMap.coordinates
              ? {
                  address: openMap.address ?? "",
                  coordinates: openMap.coordinates,
                }
              : null
          }
        />
      )}
    </CoordiantesLayout>
  );
};
