import React, { useState, useMemo, useEffect } from "react";
import CenteredCircularProgress from "../../components/centered-circular-progress";
import AlertBox from "../../components/alert-box";
import { makeStyles, List, Divider, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ContactsIcon from "@material-ui/icons/Contacts";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { useParams, useHistory, useRouteMatch } from "react-router";
import EstablishmentUpsertDialog from "./establishment-upsert-dialog";
import EstablishmentItem from "./establishment-item";
import { IEstablishment } from "../../gateways/establishment.interface";
import { EstablishmentGateway } from "../../gateways/establishment.gateway";
import ScreenGlobalStructure from "../../components/screen-global-structure";
import ClientsDrawer from "../../components/clients-drawer";
import PeopleIcon from "@material-ui/icons/People";
import CompaniesDrawer from "../../components/companies-drawer";
import ComplexDrawer from "../../components/complex-drawer";
import CompanyIcon from "@material-ui/icons/BusinessCenter";
import ComplexIcon from "@material-ui/icons/Dashboard";

const useStyles = makeStyles((theme) => ({
  itemAvatarIcon: {
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
}));

interface IEstablishmentRouteParams {
  [x: string]: string | undefined;
  clientId: string;
  companyId: string;
  complexId: string;
}

const EstablishmentList = () => {
  const classes = useStyles();
  const { clientId } = useParams<IEstablishmentRouteParams>();
  const { companyId } = useParams<IEstablishmentRouteParams>();
  const { complexId } = useParams<IEstablishmentRouteParams>();

  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState<IEstablishment[]>([]);
  const [openUpsertDialog, setOpenUpsertDialog] = useState(false);
  const [item, setItem] = useState<IEstablishment>();
  const [showDiscardedEstablishments, setShowDiscardedEstablishments] = useState<boolean>(false);
  const [filedButtonTitle, setFiledButtonTitle] = useState<string>(`${t("establishmentList.buttonNonDiscardedTitle")}`);
  const isDataEmpty = useMemo(
    () => !isDataLoading && data.length === 0,
    [isDataLoading, data]
  );
  const establishmentGateway =
    useIoC(EstablishmentGateway);

  const handleClickFabButton = () => {
    setItem(undefined);
    setOpenUpsertDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenUpsertDialog(false);
  };

  const handleItemUpsert = (item: IEstablishment, isNew: boolean) => {
    let copy = data.slice();
    if (isNew) {
      copy = [item, ...data];
    } else {
      const index = data.findIndex((value) => value.id === item.id);
      copy[index] = item;
    }
    setData(copy);
  };
  const handleClickItem = (item: IEstablishment) => {
    history.push(`${url}/${item.id}`);
  };

  useEffect(() => {
    (async () => {
      (async () => {
        setIsDataLoading(true);
        if (complexId) {
          const data = await establishmentGateway.findAll({
            complexId: parseInt(complexId, 10),
            showDiscarded: showDiscardedEstablishments
          });
          setData(data);
        } else if (companyId) {
          const data = await establishmentGateway.findAll({
            companyId: parseInt(companyId, 10),
            showDiscarded: showDiscardedEstablishments
          });
          setData(data);
        } else {
          const data = clientId 
          ? await establishmentGateway.findAll({
            clientId: parseInt(clientId, 10),
            showDiscarded: showDiscardedEstablishments
          }): [];
          setData(data);
        }
        setIsDataLoading(false);
      })();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [establishmentGateway]);

  const Content = () => {
    if (isDataLoading) {
      return <CenteredCircularProgress />;
    }

    if (isDataEmpty) {
      return <AlertBox variant="info">{t("common.noItems") as string}</AlertBox>;
    }

    return (
      <List>
        {data.map((item, index) => {
          return (
            <div key={item.id}>
              <EstablishmentItem item={item} showDiscarded={showDiscardedEstablishments} onClickItem={handleClickItem} />
              {index < data.length - 1 ? (
                <Divider variant="inset" component="li" />
              ) : null}
            </div>
          );
        })}
      </List>
    );
  };

  const handleEstablishmentList = async () => {
    setIsDataLoading(true);
    let establishments;
    
    const bool = showDiscardedEstablishments === false ? true: false;

    if (complexId) {
      establishments = await establishmentGateway.findAll({
        complexId: parseInt(complexId, 10),
        showDiscarded: bool
      });
    } else if (companyId) {
      establishments = await establishmentGateway.findAll({
        companyId: parseInt(companyId, 10),
        showDiscarded: bool
      });
    } else {
      establishments = clientId 
      ? await establishmentGateway.findAll({
        clientId: parseInt(clientId, 10),
        showDiscarded: bool
      }): [];
      setIsDataLoading(false);
    }

    setData(establishments);

    if(showDiscardedEstablishments === true) setFiledButtonTitle(`${t("establishmentList.buttonNonDiscardedTitle")}`)
    else setFiledButtonTitle(`${t("establishmentList.buttonDiscardedTitle") as string}`);

    setShowDiscardedEstablishments(!showDiscardedEstablishments);
  }

  const handleOnLoading = (value: boolean) => {
    setIsDataLoading(value);
  }

  return (
    <Box>
      <ScreenGlobalStructure
        buttonIcon={<AddIcon />}
        headerIcon={ContactsIcon}
        onButtonClick={handleClickFabButton}
        title={t("companyList.title") as string}
        matchUrl={url}
        clientId={clientId ?? ""}
        loading={isDataLoading}
        drawer={
          complexId ? (
            <ComplexDrawer
              matchUrl={url}
              lateralMenu={2}
              icon={
                <ComplexIcon
                  fontSize="large"
                  className={classes.itemAvatarIcon}
                />
              }
              goBack={true}
              contacts={true}
            />
          ) : companyId ? (
            <CompaniesDrawer
              matchUrl={url}
              lateralMenu={3}
              icon={
                <CompanyIcon
                  fontSize="large"
                  className={classes.itemAvatarIcon}
                />
              }
              goBack={true}
            />
          ) : (
            <ClientsDrawer
              matchUrl={url}
              lateralMenu={4}
              icon={
                <PeopleIcon
                  fontSize="large"
                  className={classes.itemAvatarIcon}
                />
              }
              goBack={true}
            />
          )
        }
        filedButtonList={handleEstablishmentList}
        filedButtonListTitle={filedButtonTitle}
      >
        <Content />
      </ScreenGlobalStructure>
      {clientId && (
        <EstablishmentUpsertDialog
          open={openUpsertDialog}
          item={item}
          clientId={parseInt(clientId, 10)}
          companyId={companyId ? parseInt(companyId, 10) : undefined}
          complexId={complexId ? parseInt(complexId, 10) : undefined}
          onItemUpsert={handleItemUpsert}
          onClose={handleCloseDialog}
          onLoading={handleOnLoading}
        />
      )}
    </Box>
  );
};

export default EstablishmentList;
