import React, { FC } from 'react';
import ICompany from '../../gateways/company.interface';
import { makeStyles, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import CompanyIcon from '@material-ui/icons/BusinessCenter';

interface ICompanyItemProps {
    item: ICompany;
    showDiscarded: boolean;
    onClickItem: (item: ICompany) => void;
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    discardedCompany: {
        color: "#E0E0E0"
    },
    nonDiscardedCompany: {
        color: "black"
    }
}));

const CompanyItem: FC<ICompanyItemProps> = (props) => {
    const classes = useStyles();

    const color = props.showDiscarded ? classes.discardedCompany: classes.nonDiscardedCompany;

    return (
        <ListItem alignItems='flex-start' className={classes.listItem} onClick={() => { props.onClickItem(props.item) }}>
            <ListItemAvatar >
                <CompanyIcon color='action' fontSize='large' className={classes.itemAvatarIcon} />
            </ListItemAvatar>
            <ListItemText primary={props.item.name} className={color}/>
        </ListItem>
    );
};

export default CompanyItem;