import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IRepositoryDefinition, ICreateRepositoryDefinition, IUpdateRepositoryDefinition, IRepositoryDefinitionRelations } from "./repository-definition.interfaces";

const repositoryDefinition = 'repository-definition';
@inject('axios')
export class RepositoryDefinitionGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IRepositoryDefinitionRelations[]> {
        const response = await this.axios.get(repositoryDefinition);
        return response.data;
    }

    async findById(id: number): Promise<IRepositoryDefinitionRelations> {
        const url = `${repositoryDefinition}/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    async findBySlug(slug: string): Promise<IRepositoryDefinition[]> {
        const response = await this.axios.get(`${repositoryDefinition}/slug/${slug}`);
        return response.data;
    }

    async create(repositoryDefinitionToCreate: ICreateRepositoryDefinition): Promise<IRepositoryDefinition> {
        const response = await this.axios.post(repositoryDefinition, repositoryDefinitionToCreate);
        return response.data;
    }

    async update(id: number, repositoryDefinitionToUpdate: IUpdateRepositoryDefinition): Promise<IRepositoryDefinition> {
        const url = `${repositoryDefinition}/${id}`;
        const response = await this.axios.put(url, repositoryDefinitionToUpdate);
        return response.data;
    }

    async delete(id: number): Promise<void> {
        const url = `${repositoryDefinition}/${id}`;
        const response = await this.axios.delete(url);
        return response.data;
    }
}