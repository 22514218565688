import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IClientTypes } from "./clients-types.interfaces";

@inject("axios")
export class ClientTypesGateway {
  constructor(private axios: AxiosInstance) {}

  async findAll(): Promise<IClientTypes[]> {
    const response = await this.axios.get("clientTypes");
    return response.data;
  }
}
