export interface IComplex {
    id: number;
    name: string;
    direction: string;
    companyId: number;
    countryId: number;
    regionsIds: number[];
    coordinatesId?: number | null;
    sendBeforeValidating: boolean;
    cancelledDate?: Date | null;
}

export interface ICreateComplex {
    name: string;
    direction: string;
    companyId: number;
    countryId: number;
    regionsIds: number[];
    coordinatesId?: number | null;
    sendBeforeValidating: boolean;
    cancelledDate?: Date | null;
}

export interface IUpdateComplex {
    name: string;
    direction: string;
    companyId: number;
    countryId: number;
    regionsIds: number[];
    coordinatesId?: number | null;
    sendBeforeValidating: boolean;
    cancelledDate?: Date | null;
}

export const initialIComplex: IComplex = {
    id: 0,
    name: '',
    direction: '',
    companyId: 0,
    countryId: 0,
    regionsIds: [],
    sendBeforeValidating: true
}