import React, { FC, useEffect, useState, useMemo } from "react";
import { Typography, List, ListItem, ListItemAvatar, ListItemText, Divider, makeStyles, CircularProgress, Box } from "@material-ui/core";
import ElectricityIcon from '@material-ui/icons/OfflineBolt';
import FuelIcon from '@material-ui/icons/LocalGasStation';
import AddIcon from '@material-ui/icons/Add';
import { withRouter, RouteComponentProps } from "react-router";
import Fab from '@material-ui/core/Fab';
import useIoC from "../../contexts/ioc.context";
import AlertBox from "../../components/alert-box";
import { useNavigator } from '../../contexts/navigator.context';
import SupplyBotsIcon from '@material-ui/icons/Autorenew';
import { SupplyBotsGateway } from "../../gateways/supply-bots.gateway";
import { ISupplyBot } from "../../gateways/supply-bots.interface";

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    inline: {
        display: 'inline',
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    },
    progress: {
        margin: theme.spacing(2),
    },
    loaderContainer: {
        textAlign: 'center'
    }
}));

const SupplyBotList: FC<RouteComponentProps> = ({ history }) => {
    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const supplyBotsGateway = useIoC(SupplyBotsGateway);
    const [data, setData] = useState<ISupplyBot[]>([]);
    const [loading, setLoading] = useState(false);

    const isEmpty = useMemo(() => !loading && data.length === 0, [loading, data]);

    const handleNavigateDetail = (item: ISupplyBot) => {
        history.push('/supply-bots/' + item.id);
    }

    const handleAdd = () => {
        history.push('/supply-bots/create');
    }

    const renderItem = (item: ISupplyBot, index: number) => {
        const handleNavigateWraped = () => handleNavigateDetail(item);
        return (
            <>
                <ListItem alignItems="flex-start" onClick={handleNavigateWraped} className={classes.listItem}>
                    <ListItemAvatar >
                        {
                            item.supplyType.name === 'electricity'
                                ? <ElectricityIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
                                : <FuelIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
                        }
                    </ListItemAvatar>
                    <ListItemText
                        primary={`[${item.code}] ${item.cups}`}
                        secondary={
                            <React.Fragment>
                                <Typography>{item.invoicesFolder}</Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
                {
                    index < data.length - 1
                        ? <Divider variant="inset" component="li" />
                        : null
                }
            </>
        );
    }

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: 'Suministros (bots)',
                icon: SupplyBotsIcon
            }
        });
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const items = await supplyBotsGateway.findAll();
            setData(items);
            setLoading(false);
        })();
    }, [supplyBotsGateway]);

    return (
        <Box>
            <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleAdd}>
                <AddIcon />
            </Fab>

            {
                loading
                    ? (
                        <div className={classes.loaderContainer}>
                            <CircularProgress className={classes.progress} />
                        </div>
                    ) : null
            }

            {
                isEmpty
                    ? (
                        <AlertBox variant="info">
                            No se han encontrado registros
                        </AlertBox>
                    ) : null
            }

            <List>
                {data.map(renderItem)}
            </List>
        </Box>
    );
}

export default withRouter(SupplyBotList);