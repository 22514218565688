import React from "react";
import {  Switch, Route, useRouteMatch } from "react-router";
import FuelContractList from "./fuel-contract-list";
import FuelContractDetail from "./fuel-contract-detail";

const FuelContractRouter = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/`} exact>
                <FuelContractList/>
            </Route>
            <Route path={`${path}/:fuelContractId`} exact  >
                <FuelContractDetail/>
            </Route>

        </Switch>
    );
};
export default FuelContractRouter;
