import React, { FC } from "react";
import ICurrency from "../../gateways/currency.interface";
import { makeStyles, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import CurrencyIcon from '@material-ui/icons/MonetizationOn';
import UpdateIcon from '@material-ui/icons/Create';

interface ICurrencyItemProps {
    item: ICurrency;
    onClickUpdateButton: (item: ICurrency) => void;
}

const useStyles = makeStyles((theme) => ({
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

const CurrencyItem: FC<ICurrencyItemProps> = (props) => {
    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar >
                <CurrencyIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
            </ListItemAvatar>
            <ListItemText primary={props.item.name} secondary={props.item.isoCode} />
            <ListItemSecondaryAction>
                <IconButton onClick={() => { props.onClickUpdateButton(props.item) }} aria-label="update"><UpdateIcon /></IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default CurrencyItem;