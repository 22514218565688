import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IBotRO } from "./bots.interfaces";
import { IAccessType } from "./access-type.interfaces";

@inject('axios')
export class AccessTypeGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async findAll(): Promise<IAccessType[]> {
        const response = await this.axios.get('access-types');
        return response.data;
    }
}