import {
  Container,
  Impl,
  Args,
  PrimitiveOrDependencyCtor,
  ImplOrAny,
} from "aurelia-dependency-injection";
import { createContext, useContext } from "react";
import buildAxiosInstance from "../gateways/axios.builder";

const ioc = new Container().makeGlobal();

ioc.registerInstance("axios", buildAxiosInstance());

const IoCContext = createContext(ioc);

const useContextIoC = () => useContext(IoCContext);

type GetDepFunction<
  TBase,
  TImpl extends Impl<TBase>,
  TArgs extends Args<TBase> = Args<TBase>
> = (key: PrimitiveOrDependencyCtor<TBase, TImpl, TArgs>) => ImplOrAny<TImpl>;

function useIoC<
  TBase,
  TImpl extends Impl<TBase>,
  TArgs extends Args<TBase> = Args<TBase>
>(key: PrimitiveOrDependencyCtor<TBase, TImpl, TArgs>): ImplOrAny<TImpl> {
  return useContextIoC().get(key);
}

export default useIoC;
