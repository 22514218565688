import React, { FC, useState, useEffect } from "react"
import { useUser } from "../contexts/user.context";
import { CircularProgress, makeStyles } from "@material-ui/core";
import MainLayout from "./main.layout";
import { LoginPage } from "../pages/account/login.page";
import UnauthorizedLayout from "./unauthorized.layout";

const useStyles = makeStyles(theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    progress: {
        margin: theme.spacing(2),
    },
}));

export const Main: FC = () => {
    const [{ token }] = useUser();
    const [loading] = useState(false);
    const classes = useStyles();
    const userLogged = token !== null;

    return loading
        ? <CircularProgress className={classes.progress} />
        : (
            userLogged
                ? <MainLayout />
                : <UnauthorizedLayout />
        )

}