import React, { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { makeStyles, Box } from "@material-ui/core";
import { ElectricContractGateway } from '../../gateways/electric-contract.gateway';
import { IElectricContract } from "../../gateways/electric-contract.interfaces";
import { useHistory } from "react-router-dom";
import { TreeItem } from "@material-ui/lab";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    item: {
        padding: 0,
        margin: 0,
        fontWeight: 'normal'
    }
}));

interface IClientPeekElectricContractFormProps {
    electricSupplyId: string;
    currentUrl: string;
}

const ClientPeekElectricContractForm: FC<IClientPeekElectricContractFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [data, setData] = useState<IElectricContract[]>([]);
    const electricContractGateway = useIoC(ElectricContractGateway);
    const history = useHistory();

    const handleClickItem = (item: IElectricContract) => {
        history.push(`${props.currentUrl}/electricContract/${item.id}`);
    };

    useEffect(() => {
        (async () => {
            const electricSupplyId = props.electricSupplyId;
            const data = await electricContractGateway.findAll({ electricSupplyId });
            setData(data);
        })();
    }, [electricContractGateway]);

    const handleStopPropagation = useCallback( (event: React.MouseEvent) => {
        event.stopPropagation();               
    }, [])

    return (
        <Box>
            {
                data.length > 0 &&
                <TreeItem nodeId={props.currentUrl + 'electricContract'} label={t('rightNavigation.electricContracts')}>
                    {
                        data.map((item) => {
                            const initDate = item.initSupplyDate ? new Date(item.initSupplyDate.toString()) : undefined;
                            const endDate = item.endSupplyDate ? new Date(item.endSupplyDate.toString()) : undefined;
                            return (
                                <TreeItem nodeId={`${props.currentUrl}/electricContract/${item.id}`} label={<a onClick={handleStopPropagation} href={`${props.currentUrl}/electricContract/${item.id}`} target="_blank">{
                                    (initDate ? `${moment(initDate).format('DD/MM/YYYY')}` : t('common.undefinedDate'))
                                    +
                                    ' - '
                                    +
                                    (endDate ? `${moment(endDate).format('DD/MM/YYYY')}` : t('common.undefinedDate'))}</a>} />
                            )
                        })
                    }
                </TreeItem>
            }
        </Box>
    )
};

export default ClientPeekElectricContractForm;