import React, { FC, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { ContactTypesGateway } from "../../gateways/contact-types.gateway";
import useIoC from "../../contexts/ioc.context";
import IContact from "../../gateways/contact.interface";
import IContactType from "../../gateways/contact-types.interfaces";
import { ContactGateway } from "../../gateways/contact.gateway";
import { ContactEntityType } from "./contact-list";
import { useTranslation } from "react-i18next";
import ContactTypeAddDialog from "../contact-types/contact-type-add-dialog";
import ContactForm from "./contact-form";
import UpdateExistingContactDialog from "./update-existing-contact";

export interface IContactAddDialog {
  open: boolean;
  onClose: () => void;
  onContactCreated: (contact: IContact) => void;
  onContactUpdated: (contact: IContact) => void;
  entity: ContactEntityType;
  entityId: string;
}

const initialContact: IContact = {
  id: 0,
  fullName: "",
  contactTypeId: 0,
  email: "",
  phoneNumber1: "",
  phoneNumber2: "",
  headquarters: "",
};

const ContactAddDialog: FC<IContactAddDialog> = ({
  open,
  onClose,
  onContactCreated,
  onContactUpdated,
  entity,
  entityId,
}) => {
  const [item, setItem] = useState<IContact>(initialContact);
  const [contactTypes, setContactTypes] = useState<IContactType[]>([]);
  const [openUpdateExistingEmail, setOpenCreateExistingEmail] = useState(false);
  const [error, setError] = useState(false);
  const snackbar = useSnackbar();
  const contactTypesGateway = useIoC(ContactTypesGateway);
  const contactGateway = useIoC(ContactGateway);
  const { t } = useTranslation();
  const [openAddContactType, setOpenAddContactType] = useState(false);
  const [existingEmailId, setExistingEmailId] = useState(0);

  const handleClickAddButon = () => {
    setOpenAddContactType(true);
  };

  const closeAddContactType = () => {
    setOpenAddContactType(false);
  };

  const handleContactTypeCreate = (contactType: IContactType) => {
    setContactTypes([...contactTypes, contactType]);
  };

  const handleChange = (
    name: string, value: any
  ) => {
    setItem({ ...item, [name as string]: value });
  };

  const handleCreateContactDuplicateEmail = () => {
    if (item.fullName === "" || item.contactTypeId === 0 || item.email === '') {
      setError(true);
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      return;
    }
    (async () => {
      await createContact();
    })();
  }

  const createContact = async () => {
    try {
      const contact = await contactGateway.create(item, entity, entityId);
      snackbar.enqueueSnackbar(
        t("contactAddDialog.createSucceded", { id: contact.id }),
        { variant: "success" }
      );
      setItem(initialContact);
      onContactCreated(contact);
    } catch (e) {
      const er = (e as any);
      if (er.response.data.key === "creatingEntityError") {
        const key = "contactErrorHandler." + er.response.data.key;
        const message = "validation" in er ? er.validation : t(key);
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else {
        const message =
          "validation" in er ? er.validation : t("messages.defaultError");
        snackbar.enqueueSnackbar(message, { variant: "error" });
      }
    } finally {
      onClose();
      setOpenCreateExistingEmail(false);
    }    
  }

  const handleUpdateContact = () => {
    if (item.fullName === "" || item.contactTypeId === 0 || item.email === '') {
      setError(true);
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      return;
    }

    (async () => {
      try {
        const contact = await contactGateway.update({...item, id: existingEmailId}, entity, entityId as string);
        snackbar.enqueueSnackbar(
          t("contactUpdateDialog.updateSucceded", { id: contact.id }),
          { variant: "success" }
        );
        setItem(contact);
        onContactUpdated(contact);
      } catch (e) {
        const er = (e as any);
        if (er.response.data.key === "updatingError") {
          const key = "contactErrorHandler." + er.response.data.key;
          const message =
            "validation" in er
              ? er.validation
              : t(key, { id: er.response.data.id });
          snackbar.enqueueSnackbar(message, { variant: "error" });
        } else {
          const message =
            "validation" in er ? er.validation : t("messages.defaultError");
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      } finally {
        onClose();
        setOpenCreateExistingEmail(false);
      }
    })();
  };

  const handleCreateContact = () => {
    if (item.fullName === "" || item.contactTypeId === 0 || item.email === '') {
      setError(true);
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      return;
    }

    (async () => {
      try {
        const existingEmailId = await contactGateway.checkIfEmailExists(item.email)
        if (existingEmailId) {
          setExistingEmailId(existingEmailId)
          setOpenCreateExistingEmail(true);
          return;
        }
        await createContact();
      } catch (e) {
        const er = (e as any);
          const message =
            "validation" in er ? er.validation : t("messages.defaultError");
          snackbar.enqueueSnackbar(message, { variant: "error" });
        
      }
    })();
  };

  useEffect(() => {
    (async () => {
      const data = await contactTypesGateway.findAll();
      setContactTypes(data);
    })();
  }, [open, contactTypesGateway]);

  useEffect(() => {
    setError(false);
    setItem(initialContact);
    setExistingEmailId(0);
  }, [open])

  return (
    <Box>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{t("contactAddDialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("contactAddDialog.content")}</DialogContentText>
          <ContactForm
          contactTypes={contactTypes}
          handleChange={handleChange}
          item={item}
          clickContactTypeAddButton={handleClickAddButon}
          propagateError={error}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleCreateContact} color="primary">
            {t("common.create")}
          </Button>
        </DialogActions>
      </Dialog>
      <ContactTypeAddDialog
        open={openAddContactType}
        onClose={closeAddContactType}
        onContactTypeCreated={handleContactTypeCreate}
      />
      <UpdateExistingContactDialog
      open={openUpdateExistingEmail}
      onCreate={handleCreateContactDuplicateEmail}
      onUpdate={handleUpdateContact}
      onClose={()=>{setOpenCreateExistingEmail(false)}}
      />
    </Box>
  );
};

export default ContactAddDialog;
