import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import BotMissingDatesSuppliesForm from "./bot-missing-dates-add-supply-form";

const BotMissingDatesSupplies: FC<{
  open: boolean;
  onClose: () => void;
  onAccept: (fuelSupplyId: number, reference: string) => void;
}> = ({ open, onClose, onAccept }) => {
  const { t } = useTranslation();
  const [fuelSupplyId, setFuelSupplyId] = useState<number>(0);
  const [fuelSupplyReference, setFuelSupplyReference] = useState<string>("");
  const handleChangeFuelSupply = (
    fuelSupplyId: number,
    parsedFuelSupplyReference: string
  ) => {
    setFuelSupplyId(fuelSupplyId);
    setFuelSupplyReference(parsedFuelSupplyReference);
  };
  return (
    <Dialog open={open}>
      <DialogTitle>{t("botMissingDate.dialogTitle")}</DialogTitle>
      <DialogContent>
        <BotMissingDatesSuppliesForm
          onChangeFuelSupply={handleChangeFuelSupply}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default">
          {t("common.close")}
        </Button>
        <Button
          onClick={() => {
            onAccept(fuelSupplyId, fuelSupplyReference);
          }}
          type="submit"
          color="primary"
        >
          {t("botMissingDate.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BotMissingDatesSupplies;
