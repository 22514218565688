import React, { FC, useContext } from "react";
import { TableRow } from "@material-ui/core";
import { StateContext } from "./context";
import FSTableBodyCell from "./table-body-cell";

interface IFSTableBodyRowProps {
    item: any;
}

const FSTableBodyRow: FC<IFSTableBodyRowProps> = ({ item }) => {
    const state = useContext(StateContext);

    const handleClickRow = () => {
        if (typeof state.onRowClick === 'function') {
            state.onRowClick(item);
        }
    }

    return (
        <TableRow onClick={handleClickRow}>
            {
                state.columns.map((col, index) => (
                    <FSTableBodyCell key={'row_column_' + index} column={col} item={item} />
                ))
            }
        </TableRow>
    );
}

export default FSTableBodyRow;