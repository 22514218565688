import React, { FC, useState, useEffect } from "react";
import ICurrency from "../../gateways/currency.interface";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import FormTextField from "../../components/form-text-field";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import CurrencyGateway from "../../gateways/currency.gateway";

interface ICurrencyUpsertDialogProps {
    open: boolean;
    item: ICurrency | undefined;
    onItemUpsert: (item: ICurrency, isNew: boolean) => void;
    onClose: () => void;
}

const initialItem: ICurrency = {
    id: 0,
    isoCode: '',
    name: ''
};

const CurrencyUpsertDialog: FC<ICurrencyUpsertDialogProps> = (props) => {
    const { t } = useTranslation();
    const [item, setItem] = useState<ICurrency>(initialItem);
    const snackbar = useSnackbar();
    const currencyGateway = useIoC(CurrencyGateway);

    const createCurrency = async (item: ICurrency) => {
        const currency = await currencyGateway.create(item);
        snackbar.enqueueSnackbar(t('currencyUpsertDialog.createSucceded', { id: currency.id, name: currency.name }), { variant: "success" });
        props.onItemUpsert(currency, true);
    };

    const updateCurrency = async (item: ICurrency) => {
        const currency = await currencyGateway.update(item.id, item);
        snackbar.enqueueSnackbar(t('currencyUpsertDialog.updateSucceded', { id: currency.id, name: currency.name }), { variant: "success" });
        props.onItemUpsert(currency, false);
    };

    const upsertCurrency = async (item: ICurrency) => {
        try {
            if (!props.item) {
                await createCurrency(item);
            } else {
                await updateCurrency(item);
            }
        } catch (e) {
            const er = e as any;
            const key = 'currencyErrorHandler.' + er.response.data.key; 
            if (er.response.data.key === 'creatingEntityError'){    
                const message = "validation" in er ? er.validation : t(key);
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }else if (er.response.data.key === 'updatingError'){
                const message = "validation" in er ? er.validation : t(key, {id: er.response.data.id});
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }else{
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose();
        }
    };

    const handleAccept = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!item.name || !item.isoCode) {
            event.preventDefault();
            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        }

        upsertCurrency(item);
    };

    const handleChange = (name: string, value: any) => {
        setItem({ ...item, [name]: value });
    };

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    useEffect(() => {
        if (props.item) {
            setItem(props.item);
        } else {
            setItem(initialItem);
        }
    }, [props.item]);

    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }}>
            <DialogTitle>{!props.item ? t('currencyUpsertDialog.createTitle') : t('currencyUpsertDialog.updateTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{!props.item ? t('currencyUpsertDialog.createContent') : t('currencyUpsertDialog.updateContent')}</DialogContentText>
                <FormTextField
                    name='name'
                    label={t('common.name')}
                    type='text'
                    validator={validateRequired}
                    errorText={t('messages.requiredField') as string}
                    value={item.name}
                    onChange={handleChange}
                    autoFocus
                    fullWidth
                    required />
                <FormTextField
                    name='isoCode'
                    label={t('common.isoCode')}
                    type='text'
                    validator={validateRequired}
                    errorText={t('messages.requiredField') as string}
                    value={item.isoCode}
                    onChange={handleChange}
                    fullWidth
                    required />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onClose() }} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleAccept} color="primary">{t('common.accept')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CurrencyUpsertDialog;