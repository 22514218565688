import React, { FC, useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useUser } from "../../contexts/user.context";

export const SignInPage: FC = () => {
    
    const [,userDispatch] = useUser();
    const params = useLocation();
    const history = useHistory();
    
    useEffect(() => {
        (async () => {
            const f = new URLSearchParams(params.search);
            const token = f.get('access_token');
            const username = f.get('username');
            const roles = f.get('roles');

            if(username){
                userDispatch({
                    type: 'set-user',
                    user: username
                });
            }
            if(roles){
                userDispatch({
                    type: 'set-role',
                    role: roles.split(',')
                });
            }
            if(token){
                userDispatch({
                    type: 'set-token',
                    token
                });
                history.push('/');
            }
        })();
    });
    return null;
}