import React from "react";
import {  Switch, Route, useRouteMatch } from "react-router";
import ElectricContractList from "./electric-contract-list";
import ElectricContractDetail from "./electric-contract-detail";

const ElectricContractRouter = () => {
    
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/`} exact>
                <ElectricContractList/>
            </Route>
            <Route path={`${path}/:electricContractId`} exact>
                <ElectricContractDetail/>
            </Route>
        </Switch>
    );
};
export default ElectricContractRouter;
