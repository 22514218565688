import React, { FC } from "react";
import { makeStyles, Theme, createStyles, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IRegisterFuelBillCreate } from "../../gateways/register-fuel-bill.interface";
import { IFuelContract } from "../../gateways/fuel-contract.interfaces";

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({

        gridItem: {
            borderSpacing: 2,
            padding: theme.spacing(1)
        }
    })
);

interface IRegisterFuelBillNotNetworkProps {
    item: IRegisterFuelBillCreate;
    tankDateSelector: (value: any) => void
    fuelContractInfo: (fuelContract: IFuelContract) => string;
    onChange: (name: string, value: any) => void;
    validateRequired: (value: any) => boolean;
    fuelContract: IFuelContract;
}

const RegisterFuelBillNotNetwork: FC<IRegisterFuelBillNotNetworkProps> = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid
            container
            justify="flex-start"
            alignItems="flex-start"
        >
            <Grid item xs={6} className={classes.gridItem}>
                <KeyboardDatePicker
                    format="dd/MM/yyyy"
                    margin="none"
                    fullWidth
                    id="tankDateSelector"
                    label={t('common.tankDate') as string}
                    value={props.item.tankDate}
                    onChange={props.tankDateSelector}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    required
                />
            </Grid>
            <Grid item xs={6} className={classes.gridItem}>
                <FormTextField
                    name='fuelContractId'
                    label={t('common.electricContractId') as string}
                    type='number'
                    validator={props.validateRequired}
                    errorText={t('messages.requiredField') as string}
                    value={props.item.fuelContractId}
                    disabled={true}
                    onChange={props.onChange}
                    fullWidth
                    required
                    adornmentLabel={props.fuelContractInfo(props.fuelContract)}
                />
            </Grid>
        </Grid>
    );
};

export default RegisterFuelBillNotNetwork;