export interface ICoordinatesReturn {
  latitude: number;
  longitude: number;
  formatted: string;
  id: number;
}
export const initialCoordinatesReturn: ICoordinatesReturn = {
  formatted: "",
  latitude: 0,
  longitude: 0,
  id: 0,
};
export interface ICoordinatesCreate {
  latitude: number;
  longitude: number;
}

export interface ICoordinatesEstCmplx {
  countryId?: number;
  establishmentId?: number;
  complexId: number;
  companyId: number;
  coordinates: ICoordinatesReturn;
  address: string;
  client_email: string;
  name: string;
  brandName: string;
  clientName: string;
  clientTypeId: number;
  isEst: boolean;
  locationId: number;
  sendBeforeValidating: boolean;
}

export const initialICoordinatesReturn: ICoordinatesReturn = {
  latitude: 0,
  longitude: 0,
  formatted: "",
  id: 0,
};

export const initialICoordinatesEstCmpls: ICoordinatesEstCmplx = {
  complexId: 0,
  companyId: 0,
  coordinates: initialCoordinatesReturn,
  address: "",
  client_email: "",
  clientName: "",
  clientTypeId: 0,
  isEst: false,
  locationId: 0,
  name: "",
  brandName: "",
  sendBeforeValidating: true,
};

export interface Coordinate {
  latitude: number;
  longitude: number;
}

export interface CoordinateTask {
  id: number;
  supply_type?: string;
  reference?: string;
  client_name: string;
  complex_name: string | null;
  establishment_name: string | null;
  address: string | null;
  coordinates: Coordinate | null;
}
