import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import useIoC from "../../contexts/ioc.context";
import CoordinatesGateway from "../../gateways/coordinates.gateway";
import { Coordinate } from "../../gateways/coordinates.interfaces";

export type PlaceAutocompleteProps = {
  id?: string;
  label: string;
  value?: string | null;
  onChange?: (value: string | null) => void;
};

const PlaceAutocomplete: React.FC<PlaceAutocompleteProps> = ({
  id,
  label,
  value,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<string[]>([]);
  const coordinatesGateway = useIoC(CoordinatesGateway);

  useEffect(() => {
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return;
    }

    const fetchPredictions = async () => {
      const response = await coordinatesGateway.autocompleteAddress(inputValue);

      // Asume que la respuesta tiene una estructura similar a la API de Google Geocoding
      if (response.status === "OK") {
        const result: string[] = response.predictions.map(
          (prediction: any) => prediction.description
        );

        setOptions(result);
      } else {
        setOptions([]);
      }
    };

    // Agregar el throttle
    const timeoutId = setTimeout(() => {
      fetchPredictions();
    }, 500);

    // Limpiar el timeout al desmontar el componente o cambiar el inputValue
    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputValue, value, coordinatesGateway]);

  return (
    <Autocomplete
      id={id}
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onChange?.(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
    />
  );
};

export default PlaceAutocomplete;
