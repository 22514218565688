import React, { FC } from "react";
import { makeStyles, Theme, createStyles, List, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import { IRegisterFuelBillCreate } from "../../gateways/register-fuel-bill.interface";

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5)
        },
        button: {
            margin: theme.spacing(0.5),

        },
        textField: {
            margin: theme.spacing(0.5)
        },
        root: {
            display: 'flex',
            overflowY: 'hidden'
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 1,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1
        },
        formControl: {
            marginBottom: '1rem',
            '& :last-child': {
                marginBottom: 0
            }
        },
        progress: {
            margin: theme.spacing(2),
        },

        gridItem: {
            borderSpacing: 2,
            padding: theme.spacing(1)
        }
    })
);

interface IRegisterFuelBillGasoilDieselProps {
    item: IRegisterFuelBillCreate;
    handleChange: (name: string, value: any) => void;
    validateRequired: (value: any) => boolean;
    currency: string;
    volumeUnit: string;
}

const RegisterFuelBillGasoilDiesel: FC<IRegisterFuelBillGasoilDieselProps> = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Grid
                    container
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='amount'
                            label={t('common.amount') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.amount}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={props.volumeUnit}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItem}>
                        <FormTextField
                            name='unitaryPrice'
                            label={t('common.unitaryPrice') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.unitaryPrice}
                            onChange={props.handleChange}
                            fullWidth
                            required
                            adornmentLabel={props.currency + '/' + props.volumeUnit}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default RegisterFuelBillGasoilDiesel;