import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";

@inject("axios")
export class SuppliesGateway {
  constructor(private axios: AxiosInstance) {}

  public async findById(clientId: number): Promise<any> {
    const url = `supplies/findById`;
    const response = await this.axios.post(url, {clientId});
    return response.data;
  }

}
