import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import i18n from "i18next"; //old importname = t ¿?
import {
  Coordinate,
  CoordinateTask,
  ICoordinatesCreate,
  ICoordinatesEstCmplx,
  ICoordinatesReturn,
  initialCoordinatesReturn,
} from "./coordinates.interfaces";

@inject("axios")
export default class CoordinatesGateway {
  constructor(private axios: AxiosInstance) {}

  async findEstAndCmplx(cupsList?: string[]): Promise<ICoordinatesEstCmplx[]> {
    const response = !!!cupsList
      ? await this.axios.post("coordinates/estAndCmplx")
      : await this.axios.post("coordinates/estAndCmplx", cupsList);
    return response.data;
  }
  async create(coordinates: ICoordinatesCreate): Promise<ICoordinatesReturn> {
    const response = await this.axios.post("coordinates", coordinates);
    return response.data;
  }
  async update(coordinates: ICoordinatesCreate): Promise<ICoordinatesReturn> {
    const response = await this.axios.post("coordinates", coordinates);
    return response.data;
  }

  async getCoordinates(address: string) {
    const encodedAddress = encodeURI(address);
    const response = await this.axios.post("coordinates/addressToCoordinates", {
      address: encodedAddress,
    });
    return response.data;
  }

  async geocode(address: string) {
    const response = await this.axios.get("coordinates/geocode", {
      params: {
        address,
      },
    });
    return response.data;
  }

  async getTasksCoordinates() {
    const response = await this.axios.get<CoordinateTask[]>(
      "coordinates/tasks"
    );
    return response.data;
  }

  async markAsCompletedTask(
    id: number,
    newAddress?: { address: string; coordinates: Coordinate }
  ) {
    await this.axios.post(
      `coordinates/tasks/${id}/mark-as-completed`,
      newAddress
    );
  }

  async getAddress(
    coordinates: string
  ): Promise<{ address: string; isSpain: boolean }> {
    const regex = /^(?<latitude>[^,]+),[\s]+(?<longitude>[^,]+)$/;
    const coordinatesRegex = regex.exec(coordinates);
    const createdCoordinates: ICoordinatesCreate =
      coordinatesRegex && coordinatesRegex.groups
        ? {
            latitude: parseFloat(coordinatesRegex.groups.latitude),
            longitude: parseFloat(coordinatesRegex.groups.longitude),
          }
        : { latitude: 0, longitude: 0 };
    if (
      createdCoordinates.latitude === 0 &&
      createdCoordinates.longitude === 0
    ) {
      return {
        address: "No se ha encontrado dirección asociada a esas coordenadas",
        isSpain: false,
      };
    }
    const response = await this.axios.post(
      "coordinates/address",
      createdCoordinates
    );
    return response.data;
  }

  async getAddressFromCoordinates(
    latitude: number,
    longitude: number
  ): Promise<{ address: string; isSpain: boolean }> {
    const response = await this.axios.post("coordinates/address", {
      latitude,
      longitude,
    });
    return response.data;
  }

  async autocompleteAddress(input: string): Promise<any> {
    const response = await this.axios.get("coordinates/autocomplete", {
      params: {
        input,
      },
    });
    return response.data;
  }

  async findById(id: number): Promise<ICoordinatesReturn> {
    const response = await this.axios.get(`coordinates/${id}`);
    return response.data;
  }
}
