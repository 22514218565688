import React, { FC } from "react";
import { withRouter, RouteComponentProps, Route, Switch } from "react-router";
import { useUser } from "../../contexts/user.context";
import PrometheusBotList from "./prometheus-bot-list";
import PrometheusJobDetail from "./prometheus-job-detail";
import PrometheusBotJobsList from "./prometheus-jobs-list";

const PrometheusIndex: FC<RouteComponentProps> = ({ match }) => {
    const [{ canViewMaintenanceMenu, subCanViewMaintenanceRestOfItems }] = useUser();
    return(
        <Switch>
            {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? <Route path={`${match.path}`} exact component={PrometheusBotList} /> : null}
            {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? <Route path={`${match.path}/:id`} exact component={PrometheusBotJobsList}/> : null}
            {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? <Route path={`${match.path}/:id/:name`} exact component={PrometheusJobDetail}/> : null}
        </Switch>
    );
}

export default withRouter(PrometheusIndex);