import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import SelectorField from "../../components/selector-field";
import {
  makeStyles,
  Theme,
  createStyles,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { IElectricSupply, IUpdateElectricSupply } from "../../gateways/electric-supply.interface";
import { IAccessType } from "../../gateways/access-type.interfaces";
import { IProviders } from "../../gateways/providers.interface";
import { KeyboardDatePicker } from "@material-ui/pickers";
import SupplyDateGroup from "../../components/supply-date-group";
import { emailValidationError } from "../../utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      paddingTop: theme.spacing(3),
    },
  })
);

interface IComplexDetailProps {
  supply: IElectricSupply | IUpdateElectricSupply;
  onChangeSupply: (name: string, value: any) => void;
  validateRequired: (value: any) => boolean;
  errorMissingData?: boolean;
  accessTypes?: IAccessType[];
  providers?: IProviders[];
  showAllFields?: boolean;
  onTelemedidaChange: () => void;
  onSendEmailChange?: () => void;
  onSendClientEmailChange?: () => void;
  onCounterCheckChange?: () => void;
  onProviderAddButtonClick?: () => void;
  onStartCaptureDateChange?: (value: any | undefined) => void;
  onValidationsDateChange?: (value: any | undefined) => void;
  onSeparatedMeterStartCaptureDateChange?: (value: any) => void;
  onSeparatedMeterStartValidationsDateChange?: (value: any) => void;
}

const ElectricSupplyForm: FC<IComplexDetailProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <FormTextField
        name="referencia"
        label={t("common.electricReference") as string}
        type="text"
        validator={props.validateRequired}
        error={props.errorMissingData}
        errorText={t("messages.requiredField") as string}
        value={props.supply.referencia ?? ""}
        onChange={props.onChangeSupply}
        autoFocus
        fullWidth
        required
      />
      <FormControlLabel
        control={
          <Checkbox
            color={"primary"}
            checked={props.supply.telemedida}
            onChange={props.onTelemedidaChange}
          />
        }
        label={t("common.telemetry") as string}
      />
      {props.showAllFields && props.onStartCaptureDateChange && (
        <div>
          {props.accessTypes && (
            <SelectorField
              name="accessTypeId"
              emptyValue={false}
              fullWidth
              required={false}
              inputLabel={t("common.access") as string}
              onChange={props.onChangeSupply}
              value={1}
              validator={props.validateRequired}
              values={props.accessTypes.map((value) => {
                return { id: value.id, value: value.name };
              })}
            />
          )}

          {props.providers && (
            <SelectorField
              name="providerId"
              emptyValue={true}
              fullWidth
              required={false}
              inputLabel={t("common.provider") as string}
              onChange={props.onChangeSupply}
              value={props.supply.providerId}
              values={props.providers.map((value) => {
                return { id: value.id, value: value.society };
              })}
              handleClickAddButon={props.onProviderAddButtonClick}
              disableError={true}
            />
          )}

          <Typography variant="h6" className={classes.subtitle}>
            {t("common.botConfigFields") as string}
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                color={"primary"}
                checked={props.supply.sendEmail}
                onChange={props.onSendEmailChange}
              />
            }
            label={t("common.sendEmail") as string}
          />

          <FormControlLabel
            control={
              <Checkbox
                color={"primary"}
                checked={props.supply.sendClientEmail}
                onChange={props.onSendClientEmailChange}
              />
            }
            label={t("common.sendClientEmail") as string}
          />

          <FormControlLabel
            control={
              <Checkbox
                color={"primary"}
                checked={props.supply.separatedMeterAccount}
                onChange={props.onCounterCheckChange}
              />
            }
            label={t("common.counterContract")}
          />

          <FormTextField
            name="code"
            label={t("common.code") as string}
            type="text"
            value={props.supply.code}
            onChange={props.onChangeSupply}
            fullWidth
          />

          <FormTextField
            disabled
            name="invoicesFolder"
            label={t("common.invoicesFolder") as string}
            type="text"
            value={props.supply.invoicesFolder}
            onChange={props.onChangeSupply}
            fullWidth
          />

          {props.supply.separatedMeterAccount &&(
          <FormTextField
            disabled
            name="separatedMeterInvoiceFolder"
            label={t("common.invoicesCounterFolder") as string}
            type="text"
            value={props.supply.separatedMeterInvoiceFolder ?? ""}
            onChange={props.onChangeSupply}
            fullWidth
          />
          )}

          <FormTextField
            name="emailNotification"
            label={t("common.client_email")}
            type="emailNotification"
            value={props.supply.emailNotification ?? ''}
            onChange={props.onChangeSupply}
            fullWidth
            error={emailValidationError(props.supply.emailNotification)}
            helperTextMessage={`Si hay más de 1 correo, separar con punto y coma, ejemplo: test1@test.com; test2@test.com`}
          />

          {props.onValidationsDateChange && props.onStartCaptureDateChange && (
            <SupplyDateGroup
              item={{
                startCaptureDate: props.supply.startCaptureDate,
                startValidationsDate: props.supply.startValidationsDate,
              }}
              onStartCaptureDateChange={props.onStartCaptureDateChange}
              onValidationsDateChange={props.onValidationsDateChange}
            />
          )}

          {props.supply.separatedMeterAccount &&(
            <SupplyDateGroup
              startCaptureDateLabel= {t("common.startCaptureCounterDate") as string}
              validationDateLabel= {t("common.startValidationsCounterDate") as string}
              item={{
                startCaptureDate: props.supply.separatedMeterStartCaptureDate,
                startValidationsDate: props.supply.separatedMeterStartValidationsDate,
              }}
              onStartCaptureDateChange={props.onSeparatedMeterStartCaptureDateChange!}
              onValidationsDateChange={props.onSeparatedMeterStartValidationsDateChange!}
            />
          )}
        </div>
        )}
      </div>
  );
};
export default ElectricSupplyForm;
