import React, { FC, Fragment, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import useIoC from '../../contexts/ioc.context';
import { BotsGateway } from '../../gateways/bots.gateway';
import { IBotTrackRO, ITrackingQuery } from '../../gateways/bots.interfaces';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useNavigator } from '../../contexts/navigator.context';
import SupplyBotsIcon from '@material-ui/icons/Autorenew';
import { SupplyBotDrawer } from './supply-bot.drawer';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

interface IRouteParams {
    id: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        paper: {
            marginTop: theme.spacing(3),
            width: '100%',
            overflowX: 'auto',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 650,
        },
        filters: {
            padding: '0.5rem 1rem',
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 180,
        },
    }),
);

export const SupplyBotHistory: FC<RouteComponentProps<IRouteParams>> = ({ match, history }) => {
    const [, navigatorDispatch] = useNavigator();
    const classes = useStyles();
    const id = (/^\d+$/g).test(match.params.id) ? parseInt(match.params.id) : -1;
    const botsGateway = useIoC(BotsGateway);
    const [tracks, setTracks] = useState<IBotTrackRO[]>([]);
    const [levels, setLevels] = useState<string[]>([]);

    async function search(query?: Partial<ITrackingQuery>) {
        const result = await botsGateway.getTracking({
            supplyBotId: id,
            levels,
            ...query,
        });
        setTracks(result);
    }

    function handleChangeLevel(event: React.ChangeEvent<{ value: unknown }>) {
        const value = event.target.value as string[];
        setLevels(value);
        search({ levels: value });
    }

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: 'Historico de ejecuciones',
                icon: SupplyBotsIcon
            }
        });
        navigatorDispatch({
            type: 'set-right-nav',
            component: (<SupplyBotDrawer id={id} tabActive="history" />)
        });
    }, []);

    useEffect(() => {
        if (id === -1) {
            history.push('/supply-bots');
            return;
        }

        search();
    }, [id]);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div className={classes.filters}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="level-label">Nivel</InputLabel>
                        <Select
                            id="level-select"
                            multiple
                            value={levels}
                            onChange={handleChangeLevel}
                        >
                            <MenuItem value="info">Información</MenuItem>
                            <MenuItem value="error">Error</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Mensaje</TableCell>
                            <TableCell align="right">Nivel</TableCell>
                            <TableCell align="right">Fecha</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tracks.map(track => (
                            <TableRow key={track.id}>
                                <TableCell component="th" scope="row">
                                    {track.message}
                                </TableCell>
                                <TableCell align="right">{track.level}</TableCell>
                                <TableCell align="right">{track.date}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
}