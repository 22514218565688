import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { IValidationCriteria } from "../../gateways/validation-criteria.interfaces";
import ValidationCriteriaItemDialog from "../validation-criteria/validation-criteria-item-dialog";
import FormTextField from "../../components/form-text-field";
import { IValidationGroup } from "../../gateways/validation-group.interfaces";


const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    fields: {
        marginBottom: theme.spacing(0.5)
    },
}));


interface IValidationCriteriaFormProps {
    item: IValidationGroup;
    onChange: (item: IValidationGroup) => void
}

const ValidationCriteriaForm: FC<IValidationCriteriaFormProps> = ({ item, onChange }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleAddFilter = () => {
        const validationCriteria: IValidationCriteria = {
            id: 0,
            nameProperty: '',
            criteriaOperations: []
        }
        item.validationCriterias.push(validationCriteria);
        onChange({...item});
    };

    const handleDeleteItem = (index: number) => {

        item.validationCriterias.splice(index, 1);
        
        onChange({...item});

    };

    const handleUpdate = (validationCriteria: IValidationCriteria, index: number) => {
        item.validationCriterias[index] = validationCriteria;

        onChange({...item});
    };

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    const handleChange = (name: string, value: any) => {
        const itemChaged = { ...item, [name]: value };
        onChange(itemChaged)
    };

    return (
        <>
            <FormTextField
                name='name'
                label={t('common.name') as string}
                type='text'
                validator={validateRequired}
                errorText={t('messages.requiredField') as string}
                value={item.name}
                onChange={handleChange}
                autoFocus
                fullWidth
                required
                className={classes.fields}
            />
            {
                item.validationCriterias.map((validationCriteria, index) => (
                    <ValidationCriteriaItemDialog
                        key={'validation_' + index}
                        validation={validationCriteria}
                        index={index}
                        onDeleteClick={handleDeleteItem}
                        onUpdate={handleUpdate}
                    />
                ))
            }
            <Button
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={handleAddFilter}
            >
                {t('validationGroupForm.add')}
            </Button>
        </>
    );
};

export default ValidationCriteriaForm;