export interface IUpdateEnergyDetail {
    consumedEnergy: number;
    secondConsumedEnergy?: number;
    electricityCost: number;
    secondElectricityCost?: number;
    reactiveEnergy: number;
    registerElectricBillId: number;
}

export interface IEnergyDetail {
    id: number;
    consumedEnergy: number;
    secondConsumedEnergy?: number;
    electricityCost: number;
    secondElectricityCost?: number;
    reactiveEnergy: number;
    registerElectricBillId: number;
}

export const initialIEnergyDetail: IEnergyDetail[] = [
    { id: 0, secondConsumedEnergy: 0, secondElectricityCost: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 },
    { id: 0, secondConsumedEnergy: 0, secondElectricityCost: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 },
    { id: 0, secondConsumedEnergy: 0, secondElectricityCost: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 },
    { id: 0, secondConsumedEnergy: 0, secondElectricityCost: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 },
    { id: 0, secondConsumedEnergy: 0, secondElectricityCost: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 },
    { id: 0, secondConsumedEnergy: 0, secondElectricityCost: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 },];
export const initialICreateEnergyDetail: ICreateEnergyDetail[] = [
    { secondConsumedEnergy: 0, secondElectricityCost: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 },
    { secondConsumedEnergy: 0, secondElectricityCost: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 },
    { secondConsumedEnergy: 0, secondElectricityCost: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 },
    { secondConsumedEnergy: 0, secondElectricityCost: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 },
    { secondConsumedEnergy: 0, secondElectricityCost: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 },
    { secondConsumedEnergy: 0, secondElectricityCost: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 },];

export interface ICreateEnergyDetail {
    consumedEnergy: number;
    secondConsumedEnergy?: number;
    electricityCost: number;
    secondElectricityCost?: number;
    reactiveEnergy: number;
    registerElectricBillId: number;
}