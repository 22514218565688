import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IWaterSupplyInterface } from "../../gateways/water-supply.interfaces";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    crossButton: {
      position: "absolute",
      top: 7,
    },
    buttonGoDetail: {
        marginRight: 20,
        marginBottom: 20,
        borderSpacing: 4
    }
  })
);

export interface FuelSupplyGoDetailDialogProps {
  open: boolean;
  item: IWaterSupplyInterface | undefined;
  navigateToNextWindow: (item: IWaterSupplyInterface) => void;
  handleClose: () => void;
}

const WaterSupplyGoDetailDialog: FC<FuelSupplyGoDetailDialogProps> = (
  props
) => {
  const [componentDisabled, setComponentDisabled] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const handleGoDetail = () => {
    if (!props.item) {
      return;
    }
    setComponentDisabled(true);
    props.navigateToNextWindow(props.item);
    setComponentDisabled(false);
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {t("common.navigateToDetail")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("waterSupplyGoDetailDialog.content")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.handleClose ? (
          <IconButton
          aria-label="close"
          onClick={props.handleClose}
          className={classes.crossButton}
          >
          <CloseIcon/>
          </IconButton>
        ) : null}
                
          <Button 
              onClick={handleGoDetail} 
              color="primary" 
              variant="outlined"
              disabled={componentDisabled} 
              className={classes.buttonGoDetail}
              >
                  {t('common.navigateToDetail')}
          </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WaterSupplyGoDetailDialog;
