import React, { FC } from "react";
import { List, ListItem, ListItemText, ListItemIcon, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link as ReactRouterLink } from "react-router-dom";
import { useBreadCrumbName } from "../contexts/breadCrumbNames.context";
import AdbIcon from '@material-ui/icons/Adb';

interface IDrawerProps {
    onOpenBotDialog: () => void;
    icon: JSX.Element;
};

const ContractDrawer: FC<IDrawerProps> = (props) => {
    const { names: BreadCrumbNames } = useBreadCrumbName();
    const { t } = useTranslation();

    return (
        <div>
            <List>
                <ListItem>
                    <Grid container direction="column" alignItems="center">
                        <Grid>
                            {props.icon}
                        </Grid>
                        <Grid>
                            <ListItemText primary={BreadCrumbNames['clients']} />
                        </Grid>
                    </Grid>
                </ListItem>

                <ListItem button onClick={props.onOpenBotDialog} >
                    <ListItemIcon><AdbIcon /></ListItemIcon>
                    <ListItemText primary={t('rightNavigation.connectBot')} />
                </ListItem>
            </List>
        </div>
    );
};

export default ContractDrawer;