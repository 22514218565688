import { createStyles, List, makeStyles, Theme, TextField, Grid, Input  } from "@material-ui/core";
import React, { useCallback } from "react";
import { FC, useEffect, useState } from "react";
import useIoC from "../../contexts/ioc.context";
import { MongoGateway } from "../../gateways/mongo.gateway";
import {
  IMongoBotMissingDatesDisplay,
} from "../../gateways/mongo.interfaces";
import { useTranslation } from "react-i18next";
import CenteredCircularProgress from "../../components/centered-circular-progress";
import AlertBox from "../../components/alert-box";
import { S3Gateway } from "../../gateways/s3.gateway";
import { useSnackbar } from "notistack";
import BotMissingDatesFields from "./bot-missing-dates-fields";
import moment from 'moment';
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paginationItem: {
      marginTop: theme.spacing(2),
      justifyContent: "center",
      display: "flex",
    },
    right: {
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(3)
    }
  })
);
const BotMissingDatesList: FC = () => {
  const classes = useStyles();
  const mongoGateway = useIoC(MongoGateway);
  const s3Gateway = useIoC(S3Gateway);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [isDataEmpty, setIsDataEmpty] = useState<boolean>(false);
  const [totalOfInformation, setTotalOfInformation] = useState<
    IMongoBotMissingDatesDisplay[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const [item, setItem] = useState<IMongoBotMissingDatesDisplay[]>([]);
  const [loadingRow, setLoadingRow] = useState<string>("");
  const [searchItem, setSearchItem] = useState<string>("");
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const computeNumberOfPages = (numberOfItems: number) => {
    const numberOfPages =
      numberOfItems % 10 === 0
        ? numberOfItems / 10
        : Math.floor(numberOfItems / 10) + 1;
    return numberOfPages;
  };
  const computeSliceValues = (page: number) => {
    return { topValue: page * 10, initValue: page * 10 - 10 };
  };

  const queryAllMissingInvoicesMongo = async() => {
    const queryResult: IMongoBotMissingDatesDisplay[] = (
      await mongoGateway.findAll({
        dbCollection: "missingDates",
        dbName: "ebmMaintainment",
        where: [
          {$match: { $or: [ {endDate: ""}, {endDate: null}, {initDate: ""},{initDate: null}, {issueDate: ""},{issueDate: null}, {supplyId: 0, platform: "disa - fg"}]}},
          {$group: {_id: "$invoiceNumber", "doc": {"$first":"$$ROOT"}}},
          {"$replaceRoot":{"newRoot":"$doc"}}
        ]
      })
    );
    return queryResult;
  }

  const getNearValidDate = (obj: IMongoBotMissingDatesDisplay) => {
    let date = moment(obj.issueDate);

    if (!date.isValid())
    {
      date = moment(obj.endDate);
    }

    if (!date.isValid())
    {
      date = moment(obj.initDate);
    }

    return date;

  }

  const SortByDates = (data: IMongoBotMissingDatesDisplay[]) => {
    return data.sort(function(a: IMongoBotMissingDatesDisplay, b: IMongoBotMissingDatesDisplay) {
      let firstDateA = getNearValidDate(a);
      let firstDateB = getNearValidDate(b);

      if (firstDateA.isValid() && firstDateB.isValid())
      {
        //both dates are valid
        if (firstDateA.isAfter(firstDateB))
          return -1;
        if (firstDateA.isBefore(firstDateB))
          return 1;
      }
      return 0;
    });
  }

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      const queryResult = SortByDates(await queryAllMissingInvoicesMongo());

      setTotalOfInformation(queryResult);
      setNumberOfPages(computeNumberOfPages(queryResult.length));
      setItem(
        queryResult.slice(0, 10).map((i) => {
          return {
            ...i,
            issueDate: !!!i.issueDate ? null : new Date(i.issueDate),
            initDate: !!!i.initDate ? null : new Date(i.initDate),
            endDate: !!!i.endDate ? null : new Date(i.endDate),
          };
        })
      );
      setIsDataLoading(false);
      setIsDataEmpty(queryResult.length === 0);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const queryResult = SortByDates(await queryAllMissingInvoicesMongo())
      .filter((val: any) => {
        if(searchItem === "") return val;
        else if(val.invoiceNumber.toLowerCase().includes(searchItem.toLowerCase())) return val;
      });
      setTotalOfInformation(queryResult);
      setNumberOfPages(computeNumberOfPages(queryResult.length));
      setItem(
        queryResult.slice(0, 10).map((i) => {
          return {
            ...i,
            issueDate: !!!i.issueDate ? null : new Date(i.issueDate),
            initDate: !!!i.initDate ? null : new Date(i.initDate),
            endDate: !!!i.endDate ? null : new Date(i.endDate),
          };
        })
      );
    })();
  }, [searchItem]);

  const handleChangePage = (event: any, value: number) => {
    setCurrentPage(value);
    const pageLimits =
      value === numberOfPages
        ? { initValue: value * 10 - 10, topValue: totalOfInformation.length }
        : computeSliceValues(value);
    setItem(
      totalOfInformation
        .slice(pageLimits.initValue, pageLimits.topValue)
        .map((i) => {
          return {
            ...i,
            issueDate: !!!i.issueDate ? null : new Date(i.issueDate),
            initDate: !!!i.initDate ? null : new Date(i.initDate),
            endDate: !!!i.endDate ? null : new Date(i.endDate),
          };
        })
    );
  };

  const handleChangeDate = useCallback(
    (event: any, id: string | null | undefined) => {
      if (!id) {
        return;
      }
      const rgx = /(?<name>[\S]+)->(?<idNumber>[\S]+)/;
      const regexResult = rgx.exec(id);
      if (!!!regexResult || !!!regexResult.groups) {
        return;
      }
      const originId = regexResult.groups.idNumber;
      const name = regexResult.groups.name;
      setItem((prevItem) =>
        prevItem.map((handleChangeDateItem) => {
          if (handleChangeDateItem.originId != originId) {
            return handleChangeDateItem;
          } else {
            return { ...handleChangeDateItem, [name]: event };
          }
        })
      );
    },
    []
  );

  const handleChange = useCallback(
    (name: string, value: any, id: string | null | undefined) => {
      if (!id) {
        return;
      }
      setItem((prevItem) =>
        prevItem.map((handleChangeItem) => {
          if (handleChangeItem.originId !== id) {
            return handleChangeItem;
          } else {
            return { ...handleChangeItem, [name]: value };
          }
        })
      );
    },
    []
  );

  const handleClickDescriptionIcon = useCallback(
    async (itemToProcess: IMongoBotMissingDatesDisplay) => {
      const link = await s3Gateway.getUrl(itemToProcess.url);
      window.open(link, "_blank");
    },
    []
  );
  const handleClickSave = useCallback(
    async (
      itemToProcess: IMongoBotMissingDatesDisplay,
      currPage: number,
      currNPages: number
    ) => {
      setLoadingRow(itemToProcess._id);
      try {
        if (
          moment(itemToProcess.initDate).isAfter(
            moment(itemToProcess.endDate)
          ) ||
          moment(itemToProcess.initDate).isAfter(
            moment(itemToProcess.issueDate)
          ) ||
          moment(itemToProcess.endDate).isAfter(
            moment(itemToProcess.issueDate)
          ) ||
          moment().isBefore(moment(itemToProcess.initDate)) ||
          moment().isBefore(moment(itemToProcess.endDate)) ||
          moment().isBefore(moment(itemToProcess.issueDate))
        ) {
          snackbar.enqueueSnackbar(t("messages.dateRangeError"), {
            variant: "error",
          });
          setLoadingRow("");
          return;
        }
        if (
          (itemToProcess.platform?.toLowerCase() === "disa - fg" &&
            !itemToProcess.supplyId) ||
          !!!itemToProcess.issueDate ||
          !!!itemToProcess.initDate ||
          !!!itemToProcess.endDate
        ) {
          snackbar.enqueueSnackbar(t("messages.requiredFields"), {
            variant: "error",
          });
          setLoadingRow("");
          return;
        }
        mongoGateway
          .editCollections({
            dbCollection: "missingDates",
            dbName: "ebmMaintainment",
            id: itemToProcess._id,
            item: {
              originDataBase: itemToProcess.originDataBase,
              originCollection: itemToProcess.originCollection,
              originId: itemToProcess.originId,
              invoiceNumber: itemToProcess.invoiceNumber,
              cups: itemToProcess.cups,
              url: itemToProcess.url,
              issueDate: itemToProcess.issueDate.toISOString(),
              initDate: itemToProcess.initDate.toISOString(),
              endDate: itemToProcess.endDate.toISOString(),
              supplyId: itemToProcess.supplyId,
            },
            where: { invoiceNumber: itemToProcess.invoiceNumber },
          })
          .then(() => {
            let itemToSet: IMongoBotMissingDatesDisplay[] = [];
            let nOfPages = 0;
            let newCurrPage = currPage;
            setTotalOfInformation((prevTotalOfInformation) => {
              const filteredValues: IMongoBotMissingDatesDisplay[] =
                prevTotalOfInformation.filter(
                  (i: IMongoBotMissingDatesDisplay) =>
                    i._id !== itemToProcess._id
                );
              nOfPages = computeNumberOfPages(filteredValues.length);
              if (currNPages !== nOfPages && currPage === currNPages) {
                newCurrPage = nOfPages;
              }
              const sliceLimits =
                newCurrPage === nOfPages
                  ? {
                      initValue: newCurrPage * 10 - 10,
                      topValue: filteredValues.length,
                    }
                  : computeSliceValues(newCurrPage);
              itemToSet = filteredValues.slice(
                sliceLimits.initValue,
                sliceLimits.topValue
              );
              return filteredValues;
            });
            setCurrentPage(newCurrPage);
            setNumberOfPages(nOfPages);
            setItem(
              itemToSet.map((value: IMongoBotMissingDatesDisplay) => {
                return {
                  ...value,
                  issueDate: !!!value.issueDate
                    ? null
                    : new Date(value.issueDate),
                  initDate: !!!value.initDate ? null : new Date(value.initDate),
                  endDate: !!!value.endDate ? null : new Date(value.endDate),
                };
              })
            );
            snackbar.enqueueSnackbar(t("botMissingDates.success"), {
              variant: "success",
            });
          });
      } catch (e) {
        snackbar.enqueueSnackbar(t("messages.defaultError"), {
          variant: "error",
        });
        return;
      }
    },
    []
  );

  if (isDataLoading) {
    return <CenteredCircularProgress />;
  }
  if (isDataEmpty) {
    return <AlertBox variant="info">{t("common.noItems")}</AlertBox>;
  }
  return (
    <div>

      <Grid container justify="flex-end" className={classes.right}>
        <TextField 
          id="outlined-basic" 
          label="Find by invoice number" 
          variant="outlined" 
          size="small"
          onChange= { (event: any) => {
            setSearchItem(event.target.value);
          }}
        />
      </Grid>

      <List>
        {item.map((rendering, i) => {
          return (
            <BotMissingDatesFields
              focus={false}
              fields={rendering}
              onChangeDate={handleChangeDate}
              onOpenFile={handleClickDescriptionIcon}
              onSave={handleClickSave}
              key={`botMissingDatesKey->${i}`}
              loadingRow={loadingRow}
              currentNumberOfPages={numberOfPages}
              currentPage={currentPage}
              isDisaFg={
                rendering.platform
                  ? rendering.platform.toLowerCase() === "disa - fg"
                    ? true
                    : false
                  : false
              }
              onChange={handleChange}
            />
          );
        })}
      </List>
      <Pagination
        className={classes.paginationItem}
        count={numberOfPages}
        variant="outlined"
        color="primary"
        onChange={handleChangePage}
        page={currentPage}
      />
    </div>
  );
};
export default BotMissingDatesList;
