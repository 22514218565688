import React, { useMemo, useEffect, useState, Fragment } from 'react';
import { Typography, Card, CardContent, makeStyles, createStyles, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, ListItem, ListItemIcon, ListItemText, List, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, ListItemAvatar, Avatar } from '@material-ui/core';
import qs from 'query-string';
import moment from 'moment';

import InfoIcon from '@material-ui/icons/Info';
import FuelIcon from '@material-ui/icons/LocalGasStation';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import CommentIcon from '@material-ui/icons/Comment';
import CachedIcon from '@material-ui/icons/Cached';
import AndroidIcon from '@material-ui/icons/Android';

import { yellow, red, green, blue, grey } from '@material-ui/core/colors';
import { Link, RouteComponentProps, withRouter, Switch, Route } from 'react-router-dom';
import { useNavigator } from '../../contexts/navigator.context';
import { useTranslation } from 'react-i18next';
import BotIcon from '@material-ui/icons/Android';
import clsx from 'clsx';
import useIoC from '../../contexts/ioc.context';
import { ClientsGateway } from "../../gateways/clients.gateway";
import { DashboardGateway } from '../../gateways/dashboard.gateway';
import { IDashboardInfo, IDashboardToDisplay, BotTrackDto, ISuppliesStateInterface } from '../../gateways/dashboard.interfaces';
import { IClient } from '../../gateways/clients.interfaces';
import { getClippingParents } from '@fullcalendar/core';
import clientList from '../clients/client-list';
import {DashboardForm} from './dashboard-form';
import CenteredCircularProgress from '../../components/centered-circular-progress';
import { useUser } from '../../contexts/user.context';

const useStyles = makeStyles((theme) => createStyles({
    root: {
        marginBottom: theme.spacing(2),
    },
}));

const ClientSupplyBotDialog: React.FC<{ open: boolean, onClose?: () => void, clientId: number, supplyId?: number, fuelSupply?: boolean }> =
    ({ onClose, open, clientId, supplyId, fuelSupply }) => {
        const [comment, setComment] = useState('');
        const [dashBoardTracks, setDashboardTracks] = useState<BotTrackDto[]>([]);
        const dashboardGateway = useIoC(DashboardGateway);
        const { t } = useTranslation();

        const handleSaveComment = () => {

        };

        useEffect(() => {
            setComment('');
            (async () => {
                if (supplyId) {
                    const tracksToDisplay = await dashboardGateway.getBotMessages(clientId, supplyId, fuelSupply);
                    if (tracksToDisplay.length > 0) {
                        setDashboardTracks(tracksToDisplay);
                    } else {
                        setDashboardTracks([{
                            botTrackingId: 0,
                            date: new Date(),
                            level: 'info',
                            message: t('messages.noTracksFound'),
                            preconfigId: 0,
                            supplyConnectionBotId: 0,
                        }])
                    }
                }
            })();

        }, [open]);

        return supplyId ? (
            <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Comentarios</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <List>
                            {
                                dashBoardTracks.map(dashBoardTrack => (
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <CommentIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                dashBoardTrack.botTrackingId !== 0 ?
                                                    `bot - ${moment(dashBoardTrack.date).format("DD/MM/YYYY HH:mm:ss")}` :
                                                    `dashboard - ${moment(dashBoardTrack.date).format("DD/MM/YYYY HH:mm:ss")}`
                                            }
                                            secondary={`${dashBoardTrack.message}`
                                            } />
                                    </ListItem>
                                ))
                            }
                        </List>
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Escribir un nuevo comentario"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cerrar
                </Button>
                    <Button onClick={onClose} color="primary">
                        Guardar comentario
                </Button>
                </DialogActions>
            </Dialog>
        ) :
            (<div></div>)
    };


const ClientBotDetailDashboard: React.FC<{ client: { id: number, name: string } }> = ({ client }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [supplyTypes, setSupplyTypes] = useState<any[]>([]);
    const [selectedSupply, setSelectedSupply] = useState<any>(null);
    const [suppliesToDisplay, setSuppliesToDisplay] = useState<IDashboardToDisplay[][]>([]);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const handleCloseSupplyDialog = () => setSelectedSupply(null);
    const handleShowSupplyDialog = (supply: any) => setSelectedSupply(supply);
    const dashboardGateway = useIoC(DashboardGateway);

    useEffect(() => {
        setSupplyTypes([
            { id: 1, name: 'Electricidad' },
            { id: 2, name: 'Fuel' },
        ]);
    }, []);

    const groupBy = (objectArray: any, property: string, porpertyOfProperty?: string, propertyOfPropertyOfProperty?: string) => {
        return objectArray.reduce((acc: any, obj: any) => {
            const key = propertyOfPropertyOfProperty && porpertyOfProperty ?
                obj[property][porpertyOfProperty][propertyOfPropertyOfProperty] :
                porpertyOfProperty ?
                    obj[property][porpertyOfProperty] :
                    obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    useEffect(() => {
        (async () => {
            setIsDataLoading(true);
            const dashboardInfos = await dashboardGateway.findAllByClientId(client.id);
            const getClientBotSuppliesExecutionState =
                await dashboardGateway.getClientBotSuppliesExecutionState(client.id);
            const getGroupedClientBotSuppliesExecutionStateElectric =
                groupBy(getClientBotSuppliesExecutionState.electricSuppliesState, 'supplyId');
            const getGroupedClientBotSuppliesExecutionStateFuel =
                groupBy(getClientBotSuppliesExecutionState.fuelSuppliesState, 'supplyId');
            let suppliesToDisplay: IDashboardToDisplay[][] = [[], []];
            for (let supply of dashboardInfos) {
                if (!supply.fuelSupply) {
                    suppliesToDisplay[0].push({
                        supplyId: supply.supplyId,
                        cups: supply.cups,
                        lastCoveredDate: supply.lastCoveredDate,
                        lastSupplyInvoice: supply.lastSupplyInvoice,
                        lastInvoiceDate: supply.lastInvoiceDate,
                        lastInvoicePeriod: supply.lastInvoicePeriod,
                        lastRevisionDate: supply.lastRevisionDate,
                        state: getGroupedClientBotSuppliesExecutionStateElectric[supply.supplyId][0].state,
                        supplyName: supply.supplyName,
                    });
                } else {
                    suppliesToDisplay[1].push({
                        supplyId: supply.supplyId,
                        cups: supply.cups,
                        lastSupplyInvoice: supply.lastSupplyInvoice,
                        lastCoveredDate: supply.lastCoveredDate,
                        lastInvoiceDate: supply.lastInvoiceDate,
                        lastInvoicePeriod: supply.lastInvoicePeriod,
                        lastRevisionDate: supply.lastRevisionDate,
                        state: getGroupedClientBotSuppliesExecutionStateFuel[supply.supplyId][0].state,
                        supplyName: supply.supplyName,
                        fuelSupply: supply.fuelSupply,
                    });
                }
            }
            setSuppliesToDisplay(suppliesToDisplay);
            setIsDataLoading(false);
        })();
    }, [client]);

    return client ? (
        <section className={classes.root}>
            {
                isDataLoading &&
                <CenteredCircularProgress />
            }
            {
                !isDataLoading &&
                <DashboardForm
                    client={client}
                    handleShowSupplyDialog={handleShowSupplyDialog}
                    suppliesToDisplay={suppliesToDisplay}
                    supplyTypes={supplyTypes}
                />
            }

            <ClientSupplyBotDialog
                open={selectedSupply !== null}
                onClose={handleCloseSupplyDialog}
                clientId={client.id}
                supplyId={selectedSupply ? selectedSupply.supplyId : undefined}
                fuelSupply={selectedSupply ? selectedSupply.fuelSupply : undefined}
            />

        </section >
    ) : (
            <div>Cargando</div>
        )
};

const BotsDashboard: React.FC<RouteComponentProps> = ({ match, location }) => {
    const [{ canViewBotsMenu }] = useUser();
    const [, navigatorDispatch] = useNavigator();
    const { t } = useTranslation();
    const [clients, setClients] = useState<{ id: number, name: string, state: number }[]>([]);
    const { clientId } = qs.parse(location.search);
    const client = useMemo(() => canViewBotsMenu && clientId ? clients.find(x => x.id.toString() === clientId) || null : null, [clientId, clients]);
    const clientsGateway = useIoC(ClientsGateway);
    const dashboardGateway = useIoC(DashboardGateway);


    const drawer = useMemo(() => (
        <div>
            <List>
                {canViewBotsMenu ? clients.map((item) => {
                    const StateIcon = [CheckIcon, WarningIcon, ErrorIcon, CachedIcon, AndroidIcon, AndroidIcon][item.state];
                    const stateColor = [green[600], yellow[600], red[600], blue[600], grey[400], green[600]][item.state];

                    return (
                            <ListItem button component={Link} to={`${match.url}?clientId=${item.id}`} selected={client !== null && client.id === item.id}>
                                <ListItemIcon>
                                    <StateIcon style={{ color: stateColor }} />
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                            </ListItem>
                    );
                }) : <Fragment/>}
            </List>
        </div>
    ), [clients, client]);

    useEffect(() => {
        if (canViewBotsMenu)
            navigatorDispatch({
                type: 'set-header',
                header: {
                    title: client ? t('dashboards.botTitle') + client.name : t('dashboards.botsTitle'),
                    icon: BotIcon
                }
            });
    }, [client]);

    useEffect(() => {
        if (canViewBotsMenu)
            navigatorDispatch({
                type: 'set-right-nav',
                component: drawer
            });
    }, [clients, match]);

    useEffect(() => {

        if (canViewBotsMenu)
            (async () => {
                const clients = await clientsGateway.findAll({ isMarketing: false });
                const clientsDrawer = await getClients(clients);
                setClients(clientsDrawer);
            })();

    }, []);

    const getClients = async (clients: IClient[]) => {
        return await Promise.all(clients.map(async client => { return await (fillClientDrawer(client)) }));
    }

    const fillClientDrawer = async (client: IClient) => {
        return {
            id: client.id,
            name: client.fullname,
            state: await dashboardGateway.getClientBotExecutionState(client.id),
        }
    }

    return canViewBotsMenu && client ? (
        <ClientBotDetailDashboard client={client} />
    ) : (canViewBotsMenu ? <p>Selecciona un cliente</p> : <Fragment/>);
};


export default withRouter(BotsDashboard);