import React, { FC, useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import LocationPin from "./map-location-pin";
import useSupercluster from "use-supercluster";
import { ICoordinatesEstCmplx } from "../gateways/coordinates.interfaces";
import config from "../config";

interface IMapProps {
  locations: ICoordinatesEstCmplx[];
  zoomLevel: number;
  showNames: boolean;
}

const Map: FC<IMapProps> = (props) => {
  const mapRef = useRef();
  const [bounds, setBounds] = useState<any>(null);
  const [zoom, setZoom] = useState<number>(props.zoomLevel);
  const points = props.locations.map((location) => {
    return {
      type: "Feature",
      properties: {
        cluster: false,
        coordinatesId: location.coordinates.id,
        client: location.clientName,
      },
      geometry: {
        type: "Point",
        coordinates: [
          location.coordinates.longitude,
          location.coordinates.latitude,
        ],
      },
    };
  });

  const { clusters } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });
  return (
    <div style={{ width: "100%", height: "100%", background: "default" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={{ lat: 39.59630865176704, lng: 2.6565865248007134 }}
        defaultZoom={props.zoomLevel}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount,
            client: clientName,
          } = cluster.properties;
          if (isCluster) {
            console.log(pointCount, points, isCluster, cluster);
            return (
              <LocationPin
                lat={latitude}
                lng={longitude}
                isCluster={true}
                pointCount={pointCount}
                points={points}
              />
            );
          }
          return (
            <LocationPin
              lat={latitude}
              lng={longitude}
              text={props.showNames ? clientName : ""}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
