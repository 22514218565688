import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import CoordinatesPending from "./coordinates-pendings";
import { CoordinatesTasks } from "./coordinates-tasks";

export const CoordinatesPage: FC = () => {
  return (
    <Box>
      <Switch>
        <Route path="/coordinates/" exact component={CoordinatesPending} />
        <Route path="/coordinates/tasks" exact component={CoordinatesTasks} />
      </Switch>
    </Box>
  );
};
