import React, { FC, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import {
  makeStyles,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Typography,
  Select,
  MenuItem,
  Fab,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from "@material-ui/core";
import SupplyBotsIcon from "@material-ui/icons/Autorenew";
import { useNavigator } from "../../contexts/navigator.context";
import {
  IBotRO,
  IBotPreconfigFieldRO,
  SaveBotPreconfigDto,
} from "../../gateways/bots.interfaces";
import useIoC from "../../contexts/ioc.context";
import { BotsGateway } from "../../gateways/bots.gateway";
import SaveIcon from "@material-ui/icons/Save";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.drawer,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  formControl: {
    marginBottom: "1rem",
    "& :last-child": {
      marginBottom: 0,
    },
  },
  configRoot: {
    padding: theme.spacing(3, 2),
    marginBottom: theme.spacing(1),
  },
  progress: {
    margin: theme.spacing(2),
  },
  loaderContainer: {
    textAlign: "center",
  },
  // disabled: {
  //   pointerEvents: "none",
  //   opacity: 0.5
  // }
}));

const SupplyBotDetail: FC<RouteComponentProps> = ({ history }) => {
  const [, navigatorDispatch] = useNavigator();
  const classes = useStyles();
  const snackbar = useSnackbar();

  const botsGateway = useIoC(BotsGateway);

  const [bots, setBots] = useState<IBotRO[]>([]);

  const [botId, setBotId] = useState<number | "">("");
  const [fields, setFields] = useState<IBotPreconfigFieldRO[]>([]);
  const [alias, setAlias] = useState<string>("");
  const [meterSupplierEnabled, setMeterSupplierEnabled] =
    useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  
  const handleChangeBot = (e: React.ChangeEvent<{ value: unknown }>) =>
    setBotId(e.target.value as number);
  const handleChangeAlias = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAlias(e.target.value);
  const handleChangeCheckButton = (e: React.ChangeEvent<HTMLInputElement>) =>
    setMeterSupplierEnabled(e.target.checked);

  const handleSave = async (event: any) => {
    event.preventDefault();
    // event.currentTarget.disabled = true;
    // const fabContainer = event.currentTarget.parentNode;
    // fabContainer.classList.add("disabled");
    setFormDisabled(true);

    try {
      const preconfig: SaveBotPreconfigDto = {
        fields: fields,
        alias: alias,
        botId: botId as number,
        enabled: true,
        meterRentalPreconfig: meterSupplierEnabled,
      };
      const result = await botsGateway.createPreconfig(preconfig);
      if (typeof result.id === 'undefined')
        throw new Error(result);

      history.push("/preconfig-bots/" + result.id);
      snackbar.enqueueSnackbar("El bot se ha creado correctamente", {
        variant: "success",
      });

    } catch (e) {
      const er = e as any;
      const message =
        er.validation || er.message || JSON.stringify(er) || "Ha ocurrido un error no controlado al guardar el Bot";
      snackbar.enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      // event.currentTarget.disabled = false;
      // fabContainer.classList.remove("disabled");
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    (async () => {
      let bots = await botsGateway.findAll();
      setBots(quickSort(bots));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botsGateway]);

  useEffect(() => {
    if (botId) {
      const bot = bots.find((x) => x.id === botId);
      if (bot) {
        setFields(
          bot.fields.map((f) => ({
            field: f.field,
            value: "",
            type: f.type,
          }))
        );
        return;
      }
    }
    setFields([]);
  }, [botId, bots]);

  useEffect(() => {
    navigatorDispatch({
      type: "set-header",
      header: {
        title: "Alta nuevo bot de suministro",
        icon: SupplyBotsIcon,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function quickSort(bots: IBotRO[]): IBotRO[] {
    if (bots.length <= 1) return bots;
    else {
      let left = [];
      let right = [];
      let newArray: IBotRO[] = [];
      let pivot = bots.pop();
      let length = bots.length;

      if (!!!pivot) return [];

      for (let i = 0; i < length; i++) {
        if (
          bots[i].name.toLocaleLowerCase().charAt(0) <=
          pivot.name.toLocaleLowerCase().charAt(0)
        ) {
          left.push(bots[i]);
        } else {
          right.push(bots[i]);
        }
      }

      return newArray.concat(quickSort(left), pivot, quickSort(right));
    }
  }

  const renderFieldConfig = (field: Partial<IBotPreconfigFieldRO>) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      field.value = e.target.value;
      setFields([...fields]);
    };

    return (
      <FormControl key={"field_" + field.field} className={classes.formControl} disabled={formDisabled}>
        <InputLabel htmlFor={`config-${field.field}-input`}>
          {field.field}
        </InputLabel>
        <Input
          id={`config-${field.field}-input`}
          value={field.value}
          onChange={handleChange}
        />
      </FormControl>
    );
  };

  return (
    <>
      {formDisabled ? (
         <div className={classes.loaderContainer}>
           <CircularProgress className={classes.progress} />
         </div>
       ): null}
        <form className={classes.root} onSubmit={handleSave}>
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            // onClick={handleSave}
            type="submit"
            disabled={formDisabled}
          >
            <SaveIcon />
          </Fab>

          <div className={classes.content}>
            <FormControl className={classes.formControl} disabled={formDisabled}>
              <InputLabel htmlFor="code-input">Alias</InputLabel>
              <Input
                id="code-input"
                aria-describedby="code-helper-text"
                value={alias}
                onChange={handleChangeAlias}
              />
              <FormHelperText id="code-helper-text">
                Por ejemplo: BPTAL
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.formControl} disabled={formDisabled}>
              <InputLabel htmlFor="bot-input">Bot</InputLabel>
              <Select
                value={botId}
                onChange={handleChangeBot}
                inputProps={{ name: "bot-input" }}
              >
                {bots.map((x) => (
                  <MenuItem key={"bot_" + x.id} value={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="bot-helper-text">
                Bot encargado de capturar las facturas del suministro
              </FormHelperText>
            </FormControl>

            <FormControl disabled={formDisabled}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={meterSupplierEnabled}
                    onChange={handleChangeCheckButton}
                  />
                }
                label="Proveedor de alquiler de contadores"
              />
            </FormControl>

            <h2>Configuración de la integración</h2>
            {botId ? (
              fields.map(renderFieldConfig)
            ) : (
              <Typography color="error">Selecciona un bot</Typography>
            )}
          </div>
        </form>
    </>
  );
};

export default withRouter(SupplyBotDetail);
