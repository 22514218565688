import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { datesHandler } from "../utils/dates-handle";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      height: "100%",
      borderSpacing: 2,
      padding: theme.spacing(1),
    },
  })
);
interface ISupplyDateGroup {
  startCaptureDateLabel?: string;
  validationDateLabel?: string;
  onStartCaptureDateChange: (value: any) => void;
  onValidationsDateChange: (value: any) => void;
  item: {
    startCaptureDate: Date | undefined;
    startValidationsDate: Date | undefined;
  };
}

const SupplyDateGroup: FC<ISupplyDateGroup> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container direction="row" xs={12}>
      <Grid item xs={6} className={classes.gridItem}>
        <KeyboardDatePicker
          format="dd/MM/yyyy"
          margin="normal"
          id="startCaptureDate"
          label={t(props.startCaptureDateLabel ?? "common.startCaptureDate")}
          value={props.item.startCaptureDate !== undefined ? props.item.startCaptureDate : null}
          onChange={props.onStartCaptureDateChange ?? undefined}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} className={classes.gridItem}>
        <Tooltip title={t("common.startValidationsDateTooltip") as string}>
          <KeyboardDatePicker
            format="dd/MM/yyyy"
            margin="normal"
            id="startValidationsDate"
            label={t("common.startValidationsDate")}
            value={!!!props.item.startValidationsDate ? null : props.item.startValidationsDate }
            onChange={props.onValidationsDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            fullWidth
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};
export default SupplyDateGroup;
