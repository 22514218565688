import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IBotRO, IBotPreconfigRO, SaveBotPreconfigDto, ITrackingQuery, IBotTrackRO, IPreconfigCustomFields, IBotPreconfigFieldRO } from "./bots.interfaces";
import { IJobSaveConfig, ISaveJob, IVariableData } from "./prometheus.interface";

@inject('axios')
export class BotsGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async findAll(): Promise<IBotRO[]> {
        const response = await this.axios.get('bots');
        return response.data;
    }

    public async findById(id: number): Promise<IBotRO> {
        const response = await this.axios.get(`bots/${id}`);
        return response.data;
    }

    public async getTracking(query?: ITrackingQuery): Promise<IBotTrackRO[]> {
        const response = await this.axios.get('bots/track', { params: query });
        return response.data;
    }

    public async getPrometheusMetadata(botId: number) {
        const response = await this.axios.get(`bots/prom-metadata/${botId}`);
        return response.data;
    }

    public async insertBotJobs(jobToSave: ISaveJob) {
        await this.axios.post('bots/insert-jobs', jobToSave);
    }

    public async saveJobConfig(config: IJobSaveConfig) {
        await this.axios.post('bots/save-job-config', config);
    }
    
    public async sendJobVariables(variables: IVariableData[]) {
        await this.axios.post('bots/send-job-variables', variables);
    }

    public async getPreconfig(id: number): Promise<IBotPreconfigRO> {
        const response = await this.axios.get('preconfig-bots/' + id);
        return response.data;
    }

    public async findAllPreconfig(): Promise<IBotPreconfigRO[] | any> {
        try {
            const response = await this.axios.get('preconfig-bots');
            return response.data;
        } catch (e) {
            throw e;
        }
    }

    public async createPreconfig(dto: SaveBotPreconfigDto): Promise<IBotPreconfigRO | any> {
        try {
            const response = await this.axios.post('preconfig-bots', dto);
            return response.data;            
        } catch (error) {
            return error as any;
        }
    }

    public async updatePreconfig(id: number, dto: SaveBotPreconfigDto): Promise<IBotPreconfigRO | any> {
        try {
            const response = await this.axios.put('preconfig-bots/' + id, dto);
            return response.data;            
        } catch (error) {
            return error as any;
        }
    }

    public async updateField(id: number, new_field: IBotPreconfigFieldRO): Promise<any> {
        return await this.axios.post('preconfig-bots/' + id, new_field);
    }

    public async deletePreconfig(id: number) {
        await this.axios.delete(`preconfig-bots/${id}`);
    }
    
    public async launchBotProvider(dto:IBotPreconfigRO, customFields?: IPreconfigCustomFields[]){
        const payload = customFields !== undefined ? [dto, customFields]: [dto];
        const response = await this.axios.post(`bots/start-bot-provider`, payload);
        return response.data;
    }

    public async launchBotPlatform(idBot: number){
        await this.axios.get(`bots/start_bot_platform/${idBot}`)
    }
}