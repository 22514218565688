import { IRepositoryDefinition, IRepositoryDefinitionRelations, initialRepositoryDefinitionRelations } from "./repository-definition.interfaces";
import { IValidationGroup } from "./validation-group.interfaces";

export interface IValidationContext {
    id: string;
    name: string;
    repositoryDefinitionEntity: IRepositoryDefinitionRelations;
    validationGroups: IValidationGroup[];
}

export interface ICreateValidationContext {
    name: string;
    repositoryDefinitionEntity: IRepositoryDefinition;
}

export interface IUpdateValidationContext {
    name: string;
    repositoryDefinitionEntity: IRepositoryDefinition;
}

export const initialValidContext: IValidationContext = {
    id: '0',
    name: '',
    repositoryDefinitionEntity: initialRepositoryDefinitionRelations,
    validationGroups:[]
}