import React, { FC, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { RouteComponentProps } from 'react-router-dom';
import { makeStyles, Fab } from "@material-ui/core";
import SupplyBotsIcon from '@material-ui/icons/Autorenew';
import { useNavigator } from '../../contexts/navigator.context';
import useIoC from "../../contexts/ioc.context";
import { ISaveSupplyBot } from "../../gateways/supply-bots.interface";
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import { SupplyBotsGateway } from "../../gateways/supply-bots.gateway";
// import { KeyboardDatePicker } from '@material-ui/pickers';
import SupplyBotForm from './supply-bot.form';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    formControl: {
        marginBottom: '1rem',
        '& :last-child': {
            marginBottom: 0
        }
    },
    configRoot: {
        padding: theme.spacing(3, 2),
        marginBottom: theme.spacing(1)
    }
}));



const SupplyBotDetail: FC<RouteComponentProps> = ({ history }) => {
    const [, navigatorDispatch] = useNavigator();
    const classes = useStyles();
    const snackbar = useSnackbar();

    const supplyBotsGateway = useIoC(SupplyBotsGateway);

    const [supplyBot, setSupplyBot] = useState<ISaveSupplyBot>({
        cups: '',
        code: '',
        invoicesFolder: '',
        emailNotification: '',
        enabled: true,
        supplyTypeId: -1,
        accounts: [],
    });


    const handleSave = async () => {
        try {
            const result = await supplyBotsGateway.create(supplyBot);
            history.push('/supply-bots/' + result.id);
            snackbar.enqueueSnackbar('El bot se ha creado correctamente', {
                variant: 'success',
            });
        } catch (e) {
            const er = e as any;
            const message = er.validation || er.message || 'Ha ocurrido un error no controlado';
            snackbar.enqueueSnackbar(message, {
                variant: 'error',
            });
        }
    };

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: 'Alta nuevo bot de suministro',
                icon: SupplyBotsIcon
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form className={classes.root}>
            <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleSave}>
                <SaveIcon />
            </Fab>

            <SupplyBotForm
                data={supplyBot}
                onChange={setSupplyBot}
            />
        </form>
    );
}

export default withRouter(SupplyBotDetail);