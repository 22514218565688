import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from "@material-ui/core";

import SelectorFieldEst from "./selectorFieldEst";
import SelectorField from "../../../../components/selector-field";
import { IClient } from "../../../../gateways/clients.interfaces";
import { IComplex, initialIComplex } from "../../../../gateways/complex.interfaces";
import { IEstablishment } from "../../../../gateways/establishment.interface";
import ICompany from "../../../../gateways/company.interface";

interface IClientCpanyEstCplexForm {
  clients: IClient[];
  companies: ICompany[];
  complexes: IComplex[];
  establishments: IEstablishment[];

  clientSelected?: IClient | null;
  companySelected?: ICompany | null;
  establishmentSelected?: IEstablishment | null;
  complexSelected?: IComplex | null;

  validateRequired: (value: any) => boolean;
  errorMissingData: boolean;

  onSelectClient: (client: IClient) => void;
  onSelectCompany: (company: ICompany) => void;
  onSelectEstablishment: (establishment: IEstablishment) => void;
  onSelectComplex: (complex: IComplex) => void;

  onClickAddCompanyButton?: () => void;
  onClickAddEstablishmentButton: () => void;
  onClickAddComplexButton?: () => void;
  onClickAddClientButton?: () => void;

  style?: CSSStyleSheet;
}

const ClientCpanyEstCplexForm: FC<IClientCpanyEstCplexForm> = ({
  clients,
  companies,
  complexes,
  establishments,

  clientSelected,
  companySelected,
  complexSelected,
  establishmentSelected,
  
  errorMissingData,
  validateRequired,

  onSelectClient,
  onSelectCompany,
  onSelectComplex,
  onSelectEstablishment,

  onClickAddClientButton,
  onClickAddCompanyButton,
  onClickAddComplexButton,
  onClickAddEstablishmentButton,
}) => {
  const { t } = useTranslation();
  const [locationType, setLocationType] = useState<"complex" | "establishment">(
    "complex"
  );

  const handleChangeClient = (name: string, id: number) => {
    const selectedClient = clients.find((x) => x.id === id);
    selectedClient && onSelectClient(selectedClient);
  };

  const handleChangeCompany = (name: string, id: number) => {
    const selectedCompany = companies.find((x) => x.id === id);
    selectedCompany && onSelectCompany(selectedCompany);
  };

  const handleChangeComplex = (name: string, id: number) => {
    const selectedComplex = complexes.find((x) => x.id === id);
    selectedComplex && onSelectComplex(selectedComplex);
    !selectedComplex && onSelectComplex({...initialIComplex});
  };

  const handleChangeEstablishment = (name: string, id: number) => {
    const selectedEstablishment = establishments.find((x) => x.id === id);
    selectedEstablishment && onSelectEstablishment(selectedEstablishment);
  };

  const handleChangeLocationType = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setLocationType(value as "complex" | "establishment");
  };

  const getComplexName = (complexId: number | undefined | null) =>
    complexId ? complexes.find((x) => x.id === complexId)?.name : undefined;

  return (
    <div>
      <SelectorField
        name="clientId"
        fullWidth
        inputLabel={t("clientList.title")}
        onChange={handleChangeClient}
        required
        value={clientSelected?.id ?? null}
        values={clients.map(({ id, fullname }) => ({ id, value: fullname }))}
        emptyValue={false}
        handleClickAddButon={onClickAddClientButton}
      />

      <SelectorField
        name="companyId"
        emptyValue={false}
        fullWidth
        inputLabel={t("common.company")}
        required
        onChange={handleChangeCompany}
        value={companySelected?.id ?? null}
        values={companies.map(({ id, name }) => ({ id, value: name }))}
        handleClickAddButon={onClickAddCompanyButton}
        disabled={!!!clientSelected}
      />

      <FormControl>
        {<p>El suministro se encuentra ubicado en:</p>}
        <RadioGroup
          defaultValue={"complex"}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={handleChangeLocationType}
        >
          <FormControlLabel
            value="complex"
            control={<Radio color="primary" />}
            label="Complejo"
          />
          <FormControlLabel
            value="establishment"
            control={<Radio color="primary" />}
            label="Establecimiento"
          />
        </RadioGroup>
      </FormControl>

      {locationType === "establishment" && (
        <SelectorFieldEst
          name="establishmentId"
          emptyValue={true}
          error={errorMissingData && (establishmentSelected?.id === undefined && locationType === "establishment")}
          fullWidth
          inputLabel={t("establishmentList.title")+"*"}
          helperText={t("messages.requiredField") as string}
          required={true}
          onChange={handleChangeEstablishment}
          value={establishmentSelected?.id ?? null}
          values={establishments.map(({ id, name, complexId }) => ({
            id,
            value: name,
            category: getComplexName(complexId),
          }))}
          handleClickAddButon={onClickAddEstablishmentButton}
          disabled={!!!companySelected}
          validator={validateRequired}
        />
      )}

      {locationType === "complex" && (
        <SelectorField
          name="complexId"
          emptyValue={true}
          fullWidth
          error={errorMissingData && (complexSelected?.id === undefined && locationType === "complex")}
          inputLabel={t("complexList.title")+"*"}
          helperText={t("messages.requiredField") as string}
          required={true}
          onChange={handleChangeComplex}
          value={complexSelected?.id ?? null}
          values={complexes.map(({ id, name }) => ({ id, value: name }))}
          handleClickAddButon={onClickAddComplexButton}
          disabled={!!!companySelected}
          validator={validateRequired}
        />
      )}
    </div>
  );
};

export default ClientCpanyEstCplexForm;
