import React, { FC, useEffect, useState, useReducer } from "react";
import { useRouteMatch, Route, RouteComponentProps } from "react-router-dom";
import useIoC from "../../contexts/ioc.context";
import EstablishmentRouter from "./establishment-router";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import { useTranslation } from "react-i18next";
import {
  StateType,
  EstablishmentReducer,
  EstablishmentContext,
} from "./establishment-context";
import EstablishmentDetail from "./establishment-detail";
import ElectricSupplyRouter from "../electric-supply/electric-supply-router";
import FuelSupplyRouter from "../fuel-supply/fuel-supply-router";
import { EstablishmentGateway } from "../../gateways/establishment.gateway";
import WaterSupplyRouter from "../water-supply/water-supply-router";
import ContactList, { ContactEntityType } from "../contacts/contact-list";
import { ClientsGateway } from "../../gateways/clients.gateway";

const initialState: StateType = { establishment: null };
interface IContactsRouteParams {
  establishmentId: string;
}

const ContactRouter = (props: RouteComponentProps<IContactsRouteParams>) => (
  <ContactList
    entity={ContactEntityType.Establishments}
    entityId={props.match.params.establishmentId}
  />
);

export const EstablishmentWrapper: FC = () => {
  const { t } = useTranslation();
  const match = useRouteMatch<{ establishmentId: string }>();
  const [establishmentId, setEstablishmentId] = useState<number | null>(null);
  const establishmentGateway =
    useIoC(EstablishmentGateway);
  const [, setLoading] = useState(true);
  const { update: updateBreadCrumbName } = useBreadCrumbName();

  const [establishmentState, establishmentStateReducer] = useReducer(
    EstablishmentReducer,
    initialState
  );

  useEffect(() => {
    setEstablishmentId(parseInt(match.params.establishmentId, 10));
  }, [match.params.establishmentId]);

  useEffect(() => {
    (async () => {
      if (establishmentId === null) {
        return;
      }
      setLoading(true);
      const temp = await establishmentGateway.findById(establishmentId);
      const breadCrumbName = temp.cancelledDate === null ? temp.name: temp.name + " (Cancelado)";
      updateBreadCrumbName("establishments", breadCrumbName);
      establishmentStateReducer({
        type: "update-establishment",
        payload: temp,
      });
      setLoading(false);
    })();
  }, [establishmentId]);

  if (establishmentState.establishment === null) {
    return <div>{t("common.loading")}</div>;
  }
  return (
    <EstablishmentContext.Provider
      value={[establishmentState, establishmentStateReducer]}
    >
      <Route path={`${match.path}`} exact>
        <EstablishmentDetail />
      </Route>
      <Route path={`${match.path}/contacts`} render={ContactRouter} />
      <Route path={`${match.path}/electricSupply`}>
        <ElectricSupplyRouter />
      </Route>
      <Route path={`${match.path}/fuelSupply`}>
        <FuelSupplyRouter />
      </Route>
      <Route path={`${match.path}/waterSupply`}>
        <WaterSupplyRouter />
      </Route>
    </EstablishmentContext.Provider>
  );
};
