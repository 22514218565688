import { FC } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export interface IContactTypeDeleteDialogProps {
    open: boolean,
    handleClose: () => void,
    handleDeleteContactType: () => void
}

const ContactTypeDeleteDialog: FC<IContactTypeDeleteDialogProps> = ({ open, handleClose, handleDeleteContactType }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('contactTypeDeleteDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('contactTypeDeleteDialog.content')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleDeleteContactType} color="primary">{t('common.accept')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ContactTypeDeleteDialog;