
const validateEmail = (email: string) => {
    return email.toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) ? true : false;
  };

export const emailValidationError = (email:string | null | undefined) => {
  let result = undefined;

  if (typeof email !== 'undefined' && email != null && email.length > 0)
  {
    let emails = email.includes(';') ? email.split(';').filter(e => e.replaceAll(' ', '')) : [email.replaceAll(' ', '')]
    
    if (emails.length > 0)
    {
      emails.forEach(em => {
        if (!validateEmail(em))
        {
          result = true;
        }
      });
      if (typeof result === 'undefined')
        result = false;

    } else 
      result = true;

  }

  return result;
}

export const obtainDomainFromEmail = (email: string | null | undefined) => {

  if (!emailValidationError(email) && email != null)
  {
    //is valid email or email list
    let emails = email.includes(';') ? email.split(';').filter(e => e.replaceAll(' ', '')) : [email.replaceAll(' ', '')]

    if (emails.length > 0)
    {
      const em = emails[0].split('@');
      return em[1];
    }

  } else
    return undefined;

}