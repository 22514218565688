import React, { FC } from "react";
import { makeStyles, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { IValidationContext } from "../../gateways/validation-context.interfaces";

interface IValidationContextItemProps {
    item: IValidationContext;
    onClickItem: (item: IValidationContext) => void;
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

const ValidationContextItem: FC<IValidationContextItemProps> = (props) => {
    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start" className={classes.listItem} onClick={() => { props.onClickItem(props.item) }}>
            <ListItemAvatar >
                <VerifiedUserIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
            </ListItemAvatar>
            <ListItemText primary={props.item.name} secondary={props.item.repositoryDefinitionEntity.name} />

        </ListItem>
    );
};

export default ValidationContextItem;