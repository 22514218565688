import { createContext, Dispatch } from "react";
import { IWaterSupplyInterface } from "../../gateways/water-supply.interfaces";

export type StateType = { waterSupply: IWaterSupplyInterface | null };
export type ActionType = {
  type: "update-waterSupply";
  payload: IWaterSupplyInterface;
};

export const WaterSupplyReducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case "update-waterSupply":
      return { ...state, waterSupply: action.payload };
  }
  return state;
};
export const WaterSupplyContext = createContext<
  [StateType, Dispatch<ActionType>]
>([{ waterSupply: null }, (state) => state]);
