import {
  Checkbox,
  createStyles,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React from "react";
import { FC } from "react";
import BugReportIcon from "@material-ui/icons/BugReport";
import CancelScheduleSendIcon from "@material-ui/icons/CancelScheduleSend";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import ErrorIcon from "@material-ui/icons/Error";
import SdCardAlertIcon from "@material-ui/icons/NoSim";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { IMissingBotDataQuery } from "../../../gateways/operate.gateway";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      height: "100%",
      borderSpacing: 1,
      marginRight: 15,
      padding: theme.spacing(1),
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: 240,
        flexShrink: 0,
      },
    },
    toolbar: theme.mixins.toolbar as CSSProperties,
    drawerPaper: {
      width: 240,
    },
    buttonItem: {
      margin: 10,
      borderSpacing: 1,
      padding: theme.spacing(1),
    },
  })
);

// interface IInfoBotSidebarProps{
//     handleFilterErrors: () => void;
// }

export interface IInfoBotSidebarProps {
  filters: IMissingBotDataQuery;
  onChangeFilters: (filters: IMissingBotDataQuery) => void;
}

const InfoBotSidebar: FC<IInfoBotSidebarProps> = ({
  filters,
  onChangeFilters,
}) => {
  const classes = useStyles();

  const handleChangeCheckboxFilter =
    (field: keyof IMissingBotDataQuery) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeFilters({
        ...filters,
        [field]: e.target.checked,
      });
    };

  return (
    <div>
      <ListItem>
        <Grid container direction="column" alignItems="center">
          <Grid>
            <IconButton className={classes.buttonItem}>
              <BugReportIcon style={{ fontSize: 50, color: "#e50000" }} />
            </IconButton>
          </Grid>
          <Grid>
            <ListItemText
              primary="Tipos de error"
              secondary="Para editar los suministros, filtra por tipos de error."
            />
          </Grid>
        </Grid>
      </ListItem>
      <List dense component="nav">
        <ListItem button>
          <ListItemIcon>
            <ErrorIcon style={{ fontSize: 30, color: "#e50000" }}/>
          </ListItemIcon>
          <ListItemText primary="Error Referencia" />
          <Checkbox
            defaultChecked={filters.errorNoCups}
            onChange={handleChangeCheckboxFilter("errorNoCups")}
            edge="end"
            style={{color: "#e50000"}}
          />
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemIcon>
            <EventBusyIcon style={{ fontSize: 30, color: "#e50000" }} />
          </ListItemIcon>
          <ListItemText primary="Error Fecha" />
          <Checkbox
            defaultChecked={filters.errorNoDate}
            onChange={handleChangeCheckboxFilter("errorNoDate")}
            edge="end"
            style={{color: "#e50000"}}
          />
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemIcon>
            <SdCardAlertIcon style={{ fontSize: 30, color: "#e50000" }} />
          </ListItemIcon>
          <ListItemText primary="Error Carpeta One Drive" />
          <Checkbox
            defaultChecked={filters.errorOneDriveFile}
            onChange={handleChangeCheckboxFilter("errorOneDriveFile")}
            edge="end"
            style={{color: "#e50000"}}
          />
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemIcon>
            <CancelScheduleSendIcon style={{ fontSize: 30, color: "#e50000" }}/>
          </ListItemIcon>
          <ListItemText primary="Error Correo" />
          <Checkbox
            defaultChecked={filters.errorOperative}
            onChange={handleChangeCheckboxFilter("errorOperative")}
            edge="end"
            style={{color: "#e50000"}}
          />
        </ListItem>
      </List>
    </div>
  );
};

export default InfoBotSidebar;
