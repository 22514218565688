import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import SelectorField from "../../components/selector-field";
import { IComplex } from "../../gateways/complex.interfaces";
import ICountry from "../../gateways/country.interfaces";
import ICompany from "../../gateways/company.interface";
import {
  Chip,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Tooltip,
  FormControlLabel,
  Checkbox,
  GridSize,
} from "@material-ui/core";
import IRegion from "../../gateways/region.interface";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      margin: theme.spacing(0.5),
    },
    gridItem: {
      height: "100%",
      borderSpacing: 2,
      padding: theme.spacing(1),
    },
    specialGridItem: {
      height: "100%",
      borderSpacing: 2,
      padding: theme.spacing(1),
      paddingLeft: "0px",
    },
  })
);

interface IComplexDetailProps {
  item: IComplex;
  onChange: (name: string, value: any) => void;
  validateRequired: (value: any) => boolean;
  companies: ICompany[];
  countries: ICountry[];
  regions: IRegion[];
  onCountryChange: (name: string, value: any) => void;
  onChipRegionDelete: (index: number) => void;
  onClickAddCountryButton: () => void;
  onClickAddCompanyButton?: () => void;
  coordinates: string;
  handleSetDireccion: (name: string, value: string) => void;
  handleChangeAddress: (name: string, value: string) => void;
  onSendBeforeTelemedidaChange: () => void;
  largeXs: GridSize;
  smallXs: GridSize
}

const ComplexForm: FC<IComplexDetailProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <Grid container direction="row" xs={12}>
        <Grid xs={props.largeXs} className={classes.specialGridItem}>
          <FormTextField
            name="name"
            label={t("common.name")}
            type="text"
            validator={props.validateRequired}
            errorText={t("messages.requiredField") as string}
            value={props.item.name}
            onChange={props.onChange}
            autoFocus
            fullWidth
            required
          />
        </Grid>
        <Grid xs={props.smallXs} className={classes.gridItem}>
          <Tooltip title={t("common.sendBeforeValidatingTooltip") as string}>
            <FormControlLabel
              control={
                <Checkbox
                  color={"primary"}
                  checked={props.item.sendBeforeValidating}
                  onChange={props.onSendBeforeTelemedidaChange}
                />
              }
              label={t("common.sendBeforeValidating")}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <FormTextField
        name="direction"
        label={t("common.address")}
        type="text"
        validator={props.validateRequired}
        errorText={t("messages.requiredField") as string}
        value={props.item.direction}
        // onChange={props.handleChangeAddress}
        onChange={props.handleSetDireccion}
        fullWidth
        required
      />
      <FormTextField
        name="coordinates"
        disabled
        label={t("common.coordinates")}
        type="text"
        validator={props.validateRequired}
        errorText={t("messages.requiredField") as string}
        value={props.coordinates}
        onChange={props.handleChangeAddress}
        fullWidth
        required
      />
      <SelectorField
        name="companyId"
        emptyValue={false}
        fullWidth
        inputLabel={t("common.company")}
        helperText={t("messages.requiredField") as string}
        required
        onChange={props.onChange}
        value={props.item.companyId}
        validator={props.validateRequired}
        values={props.companies.map((value) => {
          return { id: value.id, value: value.name };
        })}
        handleClickAddButon={props.onClickAddCompanyButton}
      />
      <SelectorField
        name="countryId"
        emptyValue={false}
        fullWidth
        inputLabel={t("common.country")}
        helperText={t("messages.requiredField") as string}
        required
        onChange={props.onCountryChange}
        value={props.item.countryId}
        validator={props.validateRequired}
        values={props.countries.map((value) => {
          return { id: value.id, value: value.name };
        })}
        handleClickAddButon={props.onClickAddCountryButton}
      />
      <div>
        {props.regions.map((value, index) => {
          return (
            <Chip
              key={index}
              label={value.name}
              onDelete={() => {
                props.onChipRegionDelete(index);
              }}
              className={classes.chip}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ComplexForm;
