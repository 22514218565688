import { IPowerDetail, ICreatePowerDetail, initialIPowerDetail, initialICreatePowerDetail } from "./period-power-details.interface";

import { IEnergyDetail, ICreateEnergyDetail, initialIEnergyDetail, initialICreateEnergyDetail } from "./period-energy-details.interface";

export interface IRegisterElectricBill {
    id: number;
    firstRangeOfDates?: string;
    secondRangeOfDates?: string;
    numberBill: string;
    multiplePeriodsBill?: boolean;
    issueDate: Date;
    startingPeriodDate: Date;
    endingPeriodDate: Date;
    bonificationIncluded: boolean;
    bonusBillNumber: number;
    bonusImport: number;
    estimated: boolean;
    powerExcessImport: number;
    importEquipmentRental: number;
    electricTax: number;
    electricContractId: number;
    powerRateImport: number;
    electricRateBillImport: number;
    reactiveImport: number;
    totalBillImport: number;
    totalBillImportIVA: number;
    electricSupplyId: number;
    compensateInjectEnergy?: boolean;
    unitCompensatePrice?: number;
    amountToCompensate?: number;
}

export default interface IRegisterElectricBillUpdate {

    numberBill: string;
    firstRangeOfDates?: string;
    secondRangeOfDates?: string;
    multiplePeriodsBill?: boolean;
    issueDate: Date;
    startingPeriodDate: Date;
    endingPeriodDate: Date;
    bonificationIncluded: boolean;
    bonusBillNumber: number;
    bonusImport: number;
    estimated: boolean;
    powerExcessImport: number;
    importEquipmentRental: number;
    electricTax: number;
    periodPowerDetails: IPowerDetail[];
    powerRateImport: number;
    periodEnergyDetails: IEnergyDetail[];
    electricRateBillImport: number;
    reactiveImport: number;
    totalBillImport: number;
    totalBillImportIVA: number;
    electricContractId: number;
    electricSupplyId: number;
    compensateInjectEnergy?: boolean;
    unitCompensatePrice?: number;
    amountToCompensate?: number;
}

export const initialIRegisterElectricBillUpdate: IRegisterElectricBillUpdate = {
    bonificationIncluded: false,
    multiplePeriodsBill: false,
    firstRangeOfDates: '',
    secondRangeOfDates: '',
    bonusBillNumber: 0,
    bonusImport: 0,
    electricContractId: 0,
    electricRateBillImport: 0,
    electricTax: 0,
    endingPeriodDate: new Date(),
    estimated: false,
    importEquipmentRental: 0,
    issueDate: new Date(),
    numberBill: '',
    powerExcessImport: 0,
    powerRateImport: 0,
    periodEnergyDetails: initialIEnergyDetail,
    periodPowerDetails: initialIPowerDetail,
    reactiveImport: 0,
    startingPeriodDate: new Date(),
    totalBillImport: 0,
    totalBillImportIVA: 0,
    electricSupplyId: 0,
    compensateInjectEnergy: false,
    unitCompensatePrice: 0,
    amountToCompensate: 0,
}

export interface IRegisterElectricBillCreate {

    numberBill: string;
    multiplePeriodsBill?: boolean;
    issueDate: Date;
    firstRangeOfDates?: string;
    secondRangeOfDates?: string;
    startingPeriodDate: Date;
    endingPeriodDate: Date;
    bonificationIncluded: boolean;
    bonusBillNumber: number;
    bonusImport: number;
    estimated: boolean;
    powerExcessImport: number;
    importEquipmentRental: number;
    electricTax: number;
    periodPowerDetails: ICreatePowerDetail[];
    powerRateImport: number;
    periodEnergyDetails: ICreateEnergyDetail[];
    electricRateBillImport: number;
    reactiveImport: number;
    totalBillImport: number;
    totalBillImportIVA: number;
    electricContractId: number;
    electricSupplyId: number;
    compensateInjectEnergy?: boolean;
    unitCompensatePrice?: number;
    amountToCompensate?: number;
}

export const initialIRegisterElectricBillCreate: IRegisterElectricBillCreate = {
    bonificationIncluded: false,
    multiplePeriodsBill: false,
    firstRangeOfDates: '',
    secondRangeOfDates: '',
    bonusBillNumber: 0,
    bonusImport: 0,
    electricContractId: 0,
    electricRateBillImport: 0,
    electricTax: 0,
    endingPeriodDate: new Date(),
    estimated: false,
    importEquipmentRental: 0,
    issueDate: new Date(),
    numberBill: '',
    powerExcessImport: 0,
    powerRateImport: 0,
    periodEnergyDetails: initialICreateEnergyDetail,
    periodPowerDetails: initialICreatePowerDetail,
    reactiveImport: 0,
    startingPeriodDate: new Date(),
    totalBillImport: 0,
    totalBillImportIVA: 0,
    electricSupplyId: 0,
    compensateInjectEnergy: false,
    unitCompensatePrice: 0,
    amountToCompensate: 0,
}

export interface IRegisterElectricBillFind {
    id: number;
    numberBill: string;
    issueDate: Date;
    startingPeriodDate: Date;
    endingPeriodDate: Date;
    bonificationIncluded: boolean;
    bonusBillNumber: number;
    bonusImport: number;
    estimated: boolean;
    powerExcessImport: number;
    importEquipmentRental: number;
    electricTax: number;
    periodPowerDetails: IPowerDetail[];
    powerRateImport: number;
    periodEnergyDetails: IEnergyDetail[];
    electricRateBillImport: number;
    reactiveImport: number;
    totalBillImport: number;
    totalBillImportIVA: number;
    electricContractId: number;
    electricSupplyId: number;
    compensateInjectEnergy?: boolean;
    unitCompensatePrice?: number;
    amountToCompensate?: number;
}