import React, { FC, useState, useEffect, useMemo, Fragment } from "react";
import { RouteComponentProps } from "react-router";
import {
  makeStyles,
  CircularProgress,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon,
  Fab,
} from "@material-ui/core";
import useIoC from "../../../contexts/ioc.context";
import { ESMGQGateway } from "../../../gateways/esm-google-query.gateway";
import { IESM_GOOGLE_QUERY_RO } from "../../../gateways/esm-google-query.interfaces";
import AlertBox from "../../../components/alert-box";
import { useNavigator } from "../../../contexts/navigator.context";
import { useTranslation } from "react-i18next";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import BuildIcon from "@material-ui/icons/Build";
import { useSnackbar } from "notistack";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CircularProgressWithLabel from "../../../components/circular-progress-with-label";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  progress: {
    margin: theme.spacing(2),
  },
  loaderContainer: {
    textAlign: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: "46%",
  },
  iconButton: {
    padding: 10,
  },
  listItem: {
    cursor: "pointer",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  fabAdd: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export const ESMGQCallAndExport: FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const [, navigatorDispatch] = useNavigator();
  const { t } = useTranslation();
  const snackbar = useSnackbar();

  const [data, setData] = useState<IESM_GOOGLE_QUERY_RO[]>([]);
  const [loading, setLoading] = useState(false);
  const isEmpty = useMemo(() => !loading && data.length === 0, [loading, data]);

  const [error, setError] = useState<string>("");

  const [expanded, setExpanded] = useState<number[]>([]);

  const handleExpand = (value: number) => () => {
    const currentIndex = expanded.indexOf(value);
    const newExpanded = [...expanded];

    if (currentIndex === -1) {
      newExpanded.push(value);
    } else {
      newExpanded.splice(currentIndex, 1);
    }

    setExpanded(newExpanded);
  };

  const handleAdd = () => {
    history.push("/marketing/esm-google-query/create");
  };

  const calledGateway = useIoC(ESMGQGateway);

  useEffect(() => {
    navigatorDispatch({
      type: "set-header",
      header: {
        title: t("navigation.esm-google-query"),
        icon: LocationSearchingIcon,
      },
    });
  }, [data, navigatorDispatch, t]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const items = await calledGateway.findAll();
        // console.log(JSON.stringify(items));
        setData(items);
      } catch (e) {
        console.error(e);
        setError(
          "Error getting execution indicators, \n Error: " + (e as any).message
        );
      }

      setLoading(false);
    })();
  }, [calledGateway]);

  useEffect(() => {
    if (error && error.length > 0) {
      alert(error);
      setError("");
    }
  }, [error]);

  const handleOnStopTaskClick = (item: IESM_GOOGLE_QUERY_RO) => {
    if (!!!item.id) {
      snackbar.enqueueSnackbar(t("common.documentationNotAvailable"), {
        variant: "error",
      });
    } else {
      console.log("[directus] renderItem: " + JSON.stringify(item));
      history.push(`/esm-google-query/stopid=${item.id}`);
    }
  };

  const handleOnStartOrContinueTaskClick = (item: IESM_GOOGLE_QUERY_RO) => {
    if (!!!item.id) {
      snackbar.enqueueSnackbar(t("common.documentationNotAvailable"), {
        variant: "error",
      });
    } else {
      console.log("[directus] renderItem: " + JSON.stringify(item));
      history.push(`/esm-google-query/startid=${item.id}`);
    }
  };

  const renderItem = (item: IESM_GOOGLE_QUERY_RO, index: number) => {
    console.log('[directus] renderItem: ' + JSON.stringify(item) + ', ListIndex: ' + index);

    const handleClickStopWrapper = async () => handleOnStopTaskClick(item);
    const handleClickStartWrapper = async () =>
      handleOnStartOrContinueTaskClick(item);

    const calcPercItem = (item: IESM_GOOGLE_QUERY_RO) => {
      let calcPerc = 0;
      const intPerc = Math.round(
        (item.actual_iteration / item.total_to_process) * 100
      );
      if (intPerc !== item.percentage) {
        calcPerc = intPerc;
      } else {
        calcPerc = item.percentage;
      }

      return calcPerc;
    };

    return (
      <Fragment key={index}>
        <ListItem
          button
          onClick={handleExpand(index)}
          alignItems="flex-start"
          className={classes.listItem}
        >
          <ListItemText
            primary={`${item.indicated_process}`}
            //   secondary={<CircularProgressWithLabel value={calcPerc} />}
            //   secondary={`${item.percentage} %`}
          />
          <CircularProgressWithLabel value={calcPercItem(item)} />
          {expanded.indexOf(index) !== -1 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse
          in={expanded.indexOf(index) !== -1}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <ListItemText onClick={handleExpand(index)} primary="Functions" />
              <IconButton
                onClick={handleClickStopWrapper}
                className={classes.iconButton}
                aria-label="stop task"
              >
                <HighlightOffIcon />
              </IconButton>
              <IconButton
                onClick={handleClickStartWrapper}
                className={classes.iconButton}
                aria-label="start task"
              >
                <PlayCircleFilledIcon />
              </IconButton>
            </ListItem>
          </List>
        </Collapse>
      </Fragment>
    );
  };

  return (
    <Box>
      {loading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.progress} />
        </div>
      ) : (
        <Fragment>
          {/* Barra de busqueda */}
          {/* <Paper component="form" className={classes.root}>
            <TextField
              id="address-search"
              className={classes.input}
              label="Dirección fisica"
              type="search"
            />
            <TextField
              id="website-search"
              className={classes.input}
              label="Dirección Web"
              type="search"
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <ArrowForwardIcon />
            </IconButton>
          </Paper> */}
          {/* Lista de busquedas anteriores */}
          <Box>
            <h3>
              <PersonPinIcon />
              Listado actual
            </h3>

            <List component="nav">
              {data ? (
                data.map(renderItem)
              ) : (
                <ListItem alignItems="flex-start" className={classes.listItem}>
                  <ListItemText primary="data is empty"></ListItemText>
                </ListItem>
              )}
            </List>
            <Fab
              color="primary"
              aria-label="add"
              className={classes.fabAdd}
              onClick={handleAdd}
            >
              <AddIcon />
            </Fab>
          </Box>
        </Fragment>
      )}

      {isEmpty ? <AlertBox variant="info">No hay registros</AlertBox> : null}
    </Box>
  );
};

export default ESMGQCallAndExport;
