import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Chip, makeStyles, Typography, Box } from "@material-ui/core";
import useIoC from "../../contexts/ioc.context";
import SelectorField from "../../components/selector-field";
import { IValidationContext, initialValidContext } from "../../gateways/validation-context.interfaces";
import { RepositoryDefinitionGateway } from "../../gateways/repository-definition.gateway";
import { IRepositoryDefinitionRelations } from "../../gateways/repository-definition.interfaces";
import FormTextField from "../../components/form-text-field";
import RepositoryDefinitionAddDialog from "../repository-definition/repository-definition-add-dialog";

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5)
    },
    fields: {
        marginBottom: theme.spacing(0.5)
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    }
}));

interface IValidationContextFormProps {
    item: IValidationContext;
    onChange: (item: IValidationContext) => void;
}

const ValidationContextForm: FC<IValidationContextFormProps> = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const [item, setItem] = useState<IValidationContext>(initialValidContext);
    const [repositoryDefinitions, setRepositoryDefinitions] = useState<IRepositoryDefinitionRelations[]>([]);
    const [repositoryDefinitionId, setRepositoryDefinitionId] = useState<number>(0);

    const repositoryDefinitionsGateway = useIoC(RepositoryDefinitionGateway);
    const [openRepositoryDialog, setOpenRepositoryDialog] = useState(false);

    const handleOpenRepositoryDialog = () => {
        setOpenRepositoryDialog(true);
    };

    const handleCloseRepositoryDialog = () => {
        setOpenRepositoryDialog(false);
    };

    const handleNewRepositoyDefinition = (item: IRepositoryDefinitionRelations) => {
        setRepositoryDefinitions([...repositoryDefinitions, item]);
    }


    const handleChange = (name: string, value: any) => {
        const itemChaged = { ...item, [name]: value };
        setItem(itemChaged);
        props.onChange(itemChaged)
    };

    const handleChangeSelector = (name: string, id: number) => {
        setRepositoryDefinitionId(id);
        const repositoryDefinition = repositoryDefinitions.find(x => x.id === id);
        if (repositoryDefinition) {
            const itemChaged = { ...item, repositoryDefinitionEntity: repositoryDefinition };
            setItem(itemChaged);
            props.onChange(itemChaged)
        }
    }

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    useEffect(() => {

        (async () => {
            if (props.item) {
                setItem(props.item)
                setRepositoryDefinitionId(props.item.repositoryDefinitionEntity.id);
            }
            const repositoryDefinitions = await repositoryDefinitionsGateway.findAll();
            setRepositoryDefinitions(repositoryDefinitions);
        })();
    }, [props.item]);

    return (
        <div className={classes.content}>
            <FormTextField
                name='name'
                label={t('common.name') as string}
                type='text'
                validator={validateRequired}
                errorText={t('messages.requiredField') as string}
                value={item.name}
                onChange={handleChange}
                autoFocus
                fullWidth
                required
                className={classes.fields}
            />

            <SelectorField
                name='repositoryDefinitionId'
                emptyValue={false}
                fullWidth
                inputLabel={t('common.repositoryDefinition') as string}
                helperText={t('messages.requiredField') as string}
                required
                onChange={handleChangeSelector}
                value={repositoryDefinitionId}
                validator={validateRequired}
                values={repositoryDefinitions.map(value => {
                    return { id: value.id, value: value.name };
                })}
                className={classes.fields}
                handleClickAddButon={handleOpenRepositoryDialog}
            />

            <Typography className={classes.fields} >
                {t('common.parameters')}
            </Typography>
            <div>
                {
                    item.repositoryDefinitionEntity ? item.repositoryDefinitionEntity.parameters.map((value, index) => {
                        return (
                            <Chip
                                key={index}
                                label={value.name}
                                className={classes.chip}
                            />
                        );
                    }) : null
                }
            </div>
            <RepositoryDefinitionAddDialog
                onClose={handleCloseRepositoryDialog}
                onCreated={handleNewRepositoyDefinition}
                open={openRepositoryDialog}
            />
        </div>
    );
};

export default ValidationContextForm;