import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IFuelContract, IFuelContractCreate, IFuelContractUpdate } from "./fuel-contract.interfaces";
import querystring from 'querystring';



type FindFinderFuelContract = {

    providerId?: string;
    fuelSupplyRateId?: string;
    fuelSupplyId?: string;

}

const urlFuelContract = 'fuel-contract';

@inject('axios')
export class FuelContractGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(filter?: FindFinderFuelContract): Promise<IFuelContract[]> {

        let url = urlFuelContract;

        if(filter) {
            const query = querystring.stringify(filter);
            url += `?${query}`;
        }

        const response = await this.axios.get(url);
        return response.data;
    }

    async findById(id: string): Promise<IFuelContract> {
        const url = `${urlFuelContract}/${id}`;
        const response = await this.axios.get(url);
        return response.data;
    }

    async create(fuelContractCreate: IFuelContractCreate): Promise<IFuelContract> {
        const response = await this.axios.post(urlFuelContract, fuelContractCreate);
        return response.data;
    }

    async update(id: string, fuelContractUpdate: IFuelContractUpdate): Promise<IFuelContract> {
        const url = `${urlFuelContract}/${id}`;
        const response = await this.axios.put(url, fuelContractUpdate);
        return response.data;
    }

    async findByBillDate(date: Date, fuelSupplyId: string): Promise<IFuelContract> {
        const url = `${urlFuelContract}/initDate/` + this.formatDate(date) + '/' + fuelSupplyId;
        const response = await this.axios.get(url);
        return response.data;
    }

    async addAccountNumber(id: number, accountNumber: string, username: string){
        const url = `${urlFuelContract}/add-account-number`;
        const payload = { id, accountNumber, username };
        const response = await this.axios.post(url, payload );
        return response.data;
    }

    async accountHistoryLogs(id:number){ 
        const url = `${urlFuelContract}/account-history-logs`;
        const payload = { id }
        const response = await this.axios.post( url, payload );
        return response.data;
    }

    formatDate = (date: Date) => {
        var dateToReturn: string;
        const dateCopy = new Date(date);
        const day: number = dateCopy.getDate();
        const month: number = dateCopy.getMonth() + 1;
        const year: number = dateCopy.getFullYear();
        dateToReturn = year.toString()
        if (month < 10) {
            dateToReturn  = dateToReturn + `-0${month}`
        }else{
            dateToReturn  = dateToReturn + `-${month}`
        }

        if (day < 10) {
            dateToReturn  = dateToReturn + `-0${day}`
        }else{
            dateToReturn  = dateToReturn + `-${day}`
        }
        return dateToReturn;   
    };
}