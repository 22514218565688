import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import {  useHistory } from "react-router-dom";


export interface FuelSupplyGoEstablishmentDialogProps {
    open: boolean,
    handleClose: () => void,
}

const FuelSupplyGoEstablishmentDetailDialog: FC<FuelSupplyGoEstablishmentDialogProps> = ({ open, handleClose }) => {
    const { t } = useTranslation();
    const [componentDisabled, setComponentDisabled] = useState(false);
    const history = useHistory();


    const handleGoEstablishmentDetail = () => {
        history.goBack();
        handleClose();
      };


    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('fuelSupplyGoEstablishmentDetail.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('fuelSupplyGoEstablishmentDetail.content')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={componentDisabled}>{t('common.cancel')}</Button>
                <Button onClick={handleGoEstablishmentDetail} color="primary" disabled={componentDisabled}>{t('common.navigateToDetail')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default FuelSupplyGoEstablishmentDetailDialog;