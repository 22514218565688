import React, { FC, useEffect, useState, useMemo } from "react";
import { List, ListItem, ListItemAvatar, ListItemText, Divider, makeStyles, CircularProgress, Box, Table, TableHead, TableBody, TableCell, TableRow, ListItemSecondaryAction, Switch, ListItemIcon, TextField, Button, IconButton } from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { withRouter, RouteComponentProps } from "react-router";
import Fab from '@material-ui/core/Fab';
import useIoC from "../../contexts/ioc.context";
import AlertBox from "../../components/alert-box";
import { useNavigator } from '../../contexts/navigator.context';
import ClientIcon from '@material-ui/icons/People';
import { useTranslation } from "react-i18next";
import { MarketingGateway } from "../../gateways/marketing.gateway";
import { IClientMarketing } from "../../gateways/marketing.interfaces";
import FSTable from "../../components/fs-table";
import { IRepositoryQueryFilterDto, FilterOperator, IRepositoryDefinitionGroup } from "../../gateways/repository.interfaces";
import CreateCampaignDialog from "./create-campaign.dialog";
import { useSnackbar } from "notistack";


const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    inline: {
        display: 'inline',
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    progress: {
        margin: theme.spacing(2),
    },
    loaderContainer: {
        textAlign: 'center'
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const AdminCampaignList: FC<RouteComponentProps> = ({ history }) => {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const [, navigatorDispatch] = useNavigator();
    const { t } = useTranslation();
    const marketingGateway = useIoC(MarketingGateway);
    const [createCampaignOpen, setCreateCampaignOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const handleAddCampaign = () => {
        setCreateCampaignOpen(true);
    };

    const hnadleCloseCreateCampaignDialog = () => {
        setCreateCampaignOpen(false);
    }

    const handleNavigateCampaign = (campaign: any) => {
        history.push(`/marketing/campaigns-admin/${campaign.id}`);
    }

    const handleDeleteCampaign = (campaign: any) => {
        return async (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            if (window.confirm(`¿Seguro que quieres eliminar la campaña ${campaign.name}?`)) {
                setDeleting(true);
                try {
                    await marketingGateway.deleteCampaign(campaign.id);
                    snackbar.enqueueSnackbar('La acción se ha completado correctamente', {
                        variant: 'success',
                    });
                } catch (e) {
                    console.error('An error ocurred deleting campaign', e);
                    snackbar.enqueueSnackbar('Ha ocurrido un error al eliminar la campaña', {
                        variant: 'error',
                    });
                } finally {
                    setDeleting(false);
                }
            }
        }
    }

    return (
        <Box>
            <div className={classes.fab}>
                <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleAddCampaign}>
                    <AddIcon />
                </Fab>
            </div>
            {
                deleting
                    ? <span>Cargando</span>
                    :
                    <FSTable
                        repositoryId="admin-campaigns"
                        onRowClick={handleNavigateCampaign}
                        groupsEnabled={false}
                        title='Administración de campañas'
                        columns={[
                            { name: 'name', label: 'Nombre', sortable: true, },
                            { name: 'totalClients', label: 'Clientes', sortable: true, },
                            { name: 'averageInvoice', label: 'Fact. media', sortable: false, },

                            { name: 'volume', label: 'Volumen', sortable: true, },
                            { name: 'potentalSavings', label: 'Ahorro potencial', sortable: true, },
                            { name: 'opportunityFees', label: 'Oportunidad honorarios', sortable: true, },

                            { label: 'Acciones', sortable: false, render: (item: any) => <IconButton onClick={handleDeleteCampaign(item)} color="secondary"><DeleteIcon /></IconButton> },
                        ]}
                    />
            }

            <CreateCampaignDialog
                open={createCampaignOpen}
                onClose={hnadleCloseCreateCampaignDialog}
            />
        </Box>
    );
}

export default withRouter(AdminCampaignList);