import React, { FC, useState, useEffect, useMemo } from "react";
import { Typography, List, ListItem, ListItemAvatar, ListItemText, Divider, makeStyles, Box,} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import AssignmentIcon from '@material-ui/icons/Assignment';
import useIoC from "../../contexts/ioc.context";
import { useNavigator } from '../../contexts/navigator.context';
import { BotsGateway } from "../../gateways/bots.gateway";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useRouteMatch } from "react-router";
import { IPrometheusMetadata, ISaveJob } from "../../gateways/prometheus.interface";

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer',
    },
    inline: {
        display: 'inline',
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    },
    progress: {
        margin: theme.spacing(2),
    },
    loaderContainer: {
        textAlign: 'center'
    }
}));

const PrometheusBotJobsList: FC<RouteComponentProps> = ({ history }) => {

    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const { t } = useTranslation();
    const match = useRouteMatch<{ id: string }>();
    
    const [jobsData, setJobsData] = useState<IPrometheusMetadata[]>([]);

    const botsGateway = useIoC(BotsGateway);

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('common.prometheusJobs'),
                icon: InfoIcon
            }
        });
    }, [jobsData]);

    useEffect(() => {
        (async () => {
            const jobsInfo = await botsGateway.getPrometheusMetadata(parseInt(match.params.id, 10));
            setJobsData(jobsInfo);
            const jobNames = jobsInfo.map((item: IPrometheusMetadata) => {
                return item.name;
            })
            const jobToSave: ISaveJob = {
                botId: parseInt(match.params.id, 10),
                jobNames
            }
            await botsGateway.insertBotJobs(jobToSave);
        })();
    }, []);

    const handleClick = async (item: IPrometheusMetadata) => {
        history.push(`${match.url}/${item.name}`);
    };

    const renderItem = (item: IPrometheusMetadata, index: number) => {
        const handleClickWrapper = async () => handleClick(item);
        return (
            <>
                <ListItem alignItems="flex-start" className={classes.listItem} onClick={handleClickWrapper}>
                    <ListItemAvatar >
                        <AssignmentIcon
                            fontSize="large"
                            className={classes.itemAvatarIcon}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${item.name}`}
                        secondary={
                            <React.Fragment>
                                <Typography>{item.description}</Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
                {
                    index < jobsData.length - 1
                        ? <Divider variant="inset" component="li" />
                        : null
                }
            </>
        );
    }

    return(
        <Box>
            <List>
                {jobsData.map(renderItem)}
            </List>
        </Box>
    );
}

export default PrometheusBotJobsList;