import React, { FC, useState, useMemo, FormEventHandler, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useIoC from '../../contexts/ioc.context';
import { useSnackbar } from 'notistack';
import { CircularProgress, makeStyles, FormHelperText, FormControl, Checkbox, FormControlLabel, InputLabel, TableHead, TableBody, TableCell, Table, TableRow } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { green } from '@material-ui/core/colors';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { IProduct } from '../../gateways/products.interface';
import { ProductsGateway } from '../../gateways/products.gateway';
import { MarketingGateway } from '../../gateways/marketing.gateway';
import { IMarketingTaskType, IClientMarketing, IClientMarketingTask } from '../../gateways/marketing.interfaces';
import PrettoSlider from '../../components/pretto-slider';

const useStyles = makeStyles(() => ({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {

    },
}));

interface INewActionDialogProps {
    open: boolean;
    client: IClientMarketing;
    onClose: () => void;
    onActionCreated: (client: IClientMarketingTask) => void;
}

interface ISelectableProduct {
    product: IProduct;
    selected: boolean;
    probability: number;
}

const NewActionDialog: FC<INewActionDialogProps> = ({ open, onClose, client, onActionCreated }) => {

    const snackbar = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const classes = useStyles();
    const [products, setProducts] = useState<ISelectableProduct[]>([]);
    const productsGateway = useIoC(ProductsGateway);
    const marketingGateway = useIoC(MarketingGateway);

    const [taskDate, setTaskDate] = useState<Date | null>(null);
    const [expiryDate, setExpiryDate] = useState<Date | null>(null);
    const [comment, setComment] = useState<string>('');

    const [taskType, setTaskType] = useState<IMarketingTaskType | null>(null);
    const [taskTypes, setTaskTypes] = useState<IMarketingTaskType[]>([]);

    const hasAnyAddress = useMemo(() => client.contacts.filter(x => x.contactTypeId === 1).length > 0, [client]);
    const [address, setAddress] = useState<string>('');
    const [useAnotherAddress, setUseAnotherAddress] = useState<boolean>(!hasAnyAddress);

    const hasAnyPhone = useMemo(() => client.contacts.filter(x => x.contactTypeId === 0).length > 0, [client]);
    const [phone, setPhone] = useState<string>('');
    const [useAnotherPhone, setUseAnotherPhone] = useState<boolean>(!hasAnyPhone);

    useEffect(() => {
        (async () => {
            const products = await productsGateway.findAll();
            setProducts(products.map((product) => ({
                product,
                selected: false,
                probability: 0
            })));
        })();
    }, [productsGateway]);

    useEffect(() => {
        (async () => {
            const taskTypes = await marketingGateway.getTaskTypes();
            setTaskTypes(taskTypes);
        })();
    }, [marketingGateway]);

    const handleCreateAction: FormEventHandler = (e) => {
        e.preventDefault();

        if (taskType === null) {
            return window.alert('Tienes que seleccionar el tipo de acción');
        }

        if (!window.confirm('¿Estas seguro de que quieres crear la acción comercial?')) {
            return;
        }
        setLoading(true);

        (async () => {
            try {
                const marketingTask = {
                    marketingClientId: client.id,
                    taskTypeId: taskType.id,
                    taskDate,
                    expiryDate,
                    comment: comment.length > 0 ? comment : null,
                    phone: phone.length > 0 ? phone : null,
                    address: address.length > 0 ? address : null,
                    products: products.filter(x => x.selected).map(x => ({
                        productId: x.product.id,
                        probability: x.probability,
                    }))
                };

                const result = await marketingGateway.createTask(marketingTask);

                snackbar.enqueueSnackbar('Se ha dado de alta la acción comercial', {
                    variant: 'success'
                });

                onClose();
                onActionCreated(result);
            } catch (e) {
                snackbar.enqueueSnackbar('Ha ocurrido un error al dar de alta la acción comercial: ' + e, {
                    variant: 'error'
                });
            } finally {
                setLoading(false);
            }
        })();
    }

    const handleChangeTaskDate = (date: Date | null) => setTaskDate(date);
    const handleChangeExpiryDate = (date: Date | null) => setExpiryDate(date);
    const handleChangeComment: React.ChangeEventHandler<HTMLInputElement> = (e) => setComment(e.target.value);
    const handleChangeUseAnotherAddress: React.ChangeEventHandler<HTMLInputElement> = (e) => setUseAnotherAddress(e.target.checked);
    const handleChangeAddress = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => setAddress(e.target.value as string);
    const handleChangeUseAnotherPhone: React.ChangeEventHandler<HTMLInputElement> = (e) => setUseAnotherPhone(e.target.checked);
    const handleChangePhone = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => setPhone(e.target.value as string);
    const handleChangeProductProbability = (product: ISelectableProduct, value: number) => {
        product.probability = value;
    }
    const handleChangeProductSelected = (product: ISelectableProduct, checked: boolean) => {
        product.selected = checked;
    }
    const handleChangeTaskType = (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => {
        const valueStr = event.target.value as string;
        let value: number | null = null;
        if (valueStr.length > 0) {
            const tmp = parseInt(valueStr);
            if (!isNaN(tmp)) {
                value = tmp;
            }
        }
        if (value === null) {
            setTaskType(null);
        } else {
            const item = taskTypes.find(x => x.id === value) || null;
            setTaskType(item);
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('marketing.acctionDate')}</DialogTitle>
            <form onSubmit={handleCreateAction}>
                <DialogContent>
                    <DialogContentText>
                        {t('marketing.registerForm')}
                    </DialogContentText>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel id="type-selector-label">{t('marketing.acctionType')}</InputLabel>
                        <Select
                            native
                            labelId="type-selector-label"
                            onChange={handleChangeTaskType}
                            value={taskType !== null ? taskType.id : ''}
                            inputProps={{
                                name: 'type-selector',
                                id: 'type-selector',
                            }}
                        >
                            <option value=""></option>
                            {
                                taskTypes.map((item, index) => {
                                    return (
                                        <option key={'task_type_' + index} value={item.id}>{item.name}</option>
                                    );
                                })
                            }
                        </Select>
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                        >
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    fullWidth
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="dense"
                                    id="task-date"
                                    label="Fecha prevista"
                                    value={taskDate}
                                    onChange={handleChangeTaskDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardTimePicker
                                    fullWidth
                                    margin="dense"
                                    variant="inline"
                                    id="task-time"
                                    label="Hora prevista"
                                    value={taskDate}
                                    onChange={handleChangeTaskDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <FormHelperText id="start-capture-date-helper-text">
                            {t('marketing.executionDate')}
                        </FormHelperText>
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <KeyboardDatePicker
                            disableToolbar
                            fullWidth
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="dense"
                            id="expiry-date"
                            label="Fecha caduque"
                            value={expiryDate}
                            onChange={handleChangeExpiryDate}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        <FormHelperText id="start-capture-date-helper-text">
                            {t('marketing.discarDay')}
                        </FormHelperText>
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="comment"
                            label="Comentario"
                            type="text"
                            multiline
                            value={comment}
                            onChange={handleChangeComment}
                            disabled={loading}
                        />
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        {
                            !useAnotherPhone
                                ? (<>
                                    <InputLabel id="phone-selector-label">{t('marketing.phone')}</InputLabel>
                                    <Select
                                        native
                                        labelId="phone-selector-label"
                                        onChange={handleChangePhone}
                                        id="phone-selector"
                                    >
                                        <option value=""></option>
                                        {
                                            client.contacts.filter(x => x.contactTypeId === 0).map((contact, index) => (
                                                <option key={'phone_' + index} value={contact.value}>{contact.value}</option>
                                            ))
                                        }
                                    </Select>
                                </>)
                                : (<TextField
                                    fullWidth
                                    margin="dense"
                                    id="phone"
                                    label="Teléfono alternativo"
                                    type="phone"
                                    value={phone}
                                    onChange={handleChangePhone}
                                    disabled={loading}
                                />)
                        }

                        {
                            hasAnyPhone ? (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={useAnotherPhone}
                                            onChange={handleChangeUseAnotherPhone}
                                            color="primary"
                                        />
                                    }
                                    label="Usar otro teléfono"
                                />
                            ) : null
                        }
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        {
                            !useAnotherAddress
                                ? (<>
                                    <InputLabel id="address-selector-label">{t('marketing.addres')}</InputLabel>
                                    <Select
                                        native
                                        labelId="address-selector-label"
                                        id="address-selector"
                                        onChange={handleChangeAddress}
                                    >
                                        <option value=""></option>
                                        {
                                            client.contacts.filter(x => x.contactTypeId === 1).map((contact, index) => (
                                                <option key={'address_' + index} value={contact.value}>{contact.value}</option>
                                            ))
                                        }
                                    </Select>
                                </>)
                                : (<TextField
                                    fullWidth
                                    margin="dense"
                                    id="address"
                                    label="Dirección alternativa"
                                    type="address"
                                    value={address}
                                    onChange={handleChangeAddress}
                                    disabled={loading}
                                />)
                        }

                        {
                            hasAnyAddress ? (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={useAnotherAddress}
                                            onChange={handleChangeUseAnotherAddress}
                                            color="primary"
                                        />
                                    }
                                    label="Usar otra dirección"
                                />
                            ) : null
                        }
                    </FormControl>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{t('marketing.product')}</TableCell>
                                <TableCell>{t('marketing.probability')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                products.map((item, index) => (
                                    <TableRow key={'product_' + index}>
                                        <TableCell>
                                            <Checkbox onChange={(e) => handleChangeProductSelected(item, e.target.checked)} />
                                        </TableCell>
                                        <TableCell>
                                            {item.product.name}
                                        </TableCell>
                                        <TableCell>
                                            <PrettoSlider
                                                valueLabelDisplay="auto"
                                                aria-label="probabilidad"
                                                defaultValue={0}
                                                onChange={(e, value) => handleChangeProductProbability(item, value as number)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="default" disabled={loading}>
                        {t('common.close')}
                    </Button>
                    <Button type="submit" color="primary" disabled={loading}>
                        {t('marketing.registerAction')}
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </form>
        </Dialog >
    );
}

export default NewActionDialog;