import React, { FC, useEffect, useReducer, useState } from 'react';
import { RepositoryGateway } from '../../gateways/repository.gateway';
import useIoC from '../../contexts/ioc.context';
import { IRepositoryQueryFilterDto, IRepositoryDefinitionGroup } from '../../gateways/repository.interfaces';
import FSTable from './table';
import { tableReducer, initialState } from './reducer';
import { StateContext, ReducerContext } from './context';
import FSTablePagination from './pagination';
import { IFSTableColumn } from './interfaces';
import FSTableToolbar from './table-toolbar';
import { FiltersDialog } from './filters.dialog';
import CreateGroupDialog from './create-group.dialog';
import { SelectGroupDialog } from './select-group.dialog';


export interface IFSTableProps {
    repositoryId: string;
    title: string;
    columns: IFSTableColumn[];
    groupsEnabled?: boolean;
    forceGroupId?: number;
    params?: { [name: string]: any };
    onGroupCreated?: (group: IRepositoryDefinitionGroup) => void;
    onRowClick?: (item: any) => void;
}

const FSTableIndex: FC<IFSTableProps> = ({ title, onRowClick, forceGroupId, groupsEnabled, onGroupCreated, repositoryId, params, columns }) => {
    groupsEnabled = groupsEnabled === undefined || groupsEnabled === true;
    const repositoryGateway = useIoC(RepositoryGateway);
    const [state, dispatch] = useReducer(tableReducer, { ...initialState, groupsEnabled, repositoryId, onRowClick, title, columns });
    const [loading, setLoading] = useState(true);

    console.log('params', params);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const groups = await repositoryGateway.getDefinitionGroups(repositoryId);
            const group = forceGroupId === undefined ? undefined : groups.find(x => x.id === forceGroupId);
            dispatch({ type: 'updateAny', payload: { repositoryId, groups, forceGroup: group === undefined ? null : group } });
            setLoading(false);
        })();
    }, [repositoryId]);

    useEffect(() => {
        dispatch({ type: 'updateAny', payload: { onGroupCreated: onGroupCreated } });
    }, [onGroupCreated]);

    useEffect(() => {
        if (loading) { return; }
        handleLoadData();
        handleLoadCount();
    }, [state.filters, state.selectedGroup, state.forceGroup, params]);

    useEffect(() => {
        if (loading) { return; }
        handleLoadData();
    }, [state.orderBy, state.orderDirection, state.rowsPerPage, state.currentPage]);

    useEffect(() => {
        if (!loading) {
            handleLoadData();
            handleLoadCount();
        }
    }, [loading]);

    const handleLoadCount = async () => {
        dispatch({ type: 'counting' });

        const d = await repositoryGateway.count(repositoryId, {
            criteria: {
                filters: state.filters.length > 0 ? state.filters : undefined,
                filterGroupId: state.forceGroup !== null ? state.forceGroup.id : (state.selectedGroup !== null ? state.selectedGroup.id : undefined),
            },
            params: params ? Object.getOwnPropertyNames(params).map(x => ({ name: x, value: params[x] })) : undefined,
        });

        dispatch({ type: 'setCount', payload: d });
    }

    const handleLoadData = async () => {
        dispatch({ type: 'fetchingData' });
        console.log('fetching data...', { state, loading });
        const d = await repositoryGateway.query(repositoryId, {
            criteria: {
                orders: state.orderBy !== null ? [
                    { property: state.orderBy, asc: state.orderDirection === 'asc' }
                ] : undefined,
                filterGroupId: state.forceGroup !== null ? state.forceGroup.id : (state.selectedGroup !== null ? state.selectedGroup.id : undefined),
                filters: state.filters.length > 0 ? state.filters : undefined,
                pagination: {
                    skip: (state.currentPage) * state.rowsPerPage,
                    take: state.rowsPerPage
                }
            },
            params: params ? Object.getOwnPropertyNames(params).map(x => ({ name: x, value: params[x] })) : undefined,
        });

        dispatch({ type: 'fetchedData', payload: d })
    }

    return (
        <StateContext.Provider value={state}>
            <ReducerContext.Provider value={dispatch}>
                <FSTableToolbar />
                <FSTable />
                <FSTablePagination />
                <FiltersDialog />
                {
                    groupsEnabled
                        ? <>
                            <CreateGroupDialog />
                            <SelectGroupDialog />
                        </>
                        : null
                }
            </ReducerContext.Provider>
        </StateContext.Provider>
    )
}

export default FSTableIndex;
