import React, { FC, useState, useMemo, useEffect } from 'react';
import { makeStyles, Box, Fab, List, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CountryGateway from '../../gateways/country.gateway';
import ICountry from '../../gateways/country.interfaces';
import { useTranslation } from 'react-i18next';
import useIoC from '../../contexts/ioc.context';
import { useNavigator } from '../../contexts/navigator.context';
import CenteredCircularProgress from '../../components/centered-circular-progress';
import AlertBox from '../../components/alert-box';
import CountryIcon from '@material-ui/icons/Public';
import CountryItem from './country-item';
import CountryUpsertDialog from './country-upsert-dialog';
import CountryDeleteWizard from './country-delete-wizard';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    }
}));

const CountryList: FC = () => {
    const classes = useStyles();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [data, setData] = useState<ICountry[]>([]);
    const [openUpsertDialog, setOpenUpsertDialog] = useState(false);
    const [openDeleteWizard, setOpenDeleteDialog] = useState(false);
    const [item, setItem] = useState<ICountry>();
    const isDataEmpty = useMemo(() => !isDataLoading && data.length === 0, [isDataLoading, data]);
    const { t } = useTranslation();
    const countryGateway = useIoC(CountryGateway);
    const [, navigatorDispatch] = useNavigator();

    const handleClickFabButton = () => {
        setItem(undefined);
        setOpenUpsertDialog(true);
    };

    const handleCloseDeleteWizard = () => {
        setOpenDeleteDialog(false);
    };

    const handleCloseUpsertDialog = () => {
        setOpenUpsertDialog(false);
    };

    const handleClickDeleteButtonItem = (item: ICountry) => {
        setItem(item);
        setOpenDeleteDialog(true);
    };

    const handleClickUpdateButtonItem = (item: ICountry) => {
        setItem(item);
        setOpenUpsertDialog(true);
    };

    const handleItemDeleted = (id: number) => {
        let copy = data.filter(value => value.id !== id);
        setData(copy);
    };

    const handleItemUpsert = (item: ICountry, isNew: boolean) => {
        let copy = data.slice();
        if (isNew) {
            copy = [item, ...data];
        } else {
            const index = data.findIndex(value => value.id === item.id);
            copy[index] = item;
        }
        setData(copy);
    };

    useEffect(() => {
        (async () => {
            (async () => {
                setIsDataLoading(true);
                const data = await countryGateway.findAll();
                setData(data);
                setIsDataLoading(false);
            })();
        })();
    }, [countryGateway]);

    useEffect(() => {
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t('countryList.title'),
                icon: CountryIcon
            }
        });
    }, []);

    const Content = () => {
        if (isDataLoading) {
            return <CenteredCircularProgress />;
        }

        if (isDataEmpty) {
            return <AlertBox variant='info'>{t('common.noItems')}</AlertBox>;
        }

        return (
            <List>
                {data.map((item, index) => {
                    return (
                        <div key={item.id}>
                            <CountryItem item={item} onClickUpdateButton={handleClickUpdateButtonItem} onClickDeleteButton={handleClickDeleteButtonItem} />
                            {index < data.length - 1 ? <Divider variant='inset' component='li' /> : null}
                        </div>
                    );
                })}
            </List>
        );
    };

    return (
        <Box>
            <Fab color='primary' aria-label='add' className={classes.fab} onClick={handleClickFabButton}>
                <AddIcon />
            </Fab>
            <Content />
            <CountryUpsertDialog
                open={openUpsertDialog}
                item={item}
                onItemUpsert={handleItemUpsert}
                onClose={handleCloseUpsertDialog} />
            <CountryDeleteWizard
                open={openDeleteWizard}
                item={item}
                onItemDeleted={handleItemDeleted}
                onClose={handleCloseDeleteWizard}
            />
        </Box>
    );
};

export default CountryList;
