import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";

@inject('axios')
export class S3Gateway {
    constructor(private axios: AxiosInstance) { }
    public async getUrl(url: string): Promise<string> {
        const response = await this.axios.get(`s3-ebm?url=${url}`);
        return response.data;
    }

    public async getHashFromUriData(uriData: string): Promise<string> {
        try {
            const payload = {
                uriData
            }
    
            const response = await this.axios.post(`s3-ebm/get_hash_from_uriData`,
                payload
            );
    
            return response.data;            
        } catch (e) {
            console.error('[getHashFromUriData] Error: ', e);
            throw e;
        }
    }
}