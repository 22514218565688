import React, { useState } from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { SvgIconProps } from "@material-ui/core/SvgIcon/SvgIcon";
import Collapse from "@material-ui/core/Collapse/Collapse";
import List from "@material-ui/core/List/List";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export interface IExpandableListProps {
    icon?: React.ComponentType<SvgIconProps>;
    text: React.ReactNode;
    opened?: boolean;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listNested: {
            '& > .MuiListItem-root': {
                paddingLeft: theme.spacing(4),
            }
        },
    }),
);

export const ExpandableList: React.FC<IExpandableListProps> = ({ icon, text, opened, className, children }) => {
    const IconComponent = icon;
    const [open, setOpen] = useState(opened === true);
    const classes = useStyles();

    function handleToggleOpen() {
        setOpen(!open);
    }

    return (
        <>
            <ListItem button onClick={handleToggleOpen}>
                {
                    IconComponent
                        ? (
                            <ListItemIcon>
                                <IconComponent />
                            </ListItemIcon>
                        )
                        : null
                }
                <ListItemText primary={text} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" className={!!!className ? classes.listNested : className} disablePadding>
                    {children}
                </List>
            </Collapse>
        </>
    )
};
