import {
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { IWaterSupplyInterface } from "../../gateways/water-supply.interfaces";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import OpacityIcon from "@material-ui/icons/Opacity";
import EstablishmentIcon from '@material-ui/icons/Store';
import { EstablishmentGateway } from "../../gateways/establishment.gateway";
import useIoC from "../../contexts/ioc.context";

interface IWaterSupplyItemProps {
  item: IWaterSupplyInterface;
  onClickItem: (item: IWaterSupplyInterface) => void;
  establishmentId: string;
}

const useStyles: (
  props?: any
) => Record<"listItem" | "itemAvatarIcon" | "deletedSupply" | "nonDeletedSupply", string> = makeStyles((theme) => ({
  listItem: {
    cursor: "pointer",
  },
  itemAvatarIcon: {
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    padding: theme.spacing(1),
  },
  deletedSupply: {
    color: "#E0E0E0"
  },
  nonDeletedSupply: {
      color: "black"
  }
}));

const WaterSupplyItem: FC<IWaterSupplyItemProps> = (props) => {
  const classes = useStyles();

  const establishmentGateway = useIoC(EstablishmentGateway);

  const [establishmentName, setEstablishmentName] = useState<string>("");

  const ComponentIcon = () => {
    if (props.item.establishmentId && props.establishmentId === undefined) {
      return(
        <Tooltip title={establishmentName} placement="top">
          <ListItemIcon>
            <EstablishmentIcon color="action" fontSize="large" className={classes.itemAvatarIcon}/>
          </ListItemIcon>
        </Tooltip>
      );
    }
    return null;
  };

  let color = classes.nonDeletedSupply;
  if(props.item.blacklisted){
      color = classes.deletedSupply;
  }

  useEffect(() => {
    (async () => {
        if(props.item.establishmentId){
            const establishment = await establishmentGateway.findById(props.item.establishmentId);
            setEstablishmentName(establishment.name);
        }
    })();
  }, []);

  return (
    <div>
      <ListItem
        alignItems="flex-start"
        className={classes.listItem}
        onClick={() => {
          props.onClickItem(props.item);
        }}
      >
        <ListItemAvatar>
          <OpacityIcon
            color="action"
            fontSize="large"
            className={classes.itemAvatarIcon}
          />
        </ListItemAvatar>
        <ListItemText className={color} primary={`${props.item.referencia}`} />
        <ListItemSecondaryAction>
          <ComponentIcon />
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  );
};

export default WaterSupplyItem;
