import React, { FC, useState } from "react";
import ISector from "../../gateways/sector.interfaces";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import FormTextField from "../../components/form-text-field";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { SectorGateway } from "../../gateways/sector.gateway";
import { useSnackbar } from "notistack";

interface ISectorCreateDialogProps {
    open: boolean;
    onItemCreate: (item: ISector) => void;
    onClose: () => void;
}

const SectorCreateDialog: FC<ISectorCreateDialogProps> = (props) => {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const sectorGateway = useIoC(SectorGateway);
    const snackbar = useSnackbar();

    const handleCancel = () => {
        setName('');
        props.onClose();
    };

    const handleCreate = async () => {
        (async () => {
            if (validateRequired(name)) {
                try {
                    const sector = await sectorGateway.create({ name });
                    snackbar.enqueueSnackbar(t('sectorAddDialog.createSucceded', { id: sector.id }), { variant: "success" });
                    setName('');
                    props.onItemCreate(sector);
                } catch (e) {
                    const er = e as any;
                    if (er.response.data.key === 'creatingEntityError') {
                        const key = 'sectorErrorHandler.' + er.response.data.key;
                        const message = "validation" in er ? er.validation : t(key);
                        snackbar.enqueueSnackbar(message, { variant: "error" });
                    } else {
                        const message = "validation" in er ? er.validation : t('messages.defaultError');
                        snackbar.enqueueSnackbar(message, { variant: "error" });
                    }
                } finally {
                    props.onClose();
                }
            } else {
                snackbar.enqueueSnackbar(t('messages.requiredField'), { variant: "error" });
            }
        })();
    };

    const handleChange = (name: string, value: any) => {
        setName(value);
    };

    const validateRequired = (value: any) => {
        return value !== null && value !== undefined && value !== '';
    };

    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }}>
            <DialogTitle>{t('sectorAddDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('sectorAddDialog.content')}</DialogContentText>
                <FormTextField
                    name='name'
                    label={t('common.name') as string}
                    type='text'
                    validator={validateRequired}
                    errorText={t('messages.requiredField') as string}
                    value={name}
                    onChange={handleChange}
                    autoFocus
                    fullWidth
                    required />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleCreate} color="primary">{t('common.create')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SectorCreateDialog;