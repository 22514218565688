import React, { FC, useEffect, useState, useReducer } from "react";
import { useRouteMatch, Route } from "react-router-dom";
import useIoC from "../../contexts/ioc.context";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import { useTranslation } from "react-i18next";
import { StateType, FuelSupplyReducer, FuelSupplyContext } from "./fuel-supply-context"
import FuelSupplyDetail from "./fuel-supply-detail";
import FuelSupplyRouter from "./fuel-supply-router";
import { FuelSupplyGateway } from "../../gateways/fuel-supply.gateway";
import FuelContractRouter from "../fuel-contract/fuel-contract-router";
import RegisterFuelBillRouter from "../register-fuel-bill/register-fuel-bill-router";
import { IFuelSupply } from "../../gateways/fuel-supply.interface";
import { BlacklistGateway } from "../../gateways/blacklist.gateway";

const initialState: StateType = { fuelSupply: null };

export const FuelSupplyWrapper: FC = () => {
    const { t } = useTranslation();
    const match = useRouteMatch<{ fuelSupplyId: string }>();
    const [fuelSupplyId, setFuelSupplyId] = useState<number | null>(null);
    const fuelSupplyGateway = useIoC(FuelSupplyGateway);
    const blacklistGateway = useIoC(BlacklistGateway);
    const [, setLoading] = useState(true);
    const { update: updateBreadCrumbName } = useBreadCrumbName();

    const [fuelSupplyState, fuelSupplyStateReducer] = useReducer(FuelSupplyReducer, initialState);

    useEffect(() => {
        setFuelSupplyId(parseInt(match.params.fuelSupplyId, 10));
    }, [match.params.fuelSupplyId]);

    useEffect(() => {
        (async () => {
            if (fuelSupplyId === null) {
                return;
            }
            setLoading(true);
            const temp = await fuelSupplyGateway.findById(fuelSupplyId);
            const result = await checkIfBlackListed(temp);
            const breadCrumbName = result === false ? temp.reference: temp.reference + " (Cancelado)";
            updateBreadCrumbName('fuelSupply', breadCrumbName);
            fuelSupplyStateReducer({ type: 'update-fuelSupply', payload: {...temp, blacklisted: result } });
            setLoading(false);
        })();
    }, [fuelSupplyId]);

    async function checkIfBlackListed(data: IFuelSupply): Promise<boolean>{
        const r = data.reference;
        const referencia = r.length === 20 ? [r, r+"0F"]: r.length === 22 ? [r, r.slice(0,20)]: [r];
        const result = await blacklistGateway.findCups(referencia);
        if(result.length > 0){
            return r.length === 22 ? result[0] === r || result[0] === r.slice(0,20):
                   r.length === 20 ? result[0] === r || result[0] === r+"0F":
                   result[0] === r;
        }
        return false;
    }

    if (fuelSupplyState.fuelSupply === null) {
        return (<div>{t('common.loading')}</div>);
    }
    return (<FuelSupplyContext.Provider value={[fuelSupplyState, fuelSupplyStateReducer]}>

        <Route path={`${match.path}`} exact  >
            <FuelSupplyDetail />
        </Route>
        <Route path={`${match.path}/fuelContract`}>
            <FuelContractRouter />
        </Route>
        <Route path={`${match.path}/registerFuelBill`}>
            <RegisterFuelBillRouter />
        </Route>

    </FuelSupplyContext.Provider>);
}