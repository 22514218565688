import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IBotRO } from "./bots.interfaces";
import { IProviderType } from "./provider-types.interface";

@inject('axios')
export class ProviderTypesGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async findAll(): Promise<IProviderType[]> {
        const response = await this.axios.get('provider-types');
        return response.data;
    }
}