import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import IContact from "./contact.interface";
import { ContactEntityType } from "../pages/contacts/contact-list";
import querystring from 'querystring';

const contactCollection = 'contacts';

type ContactFindAllFilter = {
    entity?: string,
    entityId?: string,
    contactTypeId?: number
};

@inject('axios')
export class ContactGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async find(filter?: ContactFindAllFilter): Promise<IContact[]> {
        let url = `${contactCollection}`;
        if (filter !== undefined) {
            const query = querystring.stringify(filter);
            url += `?${query}`;
        }
        const response = await this.axios.get(url);
        return response.data;
    }

    async findHierarchy(filter: ContactFindAllFilter): Promise<{
        childContacts: IContact[];
        actualContacts: IContact[];
        parentContacts: IContact[];
    }> {
        const response = await this.axios.get(`${contactCollection}/hierarchy?${querystring.stringify(filter)}`);
        return response.data;
    }

    async create(contact: IContact, entity: ContactEntityType, entityId: string): Promise<IContact> {
        const response = await this.axios.post(contactCollection, { ...contact, entity, entityId });
        return response.data;
    }

    async update(contact: IContact, entity: ContactEntityType, entityId: string): Promise<IContact> {
        const response = await this.axios.put(contactCollection + `/${contact.id}`, { ...contact, entity, entityId: parseInt(entityId) });
        return response.data;
    }

    async migrateContacts(contactIds: number[], contactTypeId: number) {
        await this.axios.put(contactCollection, { contactIds, contactTypeId });
    }

    async delete(contactId: number): Promise<void> {
        await this.axios.delete(contactCollection + `/${contactId}`);
    }

    async checkIfEmailExists(email: string): Promise<number> {
        const response = await this.axios.get(contactCollection + '/checkExistingEmail/' + email);
        return response.data;
    }
}