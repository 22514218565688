import React, { FC } from "react";
import { makeStyles, Theme, createStyles, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import { ICreatePowerDetail } from "../../gateways/period-power-details.interface";
import FormControl from "@material-ui/core/FormControl";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IRegisterFuelBillCreate } from "../../gateways/register-fuel-bill.interface";

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5)
        },
        button: {
            margin: theme.spacing(0.5),

        },
        textField: {
            margin: theme.spacing(0.5)
        },
        root: {
            display: 'flex',
            overflowY: 'hidden'
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 1,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        formControl: {
            marginBottom: '1rem',
            '& :last-child': {
                marginBottom: 0
            }
        },
        progress: {
            margin: theme.spacing(2),
        },

        gridItem: {
            borderSpacing: 2,
            padding: theme.spacing(1)
        }
    })
);

interface IRegisterFuelBillGeneralProps {
    item: IRegisterFuelBillCreate;
    handleIssueDateSelector: (value: any) => void;
    handleChange: (name: string, value: any) => void;
    validateRequired: (value: any) => boolean;
}

const RegisterFuelBillGeneral: FC<IRegisterFuelBillGeneralProps> = (props) => {

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <Grid
                    container
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={6} className={classes.gridItem}>
                        <KeyboardDatePicker
                            margin="none"
                            fullWidth
                            format="dd/MM/yyyy"
                            id="issueDateSelector"
                            label={t('common.issueDate') as string}
                            value={props.item.issueDate}
                            onChange={props.handleIssueDateSelector}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={6} className={classes.gridItem} >
                        <FormTextField
                            name='numberBill'
                            label={t('common.numberBill') as string}
                            type='text'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.numberBill}
                            onChange={props.handleChange}
                            fullWidth
                            required
                        />
                    </Grid>

                    <Grid item xs={6} className={classes.gridItem} >
                        <FormTextField
                            name='totalBillImport'
                            label={t('common.totalBillImport') as string}
                            type='number'
                            validator={props.validateRequired}
                            errorText={t('messages.requiredField') as string}
                            value={props.item.totalBillImport}
                            onChange={props.handleChange}
                            fullWidth
                            required
                        />
                    </Grid>

                </Grid>

            </div>
        </div>
    );
};

export default RegisterFuelBillGeneral;