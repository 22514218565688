import { makeStyles, Fab, Box, List, Divider } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";
import React, { FC, useState, useMemo, useEffect } from "react";
import CenteredCircularProgress from "../../components/centered-circular-progress";
import { useNavigator } from "../../contexts/navigator.context";
import useIoC from "../../contexts/ioc.context";
import AddIcon from "@material-ui/icons/Add";
import AlertBox from "../../components/alert-box";
import IContactType from "../../gateways/contact-types.interfaces";
import { ContactTypesGateway } from "../../gateways/contact-types.gateway";
import ContactTypesIcon from '@material-ui/icons/ImportContacts';
import ContactTypeItem from "./contact-type-item";
import ContactTypeAddDialog from "./contact-type-add-dialog";
import ContactTypeUpdateDialog from "./contact-type-update-dialog";
import ContactTypeDeleteDialog from "./contact-type-delete-dialog";
import { useSnackbar } from "notistack";
import { ContactGateway } from "../../gateways/contact.gateway";
import ContactTypeMigrateContactsDialog from "./contact-type-migrate-contacts-dialog";
import IContact from "../../gateways/contact.interface";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer + 1,
    }
}));

const initialContactTypeState: IContactType = {
    id: 0,
    name: ''
}

const ContactTypeList: FC<RouteComponentProps> = ({ history }) => {
    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const contactTypesGateway = useIoC(ContactTypesGateway);
    const contactGateway = useIoC(ContactGateway);
    const [data, setData] = useState<IContactType[]>([]);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [contactType, setContactType] = useState<IContactType>(initialContactTypeState);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [openMigrateDialog, setOpenMigrateDialog] = useState(false);
    const [contacts, setContacts] = useState<IContact[]>([]);
    const snackbar = useSnackbar();
    const { t } = useTranslation();


    const deleteContactType = async (contactType: IContactType) => {
        try {
            await contactTypesGateway.delete(contactType.id);

            snackbar.enqueueSnackbar(t('contactTypeList.deleteSucceded', { id: contactType.id }), {
                variant: 'success',
            });

            removeItem(contactType);
            setDeleteDialogOpen(false);
        } catch (e) {
            const er = (e as any);
            if (er.response.data.key === 'deletingError') {
                const key = 'contactTypeErrorHandler.' + er.response.data.key;
                const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            setDeleteDialogOpen(false);
        }
    };

    const handleContactTypeCreated = (item: IContactType) => {
        setData([item, ...data]);
    };

    const handleAddContactType = () => {
        setAddDialogOpen(true);
    };

    const handleCloseAddDialog = () => {
        setAddDialogOpen(false);
    };

    const handleClickContactType = (item: IContactType) => {
        setContactType(item);
        setUpdateDialogOpen(true);
    };

    const handleCloseUpdateDialog = () => {
        setUpdateDialogOpen(false);
    };

    const handleContactTypeUpdated = (item: IContactType) => {
        let copy = data.slice();
        const index = copy.findIndex((value, index) => value.id === item.id);
        copy[index] = item;
        setData(copy);
    };

    const handleClickDeleteItem = (item: IContactType) => {
        setContactType(item);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };

    const handleCloseMigrateDialog = () => {
        setOpenMigrateDialog(false);
    };

    const handleDeleteContactType = async () => {
        const contacts = await contactGateway.find({ contactTypeId: contactType.id });
        setContacts(contacts);

        if (contacts.length === 0) {
            deleteContactType(contactType);
        } else {
            setDeleteDialogOpen(false);
            setOpenMigrateDialog(true);
        }
    };

    const handleMigrateContacts = async (newContactTypeId: number) => {
        const contactIds = contacts.map(c => c.id);
        const migrated = await migrateContacts(contactIds, newContactTypeId);
        if (migrated) {
            deleteContactType(contactType);
        }
    };

    const removeItem = (item: IContactType) => {
        let copy = data.filter((value) => {
            return value.id !== item.id;
        });
        setData(copy);
    };

    const migrateContacts = async (contactIds: number[], newContactTypeId: number) => {
        try {
            await contactGateway.migrateContacts(contactIds, newContactTypeId);

            snackbar.enqueueSnackbar(t('contactTypeList.migrationSucceded', { id: newContactTypeId }), {
                variant: 'success',
            });

            setOpenMigrateDialog(false);
            return true;
        } catch (e) {
            const er = (e as any);
            if (er.response.data.key === 'updatingError') {
                const key = 'contactErrorHandler.' + er.response.data.key;
                const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        return false;
    } finally {
        setOpenMigrateDialog(false);
    }
};

useEffect(() => {
    navigatorDispatch({
        type: 'set-header',
        header: {
            title: t('contactTypeList.title'),
            icon: ContactTypesIcon
        }
    });
}, []);

useEffect(() => {
    (async () => {
        setLoading(true);
        const clients = await contactTypesGateway.findAll();
        setData(clients);
        setLoading(false);
    })();
}, [contactTypesGateway]);

const [loading, setLoading] = useState(false);
const loadingComponent = loading ? <CenteredCircularProgress /> : null;

const isEmpty = useMemo(() => !loading && data.length === 0, [loading, data]);
const noItemsAlertBoxComponent = isEmpty ? <AlertBox variant="info">{t('common.noItems')}</AlertBox> : null;

return (
    <Box>
        <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleAddContactType}>
            <AddIcon />
        </Fab>
        {loadingComponent}
        {noItemsAlertBoxComponent}
        <List>
            {data.map((item, index) => {
                return (
                    <div>
                        <ContactTypeItem contactType={item} onClick={handleClickContactType} handleClickDeleteItem={handleClickDeleteItem} />
                        {index < data.length - 1 ? <Divider variant="inset" component="li" /> : null}
                    </div>
                );
            })}
        </List>
        <ContactTypeAddDialog
            open={addDialogOpen}
            onClose={handleCloseAddDialog}
            onContactTypeCreated={handleContactTypeCreated}
        />
        <ContactTypeUpdateDialog
            open={updateDialogOpen}
            onClose={handleCloseUpdateDialog}
            onContactTypeUpdated={handleContactTypeUpdated}
            contactType={contactType}
        />
        <ContactTypeDeleteDialog
            open={deleteDialogOpen}
            handleClose={handleCloseDeleteDialog}
            handleDeleteContactType={handleDeleteContactType}
        />
        <ContactTypeMigrateContactsDialog
            open={openMigrateDialog}
            handleClose={handleCloseMigrateDialog}
            onMigrateContacts={handleMigrateContacts}
            contactTypeId={contactType.id}
        />
    </Box>
);
}

export default withRouter(ContactTypeList);