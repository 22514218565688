import React, { FC, useEffect, useState, useReducer } from "react";
import { useRouteMatch, Route } from "react-router-dom";
import useIoC from "../../contexts/ioc.context";
import { useBreadCrumbName } from "../../contexts/breadCrumbNames.context";
import { useTranslation } from "react-i18next";
import { StateType, ElectricSupplyReducer, ElectricSupplyContext } from "./electric-supply-context"
import ElectricSupplyDetail from "./electric-supply-detail";
import ElectricSupplyRouter from "./electric-supply-router";
import FuelSupplyRouter from "../fuel-supply/fuel-supply-router";
import { ElectricSupplyGateway } from "../../gateways/electric-supply.gateway";
import ElectricContractRouter from "../electric-contract/electric-contract-router";
import RegisterElectricBillRouter from "../register-electric-bill/register-electric-bill-router";
import { IElectricSupply } from '../../gateways/electric-supply.interface';
import { BlacklistGateway } from "../../gateways/blacklist.gateway";

const initialState: StateType = { electricSupply: null };

export const ElectricSupplyWrapper: FC = () => {
    const { t } = useTranslation();
    const match = useRouteMatch<{ electricSupplyId: string }>();
    const [electricSupplyId, setElectricSupplyId] = useState<number | null>(null);
    const electricSupplyGateway = useIoC(ElectricSupplyGateway);
    const blacklistGateway = useIoC(BlacklistGateway);
    const [, setLoading] = useState(true);
    const { update: updateBreadCrumbName } = useBreadCrumbName();

    const [electricSupplyState, electricSupplyStateReducer] = useReducer(ElectricSupplyReducer, initialState);

    useEffect(() => {
        setElectricSupplyId(parseInt(match.params.electricSupplyId, 10));
    }, [match.params.electricSupplyId]);

    useEffect(() => {
        (async () => {
            if (electricSupplyId === null) {
                return;
            }
            setLoading(true);
            const temp = await electricSupplyGateway.findById(electricSupplyId);            
            const result = await checkIfBlackListed(temp);
            const breadCrumbName = result === false ? temp.referencia: temp.referencia + " (Cancelado)";
            updateBreadCrumbName('electricSupply', breadCrumbName);
            electricSupplyStateReducer({ type: 'update-electricSupply', payload: {...temp, blacklisted: result } });
            setLoading(false);
        })();
    }, [electricSupplyId]);

    async function checkIfBlackListed(data: IElectricSupply): Promise<boolean>{
        const r = data.referencia;
        const referencia = r.length === 20 ? [r, r+"0F"]: r.length === 22 ? [r, r.slice(0,20)]: [r];

        const result = await blacklistGateway.findCups(referencia);
        if(result.length > 0){
            return r.length === 22 ? result[0] === r || result[0] === r.slice(0,20):
                   r.length === 20 ? result[0] === r || result[0] === r+"0F":
                   result[0] === r;
        }
        return false;
    }

    if (electricSupplyState.electricSupply === null) {
        return (<div>{t('common.loading')}</div>);
    }
    return (<ElectricSupplyContext.Provider value={[electricSupplyState, electricSupplyStateReducer]}>

        <Route path={`${match.path}`} exact  >
            <ElectricSupplyDetail />
        </Route>
        <Route path={`${match.path}/electricContract`}>
            <ElectricContractRouter />
        </Route>
        <Route path={`${match.path}/registerElectricBill`}>
            <RegisterElectricBillRouter />
        </Route>

    </ElectricSupplyContext.Provider>);
}