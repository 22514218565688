import React, {
  FC,
  useContext,
  useState,
  useEffect,
  useMemo,
  useReducer,
  Fragment,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  FormControl,
  TextField,
} from "@material-ui/core";
import { StateContext, ReducerContext } from "./context";
import {
  IRepositoryQueryFilterDto,
  FilterOperator,
  IRepositoryQueryFilterOrDto,
  IRepositoryGroupType,
} from "../../gateways/repository.interfaces";
import AddIcon from "@material-ui/icons/Add";
import FilterItemDialog from "./filter-item.dialog";
import { FiltersReducer } from "./filters.reducer";
import { RepositoryGateway } from "../../gateways/repository.gateway";
import useIoC from "../../contexts/ioc.context";
import clsx from "clsx";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  hidden: {
    display: "none",
  },
}));

const CreateGroupDialog: FC = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(ReducerContext);
  const classes = useStyles();
  const repositoryGateway = useIoC(RepositoryGateway);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState<IRepositoryGroupType[]>([]);
  const [selectedGroup, setSelectedGroup] =
    useState<IRepositoryGroupType | null>(null);
  const [groupName, setGroupName] = useState<string>("");
  const snackbar = useSnackbar();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      setGroups(
        await repositoryGateway.getDefinitionGroupTypes(state.repositoryId)
      );
      setLoading(false);
    })();
  }, [state.repositoryId]);

  const handleClose = () => {
    dispatch({ type: "hideCreateGroupDialog" });
  };

  const handleSelectGroup = (group: IRepositoryGroupType) => {
    return () => {
      setSelectedGroup(group);
    };
  };

  const handleChangeGroupName = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setGroupName(event.target.value as string);
  };

  const handleCreateGroup = async () => {
    if (!selectedGroup) {
      snackbar.enqueueSnackbar("Tienes que seleccionar un grupo", {
        variant: "error",
      });
    } else if (groupName.trim().length === 0) {
      snackbar.enqueueSnackbar("Tienes que especificar el nombre del grupo", {
        variant: "error",
      });
    } else {
      try {
        setSaving(true);
        const group = await repositoryGateway.createGroup(state.repositoryId, {
          request: {
            criteria: {
              filters: state.filters,
              filterGroupId:
                state.selectedGroup !== null
                  ? state.selectedGroup.id
                  : undefined,
            },
          },
          groupTypeId: selectedGroup.id,
          name: groupName,
        });
        snackbar.enqueueSnackbar("Se ha creado el grupo correctamente", {
          variant: "success",
        });
        if (typeof state.onGroupCreated === "function") {
          state.onGroupCreated(group);
        }
        handleClose();
      } catch (e) {
        snackbar.enqueueSnackbar(
          "Ha ocurrido un error al crear el grupo: " + e,
          {
            variant: "error",
          }
        );
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <Dialog
      open={state.openCreateGroupDialog}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        Asistente de alta de un grupo
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : groups.length === 0 ? (
          <Typography>
            No se han encontrado grupos configurados para esta tabla
          </Typography>
        ) : !selectedGroup ? (
          <Fragment>
            <Typography>Selecciona un grupo de la siguiente lista</Typography>
            <List component="nav">
              {groups.map((group) => (
                <ListItem button onClick={handleSelectGroup(group)}>
                  <ListItemText primary={group.description} />
                </ListItem>
              ))}
            </List>
          </Fragment>
        ) : (
          <Fragment>
            <FormControl fullWidth>
              <TextField
                label="Nombre del grupo"
                type="text"
                onChange={handleChangeGroupName}
              />
            </FormControl>
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default" disabled={loading}>
          Cancelar
        </Button>
        <Button
          onClick={handleCreateGroup}
          color="primary"
          disabled={loading}
          className={selectedGroup ? undefined : classes.hidden}
        >
          Crear grupo
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateGroupDialog;
