import React, { FC, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from "@material-ui/core";
import {
  IFuelContract,
  IFuelContractCreate,
  initialIFuelContract,
} from "../../gateways/fuel-contract.interfaces";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IProvidersDisplay,
  IProviders,
} from "../../gateways/providers.interface";
import { IFuelSupplyRate } from "../../gateways/fuel-supply-rates.interface";
import { useSnackbar } from "notistack";
import { FuelContractGateway } from "../../gateways/fuel-contract.gateway";
import useIoC from "../../contexts/ioc.context";
import { datesHandler } from "../../utils/dates-handle";
import { ProvidersGateway } from "../../gateways/providers.gateway";
import { FuelSupplyRatesGateway } from "../../gateways/fuel-supply-rates.gateway";
import { ProviderType } from "../../gateways/provider-types.interface";
import { SupplyType } from "../../gateways/supply-type.interfaces";
import { FuelSupplyGateway } from "../../gateways/fuel-supply.gateway";
import moment from "moment";
import FuelContractForm from "./fuel-contract-form";
import ProvidersUpsertDialog from "../providers/providers-upsert-dialog";
import { useUser } from "../../contexts/user.context";

interface IFuelContractUpsertDialogProps {
  open: boolean;
  item: IFuelContract | undefined;
  onItemUpsert: (item: IFuelContract) => void;
  onClose: () => void;
}

interface IFuelContractRouteParams {
  [x: string]: string | undefined;
  fuelSupplyId: string;
}

const FuelContractUpsertDialog: FC<IFuelContractUpsertDialogProps> = (
  props
) => {
  const { fuelSupplyId } = useParams<IFuelContractRouteParams>();
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const dateHandler = new datesHandler();

  const [item, setItem] = useState<IFuelContract>(initialIFuelContract);
  const [providers, setProviders] = useState<IProviders[]>([]);
  const [fuelSupplyRates, setFuelSupplyRates] = useState<IFuelSupplyRate[]>([]);
  const [firstIteration, setFirstIteration] = useState<boolean>(true);
  const [{ user }] = useUser();

  const fuelSupplyGateway = useIoC(FuelSupplyGateway);
  const fuelContractGateway = useIoC(FuelContractGateway);
  const providerGateway = useIoC(ProvidersGateway);
  const fuelSupplyRatesGateway = useIoC(
    FuelSupplyRatesGateway
  );

  const [openProviderAddDialog, setOpenProviderAddDialog] = useState(false);

  const handleClickAddProviderDialog = () => {
    setOpenProviderAddDialog(true);
  };

  const handleCloseAddProviderDialog = () => {
    setOpenProviderAddDialog(false);
  };

  const handleCreateNewProvider = (item: IProviders, isNew: boolean) => {
    setProviders([...providers, item]);
  };

  const createFuelContract = async (item: IFuelContract) => {
    let contractToCreate:IFuelContractCreate = item //debug here ???
    if (!fuelSupplyId) return;
    const fuelContract: IFuelContract = await fuelContractGateway.create({
      ...contractToCreate,
      fuelSupplyId: parseInt(fuelSupplyId),
    });

    if(item.accountNumber && user)
      await fuelContractGateway.addAccountNumber(fuelContract.id, item.accountNumber, user)
    
    snackbar.enqueueSnackbar(
      t("fuelContractUpsertDialog.createSucceded", {
        id: fuelContract.id,
        reference: fuelContract.signContractDate,
      }),
      { variant: "success" }
    );

    props.onItemUpsert(fuelContract);
  };

  const handleAccept = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // if (!item.term || !item.signContractDate || !item.providerId
    //     || !item.modality || !item.initSupplyDate || !item.duration
    //     || !item.endSupplyDate || !item.fuelSupplyRateId || !item.cancelPriorNotice) {
    //     event.preventDefault();
    //     snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
    //     return;
    // }

    if (!dateHandler.checkLimits(item.initSupplyDate, item.endSupplyDate)) {
      event.preventDefault();
      snackbar.enqueueSnackbar(t("messages.dateRangeError"), {
        variant: "error",
      });
      return;
    }
    const existingContracts: IFuelContract[] =
      await fuelContractGateway.findAll({ fuelSupplyId });
    for (let i: number = 0; i < existingContracts.length; i++) {
      if (
        dateHandler.checkOverlap(
          new Date(item.initSupplyDate),
          new Date(item.endSupplyDate),
          existingContracts[i].initSupplyDate,
          existingContracts[i].endSupplyDate
        )
      ) {
        event.preventDefault();
        snackbar.enqueueSnackbar(
          t("messages.dateOverlapError", { id: existingContracts[i].id }),
          { variant: "error" }
        );
        return;
      }
    }

    createFuelContract(item);
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleChange = (name: string, value: any) => {
    setItem({ ...item, [name]: value });
  };

  const handleChangeModality = (name: string, value: any) => {
    if (value !== "2") {
      setItem({
        ...item,
        [name]: value,
        variableA: 0,
        variableB: 0,
        variableC: 0,
      });
    } else {
      setItem({ ...item, [name]: value });
    }
  };

  const handleSignContractDateChange = (value: any) => {
    setItem({ ...item, signContractDate: value });
  };

  const handleInitSupplyDateChange = (value: any) => {
    setItem({ ...item, initSupplyDate: value });
  };

  const handleEndSupplyDateChange = (value: any) => {
    setItem({ ...item, endSupplyDate: value });
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  useEffect(() => {
    (async () => {
      setItem(initialIFuelContract);
      const fuelSupply = fuelSupplyId
        ? await fuelSupplyGateway.findById(parseInt(fuelSupplyId))
        : null;

      const providers = await providerGateway.findWithFilters({
        providerTypeId: ProviderType.Comercializadora.toString(),
        supplyTypeId: SupplyType.Combustible.toString(),
        accessTypeId: fuelSupply?.accessTypeId.toString(),
      });

      setProviders(providers);

      const fuelSupplyRates = await fuelSupplyRatesGateway.findAll();
      setFuelSupplyRates(fuelSupplyRates);
    })();
  }, [props.open]);

  useEffect(() => {
    (async () => {
      if (firstIteration) {
        setFirstIteration(false);
      } else if (
        !dateHandler.checkLimits(item.initSupplyDate, item.endSupplyDate) &&
        props.open
      ) {
        snackbar.enqueueSnackbar(t("messages.dateRangeError"), {
          variant: "error",
        });
        return;
      } else if (props.open) {
        const existingContracts: IFuelContract[] =
          await fuelContractGateway.findAll({ fuelSupplyId });
        for (let i: number = 0; i < existingContracts.length; i++) {
          if (
            dateHandler.checkOverlap(
              new Date(item.initSupplyDate),
              new Date(item.endSupplyDate),
              existingContracts[i].initSupplyDate,
              existingContracts[i].endSupplyDate
            )
          ) {
            snackbar.enqueueSnackbar(
              t("messages.dateOverlapErrorContract", {
                id: existingContracts[i].id,
              }),
              { variant: "error" }
            );
            return;
          }
        }
      }
      setItem({
        ...item,
        duration: Math.ceil(
          moment(item.endSupplyDate).diff(item.initSupplyDate, "month", true)
        ),
      });
    })();
  }, [item.initSupplyDate, item.endSupplyDate]);

  return (
    <Box>
      <Dialog open={props.open} maxWidth={"md"} fullWidth={true}>
        <DialogTitle>{t("fuelContractUpsertDialog.createTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("fuelContractUpsertDialog.createContent")}
          </DialogContentText>
          <FuelContractForm
            fuelSupplyRates={fuelSupplyRates}
            item={item}
            onChange={handleChange}
            onChangeModality={handleChangeModality}
            onEndSupplyDateChange={handleEndSupplyDateChange}
            onInitSupplyDateChange={handleInitSupplyDateChange}
            onSignContractDateChange={handleSignContractDateChange}
            providers={providers}
            validateRequired={validateRequired}
            onClickAddProvider={handleClickAddProviderDialog}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("common.cancel")}
          </Button>
          <Button onClick={handleAccept} color="primary">
            {t("common.accept")}
          </Button>
        </DialogActions>
      </Dialog>
      <ProvidersUpsertDialog
        open={openProviderAddDialog}
        item={undefined}
        onClose={handleCloseAddProviderDialog}
        onItemUpsert={handleCreateNewProvider}
      />
    </Box>
  );
};

export default FuelContractUpsertDialog;
