import { FC, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import React from "react";
import useIoC from "../../contexts/ioc.context";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ValidationRuleGateway } from "../../gateways/validation-rules.gateway";
import { IValidationRule } from "../../gateways/validation-rules.interfaces";

export interface IValidationRuleDeleteDialogProps {
  open: boolean;
  validationRule: IValidationRule;
  onClose: () => void;
  onDeleted: (item: IValidationRule) => void;
}

const ValidationRuleDeleteDialog: FC<IValidationRuleDeleteDialogProps> = ({ open, onClose, onDeleted, validationRule }) => {
  const snackbar = useSnackbar();
  const { t } = useTranslation();

  const [componentDisabled, setComponentDisabled] = useState(false);

  const validationRuleGateway = useIoC(ValidationRuleGateway);

  const handleDeleteValidationGroup = () => {
    setComponentDisabled(true);
    (async () => {
      try {
        await validationRuleGateway.delete(validationRule.id);
        snackbar.enqueueSnackbar(t('validationRulesDeleteDialog.deleteSucceded', { name: validationRule.name }), { variant: "success" });
        onDeleted(validationRule);
      } catch (e) {
        const er = e as any;
        if (er.response.data.key === 'deletingError') {
          const key = 'validationGroupErrorHandler.' + er.response.data.key;
          const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
          snackbar.enqueueSnackbar(message, { variant: "error" });
        } else {
          const message = "validation" in er ? er.validation : t('messages.defaultError');
          snackbar.enqueueSnackbar(message, { variant: "error" });
        }
      } finally {
        setComponentDisabled(false);
        onClose();
      }
    })();
  };

  return (
    <Dialog open={open} onClose={() => { onClose() }} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('validationRulesDeleteDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('validationRulesDeleteDialog.content')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { onClose() }} color="primary" disabled={componentDisabled}>{t('common.cancel')}</Button>
        <Button onClick={handleDeleteValidationGroup} color="primary" disabled={componentDisabled}>{t('common.accept')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValidationRuleDeleteDialog;
