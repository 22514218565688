import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import ICompany from "./company.interface";
import querystring from 'querystring';

type CompanyFindAllFilter = {
    clientId?: number;
    countryId?: number;
    showDiscarded?: boolean;
}
@inject('axios')
export class CompanyGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(filter?: CompanyFindAllFilter): Promise<ICompany[]> {
        let url = 'companies';
        if (filter) {
            const query = querystring.stringify(filter);
            url += `?${query}`;
        }
        const response = await this.axios.get(url);
        return response.data;
    }

    async findById(id: string | number): Promise<ICompany> {
        const response = await this.axios.get(`companies/${id}`);
        return response.data;
    }

    async findAllFiltered(toLookFor: string, input: string): Promise<ICompany[]> {
        const response = await this.axios.get(`companies/filter/${toLookFor}/${input}`);
        return response.data;
    }

    async create(company: Partial<ICompany>): Promise<ICompany> {
        const response = await this.axios.post('companies', company);
        return response.data;
    }

    async update(id: string, company: Partial<ICompany>): Promise<ICompany> {
        const response = await this.axios.put(`companies/${id}`, company);
        return response.data;
    }

    async migrateCompanies(companyIds: number[], countryId: number): Promise<ICompany[]> {
        const response = await this.axios.put('companies', { companyIds, countryId });
        return response.data;
    }
}