import React, { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { List, ListItem, makeStyles, Box } from "@material-ui/core";
import { ElectricSupplyGateway } from '../../gateways/electric-supply.gateway';
import { IRegisterElectricBill } from "../../gateways/register-electric-bill.interface";
import { useHistory } from "react-router-dom";
import { TreeItem } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    item: {
        padding: 0,
        margin: 0,
        fontWeight: 'normal'
    }
}));

interface IClientPeekElectricBillFormProps {
    electricSupplyId: string;
    currentUrl: string;
}

const ClientPeekElectricBillForm: FC<IClientPeekElectricBillFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [data, setData] = useState<IRegisterElectricBill[]>([]);
    const electricSupplyGateway = useIoC(ElectricSupplyGateway);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const data = await electricSupplyGateway.findByIdRegisterElectricBill(parseInt(props.electricSupplyId));
            setData(data);
        })();
    }, [electricSupplyGateway]);

    const handleStopPropagation = useCallback( (event: React.MouseEvent) => {
        event.stopPropagation();               
    }, [])


    return (
        <Box>
            {
                data.length > 0 &&
                <TreeItem nodeId={props.currentUrl + 'registerElectricBill'} label={t('rightNavigation.electricSupply')}>
                    {
                        data.map((item) => {
                            return (
                                <TreeItem nodeId={`${props.currentUrl}/registerElectricBill/${item.id}`} label={<a onClick={handleStopPropagation} href={`${props.currentUrl}/registerElectricBill/${item.id}`} target="_blank">{item.numberBill}</a>} />
                            )
                        })
                    }
                </TreeItem>
            }
        </Box>
    )
};

export default ClientPeekElectricBillForm;