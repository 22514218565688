import React, { FC, useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, makeStyles, Theme, createStyles, Chip, FormControl, FormControlLabel, Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import SelectorField from "../../components/selector-field";
import { ISupplyConnectionBot, initialSupplyConnectBot, ICreateSupplyConnectionBot, IUpdateSupplyConnectionBot } from "../../gateways/supply-connection-bot.interface";
import { SupplyConnectionBotGateway } from "../../gateways/supply-connection-bot.gateway";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { BotsGateway } from "../../gateways/bots.gateway";
import { IBotPreconfigRO } from "../../gateways/bots.interfaces";

interface ISupplyConnectionBotFormProps {
    open: boolean;
    item: ISupplyConnectionBot | undefined;
    electricContractId?: string;
    fuelContractId?: string;
    onItem: (item: ISupplyConnectionBot) => void;
    onClose: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5)
        },
        button: {
            margin: theme.spacing(0.5),

        },
        textField: {
            margin: theme.spacing(0.5)
        },
        formControl: {
            marginBottom: '1rem',
            '& :last-child': {
                marginBottom: 0
            }
        }
    })
);


const SupplyConnectionBotDialog: FC<ISupplyConnectionBotFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const snackbar = useSnackbar();

    const [item, setItem] = useState<ISupplyConnectionBot>(initialSupplyConnectBot);
    const [botPreconfigs, setBotPreconfigs] = useState<IBotPreconfigRO[]>([]);
    const supplyConnectionBotGateway = useIoC(SupplyConnectionBotGateway);
    const botPreconfigGateway = useIoC(BotsGateway);



    const createSupplyConnectionBot = async (item: ISupplyConnectionBot) => {
        const createSupplyConnectionBot: ICreateSupplyConnectionBot =
        {
            enabled: item.enabled,
            electricContractId: props.electricContractId,
            fuelContractId: props.fuelContractId,
            botPreconfigId: item.botPreconfigId,
            startCaptureDate: item.startCaptureDate
        };
        const supplyConnectionBot = await supplyConnectionBotGateway.create(createSupplyConnectionBot);
        snackbar.enqueueSnackbar(t('supplyConnectionBotDialog.createSucceded'), { variant: "success" });
        props.onItem(supplyConnectionBot);
    };

    const updateSupplyConnectionBot = async (item: ISupplyConnectionBot) => {
        const updateSupplyConnectionBot: IUpdateSupplyConnectionBot =
        {
            enabled: item.enabled,
            electricContractId: props.electricContractId,
            fuelContractId: props.fuelContractId,
            botPreconfigId: item.botPreconfigId,
            startCaptureDate: item.startCaptureDate
        };
        const supplyConnectionBot = await supplyConnectionBotGateway.update(item.id, updateSupplyConnectionBot);
        snackbar.enqueueSnackbar(t('supplyConnectionBotDialog.updateSucceded'), { variant: "success" });
        props.onItem(supplyConnectionBot);

    };

    const upsertSupplyConnectionBot = async (item: ISupplyConnectionBot) => {
        try {
            if (!props.item) {
                await createSupplyConnectionBot(item);
            } else {
                await updateSupplyConnectionBot(item);
            }
        } catch (e) {
            const er = e as any;
            const key = 'complexErrorHandler.' + er.response.data.key;
            if (er.response.data.key === 'creatingEntityError') {
                const message = "validation" in er ? er.validation : t(key);
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else if (er.response.data.key === 'updatingError') {
                const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose();
        }
    };

    const handleAccept = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!item.startCaptureDate || !item.botPreconfigId) {
            event.preventDefault();
            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        }

        upsertSupplyConnectionBot(item);
    };

    const handleChangeCheck = (prop: string) => (event: React.ChangeEvent<{ value: any }>) => {

        setItem({ ...item, enabled: !item.enabled })

    };
    const handleChange = (name: string, value: any) => {
        setItem({ ...item, [name]: value });
    };
    const handleChangeDate = (prop: string) => (date: Date | null) => {
        setItem({ ...item, [prop]: date });

    };


    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    useEffect(() => {
        if (props.item) {
            setItem(props.item);
        } else {
            setItem(initialSupplyConnectBot);
        }
        (async () => {

            const botPreconfigs = await botPreconfigGateway.findAllPreconfig();
            setBotPreconfigs(botPreconfigs);
        })();
    }, [props.item, props.open]);

    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }}>
            <DialogTitle>{!props.item ? t('supplyConnectionBotDialog.createTitle') : t('supplyConnectionBotDialog.updateTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{!props.item ? t('supplyConnectionBotDialog.createContent') : t('supplyConnectionBotDialog.updateContent')}</DialogContentText>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={item.enabled}
                                onChange={handleChangeCheck('enabled')}
                            />
                        }
                        label="Activo"
                    />
                </FormControl>
                <FormControl fullWidth className={classes.formControl}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label="Capturar facturas a partir de"
                        value={item.startCaptureDate}
                        onChange={handleChangeDate('startCaptureDate')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </FormControl>

                <SelectorField
                    name='botPreconfigId'
                    emptyValue={false}
                    fullWidth
                    inputLabel={t('common.botPreconfig')}
                    helperText={t('messages.requiredField') as string}
                    required
                    onChange={handleChange}
                    value={item.botPreconfigId}
                    validator={validateRequired}
                    values={botPreconfigs.map(value => {
                        return { id: value.id, value: value.alias };
                    })} />



            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onClose() }} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleAccept} color="primary">{t('common.accept')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SupplyConnectionBotDialog;