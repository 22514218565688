import React, { FC } from "react";
import { makeStyles, Grid, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    }
}));


interface IStepProps {
    title: string;
    nextStep?: () => void;
    prevStep?: () => void;
    endStep?: () => void;
}

const Step: FC<IStepProps> = ({ title, nextStep, prevStep,endStep ,children }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (

        <Grid container >
            <Typography> {title} </Typography>

            {children}

            {prevStep ? (
                <Button
                    color="primary"
                    variant="contained"
                    onClick={prevStep}
                    className={classes.button}
                >
                    {t('common.back')}
                </Button>)
                : null
            }

            {nextStep ?
                <Button
                    color="primary"
                    variant="contained"
                    onClick={nextStep}
                    className={classes.button}
                >
                    {t('common.next')}
                </Button>
                : null}

            {endStep ?
                <Button
                    color="primary"
                    variant="contained"
                    onClick={endStep}
                    className={classes.button}
                >
                    {t('common.end')}
                </Button>
                : null}
        </Grid>

    );
};
export default Step;