import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";

@inject('axios')
export class OAuth2Gateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async generateUrl() {
        return await this.axios.get('oauth2/auth');
    }
}