import React, { useEffect, useState, Fragment } from 'react';
import { SnackbarProvider } from 'notistack';

import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core';
import { UserProvider } from './contexts/user.context';
import { ThemeProvider } from '@material-ui/styles';
import { NavigatorProvider } from './contexts/navigator.context';
import { Main } from './layout/main';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { BreadCrumbNameContextProvider } from './contexts/breadCrumbNames.context'

const EnovamTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#b1c904',
      contrastText: '#fff'
    },
  },
});

const App: React.FC = () => {
  return (
    <UserProvider>
      <BreadCrumbNameContextProvider>
        <NavigatorProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <SnackbarProvider maxSnack={3}>
              <ThemeProvider theme={EnovamTheme}>
                <CssBaseline />
                <Main />
              </ThemeProvider>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </NavigatorProvider>
      </BreadCrumbNameContextProvider>
    </UserProvider>
  );
}

export default App;
