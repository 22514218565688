import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  IBotPreconfigFieldRO,
  IBotRO,
  IPreconfigCustomFields,
} from "../../gateways/bots.interfaces";
import useIoC from "../../contexts/ioc.context";
import { BotsGateway } from "../../gateways/bots.gateway";
import {
  Dialog,
  makeStyles,
  FormControl,
  InputLabel,
  Input,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  FormHelperText,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  fabAdd: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.drawer + 1,
  },
  fabDelete: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(10),
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  formControl: {
    marginBottom: "1rem",
    "& :last-child": {
      marginBottom: 0,
    },
  },
  configRoot: {
    padding: theme.spacing(3, 2),
    marginBottom: theme.spacing(1),
  },
}));

interface IPreconfigCustomDialog {
  open: boolean;
  onClose: () => void;
}

const PreconfigCustomDialog: FC<IPreconfigCustomDialog> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const snackbar = useSnackbar();

  const botsGateway = useIoC(BotsGateway);

  const [bots, setBots] = useState<IBotRO[]>([]);
  const [fields, setFields] = useState<IBotPreconfigFieldRO[]>([]);
  const [customFields, setCustomFields] = useState<IPreconfigCustomFields[]>(
    []
  );
  const [botId, setBotId] = useState<number | "">("");

  const handleChangeBot = (e: React.ChangeEvent<{ value: unknown }>) =>
    setBotId(e.target.value as number);

  const handleClick = async () => {
    const bot = bots.find((e) => e.id === botId);
    if (!bot) return;

    const botToLaunch = {
      id: 1,
      alias: "",
      bot: bot,
      fields: fields,
      enabled: true,
      meterRentalPreconfig: false,
    };

    try {
      await botsGateway.launchBotProvider(botToLaunch, customFields);
      snackbar.enqueueSnackbar(t("messages.botAccess"), {
        variant: "success",
      });
    } catch (e) {
      const er = e as any;
      const message = er.validation || er.message;
      snackbar.enqueueSnackbar(message, {
        variant: "error",
      });
    }
    props.onClose();
    setBotId("");
  };

  useEffect(() => {
    (async () => {
      const bots = await botsGateway.findAll();
      setBots(bots);
    })();
  }, [botsGateway]);

  useEffect(() => {
    if (botId) {
      const bot = bots.find((x) => x.id === botId);
      if (bot) {
        const botFields = bot.fields.map((f) => ({
          field: f.field,
          value: "",
          type: f.type,
        }));

        setFields(botFields);
        setCustomFields([
          { field: "cups", value: "" },
          { field: "emailNotification", value: "" },
          { field: "invoicesFolder", value: "" },
          { field: "startCaptureDate", value: new Date() },
          { field: "startValidationsDate", value: new Date() },
        ]);
        return;
      }
    }
    setFields([]);
  }, [botId, bots]);

  const renderFieldConfig = (field: Partial<IPreconfigCustomFields>) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      field.value = e.target.value;
      if (customFields.find((e) => e.field === field.field))
        setCustomFields([...customFields]);
      else setFields([...fields]);
    };

    const handleChangeDate = (newDate: any) => {
      if (!newDate) return;
      field.value = newDate;
      setCustomFields([...customFields]);
    };

    if (
      field.field === "startCaptureDate" ||
      field.field === "startValidationsDate"
    ) {
      const label =
        field.field === "startCaptureDate"
          ? "startCaptureDate"
          : "startValidationsDate";
      return (
        <KeyboardDatePicker
          format="dd/MM/yyyy"
          margin="normal"
          id={label}
          label={t(`common.${label}`)}
          value={field.value}
          onChange={handleChangeDate}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          fullWidth
        />
      );
    } else {
      return (
        <FormControl
          key={"field_" + field.field}
          className={classes.formControl}
        >
          <InputLabel htmlFor={`config-${field.field}-input`}>
            {t(`common.${field.field}`)}
          </InputLabel>
          <Input
            id={`config-${field.field}-input`}
            value={field.value}
            onChange={handleChange}
            required
          />
        </FormControl>
      );
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle id="form-dialog-title">
        <Typography variant="h5">{t("common.botPreconfigDialog")}</Typography>
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="bot-input">Bot</InputLabel>
            <Select
              value={botId}
              onChange={handleChangeBot}
              inputProps={{ name: "bot-input" }}
            >
              {bots.map((x) => (
                <MenuItem key={"bot_" + x.id} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText id="bot-helper-text">
              Bot encargado de capturar las facturas del suministro
            </FormHelperText>
          </FormControl>

          <Typography variant="h6">{t("common.botConfigFields")}</Typography>
          {botId ? (
            fields.map(renderFieldConfig)
          ) : (
            <Typography color="error">Selecciona un bot</Typography>
          )}
          {botId ? customFields.map(renderFieldConfig) : ""}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="default">
          {t("common.close")}
        </Button>
        <Button onClick={handleClick} color="primary">
          {t("common.execute")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreconfigCustomDialog;
