import React, { FC } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface ICountryDeleteDialogProps {
    open: boolean;
    onItemDelete: () => void;
    onClose: () => void;
}

const CountryDeleteDialog: FC<ICountryDeleteDialogProps> = (props) => {
    const { t } = useTranslation();

    const handleAccept = () => {
        props.onItemDelete();
    };

    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{t('countryDeleteDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('countryDeleteDialog.content')}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onClose() }} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleAccept} color="primary">{t('common.accept')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CountryDeleteDialog;