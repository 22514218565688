import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IAddManualInvoice } from "./invoices.interfaces";

@inject('axios')
export class InvoiceGateway {
    constructor(
        private axios: AxiosInstance
    ){}

    public async findAllTypes(){
        const response = await this.axios.get('invoices/findInvoiceTypes');
        return response.data;
    }

    public async findInvoicesByQuery(query: any){
        const response = await this.axios.post('invoices/findmany_invoice_by_query', query);
        return response.data;
    }

    public async findInvoicesByTextQuery(query: any){
        const response = await this.axios.post('invoices/findmany_invoice_by_textquery', query);
        return response.data;
    }

    public async saveManualInvoice(item: IAddManualInvoice, uriData: string, username: string) {
        try{
            return await this.axios.post('invoices/save_manual_invoice', {item, uriData, username});
        } catch (error){
            throw error;
        }
    }
}
