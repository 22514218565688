import React, { FC } from "react";
import { withRouter, RouteComponentProps, Route, Switch } from "react-router";
import { useUser } from "../../contexts/user.context";
import curvesList from "./curves-list";

const CurvesIndex: FC<RouteComponentProps> = ({ match }) => {
    const [{ canViewBotsMenu, canViewCurvesMenu }] = useUser();
    return (
        <Switch>
            {canViewBotsMenu || canViewCurvesMenu ? <Route path={`${match.path}`} exact component={curvesList} /> : null }
        </Switch>
    );
}

export default withRouter(CurvesIndex);