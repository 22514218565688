import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import FSTable from "../../components/fs-table";
import { Box, IconButton } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MarketingGateway } from '../../gateways/marketing.gateway';
import useIoC from '../../contexts/ioc.context';
import { useSnackbar } from 'notistack';

interface IMarketingAdminCampaignDetailProps {
    campaignId: string;
}

const AdminCampaignDetailItemAction: FC<{ item: any, campaignId: number, onChange: () => void }> = ({ item, campaignId, onChange }) => {
    const [inCampaign, setInCampaign] = useState<boolean>(item.inCampaign);
    const [updating, setUpdating] = useState<boolean>(false);
    const marketingGateway = useIoC(MarketingGateway);
    const snackbar = useSnackbar();

    const handleAdd = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        (async () => {
            setUpdating(true);
            try {
                await marketingGateway.addClientToCampaign(campaignId, item.id);
                setInCampaign(true);
                onChange();
            } catch (e) {
                console.error('Ha ocurrido un error al añadir un cliente a la campaña', e);
                snackbar.enqueueSnackbar('Ha ocurrido un error al añadir el cliente a la campaña', {
                    variant: 'error',
                });
            }
            setUpdating(false);
        })();
    }

    const handleRemove = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        (async () => {
            setUpdating(true);
            try {
                await marketingGateway.removeClientToCampaign(campaignId, item.id);
                setInCampaign(false);
                onChange();
            } catch (e) {
                console.error('Ha ocurrido un error al eliminar un cliente de la campaña', e);
                snackbar.enqueueSnackbar('Ha ocurrido un error al eliminar el cliente de la campaña', {
                    variant: 'error',
                });
            }
            setUpdating(false);
        })();
    }

    useEffect(() => {
        setInCampaign(item.inCampaign);
    }, [item]);

    return updating ? (
        <CircularProgress color="secondary" />
    ) : (inCampaign ? (
        <IconButton onClick={handleRemove} color="secondary">
            <DeleteIcon />
        </IconButton>
    ) : (
            <IconButton onClick={handleAdd} color="primary">
                <AddIcon />
            </IconButton>
        )
        );
}


let hackReloadStatic = 1;
const AdminCampaignDetail: FC<RouteComponentProps<IMarketingAdminCampaignDetailProps>> = ({ history, match }) => {

    const campaignId = useMemo(() => parseInt(match.params.campaignId), [match.params.campaignId]);
    const [hackReload, setHackReload] = useState(0);

    const params = useMemo(() => ({ campaignId, hackReload }), [hackReload, campaignId]);

    const handleChange = useCallback(() => {
        setHackReload(hackReloadStatic++);
    }, [hackReload])

    const handleNavigateClient = (item: any) => {
        history.push('/marketing/clients/' + item.id);
    }

    const columns = useMemo(() => ([
        { label: 'Camp.', name: 'inCampaign', sortable: true, render: (item: any) => React.cloneElement(<AdminCampaignDetailItemAction onChange={() => handleChange()} campaignId={campaignId} item={item} />) },
        { name: 'comercialName', label: 'Nombre Comercial', sortable: true, },
        { name: 'postalCode', label: 'C.P.', sortable: true, },
        { name: 'opportunityFees', label: 'Oportunidad honorarios', sortable: true, },
        { name: 'potentalSavings', label: 'Ahorro potencial', sortable: true, },
        { name: 'averageInvoice', label: 'Fact. media', sortable: false, },
        { name: 'volume', label: 'Volumen', sortable: true, },
        { name: 'horasEquivalentes', label: 'heq', sortable: true, },

        { name: 'nif', label: 'NIF', sortable: true, },
        { name: 'cups', label: 'CUPS', sortable: false, },
        { name: 'nombreComercializadora', label: 'Comercializadora', sortable: true, },
        { name: 'potenciaContratadaP1', label: 'Pot. contr. P1', sortable: true, },
        { name: 'potenciaContratadaP2', label: 'Pot. contr. P2', sortable: true, },
        { name: 'potenciaContratadaP3', label: 'Pot. contr. P3', sortable: true, },
        { name: 'potenciaContratadaP4', label: 'Pot. contr. P4', sortable: true, },
        { name: 'potenciaContratadaP5', label: 'Pot. contr. P5', sortable: true, },
        { name: 'potenciaContratadaP6', label: 'Pot. contr. P6', sortable: true, },

        { name: 'estado', label: 'Estado', sortable: true, },
        { name: 'types', label: 'Tipos', sortable: false, },

        // { name: 'inCampaign', label: 'En campaña', visible: false},
    ]), [campaignId, hackReload, handleChange]);

    return (
        <Box>
            <FSTable
                repositoryId="main-marketing-cups-admin"
                groupsEnabled={false}
                onRowClick={handleNavigateClient}
                title={'Listado de clientes en campaña ' + campaignId}
                params={{ ...params }}
                columns={columns}
            />
        </Box>
    );
}

export default withRouter(AdminCampaignDetail);