import React, { FC, useState, useEffect } from "react";
import { Dialog, Theme, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, makeStyles, createStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import { ElectricContractGateway } from "../../gateways/electric-contract.gateway";
import { IRegisterElectricBill, IRegisterElectricBillCreate, initialIRegisterElectricBillCreate } from "../../gateways/register-electric-bill.interface";
import { RegisterElectricBillGateway } from "../../gateways/register-electric-bill.gateway";
import { ICreatePowerDetail, IPowerDetail, initialIPowerDetail, initialICreatePowerDetail } from "../../gateways/period-power-details.interface";
import { ICreateEnergyDetail, IEnergyDetail, initialICreateEnergyDetail, initialIEnergyDetail } from "../../gateways/period-energy-details.interface";
import CountryGateway from "../../gateways/country.gateway";
import CurrencyGateway from "../../gateways/currency.gateway";
import { datesHandler } from "../../utils/dates-handle";
import { ElectricSupplyGateway } from "../../gateways/electric-supply.gateway";
import { useParams } from "react-router";
import { IElectricContract, initialElectricContract } from "../../gateways/electric-contract.interfaces";
import { IElectricBillPowerMaterialTableRows, IElectricBillEnergyMaterialTableRows } from "../../utils/material-table.interface";
import RegisterElectricBillFormUpsert from "./register-electric-bill-form-upsert"

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(0.5),

        },
        textField: {
            margin: theme.spacing(0.5)
        },
        root: {
            display: 'flex',
            overflowY: 'hidden'
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 1,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        },
        formControl: {
            marginBottom: '1rem',
            '& :last-child': {
                marginBottom: 0
            }
        },
        progress: {
            margin: theme.spacing(2),
        },

        gridItem: {
            height: '100%',
            borderSpacing: 2,
            padding: theme.spacing(1)
        }
    })
);
interface IRegisterElectricBillUpsertDialogProps {
    open: boolean;
    item: IRegisterElectricBillCreate | undefined;
    electricSupplyId: string;
    complexId: string | undefined;
    establishmentId: string | undefined;
    onItemUpsert: (item: IRegisterElectricBill, isNew: boolean) => void;
    onClose: () => void;
}

interface IElectricBillRouteParams {
    electricSupplyId: string;
}

const RegisterElectricBillUpsertDialog: FC<IRegisterElectricBillUpsertDialogProps> = (props) => {
    const { electricSupplyId } = useParams<IElectricBillRouteParams>();
    const { t } = useTranslation();
    const snackbar = useSnackbar();
    const dateHandler = new datesHandler();

    const [item, setItem] = useState<IRegisterElectricBillCreate>(initialIRegisterElectricBillCreate);
    const [, setPowerPastMonth] = useState<IPowerDetail[]>(initialIPowerDetail);
    const [, setPowerPastYear] = useState<IPowerDetail[]>(initialIPowerDetail);
    const [, setEnergyPastMonth] = useState<IEnergyDetail[]>(initialIEnergyDetail);
    const [, setEnergyPastYear] = useState<IEnergyDetail[]>(initialIEnergyDetail);
    const [firstIteration, setFirstIteration] = useState<boolean>(true);
    const [currency, setCurrency] = useState<string>('');
    const [electricContract, setElectricContract] = useState<IElectricContract>(initialElectricContract);
    const [powerState, setPowerState] = useState<IElectricBillPowerMaterialTableRows[]>([]);
    const [energyState, setEnergyState] = useState<IElectricBillEnergyMaterialTableRows[]>([]);

    const registerElectricBillGateway = useIoC(RegisterElectricBillGateway);
    const electricContractGateway = useIoC(ElectricContractGateway);
    const countriesGateway = useIoC(CountryGateway);
    const currenciesGateway = useIoC(CurrencyGateway);
    const electricSupplyGateway = useIoC(ElectricSupplyGateway);

    const createRegisterElectricBill = async (item: IRegisterElectricBillCreate) => {
        const registerElectricBill = await registerElectricBillGateway.create({ ...item, electricSupplyId: parseInt(props.electricSupplyId) });
        snackbar.enqueueSnackbar(t('registerElectricBillUpsertDialog.createSucceded',
            { id: registerElectricBill.id }), { variant: "success" });
        props.onItemUpsert(registerElectricBill, true);
    };

    const upsertElectricSupply = async (item: IRegisterElectricBillCreate) => {
        try {
            if (!props.item) {
                await createRegisterElectricBill(item);
            } else {
                throw Error();
            }
        } catch (e) {
            const er = e as any;
            const key = 'registerElectricBillErrorHandler.' + er.response.data.key;
            if (er.response.data.key === 'creatingEntityError') {
                const message = "validation" in er ? er.validation : t(key);
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else if (er.response.data.key === 'updatingError') {
                const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose();
        }
    };

    const handleAccept = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!item.numberBill || !item.issueDate || !item.startingPeriodDate
            || !item.endingPeriodDate || !checkBonification() || !item.powerExcessImport
            || !item.importEquipmentRental || !item.electricTax || !item.electricContractId
            || !checkPowerDetail() || !item.powerRateImport || !checkEnergyDetail() ||
            (
                item.multiplePeriodsBill &&
                (
                    !item.firstRangeOfDates ||
                    !item.secondRangeOfDates
                )
            )
            || !item.electricRateBillImport || !item.reactiveImport
            || !item.totalBillImport || !item.totalBillImportIVA) {
            event.preventDefault();
            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        }
        if (!(await checkBillOverlap())) {
            return;
        }

        if (!dateHandler.checkLimits(item.startingPeriodDate, item.endingPeriodDate)) {
            event.preventDefault();
            snackbar.enqueueSnackbar(t('messages.dateRangeError'), { variant: "error" });
            return;
        }
        upsertElectricSupply(item);
    };

    const checkBillOverlap = async (): Promise<boolean> => {
        const existingBills =
            await electricSupplyGateway.findByIdRegisterElectricBill(parseInt(electricSupplyId));

        for (const bill of existingBills) {
            if (dateHandler.checkOverlap(new Date(item.startingPeriodDate),
                new Date(item.endingPeriodDate),
                bill.startingPeriodDate,
                bill.endingPeriodDate)) {
                snackbar.enqueueSnackbar(t('messages.dateOverlapErrorBill', { id: bill.id }), { variant: "error" });
                return false;
            }
        }
        return true;
    }

    const checkEnergyDetail = () => {
        for (const periodEnergyDetail of item.periodEnergyDetails) {
            if (!periodEnergyDetail.consumedEnergy ||
                (
                    item.multiplePeriodsBill &&
                    (
                        !periodEnergyDetail.secondConsumedEnergy ||
                        !periodEnergyDetail.secondElectricityCost
                    )
                )
                || !periodEnergyDetail.electricityCost ||
                !periodEnergyDetail.reactiveEnergy) {
                return false;
            }
        }

        return true;
    };

    const checkPowerDetail = () => {
        for (const periodPowerDetail of item.periodPowerDetails) {
            if (!periodPowerDetail.consumedPower ||
                (
                    item.multiplePeriodsBill &&
                    (
                        !periodPowerDetail.consumedPowerSecondPeriod ||
                        !periodPowerDetail.secondaryPowerRate
                    )
                )
                || !periodPowerDetail.powerRate ||
                !periodPowerDetail.discount || !periodPowerDetail.hiredPower) {
                return false;
            }
        }
        return true;
    };

    const checkBonification = () => {
        if (item.bonificationIncluded && (!item.bonusImport || !item.bonusBillNumber)) {
            return false
        }
        return true;
    };

    const handleChange = (name: string, value: any) => {
        setItem({ ...item, [name]: value });
    };

    const handleIssueDateSelector = (value: any) => {
        setItem({ ...item, issueDate: value });
    };

    const handleStartingPeriodDateSelector = (value: any) => {
        setItem({ ...item, startingPeriodDate: value });
    };

    const handleEndingPeriodDateSelector = (value: any) => {
        setItem({ ...item, endingPeriodDate: value });
    };

    const handleBonificationIncludedChange = () => {
        setItem({ ...item, bonificationIncluded: !item.bonificationIncluded })
    };

    const handleCompensatedChange = () => {
        setItem({ ...item, compensateInjectEnergy: !item.compensateInjectEnergy })
    };

    const handleEstimatedChange = () => {
        setItem({ ...item, estimated: !item.estimated })
    };

    const handleMultiplePeriodsBillChange = () => {
        setItem({ ...item, multiplePeriodsBill: !item.multiplePeriodsBill })
    };

    const handleChangeDoNothing = () => {

    };

    const handleChangePeriodRegisterElectricBillPower = (updatingArray: IElectricBillPowerMaterialTableRows, index: number) => {
        let updatePeriodsAid: ICreatePowerDetail[] = item.periodPowerDetails.slice();
        updatePeriodsAid[index].consumedPowerSecondPeriod = updatingArray.consumedPowerSecondPeriod;
        updatePeriodsAid[index].secondaryPowerRate = updatingArray.secondaryPowerRate;
        updatePeriodsAid[index].discount = updatingArray.discount;
        updatePeriodsAid[index].consumedPower = updatingArray.consumedPower;
        updatePeriodsAid[index].hiredPower = updatingArray.hiredPower;
        updatePeriodsAid[index].powerRate = updatingArray.powerRate;
        setItem({ ...item, periodPowerDetails: updatePeriodsAid });
    };

    const handleChangePeriodRegisterElectricBillEnergy = (updatingArray: IElectricBillEnergyMaterialTableRows, index: number) => {
        let updatePeriodsAid: ICreateEnergyDetail[] = item.periodEnergyDetails.slice();
        updatePeriodsAid[index].secondConsumedEnergy = updatingArray.secondConsumedEnergy;
        updatePeriodsAid[index].secondElectricityCost = updatingArray.secondElectricityCost;
        updatePeriodsAid[index].consumedEnergy = updatingArray.consumedEnergy;
        updatePeriodsAid[index].electricityCost = updatingArray.electricityCost;
        updatePeriodsAid[index].reactiveEnergy = updatingArray.reactiveEnergy;
        setItem({ ...item, periodEnergyDetails: updatePeriodsAid });
    };
    const FormatDate = (dateString: Date) => {
        const date = new Date(dateString.toString());
        return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();

    };
    const electricContractInfo = (electricContract: IElectricContract) => {
        return FormatDate(electricContract.initSupplyDate) + ' - '
            + FormatDate(electricContract.endSupplyDate);
    };

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    useEffect(() => {
        (async () => {
            if (props.complexId) {
                try {
                    const coinId = await countriesGateway.getCountrieCurrencyComplex(parseInt(props.complexId));
                    const coin = await currenciesGateway.findById(coinId);
                    setCurrency(coin.isoCode);

                } catch (e) {
                    snackbar.enqueueSnackbar(t('currencyErrorHandler.readErrorComplex'), { variant: "warning" });
                }
            } else if (props.establishmentId) {
                try {
                    const coinId = await countriesGateway.getCountrieCurrencyEstablishment(parseInt(props.establishmentId));
                    const coin = await currenciesGateway.findById(coinId);
                    setCurrency(coin.isoCode);
                } catch (e) {
                    snackbar.enqueueSnackbar(t('currencyErrorHandler.readErrorEstablishment'), { variant: "warning" });
                }
            }
            if (props.item) {
                setItem(props.item);
            } else {
                const initPeriodEnergyDetail: ICreateEnergyDetail[] = initialICreateEnergyDetail;

                const initPeriodPowerDetail: ICreatePowerDetail[] = initialICreatePowerDetail;
                try {
                    const contract = await electricContractGateway.findByBillDate(initialIRegisterElectricBillCreate.startingPeriodDate, electricSupplyId);
                    setItem(
                        {
                            ...initialIRegisterElectricBillCreate,
                            periodPowerDetails: initPeriodPowerDetail,
                            periodEnergyDetails: initPeriodEnergyDetail,
                            electricContractId: contract.id
                        }
                    );
                } catch (e) {
                    setItem(
                        {
                            ...initialIRegisterElectricBillCreate,
                            periodPowerDetails: initPeriodPowerDetail,
                            periodEnergyDetails: initPeriodEnergyDetail,
                        }
                    );
                }
            }

            var fillPowerStateAid: IElectricBillPowerMaterialTableRows[] = [];
            var fillEnergyStateAid: IElectricBillEnergyMaterialTableRows[] = [];
            for (let i: number = 0; i < 6; i++) {
                fillPowerStateAid[i] = {
                    periodNumber: i + 1,
                    consumedPower: item.periodPowerDetails[i].consumedPower,
                    consumedPowerSecondPeriod: item.periodPowerDetails[i].consumedPowerSecondPeriod,
                    secondaryPowerRate: item.periodPowerDetails[i].secondaryPowerRate,
                    discount: item.periodPowerDetails[i].discount,
                    hiredPower: item.periodPowerDetails[i].hiredPower,
                    powerRate: item.periodPowerDetails[i].powerRate,

                };

                fillEnergyStateAid[i] = {
                    periodNumber: i + 1,
                    consumedEnergy: item.periodEnergyDetails[i].consumedEnergy,
                    secondConsumedEnergy: item.periodEnergyDetails[i].secondConsumedEnergy,
                    secondElectricityCost: item.periodEnergyDetails[i].secondElectricityCost,
                    electricityCost: item.periodEnergyDetails[i].electricityCost,
                    reactiveEnergy: item.periodEnergyDetails[i].reactiveEnergy
                }
            };

            setPowerState(fillPowerStateAid);
            setEnergyState(fillEnergyStateAid);
        }
        )();
    }, [props.item, props.open]);

    const getPastMonth = (date: Date) => {
        let returnDate = new Date(date.getTime());
        returnDate.setDate(10);
        return new Date(returnDate.setMonth(returnDate.getMonth() - 1));
    };

    const getPastYear = (date: Date) => {
        return new Date(date.setFullYear(date.getFullYear() - 1));
    };

    useEffect(() => {
        (async () => {
            if (item.startingPeriodDate && item.endingPeriodDate && props.open
                && !dateHandler.checkLimits(item.startingPeriodDate, item.endingPeriodDate) && !firstIteration) {
                snackbar.enqueueSnackbar(t('messages.dateRangeError'), { variant: "error" });
                return;
            }
            if (props.open) {
                await checkBillOverlap();
            }
        })();
    }, [item.startingPeriodDate, item.endingPeriodDate]);

    useEffect(() => {
        (async () => {
            try {
                const contract = await electricContractGateway.findByBillDate(item.startingPeriodDate, electricSupplyId);
                setElectricContract(contract);
                setItem({ ...item, electricContractId: contract.id });
            } catch (e) {
                if (!firstIteration) {
                    const data = await electricSupplyGateway.findByIdElectricContract(parseInt(electricSupplyId));
                    if (data.length > 0) {
                        setFirstIteration(false);
                        setItem({ ...item, electricContractId: 0 });
                        snackbar.enqueueSnackbar(t('messages.contractNotFoundError', {
                            day: item.startingPeriodDate.getDate(),
                            month: item.startingPeriodDate.getMonth() + 1,
                            year: item.startingPeriodDate.getFullYear()
                        }), { variant: "error" });
                    } else {
                        snackbar.enqueueSnackbar(t('messages.nonExistentContracts'), { variant: "error" });
                    }
                }
            } finally {
                setFirstIteration(false);
            }
        })();
    }, [item.startingPeriodDate]);

    useEffect(() => {
        (async () => {
            const defaultPower: IPowerDetail[] = [];
            let pastMonthDate: Date = getPastMonth(new Date(item.issueDate.getTime()));
            let pastYearDate: Date = getPastYear(new Date(item.issueDate.getTime()));
            for (let i: number = 0; i < 6; i++) {
                defaultPower[i] = { consumedPower: 0, discount: 0, hiredPower: 0, registerElectricBillId: 0, id: 0, powerRate: 0 }
            }

            try {
                const powerDetailPastMonth =
                    await registerElectricBillGateway.findPastPeriodPowerDetailsMonth(pastMonthDate);
                setPowerPastMonth(powerDetailPastMonth);
            } catch (e) {
                setPowerPastMonth(defaultPower)
            }

            try {
                const powerDetailPastYear = await registerElectricBillGateway.findPastPeriodPowerDetailsYear(pastYearDate);
                setPowerPastYear(powerDetailPastYear);
            } catch (e) {
                setPowerPastYear(defaultPower)
            }

            const defaultEnergy: IEnergyDetail[] = [];
            for (let i: number = 0; i < 6; i++) {
                defaultEnergy[i] = { id: 0, registerElectricBillId: 0, electricityCost: 0, consumedEnergy: 0, reactiveEnergy: 0 }
            }

            try {
                const energyDetailPastMonth =
                    await registerElectricBillGateway.findPastPeriodEnergyDetailsMonth(pastMonthDate);
                setEnergyPastMonth(energyDetailPastMonth);
            } catch (e) {
                setEnergyPastMonth(defaultEnergy)
            }

            try {
                const energyDetailPastYear =
                    await registerElectricBillGateway.findPastPeriodEnergyDetailsYear(pastYearDate);
                setEnergyPastYear(energyDetailPastYear);
            } catch (e) {
                setEnergyPastYear(defaultEnergy)
            }
        })();
    }, [item.issueDate]);


    return (
        <Dialog open={props.open} onClose={() => { props.onClose() }} maxWidth={'xl'} fullWidth={true}>
            <DialogTitle>{!props.item ? t('registerElectricBillUpsertDialog.createTitle') : t('registerElectricBillUpsertDialog.updateTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{!props.item ? t('registerElectricBillUpsertDialog.createContent') : t('registerElectricBillUpsertDialog.updateContent')}</DialogContentText>

                <RegisterElectricBillFormUpsert
                    onCompensatedChange={handleCompensatedChange}
                    onMultiplePeriodsBillChange={handleMultiplePeriodsBillChange}
                    currency={currency}
                    electricContract={electricContract}
                    electricContractInfo={electricContractInfo}
                    energyState={energyState}
                    item={item}
                    onBonificationIncludedChange={handleBonificationIncludedChange}
                    onChange={handleChange}
                    onChangeDoNothing={handleChangeDoNothing}
                    onChangePeriodRegisterElectricBillEnergy={handleChangePeriodRegisterElectricBillEnergy}
                    onChangePeriodRegisterElectricBillPower={handleChangePeriodRegisterElectricBillPower}
                    onEndingPeriodDateSelector={handleEndingPeriodDateSelector}
                    onEstimatedChange={handleEstimatedChange}
                    onIssueDateSelector={handleIssueDateSelector}
                    onStartingPeriodDateSelector={handleStartingPeriodDateSelector}
                    powerState={powerState}
                    setEnergyState={setEnergyState}
                    setPowerState={setPowerState}
                    validateRequired={validateRequired}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onClose() }} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleAccept} color="primary">{t('common.accept')}</Button>
            </DialogActions>
        </Dialog >
    );
};

export default RegisterElectricBillUpsertDialog;