import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Fab,
  makeStyles,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  Select,
  MenuItem,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  InputAdornment,
  Icon,
  IconButton,
  Box,
} from "@material-ui/core";
import EyeOffIcon from "@material-ui/icons/VisibilityOff";
import EyeOnIcon from "@material-ui/icons/Visibility";
import { RouteComponentProps } from "react-router-dom";
import { useNavigator } from "../../contexts/navigator.context";
import SupplyBotsIcon from "@material-ui/icons/Autorenew";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  IBotRO,
  IBotPreconfigFieldRO,
  SaveBotPreconfigDto,
} from "../../gateways/bots.interfaces";
import useIoC from "../../contexts/ioc.context";
import { BotsGateway } from "../../gateways/bots.gateway";
import { useSnackbar } from "notistack";
import PreconfigAddFieldDialog from "./preconfig-add-field-dialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  fabAdd: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.drawer + 1,
  },
  fabDelete: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(10),
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  formControl: {
    marginBottom: "1rem",
    "& :last-child": {
      marginBottom: 0,
    },
  },
  configRoot: {
    padding: theme.spacing(3, 2),
    marginBottom: theme.spacing(1),
  },
}));

interface IRouteParams {
  id: string;
}

const PreconfigDetail: FC<RouteComponentProps<IRouteParams>> = ({
  match,
  history,
}) => {
  const [, navigatorDispatch] = useNavigator();
  const classes = useStyles();
  const snackbar = useSnackbar();

  const id = /^\d+$/g.test(match.params.id) ? parseInt(match.params.id) : -1;

  const botsGateway = useIoC(BotsGateway);

  const [bots, setBots] = useState<IBotRO[]>([]);

  const [botId, setBotId] = useState<number | "">("");
  const [fields, setFields] = useState<IBotPreconfigFieldRO[]>([]);
  const [alias, setAlias] = useState<string>("");
  const [enabled, setEnabled] = useState<boolean>(false);
  const [meterSupplierEnabled, setMeterSupplierEnabled] =
    useState<boolean>(false);

  const [addFieldDialogOpen, setAddFieldDialogOpen] = useState<boolean>(false);

  const handleChangeAlias = (e: React.ChangeEvent<HTMLInputElement>) =>
    setAlias(e.target.value);
  const handleChangeEnabled = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEnabled(e.target.checked);
  const handleChangeMeterSupplierEnabled = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setMeterSupplierEnabled(event.target.checked);

  const handleChangeBot = (e: React.ChangeEvent<{ value: unknown }>) => {
    const botId = e.target.value as number;
    setBotId(e.target.value as number);
    if (botId) {
      const bot = bots.find((x) => x.id === botId);
      if (bot) {
        setFields(
          bot.fields.map((f) => ({
            field: f.field,
            value: "",
            type: f.type,
          }))
        );
        return;
      }
    }
    setFields([]);
  };

  const handleSave = async () => {
    const preconfig: SaveBotPreconfigDto = {
      fields: fields,
      alias: alias,
      botId: botId as number,
      enabled,
      meterRentalPreconfig: meterSupplierEnabled,
    };

    try {
      const result = await botsGateway.updatePreconfig(id, preconfig);
      if (typeof result.id === 'undefined')
        throw new Error(result);
        
      history.push("/preconfig-bots/" + result.id);
      snackbar.enqueueSnackbar("El bot se ha guardado correctamente", {
        variant: "success",
      });
    } catch (e) {
      const er = e as any;
        const message =
          er.validation || er.message || JSON.stringify(er) || "Ha ocurrido un error no controlado";
        snackbar.enqueueSnackbar(message, {
          variant: "error",
        });
    }
  };

  useEffect(() => {
    navigatorDispatch({
      type: "set-header",
      header: {
        title: "Ficha del bot del suministro",
        icon: SupplyBotsIcon,
      },
    });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const preconfig = id === -1 ? null : await botsGateway.getPreconfig(id);
        if (!preconfig) {
          snackbar.enqueueSnackbar(
            "No se ha encontrado el bot del suministro",
            {
              variant: "error",
            }
          );
          history.push("/preconfig-bots/");
        } else {
          setAlias(preconfig.alias);
          setBotId(preconfig.bot.id);
          setFields(
            preconfig.bot.fields.map((x) => ({
              field: x.field,
              type: x.type,
              value:
                preconfig.fields.find((c) => c.field === x.field)?.value ?? "",
            }))
          );
          setEnabled(preconfig.enabled);
          setMeterSupplierEnabled(preconfig.meterRentalPreconfig);
        }
      } catch (e) {
        snackbar.enqueueSnackbar(
          "Ha ocurrido un error al recuperar el bot del siuministro",
          {
            variant: "error",
          }
        );
        history.push("/supply-bots/");
      }
    })();
  }, [match.params.id]);

  useEffect(() => {
    (async () => {
      const bots = await botsGateway.findAll();
      setBots(bots);
    })();
  }, [botsGateway]);

  const renderFieldConfig = (field: Partial<IBotPreconfigFieldRO>) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      field.value = e.target.value;
      setFields([...fields]);
    };

    const handleChangeRaw = (value: string) => {
      field.value = value;
      setFields([...fields]);
    };

    let fieldInput;
    let includedLabel = false;

    switch (field.type ?? "text") {
      case "password":
        fieldInput = (
          <InputPassword
            id={`config-${field.field}-input`}
            value={field.value ?? ""}
            onChange={handleChange}
          />
        );
        break;
      case "checkbox":
        includedLabel = true;
        fieldInput = (
          <FormControlLabel
            label={field.field}
            control={
              <Checkbox
                checked={field.value === "1"}
                indeterminate={field.value === ""}
                onChange={(_, checked) => handleChangeRaw(checked ? "1" : "0")}
              />
            }
          />
        );
        break;
      default:
        fieldInput = (
          <>
            <Input
              id={`config-${field.field}-input`}
              value={field.value}
              onChange={handleChange}
            />
            {field.field == 'cups' ?
            <FormHelperText id="code-helper-text">
              {`Esto sirve para forzar al bot a buscar facturas por cups (Casos en que en el buscador de la web no aparece). Si hay más de 1 cups, separar con coma, ejemplo: 1234567890, 0987654321`}
            </FormHelperText> : <></>}
          </>
          
        );
        break;
    }

    if (includedLabel) {
      return fieldInput;
    } else {
      return (
        <FormControl
          key={"field_" + field.field}
          className={classes.formControl}
        >
          <InputLabel htmlFor={`config-${field.field}-input`}>
            {field.field}
          </InputLabel>
          {fieldInput}
        </FormControl>
      );
    }
  };

  const handleDelete = async () => {
    if (window.confirm("¿Desea eliminar estas credenciales?")) {
      if (botId === "") return;
      await botsGateway.deletePreconfig(id);
      window.location.href = window.location.origin + "/preconfig-bots";
    } else return;
  };

  const handleCreateNewBotPreconfigField = async(item: IBotPreconfigFieldRO) => {
    console.log('Field Item to add: ', item);
    
    if (item.field.length === 0 || item.value.length === 0)
    {
      //error
      const message = "Algunos de los campos están vacíos, no se puede añadir...";
      snackbar.enqueueSnackbar(message, {
        variant: "error",
      });

    } else {
      try {
        const result = await botsGateway.updateField(id, item);
        history.push("/preconfig-bots/" + id);
        snackbar.enqueueSnackbar("Se ha añadido el campo correctamente", {
          variant: "success",
        });
      } catch (e) {
        const er = e as any;
        const message =
          er.validation || er.message || JSON.stringify(er) || "Ha ocurrido un error no controlado";
        snackbar.enqueueSnackbar(message, {
          variant: "error",
        });
      }
    }

  }

  return (
    <form className={classes.root}>
      <Box
        style={{
          position: "fixed",
          right: 16,
          bottom: 16,
          display: "flex",
          gap: 16,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fab
          color="secondary"
          aria-label="delete"
          onClick={handleDelete}
          size="medium"
        >
          <DeleteIcon />
        </Fab>

        <Fab color="primary" aria-label="add" onClick={handleSave}>
          <SaveIcon />
        </Fab>
      </Box>

      <div className={classes.content}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="code-input">Alias</InputLabel>
          <Input
            id="code-input"
            aria-describedby="code-helper-text"
            value={alias}
            onChange={handleChangeAlias}
          />
          <FormHelperText id="code-helper-text">
            Por ejemplo: BPTAL
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="bot-input">Bot</InputLabel>
          <Select
            value={botId}
            onChange={handleChangeBot}
            inputProps={{ name: "bot-input" }}
          >
            {bots.map((x) => (
              <MenuItem key={"bot_" + x.id} value={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText id="bot-helper-text">
            Bot encargado de capturar las facturas del suministro
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={
                <Checkbox checked={enabled} onChange={handleChangeEnabled} />
              }
              label="Activo"
            />
          </FormGroup>
          <FormHelperText id="code-helper-text">
            Si se desmarca, no se procesaran todos los suministros asociados a
            esta configuración
          </FormHelperText>
        </FormControl>

        <h2>Configuración de la integración</h2>
        {botId ? (
          <>
            {fields.map(renderFieldConfig)}
            {/* <IconButton
              color="primary"
              aria-label="add"
              onClick={() => setAddFieldDialogOpen(true)}
              style={{ paddingBottom: 0 }}
            >
            <AddCircleIcon />
          </IconButton> */}
          <PreconfigAddFieldDialog 
            open={addFieldDialogOpen}
            onClose={() => setAddFieldDialogOpen(false)}
            onCreate={handleCreateNewBotPreconfigField}
          />
          
          </>
        ) : (
          <Typography color="error">Selecciona un bot</Typography>
        )}
      </div>
    </form>
  );
};

const InputPassword: FC<{
  id: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}> = ({ id, value, onChange }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Input
      id={id}
      type={isVisible ? "text" : "password"}
      value={value}
      onChange={onChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton onClick={() => setIsVisible(!isVisible)}>
            {isVisible ? <EyeOffIcon /> : <EyeOnIcon />}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default PreconfigDetail;
