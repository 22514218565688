import { Reducer } from "react";
import { IRepositoryQueryFilterDto } from "../../gateways/repository.interfaces";

export interface IFilterReducerState {
    filters: IRepositoryQueryFilterDto[];
}

export type IFilterReducerAction =
    { type: 'add', payload: IRepositoryQueryFilterDto }
    | { type: 'remove', payload: number }
    | { type: 'reset', payload?: IRepositoryQueryFilterDto[] }
    | { type: 'forceUpdate' };

export const FiltersReducer: Reducer<IFilterReducerState, IFilterReducerAction> = (state, action) => {
    switch (action.type) {
        case 'add':
            return { ...state, filters: [...state.filters, action.payload] };
        case 'remove':
            return { ...state, filters: state.filters.filter((_item, index) => index !== action.payload) };
        case 'reset':
            return { ...state, filters: action.payload || [] };
        case 'forceUpdate':
            return { ...state };
    }
    return state;
}