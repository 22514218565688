import React, { FC } from "react";
import { IValidationRule } from "../../gateways/validation-rules.interfaces";
import { makeStyles, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import Step from "../../components/step";

const useStyles = makeStyles((theme) => ({
    fields: {
        marginBottom: theme.spacing(0.5)
    }
}));

interface ITextValidationRulesFormProps {
    nextStep: () => void;
    onChange: (name: string, value: any) => void;
    item: IValidationRule;
}

const TextValidationRulesForm: FC<ITextValidationRulesFormProps> = ({ nextStep, onChange, item }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    return (
        <Step
            title={t('textValidationRulesForm.title')}
            nextStep={nextStep}
        >
            < Grid container direction="column" >
                <Grid item>
                    <FormTextField
                        name='name'
                        label={t('common.name') as string}
                        type='text'
                        validator={validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={item.name}
                        onChange={onChange}
                        fullWidth
                        required
                        className={classes.fields}
                    />
                    <FormTextField
                        name='messageError'
                        label={t('common.messageError') as string}
                        type='text'
                        validator={validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={item.messageError}
                        onChange={onChange}
                        fullWidth
                        required
                        className={classes.fields}
                    />
                </Grid>
            </Grid >
        </Step>
    );
};
export default TextValidationRulesForm;