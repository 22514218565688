import React, { FC } from "react";
import { IValidationRule } from "../../gateways/validation-rules.interfaces";
import { makeStyles, Grid,  Chip, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import FormTextField from "../../components/form-text-field";
import Step from "../../components/step";

const useStyles = makeStyles((theme) => ({
    fields: {
        marginBottom: theme.spacing(0.5)
    },
    chip: {
        margin: theme.spacing(0.5)
    }
}));

interface IResultConditionalValidationRulesFormProps {
    item: IValidationRule;
    prevStep: () => void;
    endStep: () => void;
    onChange: (name: string, value: any) => void;
}


const ResultConditionalValidationRulesForm: FC<IResultConditionalValidationRulesFormProps> = ({ item, endStep, prevStep, onChange, }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };
    return (
        <Step
            title={t('resultConditionalValidationRulesForm.title')}
            endStep={endStep}
            prevStep={prevStep}
        >
            < Grid container direction="column">
                <Grid item>
                    <FormTextField
                        name='resultConditional'
                        label={t('common.resultConditional') as string}
                        type='text'
                        validator={validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={item.resultConditional}
                        onChange={onChange}
                        fullWidth
                        required
                        className={classes.fields}
                    />
                </Grid >
                <Grid item>
                    <Typography> {t('common.variables')} </Typography>

                    {
                        item.rules.map((value, index) => {
                            return (
                                <Chip
                                    key={index}
                                    label={'$' + value.name}
                                    className={classes.chip}
                                />
                            );
                        })
                    }
                </Grid>
            </Grid >

        </Step>
    );
};
export default ResultConditionalValidationRulesForm;