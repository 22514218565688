import React, { FC, Fragment } from "react";
import { Route, withRouter, RouteComponentProps } from "react-router";
import { useUser } from "../../contexts/user.context";
import ContactTypeList from "./contact-type-list";

const ContactTypesIndex: FC<RouteComponentProps> = ({match}) => {
    const [{ canViewMaintenanceMenu, subCanViewMaintenanceRestOfItems }] = useUser();
    return (
        <Fragment>
            {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? <Route path={`${match.path}`} exact component={ContactTypeList} /> : null}
        </Fragment>
    );
};

export default withRouter(ContactTypesIndex);