import { FC } from "react";
import { makeStyles, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton} from "@material-ui/core";
import React from "react";
import UpdateIcon from '@material-ui/icons/Create';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import DeleteIcon from '@material-ui/icons/Delete';
import {IRepositoryDefinitionRelations} from '../../gateways/repository-definition.interfaces'

interface IRepositoryDefinitionItemProps {
    item: IRepositoryDefinitionRelations;
    onClickUpdateButton: (item: IRepositoryDefinitionRelations) => void;
    onClickDeleteButton: (item: IRepositoryDefinitionRelations) => void;
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        cursor: 'pointer'
    },
    itemAvatarIcon: {
        backgroundColor: '#e0e0e0',
        borderRadius: '50%',
        padding: theme.spacing(1)
    }
}));

const RepositoryDefinitionItem: FC<IRepositoryDefinitionItemProps> = (props) => {
    const classes = useStyles();

    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar >
                <SettingsEthernetIcon color="action" fontSize="large" className={classes.itemAvatarIcon} />
            </ListItemAvatar>
            <ListItemText primary={props.item.name} />
            <ListItemSecondaryAction>
                <IconButton onClick={() => { props.onClickUpdateButton(props.item) }} aria-label="update"><UpdateIcon /></IconButton>
                <IconButton onClick={() => { props.onClickDeleteButton(props.item) }} aria-label="delete"><DeleteIcon /></IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
export default RepositoryDefinitionItem;