export interface IClient {
  id: number;
  fullname: string;
  address: string;
  nif: string;
  email?: string;
  client_email?: string;
  client_email_domain?: string;
  clientTypeId: number;
  cancelledDate?: Date | null;
}

export interface IUpdateClientDto {
  fullname: string;
  address: string;
  nif: string;
  email?: string;
  client_email?: string;
  client_email_domain?: string;
  clientTypeId: number;
}

export interface IClientFindAll {
  clientTypeId?: number | null;
  isMarketing?: boolean;
  showDiscarded?: boolean;
}

export const initialClientDto: IUpdateClientDto = {
  fullname: "",
  address: "",
  nif: "",
  email: "",
  client_email: "",
  client_email_domain: "",
  clientTypeId: 0,
};

export const initialIClientDto: IClient = {
  id: 0,
  fullname: "",
  address: "",
  nif: "",
  email: "",
  client_email: "",
  client_email_domain: "",
  clientTypeId: 0,
};

export interface ISuppliesByClientId {
  client_id: number;
  client_name: string;
  op_email: string | null;
  client_email: string | null;
  company_id: number;
  company_name: string;
  company_cif: string;
  company_client_email: string | null;
  establishment_client_email: string | null;
  supply_id: number;
  supply_type: string;
  supply_reference: string;
  establishment_id: number | null;
  complex_id: number | null;
  supply_client_email: string | null;
}