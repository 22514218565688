import React, { FC, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { RouteComponentProps } from 'react-router-dom';
import { makeStyles, Fab } from "@material-ui/core";
import { useNavigator } from '../../../contexts/navigator.context';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: theme.zIndex.drawer,
    },
}));

const ESMGQCreate: FC<RouteComponentProps> = ({ history }) => {
    const classes = useStyles();
    const [, navigatorDispatch] = useNavigator();
    const { t } = useTranslation();

    useEffect(() => {
        console.log('[ESMGQCreate] created')
        navigatorDispatch({
            type: 'set-header',
            header: {
                title: t("navigation.esm-google-query-create"),
                icon: SaveIcon
            }
        });
    }, [navigatorDispatch, t]);

    const handleSave = () => {
        console.log('[handleSave] Clicked')
    }
    
    return (
        <form className={classes.root}>
            <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleSave}>
                <SaveIcon />
            </Fab>
            <h3>Hola mundo</h3>
        </form>
    );
}

export default withRouter(ESMGQCreate);