import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import { IESM_GOOGLE_QUERY_RO } from "./esm-google-query.interfaces";

@inject('axios')
export class ESMGQGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    public async findAll(): Promise<IESM_GOOGLE_QUERY_RO[]> {
        try {
            const response = await this.axios.get('esmgq');
            return response.data["data"];
        } catch (e) {
            throw e;
        }
    }

    public async findCustom(id: number): Promise<IESM_GOOGLE_QUERY_RO[]> {
        try {
            const response = await this.axios.get('esmgq/get-custom-execution-indicators' + id);
            return response.data["data"];
        } catch (e) {
            throw e;
        }
    }

}