import React, { FC, useState, useCallback, Fragment } from "react";
import Header from "./app-bar";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Navigator } from "./navigator";
import RightNavigator from "./right-navigator";
import { makeStyles } from "@material-ui/core";
import { PageLoader } from "../components/page-loader";
import Clients from "../pages/clients";
import PageHeader from "./header";
import AppContainer from "./app.container";
import ContactTypes from "../pages/contact-types";
import SupplyBots from "../pages/supply-bots";
import PreconfigBots from "../pages/preconfig-bots";
import ServiceBots from "../pages/providers-bots";
import Sectors from "../pages/sectors/sector-list";
import VolumeUnits from "../pages/volume-units/volume-unit-list";
import Currencies from "../pages/currencies/currency-list";
import Countries from "../pages/countries/country-list";
import EstablishmentType from "../pages/establishment-type/establishment-type-list";
import Providers from "../pages/providers/providers-list";
import Dashboards from "../pages/dashboards";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import Marketing from "../pages/marketing";
import RepositoryDefinitionList from "../pages/repository-definition/repository-definition-list";
import ValidationContextRouter from "../pages/validation-context/validation-context-router";
import HiringDashboardList from "../pages/hiring-dashboard/hiring-dashboard-list";
import ListInfoBotF from "../pages/info-bot_f/info-bot_f-list";
import BotMissingDatesList from "../pages/bot-missing-dates/bot-missing-dates-list";
import GenerateSignaturePreview from "../pages/generate-signature/generate-signature-preview";
import PrometheusBotList from "../pages/prometheus";
import Jira from "../pages/jira";
import { useUser } from "../contexts/user.context";
import { DashboardPage, EmptyDashboardPage } from "../pages/dashboard";
import AddManualInvoice from "../pages/manual-invoices/add-manual-invoice";
import curvas from "../pages/curvas";
import { CoordinatesPage } from "../pages/coordinates";
import OnedrivePathDecoder from "../pages/onedrive-decoder/onedrive-decoder";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "calc(100% - 480px)",
    paddingBottom: theme.spacing(5),
  },
  toolbar: theme.mixins.toolbar as CSSProperties,
}));

const MainLayout: FC = () => {
  const [
    {
      canViewBotsMenu,
      canViewCurvesMenu,
      canViewHiringBoard,
      canViewClientsList,
      canViewProvidersList,
      canViewMaintenanceMenu,
      canViewESM_Menu,
      canViewManualInvoice,
      subCanViewBotMissingDate,
      subCanViewSignatureGenerator,
      subCanViewMaintenanceRestOfItems,
      subCanViewCoordenadas,
      subCanViewOneDriveConverter
    },
  ] = useUser();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const handleLoad = useCallback(() => setLoading(false), []);
  return (
    <div className={classes.root}>
      {loading ? (
        <PageLoader onLoad={handleLoad} />
      ) : (
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <AppContainer>
            <Header />
            <Navigator />
            <div className={classes.content}>
              <div className={classes.toolbar} />
              <PageHeader />
              <Switch>
                <Route
                  path="/"
                  exact
                  component={
                    canViewBotsMenu && canViewClientsList
                      ? DashboardPage
                      : EmptyDashboardPage
                  }
                />
                {canViewBotsMenu ? (
                  <Route path="/dashboards" component={Dashboards} />
                ) : null}
                {canViewBotsMenu ? (
                  <Route path="/supply-bots" component={SupplyBots} />
                ) : null}
                {canViewBotsMenu ? (
                  <Route path="/preconfig-bots" component={PreconfigBots} />
                ) : null}
                {canViewBotsMenu ? (
                  <Route path="/providers-bots" component={ServiceBots} />
                ) : null}
                {canViewBotsMenu ? (
                  <Route path="/jira" component={Jira} />
                ) : null}
                {canViewBotsMenu || canViewCurvesMenu ? (
                  <Route path="/curvas" component={curvas} />
                ) : null}
                {canViewHiringBoard ? (
                  <Route
                    path="/hiring-dashboard"
                    component={HiringDashboardList}
                  />
                ) : null}
                {canViewClientsList ? (
                  <Route path="/clients" component={Clients} />
                ) : null}
                {canViewProvidersList ? (
                  <Route path="/providers" component={Providers} />
                ) : null}
                {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? (
                  <Route path="/contact-types" component={ContactTypes} />
                ) : null}
                {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? (
                  <Route path="/sectors" component={Sectors} />
                ) : null}
                {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? (
                  <Route path="/volume-units" component={VolumeUnits} />
                ) : null}
                {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? (
                  <Route path="/currencies" component={Currencies} />
                ) : null}
                {canViewMaintenanceMenu && (subCanViewMaintenanceRestOfItems || subCanViewCoordenadas) ? (
                  <Route path="/coordinates" component={CoordinatesPage} />
                ) : null}
                {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? (
                  <Route path="/operate" component={ListInfoBotF} />
                ) : null}
                {canViewMaintenanceMenu && subCanViewBotMissingDate ? (
                  <Route
                    path="/bot-missing-dates"
                    component={BotMissingDatesList}
                  />
                ) : null}
                {canViewMaintenanceMenu && subCanViewSignatureGenerator ? (
                  <Route
                    path="/generate-signature"
                    component={GenerateSignaturePreview}
                  />
                ) : null}
                {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? (
                  <Route path="/prometheus" component={PrometheusBotList} />
                ) : null}

                {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? (
                  <Route path="/countries" component={Countries} />
                ) : null}
                {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? (
                  <Route
                    path="/establishment-type"
                    component={EstablishmentType}
                  />
                ) : null}
                {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? (
                  <Route
                    path="/repository-definition"
                    component={RepositoryDefinitionList}
                  />
                ) : null}
                {canViewMaintenanceMenu && subCanViewMaintenanceRestOfItems ? (
                  <Route
                    path="/validations"
                    component={ValidationContextRouter}
                  />
                ) : null}
                {canViewESM_Menu ? (
                  <Route path="/marketing" component={Marketing} />
                ) : null}
                {canViewManualInvoice ? (
                  <Route
                    path="/new-manual-invoice"
                    component={AddManualInvoice}
                  />
                ) : null}
                {subCanViewOneDriveConverter ? (
                  <Route
                    path="/onedrive-path"
                    component={OnedrivePathDecoder}
                  />
                ) : null}
                <Redirect to="/" />
              </Switch>
            </div>
            <RightNavigator />
          </AppContainer>
        </BrowserRouter>
      )}
    </div>
  );
};
export default MainLayout;
