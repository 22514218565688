import React, { FC, useMemo, useState, useEffect } from 'react';
import { FilterOperator } from '../../gateways/repository.interfaces';
import { Grid, IconButton, FormControl, makeStyles, Link } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { IValidationCriteria } from '../../gateways/validation-criteria.interfaces';
import SelectorField from '../../components/selector-field';
import { useParams } from 'react-router-dom';
import useIoC from '../../contexts/ioc.context';
import { RepositoryDefinitionPropertyGateway } from '../../gateways/repository-definition-property.gateway';
import { IRepositoryDefinitionProperty } from '../../gateways/repository-definition.interfaces';
import { ICriteriaOperation } from '../../gateways/criteria-operation.interfaces';
import FormTextField from '../../components/form-text-field';
import { OperatorGateway } from '../../gateways/operators.gateway';
import { IOperator } from '../../gateways/operators.interface';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    item: {
        padding: theme.spacing(1)
    },
    operator: {
        padding: theme.spacing(0, 0, 0, 1)
    }
}));

interface IValidationCriteriaItemDialogParams {
    validationContextId: string;
}

interface IValidationCriteriaItemDialog {
    validation: IValidationCriteria;
    index: number;
    onDeleteClick: (item: number) => void;
    onUpdate: (validationCriteria: IValidationCriteria, index: number) => void;
}

const ValidationCriteriaItemDialog: FC<IValidationCriteriaItemDialog> = ({ validation, index, onDeleteClick, onUpdate }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { validationContextId } = useParams<IValidationCriteriaItemDialogParams>();

    const [propertyId, setPropertyId] = useState(0);
    const [repositoryDefinitionProperties, setRepositoryDefinitionProperties] = useState<IRepositoryDefinitionProperty[]>([]);
    const [operators, setOperators] = useState<IOperator[]>([]);

    const repositoryDefinitionPropertyGateway = useIoC(RepositoryDefinitionPropertyGateway);
    const operatorGateway = useIoC(OperatorGateway);

    useEffect(() => {
        (async () => {
            const properties = await repositoryDefinitionPropertyGateway.findByValidationContextId(validationContextId);
            setRepositoryDefinitionProperties(properties);

            const operators = await operatorGateway.findAll();
            setOperators(operators);

            if (validation.nameProperty) {
                const property = properties.find((property) => property.name === validation.nameProperty);
                property ? setPropertyId(property.id) : setPropertyId(0);
            }
        })();

    }, []);

 

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    const handleChangeProperty = (name: string, id: number) => {
        setPropertyId(id);
        const property = repositoryDefinitionProperties.find(property => { return property.id === id });
        if (property) {
            validation.nameProperty = property.name;
        }
        onUpdate(validation, index);
    }

    const handleAddFilterOr = () => {
        const criteriaOperation: ICriteriaOperation = {
            id: 0,
            operatorId: 0,
            value: ''
        };

        validation.criteriaOperations.push(criteriaOperation);
        onUpdate(validation, index);
    };

    const handleRemoveFilterOr = (orIndex: number) => {
        validation.criteriaOperations.splice(orIndex, 1);
        onUpdate(validation, index);
    };

    const handleChange = (or: ICriteriaOperation, orIndex: number) => {
        return (name: string, value: any) => {
            or = { ...or, [name]: value };

            validation.criteriaOperations[orIndex] = or;
            onUpdate(validation, index);
        };
    };

    return (
        <Grid container direction={"row"} className={classes.item} key={'filter_' + index} >
            <Grid container xs spacing={1} alignItems="flex-start">
                <Grid item>
                    <IconButton aria-label="delete" size="small" color="secondary" onClick={() => { onDeleteClick(index) }}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
                <Grid item xs>
                    <FormControl fullWidth>
                        <SelectorField
                            name='propertyId'
                            emptyValue={false}
                            fullWidth
                            inputLabel={t('common.property')}
                            helperText={t('messages.requiredField') as string}
                            required
                            onChange={handleChangeProperty}
                            value={propertyId}
                            validator={validateRequired}
                            values={repositoryDefinitionProperties.map(property => {
                                return { id: property.id, value: property.name }
                            })}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container xs={6} className={classes.operator} direction={"column"} spacing={1} >
                <Grid item >
                    {validation.criteriaOperations.map((or, orIndex) => (
                        <Grid container spacing={1} key={'operator_'+or} >
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <SelectorField
                                        name='operatorId'
                                        emptyValue={false}
                                        fullWidth
                                        inputLabel={t('common.operatorShort')}
                                        helperText={t('messages.requiredField') as string}
                                        required
                                        validator={validateRequired}
                                        value={or.operatorId}
                                        onChange={handleChange(or, orIndex)}
                                        values={operators.map((operator, index) => {
                                            return { id: operator.id, value:  operator.name}
                                        })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item xs>
                                        <FormControl fullWidth>
                                            <FormTextField
                                                name={'value'}
                                                margin="none"
                                                label="Valor"
                                                type="text"
                                                value={or.value}
                                                onChange={handleChange(or, orIndex)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            aria-label="delete"
                                            size="small"
                                            color="secondary"
                                            disabled={validation.criteriaOperations.length <= 1}
                                            onClick={() => handleRemoveFilterOr(orIndex)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid container spacing={1} alignItems="center" justify="center" >
                        <Grid item >
                            <Link onClick={handleAddFilterOr}>{t('validationCriteriaItemDialog.addOperator')}</Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
};

export default ValidationCriteriaItemDialog;