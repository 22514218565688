import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigator } from "../../contexts/navigator.context";
import {
  Grid,
  makeStyles,
  InputAdornment,
  TextField,
  Button,
  Box,
  Tooltip,
} from "@material-ui/core";
import FilterDramaIcon from "@material-ui/icons/FilterDrama";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginTop: theme.spacing(4),
  },
}));

const OnedrivePathDecoder: FC = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const [, navigatorDispatch] = useNavigator();

  const [path, setPath] = useState<string>("");
  const [decodedPath, setDecodedPath] = useState<string>("");

  useEffect(() => {
    navigatorDispatch({
      type: "set-header",
      header: {
        title: t("common.onedrivePath"),
        icon: FilterDramaIcon,
      },
    });
  }, []);

  const handleOnChange = (e: any) => {
    const { value } = e.target;
    const decoded = decodeURI(value);
    const formatted = decoded.split("?")[0].replaceAll("/", "\\");
    setDecodedPath(formatted);
    setPath(value);
  };

  const handleCopyClipboard = async () => {
    if (!!decodedPath) {
      await navigator.clipboard.writeText(decodedPath);
      snackbar.enqueueSnackbar("Se ha copiado al portapapeles", {
        variant: "success",
      });
    }
  };

  return (
    <Box className={classes.rootContainer}>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <TextField
            autoFocus
            fullWidth
            label={"Ruta codificada"}
            name="Ruta codificada"
            required
            type="text"
            value={path}
            onChange={handleOnChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={"Ruta formateada"}
            name="Ruta formateada"
            type="text"
            value={decodedPath}
            onChange={() => {}}
            multiline
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Copiar al portapapeles" placement="top">
                    <Button
                      color="primary"
                      startIcon={<FileCopyIcon />}
                      component="label"
                      onClick={handleCopyClipboard}
                    />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OnedrivePathDecoder;
