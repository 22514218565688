import { inject } from "aurelia-dependency-injection";
import { AxiosInstance } from "axios";
import querystring from 'querystring';
import { IHiringDashboard } from "./hiring-dashboard.interfaces";

const url = 'quick-import';
@inject('axios')
export class HiringDashboardGateway {
    constructor(
        private axios: AxiosInstance
    ) { }

    async findAll(): Promise<IHiringDashboard[]> {
        const response = await this.axios.get(url);
        return response.data;
    }

    async create(hirngDashboard: IHiringDashboard): Promise<IHiringDashboard> {
        const response = await this.axios.post(url, hirngDashboard);
        return response.data;
    }
}