import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from "@material-ui/core";
import FormTextField from "../../components/form-text-field";
import ICompany, { initialICompany } from "../../gateways/company.interface";
import { CompanyGateway } from "../../gateways/company.gateway";
import { SectorGateway } from "../../gateways/sector.gateway";
import ICountry from "../../gateways/country.interfaces";
import ISector from "../../gateways/sector.interfaces";
import CountryGateway from "../../gateways/country.gateway";
import SelectorField from "../../components/selector-field";
import SectorCreateDialog from "../sectors/sector-create-dialog";
import CountryUpsertDialog from "../countries/country-upsert-dialog";
import CompanyForm from "./company-form";

interface ICompanyAddDialogProps {
  open: boolean;
  onClose: () => void;
  onItemCreated: (item: ICompany) => void;
  onLoading: (value: boolean) => void;
  clientId: number;
}

const CompanyAddDialog: FC<ICompanyAddDialogProps> = (props) => {
  const { t } = useTranslation();
  const [item, setItem] = useState<ICompany>({
    ...initialICompany,
    clientId: props.clientId,
  });
  
  const [ loading, setLoading] = useState(false);
  const [sectors, setSectors] = useState<ISector[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const snackbar = useSnackbar();
  const companyGateway = useIoC(CompanyGateway);
  const sectorGateway = useIoC(SectorGateway);
  const countryGateway = useIoC(CountryGateway);
  const [createSector, setCreateSector] = useState(false);
  const [createCountry, setCreateCountry] = useState(false);

  const handleClickAddSectorButton = () => {
    setCreateSector(true);
  };

  const handleClickAddCountryButton = () => {
    setCreateCountry(true);
  };
  const handleCloseAddSectorButton = () => {
    setCreateSector(false);
  };

  const handleCloseAddCountryButton = () => {
    setCreateCountry(false);
  };
  const handleCreateSector = (item: ISector) => {
    setSectors([...sectors, item]);
  };
  const handleCreateCountry = (item: ICountry, isNew: boolean) => {
    setCountries([...countries, item]);
  };

  const createCurrencyAsync = async (item: ICompany) => {
    try {
      setLoading(true);
      const company = await companyGateway.create(item);
      snackbar.enqueueSnackbar(
        t("companyAddDialog.createSucceded", {
          id: company.id,
          name: company.name,
        }),
        { variant: "success" }
      );
      props.onItemCreated(company);
    } catch (e) {
      const er = (e as any);
      if (er.response.data.key === "creatingEntityError") {
        const key = "companiesErrorHandler." + er.response.data.key;
        const message = "validation" in er ? er.validation : t(key);
        snackbar.enqueueSnackbar(message, { variant: "error" });
      } else {
        const message =
          "validation" in er ? er.validation : t("messages.defaultError");
        snackbar.enqueueSnackbar(message, { variant: "error" });
      }
    } finally {
      setLoading(false);
      props.onClose();
    }
  };

  const handleAccept = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!item.name || !item.countryId || !item.sectorId) {
      event.preventDefault();
      snackbar.enqueueSnackbar(t("messages.requiredFields"), {
        variant: "error",
      });
      return;
    }

    createCurrencyAsync(item);
  };

  const handleChange = (name: string, value: any) => {
    setItem({ ...item, [name]: value });
  };

  const validateRequired = (value: any) => {
    return value !== undefined && value !== null && value !== "" && value !== 0;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const data = await sectorGateway.findAll();
      setSectors(data);
      setLoading(false);
    })();
  }, [sectorGateway]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const data = await countryGateway.findAll();
      setCountries(data);
      setLoading(false);
    })();
  }, [countryGateway]);

  return (
    <Box>
      <Dialog
        open={props.open && !loading}
        onClose={() => {
          props.onClose();
        }}
      >
        <DialogTitle>{t("companyAddDialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("companyAddDialog.content")}</DialogContentText>
          <CompanyForm
            item={item}
            onChange={handleChange}
            validateRequired={validateRequired}
            countries={countries}
            sectors={sectors}
            handleClickAddButtonCountry={handleClickAddCountryButton}
            handleClickAddButtonSector={handleClickAddSectorButton}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.onClose();
            }}
            color="primary"
          >
            {t("common.cancel")}
          </Button>
          <Button onClick={handleAccept} color="primary">
            {t("common.accept")}
          </Button>
        </DialogActions>
      </Dialog>
      <SectorCreateDialog
        open={createSector}
        onItemCreate={handleCreateSector}
        onClose={handleCloseAddSectorButton}
      />
      <CountryUpsertDialog
        open={createCountry}
        onItemUpsert={handleCreateCountry}
        onClose={handleCloseAddCountryButton}
        item={undefined}
      />
    </Box>
  );
};

export default CompanyAddDialog;
