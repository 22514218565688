import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IUpdateClientDto, IClient } from "../../gateways/clients.interfaces";
import ClientPeekContactsSupplyForm from "./client-peek-form-contacts";
import ClientPeekCompanyFormProps from "./client-peek-form-companies";
import ClientPeekComplexFormProps from "./client-peek-form-complex";
import ClientPeekEstablishmentForm from "./client-peek-form-establishments";
import { ContactEntityType } from "../contacts/contact-list";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { TreeView, TreeItem } from '@material-ui/lab';
import ClientPeekSuppliesForm from "./client-peek-form-supplies";

interface IClientPeekFormProps {
    clientId: string; 
}

const ClientPeekForm: FC<IClientPeekFormProps> = (props) => {
    const { t } = useTranslation();

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            multiSelect
        >
            <ClientPeekContactsSupplyForm
                clientId={props.clientId}
                entity={ContactEntityType.Client}
            />
            <ClientPeekCompanyFormProps
                clientId={props.clientId}
            />
            <ClientPeekEstablishmentForm
                clientId={props.clientId} />
            <ClientPeekSuppliesForm clientId={props.clientId}/>
        </TreeView>
    );
};

export default ClientPeekForm;