import React, { FC, useState, useEffect } from "react";
import ICountry from "../../gateways/country.interfaces";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import useIoC from "../../contexts/ioc.context";
import CountryGateway from "../../gateways/country.gateway";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, makeStyles, createStyles, Theme, Chip, Box } from "@material-ui/core";
import FormTextField from "../../components/form-text-field";
import SelectorField from "../../components/selector-field";
import CurrencyGateway from "../../gateways/currency.gateway";
import ICurrency from "../../gateways/currency.interface";
import IRegion from "../../gateways/region.interface";
import { IVolumeUnit } from "../../gateways/volume-unit.interface";
import { VolumeUnitGateway } from "../../gateways/volume-units.gateway";
import CurrencyUpsertDialog from "../currencies/currency-upsert-dialog";
import VolumeUnitAddDialog from "../volume-units/volume-unit-add-dialog";
interface ICountryUpsertDialogProps {
    open: boolean;
    item: ICountry | undefined;
    onItemUpsert: (item: ICountry, isNew: boolean) => void;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5)
        },
        button: {
            margin: theme.spacing(2.5),


        },
        textField: {
            margin: theme.spacing(0.5)
        }
    })
);

const initialItem: ICountry = {
    id: 0,
    name: '',
    currencyId: 0,
    volumeUnitId: 0
};

const initialRegion: IRegion = {
    id: 0,
    name: ''
};

const CountryUpsertDialog: FC<ICountryUpsertDialogProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [item, setItem] = useState<ICountry>(initialItem);
    const [currencies, setCurrencies] = useState<ICurrency[]>([]);
    const [volumeUnits, setVolumeUnits] = useState<IVolumeUnit[]>([]);
    const [region, setRegion] = useState<IRegion>(initialRegion);
    const [regions, setRegions] = useState<IRegion[]>([]);
    const snackbar = useSnackbar();
    const countryGateway = useIoC(CountryGateway);
    const currencyGateway = useIoC(CurrencyGateway);
    const volumeUnitGateway = useIoC(VolumeUnitGateway);

    const [openVolumeUnitCreateDialog, setOpenVolumeUnitCreateDialog] = useState(false);
    const [openCurrencyCreateDialog, setOpenCurrencyCreateDialog] = useState(false);

    const handleOpenVolumeUnitCreateDialog = () => {
        setOpenVolumeUnitCreateDialog(true);
    };

    const handleOpenCurrencyCreateDialog = () => {
        setOpenCurrencyCreateDialog(true);
    };

    const handleCloseVolumeUnitCreateDialog = () => {
        setOpenVolumeUnitCreateDialog(false);
    }

    const handleCloseCurrencyCreateDialog = () => {
        setOpenCurrencyCreateDialog(false);
    };

    const handleCreateVolumeUnit = (item: IVolumeUnit) => {
        setVolumeUnits([...volumeUnits, item]);
    };

    const handleCreateCurrency = (item: ICurrency, isNew: boolean) => {
        setCurrencies([...currencies, item]);
    };

    const createCountry = async (item: ICountry) => {
        const country = await countryGateway.create(item);
        if (regions) {
            await countryGateway.createRegions(country.id, regions);
        }
        snackbar.enqueueSnackbar(t('countryUpsertDialog.createSucceded', { id: country.id, name: country.name }), { variant: "success" });
        props.onItemUpsert(country, true);
    };

    const updateCountry = async (item: ICountry) => {
        const country = await countryGateway.update(item.id, item);
        if (regions) {
            await countryGateway.updateRegions(country.id, regions);
        }
        snackbar.enqueueSnackbar(t('countryUpsertDialog.updateSucceded', { id: country.id, name: country.name }), { variant: "success" });
        props.onItemUpsert(country, false);
    };

    const upsertCountry = async (item: ICountry) => {
        try {
            if (!props.item) {
                await createCountry(item);
            } else {
                await updateCountry(item);
            }
        } catch (e) {
            const er = e as any;
            const key = 'countryErrorHandler.' + er.response.data.key;
            if (er.response.data.key === 'creatingEntityError') {
                const message = "validation" in er ? er.validation : t(key);
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else if (er.response.data.key === 'updatingError') {
                const message = "validation" in er ? er.validation : t(key, { id: er.response.data.id });
                snackbar.enqueueSnackbar(message, { variant: "error" });
            } else {
                const message = "validation" in er ? er.validation : t('messages.defaultError');
                snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose();
        }
    };

    const handleAccept = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!item.name) {
            event.preventDefault();
            snackbar.enqueueSnackbar(t('messages.requiredFields'), { variant: "error" });
            return;
        }

        upsertCountry(item);
    };

    const handleClose = () => {

        props.onClose();
    };

    const handleChange = (name: string, value: any) => {
        setItem({ ...item, [name]: value });
    };

    const handleChangeRegion = (event: any) => {
        const name = event.target.value;
        setRegion({ ...region, name });
    };

    const handleClickAddRegion = () => {
        if (region.name) {
            setRegions([...regions, region]);
            setRegion(initialRegion);
        }
    };

    const handleDeleteChipRegion = (index: number) => {
        const copy = regions.filter((v, i) => i !== index);
        setRegions(copy);
    };

    const validateRequired = (value: any) => {
        return value !== undefined && value !== null && value !== '' && value !== 0;
    };

    useEffect(() => {
        if (props.item) {
            setItem(props.item);
        } else {
            setItem(initialItem);
        }
    }, [props.item, props.open]);

    useEffect(() => {
        (async () => {
            const data = await currencyGateway.findAll();
            setCurrencies(data);
        })();
    }, [currencyGateway]);

    useEffect(() => {
        (async () => {
            const data = await volumeUnitGateway.findAll();
            setVolumeUnits(data);
        })();
    }, [volumeUnitGateway]);

    useEffect(() => {
        (async () => {
            if (props.item) {
                const regions = await countryGateway.findAllRegions(props.item.id);
                setRegions(regions);
            } else {
                setRegions([]);
                setRegion(initialRegion);
            }
        })();
    }, [props.item, props.open]);

    return (
        <Box>
            <Dialog open={props.open} onClose={() => { props.onClose() }}>
                <DialogTitle>{!props.item ? t('countryUpsertDialog.createTitle') : t('countryUpsertDialog.updateTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{!props.item ? t('countryUpsertDialog.createContent') : t('countryUpsertDialog.updateContent')}</DialogContentText>
                    <FormTextField
                        name='name'
                        label={t('common.name')}
                        type='text'
                        validator={validateRequired}
                        errorText={t('messages.requiredField') as string}
                        value={item.name}
                        onChange={handleChange}
                        autoFocus
                        fullWidth
                        required />
                    <SelectorField
                        name='currencyId'
                        emptyValue={false}
                        fullWidth
                        inputLabel={t('common.currency')}
                        helperText={t('messages.requiredField') as string}
                        required
                        onChange={handleChange}
                        value={item.currencyId}
                        validator={validateRequired}
                        values={currencies.map(value => {
                            return { id: value.id, value: value.name };
                        })}
                        handleClickAddButon={handleOpenCurrencyCreateDialog}
                />
                <SelectorField
                        name='volumeUnitId'
                        emptyValue={false}
                        fullWidth
                        inputLabel={t('common.volumeUnit')}
                        helperText={t('messages.requiredField') as string}
                        required
                        onChange={handleChange}
                        value={item.volumeUnitId}
                        validator={validateRequired}
                        values={volumeUnits.map(value => {
                            return { id: value.id, value: value.name };
                        })}
                        handleClickAddButon={handleOpenVolumeUnitCreateDialog}
                    />
                    <div>
                        <TextField
                            className={classes.textField}
                            name='region'
                            label={t('common.regions')}
                            value={region.name}
                            onChange={handleChangeRegion}
                        />
                        <Button
                            className={classes.button}
                            variant="contained"
                            color="primary"
                            onClick={handleClickAddRegion}
                            size="small"
                        >
                            {t('common.add')}
                        </Button>
                    </div>
                    <div>
                        {regions.map((value, index) => {
                            return (
                                <Chip
                                    key={index}
                                    label={value.name}
                                    onDelete={() => { handleDeleteChipRegion(index) }}
                                    className={classes.chip}

                                />
                            );
                        })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">{t('common.cancel')}</Button>
                    <Button onClick={handleAccept} color="primary">{t('common.accept')}</Button>
                </DialogActions>
            </Dialog>
            <CurrencyUpsertDialog
            item={undefined}
            onClose={handleCloseCurrencyCreateDialog}
            onItemUpsert={handleCreateCurrency}
            open={openCurrencyCreateDialog}
            />
            <VolumeUnitAddDialog
            handleClose={handleCloseVolumeUnitCreateDialog}
            handleCreated={handleCreateVolumeUnit}
            open={openVolumeUnitCreateDialog}
            />
        </Box>
    );
};

export default CountryUpsertDialog;
