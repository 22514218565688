import React, { FC, useContext, useState, useEffect } from 'react';
import { makeStyles, Theme, Dialog, DialogTitle, DialogContent, RadioGroup, FormControlLabel, DialogActions, Button, Radio } from '@material-ui/core';
import { StateContext, ReducerContext } from './context';
import { RepositoryGateway } from '../../gateways/repository.gateway';
import useIoC from '../../contexts/ioc.context';
import { IRepositoryDefinitionGroup } from '../../gateways/repository.interfaces';

const useStyles = makeStyles((theme: Theme) => ({
}));

export const SelectGroupDialog: FC = () => {
    const classes = useStyles();

    const state = useContext(StateContext);
    const dispatch = useContext(ReducerContext);
    const repositoryGateway = useIoC(RepositoryGateway);

    const radioGroupRef = React.useRef<HTMLElement>(null);
    const [groupId, setGroupId] = React.useState<string>('');
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState<IRepositoryDefinitionGroup[]>([]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            setGroups(await repositoryGateway.getDefinitionGroups(state.repositoryId));
            setLoading(false);
        })();
    }, [state.repositoryId]);

    const handleEntering = () => {
        if (radioGroupRef.current !== null) {
            radioGroupRef.current.focus();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroupId((event.target as HTMLInputElement).value);
    };

    const handleClose = () => {
        dispatch({ type: 'hideSelectGroupDialog' });
    }

    const handleSelectGroup = () => {
        dispatch({ type: 'applyGroup', payload: groupId.length > 0 ? groups.find(x => x.id.toString() === groupId) || null : null });
        handleClose();
    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            onEntering={handleEntering}
            open={state.openSelectGroupDialog}
        >
            <DialogTitle>Selecciona un grupo de la lista</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    name="group"
                    value={groupId}
                    onChange={handleChange}
                >
                    <FormControlLabel value={''} control={<Radio />} label="Ningun grupo" />
                    {groups.map(group => (
                        <FormControlLabel value={group.id.toString()} key={'group_' + group.id} control={<Radio />} label={group.name} />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="default">
                    Cerrar
                </Button>
                <Button onClick={handleSelectGroup} color="primary">
                    Aplicar grupo
                </Button>
            </DialogActions>
        </Dialog>
    )
}
