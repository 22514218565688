import React, { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ClientPeekContactsForm from "./client-peek-form-contacts";
import ClientPeekEstablishmentForm from "./client-peek-form-establishments";
import ClientPeekComplexForm from "./client-peek-form-complex"
import { ContactEntityType } from "../contacts/contact-list";
import useIoC from "../../contexts/ioc.context";
import { CompanyGateway } from "../../gateways/company.gateway";
import ICompany from "../../gateways/company.interface";
import { makeStyles, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { TreeItem } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    item: {
        padding: 0,
        margin: 0,
        fontWeight: 'normal'
    }
}));

interface IClientPeekClientFormProps {
    clientId: string;
}

const ClientPeekCompanyFormProps: FC<IClientPeekClientFormProps> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const companyGateway = useIoC(CompanyGateway);
    const [data, setData] = useState<ICompany[]>([]);
    const history = useHistory();
    let nodeTreeId: string = `company${props.clientId}`;

    useEffect(() => {
        (async () => {
            (async () => {
                const clientId = parseInt(props.clientId, 10);
                const data = await companyGateway.findAll({ clientId });
                setData(data);
            })();
        })();
    }, [companyGateway]);

    const handleStopPropagation = useCallback( (event: React.MouseEvent) => {
        event.stopPropagation();               
    }, [])

    return (
        <Box>
            {
                data.length > 0 &&
                <TreeItem nodeId={nodeTreeId} label={t('rightNavigation.companies')}>
                    {
                        data.map((item) => {
                            return (
                                <TreeItem nodeId={nodeTreeId + item.id} label={<a onClick={handleStopPropagation} href={`${props.clientId}/companies/${item.id}`} target="_blank">{item.name}</a>}>
                                    <ClientPeekContactsForm
                                        clientId={item.id.toString()}
                                        entity={ContactEntityType.Company} />

                                    <ClientPeekComplexForm
                                        clientId={props.clientId}
                                        companyId={item.id.toString()}
                                    />
                                    <ClientPeekEstablishmentForm
                                        clientId={props.clientId}
                                        companyId={item.id.toString()}
                                    />
                                </TreeItem>
                            )
                        })
                    }
                </TreeItem>
            }
        </Box>
    );
};

export default ClientPeekCompanyFormProps;