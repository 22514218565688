import React, { FC, useMemo, useCallback, useEffect } from "react";
import { makeStyles, Hidden, Drawer } from "@material-ui/core";
import { useNavigator } from '../contexts/navigator.context';
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles((theme) => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: 240,
            flexShrink: 0,
        },
    },
    toolbar: theme.mixins.toolbar as CSSProperties,
    drawerPaper: {
        width: 240,
    },
}));

const RightNavigator: FC = () => {
    const [{ rightNavComponent, rightNavOpen }, navDispatch] = useNavigator();

    const classes = useStyles();

    const handleClose = useCallback(() => {
        navDispatch({ type: 'close-right-nav' });
    }, [navDispatch]);

    return rightNavComponent ? (
        <nav className={classes.drawer}>
            <Hidden smUp implementation="css">
                <Drawer
                    open={rightNavOpen}
                    onClose={handleClose}
                    variant="temporary"
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    {rightNavComponent}
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    anchor="right"
                    open
                >
                    <div className={classes.toolbar} />
                    {rightNavComponent}
                </Drawer>
            </Hidden>
        </nav>
    ) : null;
}

export default RightNavigator;