import React, { FC, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import useIoC from "../../contexts/ioc.context";
import { Box } from "@material-ui/core";
import { TreeItem } from "@material-ui/lab";
import { IWaterSupplyInterface } from "../../gateways/water-supply.interfaces";
import { WaterSupplyGateway } from "../../gateways/water-supply.gateway";

interface IClientPeekWaterSupplyFormProps {
    establishmentId?: string;
    complexId?: string;
    currentUrl: string
}

const ClientPeekWaterSupplyForm: FC<IClientPeekWaterSupplyFormProps> = (props) => {
    const { t } = useTranslation();
    const [data, setData] = useState<IWaterSupplyInterface[]>([]);
    const waterSupplyGateway = useIoC(WaterSupplyGateway);

    const computeCurrentUrl = (item: IWaterSupplyInterface): string => {
        return (`${props.currentUrl}/waterSupply/${item.id}`);
    }

    useEffect(() => {
        (async () => {

            if (props.establishmentId) {
                const establishmentId = props.establishmentId;
                const data = await waterSupplyGateway.findAll({ establishmentId });
                setData(data);

            }
            else if (props.complexId) {
                const complexId = props.complexId;
                const data = await waterSupplyGateway.findAll({ complexId });
                setData(data);
            }
        })();
    }, [waterSupplyGateway]);

    const handleStopPropagation = useCallback((event: React.MouseEvent) => {
        event.stopPropagation();
    }, [])

    return (
        <Box>
            {
                data.length > 0 &&
                <TreeItem nodeId={props.currentUrl + 'waterSupply'} label={t('rightNavigation.waterSupply')}>
                    {
                        data.map((item) => {
                            return (
                                <TreeItem nodeId={computeCurrentUrl(item)} 
                                          label={<a onClick={handleStopPropagation} 
                                          href={computeCurrentUrl(item)} 
                                          target="_blank">{item.referencia}</a>} />
                            )
                        })
                    }
                </TreeItem>
            }
        </Box>
    );
};

export default ClientPeekWaterSupplyForm;