import React from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IWaterSupplyInterfaceUpsert } from "../../gateways/water-supply.interfaces";
import FormTextField from "../../components/form-text-field";
import { 
  FormControlLabel,
  Checkbox,
  Typography, 
  makeStyles, 
  Theme, 
  createStyles 
} from "@material-ui/core";
import SupplyDateGroup from "../../components/supply-date-group";
import SelectorField from "../../components/selector-field";
import { IProviders } from "../../gateways/providers.interface";
import { IAccessType } from "../../gateways/access-type.interfaces";
import { emailValidationError } from "../../utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subtitle: {
      paddingTop: theme.spacing(3),
    },
  })
);

interface IWaterSupplyFormProps {
  supply: IWaterSupplyInterfaceUpsert;
  onChangeSupply: (name: string, value: any) => void;
  validateRequired: (value: any) => boolean;
  errorMissingData?: boolean;
  accessTypes?: IAccessType[];
  providers?: IProviders[];
  showAllFields?: boolean;
  onSendEmailChange: () => void;
  onSendClientEmailChange: () => void;
  onCounterCheckChange?: () => void;
  handleDateChange?: (value: any) => void;
  onProviderAddButtonClick?: () => void;
  handleValidationDateChange?: (value: any) => void;
  onChangeSeparatedMeterInvoiceFolder?: (value: any) => void;
  onSeparatedMeterStartCaptureDateChange?: (value: any) => void;
  onSeparatedMeterStartValidationsDateChange?: (value: any) => void;
}

const WaterSupplyForm: FC<IWaterSupplyFormProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <FormTextField
        name="referencia"
        label={t("waterSupplyCreateDialog.reference") as string}
        type="text"
        validator={props.validateRequired}
        error={props.errorMissingData}
        errorText={t("messages.requiredField") as string}
        value={props.supply.referencia ?? ""}
        onChange={props.onChangeSupply}
        fullWidth
        required
        autoFocus
      />
      {props.accessTypes && (
        <SelectorField
          name="accessTypeId"
          emptyValue={true}
          fullWidth
          inputLabel={t("common.access") as string}
          required={false}
          onChange={props.onChangeSupply}
          value={props.supply.accessTypeId}
          validator={props.validateRequired}
          values={props.accessTypes.map((value) => {
            return { id: value.id, value: value.name };
          })}
          handleClickAddButon={props.onProviderAddButtonClick}
          disableError={true}
        />
      )}
      {props.showAllFields && (
      <div>
        {props.providers && (
          <SelectorField
            name="providerId"
            emptyValue={true}
            fullWidth
            inputLabel={t("common.provider") as string}
            required={false}
            onChange={props.onChangeSupply}
            value={props.supply.providerId}
            values={props.providers.map((value) => {
              return { id: value.id, value: value.society };
            })}
            handleClickAddButon={props.onProviderAddButtonClick}
            disableError={true}
          />
        )}

          <Typography variant="h6" className={classes.subtitle}>
            {t("common.botConfigFields") as string}
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                color={"primary"}
                checked={props.supply.sendEmail}
                onChange={props.onSendEmailChange}
              />
            }
            label={t("common.sendEmail") as string}
          />

          <FormControlLabel
            control={
              <Checkbox
                color={"primary"}
                checked={props.supply.sendClientEmail}
                onChange={props.onSendClientEmailChange}
              />
            }
            label={t("common.sendClientEmail") as string}
          />

          <FormControlLabel
            control={
              <Checkbox
                color={"primary"}
                checked={props.supply.separatedMeterAccount}
                onChange={props.onCounterCheckChange}
              />
            }
            label={t("common.counterContract") as string}
          />
          <FormTextField
            name="code"
            label={t("common.code") as string}
            type="text"
            validator={props.validateRequired}
            value={props.supply.code ?? ""}
            onChange={props.onChangeSupply}
            fullWidth
          />
          <FormTextField
            disabled
            name="invoicesFolder"
            label={t("common.invoicesFolder") as string}
            type="text"
            validator={props.validateRequired}
            value={props.supply.invoicesFolder ?? ""}
            onChange={props.onChangeSupply}
            fullWidth
          />
          {props.supply.separatedMeterAccount &&(
          <FormTextField
            disabled
            name="separatedMeterInvoiceFolder"
            label={t("common.invoicesCounterFolder") as string}
            type="text"
            value={props.supply.separatedMeterInvoiceFolder ?? ""}
            onChange={props.onChangeSupply}
            fullWidth
          />
          )}
          <FormTextField
            name="emailNotification"
            label={t("common.client_email")}
            type="text"
            validator={props.validateRequired}
            value={props.supply.emailNotification ?? ''}
            onChange={props.onChangeSupply}
            fullWidth
            error={emailValidationError(props.supply.emailNotification)}
            helperTextMessage={`Si hay más de 1 correo, separar con punto y coma, ejemplo: test1@test.com; test2@test.com`}
          />
          {props.handleDateChange && props.handleValidationDateChange && (
            <SupplyDateGroup
              item={{
                startCaptureDate: props.supply.startCaptureDate,
                startValidationsDate: props.supply.startValidationsDate,
              }}
              onStartCaptureDateChange={props.handleDateChange}
              onValidationsDateChange={props.handleValidationDateChange}
            />
          )}
          {props.supply.separatedMeterAccount &&(
            <SupplyDateGroup
              startCaptureDateLabel= {t("common.startCaptureCounterDate") as string}
              validationDateLabel= {t("common.startValidationsCounterDate") as string}
              item={{
                startCaptureDate: props.supply.separatedMeterStartCaptureDate,
                startValidationsDate: props.supply.separatedMeterStartValidationsDate,
              }}
              onStartCaptureDateChange={props.onSeparatedMeterStartCaptureDateChange!}
              onValidationsDateChange={props.onSeparatedMeterStartValidationsDateChange!}
            />
          )}
          </div>
        )}
    </div>
  );
};

export default WaterSupplyForm;
