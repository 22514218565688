import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from 'react-router';
import { useNavigator } from '../contexts/navigator.context';

const AppContainer: FC<RouteComponentProps> = ({ history, location, children }) => {

    const [navigator, navigatorDispatch] = useNavigator();
    const [currentPath, setCurrentPath] = useState(location.pathname);

    useEffect(() => {
        const unregisterCallback = history.listen((location) => {
            if (location.pathname !== currentPath) {
                setCurrentPath(location.pathname);
                navigatorDispatch({ type: 'reset' });
            }
        });

        return () => {
            unregisterCallback();
        }
    }, [history, location.pathname]);

    return (
        <>
            {children}
        </>
    );
}

export default withRouter(AppContainer);