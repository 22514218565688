import React from "react";
import {  Switch, Route, useRouteMatch } from "react-router";
import FuelSupplyList from "./fuel-supply-list";
import FuelSupplyDetail from "./fuel-supply-detail";
import FuelContractRouter from "../fuel-contract/fuel-contract-router";
import RegisterFuelBillRouter from "../register-fuel-bill/register-fuel-bill-router";
import { FuelSupplyWrapper } from "./fuel-supply-wrapper";


const FuelSupplyRouter = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/`} exact>
                <FuelSupplyList/>
            </Route>
            <Route path={`${path}/:fuelSupplyId`} component={FuelSupplyWrapper}/>
        </Switch>
    );
};
export default FuelSupplyRouter;
