import React, { FC, useState, useEffect } from 'react';
import ICountry from '../../gateways/country.interfaces';
import CountryDeleteDialog from './country-delete-dialog';
import useIoC from '../../contexts/ioc.context';
import CountryGateway from '../../gateways/country.gateway';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { CompanyGateway } from '../../gateways/company.gateway';
import ICompany from '../../gateways/company.interface';
import CountryMigrateCompaniesDialog from './country-migrate-companies-dialog';

interface ICountryDeleteWizard {
    open: boolean;
    item: ICountry | undefined;
    onItemDeleted: (id: number) => void;
    onClose: () => void;
}

const CountryDeleteWizard: FC<ICountryDeleteWizard> = (props) => {
    const snackbar = useSnackbar();
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const countryGateway = useIoC(CountryGateway);
    const companyGateway = useIoC(CompanyGateway);

    const deleteCountry = async (item: ICountry) => {
        try {
            await countryGateway.delete(item.id);
            snackbar.enqueueSnackbar(t('countryDeleteDialog.deleteSucceded', { id: item.id, name: item.name }), { variant: "success" });
            props.onItemDeleted(item.id);
        } catch (e) {
            const er = e as any;
            if (er.response.data.key === 'deletingError'){
              const key = 'countryErrorHandler.' + er.response.data.key; 
              const message = "validation" in er ? er.validation : t(key, {id: er.response.data.id});
              snackbar.enqueueSnackbar(message, { variant: "error" });
            }else{
              const message = "validation" in er ? er.validation : t('messages.defaultError');
              snackbar.enqueueSnackbar(message, { variant: "error" });
            }
        } finally {
            props.onClose();
        }
    };

    const handleItemDelete = async () => {
        if (props.item) {
            const companies = await companyGateway.findAll({ countryId: props.item.id })
            setCompanies(companies);
            if (companies.length === 0) {
                await deleteCountry(props.item);
            } else {
                setStep(2);
            }
        }
    };

    const handleMigration = async (countryId: number) => {
        if (props.item) {
            try {
                await companyGateway.migrateCompanies(companies.map(value => value.id), countryId);
                snackbar.enqueueSnackbar(t('countryDeleteWizard.migrationSucceded', { id: countryId }), { variant: "success" });
                props.onItemDeleted(props.item.id);
            } catch (e) {
                const er = e as any;
                if(er.response.data.key === 'updatingCompaniesCountriesError'){
                    const key = 'countryErrorHandler.' + er.response.data.key;
                    const message = "validation" in er ? er.validation : t(key);
                    snackbar.enqueueSnackbar(message, { variant: "error" });
                }else{
                    const message = "validation" in er ? er.validation : t('messages.defaultError');
                    snackbar.enqueueSnackbar(message, { variant: "error" });

                }
            } finally {
                props.onClose();
            }
        }
    };

    useEffect(() => {
        setStep(1);
    }, [props.open]);

    if (props.item) {
        return (
            <>
                <CountryDeleteDialog
                    open={props.open && step === 1}
                    onItemDelete={handleItemDelete}
                    onClose={props.onClose}
                />
                <CountryMigrateCompaniesDialog
                    open={props.open && step === 2}
                    id={props.item.id}
                    onMigration={handleMigration}
                    onClose={props.onClose}
                />
            </>
        );
    } else {
        return null;
    }
};

export default CountryDeleteWizard;